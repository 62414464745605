<template>
    <div class="feedback-main">
        <Header v-if="isAdmin == false" :imageUrl= userData :chatObject= chatObject :activeGroup = activeGroup :activeChat= activeChat :groupData="groupData"></Header>
        <section class="feedback-wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="backbtn-wrapper p-3" v-if="businessMenuActive == false">
                            <router-link to="/chat"><button class="btn btn-white"><img src="@/assets/images/tag-arrow.png" class="back-arrow"> Back</button></router-link>
                        </div>
                         <div class="backbtn-wrapper p-3" v-else>
                            <router-link to="/businesschat"><button class="btn btn-white"><img src="@/assets/images/tag-arrow.png" class="back-arrow"> Back</button></router-link>
                        </div>
                    </div>
                </div>
            </div>    
            <div class="feedback-box">
                <h4>Give Feedback</h4>
                <img src="@/assets/images/afterimg.png">
                <p>Please tell us what you like about Dooey or how you think it
                could be improved?</p>
                <ValidationObserver ref="feedback" v-slot="{ handleSubmit }">
                <form class="feddback-form"  @submit.prevent="handleSubmit(submitFeedback)">
                    <ValidationProvider rules="required|min:3" name="feedback" v-slot="{ errors }">
                    <div class="form-group addtextarea">
                        <!-- <label>Feedback *</label> -->
                        <textarea placeholder="Enter Feedback*" name="feedback" class="form-control" maxlength="500" @keyup="checkCharecterLengthFeedBack(feedback)" v-model="feedback"></textarea>
                        <span class="InvalidInput">{{ errors[0]=='The feedback field must be at least 3 characters'?'minimum 3 character is required.':errors[0] }}</span>

                        <span v-if="charecterErrorMessageForFeedBack != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForFeedBack}}</span>
                    </div>
                    </ValidationProvider>
                    <div class="btn-wrapper">
                        <button type="button" class="btn btn-white btn-cancel" @click="cancelFeedback()">Cancel</button>
                        <button type="submit" v-if="businessMenuActive == false" class="btn btn-yellow" :class="{disabled : isSpinner == true}" >submit</button>
                        <button type="submit" v-else class="btn btn-blue" :class="{disabled : isSpinner == true}" >Submit</button>
                    </div>
                </form>
                </ValidationObserver>
            </div>
        </section>
        <FrontFooter></FrontFooter>
    </div>
</template>
<script>
import Header from "@/components/header.vue";
import FrontFooter from "@/components/forntfooter.vue";
import firebase from "./firebaseInit";
import firebaseData from '../utils/firebaseCollection.js'
import Vue from 'vue'
const db = firebase.firestore()
export default {
  name: "headers",
  components: {
    Header, FrontFooter
  },
  data(){
      return{
          feedback:'',
          userid:'',
          isAdmin:'',
          loginUser:'',
          imageUrl:'',
        chatObject:{
            firstName:'',
            lastName:'',
            profileUrl:'',
            userid:''
        },
        groupData:{},
        activeGroup:false,
        userData:{},
        activeChat:false,
        isSpinner:false,
        businessMenuActive:false,
        charecterErrorMessageForFeedBack:''
      }
  },
  created(){
    //   //debugger // eslint-disable-line
        var self = this;
        self.$root.$on('setBusinessMenu',(value) => {
            // //debugger // eslint-disable-line
             self.businessMenuActive = value
         })
         
         self.userid = localStorage.getItem('customerDocKey');
        db.collection(firebaseData.firebasecollection.USER).doc(self.userid).onSnapshot(doc=>{
            self.imageUrl = doc.data().profileUrl;
            self.userData = doc.data()
        })
      // CHECK LOGIN USER IS ADMIN OR CUSTOMER(Shraddha)
        self.loginUser = localStorage.getItem("IsLoginUser")
        //console.log('loginUser',self.loginUser);
        if(this.loginUser == 'Admin'){
            self.isAdmin = true
        }else{
            self.isAdmin = false
        }

        
  },
  methods:{
      // CHECK CHARECTER LIMIT IN FEEDBACK (Shraddha)
      checkCharecterLengthFeedBack(groupName){
        // debugger // eslint-disable-lines
        var self = this;
        if(groupName.length == 500){
          self.charecterErrorMessageForFeedBack = 'The feedback may not be greater than 500 characters';
        }else{
          self.charecterErrorMessageForFeedBack = '';
        }
    },
      //SUBMIT FEEDBACKDATA (Shraddha)
        submitFeedback(){
          debugger // eslint-disable-line
            var self=this;
            if(self.charecterErrorMessageForFeedBack == ''){
                self.isSpinner = true
                self.feedback = self.feedback.trim()
                self.userid = localStorage.getItem('customerDocKey');
                // console.log('Vue.prototype.$adminDetails',Vue.prototype.$adminDetails)
                // if(Vue.prototype.$adminDetails.userSupportTicket){
                //     self.$axios.post(self.$apiurl+'sendMailToAdmin',{adminId:Vue.prototype.$adminDetails.id,message:`you received feedback from  ${self.userData.email},<p>feedback :${self.feedback}</p>`,operation:'ticket'})
                //     .then((result) => {
                //         // console.log(result)
                //     })
                // }
                    db.collection(firebaseData.firebasecollection.FEEDBACK).add({
                    userName:`${self.userData.firstName} ${self.userData.lastName}`,
                    feedBack : self.feedback,
                    id:self.userData.id,
                    email:self.userData.email,
                    phone:self.userData.phoneNo,
                    sendDate:new Date()
                    }).then((docref)=>{
                        db.collection(firebaseData.firebasecollection.FEEDBACK).doc(docref.id).update({
                            docId:docref.id
                        })
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"Success",
                            text:'Feedback send successfully.',
                            type:"success"
                        })
                        self.feedback = '';
                        self.isSpinner = false
                        self.$refs.feedback.reset()
                    })
               
                .catch(error=>{
                self.isSpinner = false;
                console.log(error)
            })
            }
        },
        //CANCEL FEEDBACKDATA(Shraddha)
        cancelFeedback(){
            var self = this;
            self.feedback = '';
            self.isSpinner = false;
            self.charecterErrorMessageForFeedBack = '';
            self.$refs.feedback.reset()
        }
  }
};
</script>

