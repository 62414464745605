<template>
    <b-modal id="new-chat" centered hide-footer title="" @close="closeFire()" :no-close-on-backdrop="true">
     <div class="contact-modal newchat-modal">
            <div class="contact-modal">
            <h4 v-if="businessMenuActive == false">New Chat BUSINESS</h4>
            <h4 v-else>Create Group</h4>
            <ValidationObserver ref="log" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(createGroup)">
                 <ValidationProvider rules="min:3" name="chat Name" v-slot="{ errors }">
                <div class="form-group" v-if="businessMenuActive == false">
                    <label>Chat Name *</label>
                    <input class="form-control" placeholder="Enter chat name" v-model="chatName" type="text" ref="chatMessage" maxlength="21" @keyup="checkChrecterLength(chatName)">
                    <span class="InvalidInput">{{ errors[0]=='The chat Name field must be at least 3 characters'?'minimum 3 character is required.':errors[0] }}</span>
                     <span v-if="charecterErrorMessage != ''&& errors.length == 0" style="color:red;">{{charecterErrorMessage}}</span>
                </div>
                 <div class="form-group" v-else>
                    <label>Group Name *</label>
                    <input class="form-control" placeholder="Enter group name" v-model="chatName" type="text" ref="chatMessage" maxlength="21" @keyup="checkChrecterLength(chatName)">
                     <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                     <span v-if="charecterErrorMessage != ''&& errors.length == 0" style="color:red;">{{charecterErrorMessage}}</span>
                </div>
                  </ValidationProvider>
                <div class="form-group">
                    <label>Create a few initial branches</label>
                    <div v-for="(data,index) in branchName" :key="index" style="position:relative">
                        <ValidationProvider :rules="index==0?'min:3':'required|min:3'" name="branch name" v-slot="{ errors }" :vid="'branch name'+index">
                            <input class="form-control" placeholder="Enter few initial branches" :name="'branch name'+index" v-model="data.name" maxlength="21" @keyup="checkChrecterLengthInBranch(data.name,index)">
                            <img src="@/assets/images/user_cancel.png" style="cursor:pointer" v-if="index!=0" class="remove_branch" @click="()=>{branchName.splice(index,1)}">
                            <span class="InvalidInput">{{ errors[0]=='The branch name field must be at least 3 characters'?'minimum 3 character is required.':errors[0] }}</span>
                            <span class="InvalidInput" v-if="checkBranchNamePerInput(data,index) && errors.length==0">The branch name should not be same.</span>
                            <span v-if="charecterErrorMessageForBranch != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForBranch}}</span>

                        </ValidationProvider>
                    </div>
                    <!-- <span class="InvalidInput" v-if="!validBranchName && showBranchNameError">The branch name field is required.</span> -->
                </div>
                <div class="forget-link">
                    <!-- <div>
                        <label class="checkbox-c opacity-zero">Send Message 1 to 1<input type="checkbox" checked="checked"><span class="checkmark"></span></label>
                    </div> -->
                        <a href="#" class="link-branch" v-if="branchName.length<2" @click.prevent="branchName[0].name!=''?addAnotherBranch():showError()">+ Add Branch</a>
                        <a href="#" class="link-branch" v-else @click.prevent="!duplicateBranchName?handleSubmit(addAnotherBranch):''">+ Add Branch</a>
                </div>

                <div class="form-group">
                    <label class="sc">Select Contacts</label>
                    <div class="select-contact-list">
                        <ul>
                            <li v-for="(bulkList,index) in bulkMemberList" v-bind:key="index">
                              {{bulkList.firstName}} {{bulkList.lastName}} 
                              <span class="remove-content"><img src="@/assets/images/user_added.png"></span>
                              <span class="remove-content" @click="removemember(index)"><img src="@/assets/images/user_cancel.png"></span>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- <div class="form-group">
                    <div class="select-contact-list">
                        <ul>
                            <li v-for="(groupmember,index) in groupMemberData" v-bind:key="index">
                                {{groupmember.FullName}} <span class="remove-content" @click="()=>{groupMemberData.splice(index,1)}">x</span>
                            </li>
                        </ul>
                    </div>
                </div> -->
           
                    <!-- <div class="form-group searchContact"> -->
                        <!-- <input type="search" class="form-control" placeholder="Search Contacts" v-model="searchContact" > --> 
                         <!-- <select class="vs-con-input form-control" v-model="searchContact"  @change="selectMember()">
                            <option value="" disabled hidden>Search Contacts</option>
                            <option value="" disabled v-if="groupMemberData.length == userSearchList.length">No more users</option>
                            <option v-for="option in userSearchList" v-else v-bind:key="option.value" :hidden="checkselected(option.docid)" :value = option>
                                {{ option.FullName }}
                            </option>
                            
                        </select> -->
                        
                    <!-- <v-select 
                        :options="userSearchList"
                        class="vs-con-input"
                        :multiple="true"
                        placeholder="Search Contacts"
                        label="FullName"
                        v-model="searchContact"
                    /> -->
                     <!-- @input="selectMember($event)" -->
                    <!-- </div> -->
                <div class="form-group modal-search">
                    <input type="search" input-id="tags-basic" remove-on-delete  class="form-control" placeholder="Search Contact" v-model="keyword">
                    <img src="@/assets/images/search-icon.png" title="Find" class="search-icon">
                </div>

                <div class="choose-contact">
                   <div class="scrollbar">
                       <ul class="member-list-sidebar">
                          <li v-for="(bulkUserList,index) in getFilterData" v-bind:key="index" v-show="botUser.id != bulkUserList.id">
                            <div v-if="bulkUserList.block != true"><img :src="bulkUserList.profileUrl"><span class="ml-1">{{bulkUserList.firstName}} {{bulkUserList.lastName}}</span></div>
                            <button type="button" v-if="bulkUserList.block != true" class="btn btn-white" @click="addBrodcastMmeber(bulkUserList)">Add</button>
                           </li>
                        </ul>
                    </div>
                </div>
                <p class="contact-count"><span>{{bulkMemberList.length}}</span> Contact Selected</p>
                <div class="btn-wrapper">
                    <!-- <button type="button" class="btn btn-yellow" v-bind:class="{disabled: isSpinner == true}" v-if="branchName.length>1" @click="validBranchName?!duplicateBranchName?createGroup():showBranchNameError=true:showBranchNameError=true">Create</button> -->
                    <button type="button" v-if="businessMenuActive == false" class="btn btn-blue" v-bind:class="{disabled: isSpinner == true}" @click="!duplicateBranchName?handleSubmit(createGroup):''">Create</button>
                    <button type="button" v-else class="btn btn-blue" v-bind:class="{disabled: isSpinner == true}" @click="!duplicateBranchName?handleSubmit(createGroup):''">Create</button>
                </div>
            </form>
              </ValidationObserver>
        </div>
        </div>
  </b-modal>
</template>
<script>


import firebaseData from '@/utils/firebaseCollection'
import firebase from "@/views/firebaseInit"
import firebaseTimestamp from "firebase";
import Vue from "vue";
import vSelect from "vue-select";
import {Contactlist2,getUserData2,getGroupListCount2,getUnblockperson2} from '@/businessUtills/getQueeryActiveChatList.js'
import {getLoginUserData,getBotuserData} from "@/utils/getQueeryActiveChatList.js";

const db = firebase.firestore();
Vue.component("v-select", vSelect);

export default({
    components:{
        firebaseData,
    },
    data(){
        return{
            userSearchList:[],
            getUserData:[],
            chatName:'',
            searchContact:'',
            isChatting:false,
            updateChat:{},
            connectedUserArray:[],
            AdminData:{},
            userid:'',
            socialMessageid:'',
            branchName:[{name:''}],
            groupMemberData:[],
            isSpinner:false,
            unblockgroupList:[],
            bulkMemberList : [],
            keyword:'',
            showBranchNameError:false,
            businessMenuActive:false,
            charecterErrorMessage:'',
            charecterErrorMessageForBranch:'',
            botUser:{}
        }
    },
    created(){
       
        this.getContactList();
        // this.chatFocus();
        this.$root.$on("businessMenuActive",(value,activeChat)=>{
            //   //debugger // eslint-disable-line
            this.businessMenuActive = value;
            //console.log(activeChat)
        })
        getBotuserData(firebaseData.firebasecollection.USER,(res) => {
            this.botUser = res;
        })
    },
    mounted(){
        this.getContactList();
    },
    // watch : {
    //     $route : function (){
    //         this.getContactList();
    //         // this.chatFocus();
    //     }
    // },
    methods:{
        showError()
        {
            this.$notify({
                title:'Error',
                text:'Please enter a branch name first.',
                type:'error'
            })
        },
        checkBranchNamePerInput(data,index)
        {
            var self = this

            var duplicate = false

            for (let i = 0; i < self.branchName.length; i++) {
                if(index != i && data.name==self.branchName[i].name && index>=i)
                {
                    duplicate=true
                }
            }
            return duplicate
        },
        addAnotherBranch()
        {
            var self = this

            if(self.branchName[self.branchName.length-1].name!='')
            {
                self.branchName.push({name:""})
            }
        },
        customLabel (option) {
            return `${option.library} - ${option.language}`
        },
        onSelect (option, id) {
            //console.log(option, id)
        },
        // SET FOCUS ON GROUPNAME WHEN OPEN POPUP (Shraddha)
        // chatFocus(){
        //     var self=this;
        //     self.$root.$on("setchatfocus",(value) => {
        //         // //debugger // eslint-disable-line
        //         if(value == 'yes'){
        //             setTimeout( () => {
        //                 self.$refs.chatMessage.focus();
        //             },1000)
        //         }
        //     })
        // },
          //GET CONTACT LIST(Shraddha)
        getContactList(){
            // //debugger // eslint-disable-line
            var self=this;
            self.acseptUserData=[];
            self.userSearchList = []
            self.unblockgroupList = []
            const db = firebase.firestore();
            Contactlist2(firebaseData.firebasecollection.USER,self.unblockgroupList,res=>{
                self.unblockgroupList = res
                //  console.log('unblockgroupList',self.unblockgroupList);
                getUserData2(firebaseData.firebasecollection.USER,self.getUserData,res2=>{
                    self.getUserData = res2
                    // console.log('getUserData',self.getUserData);
                    getGroupListCount2(self.unblockgroupList,self.getUserData,res3=>{
                         self.unblockgroupList = res3
                        //  console.log('unblockgroupList',self.unblockgroupList);
                        getUnblockperson2(self.unblockgroupList,res4 => {
                           self.userSearchList = res4
                            // console.log('userSearchList',self.userSearchList);
                        })
                    })
                })
            })
        },
         //CHECK GROUP NAME CHRECTER LENGTH(Shraddha)
        checkChrecterLength(groupname){
            // debugger // eslint-disable-line
            var self = this;
                if(groupname.length < 21){
                    self.chatName = groupname
                    self.charecterErrorMessage = '';
                }else{
                    self.charecterErrorMessage = 'The group name may not be greater than 20 characters';
                    self.chatName = (groupname).slice(0, 20)
                }
        },
        checkChrecterLengthInBranch(groupName,index){
            var self = this;
            if(groupName.length < 21){
                self.branchName[index].name= groupName
                self.charecterErrorMessageForBranch = '';
            }else{
                self.charecterErrorMessageForBranch = 'The Branch name may not be greater than 20 characters';
                self.branchName[index].name = (groupName).slice(0, 20)
            }
        },
        // CREATE GROUP (Shraddha)
        createGroup(){
            // debugger // eslint-disable-line
            console.log("Create Business")
            var self=this;
            if(self.charecterErrorMessage == '' && self.charecterErrorMessageForBranch == ''){
                self.chatName = self.chatName.trim();
                self.isSpinner = true
                var mainBranches = []
                if(self.branchName.length!=0)
                {
                    self.branchName.forEach(data=>{
                        if(data.name!='')
                        {
                            mainBranches.push({
                                name:data.name.trim(),
                                createdAt:new Date(),
                                subBranches:[],
                                lastMsgTime :new Date(),
                                muteArray:[],
                                messageSeenIds:[],
                                flag:false
                            })
                        }
                    })
                }
                self.isChatting = false
                self.updateChat = {}
                self.connectedUserArray = [];
                //console.log('group chat',self.chatName,self.searchContact);
                const db = firebase.firestore();
                self.userid = localStorage.getItem('customerDocKey');
                if(self.chatName != ''){
                    if(self.bulkMemberList.length > 0){
                        db.collection(firebaseData.firebasecollection.USER)
                        .doc(self.userid)
                        .get()
                        .then(doc=>{
                            self.AdminData = doc.data();
                            //console.log('self.AdminData',self.AdminData)
                        }).then(() => {
                            self.from = {
                                avtar: Vue.prototype.$defaultProfile,
                                id:self.AdminData.id,
                                name:self.AdminData.firstName,
                            }
                            //console.log('self.from',self.from)

                            self.updateChat = {
                                from:self.from,
                                msg:''
                            }
                            //console.log('self.updateChat',self.updateChat)
                            self.connectedUserArray.push(self.userid)
                            for(var i=0;i<self.bulkMemberList.length;i++){
                                self.connectedUserArray.push(self.bulkMemberList[i].id)
                            }

                            mainBranches.forEach(data=>{
                                self.connectedUserArray.forEach(ids=>{
                                    data.messageSeenIds.push({
                                        id:ids,
                                        count:0
                                    })
                                })
                            })
                            //console.log('self.connectedUserArray',self.connectedUserArray)
                        }).then(() => {
                            var messageSeenIds=[]
                            self.connectedUserArray.forEach(data=>{
                                messageSeenIds.push({id:data,count:0})
                            })
                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).add({
                                connectedUser:self.connectedUserArray,
                                id:'',
                                isGroup:true,
                                isChannel:false,
                                lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                profileUrl:Vue.prototype.$defaultGroupProfile,
                                adminName:self.AdminData.firstName+' '+self.AdminData.lastName,
                                adminId :self.userid,
                                groupcreatedate:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                title:self.chatName,
                                updateChat: {},
                                mainBranches:mainBranches,
                                muteArray:[],
                                SurpriseSelfie : {},
                                ToggleSelfie : [],
                                messageSeenIds:messageSeenIds
                            }).then((docRef) => {
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(docRef.id).update({
                                    id:docRef.id
                                }).then(() => {
                                    self.$root.$emit("audioplay");
                                    self.$notify({
                                        title:"Success",
                                        text:self.chatName+' published.',
                                        type:"success",
                                        duration: 10000
                                    })
                                    self.chatName='';
                                    self.searchContact = [];
                                    self.bulkMemberList = [];
                                    self.$refs.log.reset();
                                    self.branchName=[{name:''}];
                                    self.$bvModal.hide('new-chat');
                                    self.isSpinner = false
                                    self.$root.$emit('groupcreate','Yes');
                                })
                            })
                        })
                    }else{
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"ERROR",
                            text:'Group member is required for chat',
                            type:"error",
                            duration: 10000
                        })
                        self.isSpinner = false
                        self.$refs.log.reset();
                    }
                }else{
                    self.$root.$emit("audioplay");
                    self.$notify({
                        title:"ERROR",
                        text:'Chat name is required',
                        type:"error",
                        duration: 10000
                    })
                    self.isSpinner = false
                    self.searchContact =[]
                    self.$refs.log.reset();
                }
            }
        },
        // CLOSE EVENT FIRE(Shraddha)
        closeFire(){
            // //debugger // eslint-disable-line
            var self=this;
            self.chatName = ''
            // self.userSearchList = [];
            self.searchContact = [];
            self.bulkMemberList = [];
            self.branchName = [{name:''}]
            self.$refs.log.reset();
        },
           // ADD MEMBER FOR BRODCAST(Shraddha)
        addBrodcastMmeber(bulkobj){
          // //debugger // eslint-disable-line
          var self = this;
         self.keyword = ''
          if(self.bulkMemberList.length > 0){
            let isInArray = self.bulkMemberList.some(function(item) {
                return item.docid == bulkobj.docid
            });
            if(isInArray == false){
              self.bulkMemberList.push(bulkobj);
              
            }else{
              self.$root.$emit("audioplay");
              self.$notify({
                title:"Error",
                text:'Already exists',
                type:"error"
              })
              
            }
          }else{
            self.bulkMemberList.push(bulkobj);
            
          }
        },
        // REMOVE MEMBER FROM BULKARRAY(Shraddha)
        removemember(id){
            // //debugger // eslint-disable-line
            var self = this;
           self.bulkMemberList.splice(id,1);
        },
    },
    computed:{
        validBranchName(){
            var self = this
            if(self.branchName[self.branchName.length-1].name!='')
            {
                self.showBranchNameError=false
                return true
            }
            else
            {
                return false
            }
            // }
        },
        duplicateBranchName()
        {
            var self = this

            var duplicate = false
            var index=0

            for (let i = 0; i < self.branchName.length; i++) {
                for (let j = i+1; j < self.branchName.length; j++) {
                    if(self.branchName[i].name==self.branchName[j].name)
                    {
                        duplicate = true
                    }
                }
            }

            return duplicate
        },
        getFilterData() {
            var self = this;
            return self.userSearchList.filter(function(rawData) {
                return (
                rawData["firstName"]
                    .toLowerCase()
                    .indexOf(self.keyword.toLowerCase()) >= 0 ||
                rawData["lastName"]
                    .toLowerCase()
                    .indexOf(self.keyword.toLowerCase()) >= 0 
                );
            });
        },
    }
})
</script>

<style scoped>
div#new-chat .contact-modal.newchat-modal .InvalidInput {
    position: relative;
}
</style>