<template>
<div>
  <Header v-if="isAdmin == false" :imageUrl= userData :chatObject= chatObject :activeGroup = activeGroup :activeChat= activeChat :groupData="groupData" />
    <section class="profile-wrapper general_setting" :class="{loaderBackground : showLoader}">
        <div class="container-fluid">
             <div class="row mb-4">
              <div class="col-md-12">
                  <div class="backbtn-wrapper">
                    <router-link to="/businesschat"><button class="btn btn-white"><img src="@/assets/images/tag-arrow.png" class="back-arrow">Back To Chat</button></router-link>
                  </div>
              </div>
            </div>
             <div class="loader_div" v-if="showLoader" >
                <!-- <sync-loader :loading="true" color="#77bef6"></sync-loader> -->
                <img src="@/assets/images/Blue_GIF.gif" class="lodar_gif">
            </div>
            <!-- <div class="row">
                <div class="col-md-12">
                    <b-tabs content-class="mt-3" align="left" class="active-chat-tab">
                    <b-tab title="Social" @click="SocialMenuActiveTab()">
                        
                    </b-tab>
                    <b-tab title="Business" active @click="businessMenuActiveTab()" class="btn btn-blue">
                        <div class="admin_business_wrap">
                           
                        </div>
                    </b-tab>
                    </b-tabs>
                </div>
            </div> -->
            <div class="row">
                <div class="col-md-2">
                        <h4>Account Settings</h4>
                </div>
                <div class="col-md-10">
                  <div class="myprofile-form_wrapper"> 
                        <h4>{{activeTab}}</h4>
                  </div>
                </div>
            </div>
            <div class="col-md-12">
                <b-card no-body :class="{'businessgeneral' :businessMenuActive == true }">
                    <b-tabs pills card vertical v-model="tabIndex">
                    <b-tab title="Read Receipts"  @click.prevent="activeTab='Read Receipts'">
                        <div class="row">
                            <div class="col-md-5 read_receipts_col">
                                <div class="notifocation-wrapper">
                                    <h4>Read Receipts</h4>
                                    <div class="button-switch">
                                        <input type="checkbox" id="switch-blue" checked="checked" class="switch" @change="saveReadRecipt()" v-model="readReceiptValue">
                                    </div>
                                </div>
                                <div class="btn-wrapper read_receipte-btn">
                                    <!-- <button class="btn btn-white" @click="cancelReadRecipt()" >Cancel</button>                                   
                                    <button class="btn btn-blue"  @click="saveReadRecipt()" v-bind:class="{disabled: isSpinner == true}">Save</button> -->
                                </div>
                            </div>
                            <div class="col-md-7"></div>
                        </div>
                        
                    </b-tab>
                   <b-tab title="Subscription Details" @click.prevent="activeTab ='Subscription Details'" class="upgrade_account upgrade_account_business" v-if="companyName.isAdmin">
                        <div class="row">
                            <div class="col-md-6" >
                                <div class="social_account_box_wrapper">
                                <h4 class="business_title">Business Account</h4>
                                <div class ="currentPlanDetail">
                                    <p>Current Plan</p>
                                    <button type="Submit" @click.prevent="tabIndex++" class="btn btn-white">Invite User</button>
                                </div>
                                <div class="planList" v-for="(businessplan,index) in businessPlanData" :key="index" v-show="businessplan.plan_status != 'deactivated'">
                                <!-- <span v-if="beforeDateExpired(businessplan.dateObject)"> -->
                                 <div class="plandata_main">   
                                <p class="plan_name">{{businessplan.plan_name}}</p>
                                <p class="plan_price" v-if="businessplan.payment_status == 'paid'">{{businessplan.plan_name}} User : {{businessplan.noOfUser}}</p>
                                <p class="plan_price" v-if="businessplan.payment_status == 'paid'">{{decodeHtmlCharCodes(businessplan.price).slice(0, 1)}}{{(Number(decodeHtmlCharCodes(businessplan.price).slice(1) || 0)) * businessplan.noOfUser}}/{{businessplan.plan_duration}}</p>
                                <!-- <p class="" v-if="businessplan.payment_status == 'paid'"> Next Billing On : {{businessplan.next_billing_date}} </p> -->
                                </div>
                                <!-- <span v-if="!beforeDateExpired(businessplan.dateObject)">Expired</span> -->
                                <div class="nextbillingcycle">
                                <p class="" v-if="businessplan.payment_status == 'paid'"> Next Billing On : {{businessplan.next_billing_date}} </p>
                                <div v-if="businessplan.payment_status == 'paid'">
                                <button type="Submit" class="btn btn-deactive" style="margin-left:10px;" @click="deactivatePlan(businessplan)">Deactivate Plan</button>
                                </div>
                                </div>
                                <!-- </span> -->
                                </div>
                                
                                <div class="nextbillingcycle green_text upgradeplanbusiness_wrapper">
                                <p>Lets get the whole team involved by upgrading from our free Start Up plan. More people, storage and features are available on our Business Pro Package.</p>

                                <button type="submit" @click="upgradeplan()" class="btn btn-blue">Upgrade Plan</button>
                                </div>
                            </div>
                          
                            <div class="row" v-if="addoneArray.length > 0  && deactivatePlans.length > 1">
                               
                                <div class="col-md-12" >
                                    <div v-for="(addone,index) in addoneArray" :key="index" class="addon_area" v-show="addone.addon_status != 'deactivated'">
                                    <div>
                                        
                                        <h3 class="plan_name">{{addone.addon_name}}</h3>
                                        <h3 class="plan_name">Plan name : {{addone.plan_name}}</h3>
                                        <p>{{addone.storage_limit}}  for {{decodeHtmlCharCodes(addone.addon_price)}}/{{addone.addon_duration}}</p>
                                        <!-- <p>Next Billing On : {{addone.next_billing_date}}</p> -->
                                        
                                    </div>
                                    <div class="nextbillingcycle" style="width:100%;border-top: 1px dotted #ececec;padding-top:15px;">
                                       <p>Next Billing On : {{convertDate(addone.next_billing_date)}}</p>
                                        <div><button type="Submit" class="btn btn-deactive" v-bind:class="{disabled: isSpinner == true}" @click="deactivateAddone(addone,index)" style="margin-left: 10px;">DEACTIVATE PLAN</button></div>
                                    </div>
                                    <!-- <button type="submit" class="btn btn-blue" >Deactivate</button> -->
                                    </div>
                                    
                                </div>
                            </div>
                           
                            <div class="row" v-if="newbusinessplan.length > 1 && deactivatePlans.length > 1 ">
                                <div class="col-md-12" >
                                    <div v-for="(Addone,index) in addonArray" :key="index" class="addon_area bye_now" >
                                    <div>
                                        
                                        <h3 class="plan_name">{{Addone.addon_name}}</h3>
                                        <p class="plan_price">Add an extra {{Addone.storage}} for {{decodeHtmlCharCodes(Addone.addon_price)}}/{{Addone.addon_duration}}</p>
                                    </div>
                                    <button type="submit" class="btn btn-blue" @click="addStorage(Addone.addon_id)">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="col-md-6" style="">
                                <div class="upgrad-button_wrapper">
                                    <button type="submit" v-b-modal.modal-company class="btn btn-blue">Create A New Company</button>
                                </div>
                            </div>

                            <b-modal id="modal-company" class="modal-contact" hide-footer centered @close="closeFire($event)" :no-close-on-backdrop="true">
                                <div class="contact-modal">
                                    <div class="contact-modal">
                                        <h4>Create Business Account</h4>
                                        <ValidationObserver ref="log" v-slot="{ handleSubmit }">
                                            <form @submit.prevent="handleSubmit(createCompany)">
                                                <ValidationProvider rules="required" name="company name" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label>COMPANY NAME*</label>
                                                    <input class="form-control"  placeholder="Enter company name" maxlength="51" @keyup="isCompanyNameUniq(),checkChrecterLengthCompanyName(companyname)" @blur="companyname = companyname.trim()"  name="company name" v-model="companyname" autocomplete="off">
                                                    <span class="InvalidInput">{{ errors[0] }}</span>
                                                    <span v-if="errorMessage!= ''" class="InvalidInput">{{errorMessage}}</span>
                                                    <span v-if="charecterErrorMessageForCompanyName != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForCompanyName}}</span>
                                                </div>
                                                </ValidationProvider>
                                                <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
                                                <div class="form-group mt-3">
                                                    <label>EMAIL*</label>
                                                    <input class="form-control" placeholder="Enter email "  name="email" v-model="companyemail" autocomplete="off" disabled>
                                                    <span class="InvalidInput">{{ errors[0] }}</span>
                                                </div>
                                                </ValidationProvider>
                                                <ValidationProvider rules="required" name="company address" v-slot="{ errors }">
                                                <div class="form-group mt-3">
                                                    <label>COMPANY ADDRESS*</label>
                                                    <input class="form-control" placeholder="Enter company address " maxlength="101" @keyup="checkChrecterLengthCompanyAddress(companyaddress)" name="company address" v-model="companyaddress" autocomplete="off">
                                                    <span class="InvalidInput">{{ errors[0] }}</span>
                                                    <span v-if="charecterErrorMessageForCompanyAddress != '' && errors.length == 0" class="InvalidInput">{{charecterErrorMessageForCompanyAddress}}</span>

                                                </div>
                                                </ValidationProvider>
                                                <div class="btn-wrapper">
                                                    <button type="submit" class="btn btn-blue" v-bind:class="{disabled: isSpinner == true}"  >NEXT</button>
                                                </div>
                                            </form>
                                        </ValidationObserver>
                                    </div>
                                </div>
                            </b-modal>
                        </div>
                    </b-tab>
                    <b-tab title="User Management" @click.prevent="activeTab ='User Management'" v-if="companyName.isAdmin">
                        <div class="blocked_content">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="user_management_business_wrapper">
                                        <div class="user_management_topbar">
                                            <ValidationObserver ref="emailRequest" v-slot="{ handleSubmit }">
                                            <form class="invite_new_user" @submit.prevent="handleSubmit(sendRequest)">
                                                <ValidationProvider rules="required|email" name="email address" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label style="text-transform: none">Invite a Team Member*</label>
                                                    <input type="text" class="form-control" :maxlength="51" name="email address" v-model="emailaddress" @keydown="listenForEnter" placeholder="Not listed below ? Invite by email address..." @blur="()=>{emailaddress=emailaddress.toLowerCase()}" autocomplete="off" @keyup="checkChrecterLengthEmail(emailaddress)">
                                                    <span class="InvalidInput">{{ errors[0] }}</span>
                                                    <span v-if="charecterErrorMessageForEmail != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForEmail}}</span>
                                                </div>
                                                </ValidationProvider>
                                                <button type="submit" class="btn btn-blue btn-invite_user"  :class="{disabled : isSpinner == true}">Send Invite</button>
                                            </form>
                                            </ValidationObserver>
                                            <div class="invite_user_edit" v-if="isEdit == false" >
                                                <button  class="btn btn-blue"  @click.prevent="editUsermanagmentPlan()">Edit</button>
                                            </div>
                                             <div class="invite_user_edit" v-else >
                                                <button  class="btn btn-blue"  @click.prevent="updateUserInPlan()" >SAVE</button>
                                                <button  class="btn btn-white ml-1" @click="isEdit = false" >CANCEL</button>
                                            </div>
                                        </div>
                                        <div class="user_management_business-list">
                                            <div class="umbl_table_wrapper table-responsive">
                                                <table class="tabel">
                                                    <thead>
                                                        <tr>
                                                            <th>User</th>
                                                            <th>Status</th>
                                                            <th>Plan</th>
                                                            <th>DataUsage</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div class="d-flex">
                                                                    <img :src="userData.profileUrl">
                                                                    <div class="text_left">
                                                                    <h5>{{userData.email}}</h5>
                                                                    <span>Admin</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><span class="status_um text-green" >Active</span></td>
                                                            <td>-</td>
                                                             <td>
                                                                <div class="d-flex dataUsage">
                                                                <span>{{bytes(companyAdminInfo.dataUsage)}} /</span>
                                                                <span>{{bytes(companyAdminInfo.userUsageLimit)}}</span>
                                                                </div>
                                                                <b-progress :value="companyAdminInfo.dataUsage" :max="companyAdminInfo.userUsageLimit" class="mb-1 customeProgressBar blueProgress"></b-progress>
                                                            </td>
                                                        </tr>
                                                        
                                                        <tr v-for="(companyData,index) in CompanyUserData" :key="index" v-show="companyData.email != botUser.email">
                                                           
                                                            <td>
                                                                <div class="d-flex">
                                                                    <img :src="companyData.profileUrl">
                                                                    <h5 v-if="companyData.email">{{companyData.email}}</h5>
                                                                    <h5 v-else>{{companyData.phone}}</h5>
                                                                </div>
                                                            </td>
                                                            <td v-if="companyData.status == 'Active'"><span class="status_um text-green" >{{companyData.status}}</span></td>
                                                            <td v-else><span class="status_um ">{{companyData.status}}</span></td>
                                                            <td v-if="isEdit==false">
                                                                <span class="selected_option" v-if="companyData.status != 'Pending to accept' ">{{companyData.planName}}</span>
                                                                <span class="selected_option" v-else>-</span>
                                                            </td>
                                                            <td v-else>
                                                                <span class="selected_option" v-if="companyData.status != 'Pending to accept' ">
                                                                    <select v-model="companyData.selectplanOption" @change="changeplanname(companyData.selectplanOption,index)">
                                                                        <option v-for="(plandata,index) in newbusinessplan"  :key="index" :value="plandata" name="plandata" id="plandata" v-show="plandata.plan_status != 'deactivated'">
                                                                            {{plandata.plan_name}}
                                                                        </option>
                                                                    </select>
                                                                </span>
                                                                <span class="selected_option" v-else>-</span>
                                                            </td>
                                                            <td v-if="companyData.status != 'Pending to accept' ">
                                                                <div class="d-flex dataUsage">
                                                                <span>{{bytes(companyData.dataUsage)}} /</span>
                                                                <span>{{bytes(companyData.userUsageLimit)}}</span>
                                                                </div>
                                                                <b-progress :value="companyData.dataUsage" :max="companyData.userUsageLimit" class="mb-1 customeProgressBar blueProgress"></b-progress>
                                                            </td>
                                                            <td v-else>-</td>
                                                            <td v-if="companyData.status == 'Pending to accept' ">
                                                                <button class="btn btn-blue" @click="resendinvitation(companyData)">Resend Invite</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div v-if="CompanyUserData.length==0">
                                                    <span style="text-align:center;margin-top: 35px;font-size: 16px;text-transform: capitalize;" class="ml-1">No other users found.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Blocked Contacts" @click.prevent="activeTab ='Blocked Contacts'">
                        <div class="blocked_content">
                            <div class="row">
                                <div class="col-md-10">
                                    <ul class="member-list-sidebar">    
                                        <li v-for="(blockuser,index) in blockUserList" v-bind:key="index">
                                            <div style="display: flex;align-items: center;">
                                                <img :src="blockuser.profileUrl"><span class="ml-1">{{blockuser.firstName}} {{blockuser.lastName}}</span>
                                            </div>
                                           
                                            <button class="btn btn-blue"  type="button" @click="unblockUser(blockuser,index)">Unblock</button>
                                        </li>
                                        <span v-if="blockUserList.length==0 ">No blocked contacts</span>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                  
                    </b-tabs>
                </b-card>

                 <div v-if="!companyName.isAdmin" class="ifnotAdminDataUsage">
                <div class="d-flex dataUsage">
                    <span>{{bytes(businessObject.dataUsage)}} /</span>
                    <span>{{bytes(businessObject.userUsageLimit)}}</span> 
                </div>
                <b-progress :value="businessObject.dataUsage" :max="businessObject.userUsageLimit" class="mb-1 blueProgress"></b-progress>
                
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>

import Header from "@/components/businessComponent/businessHeader.vue";
import firebase from "../firebaseInit";
import firebaseData from '../../utils/firebaseCollection.js'
import firebaseTimestamp from "firebase";
import fb from 'firebase';
import Swal from 'sweetalert2'
import {blockgetdata,blockList,getPlanUserData} from '../../businessUtills/getSidebarMedia.js';
import {getLoginUserData,getBotuserData} from "@/utils/getQueeryActiveChatList.js";
import {getSocialContactData2,checkIfRequestIsAlreadySend2,getFreePackageId2,getFreePlanObject2,getUsersData2,planChange2,updateusercollection2,getCompanies2,Invitation2} from '../../businessUtills/getQueeryActiveChatList.js'
import moment from 'moment';
import Vue from 'vue'
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'

import { mapGetters } from "vuex";

const db = firebase.firestore()

export default {
    name: "headerpage",
    components:
    {
        Header,
        firebaseData,
        SyncLoader
    },
    data() {
        return {
            activeTab:'Subscription Details',
            plans:[],
            showLoader:false,
            selected: 'USD',
            options: ['USD' ,'EUR' ,'GBP'],
            tabIndex:1,
            packages:[],
            readReceiptValue:true,
            userid:'',
            isSpinner:false,
            readValue:'',
            isAdmin:'',
            loginUser:'',
            chatObject:{
                firstName:'',
                lastName:'',
                profileUrl:'',
                userid:''
            },
            groupData:{},
            activeGroup:false,
            userData:{},
            activeChat:false,

            showSocialBuySucess:false,
            showBusinessBuySucess:false,

            currentSocialPlan:'',
            currentPassword:'',
            newPassword:'',
            confirmNewPassword:'',
            isSpinner:false,
            blockUserId:[],
            blockUserList:[],

            message:'',
            firstname:'',
            lastname:'',
            email:'',
            phone:'',
            iscustomerSupportSpinner:false,
            businessMenuActive :true,
            chatid:'',

            businessPlan:[],
            activetab:this.$route.params.activeTab,
            CompanyUserData : [],
            emailaddress:'',
            userEmail:[],
            senderId:'',
            receiverId:'',
            contactListArray:[],
            socialRequestArray:[],
            Comapnyadmin:'',
            Planobject:{},
            FreePlanObject:{},
            companyDocKey:'',
            planArray:[],
            isactivetab:this.$route.params.activetab,
            businessPlanData:[],
            newbusinessplan:[],
            isEdit:false,
            selectPlanOption:'',
            planData:[],
            send:true,
            dateString:'',
            todaydatestring:'',
            companyName:{},
            companyname :'',
            companyemail :'',
            companyaddress :'',
            companyName :'',
            CompanyEmail :'',
            CompanyAddress :'',
            inviteEmail:'',
            addstorageArray:[],
            selectedPlanForStorage:'',
            allPlanDetail:[],
            selectoption:{},
            indx:'',
            createCompanyName : [],
            errorMessage:'',
            addonArray:[],
            sendingMessageFlag:false,
            selectedChangePlan:[],
            moreUser:true,
            totalChangePlan:[],
            selected:{},
            companyDetails:{},
            plansAndLimits:[],
            addoneArray : [],
            businessObject:{},
            deactivatePlans : [],
            addStoragePlanId:[],
            companyAdminInfo:{},
            charecterErrorMessageForEmail:'',
            charecterErrorMessageForCompanyName:'',
            charecterErrorMessageForCompanyAddress:'',
            botUser:{}
            
        }
    },
    created(){
        //  debugger // eslint-disable-line
        var self = this;
        self.addonArray = []
        self.plansAndLimits = []
        getCompanies2(firebase.auth().currentUser.uid,res=>{
            db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).get().then(doc => {
                if(doc.data().currentSelectedCompany == ''){
                    Vue.prototype.$companyName = res[0]
                    self.companyName = res[0]
                }else{
                    var companies = doc.data().companies
                    companies.forEach(data => {
                        if(data.id == doc.data().currentSelectedCompany){
                            Vue.prototype.$companyName = data
                            self.companyName = data
                        }
                    })
                }
            }).then(() => {
                if(self.isactivetab == 'Subscription_Details'){
                    self.activeTab = 'Subscription Details'
                    self.tabIndex = 0
                }else{
                    self.activeTab =  'Read Receipts'
                    self.tabIndex = 0
                }
                self.init();
            })
        })
        self.userid = localStorage.getItem('customerDocKey');
        self.$axios.get(self.$WPbaseurl+'wp-json/business/addon-plan')
        .then((res)=>{
            self.addonArray = res.data;
        })
        self.$root.$on('getActiveComapnyList',() => {
            self.init();
            self.addoneArray = []
        })
        self.$root.$on('getCompanyAdmin',(val) => {
            self.Comapnyadmin = val.isAdmin
            if(val.isAdmin)
            {
                self.tabIndex=1
            }
            else
            {
                self.tabIndex=0
            }
        })
        getBotuserData(firebaseData.firebasecollection.USER,(res) => {
            self.botUser = res;
        })
    },
    watch : {
        $route : function (){
          this.init();
        }
    },
    methods: {
        // GET DATA(Shraddha)
        init(){
            // debugger // eslint-disable-line
            var self = this;
            try{

                self.addoneArray = []
                self.companyAdminInfo = {}
                self.Comapnyadmin = ''
                self.addstorageArray = []
                self.allPlanDetail = []
                self.businessPlanData = []
                 self.newbusinessplan = []
                 self.deactivatePlans = []
                    self.userid = localStorage.getItem('customerDocKey');
                    self.todaydatestring = moment(new Date()).format('DD-MM-YYYY')
                    var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                   
                    db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).onSnapshot(doc => {
                        // querySnapshot.forEach(doc =>{
                            // if(doc.data().id == Vue.prototype.$companyName.id){
                                self.companyAdminInfo = doc.data();
                                if(doc.data().addone_Array != undefined){
                                    doc.data().addone_Array.forEach(addon => {
                                        if(addon.addon_status != 'deactivated'){
                                            self.addoneArray = doc.data().addone_Array
                                        }
                                    })
    
                                }
                                var plans = doc.data().plan
                                    for(var i=0; i<plans.length;i++){
                                        if(plans[i].payment_status != 'free' && plans[i].plan_status != 'deactivated'){
                                            // self.addstorageArray.push(plans[i].plan_name);
                                            self.allPlanDetail.push(plans[i])
                                        }
                                        if(plans[i].plan_status != 'deactivated'){
                                            self.deactivatePlans.push(plans[i])
                                        }
                                        
                                        var time = plans[i].next_billing_date
                                        var dateString = moment(time).format('DD-MM-YYYY');
                                        self.dateString = moment(time).format('DD-MM-YYYY');
                                        var finaldate = dateString.split('-')[0]
                                        var finalmonth = parseInt(dateString.split('-')[1]) - 1
                                        var finalyear = dateString.split('-')[2]
                                        var monthname = months[finalmonth]
                                        var displaydate = finaldate+' '+monthname+' '+finalyear
                                        plans[i].next_billing_date = displaydate
                                        var dateobjects = {
                                            dateobject : parseInt(self.dateString.split('-')[0]),
                                            monthobject : parseInt(self.dateString.split('-')[1]),
                                            yearobject : parseInt(self.dateString.split('-')[2])
                                        }
                                        if(plans[i].dateObject == undefined){
                                            plans[i].dateObject = dateobjects
                                        }
                                        if(plans[i].nextbillingOriginaltime == undefined){
                                            plans[i].nextbillingOriginaltime = time
                                        }
                                  
                                        self.businessPlanData = plans
                                        self.newbusinessplan = plans
                                        const ids = self.newbusinessplan.map(o => o.plan_id)
                                        const filtered = self.newbusinessplan.filter(({plan_id}, index) => !ids.includes(plan_id, index + 1))
                                        self.newbusinessplan = filtered
                                        const idss = self.deactivatePlans.map(o => o.plan_id)
                                        const filtereds = self.deactivatePlans.filter(({plan_id}, index) => !idss.includes(plan_id, index + 1))
                                        self.deactivatePlans = filtereds
                                        
                                        // console.log('self.businessPlanData',self.businessPlanData,self.deactivatePlans,self.newbusinessplan,dateobjects)
                                   
                                }
    
                            // }
                        // })
                         
                    })
                    // db.collection(firebaseData.firebasecollection.USER).doc(self.userid).get().then(doc => {
                    //     if(doc.exists){
                    //         self.createCompanyName = []
                    //         var companies = doc.data().companies||[]
                    //         self.companyemail = doc.data().email
                    //         companies.forEach(data => {
                    //             if(data.id == Vue.prototype.$companyName.id){
                    //                 if(Vue.prototype.$companyName.id != '' && Vue.prototype.$companyName.id != undefined){
                    //                     self.Comapnyadmin = data.isAdmin  
                    //                 }
                    //             }
                    //             if(data.isAdmin == true){
                    //                 self.createCompanyName.push(data.companyName);
                    //             }
                    //         })
    
                    //         // console.log('self.createCompanyName',self.createCompanyName)
                    //     }
                    // })
                      
                getLoginUserData(firebaseData.firebasecollection.USER,(res) => {
                    self.readReceiptValue =res[Vue.prototype.$companyName.id+"_BusinessreadReceipts"]
                    self.readValue = res[Vue.prototype.$companyName.id+"_BusinessreadReceipts"]
                    self.imageUrl = res.profileUrl;
                    self.userData = res;
                    self.createCompanyName = []
                    var companies = res.companies||[]
                    self.companyemail = res.email
                    companies.forEach(data => {
                        if(data.id == Vue.prototype.$companyName.id){
                            if(Vue.prototype.$companyName.id != '' && Vue.prototype.$companyName.id != undefined){
                                self.Comapnyadmin = data.isAdmin  
                            }
                        }
                        if(data.isAdmin == true){
                            self.createCompanyName.push(data.companyName);
                        }
                    })
                    // console.log('self.userData',self.userData,self.imageUrl)
                }) 
                    blockgetdata(firebaseData.firebasecollection.USER,self.userid,Vue.prototype.$companyName.id,res => {
                        self.blockUserId = res
                        // console.log('res',res)
                        // console.log('blockUserId',self.blockUserId)
                        blockList(self.blockUserId,firebaseData.firebasecollection.USER,res=>{
                        self.blockUserList = res 
                        // console.log('blockUserList',self.blockUserList)
                        }) 
                    })
                    getPlanUserData(firebaseData.firebasecollection.COMPANY,Vue.prototype.$companyName.id,res=>{
                        self.CompanyUserData = res
                        // console.log('self.CompanyUserData',self.CompanyUserData)
                    })
                    db.collection(firebaseData.firebasecollection.USER).onSnapshot(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            if(doc.exists){
                                self.userEmail.push({
                                    'email':doc.data().email,
                                    'userid':doc.data().id
                                })
                            }
                        })
                        // console.log('self.userEmail',self.userEmail);
                    })
                    getSocialContactData2(firebaseData.firebasecollection.USER,self.userid,(res)=>{
                        self.contactListArray = res
                    })
                    checkIfRequestIsAlreadySend2(Vue.prototype.$companyName.id,res => {
                        self.socialRequestArray = res
                        
                    })
                    getFreePackageId2(firebaseData.firebasecollection.COMPANY,Vue.prototype.$companyName.id,res => {
                        self.Planobject = res
                    })
                
                    // CHECK LOGIN USER IS ADMIN OR CUSTOMER(Shraddha)
                    self.loginUser = localStorage.getItem("IsLoginUser")
                    //console.log('loginUser',self.loginUser);
                    if(this.loginUser == 'Admin'){
                        self.isAdmin = true
                    }else{
                        self.isAdmin = false
                    }
    
                db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc=>{
                    // console.log('doc.data()doc.data()',doc.data())
                    self.plansAndLimits = []
                    self.companyDetails=doc.data()
                    // console.log('self.companyDetailsself.companyDetails',self.companyDetails,self.plansAndLimits)
                    self.companyDetails.plan.forEach(data=>{
                        if(data.plan_status != 'deactivated'){ 
                                if(self.plansAndLimits.length==0 )
                                {
                                    self.plansAndLimits.push({
                                        plan_id:data.plan_id,
                                        noOfUser:parseInt(data.noOfUser),
                                        usersAssigned:1,
                                        exceeded:false,
                                        status:data.payment_status,
                                        
                                    })
                                }
                                var matchFound = false
                                self.plansAndLimits.forEach((data2,index)=>{
                                    if(data2.plan_id == data.plan_id )
                                    {
                                        matchFound = true
                                        self.plansAndLimits[index].noOfUser=data2.noOfUser+parseInt(data.noOfUser)
                                    }
                                })
                                if(!matchFound)
                                {
                                    self.plansAndLimits.push({
                                        plan_id:data.plan_id,
                                        noOfUser:data.noOfUser,
                                        usersAssigned:1,
                                        exceeded:false,
                                        status:data.payment_status
                                    })
                                }
                        }
                    })
                    // console.log('self.plansAndLimits',self.plansAndLimits)
                    if(self.companyName.isAdmin == false){
                        db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc => {
                            var users = doc.data().users
                            users.forEach(datas => {
                                if(datas.id == firebase.auth().currentUser.uid){
                                    self.businessObject = datas
                                }
                            })
                        })
                    }
                })
            // console.log('self.Comapnyadmin',self.Comapnyadmin)
            } catch (error) {
                console.log("ERROR: ", error);
                self.$router.replace("/chat");
            }
        },
        convertDate(adoneDate){
            var self = this;
            var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            var time = adoneDate
            var dateString = moment(time).format('DD-MM-YYYY');
            var finaldate = dateString.split('-')[0]
            var finalmonth = parseInt(dateString.split('-')[1]) - 1
            var finalyear = dateString.split('-')[2]
            var monthname = months[finalmonth]
            var displaydate = finaldate+' '+monthname+' '+finalyear
            adoneDate = displaydate
            return adoneDate;
        },
        decodeHtmlCharCodes(str) { 
            return str.replace(/(&#(\d+);)/g, function(match, capture, charCode) {
                return String.fromCharCode(charCode);
            });
        },
        //CLOSE COMPANY POPUP(Shraddha)
        closeFire(e){
            var self=this;
            if(!self.showLoader)
            {
                self.companyname = '',
                self.companyaddress = '',
                self.$refs.log.reset()
            }
            else
            {
                e.preventDefault()
            }
        },
        // CHECK CHARECTER LIMIT IN EMAIL (Shraddha)
        checkChrecterLengthEmail(groupName){
            // debugger // eslint-disable-lines
            var self = this;
            // self.emailaddress=groupName.replace(/[^A-Za-z]/ig,'')
            if(groupName.length < 51){
            self.emailaddress = groupName
            self.charecterErrorMessageForEmail = '';
        }else{
            self.charecterErrorMessageForEmail = 'The email may not be greater than 50 characters';
            self.emailaddress = (groupName).slice(0, 50)
        }
        },
        checkChrecterLengthCompanyName(groupName){
            // debugger // eslint-disable-lines
            var self = this;
            if(groupName.length < 51){
            self.companyname = groupName
            self.charecterErrorMessageForCompanyName = '';
            }else{
            self.charecterErrorMessageForCompanyName = 'The company name may not be greater than 50 characters';
            self.companyname = (groupName).slice(0, 50)
            }
        },
        checkChrecterLengthCompanyAddress(groupName){
            // debugger // eslint-disable-lines
            var self = this;
            if(groupName.length < 101){
            self.companyaddress = groupName
            self.charecterErrorMessageForCompanyAddress = '';
            }else{
            self.charecterErrorMessageForCompanyAddress = 'The company address may not be greater than 100 characters';
            self.companyaddress = groupName.slice(0,100)
            }
        }, 
        //CREATE COMPANY(Shraddha)
        createCompany(){
            // debugger // eslint-disable-line
            var self=this
            if(self.charecterErrorMessageForCompanyName == '' && self.charecterErrorMessageForCompanyAddress == ''){
                self.companyname = self.companyname.toLowerCase()
                if(self.errorMessage == ''){
                self.showLoader=true
                localStorage.setItem('CompanyDetail','');
                self.isSpinner = true
                // console.log(self.companyname,self.companyemail,self.companyaddress)
                self.companyName = self.companyname,
                self.CompanyEmail = self.companyemail,
                self.CompanyAddress = self.companyaddress

            
                var companydetail = {
                    'companyname':self.companyname,
                    'CompanyEmail':self.CompanyEmail,
                    'CompanyAddress':self.CompanyAddress,
                    'isAdmin':true,
                    
                }
                localStorage.setItem('CompanyDetail',JSON.stringify(companydetail))
                // self.buyBusinessPackage();
                self.$axios.post(self.$WPbaseurl+'wp-json/business/business-plan',{company_id:'',company_name:self.companyname,company_email:self.CompanyEmail,company_address:self.CompanyAddress,wp_user_id:self.userData.wp_id,user_id:self.userData.id})
                .then((res)=>{
                    if(Vue.prototype.$adminDetails.userUpgradeNotification && self.userData.companiesOwned == undefined || Vue.prototype.$adminDetails.userUpgradeNotification && self.userData.companiesOwned.length == 0){
                        self.$axios.post(self.$apiurl+'sendMailToAdmin',{adminId:Vue.prototype.$adminDetails.id,Message:`${self.userData.firstName} ${self.userData.lastName} has upgraded to Business Account.`,operation:'upgrade'})
                        .then(()=>{
                        res.send({saved:true})
                        })
                    }
                    // console.log('businessplan',res)
                
                    self.$bvModal.hide('modal-company');
                    self.companyname = '',
                    self.companyemail = '',
                    self.companyaddress = ''
                    window.location.href = self.$WPbaseurl+`business-plan/?uid=${self.userData.wp_id}&clear-cart`;
                    self.isSpinner = false
                    self.$refs.log.reset()
                    self.showLoader=false
                })
                }
            }
        },
        // CONVERT BYTES VALUE IN TO GB (Shraddha)
        bytes(x){
            const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            let l = 0, n = parseInt(x, 10) || 0;
            while(n >= 1024 && ++l){
                n = n/1024;
            }
            return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        },
        niceBytes(x){
            let gbValue = (x / (1024 * 1024 * 1024)).toFixed(2);
            return (gbValue + 'GB')
        },   
       
        // READ RECIPT VALUE CHANGED(Shraddha)
        saveReadRecipt(){
            // //debugger // eslint-disable-line
            var self = this;
            self.isSpinner == true
            var companyid  = Vue.prototype.$companyName.id+"_BusinessreadReceipts"
            // console.log('companyid',companyid)
            db.collection(firebaseData.firebasecollection.USER).doc(self.userid).update({
                [companyid] : self.readReceiptValue
            }).then(() => {
                if(self.readReceiptValue == true){
                    self.$root.$emit("audioplay");
                    self.$notify({
                        title:"Success",
                        text:'Readreceipt is on successfully',
                        type:"success"
                    })
                    self.isSpinner = false
                }else{
                    self.$root.$emit("audioplay");
                    self.$notify({
                        title:"Success",
                        text:'Readreceipt is off successfully',
                        type:"success"
                    })
                    self.isSpinner = false 
                }
            })
        },
        //CANCEL READRECIPT ( Shraddha)
        cancelReadRecipt(){
            var self=this;
            self.readReceiptValue = self.readValue
        },
        unblockUser(blockuser,index){
            // debugger // eslint-disable-line
            var self=this
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
            Swal.fire({
                title: 'Are you sure you want to unblock'+' '+blockuser.firstName+' '+blockuser.lastName+ '?',
                type: "warning",
                customClass:{
                    container :"business_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: "#77bef6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, unblock it!",
            }).then((result) => {
                if (result.value) {
                     self.blockUserList.splice(index,1);
                    self.blockUserId.splice(index,1);
                    // console.log(firebase.auth().currentUser.uid)
                    db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockuser.id).get().then(doc => {
                        //console.log('doc',doc)
                        if(doc.data().chatId != undefined){
                            self.chatid = doc.data().chatId
                        }else{
                        self.chatid = '' 
                        }
                    }).then(() => {
                         var blockArray = []
                        if(self.chatid != ''){
                            db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockuser.id).set({
                                id: blockuser.id,
                                chatId : self.chatid
                            }).then(()=>{
                                db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockuser.id).delete().then(()=>{
                                self.$root.$emit("audioplay");
                                self.$notify({
                                        title:"Success",
                                        text:blockuser.firstName+' '+blockuser.lastName+' unblocked.',
                                        type:"success"
                                    }) 
                                     self.$root.$emit('activeGroup' ,'yes');
                                })
                            })
                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatid).get().then(doc => {
                                blockArray = doc.data().blockUserArray
                                var index = blockArray.findIndex(product => {
                                    return product.blockBy == firebase.auth().currentUser.uid && product.blockTo ==  blockuser.id
                                })
                                blockArray.splice(index,1);
                            }).then(() => {
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatid).update({
                                    blockUserArray : blockArray,
                                    
                                })
                            })
                        }else{
                        db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockuser.id).set({
                                id: blockuser.id,
                            }).then(()=>{
                                db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockuser.id).delete().then(()=>{
                                self.$root.$emit("audioplay");
                                self.$notify({
                                        title:"Success",
                                        text:blockuser.firstName+' '+blockuser.lastName+' unblocked.',
                                        type:"success"
                                    }) 
                                })
                                //  self.$root.$emit('activeGroup' ,'yes');
                            }) 
                        }
                    })
                }
            })
        },
        // ONLY ENTER NUMBER(PHONE NUMBER) (Shraddha)
        isCharAt(evt)
        {
        // //debugger // eslint-disable-line
        const char = String.fromCharCode(evt.keyCode);
            if (!/[0-9 ||.]/.test(char)) {
                evt.preventDefault();
            }
        },
      
        // SEND REQUEST TO JOIN COMPANY(Shraddha)
        sendRequest(){
            // debugger // eslint-disable-line
            var self=this;
            if(self.charecterErrorMessageForEmail == ''){
                self.sendingMessageFlag = true
                self.isSpinner = true
                self.receiverId = ''
                self.receiverid = ''
                self.emailaddress = self.emailaddress.toLowerCase()
                self.inviteEmail = self.emailaddress
                // console.log('Vue.prototype.$companyName',Vue.prototype.$companyName)
                getFreePlanObject2(firebaseData.firebasecollection.COMPANY,Vue.prototype.$companyName.id,res => {
                self.FreePlanObject = ''
                self.FreePlanObject = res
                // console.log(' self.FreePlanObject', self.FreePlanObject)
                self.Planobject = self.FreePlanObject
                // console.log('self.FreePlanObject',self.FreePlanObject,self.Planobject)
                getUsersData2(firebaseData.firebasecollection.USER,self.emailaddress,res=>{
                    // console.log('getUserData',res)
                    self.receiverId = res.id
                    self.receiverid = self.receiverId
                    
                    if(self.emailaddress != ''){
                        const db = firebase.firestore()
                        self.senderId = localStorage.getItem("customerDocKey");
                        var senderEmail = JSON.parse(localStorage.getItem("rememberUser")).email

                        self.isInArray =  self.contactListArray.some(function(item) {
                            return item == self.emailaddress
                        });

                        var emailArray =  self.userEmail.some(function(item) {
                            return item.email == self.emailaddress
                        });

                        
                    // IF INPUT HAS AN EMAIL ADDRESS(Shraddha)
                        if(emailArray == true){
                                if(senderEmail != self.emailaddress){
                                    if(self.isInArray == false){
                                        // var requestArray =  self.socialRequestArray.some(function(item) {
                                        //     return item.senderId == self.senderId && item.receiverId == self.receiverId
                                        // });
                                        var requestIncomingArray =  self.socialRequestArray.some(function(item) {
                                            return item == self.inviteEmail 
                                        });
                                        if(requestIncomingArray == false){
                                            // if(requestArray == false){
                                                if(self.receiverId != ''){
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).get().then(doc => {
                                                    if(doc.exists){
                                                        if(doc.data().receiverId == self.receiverId || doc.data().senderId == self.senderId){
                                                            //
                                                        }else{
                                                            //
                                                        }
                                                    }
                                                })
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).add({
                                                    id:'',
                                                    receiverId:self.receiverId,
                                                    senderId:self.senderId,
                                                    status:0
                                                }).then( (docid) => {
                                                    // console.log(docid)
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).doc(docid.id).update({
                                                        id:docid.id
                                                    }).then(() => {
                                                        self.$axios.post(self.$apiurl+'sendEmail',{email:self.emailaddress,type:'Request',requestType:'Business',userId:self.senderId,companyId:Vue.prototype.$companyName.id,receiverId:self.receiverid,planId:self.FreePlanObject.plan_id})
                                                        .then((res)=>{
                                                            // self.$axios.post(self.$apiurl+'sendRequestUserNotification',{receiverId:self.receiverid,userData : `${self.userData.firstName} ${self.userData.lastName}`,companyData:Vue.prototype.$companyName})
                                                            // .then((res)=>{
                                                            // console.log('resresres',res)
                                                            // })
                                                            // console.log('resresres',res)
                                                            self.isSpinner = false;
                                                            self.$refs.emailRequest.reset()
                                                        })
                                                        self.$root.$emit("audioplay");
                                                        self.$notify({
                                                            title:"Success",
                                                            text:'Invite sent to '+self.inviteEmail,
                                                            type:"success",
                                                            duration: 10000,
                                                        })
                                                        self.sendingMessageFlag = false
                                                        db.collection(firebaseData.firebasecollection.USER)
                                                        .doc(self.receiverid)
                                                        .get().then(doc=>{
                                                            
                                                            var users = []
                                                            if(doc.exists)
                                                            {
                                                                db.collection('companies')
                                                                .doc(Vue.prototype.$companyName.id)
                                                                .get().then(doc2=>{
                                                                    var plan = doc2.data().plan
                                                                    users = doc2.data().users
                                                                    plan.forEach(plans => {
                                                                    if(plans.plan_id == self.FreePlanObject.plan_id && plans.order_id == self.FreePlanObject.order_id){
                                                                            users.push({
                                                                                dataUsage : 0,
                                                                                id:self.receiverid,
                                                                                profileUrl :doc.data().profileUrl,
                                                                                name:`${doc.data().firstName} ${doc.data().lastName}`,
                                                                                planName:plans.plan_name,
                                                                                planId:self.FreePlanObject.plan_id,
                                                                                email:self.inviteEmail,
                                                                                phone:'',
                                                                                usedSelfie : 0,
                                                                                selfieUsers:[],
                                                                                noOfSelfie:plans.noOfSelfie,
                                                                                status:'Pending to accept',
                                                                                userUsageLimit :0,
                                                                                selfieTime : '',
                                                                                max_file_size : plans.max_file_size,
                                                                                device_plan:plans.device_plan,
                                                                            })
                                                                        }
                                                                    })
                                                                    //  console.log('usersArr',users)
                                                                        db.collection('companies')
                                                                        .doc(Vue.prototype.$companyName.id)
                                                                        .update({
                                                                            users:users
                                                                        }).then(() => {
                                                                             self.init();
                                                                            return false
                                                                        })
                                                                        .catch(error=>{
                                                                            console.log(error)
                                                                            
                                                                        })
                                                                       
                                                                })


                                                            }
                                                        })
                                                        self.emailaddress = '';
                                                        self.$refs.emailRequest.reset()
                                                        self.$bvModal.hide('modal-centers');
                                                        self.$root.$emit('getRequest','yes');
                                                         self.sendingMessageFlag = false
                                                          self.init();
                                                    })
                                                })
                                            }
                                        }else{
                                            self.emailaddress = ''
                                            self.isSpinner = false;
                                            self.$refs.emailRequest.reset()
                                            self.$root.$emit("audioplay");
                                            self.$notify({
                                                title:"ERROR",
                                                text:'User already exists',
                                                type:"error"
                                            })
                                            self.sendingMessageFlag = false
                                             self.init();
                                        }
                                    }else{
                                        self.emailaddress = ''
                                        self.isSpinner = false;
                                        self.$refs.emailRequest.reset()
                                        self.$root.$emit("audioplay");
                                        self.$notify({
                                        title:"ERROR",
                                        text:'User  already exists',
                                        type:"error"
                                        })
                                         self.sendingMessageFlag = false
                                          self.init();
                                    }
                                }else{
                                self.emailaddress = ''
                                self.isSpinner = false;
                                self.$refs.emailRequest.reset()
                                self.$root.$emit("audioplay");
                                self.$notify({
                                    title:"ERROR",
                                    text:'You can not send request to yourself',
                                    type:"error"
                                })
                                 self.sendingMessageFlag = false
                                  self.init();
                                }
                        }else{
                            var users = []
                            var requestIncomingArray =  self.socialRequestArray.some(function(item) {
                                return item == self.inviteEmail 
                            });
                            if(requestIncomingArray == false){
                                // console.log(self.$apiurl,self.$apiurl+'sendEmail',self.FreePlanObject)
                                self.$axios.post(self.$apiurl+'sendEmail',{email:self.emailaddress,type:'Invitation',requestType:'Business',userId:self.senderId,companyId:Vue.prototype.$companyName.id,planId:self.FreePlanObject.plan_id})
                                .then((res)=>{
                                    // console.log('resresres',res,self.receiverId)
                                    self.$refs.emailRequest.reset()
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSINVITE).add({
                                        byMail:senderEmail,
                                        byPhone:'',
                                        id:'',
                                        sendBy:self.senderId
                                    })
                                    .then((docref) => {
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSINVITE).doc(docref.id).update({
                                            id:docref.id
                                        })
                                        }).then(() => {
                                            self.$root.$emit("audioplay");
                                            self.$notify({
                                                title:"SUCCESS",
                                                text:'Invitation sent to '+self.inviteEmail,
                                                type:"success"
                                            })
                                            self.sendingMessageFlag = false
                                            self.emailaddress = '';
                                            self.$refs.emailRequest.reset()
                                            self.isSpinner = false; 
                                             self.init();
                                            return false
                                        })
                                })
                            }else{
                                self.emailaddress = ''
                                self.isSpinner = false;
                                self.$refs.emailRequest.reset()
                                self.$root.$emit("audioplay");
                                self.$notify({
                                    title:"ERROR",
                                    text:'Invitation already send.',
                                    type:"error"
                                })
                                 self.sendingMessageFlag = false 
                                  self.init();
                            }
                        }
                        
                    }
                })
            })
            }
        },
        //UPGRADE BUSINESS PAID PLAN(Shraddha)
        upgradeplan(){
            // debugger // eslint-disable-line
            var self=this;
            self.showLoader=true
            self.$axios.post(self.$WPbaseurl+'wp-json/business/business-plan',{company_id:Vue.prototype.$companyName.id,company_name:Vue.prototype.$companyName.companyName,company_email:'',company_address:'',wp_user_id:self.userData.wp_id,user_id:self.userData.id})
            .then((res)=>{
                // console.log('businessplan',res)
                if(res.data.status == 1){
                    window.location.href = self.$WPbaseurl+`business-plan/?uid=${self.userData.wp_id}&clear-cart`;
                    
                }
            })
        },
        // EDIT USERMANAGMENT PLAN (Shraddha)
        editUsermanagmentPlan(){
        //    debugger // eslint-disable-line
           var self=this;
           self.isEdit = true;
        //    console.log('abc@@@',self.businessPlanData,self.CompanyUserData,self.plansAndLimits)
            self.businessPlanData.forEach(planData=>{
                self.CompanyUserData.forEach(users=>{
                    if(users.planId == planData.plan_id)
                    {
                        users.selectplanOption = planData
                    }
                   
                })
            })
            // console.log('self.CompanyUserDataself.CompanyUserData',self.CompanyUserData,self.plansAndLimits)
        },
        //UPDATE USER IN PLAN (Shraddha)
        updateUserInPlan(){
            // debugger // eslint-disable-line
            var self=this;
            var response = []
            var isallow = false;
            console.log('plansAndLimitsData',self.plansAndLimits);
            // console.log("self.CompanyUserData",self.CompanyUserData);
            if(self.plansAndLimits && self.plansAndLimits.length>0){
                for(let i=0;i<self.plansAndLimits.length;i++){
                    if(self.plansAndLimits[i].plan_id == self.companyAdminInfo.plan_id){
                        self.plansAndLimits[i].usersAssigned = 1;
                    }else{
                        self.plansAndLimits[i].usersAssigned = 0;
                    }
                    self.plansAndLimits[i].isPlanUpdateAllow = false;
                }   
            }
            if(self.CompanyUserData && self.CompanyUserData.length>0){
                if(self.plansAndLimits && self.plansAndLimits.length>0){
                    for(let j=0;j<self.CompanyUserData.length;j++){
                        for(let k=0;k<self.plansAndLimits.length;k++){
                            if(self.plansAndLimits[k].plan_id == self.CompanyUserData[j].selectplanOption.plan_id){
                                
                                self.plansAndLimits[k].usersAssigned += 1;
                                if(self.plansAndLimits[k].usersAssigned <= self.plansAndLimits[k].noOfUser && self.plansAndLimits[k].status != "free"){
                                    self.plansAndLimits[k].isPlanUpdateAllow = true;
                                }
                                else if(self.plansAndLimits[k].status == "free"){
                                    self.plansAndLimits[k].isPlanUpdateAllow = true;
                                }
                                else{
                                    self.plansAndLimits[k].isPlanUpdateAllow = false;
                                }
                            }
                        }
                    }
                }
            }

            var is_allow_to_update=true;
            for(let p_i=0;p_i<self.plansAndLimits.length;p_i++){
                if(self.plansAndLimits[p_i].status != 'free'){
                    if(self.plansAndLimits[p_i].usersAssigned > self.plansAndLimits[p_i].noOfUser){
                        var is_allow_to_update=false;
                    }
                }
            }
            // console.log('is_allow_to_update',is_allow_to_update);



            // console.log('NEW plansAndLimitsData',self.plansAndLimits);
            // var index = self.plansAndLimits.findIndex(item => {
            //     return item.isPlanUpdateAllow == true 
            // })
            // console.log("index,,,,,",index);
            // if(index != -1 ){
            //     isallow = true;
            // }
            // let isInArray = self.plansAndLimits.some(function(item) {
            //     return item.isPlanUpdateAllow == false 
            // });
            
        //    console.log('isInArray',isInArray);
            if(is_allow_to_update == true){
                self.CompanyUserData.forEach(data => {
                    // if(data.error == false){
                        // console.log('self.CompanyUserDataself.CompanyUserData',self.CompanyUserData)
                        // console.log ('selectPlanOption',self.CompanyUserData)
                        planChange2(self.CompanyUserData,res=>{
                            // console.log ('selectP',res,Vue.prototype.$companyName.id)
                            response = res
                            updateusercollection2(res,firebaseData.firebasecollection.USER,Vue.prototype.$companyName,ress => {
                                // console.log('resing',ress)
                                // setTimeout(()=>{
                                    
                                    // console.log('selected',response,Vue.prototype.$companyName.id)
                                    db.collection('companies').doc(Vue.prototype.$companyName.id).update({
                                        users:response
                                    })
                                    .catch((error)=>{
                                        // console.log('Error companies',error)
                                        self.$root.$emit("audioplay");
                                        self.$notify({
                                            title:"Error companies",
                                            text:error.message,
                                            type:"error",
                                            duration: 10000
                                        })
                                    })
                                // },500)
                            })
                           
                        })
                       
                    // }else{
                    //     self.$notify({  
                    //         title:"Error",
                    //         text:'You can not enter more user then your plan limit.',
                    //         type:"error",
                    //         duration: 10000
                    //     })
                    // }
                })
                self.isEdit = false;
                self.$root.$emit("audioplay");
                self.$notify({
                    title:"Success",
                    text:'Seleceted users plan changed successfully.',
                    type:"success",
                    duration: 10000
                })
            }
            if(is_allow_to_update == false){
                self.$root.$emit("audioplay");
                self.$notify({  
                    title:"Error",
                    text:'You can not enter more user then your plan limit.',
                    type:"error",
                    duration: 10000
                })
            }
        },
        // CHANGE PLAN NAME(Shraddha)
        changeplanname(planData,index){
            // debugger // eslint-disable-line
           var self=this;
            self.plansAndLimits.forEach(data2=>{
                var count = 0
                self.CompanyUserData.forEach(data=>{
                    if(data.selectplanOption.plan_id==data2.plan_id)
                    {
                        count++
                    }
                    data2.usersAssigned=count
                })
            })
           var found = false
            for (let i = 0; i < self.selectedChangePlan.length; i++) {   
                if(self.selectedChangePlan[i].index == index)
                {
                    self.selectedChangePlan.splice(i,1)
                    self.selectedChangePlan.push({'planData':planData,'index':index})
                          
                    found = true
                }
            }
            if(!found)
            {
                self.selectedChangePlan.push({
                    'planData':planData,
                    'index':index
                });
            }
            // console.log('selectedPlan',self.selectedChangePlan)
            self.selectoption = planData
            self.indx = index
            // console.log(planData)
            if(planData.payment_status != 'free'){
                db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc => {
                    var noOfUserInPlan = []
                    var noofuser = 0
                    var users = doc.data().users
                    var plans = doc.data().plan
                    plans.forEach(plandata => {
                        if(plandata.plan_id == planData.plan_id  && plandata.plan_status != 'deactivated'){
                            noofuser = noofuser + Number(plandata.noOfUser)
                        }
                        
                        self.selectedChangePlan.forEach(datas => {
                            if(datas.planData.plan_id == plandata.plan_id && plandata.plan_status != 'deactivated' ){
                                self.totalChangePlan.push(datas)
                            }
                            
                            
                        })
                       
                    })
                   
                    users.forEach(data => {
                        if(data.planId == planData.plan_id){
                            noOfUserInPlan.push(data)
                        }
                    })
                  
                    // console.log('changeplanuser',noofuser,noOfUserInPlan.length,self.totalChangePlan.length)
                   
                        if(noofuser >= self.totalChangePlan.length){
                            if(noofuser > noOfUserInPlan.length){
                                self.CompanyUserData[index].error = false
                                self.send = true
                               
                            }else{
                                self.send = false
                                self.CompanyUserData[index].error = true
                                
                            }
                        }else{
                           self.send = false 
                           self.CompanyUserData[index].error = true
                          
                        }
                    // console.log('self.send',self.send)
                }).then(() => {
                        
                        self.totalChangePlan = []
                })
            }else{
               self.send = true 
            //    self.selectedChangePlan = []
            //    self.totalChangePlan = []
            }
        },
        // CCHECK IF PLAN IS EXPIRED OR NOT(Shraddha)
        beforeDateExpired(dateexpired){
            var todayDatesplit = new Date().getDate();
            var todayMonthsplit = new Date().getMonth()+1;
            var todayYearsplit = new Date().getFullYear();
      
            var result = false
     
            if(todayDatesplit > dateexpired.dateobject && todayMonthsplit >= dateexpired.monthobject && todayYearsplit >= dateexpired.yearobject)
            {
                result = true
            }
            else
            {
                result = false
            }
            
            return result
        },
        //DEACTIVATE PLAN (Shraddha)
        deactivatePlan(businessplan){
        //    debugger // eslint-disable-line
           var self=this;
           var companies = [];
           var found = false;
           var proCounter = 0;
           var ProUserInPlan = 0;
           var ProUserInUsers = 0;
            if(self.companyAdminInfo.plan_id == businessplan.plan_id ){
                var plans = self.companyAdminInfo.plan;
                var users = self.companyAdminInfo.users;
                plans.forEach(planData => {
                    if(planData.plan_id == 250 && planData.plan_status != 'deactivated'){
                       found = true;
                    }
                    if(planData.plan_id == 249 && planData.plan_status != 'deactivated'){
                        proCounter++;
                    }
                })
                if(found){
                   
                    self.$notify({
                        title:"Error",
                        text:'You can deactivated Pro plan before deactivate other plan.',
                        type:"error",
                        duration: 10000
                    })
                }
                if(!found){
                //    console.log('proCounter',proCounter)
                   if(proCounter == 1){
                     getFreePlanObject2(firebaseData.firebasecollection.COMPANY,Vue.prototype.$companyName.id,res => {
                        self.FreePlanObject = ''
                        self.FreePlanObject = res
                            const Toast = Swal.mixin({
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                            Swal.fire({
                                title: "Are you sure you want to deactivate"+' '+businessplan.plan_name+' '+"plan?",
                                type: "warning",
                                customClass:{
                                    container :"business_confirmation_popup"
                                },
                                showCancelButton: true,
                                confirmButtonColor: "#77bef6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Ok",
                            }).then((result) => {
                                if (result.value) {
                                    var sameplan = []
                                    var users = []
                                    var freeplanobject = {}
                                    var numberOfUser = 0
                                    var numberOfUserAdd = 0
                                     db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                                        plan_id : self.FreePlanObject.plan_id,
                                        plan_name: self.FreePlanObject.plan_name,
                                        dataUsage: 0,
                                        device_plan: self.FreePlanObject.device_plan,
                                        file_type_plan: self.FreePlanObject.file_type_plan,
                                        max_file_size: self.FreePlanObject.max_file_size,
                                        messages_history: self.FreePlanObject.messages_history,
                                        selfieTime : new Date(),
                                        selfieUsers: [],
                                        usedSelfie: 0,
                                        userUsageLimit: self.FreePlanObject.userUsageLimit,
                                        noOfSelfie:self.FreePlanObject.noOfSelfie,
                                    }).then(() => {
                                    // console.log(businessplan);
                                        db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc=>{
                                        var plans = doc.data().plan
                                        var assignuser = doc.data().users
                                        plans.forEach(data => {
                                            if(data.plan_id == businessplan.plan_id && data.plan_status != 'deactivated' && data.payment_status == 'paid' ){
                                                sameplan.push(data)
                                            }
                                            if(data.payment_status == 'free'){
                                                freeplanobject = data
                                            }

                                        })
                                        assignuser.forEach(user => {
                                            if(user.planId == businessplan.plan_id){
                                                users.push(user);
                                            }
                                        })
                                        // console.log('sameplan',sameplan,sameplan.length,users,users.length);
                                        if(users.length == 0){
                                            self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                                                wp_user_id:self.userData.wp_id,
                                                user_id:self.userData.id,
                                                company_id:Vue.prototype.$companyName.id,
                                                plan_id:businessplan.plan_id,
                                                order_id:businessplan.order_id,
                                                freeplan_orderid:'',
                                                plan_type:'business'
                                            })
                                            .then((res)=>{
                                                console.log(res)
                                                self.$root.$emit("audioplay");
                                                self.$notify({
                                                    title:"Success",
                                                    text:businessplan.plan_name+' is deactivate successfully.',
                                                    type:"success",
                                                    duration: 10000
                                                })
                                                if(self.deactivatePlans.length >= 2){
                                                    var index = self.deactivatePlans.findIndex(product => {
                                                        return product.payment_status == 'paid' && product.plan_id == businessplan.plan_id
                                                    })
                                                    self.deactivatePlans.splice(index,1);
                                                }
                                                // console.log(self.deactivatePlans)
                                            }) 
                                        
                                        }else{
                                            if(sameplan.length == 1){
                                                // console.log('sdsadaaadadaz',users.length)
                                                const Toast = Swal.mixin({
                                                    toast: true,
                                                    position: "top-end",
                                                    showConfirmButton: false,
                                                    timer: 3000,
                                                });
                                                Swal.fire({
                                                    title: "Your plan is deactivated so user will be move into"+' '+freeplanobject.plan_name+' '+"plan",
                                                    type: "warning",
                                                    customClass:{
                                                        container :"business_confirmation_popup"
                                                    },
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#77bef6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: "Ok",
                                                }).then((result) => {
                                                    if (result.value) {
                                                        // console.log(businessplan,freeplanobject);
                                                        assignuser.forEach(users => {
                                                            if(users.planId == businessplan.plan_id){
                                                                users.planId = freeplanobject.plan_id
                                                                users.planName = freeplanobject.plan_name
                                                                users.dataUsage = 0
                                                                users.noOfSelfie = freeplanobject.noOfSelfie
                                                                users.max_file_size = freeplanobject.max_file_size
                                                                users.selfieTime = new Date()
                                                                users.userUsageLimit = freeplanobject.userUsageLimit
                                                                users.usedSelfie = 0
                                                                users.selfieTime = [];
                                                            }
                                                        })
                                                        db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                                                            users : assignuser
                                                        }).then(() => {
                                                            assignuser.forEach(userdata => {
                                                                if(userdata.id != '' && userdata.planId == freeplanobject.plan_id){
                                                                    db.collection(firebaseData.firebasecollection.USER).doc(userdata.id).get().then(docs => {
                                                                    companies = docs.data().companies || []
                                                                        companies.forEach(cmp => {
                                                                            if(cmp.id == Vue.prototype.$companyName.id && cmp.planId == businessplan.plan_id){
                                                                            cmp.planId = freeplanobject.plan_id
                                                                            cmp.planName = freeplanobject.plan_name
                                                                            }
                                                                    })
                                                                    }).then(() => {
                                                                        db.collection(firebaseData.firebasecollection.USER).doc(userdata.id).update({
                                                                            companies:companies
                                                                        })
                                                                    }) 
                                                                }
                                                            })
                                                        
                                                            self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                                                                wp_user_id:self.userData.wp_id,
                                                                user_id:self.userData.id,
                                                                company_id:Vue.prototype.$companyName.id,
                                                                plan_id:businessplan.plan_id,
                                                                order_id:businessplan.order_id,
                                                                freeplan_orderid:'',
                                                                plan_type:'business'
                                                            })
                                                            
                                                                // self.addoneArray.forEach(data => {
                                                                //     if(data.plan_id == businessplan.plan_id){

                                                                //     }
                                                                // })
                                                                
                                                                
                                                            .then((res)=>{
                                                                if(self.deactivatePlans.length >= 2){
                                                                    var index = self.deactivatePlans.findIndex(product => {
                                                                        return product.payment_status == 'paid' && product.plan_id == businessplan.plan_id
                                                                    })
                                                                    self.deactivatePlans.splice(index,1);
                                                                
                                                                    // console.log(self.deactivatePlans,res)
                                                                    self.$root.$emit("audioplay");
                                                                    self.$notify({
                                                                        title:"Success",
                                                                        text:businessplan.plan_name+' is deactivate successfully.',
                                                                        type:"success",
                                                                        duration: 10000
                                                                    })
                                                                }
                                                                    if(self.deactivatePlans.length != 1){
                                                                        self.addoneArray.forEach(data => {
                                                                            if(data.plan_id == businessplan.plan_id){
                                                                                self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                                                                                    wp_user_id:self.userData.wp_id,
                                                                                    user_id:self.userData.id,
                                                                                    company_id:data.company_id,
                                                                                    plan_id:data.plan_id,
                                                                                    order_id:data.order_id,
                                                                                    freeplan_orderid:'',
                                                                                    plan_type:'addon'
                                                                                }).then((res) => {
                                                                                    console.log(res)
                                                                                })
                                                                            }
                                                                        })
                                                                    }
                                                                
                                                            }) 
                                                        })
                                                    
                                                    }
                                                })
                                            
                                            }else{
                                                sameplan.forEach(users => {
                                                numberOfUser = numberOfUser + users.noOfUser
                                                })
                                                // console.log('numberOfUser',numberOfUser,users.length,businessplan.noOfUser)
                                                var remainingNoOfUser = Number(numberOfUser) - Number(businessplan.noOfUser)
                                                var moveUser = Number(remainingNoOfUser) - Number(users.length)
                                                var movingUser = Number(users.length) - Number(remainingNoOfUser)
                                                    if(users.length <= remainingNoOfUser){
                                                    self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                                                            wp_user_id:self.userData.wp_id,
                                                            user_id:self.userData.id,
                                                            company_id:Vue.prototype.$companyName.id,
                                                            plan_id:businessplan.plan_id,
                                                            order_id:businessplan.order_id,
                                                            freeplan_orderid:'',
                                                            plan_type:'business'
                                                        })
                                                        .then((res)=>{
                                                            console.log(res)
                                                            self.$root.$emit("audioplay");
                                                            self.$notify({
                                                                title:"Success",
                                                                text:businessplan.plan_name+' is deactivate successfully.',
                                                                type:"success",
                                                                duration: 10000
                                                            })
                                                            if(self.deactivatePlans.length >= 2){
                                                                var index = self.deactivatePlans.findIndex(product => {
                                                                    return product.payment_status == 'paid' && product.plan_id == businessplan.plan_id
                                                                })
                                                                self.deactivatePlans.splice(index,1);
                                                            }
                                                            if(self.deactivatePlans.length != 0){
                                                                // console.log(self.deactivatePlans,res)
                                                                self.addoneArray.forEach(data => {
                                                                    if(data.plan_id == businessplan.plan_id){
                                                                        self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                                                                            wp_user_id:self.userData.wp_id,
                                                                            user_id:self.userData.id,
                                                                            company_id:data.company_id,
                                                                            plan_id:data.plan_id,
                                                                            order_id:data.order_id,
                                                                            freeplan_orderid:'',
                                                                            plan_type:'addon'
                                                                        }).then((res) => {
                                                                            console.log(res)
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        }) 
                                                        
                                                    }
                                                    else{
                                                        self.$root.$emit("audioplay");
                                                        self.$notify({
                                                            title:"Warning",
                                                            text:'move'+' '+ movingUser+' '+'users into other plan',
                                                            type:"warn",
                                                            duration: 10000
                                                        })
                                                        
                                                    }
                                            }
                                        }
                                    })
                                    })
                                }
                            })
                        
                    })
                   }else{
                        plans.forEach(planData => {
                            if(planData.plan_id == businessplan.plan_id && planData.plan_status != 'deactivated'){
                                ProUserInPlan+=planData.noOfUser;
                            }
                        })
                        users.forEach(userData => {
                            if(userData.planId == businessplan.plan_id){
                               ProUserInUsers++;
                            }
                        })
                        getFreePlanObject2(firebaseData.firebasecollection.COMPANY,Vue.prototype.$companyName.id,res => {
                            self.FreePlanObject = ''
                            self.FreePlanObject = res
                            console.log(ProUserInPlan,ProUserInUsers);
                            if(businessplan.noOfUser > ProUserInUsers+1){
                                 const Toast = Swal.mixin({
                                    toast: true,
                                    position: "top-end",
                                    showConfirmButton: false,
                                    timer: 3000,
                                });
                                Swal.fire({
                                    title: "Your plan is deactivated so user will be move into"+' '+self.FreePlanObject.plan_name+' '+"plan",
                                    type: "warning",
                                    customClass:{
                                        container :"business_confirmation_popup"
                                    },
                                    showCancelButton: true,
                                    confirmButtonColor: "#77bef6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Ok",
                                }).then((result) => {
                                    if (result.value) {
                                        users.forEach(userData => {
                                            if(userData.planId == businessplan.plan_id){
                                                userData.planId = self.FreePlanObject.plan_id
                                                userData.planName = self.FreePlanObject.plan_name
                                                userData.dataUsage = 0
                                                userData.noOfSelfie = self.FreePlanObject.noOfSelfie
                                                userData.max_file_size = self.FreePlanObject.max_file_size
                                                userData.selfieTime = new Date()
                                                userData.userUsageLimit = self.FreePlanObject.userUsageLimit
                                                userData.usedSelfie = 0
                                                userData.selfieTime = [];
                                            }
                                        })
                                        db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                                            plan_id : businessplan.plan_id,
                                            plan_name: businessplan.plan_name,
                                            dataUsage: 0,
                                            device_plan: businessplan.device_plan,
                                            file_type_plan: businessplan.file_type_plan,
                                            max_file_size: businessplan.max_file_size,
                                            messages_history: businessplan.messages_history,
                                            selfieTime : new Date(),
                                            selfieUsers: [],
                                            usedSelfie: 0,
                                            userUsageLimit: businessplan.userUsageLimit,
                                            noOfSelfie:businessplan.noOfSelfie,
                                            users:users
                                        }).then(() => {
                                            self.$root.$emit("audioplay");
                                            self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                                                wp_user_id:self.userData.wp_id,
                                                user_id:self.userData.id,
                                                company_id:Vue.prototype.$companyName.id,
                                                plan_id:businessplan.plan_id,
                                                order_id:businessplan.order_id,
                                                freeplan_orderid:'',
                                                plan_type:'business'
                                            }).then((res) =>{
                                                self.$notify({
                                                    title:"Success",
                                                    text:businessplan.plan_name+' is deactivate successfully.',
                                                    type:"success",
                                                    duration: 10000
                                                })
                                            })
                                        }) 
                                    }
                                })   
                            }else{
                                self.$root.$emit("audioplay");
                                self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                                    wp_user_id:self.userData.wp_id,
                                    user_id:self.userData.id,
                                    company_id:Vue.prototype.$companyName.id,
                                    plan_id:businessplan.plan_id,
                                    order_id:businessplan.order_id,
                                    freeplan_orderid:'',
                                    plan_type:'business'
                                }).then((res) =>{
                                    self.$notify({
                                        title:"Success",
                                        text:businessplan.plan_name+' is deactivate successfully.',
                                        type:"success",
                                        duration: 10000
                                    })
                                })
                            }
                        })
                   }
               }
            }
            else{
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Swal.fire({
                title: "Are you sure you want to deactivate"+' '+businessplan.plan_name+' '+"plan?",
                type: "warning",
                customClass:{
                    container :"business_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: "#77bef6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            }).then((result) => {
                if (result.value) {
                    var sameplan = []
                    var users = []
                    var freeplanobject = {}
                    var numberOfUser = 0
                    var numberOfUserAdd = 0
                    // console.log(businessplan);
                    db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc=>{
                        var plans = doc.data().plan
                        var assignuser = doc.data().users
                        plans.forEach(data => {
                            if(data.plan_id == businessplan.plan_id && data.plan_status != 'deactivated' && data.payment_status == 'paid' ){
                                sameplan.push(data)
                            }
                            if(data.payment_status == 'free'){
                                freeplanobject = data
                            }

                        })
                        assignuser.forEach(user => {
                            if(user.planId == businessplan.plan_id){
                                users.push(user);
                            }
                        })
                        // console.log('sameplan',sameplan,sameplan.length,users,users.length);
                        if(users.length == 0){
                            self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                                wp_user_id:self.userData.wp_id,
                                user_id:self.userData.id,
                                company_id:Vue.prototype.$companyName.id,
                                plan_id:businessplan.plan_id,
                                order_id:businessplan.order_id,
                                freeplan_orderid:'',
                                plan_type:'business'
                            })
                            .then((res)=>{
                                console.log(res)
                                self.$root.$emit("audioplay");
                                self.$notify({
                                    title:"Success",
                                    text:businessplan.plan_name+' is deactivate successfully.',
                                    type:"success",
                                    duration: 10000
                                })
                                if(self.deactivatePlans.length >= 2){
                                    var index = self.deactivatePlans.findIndex(product => {
                                        return product.payment_status == 'paid' && product.plan_id == businessplan.plan_id
                                    })
                                    self.deactivatePlans.splice(index,1);
                                }
                                // console.log(self.deactivatePlans)
                            }) 
                        
                        }else{
                            if(sameplan.length == 1){
                                // console.log('sdsadaaadadaz',users.length)
                                const Toast = Swal.mixin({
                                    toast: true,
                                    position: "top-end",
                                    showConfirmButton: false,
                                    timer: 3000,
                                });
                                Swal.fire({
                                    title: "Your plan is deactivated so user will be move into"+' '+freeplanobject.plan_name+' '+"plan",
                                    type: "warning",
                                    customClass:{
                                        container :"business_confirmation_popup"
                                    },
                                    showCancelButton: true,
                                    confirmButtonColor: "#77bef6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Ok",
                                }).then((result) => {
                                    if (result.value) {
                                        // console.log(businessplan,freeplanobject);
                                        assignuser.forEach(users => {
                                            if(users.planId == businessplan.plan_id){
                                                users.planId = freeplanobject.plan_id
                                                users.planName = freeplanobject.plan_name
                                                users.dataUsage = 0
                                                users.noOfSelfie = freeplanobject.noOfSelfie
                                                users.max_file_size = freeplanobject.max_file_size
                                                users.selfieTime = new Date()
                                                users.userUsageLimit = freeplanobject.userUsageLimit
                                            }
                                        })
                                        db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                                            users : assignuser
                                        }).then(() => {
                                            assignuser.forEach(userdata => {
                                                if(userdata.id != '' && userdata.planId == freeplanobject.plan_id){
                                                    db.collection(firebaseData.firebasecollection.USER).doc(userdata.id).get().then(docs => {
                                                    companies = docs.data().companies || []
                                                        companies.forEach(cmp => {
                                                            if(cmp.id == Vue.prototype.$companyName.id && cmp.planId == businessplan.plan_id){
                                                            cmp.planId = freeplanobject.plan_id
                                                            cmp.planName = freeplanobject.plan_name
                                                            }
                                                    })
                                                    }).then(() => {
                                                        db.collection(firebaseData.firebasecollection.USER).doc(userdata.id).update({
                                                            companies:companies
                                                        })
                                                    }) 
                                                }
                                            })
                                        
                                            self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                                                wp_user_id:self.userData.wp_id,
                                                user_id:self.userData.id,
                                                company_id:Vue.prototype.$companyName.id,
                                                plan_id:businessplan.plan_id,
                                                order_id:businessplan.order_id,
                                                freeplan_orderid:'',
                                                plan_type:'business'
                                            })
                                            
                                                // self.addoneArray.forEach(data => {
                                                //     if(data.plan_id == businessplan.plan_id){

                                                //     }
                                                // })
                                                
                                                
                                            .then((res)=>{
                                                if(self.deactivatePlans.length >= 2){
                                                    var index = self.deactivatePlans.findIndex(product => {
                                                        return product.payment_status == 'paid' && product.plan_id == businessplan.plan_id
                                                    })
                                                    self.deactivatePlans.splice(index,1);
                                                
                                                    // console.log(self.deactivatePlans,res)
                                                    self.$root.$emit("audioplay");
                                                    self.$notify({
                                                        title:"Success",
                                                        text:businessplan.plan_name+' is deactivate successfully.',
                                                        type:"success",
                                                        duration: 10000
                                                    })
                                                }
                                                    if(self.deactivatePlans.length != 1){
                                                        self.addoneArray.forEach(data => {
                                                            if(data.plan_id == businessplan.plan_id){
                                                                self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                                                                    wp_user_id:self.userData.wp_id,
                                                                    user_id:self.userData.id,
                                                                    company_id:data.company_id,
                                                                    plan_id:data.plan_id,
                                                                    order_id:data.order_id,
                                                                    freeplan_orderid:'',
                                                                    plan_type:'addon'
                                                                }).then((res) => {
                                                                    console.log(res)
                                                                })
                                                            }
                                                        })
                                                    }
                                                
                                            }) 
                                        })
                                    
                                    }
                                })
                            
                            }else{
                                sameplan.forEach(users => {
                                numberOfUser = numberOfUser + users.noOfUser
                                })
                                // console.log('numberOfUser',numberOfUser,users.length,businessplan.noOfUser)
                                var remainingNoOfUser = Number(numberOfUser) - Number(businessplan.noOfUser)
                                var moveUser = Number(remainingNoOfUser) - Number(users.length)
                                var movingUser = Number(users.length) - Number(remainingNoOfUser)
                                    if(users.length <= remainingNoOfUser){
                                    self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                                            wp_user_id:self.userData.wp_id,
                                            user_id:self.userData.id,
                                            company_id:Vue.prototype.$companyName.id,
                                            plan_id:businessplan.plan_id,
                                            order_id:businessplan.order_id,
                                            freeplan_orderid:'',
                                            plan_type:'business'
                                        })
                                        .then((res)=>{
                                            console.log(res)
                                            self.$root.$emit("audioplay");
                                            self.$notify({
                                                title:"Success",
                                                text:businessplan.plan_name+' is deactivate successfully.',
                                                type:"success",
                                                duration: 10000
                                            })
                                            if(self.deactivatePlans.length >= 2){
                                                var index = self.deactivatePlans.findIndex(product => {
                                                    return product.payment_status == 'paid' && product.plan_id == businessplan.plan_id
                                                })
                                                self.deactivatePlans.splice(index,1);
                                            }
                                            if(self.deactivatePlans.length != 0){
                                                // console.log(self.deactivatePlans,res)
                                                self.addoneArray.forEach(data => {
                                                    if(data.plan_id == businessplan.plan_id){
                                                        self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                                                            wp_user_id:self.userData.wp_id,
                                                            user_id:self.userData.id,
                                                            company_id:data.company_id,
                                                            plan_id:data.plan_id,
                                                            order_id:data.order_id,
                                                            freeplan_orderid:'',
                                                            plan_type:'addon'
                                                        }).then((res) => {
                                                            console.log(res)
                                                        })
                                                    }
                                                })
                                            }
                                        }) 
                                        
                                    }
                                    else{
                                        self.$root.$emit("audioplay");
                                        self.$notify({
                                            title:"Warning",
                                            text:'move'+' '+ movingUser+' '+'users into other plan',
                                            type:"warn",
                                            duration: 10000
                                        })
                                        
                                    }
                            }
                        }
                    })
                }
            })
            }
        },
         //FOR ACTIVE SOCIAL MENU(Shraddha)
        SocialMenuActiveTab(){
        // //debugger // eslint-disable-line
            var self=this;
            self.activeChat = false
            self.businessMenuActive = false
            self.$root.$emit("socialMenuActive", self.businessMenuActive,self.activeChat)
            self.$router.push('/chat');
        },
        // FOR ACTIVEBUSINESS MENU
        businessMenuActiveTab(){
        // //debugger // eslint-disable-line
            var self=this;
            self.businessMenuActive = true;
            self.activeChat = false
            self.$root.$emit("businessMenuActive", self.businessMenuActive,self.activeChat)
            self.$root.$emit("getActiveComapnyList")
            self.$router.push('/businesschat');
            // self.businessMenuActive = true;
            // self.activeChat = false
            // self.$root.$emit("businessMenuActive", self.businessMenuActive,self.activeChat)
            // self.selectedActiveMenu = 'admin'
        },
        // ADD STORAGE IN PLAN(Shraddha)
        addStorage(adoneid){
            // debugger // eslint-disable-line
            var self=this;
            self.addstorageArray = []
            self.addStoragePlanId = []
            // console.log('CompanyUserDataCompanyUserData',self.CompanyUserData);
            self.addstorageArray = []
            self.newbusinessplan.forEach(planName => {
                if(planName.payment_status != 'free' && planName.plan_status != 'deactivated'){
                    self.CompanyUserData.forEach(existuser => {
                        if(existuser.planName == planName.plan_name){
                            if(self.addstorageArray.length == 0){
                                self.addstorageArray.push(planName.plan_name)
                                self.addStoragePlanId.push(planName.plan_id)
                            }else{
                                var matchFound = false
                                self.addstorageArray.forEach(datas => {
                                    if(datas == planName.plan_name){
                                        matchFound = true
                                    }
                                })
                                if(!matchFound){
                                    self.addstorageArray.push(planName.plan_name)
                                    self.addStoragePlanId.push(planName.plan_id) 
                                }
                            }
                        }
                    })
                } 
            })
            if(self.addstorageArray.length > 1){
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
            Swal.fire({
                title: 'Select plan for add storage',
                type: 'success',
                customClass:{
                    container :"business_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: '#77bef6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Add',
                input: 'select',
                inputOptions:self.addstorageArray,
              }).then((result) => {
                // console.log('result',result)
                if (result.value) {
                    self.selectedPlanForStorage = self.addstorageArray[result.value];
                    // console.log('self.selectedPlanForStorage',self.selectedPlanForStorage,self.addStoragePlanId[result.value],self.userData,adoneid)
                    self.$axios.post(self.$WPbaseurl+'wp-json/business/cart-addon-plan',{companyid:Vue.prototype.$companyName.id,addon_id:adoneid,wp_user_id:self.userData.wp_id,plan_id: self.addStoragePlanId[result.value],user_id:self.userData.id})
                        .then((res)=>{
                        // console.log('adoneresponse',res)
                        debugger // eslint-disable-line
                        window.location.href = self.$WPbaseurl+`cart/?uid=${self.userData.wp_id}&add-to-cart=${adoneid}&is_addon=true`;
                    })
                }
              })
            }else if(self.addstorageArray.length == 1){
                const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
            Swal.fire({
                title: 'Addon will purchase for' +' '+self.addstorageArray[0]+' '+'plan.',
                type: 'success',
                customClass:{
                    container :"business_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: '#77bef6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Add',
               
              }).then((result) => {
                // console.log('result',result)
                if (result.value) {
                    self.selectedPlanForStorage =  self.addstorageArray[0]
                    // console.log('self.selectedPlanStorage',self.selectedPlanForStorage,self.userData,adoneid)
                    self.$axios.post(self.$WPbaseurl+'wp-json/business/cart-addon-plan',{companyid:Vue.prototype.$companyName.id,addon_id:adoneid,wp_user_id:self.userData.wp_id,plan_id: self.addStoragePlanId[0],user_id:self.userData.id})
                        .then((res)=>{
                        // console.log('adoneresponse',res)
                        window.location.href = self.$WPbaseurl+`cart/?uid=${self.userData.wp_id}&add-to-cart=${adoneid}&is_addon=true`;
                    })
                }
              })
            }else{
                //  debugger // eslint-disable-line
                Swal.fire({
                    title: 'Assign at least one user in premium plan.',
                    type: 'success',
                    customClass:{
                        container :"business_confirmation_popup"
                    },
                    confirmButtonColor: '#77bef6',
                    confirmButtonText: 'ok',
                })
            }
          
        },
        //COMPANY NAME FETCH UNIQ (Shraddha)  
        isCompanyNameUniq(){
        //    debugger // eslint-disable-line
           var self=this;
            // console.log('companyName',self.createCompanyName)
            let isInArray = self.createCompanyName.some(function(item) {
                return item.toLowerCase() == self.companyname.toLowerCase()
            });
            if(isInArray == true && self.companyname != ''){
                self.errorMessage = 'Company name is already exist'         
           }else{
                self.errorMessage = ''
            }
        } ,
        //HANDLE ENTER EVENT(Shraddha)
        listenForEnter(e)
        {
        // //console.log(e)
        if(!e.shiftKey && e.key=='Enter')
        {
            if(!this.sendingMessageFlag && this.emailaddress!='')
            {
            this.sendingMessageFlag=true
            this.sendRequest()
            }
            if(this.sendingMessage=='' && this.emailaddress=='')
            {
            this.checkEmpty()
            }
            e.preventDefault()
        }
        }, 
        // RESEND MAIL (Shraddha)
        resendinvitation(companyData){
            // debugger // eslint-disable-line
            var self=this;
            // console.log(companyData)
            getFreePlanObject2(firebaseData.firebasecollection.COMPANY,Vue.prototype.$companyName.id,res => {
                self.FreePlanObject = ''
                self.FreePlanObject = res
               
                var index = self.userEmail.findIndex(item => {
                    return item.email == companyData.email  
                })
                if(index != -1){
                    self.$axios.post(self.$apiurl+'resendEmail',{email:companyData.email,type:'Request',requestType:'Business',userId:firebase.auth().currentUser.uid,companyId:Vue.prototype.$companyName.id,receiverId:self.userEmail[index].userid,planId:self.FreePlanObject.plan_id})
                        .then((res)=>{
                            // console.log(res)
                            
                        })
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"Success",
                            text:'Invite sent.',
                            type:"success",
                            duration: 10000,
                        })
                }else{
                    self.$axios.post(self.$apiurl+'resendEmail',{email:companyData.email,type:'Invitation',requestType:'Business',userId:firebase.auth().currentUser.uid,companyId:Vue.prototype.$companyName.id,planId:companyData.planId})
                        .then((res)=>{
                            self.$root.$emit("audioplay");
                            // console.log(res)
                            self.$notify({
                                title:"SUCCESS",
                                text:'Invitation sent.',
                                type:"success"
                            })
                    })
                }
            })
        },
        //DEACTIVATE ADDONE ( Shraddha)
        deactivateAddone(addone,index){
            // debugger // eslint-disable-line
            var self=this;
            self.isSpinner = true
            self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                wp_user_id:self.userData.wp_id,
                user_id:self.userData.id,
                company_id:addone.company_id,
                plan_id:addone.plan_id,
                order_id:addone.order_id,
                freeplan_orderid:'',
                plan_type:'addon'
            }).then((res) => {
                // console.log('res',res)
                self.$root.$emit("audioplay");
                self.$notify({
                    title:"Success",
                    text:'Addon deactivate successfully.',
                    type:"success",
                    duration: 10000,
                })
                self.isSpinner = false
                self.addoneArray.splice(index,1)
               
            })
            // self.$notify({
            //         title:"Success",
            //         text:'Addon deactivate successfully.',
            //         type:"success",
            //         duration: 10000,
            //     })
        }   
    },

    computed:{
        ...mapGetters(["getCompanies"]),
        companiesFromDB()
        {
            this.companyName = this.getCompanies[0]
            return this.getCompanies
        }
    }
   
};
</script>