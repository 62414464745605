<template>
    <b-modal id="add-channel" centered hide-footer title="" @close="closeFire()" :no-close-on-backdrop="true">
     <div class="contact-modal newchat-modal">
            <div class="contact-modal">
            <h4>Add Channel</h4>
             <ValidationObserver ref="log" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(createGroup)">
                 <ValidationProvider rules="min:3" name="channel Name" v-slot="{ errors }">
                <div class="form-group">
                    <label>CHANNEL NAME *</label>
                    <input class="form-control" placeholder="Enter channel name" maxlength="21" v-model="chatName" type="text" ref="chatMessage" @keyup="checkChrecterLength(chatName)">
                     <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                    <span class="InvalidInput">{{ errors[0]=='The channel name field must be at least 3 characters'?'minimum 3 character is required.':errors[0] }}</span>

                     <span v-if="charecterErrorMessage != ''&& errors.length == 0" style="color:red;">{{charecterErrorMessage}}</span>
                </div>
                  </ValidationProvider>
                <!-- <div class="form-group">
                    <label>Create a few initial branches</label>
                    <div v-for="(data,index) in branchName" :key="index" style="position:relative">
                        <ValidationProvider :rules="index==0?'min:3|max:15':'required|min:3|max:15'" name="branch name" v-slot="{ errors }" :vid="'branch name'+index">
                            <input class="form-control" placeholder="Enter few initial branches" :name="'branch name'+index" v-model="data.name">
                            <img src="@/assets/images/user_cancel.png" style="cursor:pointer" v-if="index!=0" class="remove_branch" @click="()=>{branchName.splice(index,1)}">
                            <span class="InvalidInput">{{ errors[0] }}</span>
                            <span class="InvalidInput" v-if="checkBranchNamePerInput(data,index) && errors.length==0">The branch name should not be same.</span>
                        </ValidationProvider>
                    </div>
                </div> -->
                <!-- <div class="forget-link"> -->
                    <!-- <div>
                        <label class="checkbox-c opacity-zero">Send Message 1 to 1<input type="checkbox" checked="checked"><span class="checkmark"></span></label>
                    </div> -->
                        <!-- <a href="#" class="link-branch" v-if="branchName.length<2" @click.prevent="branchName[0].name!=''?addAnotherBranch():showError()">+ ADD CHANNEL</a>
                        <a href="#" class="link-branch" v-else @click.prevent="!duplicateBranchName?handleSubmit(addAnotherBranch):''">+ Add Branch</a>
                </div> -->

                <div class="form-group">
                    <label class="sc">Select Contacts</label>
                    <div class="select-contact-list">
                        <ul>
                            <li v-for="(bulkList,index) in bulkMemberList" v-bind:key="index">
                              {{bulkList.firstName}} {{bulkList.lastName}} 
                              <span class="remove-content"><img src="@/assets/images/user_added.png"></span>
                              <span class="remove-content" @click="removemember(index)"><img src="@/assets/images/user_cancel.png"></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="form-group modal-search">
                    <input type="search" input-id="tags-basic" remove-on-delete  class="form-control" placeholder="Search Contact" v-model="keyword">
                    <img src="@/assets/images/search-icon.png" title="Find" class="search-icon">
                </div>

                <div class="choose-contact">
                   <div class="scrollbar">
                       <ul class="member-list-sidebar">
                           
                          <li v-for="(bulkUserList,index) in getFilterData" v-bind:key="index" v-show="botUser.id != bulkUserList.id">
                            <div v-if="bulkUserList.block != true"><img :src="bulkUserList.profileUrl"><span class="ml-1">{{bulkUserList.firstName}} {{bulkUserList.lastName}}</span></div>
                            <button type="button" v-if="bulkUserList.block != true" class="btn btn-white" @click="addBrodcastMmeber(bulkUserList)">Add</button>
                           </li>
                        </ul>
                    </div>
                </div>
                <p class="contact-count"><span>{{bulkMemberList.length}}</span> Contact Selected</p>
                <div class="btn-wrapper">
                    <!-- <button type="button" class="btn btn-yellow" v-bind:class="{disabled: isSpinner == true}" v-if="branchName.length>1" @click="validBranchName?!duplicateBranchName?createGroup():showBranchNameError=true:showBranchNameError=true">Create</button> -->
                    <button type="button" class="btn btn-blue" v-bind:class="{disabled: isSpinner == true}" @click="!duplicateBranchName?handleSubmit(createGroup):''">Add channel</button>
                </div>
            </form>
              </ValidationObserver>
        </div>
        </div>
  </b-modal>
</template>
<script>
import firebaseData from '../../../utils/firebaseCollection.js'
import firebase from "../../../views/firebaseInit"
import firebaseTimestamp from "firebase";
import Vue from "vue";
import vSelect from "vue-select";
import {Contactlist2,getUserData2,getGroupListCount2,getUnblockperson2} from '../../../businessUtills/getQueeryActiveChatList.js';
import {getLoginUserData,getBotuserData} from "@/utils/getQueeryActiveChatList.js";
const db = firebase.firestore();
Vue.component("v-select", vSelect);

export default({
    components:{
        firebaseData,
    },
     props: ["selectedActiveMenu","searchVal"],
    data(){
        return{
            userSearchList:[],
            getUserData:[],
            chatName:'',
            searchContact:'',
            isChatting:false,
            updateChat:{},
            connectedUserArray:[],
            AdminData:{},
            userid:'',
            socialMessageid:'',
            branchName:[{name:''}],
            groupMemberData:[],
            isSpinner:false,
            unblockgroupList:[],
            bulkMemberList : [],
            keyword:'',
            showBranchNameError:false,
            businessMenuActive:false,
            noOfChannel:0,
            activeChannel:[],
            channelMemberLimit:0,
            channelsCreated:0,
            charecterErrorMessage:''
        }
    },
    created(){
        // debugger // eslint-disable-line
        this.getContactList();
        var planDetail = []
        getBotuserData(firebaseData.firebasecollection.USER,(res) => {
            this.botUser = res;
        })
        // this.$root.$on('callchannell',()=>{
        //     debugger // eslint-disable-line
        //     this.getContactList();  
        // })
      
        // this.chatFocus();
        this.$root.$on("businessMenuActive",(value,activeChat)=>{
            
            this.businessMenuActive = value;
            // console.log(activeChat)
        })
        var selectCompany = JSON.parse(localStorage.getItem('SelectedCompany'))
            if(selectCompany != '' && selectCompany !=undefined){
                Vue.prototype.$companyName =  selectCompany
                this.Comapnyadmin = selectCompany.isAdmin
                self.companyName = selectCompany
            }
        db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).onSnapshot(doc => {
            // querysnapShot.forEach(doc => {
                if(doc.exists){
                    // if(doc.data().id == Vue.prototype.$companyName.id){
                        planDetail = doc.data().plan
                        if(planDetail.length > 0){
                            this.noOfChannel = doc.data().channelLimit || 0
                            this.channelMemberLimit = doc.data().channelmemberlimit || 0
                            this.channelsCreated = doc.data().channelsCreated || 0
                        }
                    // }
                }
            // })
        })
        this.activeChannel = []
        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection('businessMessages').onSnapshot(querysnapShot => {
            querysnapShot.forEach(doc => {
                if(doc.exists){
                    if(doc.data().isChannel == true){
                        this.activeChannel.push(doc.data());
                        // console.log('this.activeChannel',this.activeChannel)
                          
                    }
                }
            })
            
        })
    },
    mounted(){
        this.getContactList();
    },
    methods:{
        showError()
        {
            this.$notify({
                title:'Error',
                text:'Please enter e branch name first.',
                type:'error'
            })
        },
        checkBranchNamePerInput(data,index)
        {
            var self = this

            var duplicate = false

            for (let i = 0; i < self.branchName.length; i++) {
                if(index != i && data.name==self.branchName[i].name && index>=i)
                {
                    duplicate=true
                }
            }
            return duplicate
        },
        addAnotherBranch()
        {
            var self = this

            if(self.branchName[self.branchName.length-1].name!='')
            {
                self.branchName.push({name:""})
            }
        },
        customLabel (option) {
            return `${option.library} - ${option.language}`
        },
        onSelect (option, id) {
            // console.log(option, id)
        },
        // SET FOCUS ON GROUPNAME WHEN OPEN POPUP (Shraddha)
        // chatFocus(){
        //     var self=this;
        //     self.$root.$on("setchatfocus",(value) => {
        //         // //debugger // eslint-disable-line
        //         if(value == 'yes'){
        //             setTimeout( () => {
        //                 self.$refs.chatMessage.focus();
        //             },1000)
        //         }
        //     })
        // },
          //GET CONTACT LIST(Shraddha)
        getContactList(){
            // debugger // eslint-disable-line
            var self=this;
            self.acseptUserData=[];
            self.userSearchList = []
            self.unblockgroupList = []
            const db = firebase.firestore();
            if(Vue.prototype.$companyName){
                Contactlist2(firebaseData.firebasecollection.USER,self.unblockgroupList,res=>{
                    self.unblockgroupList = res
                    //  console.log('>>>>>>>>>>>>sfsdfsd',self.unblockgroupList);
                    getUserData2(firebaseData.firebasecollection.USER,self.getUserData,res=>{
                        self.getUserData = res
                        getGroupListCount2(self.unblockgroupList,self.getUserData,res=>{
                            self.unblockgroupList = res
                            getUnblockperson2(self.unblockgroupList,res => {
                            self.userSearchList = res
                                // console.log('asdx',self.userSearchList);
                            })
                        })
                    })
                })
            }
        },
        //CHECK GROUP NAME CHRECTER LENGTH(Shraddha)
            checkChrecterLength(groupname){
                debugger // eslint-disable-line
                var self = this;
                    if(groupname.length < 21){
                        self.chatName = groupname
                        self.charecterErrorMessage = '';
                    }else{
                        self.charecterErrorMessage = 'The channel name may not be greater than 20 characters';
                        self.chatName = (groupname).slice(0, 20)
                    }
            },
        createGroup(){
            debugger // eslint-disable-line
            var self=this;
            if(self.charecterErrorMessage == ''){
                if(self.noOfChannel == 'unlimited'){
                    self.chatName = self.chatName.trim();
                    self.isSpinner = true
                    var mainBranches = []
        
                    if(self.branchName.length!=0)
                    {
                        self.branchName.forEach(data=>{
                            if(data.name!='')
                            {
                                mainBranches.push({
                                    name:data.name.trim(),
                                    createdAt:new Date(),
                                    subBranches:[],
                                    lastMsgTime :new Date(),
                                    muteArray:[],
                                    messageSeenIds:[],
                                })
                            }
                        })
                    }
        
                    self.isChatting = false
                    self.updateChat = {}
                    self.connectedUserArray = [];
                    // console.log('group chat',self.chatName,self.searchContact);
                    const db = firebase.firestore();
                    self.userid = localStorage.getItem('customerDocKey');
                    if(self.chatName != ''){
                        if(self.bulkMemberList.length > 0){
                            db.collection(firebaseData.firebasecollection.USER)
                            .doc(self.userid)
                            .get()
                            .then(doc=>{
                                self.AdminData = doc.data();
                                // console.log('self.AdminData',self.AdminData)
                            }).then(() => {
                                self.from = {
                                    avtar: Vue.prototype.$defaultProfile,
                                    id:self.AdminData.id,
                                    name:self.AdminData.firstName,
                                }
                                // console.log('self.from',self.from)
        
                                self.updateChat = {
                                    from:self.from,
                                    msg:''
                                }
                                // console.log('self.updateChat',self.updateChat)
                                self.connectedUserArray.push(self.userid)
                                for(var i=0;i<self.bulkMemberList.length;i++){
                                    self.connectedUserArray.push(self.bulkMemberList[i].id)
                                }
        
                                mainBranches.forEach(data=>{
                                    self.connectedUserArray.forEach(ids=>{
                                        data.messageSeenIds.push({
                                            id:ids,
                                            count:0
                                        })
                                    })
                                })
                                // console.log('self.connectedUserArray',self.connectedUserArray)
                            }).then(() => {
                                var messageSeenIds=[]
                                self.connectedUserArray.forEach(data=>{
                                    messageSeenIds.push({id:data,count:0})
                                })
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).add({
                                    connectedUser:self.connectedUserArray,
                                    id:'',
                                    isGroup:false,
                                    isChannel:true,
                                    lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    profileUrl:Vue.prototype.$defaultGroupProfile,
                                    adminName:self.AdminData.firstName+' '+self.AdminData.lastName,
                                    adminId :self.userid,
                                    groupcreatedate:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    title:self.chatName,
                                    updateChat: {},
                                    mainBranches:mainBranches,
                                    muteArray:[],
                                    SurpriseSelfie : {},
                                    ToggleSelfie : [],
                                    messageSeenIds:messageSeenIds
                                }).then((docRef) => {
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(docRef.id).update({
                                        id:docRef.id
                                    }).then(() => {
                                        db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id)
                                        .update({
                                            channelsCreated:self.channelsCreated++
                                        })
                                        .then(()=>{
                                            self.$root.$emit("audioplay");
                                            self.$notify({
                                                title:"Success",
                                                text:self.chatName+' channel created successfully.',
                                                type:"success",
                                                duration: 10000
                                            })
                                            self.chatName='';
                                            self.searchContact = [];
                                            self.bulkMemberList = [];
                                            self.$refs.log.reset();
                                            self.branchName=[{name:''}];
                                            self.$bvModal.hide('add-channel');
                                            self.isSpinner = false
                                            self.$root.$emit('groupcreate','Yes');
                                            self.getContactList();
                                        })
                                        .catch(error=>{
                                            console.log(error)
                                        })
                                    })
                                })
                            })
                        }else{
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"ERROR",
                                text:'Group member is required for chat',
                                type:"error",
                                duration: 10000
                            })
                            self.isSpinner = false
                            self.$refs.log.reset();
                            self.getContactList();
                        }
                    }else{
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"ERROR",
                            text:'Chat name is required',
                            type:"error",
                            duration: 10000
                        })
                        self.isSpinner = false
                        self.searchContact =[]
                        self.$refs.log.reset();
                        self.getContactList();
                    }
                }else{
                    if(self.noOfChannel > self.activeChannel.length){
                    self.chatName = self.chatName.trim();
                    self.isSpinner = true
                    var mainBranches = []
        
                    if(self.branchName.length!=0)
                    {
                        self.branchName.forEach(data=>{
                            if(data.name!='')
                            {
                                mainBranches.push({
                                    name:data.name.trim(),
                                    createdAt:new Date(),
                                    subBranches:[],
                                    lastMsgTime :new Date(),
                                    muteArray:[],
                                    messageSeenIds:[],
                                })
                            }
                        })
                    }
        
                    self.isChatting = false
                    self.updateChat = {}
                    self.connectedUserArray = [];
                    // console.log('group chat',self.chatName,self.searchContact);
                    const db = firebase.firestore();
                    self.userid = localStorage.getItem('customerDocKey');
                    if(self.chatName != ''){
                        if(self.bulkMemberList.length > 0){
                            db.collection(firebaseData.firebasecollection.USER)
                            .doc(self.userid)
                            .get()
                            .then(doc=>{
                                self.AdminData = doc.data();
                                // console.log('self.AdminData',self.AdminData)
                            }).then(() => {
                                self.from = {
                                    avtar: Vue.prototype.$defaultProfile,
                                    id:self.AdminData.id,
                                    name:self.AdminData.firstName,
                                }
                                // console.log('self.from',self.from)
        
                                self.updateChat = {
                                    from:self.from,
                                    msg:''
                                }
                                // console.log('self.updateChat',self.updateChat)
                                self.connectedUserArray.push(self.userid)
                                for(var i=0;i<self.bulkMemberList.length;i++){
                                    self.connectedUserArray.push(self.bulkMemberList[i].id)
                                }
        
                                mainBranches.forEach(data=>{
                                    self.connectedUserArray.forEach(ids=>{
                                        data.messageSeenIds.push({
                                            id:ids,
                                            count:0
                                        })
                                    })
                                })
                                // console.log('self.connectedUserArray',self.connectedUserArray)
                            }).then(() => {
                                var messageSeenIds=[]
                                self.connectedUserArray.forEach(data=>{
                                    messageSeenIds.push({id:data,count:0})
                                })
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).add({
                                    connectedUser:self.connectedUserArray,
                                    id:'',
                                    isGroup:false,
                                    isChannel:true,
                                    lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    profileUrl: Vue.prototype.$defaultGroupProfile,
                                    adminName:self.AdminData.firstName+' '+self.AdminData.lastName,
                                    adminId :self.userid,
                                    groupcreatedate:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    title:self.chatName,
                                    updateChat: {},
                                    mainBranches:mainBranches,
                                    muteArray:[],
                                    SurpriseSelfie : {},
                                    ToggleSelfie : [],
                                    messageSeenIds:messageSeenIds
                                }).then((docRef) => {
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(docRef.id).update({
                                        id:docRef.id
                                    }).then(() => {
                                        self.$root.$emit("audioplay");
                                        self.$notify({
                                            title:"Success",
                                            text:self.chatName+' channel created successfully.',
                                            type:"success",
                                            duration: 10000
                                        })
                                        self.chatName='';
                                        self.searchContact = [];
                                        self.bulkMemberList = [];
                                        self.$refs.log.reset();
                                        self.branchName=[{name:''}];
                                        self.$bvModal.hide('add-channel');
                                        self.isSpinner = false
                                        self.$root.$emit('groupcreate','Yes');
                                        self.getContactList();
                                    })
                                })
                            })
                        }else{
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"ERROR",
                                text:'Group member is required for chat',
                                type:"error",
                                duration: 10000
                            })
                            self.isSpinner = false
                            self.$refs.log.reset();
                            self.getContactList();
                        }
                    }else{
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"ERROR",
                            text:'Channel name is required',
                            type:"error",
                            duration: 10000
                        })
                        self.isSpinner = false
                        self.searchContact =[]
                        self.$refs.log.reset();
                        self.getContactList();
                    }
                    }else{
                    self.$root.$emit("audioplay");
                    self.$notify({
                            title:"ERROR",
                            text:'Channel limit reached.',
                            type:"error",
                            duration: 10000
                        })
                        self.isSpinner = false
                        self.searchContact =[]
                        self.$refs.log.reset(); 
                        self.getContactList();
                    }
                }
            }
        },
        // CLOSE EVENT FIRE(Shraddha)
        closeFire(){
            // //debugger // eslint-disable-line
            var self=this;
            self.chatName = ''
            // self.userSearchList = [];
            self.searchContact = [];
            self.bulkMemberList = [];
            self.branchName = [{name:''}]
            self.$refs.log.reset();
        },
       
           // ADD MEMBER FOR BRODCAST(Shraddha)
        addBrodcastMmeber(bulkobj){
          //debugger // eslint-disable-line
          var self = this;
          if(self.channelMemberLimit != 'unlimited'){

              if(self.channelMemberLimit > (self.bulkMemberList.length + 1)){
             self.keyword = ''
              if(self.bulkMemberList.length > 0){
                let isInArray = self.bulkMemberList.some(function(item) {
                    return item.docid == bulkobj.docid
                });
                if(isInArray == false){
                  self.bulkMemberList.push(bulkobj);
                  
                }else{
                  self.$root.$emit("audioplay");
                  self.$notify({
                    title:"Error",
                    text:'Already exists',
                    type:"error"
                  })
                  
                }
              }else{
                self.bulkMemberList.push(bulkobj);
                
              }
              }else{
                   self.$root.$emit("audioplay");
                   self.$notify({
                    title:"Error",
                    text:'You can not add more than'+' '+self.channelMemberLimit+' '+'member',
                    type:"error"
                  })
              }
          }else{
            self.keyword = ''
            if(self.bulkMemberList.length > 0){
                let isInArray = self.bulkMemberList.some(function(item) {
                    return item.docid == bulkobj.docid
                });
                if(isInArray == false){
                  self.bulkMemberList.push(bulkobj);
                  
                }else{
                  self.$root.$emit("audioplay");
                  self.$notify({
                    title:"Error",
                    text:'Already exists',
                    type:"error"
                  })
                  
                }
            }else{
                self.bulkMemberList.push(bulkobj);
                
            }  
          }
        },
        // REMOVE MEMBER FROM BULKARRAY(Shraddha)
        removemember(id){
            // //debugger // eslint-disable-line
            var self = this;
           self.bulkMemberList.splice(id,1);
        },
    },

    computed:{
        validBranchName()
        {
            var self = this

            // if(self.branchName.length==1 && self.branchName[0].name=='')
            // {
            //     return true
            // }
            // else
            // {
                if(self.branchName[self.branchName.length-1].name!='')
                {
                    self.showBranchNameError=false
                    return true
                }
                else
                {
                    return false
                }
            // }
        },
        duplicateBranchName()
        {
            var self = this

            var duplicate = false
            var index=0

            for (let i = 0; i < self.branchName.length; i++) {
                for (let j = i+1; j < self.branchName.length; j++) {
                    if(self.branchName[i].name==self.branchName[j].name)
                    {
                        duplicate = true
                    }
                }
            }

            return duplicate
        },
        getFilterData() {
            var self = this;
            return self.userSearchList.filter(function(rawData) {
                return (
                rawData["firstName"]
                    .toLowerCase()
                    .indexOf(self.keyword.toLowerCase()) >= 0 ||
                rawData["lastName"]
                    .toLowerCase()
                    .indexOf(self.keyword.toLowerCase()) >= 0 
                );
            });
        },
    }
})
</script>

<style scoped>
div#new-chat .contact-modal.newchat-modal .InvalidInput {
    position: relative;
}
</style>