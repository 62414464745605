<template>
  <div class="siderbar-wrapper" v-bind:class="[{open_sidebar: openSidebarForFrontMediaData || openSidebarForMediaData || opensidebarForReceipt}]">
    <b-sidebar id="sidebar-right" right shadow backdrop @hidden="opensidebarForReceipt=false,openSidebarForFrontMediaData = true , openSidebarForMediaData = false">
    <template #default="{ hide }">
     
      <div class="sidebar-content" v-if="openSidebarForFrontMediaData == true">
        <div class="view-detail-content">
          <div class="photoupload-wrapper" v-if="activeGroup == false">
            <img v-if="chatObject.profileUrl" :src="chatObject.profileUrl" class="img-fluid" />
            <img v-else src="../assets/images/noupload.png" />
            <!-- <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file"/> -->
          </div>
          
           <div class="photoupload-wrapper" v-else>
            <img v-if="preview" :src="preview" class="img-fluid" />
            <img v-else :src="groupData.profileUrl" class="img-fluid" />
            <!-- <img v-else src="@/assets/images/noupload.png" /> -->
            <input type="file" title="Edit profile" accept="image/*" @change="previewImage" class="form-control-file" id="my-file"/>
            <span style="color:red; display: block;text-align: center;" v-if="profileErrorMessage">{{profileErrorMessage}}</span>
          </div>
        </div>
          <div class="name-changes-area">
          <div class="sidebar-content-padding">
            <div class="name-update">
              <h4 v-if="activeGroup == false">{{chatObject.firstName}}</h4>
              <h4 v-else>{{groupData.title}}</h4>
              <img v-if="activeGroup == true"  src="../assets/images/edit_icon.png" @click="openEditName(groupData.title)">
            </div>
            <div class="sidebar-update-input" v-if="activeGroup == true && openedit == true">
              <div class="form-group">
                <label>Name *</label>
              </div>
                <input type="text" placeholder="Group Name" class="form-control" value="" v-model="groupName" @keyup="checkChrecterLength(groupName)">
                <span v-if="charecterErrorMessage != ''" style="color:red;">{{charecterErrorMessage}}</span>
                <span @click="editGroupName()" class="right-span" style="margin-right:5px;"><img src="@/assets/images/send-arrow.png"></span>
                <span @click="closeEdit()" class="close-span"><img src="@/assets/images/close_modal.png"></span>
            </div>
            <div class="created-by-info" v-if="activeGroup == true">
              <ul>
                <li><span>Created by : </span><span>{{groupData.adminName}}</span></li>
                <li><span>Created on : </span><span>{{groupCreateTime}}</span></li>
              </ul>
            </div>
            <div class="">
              <div class="notifocation-wrapper">
              <h4>Mute Notification</h4>
              <div class="button-switch">
                <input type="checkbox" id="switch-blue" class="switch" v-model="mutechat" @click="muteUnmute(chatId)"/>
              </div>
              </div>
            </div>
            <div class="media-gallery white-box" v-if="openSidebarForFrontMediaData == true" >
              <div class="gallery-title">
                  <h4>Media, Docs and Link</h4>
                  <div class="count_span-main">
                    <span class="count_span">{{counterArray.length}}</span>
                  <img src="../assets/images/dropdown-arrow.png" class="link-full-media" @click="openSidebarForMedia()">
                  </div>
              </div>
                <hooper ref="gallery" class="media-doc-slider" :itemsToShow="4" :itemsToSlide="1" :autoPlay="false" :wheelControl="true" :infiniteScroll="false" :trimWhiteSpace="false">
                    <slide v-for="(recent,index) in mediaImages" v-bind:key="index" class="downloadIconWrapper">
                      <img :src="recent.media"  v-if="recent.type == 'Image'">
                      <video :src="recent.media" v-if="recent.type == 'Video'"
                        controls="controls" 
                        class="video-stream" 
                        x-webkit-airplay="allow" 
                      ></video>
                      <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(recent.media,recent.name)"/>
                    </slide>
                </hooper>
            </div>
            <div class="Group-Member_list" v-if="activeGroup == true && openSidebarForFrontMediaData == true">
              <div class="gallery-title">
                  <h4>Group Members</h4>
              </div>
              <div class="scrollbar">
                <ul class="member-list-sidebar">
                  <!-- {{groupData.adminId}} -->
                  <li v-for="(groupmember,index) in checkGroupMembers(groupMember)" v-bind:key="index" :class="{'groupMemberUndefined': groupmember == undefined}">
                    <div style="width:100%;" v-if="groupmember!=undefined">
                      <img class="memeberProfile" :src="groupmember.profileUrl"><span class="ml-1" style="vertical-align: middle;">{{groupmember.firstName}} {{groupmember.lastName}}</span>
                      <span v-if="groupData.adminId==groupmember.id" style="vertical-align: middle; color:#000; background-color: #fde9cf;border: 1px solid #ffab3f;" class="groupAdminTitle">
                        Group Admin
                      </span>
                      <span style="vertical-align: middle;" >
                        <img v-if="groupData.adminId!=groupmember.id && groupData.adminId==userid" title="Remove member" src="@/assets/images/cancel-circle.png" alt="Remove" @click.prevent="removeGroupMemberConfirmation(groupmember,index)" class="crossIcon">
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-content-2" v-if="openSidebarForMediaData == true" >
          <div class="media-tab-sidebar">
              <button class="btn btn-white btn-back" @click="openSidebarForFrontMediaData = true , openSidebarForMediaData = false"><img src="../assets/images/tag-arrow.png" class="back-arrow">Back</button>
              <div>
                <b-tabs content-class="mt-3">
                  <b-tab title="media" active>
                    <div class="media-content">
                      <div v-if="recentThreeData.length==0 && lastWeekMedia.length==0 && lastMonthMedia.length==0" style="text-align:center" class="tab_title">
                        <span>No Media Found</span>
                      </div>
                      <div v-else>
                        <div v-if="recentThreeData.length!=0" class="tab_title">
                            <span>recent</span>
                        </div>
                        <div class="recent-media">
                            <ul>
                              <div v-for="(recent,index) in recentThreeData" v-bind:key="index" class="downloadIconWrapper">
                                <li v-if="new Date(recent.createdAt.seconds * 1000) >= lastWeekEndDateMedia && new Date(recent.createdAt.seconds * 1000) <= new Date()" >
                                  <img :src="recent.mediaUrl"  v-if="recent.type == 'Image'">
                                  <video :src="recent.mediaUrl" v-if="recent.type == 'Video'"
                                    controls="controls" 
                                    class="video-stream" 
                                    x-webkit-airplay="allow" 
                                  ></video>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(recent.msg,recent.name)"/>
                                </li>
                              </div>
                            </ul>
                        </div>
                        <div v-if="lastWeekMedia.length!=0" class="tab_title">
                            <span>Last week</span>
                        </div>
                        <div class="recent-media">
                            <ul>
                              <div v-for="(mediaData,index) in lastWeekMedia" v-bind:key="index" class="downloadIconWrapper">
                                <li v-if="mediaData.createdAt >= mediaData.lastWeekStartDate && mediaData.createdAt <= mediaData.lastWeekEndDate">
                                  <img :src="mediaData.media" v-if="mediaData.type == 'Image'">
                                  <video :src="mediaData.media" v-if="mediaData.type == 'Video'"
                                    controls="controls" 
                                    class="video-stream" 
                                    x-webkit-airplay="allow" 
                                  ></video>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(mediaData.media,mediaData.imagename)"/>
                                </li>
                              </div>
                            </ul>
                        </div>
                        <div v-if="lastMonthMedia.length!=0" class="tab_title">
                            <span>Last Month</span>
                        </div>
                        <div class="recent-media">
                            <ul>
                              <div v-for="(mediaData,index) in lastMonthMedia" v-bind:key="index" class="downloadIconWrapper">
                                <li v-if="mediaData.createdAt > mediaData.lastMonthDate && mediaData.createdAt < mediaData.lastWeekStartDate">
                                  <img :src="mediaData.media" v-if="mediaData.type == 'Image'">
                                  <video :src="mediaData.media" v-if="mediaData.type == 'Video'"
                                    controls="controls" 
                                    class="video-stream" 
                                    x-webkit-airplay="allow" 
                                  ></video>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(mediaData.media,mediaData.imagename)"/>
                                </li>
                              </div>
                            </ul>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="docs">
                    <div class="form-group doc-search ">
                        <input type="search" placeholder="search" class="form-control" v-model="keyword">
                        <img src="@/assets/images/search.png" alt="search">
                    </div>
                    <div v-if="getFilterLastThreeData.length==0 && getFilterLastWeekData.length==0 && getFilterLastMonthData.length==0" style="text-align:center" class="tab_title">
                      <span>No Docs Found</span>
                    </div>
                    <div v-else>
                      <div v-if="getFilterLastThreeData.length!=0" class="tab_title">
                          <span>Recent</span>
                      </div>
                      
                      <div class="doct-list">
                          <ul>
                            <div v-for="(allmedia,index) in getFilterLastThreeData" v-bind:key="index" class="downloadIconWrapper">
                              <li v-if="allmedia.createDate > allmedia.lastWeekEndDate && allmedia.createDate <= new Date()">
                                <a v-bind:key="allmedia">
                                  <img src="../assets/images/pdf-icon.png" v-if="allmedia.type == 'pdf'"/>
                                  <img src="../assets/images/Exl-icon.png" v-if="allmedia.type == 'excel'">
                                  <img src="../assets/images/Word_Icon.png" v-if="allmedia.type == 'word'">
                                </a>
                                <div class="doc-name">
                                  <!-- <p>Coffee</p> -->
                                  <div class="name_date">
                                    <div>
                                    <p>{{allmedia.imageOnlyname}}</p>
                                    <span>{{allmedia.name}}</span>
                                    </div>
                                    <div class="download-text_date">
                                    <button type="button" class="btn-without-bg" @click.prevent="downloadDocument(allmedia.media,allmedia.imagename)">Download</button>
                                    <span>{{addZero(allmedia.createdAt.split('/')[0])}}/{{addZero(allmedia.createdAt.split('/')[1])}}/{{addZero(allmedia.createdAt.split('/')[2])}}</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </div>
                          </ul>
                      </div>
                      <div v-if="getFilterLastWeekData.length!=0" class="tab_title">
                          <span>Last week</span>
                      </div>
                      
                      <div class="doct-list">
                          <ul>
                            <div v-for="(allmedia,index) in getFilterLastWeekData" v-bind:key="index" class="downloadIconWrapper">
                            <li v-if="allmedia.createDate >= allmedia.lastWeekStartDate && allmedia.createDate <= allmedia.lastWeekEndDate">
                              <a v-bind:key="allmedia">
                                <img src="../assets/images/pdf-icon.png" v-if="allmedia.type == 'pdf'"/>
                                <img src="../assets/images/Exl-icon.png" v-if="allmedia.type == 'excel'">
                                <img src="../assets/images/Word_Icon.png" v-if="allmedia.type == 'word'">
                              </a>
                              <div class="doc-name">
                                <!-- <p>Coffee</p> -->
                                <div class="name_date">
                                  <div>
                                  <p>{{allmedia.imageOnlyname}}</p>
                                  <span>{{allmedia.name}}</span>
                                  </div>
                                  <div class="download-text_date">
                                  <button type="button" class="btn-without-bg" @click.prevent="downloadDocument(allmedia.media,allmedia.imagename)">Download</button>
                                  <span>{{addZero(allmedia.createdAt.split('/')[0])}}/{{addZero(allmedia.createdAt.split('/')[1])}}/{{addZero(allmedia.createdAt.split('/')[2])}}</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                            </div>
                          </ul>
                      </div>
                      <div v-if="getFilterLastMonthData.length!=0" class="tab_title">
                          <span>Last Month</span>
                      </div>
                      
                      <div class="doct-list">
                          <ul>
                            <div v-for="(allmedia,index) in getFilterLastMonthData" v-bind:key="index" class="downloadIconWrapper">
                            <li v-if="allmedia.createDate > allmedia.lastMonthDate && allmedia.createDate < allmedia.lastWeekStartDate">
                              <a v-bind:key="allmedia">
                                <img src="../assets/images/pdf-icon.png" v-if="allmedia.type == 'pdf'"/>
                                <img src="../assets/images/Exl-icon.png" v-if="allmedia.type == 'excel'">
                                <img src="../assets/images/Word_Icon.png" v-if="allmedia.type == 'word'">
                              </a>
                              <div class="doc-name">
                                <!-- <p>Coffee</p> -->
                                <div class="name_date">
                                  <div>
                                  <p>{{allmedia.imageOnlyname}}</p>
                                  <span>{{allmedia.name}}</span>
                                  </div>
                                  <div class="download-text_date">
                                  <button type="button" class="btn-without-bg" @click.prevent="downloadDocument(allmedia.media,allmedia.imagename)">Download</button>
                                  <span>{{addZero(allmedia.createdAt.split('/')[0])}}/{{addZero(allmedia.createdAt.split('/')[1])}}/{{addZero(allmedia.createdAt.split('/')[2])}}</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                            </div>
                          </ul>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="link">
                    <div v-if="recentthreelink.length==0 && lastWeekLinks.length==0 && lastMonthLinks.length==0" style="text-align:center" class="tab_title">
                      <span>No Links Found</span>
                    </div>
                    <div v-else>
                      <div v-if="recentthreelink.length!=0" class="tab_title">
                          <span>Recent</span>
                      </div>
                      
                      <div class="doct-list">
                          <ul class="link_main_tab">
                            <div v-for="(allmedia,index) in recentthreelink" v-bind:key="index" class="downloadIconWrapper">
                              <li v-if="allmedia.createdAt > allmedia.lastWeekEndDate && allmedia.createdAt <= new Date()" >
                                <div class="doc-name">
                                  <!-- <p>Coffee</p> -->
                                  <div class="name_date">
                                    <div class="linkName">
                                      <a :href="allmedia.media" target="_blank">{{allmedia.media}}</a>
                                    </div>
                                    <div class="download-text_date">
                                    <span>{{addZero(allmedia.createDate.split('/')[0])}}/{{addZero(allmedia.createDate.split('/')[1])}}/{{addZero(allmedia.createDate.split('/')[2])}}</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </div>
                          </ul>
                      </div>
                      <div v-if="lastWeekLinks.length!=0" class="tab_title">
                          <span>Last week</span>
                      </div>
                      
                      <div class="doct-list">
                          <ul class="link_main_tab">
                            <div v-for="(allmedia,index) in lastWeekLinks" v-bind:key="index" class="downloadIconWrapper">
                                <li v-if="allmedia.createdAt >= allmedia.lastWeekStartDate && allmedia.createdAt <= allmedia.lastWeekEndDate">
                                  <div class="doc-name">
                                    <!-- <p>Coffee</p> -->
                                    <div class="name_date">
                                      <div class="linkName">
                                        <a :href="allmedia.media" target="_blank">{{allmedia.media}}</a>
                                      </div>
                                      <div class="download-text_date">
                                        <span>{{addZero(allmedia.createDate.split('/')[0])}}/{{addZero(allmedia.createDate.split('/')[1])}}/{{addZero(allmedia.createDate.split('/')[2])}}</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                            </div>
                          </ul>
                      </div>
                      <div v-if="lastMonthLinks.length!=0" class="tab_title">
                          <span>Last Month</span>
                      </div>
                      
                      <div class="doct-list">
                          <ul class="link_main_tab">
                            <div v-for="(allmedia,index) in lastMonthLinks" v-bind:key="index" class="downloadIconWrapper">
                              <li v-if="allmedia.createdAt > allmedia.lastMonthDate && allmedia.createdAt < allmedia.lastWeekStartDate">
                                <div class="doc-name">
                                  <!-- <p>Coffee</p> -->
                                  <div class="name_date">
                                    <div class="linkName">
                                  <a :href="allmedia.media" target="_blank">{{allmedia.media}}</a>
                                    </div>
                                    <div class="download-text_date">
                                    <span>{{addZero(allmedia.createDate.split('/')[0])}}/{{addZero(allmedia.createDate.split('/')[1])}}/{{addZero(allmedia.createDate.split('/')[2])}}</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </div>
                          </ul>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
          </div>
      </div>
      <div class="sidebar-content-3" v-if="opensidebarForReceipt == true">
        <!-- {{readReceiptObject}} -->
          <div class="read_message message right">
            <p v-if="readReceiptObject.type == 'Text' " >{{readReceiptObject.sendermsg}}</p>
            <p v-if="readReceiptObject.type == 'Link' "><a :href="readReceiptObject.sendermsg" target="_blank">{{readReceiptObject.sendermsg}}</a></p>
            <p v-if="readReceiptObject.type == 'Image' ">
              <img :src="readReceiptObject.mediaUrl">
            </p>
            <p v-if="readReceiptObject.type == 'pdf' ">
              <a  v-bind:key="readReceiptObject" >
                <img src="../assets/images/PDF_file_icon.png" />
              </a>
            </p>
            <p v-if="readReceiptObject.type.toLowerCase() == 'audio' ">
              <!-- {{chatmessage}} -->
              <audio :src="readReceiptObject.sendermsg" preload="auto" type="audio/mpeg" controls>
                <!-- <source :src="chatmessage.receivermsg" type="audio/mp3" style="max-width:100%;height:150px;width:200px;object-fit:contain"> -->
                Your browser does not support the audio element.
              </audio>
            </p>
            <p v-if="readReceiptObject.type == 'excel' ">
            <a  v-bind:key="readReceiptObject">
              <img src="../assets/images/Exl-icon.png" />
            </a>
          </p>
            <p v-if="readReceiptObject.type == 'word' ">
            <a  v-bind:key="readReceiptObject" >
              <img src="../assets/images/Word_Icon.png" />
            </a>
          </p>
            <p v-if="readReceiptObject.type == 'Video' ">
              <video controls="controls" 
                    class="video-stream" 
                    x-webkit-airplay="allow" 
                    :src="readReceiptObject.mediaUrl"
              ></video>
            </p>
            <!-- <p>{{readReceiptObject.sendermsg}}</p> -->
            <div class="message-nt msg-nt-left"><span class="u_time">{{readReceiptObject.Time}}</span></div>
          </div>
          <div class="read_receipts">
            <p>Read Receipts</p>
            <ul v-if="activeGroup && readReceiptObject.messageSeen.length==1">
              <div class="list-item" style="padding-top:10px;height:40px;justify-content:center">  
                <li v-if="activeGroup" style="text-align:center"><span>{{groupMember.length - 1 +' Users are remaining'}}</span></li>
                <li v-else-if="!activeGroup && !checkCurrUserId(readReceiptObject.messageSeen)" style="text-align:center"><span>--</span></li>
                <li v-else style="text-align:center"><span>{{ 1 +' User is remaining'}}</span></li>
                <!-- <li v-else style="text-align:center"><span>{{ 1 +' User is remaining'}}</span></li> -->
              </div>
            </ul>
            
            <ul v-else-if="activeGroup && readReceiptObject.messageSeen.length>1">
              <li v-for="(readPerson,index) in readReceiptObject.messageSeen" v-bind:key="index" >
              
                <div class="list-item" v-if="readPerson.id != userid">
                  <div><img :src="readPerson.avtar" height="40px" width="40px" style="border-radius :50%;object-fit: cover;"><span class="ml-1">{{readPerson.name}}</span></div>
                  <div class="date-readreceipts"><span>{{checkDate(readPerson.messageSeenTime)}}</span></div>
                </div>
              </li>
            </ul>
            <ul v-if="!activeGroup && readReceiptObject.messageSeen.length<=1">
              <div class="list-item" style="padding-top:10px;height:40px;justify-content:center">  
                <li v-if="activeGroup" style="text-align:center"><span>{{groupMember.length - 1 +' Users are remaining'}}</span></li>
                <li v-else-if="!activeGroup && !checkCurrUserId(readReceiptObject.messageSeen)" style="text-align:center"><span>--</span></li>
                <li v-else style="text-align:center"><span>{{ 1 +' User is remaining'}}</span></li>
                <!-- <li v-else style="text-align:center"><span>{{ 1 +' User is remaining'}}</span></li> -->
              </div>
            </ul>
            <ul v-else-if="!activeGroup && checkCurrUserId(readReceiptObject.messageSeen)">
              <li v-for="(readPerson,index) in readReceiptObject.messageSeen" v-bind:key="index" >
                <div class="list-item" v-if="readPerson.id != userid">
                  <div><img :src="readPerson.avtar" height="40px" width="40px" style="border-radius :50%;object-fit: cover;"><span class="ml-1">{{readPerson.name}}</span></div>
                  <div class="date-readreceipts"><span>{{checkDate(readPerson.messageSeenTime)}}</span></div>
                </div>
              </li>
            </ul>
          </div>
      </div>
       <div class="exit_sidebar_wrapper">
        <a  v-if="activeGroup == true && openSidebarForFrontMediaData == true"  @click="confirmationPopup(),hide()" class="exit_group" >Exit Group</a>
       </div>
    </template>
    </b-sidebar>
  </div>
</template>
<script>

import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import firebase from "../views/firebaseInit"
import firebaseTimestamp from "firebase";
import firebaseData from '../utils/firebaseCollection.js';
import {getMediaData,getCountFunction,getRecentData,getRecentThreeData,getMediaImages,exitGroup,getgroupmember,getRecentThreedocument,getLinkData,getRecentThreeLink} from '../utils/getSidebarMedia.js';
const db = firebase.firestore()
import Swal from 'sweetalert2'

import { mapGetters } from "vuex";

export default {
  components:{
    firebaseData,
     Hooper,
      Slide
  },
  props: [
    "chatObject",
    "activeGroup",
    // 'groupData',
  ],
  data() {
    return {
      // lastWeekStartDateMedia:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
      lastWeekEndDateMedia:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
      // lastMonthDateMedia: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
      preview: null,
      image: null,
      preview_list: [],
      image_list: [],
      openedit:false,
      groupName:'',
      groupMember:[],
      chatId:'',
      MediaDataArray:[],
      AllMediaData:[],
      recentMediaData:[],
      recentThreeData:[],
      mediaImages:[],
      lastMedia:[],
      lastWeekMedia:[],
      lastMonthMedia:[],
      lastLinks:[],
      lastWeekLinks:[],
      lastMonthLinks:[],
      openSidebarForMediaData:false,
      openSidebarForFrontMediaData:true,
      groupCreateTime:'',
      keyword:'',
      // groupData:{},
      mutechat:false,
      recentThreedoc:[],
      LinkArray:[],
      recentthreelink:[],
      opensidebarForReceipt:false,
      readReceiptObject : {},
      userid : '',
      lastWeekMedia:[],
      lastMonthMedia:[],
      currentUser:'',
      counterArray : [],
      adminName:'',
      profileErrorMessage:'',
      charecterErrorMessage:''
      
    };
  },
  created(){
    // //debugger // eslint-disable-line
    var self = this;
    self.counterArray = []
    self.userid = firebase.auth().currentUser.uid

    //if member added or removed using header
    self.$root.$on("getMembersOfGroup",(connectedUser)=>{
      // console.log("Group member updated from header",self.groupData.connectedUser)
      getgroupmember(firebaseData.firebasecollection.USER,connectedUser,self.groupMember,res=>{
        self.groupMember = res
      })
    })


    self.$root.$on("openSidebarForReadRecipt",(value) => {
        // //debugger // eslint-disable-line
      self.readReceiptObject = value
      //console.log(self.readReceiptObject);
      self.opensidebarForReceipt = true
      self.openSidebarForFrontMediaData = false
      self.openSidebarForMediaData = false
       self.readReceiptObject.messageSeen.sort((book1, book2) => {
        return self.compareObjects(book1, book2, 'messageSeenTime')
      })
    })
    
    self.$root.$on("groupArray",(collectionName,value,id)=>{
      //  debugger // eslint-disable-line
      self.chatId = '';
      // self.groupData = value
      self.chatId = id
      self.groupMember = []
      self.MediaDataArray = []
      // console.log('shgroupData',collectionName,value,id);
      self.getMediaData(self.chatId,collectionName);
      self.init();
    })
    self.$root.$on("sendgroupArray",(collectionName,value,id)=>{
      //  //debugger // eslint-disable-line
      self.chatId = '';
      self.activeGroup = true
     
      // self.groupData = value
      self.chatId = id
      self.groupMember = []
      self.MediaDataArray = []
      // //console.log('shgroupData',self.groupData,self.chatId);
      self.getMediaData(self.chatId,collectionName);
      self.init();

      self.currentUser=''
      
    })
  },
  methods: {
    // REMOVE MEMBER FROM GROUP (Shraddha)
    removeGroupMember(oldmember,index){
       // //debugger // eslint-disable-line
       var self=this;
      const db = firebase.firestore();
      //console.log('********',self.groupMember,oldmember,self.groupChatId,index)
      var replacegroupArray = []
      for(var k=0; k<self.groupData.connectedUser.length;k++){
        if(self.groupData.connectedUser[k] != oldmember.id){
          replacegroupArray.push(self.groupData.connectedUser[k]);
        }
      }
      self.groupData.messageSeenIds.forEach(data=>{
        if(data.id != self.userid)
        {
          data.count++
        }
      })
      var messageSeenIds = self.groupData.messageSeenIds||[]
      messageSeenIds.forEach((data,index)=>{
        if(data.id == oldmember.id)
        {
          messageSeenIds.splice(index,1)
        }
      })

      var mainBranches = self.groupData.mainBranches||[]

      mainBranches.forEach((data)=>{
        data.messageSeenIds.forEach((msgid,index)=>{
          if(msgid.id == oldmember.id)
          {
            data.messageSeenIds.splice(index,1)
          }
        })
        data.subBranches.forEach((data2)=>{
          data2.messageSeenIds.forEach((msgid2,index2)=>{
            if(msgid2.id == oldmember.id)
            {
              data2.messageSeenIds.splice(index2,1)
            }
          })
          data2.subSubBranches.forEach((data3)=>{
            data3.messageSeenIds.forEach((msgid3,index3)=>{
              if(msgid3.id == oldmember.id)
              {
                data3.messageSeenIds.splice(index3,1)
              }
            })
          })
        })            
      })
      // console.log('removedUser',self.groupData)
      //console.log('replacegroupArray',replacegroupArray)
      db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.groupData.id).update({
        connectedUser:replacegroupArray,
        messageSeenIds:messageSeenIds,
        mainBranches:mainBranches
      }).then(() => {
        self.groupMember.splice(index,1);
        var from={
          avatar:'',
          id:self.groupData.adminId,
          name:self.groupData.adminName,
          path:''
        }
        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.groupData.id).collection('default').add({
          createdAt:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
          messageSeen:[from],
          msg:`${self.groupData.adminName} removed ${oldmember.firstName} ${oldmember.lastName}`,
          from:from,
          type:'Left',
          name:'Left',
        })
        .then((docRef)=>{
          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.groupData.id).collection('default').doc(docRef.id).update({
            id:docRef.id
          })
          // getgroupmember(firebaseData.firebasecollection.USER,self.groupData.connectedUser,self.groupMember,res=>{
            //   self.groupMember = res
          // })
        })
        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.groupData.id).get().then(snap => {
          var gData = snap.data()
          var chatdata = {}
          var mainBranchArray = []
          self.$root.$emit('memberRemovedfromSidebar',self.groupData.connectedUser);
          self.$root.$emit('groupcreate','yes');
        }).then(() => {
          self.$root.$emit("audioplay");
          self.$notify({
            title:"Success",
            text:`${oldmember.firstName} ${oldmember.lastName} removed successfully.`,
            type:"success"
          })
        })
      })
      
    },
    checkGroupMembers(groupMember)
    {
      var tmp = []
      // console.log('GroupMember',groupMember)
      groupMember.forEach(data=>{
        if(data!=undefined)
        {
          tmp.push(data)
          if(data.id == firebase.auth().currentUser.uid)
          {
            this.currentUser = data
            // console.log('CurrentUser',this.currentUser)
          }
          if(data.id==this.groupData.adminId)
          {
            this.adminName=data
            // console.log('admin',this.adminName)
          }
        }
      })

      return tmp
    },
    checkCurrUserId(messageSeens)
    {
      var uid = firebase.auth().currentUser.uid
      var found = false

      messageSeens.forEach(data=>{
        if(data.id == uid)
        {
          found = true
        }
      })

      return found
    },
    compareObjects(object1, object2, key) {
      const obj1 = object1[key];
      const obj2 = object2[key];
      if (obj1 < obj2) {
        return -1
      }
      if (obj1 > obj2) {
        return 1
      }
      return 0
    },
    addZero(val)
    {
        if(val<10)
        {
          return '0'+val;
        }
        else
        {
          return val;
        }
    },
    //check mute / unmute group (Shiv B.Joshi)
    checkMute(array)
    {
      //console.log('ABCD')
      var self = this
      var uid = self.userid
      var muted = false
      //console.log(array)

      array.forEach(data=>{
        if(data == uid)
        {
          muted = true
        }
        else
        {
          muted = false
        }
      })
      if(!muted)
      {
        self.mutechat = false
      }
      else
      {
        self.mutechat = true
      }
    },
    muteUnmute(docId)
    {

      var self = this
      //console.log('======',docId)
      var muted = false
      var uid = self.userid
      var muteArrayData = []
      
      if(self.mutechat)
      {
        db
          .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
          .doc(docId)
          .get().then(doc=>{
            muteArrayData = doc.data().muteArray||[]
            var mainBranchesData = doc.data().mainBranches

            muteArrayData.forEach(data=>{
              if(data == uid)
              {
                muted = true
              }
            })

            if(muted)
            {
              for (let i = 0; i < muteArrayData.length; i++) {
                if(muteArrayData[i] == uid)
                {
                  muteArrayData.splice(i,1)
                }
              }
              for (let i = 0; i < mainBranchesData.length; i++) {
                for (let j = 0; j < mainBranchesData[i].muteArray.length; j++) {
                  if(mainBranchesData[i].muteArray[j]== uid)
                  {
                    mainBranchesData[i].muteArray.splice(j,1)
                  }
                }
                for (let l = 0; l < mainBranchesData[i].subBranches.length; l++) {
                  for (let m = 0; m < mainBranchesData[i].subBranches[l].muteArray.length; m++) {
                    if(mainBranchesData[i].subBranches[l].muteArray[m]== uid)
                    {
                      mainBranchesData[i].subBranches[l].muteArray.splice(m,1)
                    }
                  }
                  for (let n = 0; n < mainBranchesData[i].subBranches[l].subSubBranches.length; n++) {
                    for (let o = 0; o < mainBranchesData[i].subBranches[l].subSubBranches[n].muteArray.length; o++) {
                      if(mainBranchesData[i].subBranches[l].subSubBranches[n].muteArray[o]== uid)
                      {
                        mainBranchesData[i].subBranches[l].subSubBranches[n].muteArray.splice(o,1)
                      }
                    }
                  }
                }
              }
              
              db
                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                .doc(docId)
                .update({
                  muteArray:muteArrayData,
                  mainBranches:mainBranchesData
                })
                .then(()=>{
                  self.$root.$emit("audioplay");
                  self.$notify({
                    title:"Success",
                    text:'Unmute successfully.',
                    type:'success'
                  })
                })
                .catch(error=>{
                  // console.log(error)
                })
            }
          })
          .catch(error=>{
            // console.log(error)
          })
      }
      else
      {
        db
          .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
          .doc(docId)
          .get().then(doc=>{
            muteArrayData = doc.data().muteArray||[]
            var mainBranchesData = doc.data().mainBranches

            muteArrayData.forEach(data=>{
              if(data == uid)
              {
                muted = true
              }
            })

            //console.log("Muted",muted)

            if(!muted)
            {
              muteArrayData.push(uid)

              mainBranchesData.forEach(data=>{
                var mainMuted = false
                data.muteArray.forEach(dt=>{
                  if(dt == uid)
                  {
                    mainMuted = true
                  }
                })
                if(!mainMuted)
                {
                  data.muteArray.push(uid)

                  data.subBranches.forEach(data2=>{
                    var subMuted = false
                    data2.muteArray.forEach(dt2=>{
                      if(dt2 == uid)
                      {
                        subMuted = true
                      }
                    })
                    if(!subMuted)
                    {
                      data2.muteArray.push(uid)

                      data2.subSubBranches.forEach(data3=>{
                        var subSubMuted = false
                        data3.muteArray.forEach(dt3=>{
                          if(dt3 == uid)
                          {
                            subSubMuted = true
                          }
                        })
                        if(!subSubMuted)
                        {
                          data3.muteArray.push(uid)
                        }
                      })
                    }
                  })
                }
              })
              
              db
                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                .doc(docId)
                .update({
                  muteArray:muteArrayData,
                  mainBranches:mainBranchesData
                })
                .then(()=>{
                  self.$root.$emit("audioplay");
                  self.$notify({
                    title:"Success",
                    text:'Mute successfully.',
                    type:'success'
                  })
                })
                .catch(error=>{
                  // console.log(error)
                })
            }
          })
          .catch(error=>{
            // console.log(error)
          })
      }
    },
   // GET GROUP MEMBER( Shraddha)
    init(){
      // debugger // eslint-disable-line
      var self = this;

      self.groupMember = [];

      // console.log('Check Mute/Unmute',self.groupData,self.groupMember)
      // self.mutechat = false
      if(self.chatId != undefined && self.chatId != ''){
        // console.log('self.chatIdself.chatId',self.chatId)
      db
        .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
        .doc(self.chatId)
        .get().then((doc)=>{
          if(doc.exists){
          var array = doc.data().muteArray||[]
            if(array!=[])
            {
              self.checkMute(array)
            }
            else
            {
              self.mutechat = false
            }
          }
        })
      
      if(Object.keys(self.groupData).length > 0){
        // console.log(self.groupData,self.groupMember)
        getgroupmember(firebaseData.firebasecollection.USER,self.groupData.connectedUser,self.groupMember,res=>{
          self.groupMember = res
        })
        if(self.groupData.groupcreatedate != undefined){
          var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
          self.groupCreateTime = (new Date(self.groupData.groupcreatedate.seconds * 1000).getDate()+' '+ months[new Date(self.groupData.groupcreatedate.seconds * 1000).getMonth()]+' '+ new Date(self.groupData.groupcreatedate.seconds * 1000).getFullYear());
        }
      }
      }
    },
    // FOR GET MEDIA DOCUMENT AND LINK DATA
    getMediaData(chatId,collectionName){
      // //debugger // eslint-disable-line
      var self = this;
      self.counterArray = []
      // const db = firebase.firestore()
      self.MediaDataArray = [],
      self.recentMediaData = [],
      self.fiveMediaData = []
      getMediaData(firebaseData.firebasecollection.SOCIALMESSAGE,collectionName,self.MediaDataArray,chatId,res => {
        self.MediaDataArray = res
        self.counterArray = []
        self.MediaDataArray.forEach(countData => {
          if(countData.type != 'Text'&& countData.type.toLowerCase() != 'surpriseselfie'&& countData.type.toLowerCase() != "surpriseselfietext" && countData.type.toLowerCase() != 'audio' &&  countData.type.toLowerCase() != 'selfiedeclientext' && countData.type.toLowerCase() != 'html'  && countData.type != 'TextPoll' && countData.type != 'ImagePoll' && countData.type.toLowerCase() != 'left'){
            self.counterArray.push(countData)
          }
        })
        getCountFunction(self.MediaDataArray,self.AllMediaData,res=>{
          self.AllMediaData = res
         
          getMediaImages(self.MediaDataArray,self.mediaImages,res=>{
            self.mediaImages = res
            self.lastWeekMedia=[]
            self.lastMonthMedia=[]
            // console.log('MediaData',res,data.createdAt >= data.lastWeekStartDate && data.createdAt <= data.lastWeekEndDate,data.createdAt > data.lastMonthDate && data.createdAt < data.lastWeekStartDate)
            res.forEach(data=>{
              if(data.createdAt >= data.lastWeekStartDate && data.createdAt <= data.lastWeekEndDate)
              {
                self.lastWeekMedia.push(data)
              }
              if(data.createdAt > data.lastMonthDate && data.createdAt < data.lastWeekStartDate)
              {
                self.lastMonthMedia.push(data)
              }
            })
            // getLinkData(self.MediaDataArray,res=>{
            //   self.LinkArray = res
            //   self.lastWeekLinks=[]
            //   self.lastMonthLinks=[]
            //   res.forEach(links=>{
            //     if(links.createdAt >= links.lastWeekStartDate && links.createdAt <= links.lastWeekEndDate)
            //     {
            //       self.lastWeekLinks.push(links)
            //     }
            //     if(links.createdAt > links.lastMonthDate && links.createdAt < links.lastWeekStartDate)
            //     {
            //       self.lastMonthLinks.push(links)
            //     }
            //   })
            // })
          })
        })
      })
      getRecentData(firebaseData.firebasecollection.SOCIALMESSAGE,collectionName,self.recentMediaData,chatId,res=>{
          self.recentMediaData = res
            self.recentMediaData.sort((book1, book2) => {
              return self.compareObjects(book1, book2, 'createdAt')
            })
          // //console.log('getRecentData', self.recentMediaData )
        getRecentThreeData(firebaseData.firebasecollection.SOCIALMESSAGE,collectionName,self.recentThreeData,chatId,res=>{
             self.recentThreeData = []
             res.forEach(media=>{
               if(media.createdAt ? new Date(media.createdAt.seconds * 1000) : '' >= self.lastWeekEndDateMedia && media.createdAt ? new Date(media.createdAt.seconds * 1000) : '' <= new Date())
               {
                self.recentThreeData.push(media)
               }
             })
             self.recentThreeData.sort((book1, book2) => {
              return self.compareObjects(book1, book2, 'createdAt')
            })
            getRecentThreeLink(firebaseData.firebasecollection.SOCIALMESSAGE,collectionName,chatId,res=>{
              self.recentthreelink = []
              res.forEach(links=>{
                if(links.createdAt > links.lastWeekEndDate && links.createdAt <= new Date())
                {
                  self.recentthreelink.push(links)
                }
              })
              self.recentthreelink.sort((book1, book2) => {
                return self.compareObjects(book1, book2, 'createdAt')
              })
            })
        })
      })
      getRecentThreedocument(firebaseData.firebasecollection.SOCIALMESSAGE,collectionName,self.recentThreedoc,chatId,res=>{
        self.recentThreedoc = []
        res.forEach(docs=>{
          if(docs.createDate > docs.lastWeekEndDate && docs.createDate <= new Date())
          {
            self.recentThreedoc.push(docs)
          }
        })
        self.recentThreedoc.sort((book1, book2) => {
          return self.compareObjects(book1, book2, 'createdAt')
        })
      })  
    },
   // PRIVIEW AND UPDATE GROUP PROFILE IN DATABASE(Shraddha)
    previewImage: function (event) {
      // debugger // eslint-disable-line
      const db = firebase.firestore()
      const storageRef = firebase.storage().ref();
      var input = event.target;
      // if (input.files) {
      //   var reader = new FileReader();
      //   reader.onload = (e) => {
      //     this.preview = e.target.result;
      //   };
      //   this.image = input.files[0];
      //   reader.readAsDataURL(input.files[0]);
      // }
      if (input.files) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.image = input.files[0];
            var docExtention = this.image.type.split('/');
            if(docExtention[0] != 'image' || docExtention[1] == 'gif'){
              this.preview = this.groupData.profileUrl;
              this.profileErrorMessage = 'Only .jpg,.png,.jpeg files are allowed.';
            }else{
              this.preview = e.target.result
              this.profileErrorMessage = '';
            }
          this.image = input.files[0];
        //console.log(this.groupData)
        if(this.image != '' && this.profileErrorMessage == ''){
          var ImageNameSplit = this.image.name.split('.')
          var str = (
              Math.random().toString(36).substring(2, 8) +
              Math.random().toString(36).substring(2, 8)
          ).toUpperCase();
          var ImageName=""
          if(ImageNameSplit.length>2){
              for (let index = 0; index < ImageNameSplit.length-1; index++) {
                  ImageName += `${ImageNameSplit[index]}`
              }
              ImageName+=`_${str}.${ImageNameSplit[ImageNameSplit.length-1]}`
          }
          else{
              ImageName=`${ImageNameSplit[0]}_${str}.${ImageNameSplit[1]}`
          }
          var uploadTask = storageRef.child(firebaseData.firebasecollection.PROFILEIMAGES+ImageName).put(this.image)
          uploadTask.on('stateChanged',
          (snapshot)=>{
            // //console.log('snapshot',snapshot)
              var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
              //console.log(progress)
          },
          (error)=>{
            this.$notify({
                title:"Error",
                text:error.message,
                type:"error"
            })
          },
          ()=>{
            uploadTask.snapshot.ref.getDownloadURL()
            .then(URL=>{
              self.image=URL
              db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(this.groupData.id).update({
                profileUrl : URL
              }).then(() => {
            
                this.$root.$emit('groupcreate','Yes')
                var objectid = ''
                this.$root.$emit('changeGroupInfo',objectid,this.groupData.id)
                  this.$root.$emit("audioplay");
                  this.$notify({
                  title:"Success",
                  text:'Group profile update Successfully',
                  type:"success"
                })
              })
            })
          })
        }
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewMultiImage: function (event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          this.image_list.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
      }
    },
    reset: function () {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    },
    //OPEN EDIT NAME INPUT (Shraddha)
    openEditName(titlename){
        // //debugger // eslint-disable-line
        var self = this;
        self.groupName = titlename
        self.openedit = true;
    },
    // CLOSE OPEN EDIT (Shraddha)
    closeEdit(){
        // //debugger // eslint-disable-line
        var self = this;
        self.openedit = false;
    },
    //CHECK GROUP NAME CHRECTER LENGTH(Shraddha)
    checkChrecterLength(groupName){
      var self = this;
      if(groupName.length > 20){
        self.charecterErrorMessage = 'The group name may not be greater than 20 characters';
      }else{
        self.charecterErrorMessage = '';
      }
    },
    // EDIT GROUPNAME (Shraddha)
    editGroupName(){
      // debugger // eslint-disable-line
      var self = this;
      if(self.charecterErrorMessage == ''){
        if(self.groupName != ''){
        const db = firebase.firestore()
        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.groupData.id).update({
          title : self.groupName,
        }).then(() => {
          var objectid = ''
          self.$root.$emit('groupcreate','Yes')
          self.$root.$emit('changeGroupInfo',objectid,self.groupData.id);
          self.$root.$emit("audioplay");
          self.$notify({
            title:"Success",
            text:'group name changed Successfully',
            type:"success"
          })
          self.openedit=false
        })
        }else{
          self.$root.$emit("audioplay");
          self.$notify({
            title:"Error",
            text:'group name is required',
            type:"error"
          }) 
        }
      }
    },
    //OPEN SIDEBAR MEDIA(Shraddha)
    openSidebarForMedia(){
      // //debugger // eslint-disable-line
      var self = this;
      self.openSidebarForMediaData = true;
      self.openSidebarForFrontMediaData = false
    },
    //FOR DECENDING ORDER DISPLAY(Shraddha)
    compareObjects(object1, object2, key) {
      const obj1 = object1[key];
      const obj2 = object2[key];
      if (obj1 > obj2) {
        return -1
      }
      if (obj1 < obj2) {
        return 1
      }
      return 0
    },
    //EXIT GROUP(Shraddha)
    confirmationPopup()
    {
      var self = this
      // console.log('groupData',self.groupData,self.groupData.adminId == firebase.auth().currentUser.uid)
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      if(self.groupData.adminId == firebase.auth().currentUser.uid){
        Swal.fire({
          title: "Are you sure you want to delete the "+self.groupData.title+' group'+"?",
          type: "warning",
          customClass:{
            container :"social_confirmation_popup"
          },
          showCancelButton: true,
          confirmButtonColor: "#ffab3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.value) {
            self.deleteGroup(self.groupData)
          }
        })
      }
      else
      {
        Swal.fire({
          title: "Are you sure you want to exit the "+self.groupData.title+' group'+"?",
          type: "warning",
          customClass:{
            container :"social_confirmation_popup"
          },
          showCancelButton: true,
          confirmButtonColor: "#ffab3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.value) {
            self.IsExitGroup()
          }
        })
      }
    },
    removeGroupMemberConfirmation(groupmember,index)
    {
      debugger // eslint-disable-line
      var self = this
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      Swal.fire({
        title: `Are you sure you want to remove ${groupmember.firstName} ${groupmember.lastName} from this group?`,
        type: "warning",
        customClass:{
          container :"social_confirmation_popup"
        },
        showCancelButton: true,
        confirmButtonColor: "#ffab3f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.value) {
          self.removeGroupMember(groupmember,index)
        }
      })
    },
    IsExitGroup(){
      // //debugger // eslint-disable-line
      var self = this;
      // console.log('dfdsf',self.groupName)
      var uid = firebase.auth().currentUser.uid
      var userData = self.userData
      const db = firebase.firestore()
      // self.MediaDataArray = [];
      // self.AllMediaData = [];
      // self.mediaImages = []
      var loginUser = localStorage.getItem('customerDocKey')
      //console.log(self.chatId,loginUser)
      exitGroup(loginUser,self.groupData.connectedUser,res => {
        //console.log('rererererer',res)
        var from={
          avatar:'',
          id:userData.id,
          name:`${userData.firstName} ${userData.lastName}`,
          path:''
        }
        var messageSeenIds = self.groupData.messageSeenIds||[]
        messageSeenIds.forEach((data,index)=>{
          if(data.id == uid)
          {
            messageSeenIds.splice(index,1)
          }
        })

        var mainBranches = self.groupData.mainBranches||[]

        mainBranches.forEach((data)=>{
          data.messageSeenIds.forEach((msgid,index)=>{
            if(msgid.id == uid)
            {
              data.messageSeenIds.splice(index,1)
            }
          })
          data.subBranches.forEach((data2)=>{
            data2.messageSeenIds.forEach((msgid2,index2)=>{
              if(msgid2.id == uid)
              {
                data2.messageSeenIds.splice(index2,1)
              }
            })
            data2.subSubBranches.forEach((data3)=>{
              data3.messageSeenIds.forEach((msgid3,index3)=>{
                if(msgid3.id == uid)
                {
                  data3.messageSeenIds.splice(index3,1)
                }
              })
            })
          })            
        })
        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.chatId).collection('default').add({
          createdAt:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
          messageSeen:[from],
          msg:`${userData.firstName} ${userData.lastName} left.`,
          from:from,
          type:'Left',
          name:'Left',
        })
        .then((docRef)=>{
          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.chatId).collection('default').doc(docRef.id).update({
            id:docRef.id
          })
        })
        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.chatId).update({
          connectedUser : res,
          messageSeenIds:messageSeenIds,
          mainBranches:mainBranches
        }).then(()=>{
          self.init();
          self.$root.$emit('hideViewDetail','objectid','groupchatId');
          self.$root.$emit('activeGroup' ,'yes');
          // var groupObjectData = {}
          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.chatId).get().then(snap => {
            groupObjectData = snap.data()
            self.$root.$emit('groupArray','default',groupObjectData,self.chatId || '')
          })
            self.$root.$emit("audioplay");
            self.$notify({
              title:"Success",
              text:'Group exist Successfully',
              type:"success"
            })
        
        })
      })
    },
    deleteGroup()
    {
      var self = this
      db
        .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
        .doc(self.groupData.id)
        .update({
          isDeleted:true
        })
        .then(()=>{
          self.$root.$emit("audioplay");
          self.$notify({
            title:"Success",
            text:'Group deleted Successfully',
            type:"success"
          })
          self.$root.$emit('activeGroup' ,'yes');
          self.$root.$emit('hideViewDetail',objectid,chattedId)
        })
        .catch(error=>{
          // console.log(error)
        })
    },
      //FOR DOWNLOAD DOCUMENT(Shraddha)
    downloadDocument(url,name){
      // //debugger // eslint-disable-line
      this.$axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download',name);
            document.body.appendChild(fileLink);

            fileLink.click();
        })
        .catch(error=>{
            this.$notify({
              title:"Error",
              text:error.message,
              type:"error"
            })
        })
    },
    muteButton(){
      //  //debugger // eslint-disable-line
      var self=this;
      //console.log(self.mutechat)
    },
    //CHECK DATE FOR READ RECIPT(Shraddha)
    checkDate(expireyTimeObjects)
    {
      var expireyTimeObjectsdate = (new Date(expireyTimeObjects.seconds * 1000).getDate())
      var expireyTimeObjectsmonth = (new Date(expireyTimeObjects.seconds * 1000).getMonth())
      var expireyTimeObjectsyear = (new Date(expireyTimeObjects.seconds * 1000).getFullYear())

      var todayDatesplit = new Date().getDate();
      var todayMonthsplit = new Date().getMonth();
      var todayYearsplit = new Date().getFullYear();
      //console.log(expireyTimeObjectsdate,expireyTimeObjectsmonth,expireyTimeObjectsyear)
      if(expireyTimeObjectsdate == todayDatesplit && expireyTimeObjectsmonth == todayMonthsplit &&expireyTimeObjectsyear == todayYearsplit)
      {
        return this.addZero(new Date(expireyTimeObjects.seconds * 1000).getHours())+':'+this.addZero(new Date(expireyTimeObjects.seconds * 1000).getMinutes())
      }
      // else if(expireyTimeObjectsdate+1 == todayDatesplit && expireyTimeObjectsmonth == todayMonthsplit &&expireyTimeObjectsyear == todayYearsplit)
      // {
      //   return 'Yesterday'
      // }
      else
      {
        return this.addZero(expireyTimeObjectsdate)+'/'+ this.addZero(expireyTimeObjectsmonth+1)
      }
    },
  },
  computed: {
    ...mapGetters({userData:"getUserData"}),
    ...mapGetters({groupData:"getChatData"}),
    //LISTING DATA
    getFilterLastThreeData() {
      var self = this;
      return self.AllMediaData.filter(function(rawData) {
        if(rawData.createDate > rawData.lastWeekEndDate && rawData.createDate <= new Date())
        {
          return (
            rawData["imageOnlyname"]
              .toLowerCase()
              .indexOf(self.keyword.toLowerCase()) >= 0 
          );
        }
      });
    },
    getFilterLastWeekData() {
      var self = this;
      return self.AllMediaData.filter(function(rawData) {
        if(rawData.createDate >= rawData.lastWeekStartDate && rawData.createDate <= rawData.lastWeekEndDate)
        {
          return (
            rawData["imageOnlyname"]
              .toLowerCase()
              .indexOf(self.keyword.toLowerCase()) >= 0
          );
        }
      });
    },
    getFilterLastMonthData() {
      var self = this;
      return self.AllMediaData.filter(function(rawData) {
        if(rawData.createDate > rawData.lastMonthDate && rawData.createDate < rawData.lastWeekStartDate)
        {
          return (
            rawData["imageOnlyname"]
              .toLowerCase()
              .indexOf(self.keyword.toLowerCase()) >= 0 
          );
        }
      });
    },
  },
};
</script>
<style scoped>
.groupAdminTitle{
  background-color: #ffab3f;
  padding: 3px 12px;
  border-radius: 25px;
  font-size: 16px;
  color: white;
  float:right;
  margin-top: 6px;
}
.crossIcon{
  /* padding: 3px 12px; */
  /* border-radius: 25px; */
  /* font-size: 16px; */
  /* color: white; */
  float:right;
  margin-top: 6px;
  height: 26px;
  width: 26px;
  cursor: pointer;
}
.groupMemberUndefined{
  border-bottom: none !important;
  padding:0px !important
}
</style>