<template>
    <div>
        <!-- <b-dropdown id="admin_sb">
            <template #button-content>
                <h5>Alian Software<span>Admin</span></h5>
            </template>
            <b-dropdown-item href="#">Alian Software</b-dropdown-item>
            <b-dropdown-item href="#">Business General </b-dropdown-item>
        </b-dropdown>
        <a class="link_add-channel" v-b-modal.add-channel >+ Add Channel</a> -->
        <div class="business_contactList-area">
            <div class="search-area" v-if="selectedActiveMenu=='admin'">
                <form @submit.prevent><div class="form-group">
                    <input type="search" placeholder="Search" class="form-control"  @keyup="searchFilter()"
                    v-model="searchkeyword">
                    <button class=""  @click.prevent><img src="@/assets/images/search.png"></button>
                    </div>
                </form>
            </div>
            <div class="search-area" v-else-if="selectedActiveMenu=='adminChat'">
              <form @submit.prevent>
                <div class="form-group">
                  <input
                    type="search"
                    class="form-control"
                    placeholder="Search"
                    v-model="searchkeyword"
                    @keyup="searchFilter()"
                  />
                  <button  @click.prevent><img src="@/assets/images/search.png" /></button>
                </div>
              </form>
              <img src="@/assets/images/chat-plus.png" v-b-modal.new-chat/>

            </div>
            <a class="link_create-group" v-b-modal.new-chat v-if="selectedActiveMenu=='admin'">+ Create Group</a>
              <div class="business-active-chat-list active-chat-list">
              <h5 v-if="selectedActiveMenu=='adminChat'">Direct Message</h5>
              <ul>
                <li v-for="(activeChatList,index) in chatListArray" v-bind:key="index" v-bind:style="activeChatList.block?'display:none':''">
                 
                 
                  <div class="chat-list-bottom-border" >
                    <div class="user-profile-info">
                      <div class="u-img-time" style="cursor:pointer" @click="IsChatCustomer(activeChatList.customer_id,activeChatList.msgid)">
                        <img v-if="activeChatList.isGroup == false && activeChatList.profileUrl" class="chatprofile" :src="activeChatList.profileUrl"/>
                        <span v-if="activeChatList.isGroup == false && activeChatList.profileUrl == '' " class="chatprofile profilecharecter">{{activeChatList.firstName.charAt(0).toUpperCase()}}</span>
                        <img v-if="activeChatList.isGroup == true && activeChatList.groupProfile" class="chatprofile" :src="activeChatList.groupProfile"/>
                        <span v-if="activeChatList.isGroup == true && activeChatList.groupProfile == '' " class="chatprofile profilecharecter">{{activeChatList.title.charAt(0).toUpperCase()}}</span>
                        <div>
                          <span class="g-name" v-if="activeChatList.isGroup == false">{{activeChatList.firstName}} {{activeChatList.lastName}}
                            <label v-if="!activeChatList.isGroup && activeChatList.isOnline" class="greenDot"></label>
                            <label v-else-if="!activeChatList.isGroup && !activeChatList.isOnline" class="redDot"></label>
                          </span>
                          <span class="g-name" v-else>{{activeChatList.title}}</span>
                          <!-- <span v-if="!activeChatList.isGroup" :style="activeChatList.isOnline?'color:green':'color:red'"><strong>{{activeChatList.isOnline?'Online':'Offline'}}</strong></span> -->
                          <span class="g-timedate" v-if="!beforeDate(activeChatList.lastmsgTime)" >{{activeChatList.lastMsgArrivedTime}}</span>
                          <span class="g-timedate" v-else>{{activeChatList.lastMsgDate}}</span>

                        </div>
                      </div>
                      <div class="d-flex m_icon_wrapper">
                        <div class="m_icon" v-if="activeChatList.isGroup && checkMute(activeChatList.muteArray)">
                          <img src="@/assets/images/group-muted.png">
                        </div>
                      <b-dropdown id="dropdown-offset" text="" class="" v-if="activeChatList.isGroup == true">
                          <b-dropdown-item href="#" @click="sendSidebarData(activeChatList.msgid)"  v-b-toggle.sidebar-right>Rename</b-dropdown-item>
                          <b-dropdown-item href="#" v-if="!checkMute(activeChatList.muteArray||[])" @click.prevent="muteGroup(activeChatList.msgid)">Mute</b-dropdown-item>
                          <b-dropdown-item href="#" v-else @click.prevent="unmuteGroup(activeChatList.msgid)">Unmute</b-dropdown-item>
                          <b-dropdown-item href="#" @click="isExitGroup(activeChatList)">Delete</b-dropdown-item>
                      </b-dropdown>
                        <b-dropdown id="dropdown-offset" text="" class="" v-if="activeChatList.isGroup == false">
                          <li> <router-link v-bind:to="{ name: 'businessuserprofile', params: { id: activeChatList.customer_id }}"  class="dropdown-item">View</router-link></li>
                            <b-dropdown-item href="#" @click="IsChatCustomer(activeChatList.customer_id,activeChatList.msgid)">Chat</b-dropdown-item>
                            <b-dropdown-item href="#" @click="IsBlockCustomer(activeChatList.customer_id,activeChatList)" >Block</b-dropdown-item>
                            <b-dropdown-item href="#" @click="removeFromContact(activeChatList)" >Remove</b-dropdown-item>
                      </b-dropdown>
                      </div>
                    </div>
                    <p class="some-txt" style="cursor:pointer" @click="IsChatCustomer(activeChatList.customer_id,activeChatList.msgid)">
                      <!-- Lorem ipsum dolor sit amet, consect quis nostrud
                      exercitation ullamco... -->
                      {{activeChatList.block == true?'':activeChatList.lastmsg.type=='Text'?activeChatList.lastmsg.msg:activeChatList.lastmsg.type}}
                    </p>
                    <div class="tag-list-wrapper">
                    <div class="tag-list">
                      <hooper :itemsToShow="3">
                          <slide v-for="(val,i) in sortArray(activeChatList.mainBranches)" :key="i">
                          <span style="cursor:pointer" @click.prevent="openBranch(activeChatList.customer_id,activeChatList.msgid,val.name,i)">{{val.name}} {{getMessageCount(val.messageSeenIds)>0?`(${getMessageCount(val.messageSeenIds)})`:''}}</span>
                          </slide>
                          <!-- <slide>
                          <span>Checkout Latest</span>
                          </slide>
                          <slide>
                          <span>Checkout Latest</span>
                          </slide> -->
                      </hooper>
                    </div>
                    <img src="../../assets/images/add-branch.png" style="cursor: pointer;" @click.prevent="createBranchMethod(activeChatList.msgid)" class="black-add-branch">
                    </div>
                    <!-- <div class="tag-list">
                      <span v-for="(val,i) in activeChatList.mainBranches" :key="i">{{val.name}}</span>
                    </div> -->
                  </div>
                </li>
              </ul>
            </div>
            <!-- <BulkmessageComponent></BulkmessageComponent> -->
            <NewChat></NewChat>
            <channel></channel>
            <!-- BULK MESSAGE MODE -->
           <b-modal id="bulkmessage" centered hide-footer title=""  @close="closeFire()" :no-close-on-backdrop="true"> 
            <div class="contact-modal newchat-modal">
            <div class="contact-modal">
            <h4>Bulk Message</h4>
             <ValidationObserver ref="log" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(sendBulkMessage)">
              <ValidationProvider rules="required" name="message" v-slot="{ errors }">
                <div class="form-group addtextarea">
                    <label>Message *</label>
                    <textarea class="form-control" placeholder="Enter message" v-model="sendingMessage" ref="bulkmessage" ></textarea>
                    <span class="InvalidInput">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
                <div class="form-group">
                    <label class="sc">Select Contacts</label>
                    <div class="select-contact-list">
                        <ul>
                            <li v-for="(bulkList,index) in bulkMemberList" v-bind:key="index">
                              {{bulkList.firstName}} {{bulkList.lastName}} 
                              <span class="remove-content"><img src="@/assets/images/user_added.png"></span>
                              <span class="remove-content" @click="removemember(index)"><img src="@/assets/images/user_cancel.png"></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="form-group modal-search">
                    <input type="search" input-id="tags-basic" remove-on-delete  class="form-control" placeholder="Search Contact" v-model="keyword">
                    <img src="../../assets/images/search-icon.png" class="search-icon">
                </div>

                <div class="choose-contact">
                   <div class="scrollbar">
                       <ul class="member-list-sidebar">
                          <li v-for="(bulkUserList,index) in getFilterData" v-bind:key="index" >
                            <div v-if="bulkUserList.block != true"><img :src="bulkUserList.profileUrl"><span class="ml-1">{{bulkUserList.firstName}} {{bulkUserList.lastName}}</span></div>
                            <button type="button" v-if="bulkUserList.block != true" class="btn btn-white" @click="addBrodcastMmeber(bulkUserList)">Add</button>
                           </li>
                        </ul>
                    </div>
                </div>
               
                <div class="btn-wrapper">
                    <button type="button" class="btn btn-yellow" @click="sendBulkMessage()">send</button>
                </div>
            </form>
             </ValidationObserver>
        </div>
        </div>
      </b-modal>
        </div>
    </div>
</template>

<script>
  

// import BulkmessageComponent from "../components/allModal/bulkmessages.vue"
import Vue from 'vue'
import NewChat from "@/components/businessComponent/allBusinessModel/newchat.vue";
import channel from "@/components/businessComponent/allBusinessModel/channel.vue";
import firebaseData from '../../utils/firebaseCollection.js';
import firebase from "../../views/firebaseInit"
import Swal from 'sweetalert2'
import {getActiveChat2,getCount2,getCounting2,Contactlist2,getContactCount2,getActiveMsgArray,getAcseptUserData2} from '../../businessUtills/getQueeryActiveChatList.js'
import {exitGroup} from '../../businessUtills/getSidebarMedia.js'
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

const db = firebase.firestore()

export default ({
  components:{
    // BulkmessageComponent,
    NewChat,
      firebaseData,
      Hooper,
      Slide,
      channel
  },
  props: ["selectedActiveMenu","searchVal"],
  data(){
    return{
      acseptUserData:[],
      getUserData:[],
      socialMessage:[],
      activeChatListArray:[],
      chatObject:{},
      groupObject:{},
      chatListArray:[],
      userSearchList:[],
      getBulkData:[],
      bulkMemberList:[],
      sendingMessage:'',
      keyword:'',
      searchkeyword:this.searchVal,
      searchContactArray:[],
    }
  },
  created(){
     // //debugger // eslint-disable-line
    var self=this;
    //  self.getContactList();
     self.init();
       self.$root.$on("groupcreate",(value)=>{
         //console.log('val',value)
         if(value == 'Yes'){
           self.socialMessage = []
            self.acseptUserData=[];
            self.activeChatListArray = [];
          //  self.getContactList();
         }
      });

      self.$root.$on("grouplist",(value)=>{
         //console.log('grouplistvalue',value, self.chatListArray)
         if(value == 'Yes'){
           self.socialMessage = []
            self.acseptUserData=[];
            self.activeChatListArray = [];
          //  self.getContactList();
         }
      });

      self.$root.$on("changeGroupInfo",(objectid,chatId)=>{
        self.IsChatCustomer(objectid,chatId);
      })

      self.$root.$on('getActiveComapnyList',() => {
        // //debugger // eslint-disable-line
        self.getContactList();
      })
  },
  watch : {
      $route : function (){
        // this.getContactList();
      }
  },
  methods:{
    // FOR ACTIVE BUSINESS OR NOT (Shraddha)
   
    getMessageCount(messageSeenIdsArray)
    {
      var uid = firebase.auth().currentUser.uid
      var count = 0
      messageSeenIdsArray.forEach(data=>{
        if(data.id==uid)
        {
          count = data.count
        }
      })

      return count
    },
    sortArray(mainBranchArray)
    {
      //console.log('sortCalled')
      //console.log('arr',mainBranchArray)

      var tmp = mainBranchArray
      var tmp2 = ''

      for (let index = 0; index < tmp.length; index++) {
        for (let j = index+1; j < tmp.length; j++) {
          if(tmp[index].lastMsgTime.seconds < tmp[j].lastMsgTime.seconds)
          {
            tmp2 = tmp[index]
            tmp[index] = tmp[j]
            tmp[j]=tmp2
          }
        }
      }
      return tmp
      
    },
    openBranch(objectid,chatId,name,index)
    {
      var self = this
      var mainBranches = ''

      self.activeChatListArray.forEach(data=>{
        if(data.msgid==chatId)
        {
          //console.log('=',data,chatId)
          mainBranches = data.mainBranches
        }
      })
      //console.log(chatId,name,index)
      // self.$root.$emit('openBranch',chatId,name,index,self.mainBranches)

      //console.log('check',objectid);
      if(objectid != ''){
        db.collection(firebaseData.firebasecollection.USER).doc(objectid).get().then((doc) =>{
          self.chatObject = doc.data()
          self.groupObject = {}
          //console.log('Chat')
          //console.log('chatId',chatId)
          //console.log('name',name)
          //console.log('index',index)
          //console.log('self.chatObject',self.chatObject)
          //console.log('self.groupObject',self.groupObject)
          //console.log('mainBranches',mainBranches)
          self.$root.$emit('openBranch',chatId,name,index,self.chatObject,self.groupObject,mainBranches);
        })
      }else{
        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatId).get().then((doc) =>{
          self.groupObject = doc.data()
          self.chatObject = {}
          //console.log('Group')
          //console.log('chatId',chatId)
          //console.log('name',name)
          //console.log('index',index)
          //console.log('self.chatObject',self.chatObject)
          //console.log('self.groupObject',self.groupObject)
          //console.log('mainBranches',mainBranches)
          self.$root.$emit('openBranch',chatId,name,index,self.chatObject,self.groupObject,mainBranches);
        })
      }
    },
    createBranchMethod(chatId)
    {
      this.$bvModal.show('create-branch')
      this.$root.$emit('createBranchEvent',chatId)
    },
    checkMute(array)
    {
      var uid = firebase.auth().currentUser.uid
      var muted = false
      //console.log(array)

      array.forEach(data=>{
        if(data == uid)
        {
          muted = true
        }
        else
        {
          muted = false
        }
      })

      return muted
    },
    muteGroup(docId)
    {
      var self = this
      var muted = false
      var uid = firebase.auth().currentUser.uid
      var muteArrayData = []
      var mainBranchesData = []
      db
        .collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
        .doc(docId)
        .get().then(doc=>{
          muteArrayData = doc.data().muteArray||[]

          mainBranchesData = doc.data().mainBranches

          muteArrayData.forEach(data=>{
            if(data == uid)
            {
              muted = true
            }
          })

          //console.log("Muted",muted)

          if(!muted)
          {
            muteArrayData.push(uid)

            mainBranchesData.forEach(data=>{
              var mainMuted = false
              data.muteArray.forEach(dt=>{
                if(dt == uid)
                {
                  mainMuted = true
                }
              })
              if(!mainMuted)
              {
                data.muteArray.push(uid)

                data.subBranches.forEach(data2=>{
                  var subMuted = false
                  data2.muteArray.forEach(dt2=>{
                    if(dt2 == uid)
                    {
                      subMuted = true
                    }
                  })
                  if(!subMuted)
                  {
                    data2.muteArray.push(uid)

                    data2.subSubBranches.forEach(data3=>{
                      var subSubMuted = false
                      data3.muteArray.forEach(dt3=>{
                        if(dt3 == uid)
                        {
                          subSubMuted = true
                        }
                      })
                      if(!subSubMuted)
                      {
                        data3.muteArray.push(uid)
                      }
                    })
                  }
                })
              }
            })
            // console.log('mainBranchesData123',mainBranchesData)
            db
              .collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
              .doc(docId)
              .update({
                muteArray:muteArrayData,
                mainBranches:mainBranchesData
              })
              .then(()=>{
                self.$notify({
                  title:"Success",
                  text:'Mute successfully.',
                  type:'success'
                })
                self.IsChatCustomer('',docId)
              })
              .catch(error=>{
                console.log(error)
              })
          }
        })
        .catch(error=>{
          console.log(error)
        })
    },
    unmuteGroup(docId)
    {
      var self = this
      var muted = false
      var uid = firebase.auth().currentUser.uid
      var muteArrayData = []
      var mainBranchesData = []
      db
        .collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
        .doc(docId)
        .get().then(doc=>{
          muteArrayData = doc.data().muteArray||[]
          mainBranchesData = doc.data().mainBranches

          muteArrayData.forEach(data=>{
            if(data == uid)
            {
              muted = true
            }
          })

          if(muted)
          {
            for (let i = 0; i < muteArrayData.length; i++) {
              if(muteArrayData[i] == uid)
              {
                muteArrayData.splice(i,1)
              }
            }

            for (let i = 0; i < mainBranchesData.length; i++) {
              for (let j = 0; j < mainBranchesData[i].muteArray.length; j++) {
                if(mainBranchesData[i].muteArray[j]== uid)
                {
                  mainBranchesData[i].muteArray.splice(j,1)
                }
              }
              for (let l = 0; l < mainBranchesData[i].subBranches.length; l++) {
                for (let m = 0; m < mainBranchesData[i].subBranches[l].muteArray.length; m++) {
                  if(mainBranchesData[i].subBranches[l].muteArray[m]== uid)
                  {
                    mainBranchesData[i].subBranches[l].muteArray.splice(m,1)
                  }
                }
                for (let n = 0; n < mainBranchesData[i].subBranches[l].subSubBranches.length; n++) {
                  for (let o = 0; o < mainBranchesData[i].subBranches[l].subSubBranches[n].muteArray.length; o++) {
                    if(mainBranchesData[i].subBranches[l].subSubBranches[n].muteArray[o]== uid)
                    {
                      mainBranchesData[i].subBranches[l].subSubBranches[n].muteArray.splice(o,1)
                    }
                  }
                }
              }
            }
            
            db
              .collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
              .doc(docId)
              .update({
                muteArray:muteArrayData,
                mainBranches:mainBranchesData
              })
              .then(()=>{
                self.$notify({
                  title:"Success",
                  text:'Unmuted successfully.',
                  type:'success'
                })
                self.IsChatCustomer('',docId)
              })
              .catch(error=>{
                console.log(error)
              })
          }
        })
        .catch(error=>{
          console.log(error)
        })
    },
    // CHECK IF LASTMESSAGE DATE IS PAST OR NOT(Shraddha)
    beforeDate(lastmessagedate){
      // //debugger // eslint-disable-line
        var self=this;
        //console.log(lastmessagedate)
          var Datesplit = (new Date(lastmessagedate.seconds * 1000).getDate())
          var monthsplit = (new Date(lastmessagedate.seconds * 1000).getMonth())
          var yearsplit = (new Date(lastmessagedate.seconds * 1000).getFullYear())
          var todayDatesplit = new Date().getDate();
          var todayMonthsplit = new Date().getMonth();
          var todayYearsplit = new Date().getFullYear();
          //console.log('mainmaianmaja',Datesplit,monthsplit,yearsplit)
        //console.log('mainmsfsewerwerewrwerweaianmaja',todayDatesplit,todayMonthsplit,todayYearsplit)
        var result = false
      // //console.log('Condition1',expireyTimeObjects.date == self.splitDateObject.date ,expireyTimeObjects.month == self.splitDateObject.month ,expireyTimeObjects.year == self.splitDateObject.year ,expireyTimeObjects.hrs == self.todayTime.hrs , expireyTimeObjects.min <= self.todayTime.min)
      // //console.log('Condition2',expireyTimeObjects.date < self.splitDateObject.date , expireyTimeObjects.month <= self.splitDateObject.month , expireyTimeObjects.year <= self.splitDateObject.year)
      if(todayDatesplit > Datesplit && todayMonthsplit >= monthsplit && todayYearsplit >= yearsplit)
      {
        // //console.log('else if')
        result = true
      }
      
      else
      {
        // //console.log('else')
        result = false
      }
      // //console.log('=========Expired:',result)
      return result
    },
    //GET CONTACT LIST(Shraddha)
    getContactList(){
        // //debugger // eslint-disable-line
        var self=this;
        self.socialMessage = []
        self.activeChatListArray = [];
          self.acseptUserData=[];
        const db = firebase.firestore();
        var user = localStorage.getItem("customerDocKey");
        //console.log('useruid',user)
        getAcseptUserData2(firebaseData.firebasecollection.USER,user,res=>{
          self.acseptUserData=res
          getActiveChat2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,self.socialMessage,res=>{
            self.socialMessage = res  
            //console.log('abc',self.socialMessage)        
            getCount2(self.socialMessage,self.acseptUserData,self.activeChatListArray,user,res=>{
              self.activeChatListArray = res
              //console.log('def', self.activeChatListArray)
                getCounting2(firebaseData.firebasecollection.USER,self.getUserData,self.activeChatListArray,res=>{
                self.chatListArray = res
                // console.log('ghi',self.chatListArray)
                  // getActiveMsgArray(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,self.chatListArray,res=>{
                  //   self.chatListArray = res
                    self.searchContactArray = res
                    //console.log('self.chatListArray',self.chatListArray)

                    self.chatListArray.forEach(dt=>{
                      self.sortArray(dt.mainBranches)
                    })
                  // })
                })
            })
          })
        })
    },
    // CHAT WITH CUSTOMER (Shraddha)
    IsChatCustomer(objectid,chatid){
        // //debugger // eslint-disable-line
        var self=this;
        const db = firebase.firestore()

        var mainBranches = ''
        self.activeChatListArray.forEach(data=>{
          if(data.msgid==chatid)
          {
            //console.log('=',data,chatid)
            mainBranches = data.mainBranches
          }
        })
        
        //console.log(objectid);
        if(objectid != ''){
          db.collection(firebaseData.firebasecollection.USER).doc(objectid).get().then((doc) =>{
            self.chatObject = doc.data()
            self.groupObject = {}
            //console.log('Chat',self.chatObject)
            self.$root.$emit('chatObjefct',self.chatObject,chatid,self.groupObject,mainBranches);
          })
        }else{
          db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatid).get().then((doc) =>{
            self.groupObject = doc.data()
            self.chatObject = {}
            //console.log('Group',self.groupObject)
            self.$root.$emit('chatObjefct',self.chatObject,chatid,self.groupObject,mainBranches);
          })
        }
    },
    // BLOCK CUSTOMER
    IsBlockCustomer(blockid,activeChatList){
        // //debugger // eslint-disable-line
        var self=this;
        const db = firebase.firestore()
          const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
          Swal.fire({
              title: "Are you sure you want to block"+' '+activeChatList.firstName+' '+activeChatList.lastName+' '+"?",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#77bef6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
        // console.log(blockid);
            var user = firebase.auth().currentUser;
            // console.log(user.uid)
            var removeChatid = '';
            db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockid).get().then(() => {
            db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockid).set({
                    id:blockid
                }).then( () => {
                    db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockid).update({
                        block:true,
                        blockFrom : user.uid,
                    }).then( () => {
                        self.$notify({
                            title:"Success",
                            text:activeChatList.firstName+' '+activeChatList.lastName+' blocked.',
                            type:"success"
                        })
                        self.$root.$emit('activeGroup' ,'yes');
                    })
                })  
            // }
        })
      }
    })
    },
      //REMOVE FROM CONTACT LIST(Shraddha)
    removeFromContact(data){
        // //debugger // eslint-disable-line
        var self=this;
        var removeid = data.customer_id
        const db = firebase.firestore()
        // //console.log(removeid);
        var user = firebase.auth().currentUser;
        var removeChatid = ''
        // console.log(user.uid)
        db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(removeid).get().then(snap => {
            if(snap.data().chatId != undefined){
                removeChatid = snap.data().chatId
                // console.log('removeChatid',removeChatid)
                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(removeChatid).delete().then(()=>{
                    db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(removeid).delete().then ( () => {
                        db.collection(firebaseData.firebasecollection.USER).doc(removeid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(user.uid).delete().then( () => {
                            self.$notify({
                                title:"Success",
                                text:data.firstName+' '+data.lastName+' removed successfully.',
                                type:"success"
                            })
                            self.getContactList();
                            self.$root.$emit('activeGroup' ,'yes');
                        });
                    });
                })
            }else{
                db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(removeid).delete().then ( () => {
                    db.collection(firebaseData.firebasecollection.USER).doc(removeid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(user.uid).delete().then( () => {
                        self.$notify({
                            title:"Success",
                            text:data.firstName+' '+data.lastName+' removed successfully.',
                            type:"success"
                        })
                        self.getContactList();
                        self.$root.$emit('activeGroup' ,'yes');
                    });
                });
            }
        })
    },
    //FOR GET bULKMESSAGE LIST(Shraddha)
    init(){
      // //debugger // eslint-disable-line
        var self=this;
        self.userSearchList = []
        self.getBulkData = []
        // const db = firebase.firestore();
        // var user = localStorage.getItem("customerDocKey");
        // //console.log('useruid',user)
        Contactlist2(firebaseData.firebasecollection.USER,self.userSearchList,res=>{
          self.userSearchList = res
          getContactCount2(firebaseData.firebasecollection.USER,self.getBulkData,self.userSearchList,res=>{
            self.userSearchList = res
            //console.log('userSearchList',self.userSearchList)
          })
        })
    },
      // ADD MEMBER FOR BRODCAST(Shraddha)
    addBrodcastMmeber(bulkobj){
      // //debugger // eslint-disable-line
      var self = this;
      self.keyword = ''
      if(self.bulkMemberList.length > 0){
        let isInArray = self.bulkMemberList.some(function(item) {
            return item.docid == bulkobj.docid
        });
        if(isInArray == false){
          self.bulkMemberList.push(bulkobj);
          
        }else{
          self.$notify({
            title:"Error",
            text:'Already exists',
            type:"error"
          })
          
        }
      }else{
        self.bulkMemberList.push(bulkobj);
        
      }
    },
    // REMOVE MEMBER FROM BULKARRAY(Shraddha)
    removemember(id){
        // //debugger // eslint-disable-line
        var self = this;
        self.bulkMemberList.splice(id,1);
    },
    //SEND BULKMESSAGE(Shraddha)
    sendBulkMessage(){
        // //debugger // eslint-disable-line
        var self = this;
        self.sendingMessage = self.sendingMessage.trim();
        if(self.sendingMessage != ''){
          if(self.bulkMemberList.length != 0){
            self.$emit('OnupdateBulkData',self.bulkMemberList,self.sendingMessage);
            self.sendingMessage = ''
            self.keyword = ''
            self.bulkMemberList = []
            self.$refs.log.reset();
            self.$notify({
              title:"Success",
              text:'Message send successfully',
              type:"success"
            })
            self.$bvModal.hide('bulkmessage')
          }else{
            self.$notify({
              title:"Error",
              text:'Member list is required',
              type:"error"
            })
          }
        }
    },
      // SEARCH FILTER BY CUSTOMER(Shraddha)
    searchFilter(){
        // //debugger // eslint-disable-line
        var self=this;
        self.chatListArray =[]
        self.chatListArray = self.searchContactArray
        // console.log(self.searchkeyword,self.searchContactArray,self.chatListArray )
        var arr=[]
        self.chatListArray .forEach(datas=>{
          if(self.selectedActiveMenu=='adminChat')
          {
            if(datas.firstName.toLowerCase().includes(self.searchkeyword.toLowerCase()))
            {
                
                arr.push(datas)
                
            }
            else if(datas.lastName.toLowerCase().includes(self.searchkeyword.toLowerCase()))
            {
                
                arr.push(datas)
                
            }
            else if(datas.messages.length > 0){
                var found = false
                datas.messages.forEach(data =>{
                    if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                        found = true
                    }
                })
                if(found == true){
                    arr.push(datas)
                }  
            }
            else if(datas.messages.length > 0){
                var found = false
                datas.messages.forEach(data =>{
                    if(data.name.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                        found = true
                    }
                })
                if(found == true){
                    arr.push(datas)
                }  
            }
            else if(datas.messages.length > 0){
                var found = false
                datas.messages.forEach(data =>{
                    if(data.type.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                        found = true
                    }
                })
                if(found == true){
                    arr.push(datas)
                }  
            }
          }
          else if(self.selectedActiveMenu=='admin')
          {
            if(datas.title.toLowerCase().includes(self.searchkeyword.toLowerCase()))
            {
                
                arr.push(datas)
                
            }
            // else if(datas.lastName.toLowerCase().includes(self.searchkeyword.toLowerCase()))
            // {
                
            //     arr.push(datas)
                
            // }
            else if(datas.messages.length > 0){
                var found = false
                datas.messages.forEach(data =>{
                    if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                        found = true
                    }
                })
                if(found == true){
                    arr.push(datas)
                }  
            }
            else if(datas.messages.length > 0){
                var found = false
                datas.messages.forEach(data =>{
                    if(data.name.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                        found = true
                    }
                })
                if(found == true){
                    arr.push(datas)
                }  
            }
            else if(datas.messages.length > 0){
                var found = false
                datas.messages.forEach(data =>{
                    if(data.type.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                        found = true
                    }
                })
                if(found == true){
                    arr.push(datas)
                }  
            }
          }

        })
        //console.log('arr',arr)
        if(arr.length > 0){
          self.chatListArray  = arr   
        }
        if(arr.length == 0){
          self.chatListArray = []  
        }
        if(self.searchkeyword == ''){
          self.chatListArray =self.searchContactArray
        }
    },
    // SET BULKMESSAGE FOCUS (Shraddha)
    setBulkMesagesFocus(){
      // //debugger // eslint-disable-line
      setTimeout(()=>{
        this.$nextTick(() => {
            this.$refs.bulkmessage.focus();
        })
      },1000)
    },
    //SET CHATMESSAGE FOCUS(Shraddha)
    setchatMesagesFocus(){
      var self=this;
      self.$root.$emit('setchatfocus','yes');
    },
    //EXIT FROM GROUP(Shraddha)
    isExitGroup(groupData){
      // //debugger // eslint-disable-line
      var self = this;
      //console.log('groupDatagroupDatagroupData',groupData)
      var chatId = groupData.msgid
      var objectid = ''
      var chattedId = ''
      const db = firebase.firestore()
      var loginUser = localStorage.getItem('customerDocKey')
      
      exitGroup(loginUser,groupData.connectedUser,res => {
        // console.log('rererererer',res)
          db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatId).update({
            connectedUser : res
          }).then(()=>{
            self.$root.$emit('activeGroup' ,'yes');
            self.$root.$emit('hideViewDetail',objectid,chattedId)
            self.$notify({
              title:"Success",
              text:'Group delete Successfully',
              type:"success"
            })
          })
      })
    },
    sendSidebarData(chatid){
      // //debugger // eslint-disable-line
      var self=this;
        self.groupObject = {}
      const db = firebase.firestore()
        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatid).get().then((doc) =>{
            self.groupObject = doc.data()
            self.$root.$emit('sendgroupArray',self.groupObject,chatid)
        })
    },
    // BULK MESSAGE CLOSE EVENT(Shraddha)
    closeFire(){
        var self = this;
        self.sendingMessage = '';
        self.keyword = ''
        self.bulkMemberList = []
        self.$refs.log.reset();
    }
       
  },
   //LISTING DATA (Shraddha)
  computed: {
    getFilterData() {
      var self = this;
      return self.userSearchList.filter(function(rawData) {
        return (
          rawData["firstName"]
            .toLowerCase()
            .indexOf(self.keyword.toLowerCase()) >= 0 ||
          rawData["lastName"]
            .toLowerCase()
            .indexOf(self.keyword.toLowerCase()) >= 0 
        );
      });
    },
  },
})
</script>
<style scoped>
.greenDot{
  background-color: #08b80f;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  vertical-align: middle;
  margin-left: 10px;
}
.redDot{
  background-color: #f53434;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  vertical-align: middle;
  margin-left: 10px;
}
</style>