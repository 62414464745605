<template>
    <b-modal id="createpoll" centered hide-footer title="" @close="reset()" :no-close-on-backdrop="true">
     <div class="contact-modal newchat-modal createpoll_business">
            <div class="contact-modal">
            <h4>Create Poll</h4>
             <ValidationObserver ref="log" v-slot="{ handleSubmit }" >
            <form @submit.prevent="handleSubmit(checkValid?createPoll2:'')">
                <div class="row">
                    <div class="col-md-12">
                         <label class="mb-2">would you like to create poll with? *</label>
                         <div class="radio_wrapper mb-3">
                            <div class="radio">
                                <input id="text" @change="activepoll($event)"  name="radio" value="text" type="radio" checked="checked" v-model="pollData">
                                <label for="text" class="text-label">Text</label>
                            </div>
                            <div class="radio">
                                <input id="image" @change="activepoll($event)" name="radio" value="image" type="radio" v-model="pollData" >
                                <label for="image" class="text-label">Image</label>
                            </div>
                         </div>
                    </div>
                </div>
                <div class="row text-row" v-if="polltext == 'text'">
                    <div class="col-md-12">
                         <ValidationProvider rules="required" name="question" v-slot="{ errors }">
                        <div class="form-group mb-5">
                            <label>Question *</label>
                            <input type="text" class="form-control" placeholder="Enter question" v-model="pollquestion" maxlength ="51" @keyup="checkCharecterLengthPollQuastation(pollquestion)">
                             <span class="InvalidInput">{{ errors[0] }}</span>
                             <span v-if="charecterErrorMessageForPollQuastation != '' && errors.length == 0" class="InvalidInput">{{charecterErrorMessageForPollQuastation}}</span>
                        </div>
                         </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider rules="required" name="expire date" v-slot="{ errors }">
                        <div class="form-group mb-5" style="position:relative;">
                            <label>Expiration date *</label>
                            <input type="date" class="form-control date_input" placeholder="Enter expiration date" v-model="expireDate" :min="DateOFToday" onkeydown="return false">
                             <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                           </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider rules="required" name="expire time" v-slot="{ errors }">
                            <div class="form-group mb-5" style="position:relative;">
                                <label>Expiration time *</label>
                                <input type="time" class="form-control time_input" placeholder="Enter expiration time" id="timeinput" v-model="expiretime" onkeydown="return false">
                                <span class="InvalidInput">{{ errors[0] }}</span>
                                <span class="InvalidInput" v-if="!checkValid && errors.length==0">Time must be {{getCurrentTime}} or later</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6" :class="{'mt-30' : index > 1}" v-for="(moreoption,index) in addmoreoption" v-bind:key="index">
                        <!-- <button type = "button" v-if="index > 1" @click="()=>{addmoreoption.splice(index,1)}">Remove</button> -->
                        <ValidationProvider rules="required" :name="'option'+' '+(index+1)" :vid="'option'+index" v-slot="{ errors }">
                        <div class="form-group" style="margin-bottom:20px">
                            <label>option {{index+1}}*
                               <button type = "button" class="remove-option" v-if="index > 1" @click="()=>{addmoreoption.splice(index,1)}"><img src="@/assets/images/cancel-circle.png"></button> 
                            </label>
                            <input type="text" class="form-control" :name="'option '+(index+1)" :placeholder="'Enter option'+' '+(index+1)" v-model="moreoption.value" maxlength="21" @keyup="checkCharecterLengthPollOption(moreoption.value,index)">
                            <span class="InvalidInput" style="width:222px;">{{ errors[0] }}</span>
                            <span v-if="charecterErrorMessageForPollOption1 != '' && index == 0 && errors.length == 0" style="width:222px;" class="InvalidInput">{{charecterErrorMessageForPollOption1}}</span>
                            <span v-if="charecterErrorMessageForPollOption2 != '' && index == 1 && errors.length == 0" style="width:222px;" class="InvalidInput">{{charecterErrorMessageForPollOption2}}</span>
                            <span v-if="charecterErrorMessageForPollOption3 != '' && index == 2 && errors.length == 0" style="width:222px;" class="InvalidInput">{{charecterErrorMessageForPollOption3}}</span>
                            <span v-if="charecterErrorMessageForPollOption4 != '' && index == 3 && errors.length == 0" style="width:222px;" class="InvalidInput">{{charecterErrorMessageForPollOption4}}</span>
                        </div>
                        </ValidationProvider>
                    </div>
                   
                     <div class="forget-link">
                        <a href="#" class="link-branch" @click="addOption()" v-if="addmoreoption.length < 4 || moreoption == true">+ Add More</a>
                    </div>
                </div>
                 <div class="row image-row" v-if="polltext == 'image'">
                    <div class="col-md-12">
                         <ValidationProvider rules="required" name="question" v-slot="{ errors }">
                        <div class="form-group mb-5">
                            <label>Question *</label>
                            <input type="text" class="form-control" placeholder="Enter question" maxlength ="51" v-model="pollquestion" @keyup="checkCharecterLengthPollQuastation(pollquestion)">
                            <span v-if="charecterErrorMessageForPollQuastation != '' && errors.length == 0" class="InvalidInput">{{charecterErrorMessageForPollQuastation}}</span>
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                         </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider rules="required" name="expire date" v-slot="{ errors }">
                        <div class="form-group mb-5" style="position:relative;">
                            <label>Expiration date *</label>
                            <input type="date" class="form-control date_input" placeholder="Enter expiration date" v-model="expireDate" :min="DateOFToday" onkeydown="return false">
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider rules="required" name="expire time" v-slot="{ errors }">
                        <div class="form-group mb-5" style="position:relative;">
                            <label>Expiration time *</label>
                            <input type="time" class="form-control time_input" placeholder="Enter expiration time" id="time_input" v-model="expiretime" onkeydown="return false">
                            <span class="InvalidInput">{{ errors[0] }}</span>
                            <span class="InvalidInput" v-if="!checkValid && errors.length==0">Time must be {{getCurrentTime}} or later</span>
                        </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6" style="margin-bottom:30px">
                        <div class="form-group">
                            <label>option 1*</label>
                          
                            <ValidationProvider rules="required" name="option 1"  v-slot="{ errors }">
                           
                            <div class="photoupload-wrapper">
                                <img v-if="preview" :src="preview" class="img-fluid" />
                                <img v-else src="@/assets/images/upload-img-poll-blue.png" />
                                <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file"/>
                                <input type="text" v-model="preview" name="option 1" style="display:none;"/>
                                <span class="InvalidInput">{{ errors[0] }}</span>
                                <span class="InvalidInput"  style="font-size:12px;" v-if="profileErrorMessage != '' && errors.length == 0">{{profileErrorMessage}}</span>
                                

                            </div>
                            </ValidationProvider>
                             <ValidationProvider rules="required" name="option 1 Text" v-slot="{ errors }" class="mt-10">
                            <input type="text" class="form-control" placeholder="Enter text*" name="option 1 Text" v-model="optionimage1" maxlength="21" @keyup="checkCharecterLengthPollOption(optionimage1,0)">
                            <span class="InvalidInput" style="width:222px;">{{ errors[0] }}</span>
                            <span v-if="charecterErrorMessageForPollOption1 != '' && errors.length == 0" style="width:222px;" class="InvalidInput">{{charecterErrorMessageForPollOption1}}</span>
                            </ValidationProvider>
                        </div>
                        
                    </div>
                    <div class="col-md-6" style="margin-bottom:30px">
                        <div class="form-group">
                            <label>option 2*</label>
                            <ValidationProvider rules="required" name="option 2" v-slot="{ errors }">
                            <div class="photoupload-wrapper">
                                <img v-if="preview1" :src="preview1" class="img-fluid" />
                                <img v-else src="@/assets/images/upload-img-poll-blue.png" />
                                <input type="file" accept="image/*" @change="previewImage1" class="form-control-file" id="my-file2"/>
                                 <input type="text" v-model="preview1" name="option 2" style="display:none;"/>
                                <span class="InvalidInput">{{ errors[0] }}</span>
                                <span class="InvalidInput"  style="font-size:12px;" v-if="profileErrorMessage1 != '' && errors.length == 0">{{profileErrorMessage1}}</span>
                            </div>
                            </ValidationProvider>
                            <ValidationProvider rules="required" vid="option 2 Text" name="option 2 Text" v-slot="{ errors }" class="mt-10">
                            <input type="text" class="form-control" placeholder="Enter text*" name="option 2 Text" v-model="optionimage2" maxlength="21" @keyup="checkCharecterLengthPollOption(optionimage2,1)">
                            <span class="InvalidInput" style="width:222px;">{{ errors[0] }}</span>
                            <span v-if="charecterErrorMessageForPollOption2 != '' && errors.length == 0" style="width:222px;" class="InvalidInput">{{charecterErrorMessageForPollOption2}}</span>
                             </ValidationProvider>
                        </div>
                        
                    </div>
                      <div class="col-md-6 mt-20" v-if="morefourthoption == true">
                        <!-- <button type = "button" @click="removeOption()">Delete</button> -->
                        <div class="form-group">
                            <label>option 3*
                                <button type = "button" class="remove-option" @click="removeOption()"><img src="@/assets/images/cancel-circle.png"></button>
                            </label>
                            <ValidationProvider rules="required" name="option 3" v-slot="{ errors }">
                            <div class="photoupload-wrapper">
                                <img v-if="preview2" :src="preview2" class="img-fluid" />
                                <img v-else src="@/assets/images/upload-img-poll-blue.png" />
                                <input type="file" accept="image/*" @change="previewImage2" class="form-control-file" id="my-file3"/>
                                 <input type="text" v-model="preview2" name="option 3" style="display:none;"/>
                                 <span class="InvalidInput">{{ errors[0] }}</span>
                                 <span class="InvalidInput"  style="font-size:12px;" v-if="profileErrorMessage2 != '' && errors.length == 0">{{profileErrorMessage2}}</span>
                            </div>
                             </ValidationProvider>
                             <ValidationProvider rules="required" vid="option 3 Text" name="option 3 Text" v-slot="{ errors }" class="mt-10">
                            <input type="text" class="form-control" placeholder="Enter text*" name="option 3 Text"  v-model="optionimage3" maxlength="21" @keyup="checkCharecterLengthPollOption(optionimage3,2)">
                             <span class="InvalidInput" style="width:222px;">{{ errors[0] }}</span>
                            <span v-if="charecterErrorMessageForPollOption3 != '' && errors.length == 0" style="width:222px;" class="InvalidInput">{{charecterErrorMessageForPollOption3}}</span>
                             </ValidationProvider>
                            
                        </div>
                         
                    </div>
                      <div class="col-md-6 mt-20" v-if="addfouroption == true">
                        <!-- <button type = "button" @click="removeOption4()">Delete</button> -->
                        <div class="form-group">
                            <label>option 4*
                                <button type = "button" class="remove-option" @click="removeOption4()"><img src="@/assets/images/cancel-circle.png"></button>
                            </label>
                            <ValidationProvider rules="required" name="option 4" v-slot="{ errors }">
                             <div class="photoupload-wrapper">
                                <img v-if="preview3" :src="preview3" class="img-fluid" />
                                <img v-else src="@/assets/images/upload-img-poll-blue.png" />
                                <input type="file" accept="image/*" @change="previewImage3" class="form-control-file" id="my-file4"/>
                                 <input type="text" v-model="preview3" name="option 4" style="display:none;"/>
                                 <span class="InvalidInput">{{ errors[0] }}</span>
                                 <span class="InvalidInput"  style="font-size:12px;" v-if="profileErrorMessage3 != '' && errors.length == 0">{{profileErrorMessage3}}</span>
                            </div>
                             </ValidationProvider>
                             <ValidationProvider rules="required" name="option 4 Text" v-slot="{ errors }" class="mt-10">
                            <input type="text" class="form-control" placeholder="Enter text*" name="option 4 Text" v-model="optionimage4" maxlength="21" @keyup="checkCharecterLengthPollOption(optionimage4,3)">
                             <span class="InvalidInput" style="width:222px;">{{ errors[0] }}</span>
                            <span v-if="charecterErrorMessageForPollOption4 != '' && errors.length == 0" style="width:222px;" class="InvalidInput">{{charecterErrorMessageForPollOption4}}</span>
                             </ValidationProvider>
                        </div>
                        
                    </div>
                    <div class="forget-link">
                        <a href="#" class="link-branch" @click="addFourthOption(),addOption()" v-if="morefourthoption == false" >+ Add More</a>
                    </div>
                     <div class="forget-link">
                        <a href="#" class="link-branch" @click="addfouroptions(),addOption()" v-if="addfouroption == false && morefourthoption == true">+ Add More</a>
                    </div>
                    
                </div>
                <div class="btn-wrapper">
                    <button class="btn btn-blue" type="submit" v-bind:class="{disabled: isSpinner == true}" >Publish</button>
                </div>
            </form>
             </ValidationObserver>
        </div>
        </div>
  </b-modal>
</template>

<script>
import firebase from "@/views/firebaseInit";
import Vue from "vue";
import firebaseData from '@/utils/firebaseCollection.js';
import firebaseTimestamp from "firebase";
import {uploadImageFile} from '@/utils/storageQuerry.js'
import { mapGetters } from "vuex";
const db = firebase.firestore()
export default {
    name:'createpoll',
    props: ['groupDocId','mainBranchActive','mainBranchActiveIndex','subBranchActive','subBranchActiveIndex','subSubBranchActive','subSubBranchActiveIndex','messageSeenIds','currCollection'],
    components: {
        firebaseData
    },
    data() {
    return {
      preview: null,
      preview1: null,
      preview2: null,
      preview3: null,
      image: null,
      image1: null,
      image2: null,
      image3: null,
      preview_list: [],
      image_list: [],
      pollquestion:'',
      expireDate:'',
      expiretime:'',
    //   option1:'',
    //   option2:'',
    //   option3:'',
    //   option4:'',
      pollData:'text',
      DateOFToday:  new Date().toISOString().split('T')[0],
      moreoption:false,
      chatId:'',
      userData:'',
      fromData:{},
      questions:{},
      uploadData:[],
      userid:'',
      polltext:'text',
      imagesList:[],
      pollimages:[],
      optionimage1:'',
      optionimage2:'',
      optionimage3:'',
      optionimage4:'',

      mainBranches:[],
      updateChat:{},
      chatObjectId:this.groupDocId,
      connectedUserArray:[],
      addmoreoption:[
          {value:''},
          {value:''}
        ],
        
        moreoption:false,
        morefourthoption:false,
        addfouroption:false,
        isSpinner:false,


        min:0,
        hrs:0,
        companyuserlimit : {},
        profileErrorMessage:'',
        profileErrorMessage1:'',
        profileErrorMessage2:'',
        profileErrorMessage3:'',
         blockUserArray : [],
        isInArray : '',
        charecterErrorMessageForPollQuastation:'',
         charecterErrorMessageForPollOption1:'',
        charecterErrorMessageForPollOption2:'',
        charecterErrorMessageForPollOption3:'',
        charecterErrorMessageForPollOption4:''
    };
  },
  created(){
    // //debugger // eslint-disable-line
    var self = this;
    self.init();

    setInterval( () => {
        self.min=new Date().getMinutes()
        self.hrs=new Date().getHours()
    },100)

    // self.$root.$on('setBranch',(mainBranchActiveIndex,mainBranches,chatId)=>{
    //     self.mainBranches=mainBranches
    //     self.mainBranchActiveIndex=mainBranchActiveIndex
    //     self.chatId=chatId

    //     // console.log(mainBranchActiveIndex,mainBranches)
    // })
    self.$root.$on("chatObjefct",(value,chatId,activeGroup,mainBranchArray)=>{
        // //debugger // eslint-disable-line
        //console.log('shraddha',value,chatId,activeGroup,mainBranchArray)
        self.chatId = chatId
        self.chatObjectId = value.id
        self.mainBranches=mainBranchArray
    })
  },
  methods: {
    previewImage: function (event) {
       
      var input = event.target;
       var index = 0
       this.profileErrorMessage = '';
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
            var docExtention = input.files[0].type.split('/');
            if(docExtention[0] == 'image'){
                this.profileErrorMessage = '';
                if(Vue.prototype.$companyName.isAdmin == false){
                if(event.target.files[index].size<this.companyuserlimit.max_file_size && this.companyuserlimit.dataUsage < this.companyuserlimit.userUsageLimit)
                {
                    this.preview = e.target.result;
                    this.image = input.files[0];
                }
                else
                {
                    this.$notify({
                    title:"Error",
                    text:input.files[index].name+' file size is maximum.',
                    type:"error"
                    })
                }
                }else{
                
                    this.preview = e.target.result;
                    this.image = input.files[0];
                }
            }
            else{
                this.profileErrorMessage = 'Only .jpg,.png,.jpeg and .gif files are allowed.'
            }
         
        };
       
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewImage1: function (event) {
    // debugger // eslint-disable-line

      var input = event.target;
      var index = 0
      this.profileErrorMessage1 = '';
    if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
            var docExtention = input.files[0].type.split('/');
            if(docExtention[0] == 'image'){
                this.profileErrorMessage1 = '';
                if(Vue.prototype.$companyName.isAdmin == false){
                if(event.target.files[index].size<this.companyuserlimit.max_file_size && this.companyuserlimit.dataUsage < this.companyuserlimit.userUsageLimit)
                {
                this.preview1 = e.target.result;
                this.image1 = input.files[0];
                }
                else
                {
                    this.$notify({
                    title:"Error",
                    text:input.files[index].name+' file size is maximum.',
                    type:"error"
                    })
                }
                }else{
                
                    this.preview1 = e.target.result;
                    this.image1 = input.files[0];
                }
            }else{
                this.profileErrorMessage1 = 'Only .jpg,.png,.jpeg and .gif files are allowed.'
            }
         
    };
       
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewImage2: function (event) {
      var input = event.target;
       var index = 0
       this.profileErrorMessage2 = '';
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
            var docExtention = input.files[0].type.split('/');
            if(docExtention[0] == 'image'){
                this.profileErrorMessage2 = '';
                if(Vue.prototype.$companyName.isAdmin == false){
                if(event.target.files[index].size<this.companyuserlimit.max_file_size && this.companyuserlimit.dataUsage < this.companyuserlimit.userUsageLimit)
                {
                    this.preview2 = e.target.result;
                    this.image2 = input.files[0];
                }
                else
                {
                    this.$notify({
                    title:"Error",
                    text:input.files[index].name+' file size is maximum.',
                    type:"error"
                    })
                }
                }else{
                
                    this.preview2 = e.target.result;
                    this.image2 = input.files[0];
                }
            }else{
                this.profileErrorMessage2 = 'Only .jpg,.png,.jpeg and .gif files are allowed.'
            }
          
        };
        
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewImage3: function (event) {
      var input = event.target;
       var index = 0
       this.profileErrorMessage3 = '';
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
            var docExtention = input.files[0].type.split('/');
            if(docExtention[0] == 'image'){
                this.profileErrorMessage3 = '';
                if(Vue.prototype.$companyName.isAdmin == false){
                if(event.target.files[index].size<this.companyuserlimit.max_file_size && this.companyuserlimit.dataUsage < this.companyuserlimit.userUsageLimit)
                {
                    this.preview3 = e.target.result;
                    this.image3 = input.files[0];
                }
                else
                {
                    this.$notify({
                    title:"Error",
                    text:input.files[index].name+' file size is maximum.',
                    type:"error"
                    })
                }
                }else{
                
                    this.preview3 = e.target.result;
                    this.image3 = input.files[0];
                }
            }else{
                this.profileErrorMessage3 = 'Only .jpg,.png,.jpeg and .gif files are allowed.'
            }
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewMultiImage: function (event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          this.image_list.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
      }
    },
  
    reset: function () {
      this.image = null;
      this.image1 = null;
      this.image2 = null;
      this.image3 = null;
      this.preview0 = null;
      this.preview1 = null;
      this.preview2 = null;
      this.preview3 = null;
      this.image_list = [];
      this.preview_list = [];
      this.charecterErrorMessageForPollQuastation = '';
      this. charecterErrorMessageForPollOption1='';
        this. charecterErrorMessageForPollOption2='';
        this. charecterErrorMessageForPollOption3='';
        this. charecterErrorMessageForPollOption4=''  
    },
    // for getchatId
    init(){
        var self=this;
        
        self.$root.$on("chatObjefct",(value,chatId,activeGroup,mainBranchArray)=>{
            // //debugger // eslint-disable-line
            // console.log('shraddha',value,chatId,activeGroup,mainBranchArray)
            self.chatId = chatId
            self.chatObjectId = value.id
            self.mainBranches=mainBranchArray
        })
        self.userid = localStorage.getItem('customerDocKey');
        db.collection(firebaseData.firebasecollection.USER)
        .doc(self.userid)
        .get()
        .then(doc=>{
            self.imageUrl = doc.data().profileUrl;
            self.userData = doc.data()
        })
        db.collection(firebaseData.firebasecollection.COMPANY).onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc=>{           
            if(doc.exists){           
            if(doc.data().id == Vue.prototype.$companyName.id){
              var users = doc.data().users
              users.forEach(userdata => {
                
                if(userdata.id == self.userid){
                  self.dataUsage = Number(userdata.dataUsage)
                  self.companyuserlimit = {
                    planName:userdata.planName,
                    planId:userdata.planId,
                    max_file_size:userdata.max_file_size,
                    userUsageLimit:userdata.userUsageLimit,
                    dataUsage:Number(userdata.dataUsage)
                  }
                }
              })
            //   console.log('companyuserlimit',self.companyuserlimit)
            }
            }
          })
        })
    },
    // POLL CHANGE EVENT(Shraddha)
    activepoll(event){
        //  debugger // eslint-disable-line
         var self=this;
        //  //debugger // eslint-disable-line
        // console.log(event,event.target.value);
        self.polltext = event.target.value
        self.charecterErrorMessageForPollQuastation = '';
        self.charecterErrorMessageForPollOption1='';
        self.charecterErrorMessageForPollOption2='';
        self.charecterErrorMessageForPollOption3='';
        self.charecterErrorMessageForPollOption4=''  
        self.reset();
    },
    //ADD MORE OPTION(Shraddha)
    addOption(){
        //  //debugger // eslint-disable-line
         var self = this;
        if(self.addmoreoption.length < 4){
         self.addmoreoption.push({value:''})
         }
        if(self.addmoreimageoption.length < 4){
         self.addmoreimageoption.push({url:'',value:''})
         }

        
    },
    addFourthOption(){
         var self = this;
         self.morefourthoption = true
    },
    addfouroptions(){
        var self=this;
        self.addfouroption = true
    },
    sortArray(mainBranchArray)
    {
    //   console.log('sortCalled')
    //   console.log('arr',mainBranchArray)

      var tmp = mainBranchArray||[]
      var tmp2 = ''
      if(tmp.length!=0)
      {
        for (let index = 0; index < tmp.length; index++) {
          for (let j = index+1; j < tmp.length; j++) {
            if(tmp[index].lastMsgTime.seconds < tmp[j].lastMsgTime.seconds)
            {
              tmp2 = tmp[index]
              tmp[index] = tmp[j]
              tmp[j]=tmp2
            }
          }
        }
      }
      return tmp
    },
    checkCharecterLengthPollOption(groupName,index){
        // debugger // eslint-disable-lines
        var self = this;
        if(groupName.length < 21){
            if(index == 0){
                self.addmoreoption[index].value =groupName
                self.optionimage1 = groupName
                self.charecterErrorMessageForPollOption1 = '';
            }
            else if(index == 1){
                self.addmoreoption[index].value =groupName
                self.optionimage2 = groupName
                self.charecterErrorMessageForPollOption2 = '';
            }
            else if(index == 2){
                self.addmoreoption[index].value =groupName
                self.optionimage3 = groupName
                self.charecterErrorMessageForPollOption3 = '';
            }else if(index == 3){
                self.addmoreoption[index].value =groupName
                self.optionimage4 = groupName
                self.charecterErrorMessageForPollOption4 = '';
            }
        }else{
            if(index == 0){
                self.charecterErrorMessageForPollOption1 = 'The option 1 may not be greater than 20 characters';
                self.addmoreoption[index].value = (groupName).slice(0,20)
                self.optionimage1 = (groupName).slice(0,20)
            }
            if(index == 1){
                self.charecterErrorMessageForPollOption2 = 'The option 2 may not be greater than 20 characters';
                self.addmoreoption[index].value = (groupName).slice(0,20)
                self.optionimage2 = (groupName).slice(0,20)
            }
             if(index == 2){
                self.charecterErrorMessageForPollOption3 = 'The option 3 may not be greater than 20 characters';
                self.addmoreoption[index].value = (groupName).slice(0,20)
                self.optionimage3 = (groupName).slice(0,20)
            }
             if(index == 3){
                self.charecterErrorMessageForPollOption4 = 'The option 4 may not be greater than 20 characters';
                self.addmoreoption[index].value = (groupName).slice(0,20)
                self.optionimage4 = (groupName).slice(0,20)
            }
         
        }
    },
    //CREATE POLL (Shraddha);
    createPoll(){
        // debugger // eslint-disable-line
        var self = this;
        if(self.profileErrorMessage == '' && self.profileErrorMessage1 == '' && self.profileErrorMessage2 == '' && self.profileErrorMessage3 == '' && self.charecterErrorMessageForPollQuastation == '' && self.charecterErrorMessageForPollOption1 == ''&& self.charecterErrorMessageForPollOption2 == ''&& self.charecterErrorMessageForPollOption3 == '' && self.charecterErrorMessageForPollOption4 == ''){

        self.fromData = {}
        self.isSpinner = true
        // console.log(self.optionimage1,self.optionimage2)
        var type = ''
        var meridian = ''
        var minutes = ''

        if(self.storeChatId != '' && self.storeChatId != undefined){
            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                .doc(self.storeChatId)
                .get().then(chatData=>{
                    if(chatData.exists)
                    {
                        self.mainBranches = self.sortArray(chatData.data().mainBranches)||[]
                    }
            })
            .catch(error=>{
                // console.log(error)
            })
        }else{
            self.mainBranches = [];
        }
                // console.log('mainBranches',self.mainBranches,self.mainBranches[self.mainBranchActiveIndex],self.currCollection)

                var updateBranchData = {}
                self.uploadData = []
                self.imagesList = []
                self.pollimages = []
        
                // console.log('Branch',self.currCollection)
               if(self.pollData == 'text'){
                   type = 'TextPoll'
               }
               else if(self.pollData == 'image'){
                    type = 'ImagePoll'
               }
                self.fromData = {
                   'avtar':self.userData.profileUrl,
                    'id':self.userData.id,
                    'name':self.userData.firstName,
                }
                self.questions = {
                    question:self.pollquestion,
                    time:self.expiretime,
                    date:self.expireDate,
                    totalCount:0,
                    type:type
                }
                self.updateChat = {
                    from:self.fromData,
                    msg:self.pollquestion,
                    type:type,
                    name:type
                }
                if (type == 'ImagePoll'){
                    self.imagesList = []
                    self.imagesList.push(self.image);
                    self.imagesList.push(self.image1);
                    if(self.image2!= null){
                        self.imagesList.push(self.image2);
                        if(self.image3!= null){
                        self.imagesList.push(self.image3);
                        }
                    }
        
                    // console.log('self.imagesList',self.imagesList)
                    var ct = self.imagesList.length
                    var ind = 0
                    var imgs=[]
                    if (self.imagesList) {
                        while (ct--) {
                            var reader = new FileReader();
                            reader.onload = (e) => {
                                imgs.push(e.target.result);
                            };
                            reader.readAsDataURL(self.imagesList[ind]);
                            ind++;
                        }
                    }
        
                    self.$root.$emit('showPreviewPoll',{question:self.questions,type:type,images:imgs,imagetexts:[self.optionimage1,self.optionimage2,self.optionimage3,self.optionimage4]})
                    self.$bvModal.hide('createpoll')
        
                    for(var i=0;i<self.imagesList.length;i++){
                        uploadImageFile(firebaseData.firebasecollection.PROFILEIMAGES,self.imagesList[i].name,self.imagesList[i].size,self.imagesList[i],'poll_images','',res=>{
                            self.pollimages.push(res.imageUrl)
                             const imageSize = res.imageSize

                            db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc => {
                            var users = doc.data().users
                            users.forEach(data => {
                                if(data.id == firebase.auth().currentUser.uid){
                                data.dataUsage = data.dataUsage + Number(imageSize)
                                }
                            })
                            db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                                users : users
                            })
                            })
                            // console.log('self.self.pollimages',self.pollimages)
                            if(self.pollimages.length == self.imagesList.length){
                                self.uploadData.push({
                                    count:0,
                                    text:self.optionimage1,
                                    imageUrl:self.pollimages[0]
                                })
                                self.uploadData.push({
                                    count:0,
                                    text:self.optionimage2,
                                    imageUrl:self.pollimages[1]
                                })
                                // console.log(' self.uploadData', self.uploadData)
                                if(self.morefourthoption == true && self.optionimage3!= '' ){
                                    self.uploadData.push({
                                        count:0,
                                        
                                        text:self.optionimage3,
                                        imageUrl:self.pollimages[2]
                                    })
                                    if(self.addfouroption == true  && self.optionimage4!= '' ){
                                        self.uploadData.push({
                                            count:0,
                                            text:self.optionimage4,
                                            imageUrl:self.pollimages[3]
                                        }) 
                                    } 
                                }
                                // console.log('pollquestion',self.pollData, self.uploadData);
                                if(self.chatId != undefined && self.chatId != ''){
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).get().then(doc => {
                                        self.blockUserArray = doc.data().blockUserArray || []
                                        self.blockUserArray.forEach(inArray => {
                                            if(inArray.blockTo == firebase.auth().currentUser.uid){
                                            self.isInArray = inArray.blockBy
                                            }
                                        })
                                    }).then(() => {
                                //=====================================
                                        if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                                        {
                                            // console.log('Group',self.chatId)
                                            if(self.chatId != undefined){
                                                self.messageSeenIds.forEach(data=>{
                                                    if(data.id!=self.userid)
                                                    {
                                                        if(data.count == 0){
                                                            data.time = new Date()
                                                        }
                                                        data.count++
                                                    }
                                                })
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').add({
                                                    createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    from:self.fromData,
                                                    id:'',
                                                    questions:self.questions,
                                                    uploadPollData : self.uploadData,
                                                    type:type,
                                                    messageSeen:[self.fromData],
                                                    blockBy : self.isInArray || ''
                                                }).then((docref) => {
                                                    self.$axios.post(self.$apiurl+'sendwebNotification',{
                                                        userId:self.userData.id,msgCollection:'default',
                                                        chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                                    })
                                                    self.$root.$emit('hidePreviewPoll')
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').doc(docref.id).update({
                                                    id:docref.id,  
                                                    }).then( () => {
                                                        self.$root.$emit("messageSendAudio");
                                                        self.$notify({
                                                            title:"Success",
                                                            text:'Poll Created Successfully.',
                                                            type:"success",
                                                            duration: 10000
                                                        })
                                                        self.isSpinner = false
                                                        self.$bvModal.hide('createpoll')
                                                        self.reset();
                                                    })
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                                        updateChat:self.updateChat,
                                                        lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                        msgFromBranch:false,
                                                        messageSeenIds:self.messageSeenIds,
                                                        blockBy : self.isInArray || ''
                                                    }).then(() => {
                                                        self.isInArray = '';
                                                    })
                                                })
                                            }else{
                                                self.connectedUserArray = []
                                                self.connectedUserArray.push(self.userid)
                                                self.connectedUserArray.push(self.chatObjectId)
                                                var messageSeenIds=[]
                                                self.connectedUserArray.forEach(data=>{
                                                    if(data==firebase.auth().currentUser.uid)
                                                    {
                                                        messageSeenIds.push({id:data,count:0,time:'',IsEmailSend:false})
                                                    }
                                                    else
                                                    {
                                                        messageSeenIds.push({id:data,count:1,time:new Date(),IsEmailSend:false})
                                                    }
                                                })
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).add({
                                                    connectedUser : self.connectedUserArray,
                                                    id:'',
                                                    isGroup : false,
                                                    lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    title:'',
                                                    muteArray:[],
                                                    updateChat:{},
                                                    msgFromBranch:false,
                                                    updateBranchData:{},
                                                    SurpriseSelfie : {},
                                                    ToggleSelfie : [],
                                                    messageSeenIds:messageSeenIds
                                                }).then((doc) => {
                                                    db.collection(firebaseData.firebasecollection.USER).doc(self.userid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.chatObjectId).update({
                                                        chatId:doc.id
                                                    })
                                                    db.collection(firebaseData.firebasecollection.USER).doc(self.chatObjectId).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.userid).update({
                                                        chatId:doc.id
                                                    })
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(doc.id).update({
                                                        id : doc.id
                                                    })
                                                    self.chatId = doc.id
                                                }).then(()=>{
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').add({
                                                    createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    from:self.fromData,
                                                    id:'',
                                                    questions:self.questions,
                                                    uploadPollData : self.uploadData,
                                                    type:type,
                                                    messageSeen:[self.fromData]
                                                }).then((docref) => {
                                                    self.$axios.post(self.$apiurl+'sendwebNotification',{
                                                        userId:self.userData.id,msgCollection:'default',
                                                        chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                                    })
                                                    self.$root.$emit('hidePreviewPoll')
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').doc(docref.id).update({
                                                        id:docref.id,  
                                                    }).then( () => {
                                                        self.$root.$emit("messageSendAudio");
                                                        self.$notify({
                                                            title:"Success",
                                                            text:'Poll Created Successfully.',
                                                            type:"success",
                                                            duration: 10000
                                                        })
                                                        self.isSpinner = false
                                                        self.$bvModal.hide('createpoll')
                                                        self.reset();
                                                    })
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                                        updateChat:self.updateChat,
                                                        lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                        msgFromBranch:false
                                                    })
                                                })
                                                }) 
                                            }
                                        }
                                        else if(self.mainBranchActive || self.subBranchActive || self.subSubBranchActive)
                                        {
                                            self.fromData = {
                                                'avtar':self.userData.profileUrl,
                                                'id':self.userData.id,
                                                'name':self.userData.firstName,
                                                path:`${self.currCollection}`,
                                                
                                            }
                                            updateBranchData = {
                                                from:self.fromData,
                                                msg:self.pollquestion,
                                                type:type,
                                                name:type
                                            }
                                            self.mainBranches[self.mainBranchActiveIndex].lastMsgTime=new Date()
                
                                            // console.log('Main Branch:',self.currCollection)
                                            self.mainBranches.forEach(data=>{
                                                if(data.name == self.currCollection)
                                                {
                                                    data.messageSeenIds.forEach(ids=>{
                                                        if(ids.id!= self.userData.id)
                                                        {
                                                            if(ids.count == 0){
                                                                ids.time = new Date()
                                                            }
                                                            ids.count++
                                                        }
                                                    })
                                                }
                                                else
                                                {
                                                    data.subBranches.forEach(data2=>{
                                                        if(data2.name == self.currCollection)
                                                        {
                                                            data2.messageSeenIds.forEach(ids2=>{
                                                                if(ids2.id!= self.userData.id)
                                                                {
                                                                    if(ids2.count == 0){
                                                                        ids2.time = new Date()
                                                                    }
                                                                    ids2.count++
                                                                }
                                                            })
                                                        }
                                                        else
                                                        {
                                                            data2.subSubBranches.forEach(data3=>{
                                                                if(data3.name == self.currCollection)
                                                                {
                                                                    data3.messageSeenIds.forEach(ids3=>{
                                                                        if(ids3.id!= self.userData.id)
                                                                        {
                                                                            if(ids3.count == 0){
                                                                                ids3.time = new Date()
                                                                            }
                                                                            ids3.count++
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.currCollection).add({
                                                createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                from:self.fromData,
                                                id:'',
                                                questions:self.questions,
                                                uploadPollData : self.uploadData,
                                                type:type,
                                                messageSeen:[self.fromData],
                                                blockBy : self.isInArray || ''
                                            }).then((docref) => {
                                                self.$axios.post(self.$apiurl+'sendwebNotification',{
                                                    userId:self.userData.id,msgCollection:self.currCollection,
                                                    chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                                })
                                                self.$root.$emit('hidePreviewPoll')
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                                    updateBranchData:updateBranchData,
                                                    lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    msgFromBranch:true,
                                                    mainBranches:self.mainBranches,
                                                    blockBy : self.isInArray || ''
                                                })
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.currCollection).doc(docref.id).update({
                                                id:docref.id,
                                                }).then( () => {
                                                    self.$root.$emit("messageSendAudio");
                                                    self.isInArray = '';
                                                    self.$notify({
                                                        title:"Success",
                                                        text:'Poll Created Successfully.',
                                                        type:"success",
                                                        duration: 10000
                                                    })
                                                    self.isSpinner = false
                                                    self.$bvModal.hide('createpoll')
                                                    self.reset();
                                                })
                                            })
                                        }
                                        else if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
                                        {
                                            self.fromData = {
                                                'avtar':self.userData.profileUrl,
                                                'id':self.userData.id,
                                                'name':self.userData.firstName,
                                                path:`${self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name}`,
                                                
                                            }
                                            updateBranchData = {
                                                from:self.fromData,
                                                msg:self.pollquestion,
                                                type:type,
                                                name:type
                                            }
                                            self.mainBranches[self.mainBranchActiveIndex].lastMsgTime=new Date()
                
                                            self.mainBranches.forEach(data=>{
                                                if(data.name == self.currCollection)
                                                {
                                                    data.subBranches.forEach(data2=>{
                                                        if(data2.name == self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name)
                                                        {
                                                            data2.messageSeenIds.forEach(ids2=>{
                                                                if(ids2.id!= self.userData.id)
                                                                {
                                                                    if(ids2.count == 0){
                                                                        ids2.time = new Date()
                                                                    }
                                                                    ids2.count++
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                            // console.log('Sub Branch:',self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name)
                                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name).add({
                                                createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                from:self.fromData,
                                                id:'',
                                                questions:self.questions,
                                                uploadPollData : self.uploadData,
                                                type:type,
                                                messageSeen:[self.fromData],
                                                 blockBy : self.isInArray || ''
                                    
                                            }).then((docref) => {
                                                self.$axios.post(self.$apiurl+'sendwebNotification',{
                                                    userId:self.userData.id,msgCollection:self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name,
                                                    chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                                })
                                                self.$root.$emit('hidePreviewPoll')
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name).doc(docref.id).update({
                                                id:docref.id,  
                                                }).then( () => {
                                                    self.$root.$emit("messageSendAudio");
                                                    self.$notify({
                                                        title:"Success",
                                                        text:'Poll Created Successfully.',
                                                        type:"success",
                                                        duration: 10000
                                                    })
                                                    self.isSpinner = false
                                                    self.$bvModal.hide('createpoll')
                                                    self.reset();
                                                })
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                                    updateBranchData:updateBranchData,
                                                    lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    msgFromBranch:true,
                                                    mainBranches:self.mainBranches,
                                                    blockBy : self.isInArray || ''
                                                }).then(() => {
                                                    self.isInArray = ''
                                                })
                                            })
                                        }
                                        else if(self.mainBranchActive && self.subBranchActive && self.subSubBranchActive)
                                        {
                                            self.fromData = {
                                                'avtar':self.userData.profileUrl,
                                                'id':self.userData.id,
                                                'name':self.userData.firstName,
                                                path:`${self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name}`,
                                                
                                            }
                                            updateBranchData = {
                                                from:self.fromData,
                                                msg:self.pollquestion,
                                                type:type,
                                                name:type
                                            }
                                            self.mainBranches[self.mainBranchActiveIndex].lastMsgTime=new Date()
                
                                            self.mainBranches.forEach(data=>{
                                                if(data.name == self.currCollection)
                                                {
                                                    data.subBranches.forEach(data2=>{
                                                    if(data2.name == self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name)
                                                    {
                                                        data2.subSubBranches.forEach(data3=>{
                                                        if(data3.name == self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name)
                                                        {
                                                            data3.messageSeenIds.forEach(ids3=>{
                                                            if(ids3.id!= self.userData.id)
                                                            {
                                                                if(ids3.count == 0){
                                                                    ids3.time = new Date()
                                                                }
                                                                ids3.count++
                                                            }
                                                            })
                                                        }
                                                        })
                                                    }
                                                    })
                                                }
                                            })
                                            // console.log('subSub Branch:',self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name)
                                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name).add({
                                                createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                from:self.fromData,
                                                id:'',
                                                questions:self.questions,
                                                uploadPollData : self.uploadData,
                                                type:type,
                                                messageSeen:[self.fromData],
                                                blockBy : self.isInArray || ''
                                    
                                            }).then((docref) => {
                                                self.$axios.post(self.$apiurl+'sendwebNotification',{
                                                    userId:self.userData.id,msgCollection:self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,
                                                    chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                                })
                                                self.$root.$emit('hidePreviewPoll')
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name).doc(docref.id).update({
                                                id:docref.id,  
                                                }).then( () => {
                                                    self.$root.$emit("messageSendAudio");
                                                    self.$notify({
                                                        title:"Success",
                                                        text:'Poll Created Successfully.',
                                                        type:"success",
                                                        duration: 10000
                                                    })
                                                    self.isSpinner = false
                                                    self.reset();
                                                })
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                                    updateBranchData:updateBranchData,
                                                    lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    msgFromBranch:true,
                                                    mainBranches:self.mainBranches,
                                                    blockBy : self.isInArray || ''
                                                }).then(() => {
                                                    self.isInArray = ''
                                                })
                                            })
                                        }
                                    })
                                }else{
                                        if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                                        {
                                            // console.log('Group',self.chatId)
                                            if(self.chatId != undefined && self.chatId != ''){
                                                self.messageSeenIds.forEach(data=>{
                                                    if(data.id!=self.userid)
                                                    {
                                                        if(data.count == 0){
                                                            data.time = new Date()
                                                        }
                                                        data.count++
                                                    }
                                                })
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').add({
                                                    createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    from:self.fromData,
                                                    id:'',
                                                    questions:self.questions,
                                                    uploadPollData : self.uploadData,
                                                    type:type,
                                                    messageSeen:[self.fromData],
                                                    blockBy : self.isInArray || ''
                                                }).then((docref) => {
                                                    self.$axios.post(self.$apiurl+'sendwebNotification',{
                                                        userId:self.userData.id,msgCollection:'default',
                                                        chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                                    })
                                                    self.$root.$emit('hidePreviewPoll')
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').doc(docref.id).update({
                                                    id:docref.id,  
                                                    }).then( () => {
                                                        self.$root.$emit("messageSendAudio");
                                                        self.$notify({
                                                            title:"Success",
                                                            text:'Poll Created Successfully.',
                                                            type:"success",
                                                            duration: 10000
                                                        })
                                                        self.isSpinner = false
                                                        self.$bvModal.hide('createpoll')
                                                        self.reset();
                                                    })
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                                        updateChat:self.updateChat,
                                                        lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                        msgFromBranch:false,
                                                        messageSeenIds:self.messageSeenIds,
                                                        blockBy : self.isInArray || ''
                                                    }).then(() => {
                                                        self.isInArray = ''
                                                    })
                                                })
                                            }else{
                                                self.connectedUserArray = []
                                                self.connectedUserArray.push(self.userid)
                                                self.connectedUserArray.push(self.chatObjectId)
                                                var messageSeenIds=[]
                                               self.connectedUserArray.forEach(data=>{
                                                    if(data==firebase.auth().currentUser.uid)
                                                    {
                                                        messageSeenIds.push({id:data,count:0,time:'',IsEmailSend:false})
                                                    }
                                                    else
                                                    {
                                                        messageSeenIds.push({id:data,count:1,time:new Date(),IsEmailSend:false})
                                                    }
                                                })
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).add({
                                                    connectedUser : self.connectedUserArray,
                                                    id:'',
                                                    isGroup : false,
                                                    lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    title:'',
                                                    muteArray:[],
                                                    updateChat:{},
                                                    msgFromBranch:false,
                                                    updateBranchData:{},
                                                    SurpriseSelfie : {},
                                                    ToggleSelfie : [],
                                                    messageSeenIds:messageSeenIds,
                                                    blockBy : self.isInArray || ''
                                                }).then((doc) => {
                                                    db.collection(firebaseData.firebasecollection.USER).doc(self.userid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.chatObjectId).update({
                                                        chatId:doc.id
                                                    })
                                                    db.collection(firebaseData.firebasecollection.USER).doc(self.chatObjectId).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.userid).update({
                                                        chatId:doc.id
                                                    })
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(doc.id).update({
                                                        id : doc.id
                                                    })
                                                    self.chatId = doc.id
                                                }).then(()=>{
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').add({
                                                    createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    from:self.fromData,
                                                    id:'',
                                                    questions:self.questions,
                                                    uploadPollData : self.uploadData,
                                                    type:type,
                                                    messageSeen:[self.fromData],
                                                    blockBy : self.isInArray || ''
                                                }).then((docref) => {
                                                    self.$axios.post(self.$apiurl+'sendwebNotification',{
                                                        userId:self.userData.id,msgCollection:'default',
                                                        chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                                    })
                                                    self.$root.$emit('hidePreviewPoll')
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').doc(docref.id).update({
                                                        id:docref.id,  
                                                    }).then( () => {
                                                        self.$root.$emit("messageSendAudio");
                                                        self.$notify({
                                                            title:"Success",
                                                            text:'Poll Created Successfully.',
                                                            type:"success",
                                                            duration: 10000
                                                        })
                                                        self.isSpinner = false
                                                        self.isInArray = '';
                                                        self.$bvModal.hide('createpoll')
                                                        self.reset();
                                                        
                                                    })
                                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                                        updateChat:self.updateChat,
                                                        lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                        msgFromBranch:false
                                                    })
                                                        // self.$root.$emit('callGetChatArryForFirstPollCreate',self.chatId)
                                                    
                                                })
                                                }) 
                                            }
                                        }
                                        else if(self.mainBranchActive || self.subBranchActive || self.subSubBranchActive)
                                        {
                                            self.fromData = {
                                                'avtar':self.userData.profileUrl,
                                                'id':self.userData.id,
                                                'name':self.userData.firstName,
                                                path:`${self.currCollection}`,
                                                
                                            }
                                            updateBranchData = {
                                                from:self.fromData,
                                                msg:self.pollquestion,
                                                type:type,
                                                name:type
                                            }
                                            self.mainBranches[self.mainBranchActiveIndex].lastMsgTime=new Date()
                
                                            // console.log('Main Branch:',self.currCollection)
                                            self.mainBranches.forEach(data=>{
                                                if(data.name == self.currCollection)
                                                {
                                                    data.messageSeenIds.forEach(ids=>{
                                                        if(ids.id!= self.userData.id)
                                                        {
                                                            if(ids.count == 0){
                                                                ids.time = new Date()
                                                            }
                                                            ids.count++
                                                        }
                                                    })
                                                }
                                                else
                                                {
                                                    data.subBranches.forEach(data2=>{
                                                        if(data2.name == self.currCollection)
                                                        {
                                                            data2.messageSeenIds.forEach(ids2=>{
                                                                if(ids2.id!= self.userData.id)
                                                                {
                                                                    if(ids2.count == 0){
                                                                        ids2.time = new Date()
                                                                    }
                                                                    ids2.count++
                                                                }
                                                            })
                                                        }
                                                        else
                                                        {
                                                            data2.subSubBranches.forEach(data3=>{
                                                                if(data3.name == self.currCollection)
                                                                {
                                                                    data3.messageSeenIds.forEach(ids3=>{
                                                                        if(ids3.id!= self.userData.id)
                                                                        {
                                                                            if(ids3.count == 0){
                                                                                ids3.time = new Date()
                                                                            }
                                                                            ids3.count++
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.currCollection).add({
                                                createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                from:self.fromData,
                                                id:'',
                                                questions:self.questions,
                                                uploadPollData : self.uploadData,
                                                type:type,
                                                messageSeen:[self.fromData],
                                                blockBy : self.isInArray || ''
                                            }).then((docref) => {
                                                self.$axios.post(self.$apiurl+'sendwebNotification',{
                                                    userId:self.userData.id,msgCollection:self.currCollection,
                                                    chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                                })
                                                self.$root.$emit('hidePreviewPoll')
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                                    updateBranchData:updateBranchData,
                                                    lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    msgFromBranch:true,
                                                    mainBranches:self.mainBranches,
                                                    blockBy : self.isInArray || ''
                                                })
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.currCollection).doc(docref.id).update({
                                                id:docref.id,
                                                }).then( () => {
                                                    self.$root.$emit("messageSendAudio");
                                                    self.$notify({
                                                        title:"Success",
                                                        text:'Poll Created Successfully.',
                                                        type:"success",
                                                        duration: 10000
                                                    })
                                                    self.isSpinner = false
                                                    self.isInArray = ''
                                                    self.$bvModal.hide('createpoll')
                                                    self.reset();
                                                })
                                            })
                                        }
                                        else if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
                                        {
                                            self.fromData = {
                                                'avtar':self.userData.profileUrl,
                                                'id':self.userData.id,
                                                'name':self.userData.firstName,
                                                path:`${self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name}`,
                                                
                                            }
                                            updateBranchData = {
                                                from:self.fromData,
                                                msg:self.pollquestion,
                                                type:type,
                                                name:type
                                            }
                                            self.mainBranches[self.mainBranchActiveIndex].lastMsgTime=new Date()
                
                                            self.mainBranches.forEach(data=>{
                                                if(data.name == self.currCollection)
                                                {
                                                    data.subBranches.forEach(data2=>{
                                                        if(data2.name == self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name)
                                                        {
                                                            data2.messageSeenIds.forEach(ids2=>{
                                                                if(ids2.id!= self.userData.id)
                                                                {
                                                                    if(ids2.count == 0){
                                                                        ids2.time = new Date()
                                                                    }
                                                                    ids2.count++
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                            // console.log('Sub Branch:',self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name)
                                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name).add({
                                                createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                from:self.fromData,
                                                id:'',
                                                questions:self.questions,
                                                uploadPollData : self.uploadData,
                                                type:type,
                                                messageSeen:[self.fromData],
                                                blockBy : self.isInArray || ''
                                    
                                            }).then((docref) => {
                                                self.$axios.post(self.$apiurl+'sendwebNotification',{
                                                    userId:self.userData.id,msgCollection:self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name,
                                                    chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                                })
                                                self.$root.$emit('hidePreviewPoll')
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name).doc(docref.id).update({
                                                id:docref.id,  
                                                }).then( () => {
                                                    self.$root.$emit("messageSendAudio");
                                                    self.$notify({
                                                        title:"Success",
                                                        text:'Poll Created Successfully.',
                                                        type:"success",
                                                        duration: 10000
                                                    })
                                                    self.isSpinner = false
                                                    self.$bvModal.hide('createpoll')
                                                    self.reset();
                                                })
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                                    updateBranchData:updateBranchData,
                                                    lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    msgFromBranch:true,
                                                    mainBranches:self.mainBranches,
                                                    blockBy : self.isInArray || ''
                                                }).then(() => {
                                                    self.isInArray = '';
                                                })
                                            })
                                        }
                                        else if(self.mainBranchActive && self.subBranchActive && self.subSubBranchActive)
                                        {
                                            self.fromData = {
                                                'avtar':self.userData.profileUrl,
                                                'id':self.userData.id,
                                                'name':self.userData.firstName,
                                                path:`${self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name}`,
                                                
                                            }
                                            updateBranchData = {
                                                from:self.fromData,
                                                msg:self.pollquestion,
                                                type:type,
                                                name:type
                                            }
                                            self.mainBranches[self.mainBranchActiveIndex].lastMsgTime=new Date()
                
                                            self.mainBranches.forEach(data=>{
                                                if(data.name == self.currCollection)
                                                {
                                                    data.subBranches.forEach(data2=>{
                                                    if(data2.name == self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name)
                                                    {
                                                        data2.subSubBranches.forEach(data3=>{
                                                        if(data3.name == self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name)
                                                        {
                                                            data3.messageSeenIds.forEach(ids3=>{
                                                            if(ids3.id!= self.userData.id)
                                                            {
                                                                if(ids3.count == 0){
                                                                    ids3.time = new Date()
                                                                }
                                                                ids3.count++
                                                            }
                                                            })
                                                        }
                                                        })
                                                    }
                                                    })
                                                }
                                            })
                                            // console.log('subSub Branch:',self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name)
                                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name).add({
                                                createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                from:self.fromData,
                                                id:'',
                                                questions:self.questions,
                                                uploadPollData : self.uploadData,
                                                type:type,
                                                messageSeen:[self.fromData],
                                                blockBy : self.isInArray || ''
                                    
                                            }).then((docref) => {
                                                self.$axios.post(self.$apiurl+'sendwebNotification',{
                                                    userId:self.userData.id,msgCollection:self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,
                                                    chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                                })
                                                self.$root.$emit('hidePreviewPoll')
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name).doc(docref.id).update({
                                                id:docref.id,  
                                                }).then( () => {
                                                    self.$root.$emit("messageSendAudio");
                                                    self.$notify({
                                                        title:"Success",
                                                        text:'Poll Created Successfully.',
                                                        type:"success",
                                                        duration: 10000
                                                    })
                                                    self.isSpinner = false
                                                    self.reset();
                                                })
                                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                                    updateBranchData:updateBranchData,
                                                    lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    msgFromBranch:true,
                                                    mainBranches:self.mainBranches,
                                                    blockBy : self.isInArray || ''
                                                }).then(() => {
                                                    self.isInArray = '';
                                                })
                                            })
                                        } 
                                }
                                //=====================================
                            }
                        })
                    }
                }
                if(type == 'TextPoll'){
                    self.addmoreoption.forEach((data) => {
                        self.uploadData.push({
                            count:0,
                            text:data.value
                        })
                    })
                    
                    // console.log('pollquestion',self.pollData, self.uploadData);
                    if(self.chatId != undefined && self.chatId != ''){
                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).get().then(doc => {
                            self.blockUserArray = doc.data().blockUserArray || []
                            self.blockUserArray.forEach(inArray => {
                                if(inArray.blockTo == firebase.auth().currentUser.uid){
                                self.isInArray = inArray.blockBy
                                }
                            })
                        }).then(() => {
                            if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                            {
                                // console.log('Group',self.chatId,self.chatObjectId)
                                if(self.chatId != undefined){
                                    self.messageSeenIds.forEach(data=>{
                                        if(data.id!=self.userid)
                                        {
                                            if(data.count == 0){
                                                data.time = new Date()
                                            }
                                            data.count++
                                        }
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').add({
                                        createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        from:self.fromData,
                                        id:'',
                                        questions:self.questions,
                                        uploadPollData : self.uploadData,
                                        type:type,
                                        messageSeen:[self.fromData],
                                        blockBy : self.isInArray || ''
                                    }).then((docref) => {
                                        self.$axios.post(self.$apiurl+'sendwebNotification',{
                                            userId:self.userData.id,msgCollection:'default',
                                            chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                        })
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').doc(docref.id).update({
                                        id:docref.id,  
                                        }).then( () => {
                                            self.$root.$emit("messageSendAudio");
                                            self.$notify({
                                                title:"Success",
                                                text:'Poll Created Successfully.',
                                                type:"success",
                                                duration: 10000
                                            })
                                            self.isSpinner = false
                                            self.$bvModal.hide('createpoll')
                                            self.reset();
                                        })
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                            updateChat:self.updateChat,
                                            lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                            msgFromBranch:false,
                                            messageSeenIds:self.messageSeenIds,
                                            blockBy : self.isInArray || ''
                                        }).then(() => {
                                            self.isInArray = '';
                                        })
                                    })
                                }else{
                                    self.connectedUserArray = []
                                    self.connectedUserArray.push(self.userid)
                                    self.connectedUserArray.push(self.chatObjectId)
                                    var messageSeenIds = []
                                    self.connectedUserArray.forEach(data=>{
                                        if(data==firebase.auth().currentUser.uid)
                                        {
                                            messageSeenIds.push({id:data,count:0,time:'',IsEmailSend:false})
                                        }
                                        else
                                        {
                                            messageSeenIds.push({id:data,count:1,time:new Date(),IsEmailSend:false})
                                        }
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).add({
                                        connectedUser : self.connectedUserArray,
                                        id:'',
                                        isGroup : false,
                                        lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        title:'',
                                        muteArray:[],
                                        updateChat:{},
                                        msgFromBranch:false,
                                        updateBranchData:{},
                                        SurpriseSelfie : {},
                                        ToggleSelfie : [],
                                        messageSeenIds:messageSeenIds,
                                        blockBy : self.isInArray || ''
                                    }).then((doc) => {
                                        db.collection(firebaseData.firebasecollection.USER).doc(self.userid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.chatObjectId).update({
                                            chatId:doc.id
                                        })
                                        db.collection(firebaseData.firebasecollection.USER).doc(self.chatObjectId).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.userid).update({
                                            chatId:doc.id
                                        })
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(doc.id).update({
                                            id : doc.id
                                        })
                                        self.chatId = doc.id
                                    }).then(()=>{
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').add({
                                        createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        from:self.fromData,
                                        id:'',
                                        questions:self.questions,
                                        uploadPollData : self.uploadData,
                                        type:type,
                                        messageSeen:[self.fromData],
                                        blockBy : self.isInArray || ''
                                    }).then((docref) => {
                                        self.$axios.post(self.$apiurl+'sendwebNotification',{
                                            userId:self.userData.id,msgCollection:'default',
                                            chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                        })
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').doc(docref.id).update({
                                            id:docref.id,  
                                        }).then( () => {
                                            self.isInArray = '';
                                            self.$root.$emit("messageSendAudio");
                                            self.$notify({
                                                title:"Success",
                                                text:'Poll Created Successfully.',
                                                type:"success",
                                                duration: 10000
                                            })
                                            self.isSpinner = false
                                            self.$bvModal.hide('createpoll')
                                            self.reset();
                                        })
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                            updateChat:self.updateChat,
                                            lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                            msgFromBranch:false
                                        })
                                    })
                                    }) 
                                }
                            }
                            else if(self.mainBranchActive || self.subBranchActive || self.subSubBranchActive)
                            {
                                self.fromData = {
                                    'avtar':self.userData.profileUrl,
                                    'id':self.userData.id,
                                    'name':self.userData.firstName,
                                    path:`${self.currCollection}`,
                                    
                                }
                                updateBranchData = {
                                    from:self.fromData,
                                    msg:self.pollquestion,
                                    type:type,
                                    name:type
                                }
                                self.mainBranches[self.mainBranchActiveIndex].lastMsgTime=new Date()
                                
                                self.mainBranches.forEach(data=>{
                                    if(data.name == self.currCollection)
                                    {
                                        data.messageSeenIds.forEach(ids=>{
                                            if(ids.id!= self.userData.id)
                                            {
                                                if(ids.count == 0){
                                                    ids.time = new Date()
                                                }
                                                ids.count++
                                            }
                                        })
                                    }
                                    else
                                    {
                                        data.subBranches.forEach(data2=>{
                                            if(data2.name == self.currCollection)
                                            {
                                                data2.messageSeenIds.forEach(ids2=>{
                                                    if(ids2.id!= self.userData.id)
                                                    {
                                                        if(ids2.count == 0){
                                                            ids2.time = new Date()
                                                        }
                                                        ids2.count++
                                                    }
                                                })
                                            }
                                            else
                                            {
                                                data2.subSubBranches.forEach(data3=>{
                                                    if(data3.name == self.currCollection)
                                                    {
                                                        data3.messageSeenIds.forEach(ids3=>{
                                                            if(ids3.id!= self.userData.id)
                                                            {
                                                                if(ids3.count == 0){
                                                                    ids3.time = new Date()
                                                                }
                                                                ids3.count++
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                                // console.log('Main Branch:',self.currCollection)
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.currCollection).add({
                                    createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    from:self.fromData,
                                    id:'',
                                    questions:self.questions,
                                    uploadPollData : self.uploadData,
                                    type:type,
                                    messageSeen:[self.fromData],
                                    blockBy : self.isInArray || ''
                        
                                }).then((docref) => {
                                    self.$axios.post(self.$apiurl+'sendwebNotification',{
                                        userId:self.userData.id,msgCollection:self.currCollection,
                                        chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.currCollection).doc(docref.id).update({
                                    id:docref.id,  
                                    }).then( () => {
                                        self.$root.$emit("messageSendAudio");
                                        self.$notify({
                                            title:"Success",
                                            text:'Poll Created Successfully.',
                                            type:"success",
                                            duration: 10000
                                        })
                                        self.isSpinner = false
                                        self.$bvModal.hide('createpoll')
                                        self.reset();
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                        updateBranchData:updateBranchData,
                                        lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        msgFromBranch:true,
                                        mainBranches:self.mainBranches,
                                        blockBy : self.isInArray || ''
                                    }).then(() => {
                                        self.isInArray = '';
                                    })
                                })
                            }
                            else if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
                            {
                                self.fromData = {
                                    'avtar':self.userData.profileUrl,
                                    'id':self.userData.id,
                                    'name':self.userData.firstName,
                                    path:`${self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name}`,
                                    
                                }
                                updateBranchData = {
                                    from:self.fromData,
                                    msg:self.pollquestion,
                                    type:type,
                                    name:type
                                }
                                self.mainBranches[self.mainBranchActiveIndex].lastMsgTime=new Date()
                
                                self.mainBranches.forEach(data=>{
                                    if(data.name == self.currCollection)
                                    {
                                        data.subBranches.forEach(data2=>{
                                            if(data2.name == self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name)
                                            {
                                                data2.messageSeenIds.forEach(ids2=>{
                                                    if(ids2.id!= self.userData.id)
                                                    {
                                                        if(ids2.count == 0){
                                                            ids2.time = new Date()
                                                        }
                                                        ids2.count++
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                                // console.log('Sub Branch:',self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name)
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name).add({
                                    createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    from:self.fromData,
                                    id:'',
                                    questions:self.questions,
                                    uploadPollData : self.uploadData,
                                    type:type,
                                    messageSeen:[self.fromData],
                                    blockBy : self.isInArray || ''
                        
                                }).then((docref) => {
                                    self.$axios.post(self.$apiurl+'sendwebNotification',{
                                        userId:self.userData.id,msgCollection:self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name,
                                        chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name).doc(docref.id).update({
                                    id:docref.id,  
                                    }).then( () => {
                                        self.$root.$emit("messageSendAudio");
                                        self.$notify({
                                            title:"Success",
                                            text:'Poll Created Successfully.',
                                            type:"success",
                                            duration: 10000
                                        })
                                        self.isSpinner = false
                                        self.$bvModal.hide('createpoll')
                                        self.reset();
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                        updateBranchData:updateBranchData,
                                        lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        msgFromBranch:true,
                                        mainBranches:self.mainBranches,
                                        blockBy : self.isInArray || ''
                                    }).then(() => {
                                        self.isInArray = '';
                                    })
                                })
                            }
                            else if(self.mainBranchActive && self.subBranchActive && self.subSubBranchActive)
                            {
                                self.fromData = {
                                    'avtar':self.userData.profileUrl,
                                    'id':self.userData.id,
                                    'name':self.userData.firstName,
                                    path:`${self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name}`,
                                    
                                }
                                updateBranchData = {
                                    from:self.fromData,
                                    msg:self.pollquestion,
                                    type:type,
                                    name:type
                                }
                                self.mainBranches[self.mainBranchActiveIndex].lastMsgTime=new Date()
                
                                self.mainBranches.forEach(data=>{
                                    if(data.name == self.currCollection)
                                    {
                                        data.subBranches.forEach(data2=>{
                                            if(data2.name == self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name)
                                            {
                                                data2.subSubBranches.forEach(data3=>{
                                                    if(data3.name == self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name)
                                                    {
                                                        data3.messageSeenIds.forEach(ids3=>{
                                                            if(ids3.id!= self.userData.id)
                                                            {
                                                                if(ids3.count == 0){
                                                                    ids3.time = new Date()
                                                                }
                                                                ids3.count++
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                                // console.log('subSub Branch:',self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name)
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name).add({
                                    createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    from:self.fromData,
                                    id:'',
                                    questions:self.questions,
                                    uploadPollData : self.uploadData,
                                    type:type,
                                    messageSeen:[self.fromData],
                                    blockBy : self.isInArray || ''
                        
                                }).then((docref) => {
                                    self.$axios.post(self.$apiurl+'sendwebNotification',{
                                        userId:self.userData.id,msgCollection:self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,
                                        chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name).doc(docref.id).update({
                                    id:docref.id,  
                                    }).then( () => {
                                        self.$root.$emit("messageSendAudio");
                                        self.$notify({
                                            title:"Success",
                                            text:'Poll Created Successfully.',
                                            type:"success",
                                            duration: 10000
                                        })
                                        self.isSpinner = false
                                        self.$bvModal.hide('createpoll')
                                        self.reset();
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                        updateBranchData:updateBranchData,
                                        lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        msgFromBranch:true,
                                        mainBranches:self.mainBranches,
                                        blockBy : self.isInArray || ''
                                    }).then(() => {
                                        self.isInArray = '';
                                    })
                                })
                            }
                        })
                    }else{
                            if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                            {
                                // console.log('Group',self.chatId,self.chatObjectId)
                                if(self.chatId != undefined && self.chatId != ''){
                                    self.messageSeenIds.forEach(data=>{
                                        if(data.id!=self.userid)
                                        {
                                            if(data.count == 0){
                                                data.time = new Date()
                                            }
                                            data.count++
                                        }
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').add({
                                        createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        from:self.fromData,
                                        id:'',
                                        questions:self.questions,
                                        uploadPollData : self.uploadData,
                                        type:type,
                                        messageSeen:[self.fromData],
                                        blockBy : self.isInArray || ''
                                    }).then((docref) => {
                                        self.$axios.post(self.$apiurl+'sendwebNotification',{
                                            userId:self.userData.id,msgCollection:'default',
                                            chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                        })
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').doc(docref.id).update({
                                        id:docref.id,  
                                        }).then( () => {
                                            self.$root.$emit("messageSendAudio");
                                            self.$notify({
                                                title:"Success",
                                                text:'Poll Created Successfully.',
                                                type:"success",
                                                duration: 10000
                                            })
                                            self.isSpinner = false
                                            self.$bvModal.hide('createpoll')
                                            self.reset();
                                        })
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                            updateChat:self.updateChat,
                                            lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                            msgFromBranch:false,
                                            messageSeenIds:self.messageSeenIds,
                                            blockBy : self.isInArray || ''
                                        }).then(() => {
                                            self.isInArray = '';
                                        })
                                    })
                                }else{
                                    self.connectedUserArray = []
                                    self.connectedUserArray.push(self.userid)
                                    self.connectedUserArray.push(self.chatObjectId)
                                    var messageSeenIds = []
                                    self.connectedUserArray.forEach(data=>{
                                        if(data==firebase.auth().currentUser.uid)
                                        {
                                            messageSeenIds.push({id:data,count:0,time:'',IsEmailSend:false})
                                        }
                                        else
                                        {
                                            messageSeenIds.push({id:data,count:1,time:new Date(),IsEmailSend:false})
                                        }
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).add({
                                        connectedUser : self.connectedUserArray,
                                        id:'',
                                        isGroup : false,
                                        lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        title:'',
                                        muteArray:[],
                                        updateChat:{},
                                        msgFromBranch:false,
                                        updateBranchData:{},
                                        SurpriseSelfie : {},
                                        ToggleSelfie : [],
                                        messageSeenIds:messageSeenIds,
                                        blockBy : self.isInArray || ''
                                    }).then((doc) => {
                                        db.collection(firebaseData.firebasecollection.USER).doc(self.userid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.chatObjectId).update({
                                            chatId:doc.id
                                        })
                                        db.collection(firebaseData.firebasecollection.USER).doc(self.chatObjectId).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.userid).update({
                                            chatId:doc.id
                                        })
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(doc.id).update({
                                            id : doc.id
                                        })
                                        self.chatId = doc.id
                                    }).then(()=>{
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').add({
                                        createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        from:self.fromData,
                                        id:'',
                                        questions:self.questions,
                                        uploadPollData : self.uploadData,
                                        type:type,
                                        messageSeen:[self.fromData],
                                        blockBy : self.isInArray || ''
                                    }).then((docref) => {
                                        self.$axios.post(self.$apiurl+'sendwebNotification',{
                                            userId:self.userData.id,msgCollection:'default',
                                            chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                        })
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection('default').doc(docref.id).update({
                                            id:docref.id,  
                                        }).then( () => {
                                            self.$root.$emit("messageSendAudio");
                                            self.$notify({
                                                title:"Success",
                                                text:'Poll Created Successfully.',
                                                type:"success",
                                                duration: 10000
                                            })
                                            self.isInArray = '';
                                            self.isSpinner = false
                                            self.$bvModal.hide('createpoll')
                                            self.reset();
                                        })
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                            updateChat:self.updateChat,
                                            lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                            msgFromBranch:false
                                        })
                                            //  self.$root.$emit('callGetChatArryForFirstPollCreate',self.chatId)
                                        
                                        })
                                    }) 
                                }
                            }
                            else if(self.mainBranchActive || self.subBranchActive || self.subSubBranchActive)
                            {
                                self.fromData = {
                                    'avtar':self.userData.profileUrl,
                                    'id':self.userData.id,
                                    'name':self.userData.firstName,
                                    path:`${self.currCollection}`,
                                    
                                }
                                updateBranchData = {
                                    from:self.fromData,
                                    msg:self.pollquestion,
                                    type:type,
                                    name:type
                                }
                                self.mainBranches[self.mainBranchActiveIndex].lastMsgTime=new Date()
                                
                                self.mainBranches.forEach(data=>{
                                    if(data.name == self.currCollection)
                                    {
                                        data.messageSeenIds.forEach(ids=>{
                                            if(ids.id!= self.userData.id)
                                            {
                                                if(ids.count == 0){
                                                    ids.time = new Date()
                                                }
                                                ids.count++
                                            }
                                        })
                                    }
                                    else
                                    {
                                        data.subBranches.forEach(data2=>{
                                            if(data2.name == self.currCollection)
                                            {
                                                data2.messageSeenIds.forEach(ids2=>{
                                                    if(ids2.id!= self.userData.id)
                                                    {
                                                        if(ids2.count == 0){
                                                            ids2.time = new Date()
                                                        }
                                                        ids2.count++
                                                    }
                                                })
                                            }
                                            else
                                            {
                                                data2.subSubBranches.forEach(data3=>{
                                                    if(data3.name == self.currCollection)
                                                    {
                                                        data3.messageSeenIds.forEach(ids3=>{
                                                            if(ids3.id!= self.userData.id)
                                                            {
                                                                if(ids3.count == 0){
                                                                    ids3.time = new Date()
                                                                }
                                                                ids3.count++
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                                // console.log('Main Branch:',self.currCollection)
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.currCollection).add({
                                    createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    from:self.fromData,
                                    id:'',
                                    questions:self.questions,
                                    uploadPollData : self.uploadData,
                                    type:type,
                                    messageSeen:[self.fromData],
                                    blockBy : self.isInArray || ''
                        
                                }).then((docref) => {
                                    self.$axios.post(self.$apiurl+'sendwebNotification',{
                                        userId:self.userData.id,msgCollection:self.currCollection,
                                        chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.currCollection).doc(docref.id).update({
                                    id:docref.id,  
                                    }).then( () => {
                                        self.$root.$emit("messageSendAudio");
                                        self.$notify({
                                            title:"Success",
                                            text:'Poll Created Successfully.',
                                            type:"success",
                                            duration: 10000
                                        })
                                        self.isSpinner = false
                                        self.$bvModal.hide('createpoll')
                                        self.reset();
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                        updateBranchData:updateBranchData,
                                        lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        msgFromBranch:true,
                                        mainBranches:self.mainBranches,
                                        blockBy : self.isInArray || ''
                                    }).then(() => {
                                        self.isInArray = ''
                                    })
                                })
                            }
                            else if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
                            {
                                self.fromData = {
                                    'avtar':self.userData.profileUrl,
                                    'id':self.userData.id,
                                    'name':self.userData.firstName,
                                    path:`${self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name}`,
                                    
                                }
                                updateBranchData = {
                                    from:self.fromData,
                                    msg:self.pollquestion,
                                    type:type,
                                    name:type
                                }
                                self.mainBranches[self.mainBranchActiveIndex].lastMsgTime=new Date()
                
                                self.mainBranches.forEach(data=>{
                                    if(data.name == self.currCollection)
                                    {
                                        data.subBranches.forEach(data2=>{
                                            if(data2.name == self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name)
                                            {
                                                data2.messageSeenIds.forEach(ids2=>{
                                                    if(ids2.id!= self.userData.id)
                                                    {
                                                        if(ids2.count == 0){
                                                            ids2.time = new Date()
                                                        }
                                                        ids2.count++
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                                // console.log('Sub Branch:',self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name)
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name).add({
                                    createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    from:self.fromData,
                                    id:'',
                                    questions:self.questions,
                                    uploadPollData : self.uploadData,
                                    type:type,
                                    messageSeen:[self.fromData],
                                    blockBy : self.isInArray || ''
                        
                                }).then((docref) => {
                                    self.$axios.post(self.$apiurl+'sendwebNotification',{
                                        userId:self.userData.id,msgCollection:self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name,
                                        chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].name).doc(docref.id).update({
                                    id:docref.id,  
                                    }).then( () => {
                                        self.$root.$emit("messageSendAudio");
                                        self.$notify({
                                            title:"Success",
                                            text:'Poll Created Successfully.',
                                            type:"success",
                                            duration: 10000
                                        })
                                        self.isSpinner = false
                                        self.$bvModal.hide('createpoll')
                                        self.reset();
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                        updateBranchData:updateBranchData,
                                        lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        msgFromBranch:true,
                                        mainBranches:self.mainBranches,
                                        blockBy : self.isInArray || ''
                                    }).then(() => {
                                        self.isInArray = ''
                                    })
                                })
                            }
                            else if(self.mainBranchActive && self.subBranchActive && self.subSubBranchActive)
                            {
                                self.fromData = {
                                    'avtar':self.userData.profileUrl,
                                    'id':self.userData.id,
                                    'name':self.userData.firstName,
                                    path:`${self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name}`,
                                    
                                }
                                updateBranchData = {
                                    from:self.fromData,
                                    msg:self.pollquestion,
                                    type:type,
                                    name:type
                                }
                                self.mainBranches[self.mainBranchActiveIndex].lastMsgTime=new Date()
                
                                self.mainBranches.forEach(data=>{
                                    if(data.name == self.currCollection)
                                    {
                                        data.subBranches.forEach(data2=>{
                                        if(data2.name == self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name)
                                        {
                                            data2.subSubBranches.forEach(data3=>{
                                            if(data3.name == self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name)
                                            {
                                                data3.messageSeenIds.forEach(ids3=>{
                                                if(ids3.id!= self.userData.id)
                                                {
                                                    if(ids3.count == 0){
                                                        ids3.time = new Date()
                                                    }
                                                    ids3.count++
                                                }
                                                })
                                            }
                                            })
                                        }
                                        })
                                    }
                                })
                                // console.log('subSub Branch:',self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name)
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name).add({
                                    createdAt :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    from:self.fromData,
                                    id:'',
                                    questions:self.questions,
                                    uploadPollData : self.uploadData,
                                    type:type,
                                    messageSeen:[self.fromData],
                                    blockBy : self.isInArray || ''
                        
                                }).then((docref) => {
                                    self.$axios.post(self.$apiurl+'sendwebNotification',{
                                        userId:self.userData.id,msgCollection:self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,
                                        chatId:self.chatId,msgId:docref.id,companyId:Vue.prototype.$companyName.id
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.mainBranches[self.mainBranchActiveIndex].subBanches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name).doc(docref.id).update({
                                    id:docref.id,  
                                    }).then( () => {
                                        self.$root.$emit("messageSendAudio");
                                        self.$notify({
                                            title:"Success",
                                            text:'Poll Created Successfully.',
                                            type:"success",
                                            duration: 10000
                                        })
                                        self.isSpinner = false
                                        self.$bvModal.hide('createpoll')
                                        self.reset();
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).update({
                                        updateBranchData:updateBranchData,
                                        lastMsgtime:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        msgFromBranch:true,
                                        mainBranches:self.mainBranches,
                                        blockBy : self.isInArray || ''
                                    }).then(() => {
                                        self.isInArray = '';
                                    })
                                })
                            }
                    }
                }
           
        }
    },
    createPoll2(){
        var self = this;
        if(self.profileErrorMessage == '' && self.profileErrorMessage1 == '' && self.profileErrorMessage2 == '' && self.profileErrorMessage3 == '' && self.charecterErrorMessageForPollQuastation == '' && self.charecterErrorMessageForPollOption1 == '' && self.charecterErrorMessageForPollOption2 == '' && self.charecterErrorMessageForPollOption3 == '' && self.charecterErrorMessageForPollOption4 == '' ) {
            const fromData = {
                'avtar':self.userData.profileUrl,
                'id':self.userData.id,
                'name':self.userData.firstName,
            };
            self.isSpinner = true
            //console.log(self.optionimage1,self.optionimage2)
            let type = ''
            let meridian = ''
            let minutes = ''
            if(self.chatId != ''){
                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                .doc(self.chatId)
                .get().then(chatData=>{
                    if(chatData.exists)
                    {
                        self.mainBranches = self.sortArray(chatData.data().mainBranches)||[];
                    }
                })
                .catch(error=>{
                    console.log(error)
                })
            }else{
                self.mainBranches = []; 
            }

            self.uploadData = [];
            self.imagesList = [];
            self.pollimages = [];

            if(self.pollData == 'text'){
                type = 'TextPoll';
            }
            else if(self.pollData == 'image'){
                type = 'ImagePoll';
            }

            self.questions = {
                question:self.pollquestion,
                time:self.expiretime,
                date:self.expireDate,
                totalCount:0,
                type:type
            };

            if (type == 'ImagePoll') {
                self.imagesList = []
                self.imagesList.push(self.image);
                self.imagesList.push(self.image1);
                if(self.image2!= null){
                    self.imagesList.push(self.image2);
                    if(self.image3!= null){
                    self.imagesList.push(self.image3);
                    }
                }
    
                //console.log('self.imagesList',self.imagesList)
                let ct = self.imagesList.length
                let ind = 0
                let imgs=[]
                if (self.imagesList) {
                    while (ct--) {
                        var reader = new FileReader();
                        reader.onload = (e) => {
                            imgs.push(e.target.result);
                        };
                        reader.readAsDataURL(self.imagesList[ind]);
                        ind++;
                    }
                }
    
                self.$root.$emit('showPreviewPoll',{question:self.questions,type:type,images:imgs,imagetexts:[self.optionimage1,self.optionimage2,self.optionimage3,self.optionimage4]})
                self.$bvModal.hide('createpoll')
    
                for(let i=0;i<self.imagesList.length;i++){
                    uploadImageFile(firebaseData.firebasecollection.PROFILEIMAGES,self.imagesList[i].name,self.imagesList[i].size,self.imagesList[i],'poll_images','',res=>{
                        self.pollimages.push(res.imageUrl)
                        const imagesize = res.imageSize

                        if(self.pollimages.length == self.imagesList.length){
                            self.uploadData.push({
                                count:0,
                                text:self.optionimage1,
                                imageUrl:self.pollimages[0]
                            })
                            self.uploadData.push({
                                count:0,
                                text:self.optionimage2,
                                imageUrl:self.pollimages[1]
                            })
                            if(self.morefourthoption == true && self.optionimage3!= '' ) {
                                self.uploadData.push({
                                    count:0,
                                    text:self.optionimage3,
                                    imageUrl:self.pollimages[2]
                                })
                                if(self.addfouroption == true  && self.optionimage4!= '' ){
                                    self.uploadData.push({
                                        count:0,
                                        text:self.optionimage4,
                                        imageUrl:self.pollimages[3]
                                    }) 
                                }
                            }
                            if(self.chatId !== undefined && self.chatId !== '') {
                                self.addMessageAndCallAPI({
                                    createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    from: fromData,
                                    questions: self.questions,
                                    uploadPollData: self.uploadData,
                                    type: type,
                                    messageSeen: [fromData],
                                });
                            } else {
                                self.currCollection = "default";

                                self.connectedUserArray = [];
                                self.connectedUserArray.push(self.userid);
                                self.connectedUserArray.push(self.chatObjectId);
                                let messageSeenIds=[];

                                self.connectedUserArray.forEach(data=>{
                                    if(data==firebase.auth().currentUser.uid)
                                    {
                                        messageSeenIds.push({id:data,count:0,time:'',isEmailSend:false});
                                    }
                                    else
                                    {
                                        messageSeenIds.push({id:data,count:1,time:new Date(),isEmailSend:false});
                                    }
                                })

                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).add({
                                    connectedUser : self.connectedUserArray,
                                    id:'',
                                    isGroup : false,
                                    lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    title:'',
                                    muteArray:[],
                                    updateChat:{},
                                    msgFromBranch:false,
                                    updateBranchData:{},
                                    SurpriseSelfie : {},
                                    ToggleSelfie : [],
                                    blockBy : self.isInArray || ''
                                }).then((doc) => {
                                    self.chatId = doc.id;

                                    self.$emit('assignDocId',doc.id)

                                    db.collection(firebaseData.firebasecollection.USER).doc(self.userid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(doc.id).update({
                                        chatId:doc.id
                                    })
                                    db.collection(firebaseData.firebasecollection.USER).doc(doc.id).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.userid).update({
                                        chatId:doc.id
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(doc.id).update({
                                        id : doc.id
                                    })
                                    
                                    self.addMessageAndCallAPI({
                                        createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        from: fromData,
                                        questions: self.questions,
                                        uploadPollData: self.uploadData,
                                        type: type,
                                        messageSeen: [fromData],
                                    });
                                })
                                .catch(error=>{
                                    console.error(error);
                                })
                            }
                        }
                    })
                }
            }

            if(type == 'TextPoll') {
                self.addmoreoption.forEach((data) => {
                    self.uploadData.push({
                        count:0,
                        text:data.value
                    })
                })

                if(self.chatId !== undefined && self.chatId !== '') {
                    self.addMessageAndCallAPI({
                        createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        from: fromData,
                        questions: self.questions,
                        uploadPollData: self.uploadData,
                        type: type,
                        messageSeen: [fromData],
                    });
                } else {
                    self.currCollection = "default";

                    self.connectedUserArray = [];
                    self.connectedUserArray.push(self.userid);
                    self.connectedUserArray.push(self.chatObjectId);
                    let messageSeenIds=[];

                    self.connectedUserArray.forEach(data=>{
                        if(data==firebase.auth().currentUser.uid)
                        {
                            messageSeenIds.push({id:data,count:0,time:'',isEmailSend:false});
                        }
                        else
                        {
                            messageSeenIds.push({id:data,count:1,time:new Date(),isEmailSend:false});
                        }
                    })

                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).add({
                        connectedUser : self.connectedUserArray,
                        id:'',
                        isGroup : false,
                        lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        title:'',
                        muteArray:[],
                        updateChat:{},
                        msgFromBranch:false,
                        updateBranchData:{},
                        SurpriseSelfie : {},
                        ToggleSelfie : [],
                        blockBy : self.isInArray || ''
                    }).then((doc) => {
                        self.chatId = doc.id;
                        self.$emit('assignDocId',doc.id)

                        db.collection(firebaseData.firebasecollection.USER).doc(self.userid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(doc.id).update({
                            chatId:doc.id
                        })
                        db.collection(firebaseData.firebasecollection.USER).doc(doc.id).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.userid).update({
                            chatId:doc.id
                        })
                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(doc.id).update({
                            id : doc.id
                        })

                        
                        self.addMessageAndCallAPI({
                            createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                            updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                            from: fromData,
                            questions: self.questions,
                            uploadPollData: self.uploadData,
                            type: type,
                            messageSeen: [fromData],
                        });
                    })
                    .catch(error=>{
                        console.error(error);
                    })
                }
            }
        }
    },
    addMessageAndCallAPI(chatData){
        debugger // eslint-disable-line
        var self = this;

        try{
            self.blockUserArray = self.storeChatData.blockUserArray || []
            self.blockUserArray.forEach(inArray => {
            if(inArray.blockTo == firebase.auth().currentUser.uid){
                self.isInArray = inArray.blockBy
            }
            })
            const query = db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatId).collection(self.currCollection);

            //Request to update chat object
            self.$axios.post(self.$apiurl+'sendMessage3',{
                "socialChat": false,
                "messageCollection": self.currCollection,
                "chatId": self.chatId,
                "companyId": Vue.prototype.$companyName.id,
                "messageData":{...chatData, blockBy: self.isInArray || ""},
            })

            // Store message in specified collection
            query
            .add({...chatData, blockBy: self.isInArray || ""})
            .then((docRef)=>{
                query
                .doc(docRef.id)
                .update({
                    id: docRef.id
                })
                .catch(error=>{
                    console.error(error);
                })
                self.$axios.post(self.$apiurl+'sendpushNotification',{
                    userId:self.userData.id,msgCollection:self.currCollection,
                    chatId:self.chatId,msgId:docRef.id,companyId:Vue.prototype.$companyName.id,
                    userName: self.userData.firstName || "",
                    userProfile: self.userData.profileUrl || ""
                })
                self.$root.$emit("messageSendAudio");
                self.$notify({
                    title:"Success",
                    text:'Poll Created Successfully.',
                    type:"success",
                    duration: 10000
                });
                self.isSpinner = false
                self.isInArray = '';
                self.$bvModal.hide('createpoll');
                self.$root.$emit('hidePreviewPoll')
                self.reset();
            })
            .catch(error=>{
                console.error(error);
            })
        } catch(error) {
            console.error(error);
        }
    },
    reset(){
        var self=this;
        self.moreoption = false
        self.pollquestion = '',
        self.expireDate = '',
        self.expiretime = '',
        // self.option1 = '',
        //  self.option2 = '',
        //  self.option3 = '',
        //  self.option4 = '',
         self.optionimage1 = '',
         self.optionimage2 = '',
         self.optionimage3 = '',
         self.optionimage4 = '',
         self.preview = null,
         self.preview1 = null,
         self.preview2= null,
         self.preview3 = null,
        self.image =  null,
        self.image1 =  null,
        self.image2 =  null,
        self.image3 =  null,
         self.profileErrorMessage='',
        self.profileErrorMessage1='',
        self.profileErrorMessage2='',
        self.profileErrorMessage3=''
       
        self.addmoreoption=[
          {value:''},
          {value:''}
        ],
        self.morefourthoption = false,
        self.addfouroption = false;
        self.$refs.log.reset();
    },
    removeOption4(){
    
        this.addfouroption = false
        this.image3 =  null
        this.preview3= null
        this.optionimage4 = ''
    },
    // removeOption(){
    //     if(this.addfouroption == false && this.image3 == null && this.preview3 == null && this.optionimage4 == ''){
    //     this.morefourthoption = false 
    //     this.image2 =  null
    //     this.preview2= null
    //     this.optionimage3 = ''
    //     this.$refs.log.reset()
    //     }else{
    //     this.addfouroption = false
    //     this.image3 =  null
    //     this.preview3= null
    //     this.optionimage4 = ''
    //     this.$refs.log.reset()
    //     }
    // },
     removeOption(){
        if(this.addfouroption == false && this.image3 == null && this.preview3 == null && this.optionimage4 == ''){
            this.morefourthoption = false 
            this.image2 =  null
            this.preview2= null
            this.optionimage3 = ''
            this.$refs.log.reset()
        }else{
            this.addfouroption = false
            this.image2 = this.image3
            this.preview2 = this.preview3
            this.optionimage3 = this.optionimage4
            this.image3 =  null
            this.preview3= null
            this.optionimage4 = ''
            this.$refs.log.reset()
        }
    },
     // CHECK CHARECTER LIMIT IN FEEDBACK (Shraddha)
    checkCharecterLengthPollQuastation(groupName){
        // debugger // eslint-disable-lines
        var self = this;
        if(groupName.length < 51){
          self.pollquestion = groupName
          self.charecterErrorMessageForPollQuastation = '';
        }else{
          self.charecterErrorMessageForPollQuastation = 'The question may not be greater than 50 characters';
          self.pollquestion = (groupName).slice(0,50)
        }
    }
  },

  computed:
  {
    ...mapGetters({storeChatId:"getChatId"}),
    ...mapGetters({storeChatData:"getChatData"}),
    getCurrentTime()
    {
        var dt = new Date()
        
        var expDate = this.expireDate.split('-')
        var minMinute = 2
        var min = this.min
        var hrs = this.hrs

        // console.log(expDate,this.expiretime.split(':')[0])
        // setInterval(()=>{
            if(expDate[0]>=dt.getFullYear() && expDate[1]>=dt.getMonth()+1 && expDate[2]>dt.getDate())
            {
                return `${hrs<10?'0'+hrs:hrs}:${min<10?'0'+min:min}`
            }
            else if(expDate[0]>=dt.getFullYear() && expDate[1]>=dt.getMonth()+1 && expDate[2]==dt.getDate())
            {
                setInterval(()=>{
                    min++
                },1000)
                if(hrs<=this.expiretime.split(':')[0])
                {
                    if(min < 60-minMinute)
                    {
                        return `${hrs<10?'0'+hrs:hrs}:${min<10?'0'+(min + minMinute):min + minMinute}`
                    }
                    else
                    {
                        min = min + minMinute - 60
                        hrs += 1
                        // console.log('adfdfdfdfw3r34',min,hrs)
                        return `${hrs<10?'0'+hrs:hrs}:${min<10?'0'+min:min}`
                    }
                }
                else if(hrs>this.expiretime.split(':')[0])
                {
                    return `${hrs<10?'0'+hrs:hrs}:${min<10?'0'+min:min}`
                }
            }
            else
            {
                return `${hrs<10?'0'+hrs:hrs}:${min<10?'0'+min:min}`
            }
        // },1000)

    },

    checkValid()
    {
        var isValid = true

        var curr = this.expiretime.split(':')
        var limit = this.getCurrentTime.split(':')

        // console.log(curr,limit)
        if(this.expiretime!='')
        {
            if(parseInt(curr[0])==parseInt(limit[0]))
            {
                if(curr[1]<limit[1])
                {
                    isValid = false
                }
            }
            if(parseInt(curr[0])<parseInt(limit[0]))
            {
                isValid = false
            }
            // console.log(curr,limit)
        }
        return isValid
    }
  }
};

</script>
