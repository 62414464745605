<template>

  <div class="error_page">
    <div class="error_left">
      <img src="@/assets/images/artwork.png" />
    </div>
    <div class="error_right">
      <h2>Oops!</h2>
      <h1>404</h1>
      <h4>Page not found!</h4>
      <p>The link you followed probably broken,<br> or the page has been removed.</p>
      <div class="error_link">
        <router-link to="/"><button type="button">Back to Home</button></router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return{

    }
  }
}
</script>