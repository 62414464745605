<script>
import { Line } from 'vue-chartjs'
export default {
    extends : Line,
    props: {
        xAxis_arr: Array,
        yAxis_arr: Array,
        yAxis_arr2: Array,
    },
    created(){
        // console.log("chartInfo PROPS Line",this.yAxis_arr,this.yAxis_arr2);
        this.$root.$on('renderCharts',()=>{
            this.renderChartFun()
        })
    },
    mounted() {
        this.renderChartFun()
    },
    methods:{
        renderChartFun()
        {
            this.renderChart({
                labels: this.xAxis_arr,
                datasets: [
                    {
                        label: 'Social',
                        backgroundColor: '#ffab3f',
                        data: this.yAxis_arr
                    },
                    {
                        label: 'Business',
                        backgroundColor: '#77bef6',
                        data: this.yAxis_arr2
                    }
                ]
            }, {responsive: true, maintainAspectRatio: false})
        }
    }
}
</script>