<template>
    <div class="chat-app-wrapper" id="chat" :class="{loaderBackground : showLoaders}">
        <section>
            <Header v-if="isAdmin == false" :imageUrl= userData :chatObject= chatObject :activeGroup = activeGroup :activeChat= activeChat :groupData="groupData" :businessMenuActive="businessMenuActive" @onupdateSearch = updatesearch :activeChannel="activeChannel"></Header>
            <AdminHeader v-if="isAdmin == true "></AdminHeader>
        </section>
        <section class="chat-app-section" v-if="isAdmin == false" @click="closeDropDown()">
            <div class="chat-area">
                <!-- Fisrt part -->
                <div class="vertical-menu">
                    <ul>
                        <li 
                        class="admin_menu" v-bind:class="{ active: selectedActiveMenu == 'admin'}"
                        @click="getMenuScreen('admin')">
                            <span class="admin_menu_Name" v-if="storeSelectedCompany!={}" title="Create group and channel">
                            <!-- {{companyName.companyName.charAt(0).toUpperCase()}} -->
                            {{storeSelectedCompany!=""?storeSelectedCompany.companyName.charAt(0).toUpperCase():''}}
                            </span>
                            <span v-if="newMessageIngroup" class="socialIncomintRequestDot"></span>

                        </li>
                        <li v-if="displayChat"
                        class="" v-bind:class="{ active: selectedActiveMenu == 'adminChat' }"
                        @click="getMenuScreen('adminChat')">
                            <img src="@/assets/images/A_chat.png" class="default-img" title="Direct message">
                            <img src="@/assets/images/adminchat.png" class="active-img" title="Direct message">
                            <span v-if="newMessageInDirectMessage" class="socialIncomintRequestDot"></span>
                        </li>

                        <li v-if="displayChat"
                        v-bind:class="{ active: selectedActiveMenu == 'adminUser' }"
                        @click="getMenuScreen('adminUser')">
                            <img src="@/assets/images/A_contact.png" class="default-img" title="Contacts">
                            <img src="@/assets/images/admin_user.png" class="active-img" title="Contacts">
                        </li>
                    </ul>
                </div>
                <!-- Fisrt part End -->    
                <!-- Second part -->
                <div class="active-user-list" :class="{'hideChat' : !displayChat}">
                    <div class="active-chat-tab" :class="{'businessDot':newMessageInSocial}">
                        <div class="active-chat-tab" :class="{'socialDotWhite':newMessageInBusiness}">
                            <b-tabs content-class="mt-3" align="center">
                                <b-tab title="Social" @click="SocialMenuActiveTab()">
                                    <ActiveChatList v-if="selectedActiveMenu == 'activeList'" :chatObjectId="chatObjectId" @OnupdateBulkData="BulkData"></ActiveChatList>
                                </b-tab>
                                <b-tab title="Business" active @click="businessMenuActiveTab()">
                                    <div class="admin_business_wrap" > 
                                    <BusinessChatList v-if="selectedActiveMenu == 'adminChat'" style="border-top:1px solid #cfcfcf" :chatObjectId="chatObjectId" :selectedActiveMenu="selectedActiveMenu" @OnupdateBulkData="BulkData" ></BusinessChatList>
                                    <BusinessChatList v-if="selectedActiveMenu == 'admin'" :chatObjectId="chatObjectId" :selectedActiveMenu="selectedActiveMenu" @OnupdateBulkData="BulkData" ></BusinessChatList>
                                    <BusinessUser v-if="selectedActiveMenu == 'adminUser'"></BusinessUser>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
                <div class="displayChatAccessMessage" v-if="!displayChat">
                    <p >You don't have permission to view this screen. Upgrade your plan now to get access.</p>
                    <router-link  class="btn btn-blue" to="/businessgeneralsettings">Upgrade Plan</router-link>
                </div>
                <!-- Second part End -->
                <!-- message screen third  -->
                <div class="message-screen" :class="{'hideChat' : !displayChat}" v-if="dropFiles== false">
                    <!-- {{sendreceArray.length}} -->
                    <div class="loader_div only_messagescrenn" v-if="showLoaders">         
                        <img src="@/assets/images/Blue_GIF.gif" class="lodar_gif">
                    </div>
                    <span class="search_error_display" v-if="searchErrorMessage != '' && sendreceArray.length == 0  ">{{searchErrorMessage}}</span>
                    <div class="no-active-user-wrapper" v-if="activeGroup || activeChat">
                        <div class="chat-screen-topbar bg-topheader" :class="{'chatbusinessheader' : businessMenuActive == true}">
                            <div class="top-bar-after-addmember">
                                <div class="tag-list">
                                    <ul>
                                        <li>
                                            <span class="topbar-branchTag" :class="{'branchActiveInBusinessHeader': !mainBranchActive && !subBranchActive &&!subSubBranchActive}">
                                                <span style="cursor:pointer" @click.prevent="subSubBranchActive=false,subBranchActiveIndex=0,subBranchActive=false,subBranchActiveIndex=0,mainBranchActiveIndex=0,mainBranchActive=false,getChatArray('default',chatObjectId),sendreceArray=[],branchName=''"  >
                                                    <b v-if="activeGroup" class="send-arrow-green">{{groupData.title}}</b>
                                                    <b v-else-if="groupData.isChannel == true" class="send-arrow-green">{{groupData.title}}</b>
                                                    <b v-else class="send-arrow-green">{{chatObject.firstName}}</b>
                                                    <img v-if="activeGroup && isGroupMuted" src="@/assets/images/group-muted.png" class="volume-icon top-volume-icon">
                                                </span>
                                            </span>
                                        </li>
                                        <li v-if="mainBranches!=[] && mainBranchActive" :class="{'branchTopHeaderSocial': countAllbranch(mainBranches[mainBranchActiveIndex],'mainbranch')>0}">
                                            <span class="topbar-branchTag businessBranchDropdown" :class="{'branchActiveInBusinessHeader': mainBranchActive && !subBranchActive &&!subSubBranchActive}">
                                                <span style="cursor:pointer" class="BranchActiveSpan" @click="subBranchActive=false,subBranchActiveIndex=0,subSubBranchActive=false,subSubBranchActiveIndex=0,getChatArray(mainBranches[mainBranchActiveIndex].name,chatObjectId)">
                                                    {{mainBranches[mainBranchActiveIndex].name}}
                                                </span>
                                                <span class="msg_count_span BusinessMessageCount" v-if="countAllbranch(mainBranches[mainBranchActiveIndex],'mainbranch')>0">{{countAllbranch(mainBranches[mainBranchActiveIndex],'mainbranch')>0?`${countAllbranch(mainBranches[mainBranchActiveIndex],'mainbranch')>9?'9+':countAllbranch(mainBranches[mainBranchActiveIndex],'mainbranch')}`:''}}</span>
                                                <img style="cursor:pointer" id="mainBranchDropdown" @click="openDropdownBranch(),insideDivClickActiveBranch(mainBranches[mainBranchActiveIndex].name)" src="@/assets/images/dotimg.png" class="volume-icon top-volume-icon branchImageDropdown">
                                                <div class="message-nt BrnachDropdownSelection"   v-if="selectDropdown">
                                                    <span class="u_name" @click="selectedMainBranchDropdown('Delete')">Delete</span>
                                                    <span v-if="!checkMuted(mainBranches[mainBranchActiveIndex].muteArray)" @click="selectedMainBranchDropdown('Mute')" class="u_name" >Mute</span>
                                                    <span v-else class="u_time" @click="selectedMainBranchDropdown('Unmute')">Unmute</span>
                                                </div>
                                            </span>
                                        </li>
                                        <li v-if="mainBranches!=[] && mainBranchActive && subBranchActive" :class="{'branchTopHeaderSocial': countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex],'mainbranch')>0}">
                                            <span class="topbar-branchTag businessBranchDropdown" :class="{'branchActiveInBusinessHeader': mainBranchActive && subBranchActive &&!subSubBranchActive}">
                                                <span style="cursor:pointer" class="BranchActiveSpan" @click="subSubBranchActiveIndex=0,subSubBranchActive=false,getChatArray(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].name,chatObjectId)">
                                                    {{mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].name}}
                                                </span>
                                                <span class="msg_count_span BusinessMessageCount" v-if="countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex],'subbranch')>0">{{countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex],'subbranch')>0?`${countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex],'subbranch')>9?'9+':countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex],'subbranch')}`:''}}</span>
                                                <img style="cursor:pointer" id="subBranchDropdown" @click="openSubDropdownBranch(),insideDivClickActiveBranch1(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].name)" src="@/assets/images/dotimg.png" class="volume-icon top-volume-icon branchImageDropdown">
                                                    <div class="message-nt BrnachDropdownSelection" v-if="selectSubDropdown">
                                                    <span class="u_name" @click="selectedSubBranchDropdown('Delete')">Delete</span>
                                                    <span v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].muteArray)" @click="selectedSubBranchDropdown('Mute')" class="u_name" >Mute</span>
                                                    <span v-else class="u_time" @click="selectedSubBranchDropdown('Unmute')">Unmute</span>
                                                </div>
                                            </span>
                                        </li>
                                        <li v-if="mainBranches!=[] && mainBranchActive && subBranchActive && subSubBranchActive" :class="{'branchTopHeaderSocial': countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex],'mainbranch')>0}">
                                            <span class="topbar-branchTag businessBranchDropdown" :class="{'branchActiveInBusinessHeader': mainBranchActive && subBranchActive && subSubBranchActive}">
                                                <span style="cursor:pointer" class="BranchActiveSpan" @click="getChatArray(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex].name,chatObjectId)">
                                                {{mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex].name}}
                                                </span>
                                                <span class="msg_count_span BusinessMessageCount" v-if="countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex],'subsubbranch')>0">{{countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex],'subsubbranch')>0?`${countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex],'subsubbranch')>9?'9+':countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex],'subsubbranch')}`:''}}</span>
                                                <img style="cursor:pointer" id="subSubBranchDropdown"  @click="openSubSubDropdownBranch(),insideDivClickActiveBranch2(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex].name)" src="@/assets/images/dotimg.png" class="volume-icon top-volume-icon branchImageDropdown">
                                                <div class="message-nt BrnachDropdownSelection"   v-if="selectSubSubDropdown">
                                                    <span class="u_name" @click="selectedSubSubBranchDropdown('Delete')">Delete</span>
                                                    <span v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex].muteArray)" @click="selectedSubSubBranchDropdown('Mute')" class="u_name" >Mute</span>
                                                    <span v-else class="u_time" @click="selectedSubSubBranchDropdown('Unmute')">Unmute</span>
                                                </div>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="black-tag-list">
                                    <ul class="top_new_list business_top_list">
                                        <li v-if="showStartDots()" class="branch_show" @click.prevent="changePage('prev')"><img src="@/assets/images/dotimg.png"/></li>
                                        <div v-if="mainBranches!=[] && !mainBranchActive" >
                                            <li
                                                style="cursor:pointer" :class="{'branchHeaderBusiness' : countAllbranch(data,'mainbranch')>0}"
                                                v-for="(data,index) in  mainBranches" :key="index"
                                                v-show="index>=startIndex && index <endIndex"
                                            >
                                            <div v-if="index>=startIndex && index <endIndex">
                                                <span class="branch_msg_span"
                                                @click="mainBranchActive=true,mainBranchActiveIndex=index,getChatArray(mainBranches[mainBranchActiveIndex].name,chatObjectId),subBranchActive=false,subBranchActiveIndex=0,sendreceArray=[],branchName=data.name,startIndex=0,endIndex=6"
                                                >
                                                {{data.name}}
                                                </span>
                                                <span class="msg_count_span" v-if="countAllbranch(data,'mainbranch')>0">{{countAllbranch(data,'mainbranch')>0?`${countAllbranch(data,'mainbranch')>9?'9+':countAllbranch(data,'mainbranch')}`:''}}</span>
                                                <img style="cursor:pointer"  :id="'clickbox'+index" @click="openActiveDropdownBranch(data),insideDivClick(data,index)" src="@/assets/images/dotimg.png" class="volume-icon top-volume-icon branchImageDropdown">
                                                    <div class="message-nt BrnachDropdownSelection"   v-if="data.flag">
                                                        <span class="u_name" @click="selectedMainBranchActiveDropdown(mainBranches,data.name,data.msgid,index,'Delete')">Delete</span>
                                                        <span v-if="!checkMuted(mainBranches[index].muteArray)" @click="selectedMainBranchActiveDropdown(mainBranches,data.name,data.msgid,index,'Mute')" class="u_name" >Mute</span>
                                                        <span v-else class="u_time" @click="selectedMainBranchActiveDropdown(mainBranches,data.name,data.msgid,index,'Unmute')">Unmute</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </div>
                                        <div v-if="mainBranchActive && mainBranches[mainBranchActiveIndex].subBranches.length!=0 && !subBranchActive" >
                                            <li
                                                style="cursor:pointer" :class="{'branchHeaderBusiness' : countAllbranch(data,'subbranch')>0}"
                                                v-for="(data,index) in  mainBranches[mainBranchActiveIndex].subBranches" :key="index"
                                                v-show="index>=startIndex && index <endIndex"
                                            >
                                            <div v-if="index>=startIndex && index <endIndex">
                                                <span class="branch_msg_span"
                                                @click.prevent="subSubBranchActive=false,subSubBranchActiveIndex=0,subBranchActive=true,subBranchActiveIndex=index,getChatArray(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].name,chatObjectId),sendreceArray=[],branchName=data.name,startIndex=0,endIndex=6"
                                                >
                                                {{data.name}}
                                                </span>
                                                <span class="msg_count_span" v-if="countAllbranch(data,'subbranch')>0">{{countAllbranch(data,'subbranch')>0?`${countAllbranch(data,'subbranch')>9?'9+':countAllbranch(data,'subbranch')}`:''}}</span>
                                                <img style="cursor:pointer" :id="'clickbox'+index" @click="openActiveSubDropdownBranch(data),insideDivClick(data,index)" src="@/assets/images/dotimg.png" class="volume-icon top-volume-icon branchImageDropdown">
                                                    <div class="message-nt BrnachDropdownSelection"   v-if="data.flag">
                                                        <span class="u_name" @click="selectedSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,data.name,data.msgid,index,'Delete')">Delete</span>
                                                        <span v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[index].muteArray)" @click="selectedSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,data.name,data.msgid,index,'Mute')" class="u_name" >Mute</span>
                                                        <span v-else class="u_time" @click="selectedSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,data.name,data.msgid,index,'Unmute')">Unmute</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </div>
                                        <div v-if="mainBranchActive && mainBranches[mainBranchActiveIndex].subBranches.length!=0 && subBranchActive && !subSubBranchActive" >
                                            <li
                                            style="cursor:pointer"   :class="{'branchHeaderBusiness' : countAllbranch(data,'subsubbranch')>0}"
                                            v-for="(data,index) in  mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches" :key="index"
                                            v-show="index>=startIndex && index <endIndex"
                                            >
                                                <div v-if="index>=startIndex && index <endIndex">
                                                    <span class="branch_msg_span"
                                                    @click.prevent="subSubBranchActive=true,subSubBranchActiveIndex=index,getChatArray(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[index].name,chatObjectId),sendreceArray=[],branchName=data.name,startIndex=0,endIndex=6"
                                                    >
                                                    {{data.name}}
                                                    </span>
                                                    <span class="msg_count_span" v-if="countAllbranch(data,'subsubbranch')>0">{{countAllbranch(data,'subsubbranch')>0?`${countAllbranch(data,'subsubbranch')>9?'9+':countAllbranch(data,'subsubbranch')}`:''}}</span>
                                                    <img style="cursor:pointer" :id="'clickbox'+index" @click="openActiveSubSubDropdownBranch(data),insideDivClick(data,index)" src="@/assets/images/dotimg.png" class="volume-icon top-volume-icon branchImageDropdown">
                                                    <div class="message-nt BrnachDropdownSelection"   v-if="data.flag">
                                                        <span class="u_name" @click="selectedSubSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,subBranchActiveIndex,data.name,data.msgid,index,'Delete')">Delete</span>
                                                        <span v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[index].muteArray)" @click="selectedSubSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,subBranchActiveIndex,data.name,data.msgid,index,'Mute')" class="u_name" >Mute</span>
                                                        <span v-else class="u_time" @click="selectedSubSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,subBranchActiveIndex,data.name,data.msgid,index,'Unmute')">Unmute</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </div>
                                        <li v-if="showEndDots()" class="branch_show"><img @click.prevent="changePage('next')" src="@/assets/images/dotimg.png"/></li>
                                        <li :class="{'hideFooterForDooeyAdmin': botUserEmail == chatObject.email}" class="add_branch_icon no_bg_button" v-if="!subSubBranchActive"> <img src="@/assets/images/plus.svg" @click.prevent="createBranchMessageId='',botUserEmail != chatObject.email ? $bvModal.show('create-branch') : ''"  class="black-add-branch" style="cursor: pointer;width: 26px;" title="Create branch"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="main-chat-screen business_message_body" ondragstart="return false" v-if="activeChat == true">
                            <div class="loader_div" v-if="showLoader && searchErrorMessage == '' ">
                                <img src="@/assets/images/Blue_GIF.gif" class="lodar_gif">
                            </div>
                            <div style="visibility:hidden; opacity:0; z-index:1;" id="dropzone">
                                <div id="textnode">Drop file to share.</div>
                            </div>
                            <div class="message-body" id="scrollParent"  @click="checkMessagesSeen()" @mouseover ="checkActiveTab()" v-if="sendreceArray.length != 0" v-chat-scroll="{always: false, smooth: false, scrollonremoved:true, smoothonremoved: false}" @v-chat-scroll-top-reached="scroollmethod()">
                                <div v-for="(chatmessage,index) in sendreceArray" v-bind:key="index">
                                <!-- FOR RECEIVERMESSAGES-->
                                <div class="messageNotSeen" v-if="unreadMessages!=0 && index == (sendreceArray.length-unreadMessages)">
                                    <span>{{unreadMessages}} Unread Messages</span>
                                </div>
                                <div class="userLeftMessage" v-if="chatmessage.type.toLowerCase()=='left'">
                                    <span ondragstart="return false">{{chatmessage.msg}}</span>
                                </div>
                                <div class="message left" v-if="chatmessage.messageType == 'receiver' && chatmessage.type != 'TextPoll' && chatmessage.type!='ImagePoll'  && chatmessage.type.toLowerCase() != 'left' ">
                                    <div>
                                    <p ondragstart="return false" v-if="chatmessage.type.toLowerCase() == 'text' " >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.receivermsg}}</text-highlight>
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'link' " ><a :href="chatmessage.receivermsg" target="_blank">
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.receivermsg}}</text-highlight>
                                        </a></p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'surpriseselfietext' "> 
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.receivermsg.trim()}}</text-highlight>
                                        <img src="@/assets/images/bussinessSelfieIconAndroid.png" style="height:28px; width:30px; object-fit: contain;" alt="">
                                        <span style="display:block;"><span style="color:#77bef6;">Note:</span>To view this message, please login to the mobile application.</span>
                                    </p>
                                    <p @click="imageZoom(chatmessage.createdAt)"  v-if="chatmessage.type.toLowerCase() == 'image'" class="downloadIconWrapper">
                                        <img :src="chatmessage.mediaUrl" v-b-modal.imageZoom>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        <img v-if="chatmessage.type.toLowerCase() == 'surpriseselfie'" src="@/assets/images/bussinessSelfieIconAndroid.png" style="height:28px; width:30px; object-fit: contain;" alt="">
                                        </p>
                                        <p @click="imageZoom(chatmessage.createdAt)"  v-if="chatmessage.type.toLowerCase() == 'surpriseselfie' " class="downloadIconWrapper">
                                        <img :src="chatmessage.msg" v-b-modal.imageZoom>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.msg,chatmessage.name)"/>
                                        <img v-if="chatmessage.type.toLowerCase() == 'surpriseselfie'" src="@/assets/images/bussinessSelfieIconAndroid.png" style="height:28px; width:30px; object-fit: contain;" alt="">
                                        </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'pdf' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                        <img src="@/assets/images/PDF_file_icon.png" class="pdf_icon"/>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        </a>

                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>                   
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'excel' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                        <img src="@/assets/images/Exl-icon.png" class="pdf_icon"/>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>                         
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'word' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                        <img src="@/assets/images/Word_Icon.png" class="pdf_icon"/>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>                          </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'odt' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                        <img src="@/assets/images/ODT.png" class="pdf_icon"/>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>                          </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'ods' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                        <img src="@/assets/images/ODS.png" class="pdf_icon"/>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>                          </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'ppt' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                        <img src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon"/>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>                          </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'html' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                        <img src="@/assets/images/images.png" class="pdf_icon"/>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>                          </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'plain' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                        <img src="@/assets/images/834209.png" class="pdf_icon"/>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>                          </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'xd' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                        <img src="@/assets/images/imagesxd.png" class="pdf_icon"/>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>                          </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'video' " style="position: relative;">
                                        <video controls="controls" 
                                                class="video-stream" 
                                                x-webkit-airplay="allow" 
                                                :src="chatmessage.mediaUrl"
                                                style="max-width:100%;height:150px;width:200px;object-fit:contain"
                                        ></video>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'audio' ">
                                        <!-- {{chatmessage}} -->
                                        <audio :src="chatmessage.receivermsg" @play="playAudio(chatmessage.defaultid)" :id="chatmessage.defaultid" preload="auto" type="audio/mpeg" controls>
                                        Your browser does not support the audio element.
                                        </audio>
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'selfiedeclientext'">
                                        {{chatmessage.msg == userData.id ? "You declined surprise selfie request" : "Sorry, your request for surprise selfie has been declined." }}
                                    </p>
                                    <p  v-if="chatmessage.msg && chatmessage.type.toLowerCase() != 'text' && chatmessage.type.toLowerCase() != 'selfiedeclientext' && chatmessage.type.toLowerCase() != 'link' && chatmessage.type.toLowerCase() != 'surpriseselfietext' && chatmessage.type.toLowerCase() != 'surpriseselfie'  && chatmessage.type.toLowerCase() != 'audio'">
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.msg}}</text-highlight>
                                    </p>
                                    <div class="message-nt">
                                        <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                        <span class="u_name" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                        <span class="u_time">{{chatmessage.Time}}</span>
                                        <!-- <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                        <span class="u_time" v-else>{{chatmessage.beforeTime}}</span> -->
                                    </div>
                                    </div>
                                    <div>
                                        <b-dropdown id="dropdown-offset" text="" class=""  v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch) && botUserEmail != chatObject.email"> 
                                        <div  v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                        </div>
                                        <b-dropdown-item href="#" v-if="chatmessage.type=='Text' || chatmessage.type=='Link' " @click="copymessage(chatmessage.receivermsg)">Copy Message</b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>

                                    <!-- Question -->
                                    <div v-if="chatmessage.type.toLowerCase() == 'textpoll' && chatmessage.messageType == 'receiver' && !checkExpirey(chatmessage.expireyTimeObjects)">
                                    <div class="message left poll" v-if="!chatmessage.answered">
                                        <div>
                                            <div class="poll-req">
                                                <h4>{{chatmessage.question.question}}</h4>
                                                <ul>
                                                    <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index" >
                                                    <a href="#" @click="selectOption(polldata.text,chatmessage.defaultid,chatmessage.chatId)">{{polldata.text}}</a>
                                                    </li>

                                                </ul>
                                            </div>
                                            <div class="message-nt">
                                            <span class="poll-expaire">
                                                <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                                <img class="poll-small-icon" src="@/assets/images/fd1.png" style="height:22px;width:22px">
                                                <p>
                                                <span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                            </span>
                                            <span class="u_time">{{chatmessage.Time}}</span>
                                            <!-- <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                            <span class="u_time" v-else>{{chatmessage.beforeTime}}</span> -->
                                            </div>
                                            <span class="u_time" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                        </div>
                                        <div>
                                            <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                            </b-dropdown>                            
                                        </div>
                                    </div>
                                    <!-- Answer -->
                                    <div class="message left poll-img poll-progress consume" v-else>
                                        <div>
                                        <div class="poll-img-popup">
                                                <h4>{{chatmessage.question.question}}</h4>
                                                <ul>
                                                <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index">
                                                    <b-progress :value="polldata.count*100/chatmessage.question.totalCount" :max="max" class="mb-1"></b-progress>
                                                    <h5>{{Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2))}}% {{polldata.text}}</h5>
                                                </li>
                                                </ul>
                                        </div>
                                        <div class="message-nt">
                                            <span class="poll-expaire">
                                            <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                            <img class="poll-small-icon" src="@/assets/images/fd1.png" style="height:22px;width:22px">
                                            <p>
                                                <span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                            </span>
                                            <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                            <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                        </div>
                                            <span class="u_time" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                        </div>
                                        <div>
                                            <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                            </b-dropdown>                              
                                        </div>
                                    </div>
                                    </div>
                                <!-- </div> -->

                                <div class="message left poll poll-result2" v-if="chatmessage.type.toLowerCase() == 'textpoll' && chatmessage.messageType == 'receiver' && checkExpirey(chatmessage.expireyTimeObjects)">
                                    <div>
                                    <div class="poll-req">
                                            <h4>Poll Result</h4>
                                            <h5>{{chatmessage.question.question}}</h5>
                                            <ul>
                                            <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index" :class="{active : chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text}" >
                                                <a href="#"> 
                                                <img src="@/assets/images/poll-win.png" class="pollwinicon" v-if="chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text">
                                                    {{polldata.text}} ({{chatmessage.question.totalCount>0?Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2)):0}}%)
                                                </a>
                                            </li>
                                            </ul>
                                    </div>
                                    <div class="message-nt">
                                        <span class="poll-expaire">
                                            <p>
                                            <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                            <span>Total Votes</span>:<span>{{chatmessage.question.totalCount}}</span>
                                            </p>
                                        </span>
                                        <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                        <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                        </div>
                                        <span class="u_time" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                    </div>
                                    <div>
                                        <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                        </b-dropdown>                          
                                    </div>
                                    </div>

                                <!-- FOR RECEIVERIMAGES -->
                                <div v-if="chatmessage.type.toLowerCase() == 'imagepoll' && chatmessage.messageType == 'receiver' && !checkExpirey(chatmessage.expireyTimeObjects)">
                                    <div class="message left poll-img" v-if="!chatmessage.answered">
                                    <div>
                                    <div class="poll-img-popup">
                                            <h4>{{chatmessage.question.question}}</h4>
                                            <ul>
                                            <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index">
                                                <a class="lightbox" :href="'#'+chatmessage.defaultid+index">
                                                <img :src="polldata.imageUrl">
                                                <img src="@/assets/images/open-eye.png" style="height:auto" class="eye">
                                                </a>
                                                <div class="lightbox-target" :id="chatmessage.defaultid+index">
                                                <div class="lg_wrapper">
                                                    <img :src="polldata.imageUrl"/>
                                                    <a class="lightbox-close" href="#"></a>
                                                </div>
                                                </div>
                                                <div class="radio">
                                                    <input :id="'radio-'+index" name="radio" type="radio" :value="polldata.text" @change="selectOption(polldata.text,chatmessage.defaultid,chatmessage.chatId)" >
                                                    <label :for="'radio-'+index" class="radio-label">{{polldata.text}}</label>
                                                </div>
                                            </li>
                                            </ul>
                                    </div>
                                    <div class="message-nt">
                                        <span class="poll-expaire">
                                        <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                        <img class="poll-small-icon" src="@/assets/images/fd1.png" style="height:22px;width:22px">
                                            <p><span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                        </span>
                                        <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                        <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                    </div>
                                        <span class="u_time" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                    </div>
                                    <div>
                                        <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                    </div>
                                    <div class="message left poll-img most_like" v-else>
                                    <div>
                                        <div class="poll-img-popup">
                                            <h4>{{chatmessage.question.question}}</h4>
                                            <ul>
                                            <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index">
                                                <a class="lightbox" :href="'#'+chatmessage.defaultid+index">
                                                <img :src="polldata.imageUrl">
                                                <img src="@/assets/images/open-eye.png" style="height:auto" class="eye">
                                                </a>
                                                <div class="lightbox-target" :id="chatmessage.defaultid+index">
                                                    <div class="lg_wrapper">
                                                    <img :src="polldata.imageUrl"/>
                                                    <a class="lightbox-close" href="#"></a>
                                                    </div>
                                                </div>
                                                <h5 class="mt-1">{{polldata.text}}</h5>
                                                <b-progress :value="polldata.count*100/chatmessage.question.totalCount" :max="max" class="mb-1"></b-progress>
                                                    <h5>{{Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2))}}% </h5>
                                                </li>
                                            </ul>
                                            <div class="message-nt">
                                            <span class="poll-expaire">
                                            <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                            <img class="poll-small-icon" src="@/assets/images/fd1.png" style="height:22px;width:22px">
                                                <p>
                                                <span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                            </span>
                                            <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                            <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                            </div>
                                        <span class="u_time" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                        </div>
                                    </div>
                                        <div>
                                        <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                            </b-dropdown></div>
                                    </div>
                                </div>
                                <div class="message left poll-img most_like poll-result" v-if="chatmessage.type.toLowerCase() == 'imagepoll' && chatmessage.messageType == 'receiver' && checkExpirey(chatmessage.expireyTimeObjects)">
                                    <div>
                                    <div class="poll-img-popup">
                                            <h4>Poll Result</h4>
                                            <h5>{{chatmessage.question.question}}</h5>
                                            <ul>
                                            <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index" :class="{active : chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text}">
                                                <a class="lightbox">
                                                <img :src="polldata.imageUrl">
                                                </a>
                                                <h5 class="mt-1">
                                                    <img src="@/assets/images/poll-win.png" class="pollwinicon" v-if="chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text">
                                                    {{polldata.text}} ({{chatmessage.question.totalCount>0?Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2)):0}}%)</h5>
                                            </li>
                                            </ul>
                                            <div class="message-nt">
                                            <span class="poll-expaire">
                                                <p>
                                                <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                                <span>Total Votes</span>:<span>{{chatmessage.question.totalCount}}</span></p>
                                            </span>
                                            <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                            <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                            </div>
                                        <span class="u_time" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                    </div>
                                    
                                    </div>
                                    <div>
                                            <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                </div>
                                <!-- FOR SENDERMESSAGES-->
                                <div class="message right" v-if="chatmessage.messageType == 'sender' && chatmessage.type != 'TextPoll'  && chatmessage.type!='ImagePoll' && chatmessage.type.toLowerCase() != 'left'">
                                    <div>
                                        <p ondragstart="return false" v-if="chatmessage.type.toLowerCase() == 'text' " >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.sendermsg}}</text-highlight>
                                        </p>
                                        <p v-if="chatmessage.type.toLowerCase() == 'link' " ><a :href="chatmessage.sendermsg" target="_blank">
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.sendermsg}}</text-highlight>
                                        </a></p>
                                        <p v-if="chatmessage.type.toLowerCase() == 'surpriseselfietext' " >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.sendermsg.trim()}}</text-highlight>

                                        <img src="@/assets/images/bussinessSelfieIconAndroid.png" style="height:28px; width:30px; object-fit: contain;" alt="">
                                        </p>
                                        <p @click="imageZoom(chatmessage.createdAt)"  v-if="chatmessage.type.toLowerCase() == 'image'" class="downloadIconWrapper">
                                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                            <img :src="chatmessage.mediaUrl" v-b-modal.imageZoom>
                                            <img v-if="chatmessage.type.toLowerCase() == 'surpriseselfie'" src="@/assets/images/bussinessSelfieIconAndroid.png" style="height:28px; width:30px; object-fit: contain;" alt="">
                                        </p>
                                        <p @click="imageZoom(chatmessage.createdAt)"  v-if="chatmessage.type.toLowerCase() == 'surpriseselfie' " class="downloadIconWrapper">
                                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.msg,chatmessage.name)"/>
                                            <img :src="chatmessage.msg" v-b-modal.imageZoom>
                                            <img v-if="chatmessage.type.toLowerCase() == 'surpriseselfie'" src="@/assets/images/bussinessSelfieIconAndroid.png" style="height:28px; width:30px; object-fit: contain;" alt="">
                                        </p>
                                        <p v-if="chatmessage.type.toLowerCase() == 'pdf' " >
                                        <a   class="downloadIconWrapper" >
                                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                            <img src="@/assets/images/PDF_file_icon.png" class="pdf_icon" />
                                        </a>
                                            <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>
                                        </p>
                                        <p v-if="chatmessage.type.toLowerCase() == 'excel' " >
                                        <a   class="downloadIconWrapper" >
                                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        <img src="@/assets/images/Exl-icon.png" class="pdf_icon" />
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'word' " >
                                        <a   class="downloadIconWrapper" >
                                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        <img src="@/assets/images/Word_Icon.png" class="pdf_icon"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'odt' ">
                                        <a   class="downloadIconWrapper" target="_blank">
                                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        <img src="@/assets/images/ODT.png" class="pdf_icon"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'ods' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        <img src="@/assets/images/ODS.png" class="pdf_icon"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'ppt' ">
                                        <a class="downloadIconWrapper" target="_blank">
                                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        <img src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'html' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        <img src="@/assets/images/images.png" class="pdf_icon"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'plain' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        <img src="@/assets/images/834209.png" class="pdf_icon"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'xd' ">
                                        <a  class="downloadIconWrapper" target="_blank">
                                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        <img src="@/assets/images/imagesxd.png" class="pdf_icon"/>
                                        </a>
                                        <label class="doc_name_label" style="display:block" >
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                        </label>
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'audio' ">
                                        <audio :src="chatmessage.sendermsg" @play="playAudio(chatmessage.defaultid)" :id="chatmessage.defaultid" preload="auto" type="audio/mpeg" controls>
                                        Your browser does not support the audio element.
                                        </audio>
                                    </p>
                                    <p v-if="chatmessage.type.toLowerCase() == 'video' ">
                                        <video controls="controls" 
                                                class="video-stream" 
                                                x-webkit-airplay="allow" 
                                                :src="chatmessage.mediaUrl"
                                                style="max-width:100%;height:150px;width:200px;object-fit:contain"
                                        ></video>
                                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                        </p>
                                        <p v-if="chatmessage.type.toLowerCase() == 'selfiedeclientext'">
                                        {{chatmessage.msg == userData.id ? "You declined surprise selfie request" : "Sorry, your request for surprise selfie has been declined." }}
                                        </p>
                                        <p  v-if="chatmessage.msg && chatmessage.type.toLowerCase() != 'text' && chatmessage.type.toLowerCase() != 'selfiedeclientext' && chatmessage.type.toLowerCase() != 'link' && chatmessage.type.toLowerCase() != 'surpriseselfietext' && chatmessage.type.toLowerCase() != 'surpriseselfie'  && chatmessage.type.toLowerCase() != 'audio'">
                                        <text-highlight  :queries="searchkeyword">{{chatmessage.msg}}</text-highlight>
                                        </p>
                                        <div class="message-nt msg-nt-left">
                                        <div class="time_sendmark">
                                        <span class="u_name" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                        <span class="u_time">{{chatmessage.Time}}</span>
                                        <!-- <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span> -->
                                        <!-- <span class="u_time" v-else>{{chatmessage.beforeTime}}</span> -->
                                        <span v-if="chatmessage.messageSeen.length === connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:12px;width:10px" /></span>
                                        <span v-else-if="!chatmessage.pending && chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/blue-send-arrow.png" style="height:12px;width:10px" /></span>
                                        <span v-else><img src="@/assets/images/ClockIcon.png" style="height:12px;width:10px" /></span>
                                        </div>
                                        </div>
                                    </div>
                                    <div>
                                    
                                        <b-dropdown id="dropdown-offset" text="" class=""  v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                        <div  v-if=" chatmessage.type.toLowerCase() != 'selfiedeclientext' ">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                        </div>
                                        <b-dropdown-item href="#" v-if="chatmessage.type=='Text' || chatmessage.type=='Link' " @click="copymessage(chatmessage.sendermsg)">Copy Message</b-dropdown-item>
                                        <b-dropdown-item href="#" v-b-toggle.sidebar-right @click="readrecipt(chatmessage)">Read Receipts</b-dropdown-item>
                                        <b-dropdown-item href="#"    @click="messageDelete(chatmessage)">Delete</b-dropdown-item>

                                        </b-dropdown>
                                    </div>
                                </div>

                                <div v-if="chatmessage.type.toLowerCase() == 'textpoll' && chatmessage.messageType == 'sender' && chatmessage.messageType == 'sender' && !checkExpirey(chatmessage.expireyTimeObjects)">
                                    <div class="message right poll" v-if="!chatmessage.answered">
                                        <div>
                                        <div class="poll-req">
                                                <h4>{{chatmessage.question.question}}</h4>
                                                <ul>
                                                <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index" >
                                                    <a href="#" @click="selectOption(polldata.text,chatmessage.defaultid,chatmessage.chatId)">{{polldata.text}}</a>
                                                </li>
                                                </ul>
                                        </div>
                                        <div class="message-nt">
                                            <span class="poll-expaire">
                                            <img class="poll-small-icon" src="@/assets/images/fd1.png" style="height:22px;width:22px">
                                            <p><span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                            </span>
                                            <div class="time_sendmark">
                                            <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                            <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                            <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/blue-send-arrow.png" style="height:15px;width:15px" /></span>
                                            <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:15px;width:15px" /></span>
                                            <span v-else><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                            </div>
                                        </div>
                                            <span class="u_time" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                        </div>
                                        <div>
                                            <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                            <b-dropdown-item href="#"  @click="messageDelete(chatmessage)">Delete</b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                    <div class="message right poll-img poll-progress consume" v-else>
                                    <div>
                                        <div class="poll-img-popup">
                                            <h4>{{chatmessage.question.question}}</h4>
                                            <ul>
                                                <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index">
                                                <b-progress :value="polldata.count*100/chatmessage.question.totalCount" :max="max" class="mb-1"></b-progress>
                                                    <h5>{{Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2))}}% {{polldata.text}}</h5>
                                                </li>
                                            
                                            </ul>
                                        </div>
                                        <div class="message-nt">
                                        <span class="poll-expaire">
                                            <img class="poll-small-icon" src="@/assets/images/fd1.png" style="height:22px;width:22px">
                                            <p><span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                        </span>
                                        <div class="time_sendmark">
                                        <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                        <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                        <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/blue-send-arrow.png" style="height:15px;width:15px" /></span>
                                        <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:15px;width:15px" /></span>
                                        <span v-else><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                        </div>
                                        </div>
                                        <span class="u_time" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                    </div>
                                        <div>
                                        <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                            <b-dropdown-item href="#"  @click="messageDelete(chatmessage)">Delete</b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="message right poll poll-result2" v-if="chatmessage.type.toLowerCase() == 'textpoll' && chatmessage.messageType == 'sender' && checkExpirey(chatmessage.expireyTimeObjects)">
                                    <div>
                                    <div class="poll-req">
                                            <h4>Poll Result</h4>
                                            <h5>{{chatmessage.question.question}}</h5>                                
                                            <ul>
                                            <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index" :class="{active : chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text}" >
                                                <a href="#">
                                                    <img src="@/assets/images/poll-win.png" class="pollwinicon" v-if="chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text">
                                                    {{polldata.text}} ({{chatmessage.question.totalCount>0?Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2)):0}}%)
                                                </a>
                                            </li>
                                            </ul>
                                    </div>
                                    <div class="message-nt">
                                        <span class="poll-expaire">
                                            <p><span>Total Votes</span>:<span>{{chatmessage.question.totalCount}}</span></p>
                                        </span>
                                        <div class="time_sendmark">
                                        <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                        <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                        <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/blue-send-arrow.png" style="height:15px;width:15px" /></span>
                                        <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:15px;width:15px" /></span>
                                        <span v-else><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                        </div>
                                        </div>
                                        <span class="u_time" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                    </div>
                                    <div>
                                            <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                            <b-dropdown-item href="#"  @click="messageDelete(chatmessage)">Delete</b-dropdown-item>
                                            </b-dropdown>
                                    </div>
                                </div>
                                    <!-- FOR SENDERIMAGES -->
                                <div v-if="chatmessage.type.toLowerCase() == 'imagepoll' && chatmessage.messageType == 'sender' && !checkExpirey(chatmessage.expireyTimeObjects)">
                                    <div class="message right poll-img" v-if="!chatmessage.answered">
                                    <div>
                                        <div class="poll-img-popup">
                                            <h4>{{chatmessage.question.question}}</h4>
                                            <ul>
                                            <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index">
                                                <a class="lightbox" :href="'#'+chatmessage.defaultid+index">
                                                <img :src="polldata.imageUrl">
                                                <img src="@/assets/images/open-eye.png" style="height:auto" class="eye">
                                                </a>
                                                <div class="lightbox-target" :id="chatmessage.defaultid+index">
                                                    <div class="lg_wrapper">
                                                    <img :src="polldata.imageUrl"/>
                                                    <a class="lightbox-close" href="#"></a>
                                                    </div>
                                                </div>
                                                <div class="radio">
                                                    <input :id="'radio-'+index" name="radio" type="radio" :value="polldata.text" @change="selectOption(polldata.text,chatmessage.defaultid,chatmessage.chatId)" >
                                                    <label :for="'radio-'+index" class="radio-label">{{polldata.text}}</label>
                                                </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="message-nt">
                                        <span class="poll-expaire">
                                            <img class="poll-small-icon" src="@/assets/images/fd1.png" style="height:22px;width:22px">
                                            <p><span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                        </span>
                                        
                                        <div class="time_sendmark">
                                        <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                        <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                        <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/blue-send-arrow.png" style="height:15px;width:15px" /></span>
                                        <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:15px;width:15px" /></span>
                                        <span v-else><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                        </div>
                                        </div>
                                        <span class="u_time" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                    </div>
                                        <div>
                                        <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                            <b-dropdown-item href="#"  @click="messageDelete(chatmessage)">Delete</b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                    <div class="message right poll-img most_like" v-else>
                                    <div>
                                        <div class="poll-img-popup">
                                            <h4>{{chatmessage.question.question}}</h4>
                                            <ul>
                                            <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index">
                                                <a class="lightbox" :href="'#'+chatmessage.defaultid+index">
                                                <img :src="polldata.imageUrl">
                                                <img src="@/assets/images/open-eye.png" style="height:auto" class="eye">
                                                </a>
                                                <div class="lightbox-target" :id="chatmessage.defaultid+index">
                                                    <div class="lg_wrapper">
                                                    <img :src="polldata.imageUrl"/>
                                                    <a class="lightbox-close" href="#"></a>
                                                    </div>
                                                </div>
                                                <h5 class="mt-1">{{polldata.text}}</h5>
                                                <b-progress :value="polldata.count*100/chatmessage.question.totalCount" :max="max" class="mb-1"></b-progress>
                                                    <h5>{{Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2))}}% </h5>
                                                </li>
                                            </ul>
                                            <div class="message-nt">
                                                <span class="poll-expaire">
                                                <img class="poll-small-icon" src="@/assets/images/fd1.png" style="height:22px;width:22px">
                                                <p><span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                                </span>
                                                <div class="time_sendmark">
                                                <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                                <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                                <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/blue-send-arrow.png" style="height:15px;width:15px" /></span>
                                                <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:15px;width:15px" /></span>
                                                <span v-else><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                                </div>
                                            </div>
                                        <span class="u_time" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                        </div>
                                        
                                    </div>
                                        <div>
                                        <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                            <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                            <b-dropdown-item href="#"  @click="messageDelete(chatmessage)">Delete</b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="message right poll-img most_like poll-result" v-if="chatmessage.type.toLowerCase() == 'imagepoll' && chatmessage.messageType == 'sender' && checkExpirey(chatmessage.expireyTimeObjects)">
                                    <div>
                                    <div class="poll-img-popup">
                                            <h4>Poll Result</h4>
                                            <h5>{{chatmessage.question.question}}</h5>
                                            <ul>
                                            <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index" :class="{active : chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text}">

                                                <a class="lightbox">
                                                <img :src="polldata.imageUrl">
                                                </a>
                                                <div>
                                                <h5 class="mt-1" > 
                                                    <img src="@/assets/images/poll-win.png" class="pollwinicon" v-if="chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text">

                                                {{polldata.text}} ({{chatmessage.question.totalCount>0?Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2)):0}}%)</h5>
                                                </div>
                                            </li>
                                            </ul>
                                            <div class="message-nt">
                                                <span class="poll-expaire">
                                                <p><span>Total Votes</span>:<span>{{chatmessage.question.totalCount}}</span></p>
                                                </span>
                                                <div class="time_sendmark">
                                                <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                                <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                                <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/blue-send-arrow.png" style="height:15px;width:15px" /></span>
                                                <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:15px;width:15px" /></span>
                                                <span v-else><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                                </div>
                                            </div>
                                        <span class="u_time" style="cursor:pointer;color:#77bef6;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)" >{{chatmessage.branch}}</span>
                                    </div>
                                    
                                    </div>
                                    <div>
                                        <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch)">
                                        <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                        <b-dropdown-item href="#"  @click="messageDelete(chatmessage)">Delete</b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div v-if="preview_list.length!=0">
                                <div class="message right" v-for="(val,index) in preview_list" :key="index">
                                <div>
                                    <p class="downloadIconWrapper">
                                        <img v-if="val.docType == 'Image'" :src="val.file">
                                        <video style="max-width:100%;height:150px;width:200px;object-fit:contain" v-else-if="val.docType=='Video'" :src="val.file"></video>
                                        <img v-else-if="val.docType=='pdf'" src="@/assets/images/PDF_file_icon.png">
                                        <img v-else-if="val.docType=='excel'" src="@/assets/images/Exl-icon.png">
                                        <img v-else-if="val.docType=='word'" src="@/assets/images/Word_Icon.png">
                                        <img v-else-if="val.docType=='odt'" src="@/assets/images/ODT.png">
                                        <img v-else-if="val.docType=='ods'" src="@/assets/images/ODS.png">
                                        <img v-else-if="val.docType=='ppt'" src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png">
                                        <img v-else-if="val.docType=='html'" src="@/assets/images/images.png">
                                        <img v-else-if="val.docType=='plain'" src="@/assets/images/834209.png">
                                        <img v-else-if="val.docType=='xd'" src="@/assets/images/imagesxd.png">
                                        <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
                                    </p>
                                </div>
                                </div>
                            </div>
                            <!-- show poll before send -->
                            <div v-if="showPoll">
                                <div class="message right poll-img">
                                <div>
                                    <div class="poll-img-popup">
                                    <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
                                        <h4>{{showPollData.question.question.question}}</h4>
                                        <ul>
                                            {{showPollData.images}}
                                            {{showPollData.imagetexts}}
                                        <li v-for="(polldata,index) in showPollData.question.images" v-bind:key="index">
                                            <a class="lightbox" :href="'#'+index">
                                            <img :src="polldata">
                                            <img src="@/assets/images/open-eye.png" style="height:auto" class="eye">
                                            </a>
                                            <div class="lightbox-target" :id="index">
                                                <div class="lg_wrapper">
                                                <img :src="polldata"/>
                                                <a class="lightbox-close" href="#"></a>
                                                </div>
                                            </div>
                                            <div class="radio">
                                                <input :id="'radio-'+index" name="radio" type="radio" disabled>
                                                <label :for="'radio-'+index" class="radio-label">{{showPollData.question.imagetexts[index]}}</label>
                                            </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <!-- show poll end -->

                            <!-- show message -->
                            <div class="message right" v-if="showMsgText">
                                    <div>
                                        <p>{{sendingMessage}}</p>
                                        <div class="message-nt msg-nt-left">
                                        <div class="time_sendmark"></div>
                                        </div>
                                    </div>
                            </div>
                            <!-- show message end -->

                            <!-- show Audio -->
                            <div class="message right" v-show="showRecordedMessage">
                                <div>
                                <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
                                <p>
                                    <audio id="adioPlay" controls></audio>
                                </p>
                                <div class="message-nt msg-nt-left">
                                    <div class="time_sendmark"></div>
                                    </div>
                                </div>
                            </div>
                            <!-- show Audio End -->
                            </div>
                            <!-- {{preview_list.length}} -->
                            <div v-if="preview_list.length!=0 && sendreceArray.length==0">
                                <div class="message right" v-for="(val,index) in preview_list" :key="index">
                                    <div>
                                        <p class="downloadIconWrapper">
                                            <img v-if="val.docType == 'Image'" :src="val.file">
                                            <video style="max-width:100%;height:150px;width:200px;object-fit:contain" v-else-if="val.docType=='Video'" :src="val.file"></video>
                                            <img v-else-if="val.docType=='pdf'" src="@/assets/images/PDF_file_icon.png">
                                            <img v-else-if="val.docType=='excel'" src="@/assets/images/Exl-icon.png">
                                            <img v-else-if="val.docType=='word'" src="@/assets/images/Word_Icon.png">
                                            <img v-else-if="val.docType=='odt'" src="@/assets/images/ODT.png">
                                            <img v-else-if="val.docType=='ods'" src="@/assets/images/ODS.png">
                                            <img v-else-if="val.docType=='ppt'" src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png">
                                            <img v-else-if="val.docType=='html'" src="@/assets/images/images.png">
                                            <img v-else-if="val.docType=='plain'" src="@/assets/images/834209.png">
                                            <img v-else-if="val.docType=='xd'" src="@/assets/images/imagesxd.png">
                                            <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- show poll before send -->
                            <div v-if="showPoll && showPollData.question.images.length!=0 && sendreceArray.length==0">
                                <div class="message right poll-img">
                                    <div>
                                        <div class="poll-img-popup">
                                            <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
                                            <h4>{{showPollData.question.question.question}}</h4>
                                            <ul>
                                                {{showPollData.images}}
                                                {{showPollData.imagetexts}}
                                                <li v-for="(polldata,index) in showPollData.question.images" v-bind:key="index">
                                                    <a class="lightbox" :href="'#'+index">
                                                        <img :src="polldata">
                                                        <img src="@/assets/images/open-eye.png" style="height:auto" class="eye">
                                                    </a>
                                                    <div class="lightbox-target" :id="index">
                                                        <div class="lg_wrapper">
                                                            <img :src="polldata"/>
                                                            <a class="lightbox-close" href="#"></a>
                                                        </div>
                                                    </div>
                                                    <div class="radio">
                                                        <input :id="'radio-'+index" name="radio" type="radio" disabled>
                                                        <label :for="'radio-'+index" class="radio-label">{{showPollData.question.imagetexts[index]}}</label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- show poll end -->
                            <!-- show message -->
                            <div class="message right" v-if="showMsgText && sendreceArray.length==0">
                                <div>
                                    <p>{{sendingMessage}}</p>
                                        <div class="message-nt msg-nt-left">
                                            <div class="time_sendmark">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- show message end -->
                            <!-- show Audio -->
                            <div class="message right" v-show="showRecordedMessage && sendreceArray.length==0">
                                <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
                                <p>
                                    <audio id="adioPlay" controls></audio>
                                </p>
                                <div class="message-nt"></div>
                            </div>
                            <!-- show Audio End -->
                            <div class="message-footer" :class="{'hideFooterForDooeyAdmin': botUserEmail == chatObject.email}">
                                <form>
                                    <div class="form-footer-type">
                                        <div class="form-group addtextarea" v-show="!startRecording">
                                            <textarea @paste="renderFiles" class="form-control" placeholder="Type here" maxlength="5000" :disabled="botUserEmail == chatObject.email"  @keyup="checkChrecterLengthMessage(msgTextTmp)" @keydown="listenForEnter" v-model="msgTextTmp" ></textarea>
                                            <span v-if="charecterErrorMessageForMessage != ''" style="color:red;">{{charecterErrorMessageForMessage}}</span>
                                        </div>
                                        <div v-show="startRecording" style="display:flex" class="form-group addtextarea">
                                            <audio id="player" style="width: 100%;" controls></audio>
                                        </div>
                                        <button v-if="startRecording" class="btn stopRecordBtn" @click.prevent="cancelRecord()">Cancel</button>
                                        <!-- </b-dropdown>  -->
                                        <button :disabled="botUserEmail == chatObject.email" @click.prevent="openDropdown()" v-if="!startRecording" type class="dropbtn without-bg-btn"></button>
                                        <div id="myDropdown" class="dropdown-content">
                                            <a href="#" v-if="!activeGroup && !activeChannel">
                                                <img src="@/assets/images/bussinessSelfieIconAndroid.png" height="25px" width="25px" @click="surpriseSelfieSend()"  title="Surprice selfie">
                                            </a>
                                            <a href="#">
                                                <img src="@/assets/images/fd4.png" @click.prevent="record()" title="Record a message">
                                            </a>
                                            <a href="#">
                                                <div class="file_input_wrapper">
                                                    <label class="myFileLabel" for="my-file1" title="Add files" >
                                                        <input multiple type="file"  @input="previewListAddImage" title="Add files"  class="form-control-file" id="my-file1"/>
                                                    </label>
                                                </div>
                                            </a>
                                            <a href="#" v-b-modal.createpoll ><img src="@/assets/images/fd1.png" title = "Create poll"></a>
                                        </div>
                                        <button v-if="!surpriseSelfie" @click.prevent="!sendingMessageFlag?startRecording?stopRecord():sendMessage():'',!sendingMessageFlag?startRecording?'':checkEmpty():''" class="btn btn-blue send-btn" :class="{disabled : !checkEmpty()&&!startRecording }" type="submit">Send</button>
                                        <button v-else @click.prevent="!sendingMessageFlag?startRecording?stopRecord():sendMessage():'',!sendingMessageFlag?startRecording?'':checkEmpty():''" class="btn btn-blue send-btn surpriseselfiebutton" :class="{disabled : !checkEmpty()&&!startRecording}" type="submit"><img src="@/assets/images/bussinessSelfieIconAndroid.png" height="35px" width="35px"/></button>
                                        <a v-if="surpriseSelfie" @click.prevent="surpriseSelfie = false"><img src="@/assets/images/cancel-circle.png"/></a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Noactivechat v-if="selectedActiveMenu == 'activeList' && activeChat == false"></Noactivechat>
                    <Nocontact v-if="selectedActiveMenu == 'contact' && activeChat == false"></Nocontact>
                </div>
                <div class="message-screen dropzone_bg_custom" v-else>
                    <button @click="closeAllPreview()" class="close_preview_screen">X</button>
                    <div style="visibility:hidden; opacity:0; z-index:1;" id="dropzone">
                        <div id="textnode">Drop file to share.</div>
                    </div>
                    <div v-if="preview_list.length!=0 " class="preview_image_wrapper">
                        <img v-if="preview_list[previewIndex].docType == 'Image'" :src="preview_list[previewIndex].file" alt="">
                        <video v-else-if="preview_list[previewIndex].docType=='Video'" :src="preview_list[previewIndex].file"></video>
                        <img v-else-if="preview_list[previewIndex].docType=='pdf'" src="@/assets/images/PDF_file_icon.png" class="pdf_icon">
                        <img v-else-if="preview_list[previewIndex].docType=='excel'" src="@/assets/images/Exl-icon.png" class="pdf_icon">
                        <img v-else-if="preview_list[previewIndex].docType=='word'" src="@/assets/images/Word_Icon.png" class="pdf_icon">
                        <img v-else-if="preview_list[previewIndex].docType=='odt'" src="@/assets/images/ODT.png" class="pdf_icon">
                        <img v-else-if="preview_list[previewIndex].docType=='ods'" src="@/assets/images/ODS.png" class="pdf_icon">
                        <img v-else-if="preview_list[previewIndex].docType=='ppt'" src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon">
                        <img v-else-if="preview_list[previewIndex].docType=='html'" src="@/assets/images/images.png" class="pdf_icon">
                        <img v-else-if="preview_list[previewIndex].docType=='plain'" src="@/assets/images/834209.png" class="pdf_icon">
                        <img v-else-if="preview_list[previewIndex].docType=='xd'" src="@/assets/images/imagesxd.png" class="pdf_icon">
                    </div>
                    <div class="preview_img_text" v-if="image_list.length > 0">
                        <input type="text" class="form-control" placeholder="Enter Text" name="addText" v-model="image_list[previewIndex].text">
                    </div>
                    <div class ="dreg_drop_wrapper">
                        <ul class="dreg_drop_message">
                            <li class="downloadIconWrapper" v-for="(val,index) in preview_list" :key="index" @click="previewIndex=index">
                                <img v-if="val.docType == 'Image'" :src="val.file" height="150px" width="200px" style="object-fit:cover" >
                                <video class="drag_drop_video" v-else-if="val.docType=='Video'" :src="val.file"></video>
                                <img v-else-if="val.docType=='pdf'" src="@/assets/images/PDF_file_icon.png">
                                <img v-else-if="val.docType=='excel'" src="@/assets/images/Exl-icon.png">
                                <img v-else-if="val.docType=='word'" src="@/assets/images/Word_Icon.png">
                                <img v-else-if="val.docType=='odt'" src="@/assets/images/ODT.png">
                                <img v-else-if="val.docType=='ods'" src="@/assets/images/ODS.png">
                                <img v-else-if="val.docType=='ppt'" src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png">
                                <img v-else-if="val.docType=='html'" src="@/assets/images/images.png">
                                <img v-else-if="val.docType=='plain'" src="@/assets/images/834209.png">
                                <img v-else-if="val.docType=='xd'" src="@/assets/images/imagesxd.png">
                                <button @click="removeFromPreview(index)">X</button>
                            </li>
                            <li class="downloadIconWrapper">
                                <div class="file_input_wrapper">
                                    <label class="myFileLabel" for="my-file2" >
                                        <input multiple type="file"  @change="previewListAddImage"  class="form-control-file" id="my-file2"/>
                                    </label>
                                </div>
                            </li> 
                        </ul>
                        <button class="send_drop_msg btn btn-blue" @click="sendAllDropMedia2()">Send</button>
                    </div>
                </div>
                <!-- message screen third End -->
                <CreatePoll :groupDocId="chatObjectId" :mainBranchActive="mainBranchActive" :mainBranchActiveIndex="mainBranchActiveIndex" :subBranchActive="subBranchActive" :subBranchActiveIndex="subBranchActiveIndex" :subSubBranchActive="subSubBranchActive" :subSubBranchActiveIndex="subSubBranchActiveIndex" :connectedUsers="connectedUsers" :messageSeenIds="messageSeenIds" :currCollection="currCollection" @assignDocId="assignDocIdFunction"></CreatePoll>
            </div>
        <!-- </div> -->
        </section>
        <!--image Zoom Modal -->
        <b-modal id="imageZoom" centered hide-footer :no-close-on-backdrop="true" >
            <div class="add-member-content contact-modal chatImageZoom" v-for="(imageZoom,index) in sendreceArray" v-bind:key="index">
                <img  v-if="imageZoom.createdAt ==  imageTimeForZoom && imageZoom.messageType == 'sender'" :src="imageZoom.mediaUrl"/>
                <img  v-if="imageZoom.createdAt ==  imageTimeForZoom && imageZoom.messageType == 'receiver'" :src="imageZoom.mediaUrl"/>
            </div>
        </b-modal>
        <!--image Zoom Modal End -->
        <!-- Admin panel Design Start  -->
        <div class="Admin_Main_wrapper">
            <AdminSidebarMenu v-if="isAdmin == true "></AdminSidebarMenu>
        </div>   
        <CreateBranch :mainBranches="mainBranches" :groupDocId="chatObjectId" :mainBranchActive="mainBranchActive" :createBranchMessageId="createBranchMessageId" :mainBranchActiveIndex="mainBranchActiveIndex" :subBranchActive="subBranchActive" :subBranchActiveIndex="subBranchActiveIndex" :connectedUsers="connectedUsers"/>
    </div>
</template>
<script>
import Header from "@/components/businessComponent/businessHeader.vue";
import AdminHeader from "@/components/adminlayout/adminheader.vue";
import Noactivechat from "@/components/businessComponent/businessNoChat.vue";
import Nocontact from "@/components/businessComponent/businessNoContact.vue";
import ActiveChatList from "@/components/businessComponent/BusinessChatList.vue";
import CreatePoll from "@/components/businessComponent/allBusinessModel/createpoll.vue";
import AdminSidebarMenu from "@/components/adminlayout/adminSidebarMenu.vue";
import firebase from "../../views/firebaseInit"
import firebaseData from '../../utils/firebaseCollection.js';
import firebaseTimestamp from "firebase";
import {uploadImageFile} from '../../businessUtills/storageQuerry.js';
import Vue from 'vue'
import Swal from 'sweetalert2'
import CreateBranch from "../../components/businessComponent/allBusinessModel/createbranch.vue";
import VueChatScroll from 'vue-chat-scroll'
import {selectPollOption2,updatePollAnswer2,updateAnswerCollection2,getCompanies2} from '../../businessUtills/getQueeryActiveChatList.js'
import {getLoginUserData} from '@/utils/getQueeryActiveChatList.js';
const db = firebase.firestore()
Vue.use(VueChatScroll)

import { mapGetters,mapActions } from "vuex";
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'
import MicRecorder from 'mic-recorder-to-mp3'

// New instance
const recorder = new MicRecorder({
  bitRate: 128
});

import BusinessChatList from "@/components/businessComponent/BusinessChatList.vue"
import BusinessUser from '@/components/businessComponent/businessUser.vue'
import TextHighlight from 'vue-text-highlight';
 
Vue.component('text-highlight', TextHighlight);
export default {
    name: "businesschat",
    components: {
        Header,
        AdminHeader,
        Noactivechat,
        Nocontact,
        ActiveChatList,
        CreatePoll,
        AdminSidebarMenu,
        firebaseData,
        CreateBranch,
        BusinessChatList,
        BusinessUser,
        SyncLoader,
    },
    data() {
        return {
            chatSnapshotListener:null,
            snapShotListener:null,
            messageSeenIds:[],
            showLoader:false,
            startRecording:false,
            msgTextTmp:'',
            selectedActiveMenu: "adminChat",
            max: 100,
            isSpinner:false,
            loginUser :'',
            isAdmin:false,
            imageUrl:'',
            activeChat:false,
            activeGroup:false,
            userData:{},
            chatObject:{
                firstName:'',
                lastName:'',
                profileUrl:'',
                userid:''
            },
            groupData:{},
            sendingMessage:'',
            connectedUserArray:[],
            updateChat:{},
            from:{},
            isChatting:false,
            socialMessageid:'',
            senderArray : [],
            sendreceArray:[],
            senderchatId:'',
            months:['Jan','Feb','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            chatid:'',
            chatObjectId:'',
            groupMember:{},
            chattedId:'',
            lastVisible:0,
            preview: null,
            image: [],
            preview_list: [],
            image_list: [],
            getChatId:'',
            firstMessageId:'',
            imageTimeForZoom:'',
            bulkDataArray:[],
            BulkMessage:'',
            businessMenuActive:true,
            mainBranches:[],
            mainBranchActive:false,
            mainBranchActiveIndex:0,
            subBranchActive:false,
            subBranchActiveIndex:0,
            subSubBranchActive:false,
            subSubBranchActiveIndex:0,
            isAnswerScreen:false,
            uploadData:[],
            pollquestions:{},
            connectedUserLength:'',
            todayTime:{
                hrs:'',
                min:''
            },
            DateOFToday: new Date().toISOString().split('T')[0],
            splitDateObject:{
                date:'',
                month:"",
                year:''
            },
            mypollanswer:'',
            isHighlight:false,
            searchbaroption  :'',
            searchkeyword :'',
            copyMessage:false,
            previewSenderImage:'',
            fromArray:[],
            connectedUsers:[],
            showPoll:false,
            showPollData:'',
            searchedChat:[],
            sendreceArrayBKP:[],
            sendingMessageFlag:false,
            currCollection:'',
            createBranchMessageId:'',     
            unreadMessages:0,
            showMsgText:false,
            showRecordedMessage:false,
            loginCustomeId:'',
            companyuserlimit:{},
            isGroupMuted:false,
            fuid:'',
            activeChannel:false,
            dataUsage:0,  
            companyName:{},
            companiesArray:[],
            companyuserlimit:{},
            companyName:{},
            searchType:'All',
            currentPage:0,
            totalPages:0,
            branchPaginatedData:[],
            subBranchPaginatedData:[],
            subSubBranchPaginatedData:[],
            startIndex:0,
            endIndex:6,
            file_type_Array : [],
            new_files_type : [],
            send_Files_Type:[],
            dropFiles:false,
            previewIndex:0,
            messageHistory:'',
            previewImageSize : 0,
            showLoaders:false,
            chatkeyword : '',
            searchErrorMessage : '',
            starttime:'',
            stoptime:'',
            audiotime : '',
            audioActiveId:'',
            blockUserArray:[],
            isInArray:'',
            selfieUserData : {},
            surpriseSelfie:false,
            opendropdown:'',
            openSubdropdown:'',
            openSubSubdropdown:'',
            selectDropdown:false,
            selectSubDropdown:false,
            selectSubSubDropdown:false,
            openMainBranchActiveDropdown:'',
            openSubBranchActiveDropdown:'',
            openSubSubBranchActiveDropdown:'',
            selectMainBranchActiveDropdown:false,
            selectSubBranchActiveDropdown:false,
            selectSubSubBranchActiveDropdown:false,
            defaultId:'',
            charecterErrorMessageForMessage:'',
            usedSelfie:0,
            checkPollAnswer2Data:{},
            displayChat:true,
            botUserEmail:'',


            messageInterval:0,
            messageCount:0,
            lastMessageData:{}
        };
    },
    beforeDestroy(){
        this.detachEvents();
    },
    created(){
        var self = this;

        try {
            self.detachEvents();
            self.botUserEmail = Vue.prototype.$email;
            self.showLoader = true
            self.showLoaders = true
            self.$root.$emit('callchannell')
            self.$root.$on('changecompanydropdown',(val) => {
                Vue.prototype.$companyName = val
                self.companyName = val
            })
            self.$root.$on('changeCompany',() => {
                self.activeChannel = false;
                self.activeGroup = false;
                self.activeChat = false;
            })

            self.$root.$off('getActiveComapnyList');
            self.$root.$on('getActiveComapnyList',() => {
                self.init();
            })
            self.$root.$on('showPreviewPoll',(question,type,images,imagetexts)=>{
                self.showPoll=true
                self.showPollData={
                    question:question,
                    type:type,
                    images:images,
                    imagetexts:imagetexts
                }
            })
            // self.$root.$on-----('mainBranchActiveFalseBusiness',(val) => {
            //     self.mainBranchActive = false
            //     self.getChatArray('default',val)
            // })
            self.$root.$on('hidePreviewPoll',()=>{
                self.showPoll=false
            })
            getCompanies2(firebase.auth().currentUser.uid,res=>{
                var self = this;
                self.companies=res
                db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).get().then(doc => {
                    if(doc.data().currentSelectedCompany == ''){
                        Vue.prototype.$companyName = res[0]
                        self.companyName = res[0]
                    }
                    else if(doc.data().currentSelectedCompany == 'social' && doc.data().companies.length > 0){
                        Vue.prototype.$companyName = res[0]
                        self.companyName = res[0]
                    }
                    else if (doc.data().currentSelectedCompany == 'social' && doc.data().companies.length == 0){
                        self.$router.push('/chat')
                    }
                    else{
                        var companies = doc.data().companies
                        companies.forEach(data => {
                            if(data.id == doc.data().currentSelectedCompany){
                                Vue.prototype.$companyName = data
                                self.companyName = data
                            }
                        })
                    }
                })
    
                self.$root.$off("openBusinessChat");
    
                self.$root.$emit('getActiveComapnyList')
                self.$root.$emit('getCompanyAdmin',res[0])
                self.$root.$emit('callchannell')
            })
            var datesplit = self.DateOFToday.split('-')
            self.splitDateObject.year = datesplit[0]
            self.splitDateObject.month = datesplit[1]
            self.splitDateObject.date = datesplit[2]
            setInterval(()=>{
                var dt = new Date()
                self.todayTime.hrs= dt.getHours()<10?'0'+dt.getHours():dt.getHours()
                self.todayTime.min= dt.getMinutes()<10?'0'+dt.getMinutes():dt.getMinutes()
            },1000)
            self.mainBranchActive=false,
            self.mainBranchActiveIndex=0
            const db = firebase.firestore()
            self.$root.$on('showBusinessPreviewPoll',(question,type,images,imagetexts)=>{
                self.showPoll=true
                self.showPollData={
                    question:question,
                    type:type,
                    images:images,
                    imagetexts:imagetexts
                }
            })
            self.$root.$on('hideBusinessPreviewPoll',()=>{
                self.showPoll=false
            })
            self.$root.$on("hideViewDetail",(objectId, chatId)=>{
                self.activeChannel = false;
                self.activeGroup = false;
                self.activeChat = false;
            })
            self.$root.$on('activeChat-GroupFalse',()=>{
                self.activeChat=false
                self.activeGroup=false
                self.chatObjectId=''
                self.sendreceArray=[]
                self.sendreceArrayBKP=[];
            })
            // db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').onSnapshot(snapshot=>{
            //     snapshot.docChanges().forEach(change=>{
            //         if(change.type=='modified')
            //         {
            //             db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(change.doc.id).get().then((doc2)=>{
            //                 self.getChatArray('default',doc2.data().chatId)
            //             })
            //             .catch(error=>{
            //                 console.log(error)
            //             })
            //         }
            //     })
            // })
            // self.init();
            self.$root.$on('searchByTypeValue',(chatkeyword,searchByType)=>{
                self.searchType = self.searchbaroption
                self.searchkeyword = chatkeyword
                self.searchErrorMessage = '';
                self.searchedChat = self.sendreceArrayBKP
                self.sendreceArray = []
                self.searchedChat.forEach(data=>{   
                    if(searchByType=='Photos' && chatkeyword == '')
                    {
                        if(data.type.toLowerCase() == 'image')
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType=='Videos' && chatkeyword == '')
                    {
                        if(data.type.toLowerCase()=='video')
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType=='Links' && chatkeyword == '')
                    {
                        if(data.type.toLowerCase()=='link')
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType=='Documents' && chatkeyword == '')
                    {
                        if(data.type.toLowerCase()=='pdf' || data.type.toLowerCase()=='word' || data.type.toLowerCase()=='excel' || data.type.toLowerCase()=='ods'|| data.type.toLowerCase()=='odt' || data.type.toLowerCase()=='ppt' || data.type.toLowerCase()=='plain' || data.type.toLowerCase()=='html' || data.type.toLowerCase()=='xd')
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType=='Photos' && chatkeyword != '')
                    {
                        if(data.type.toLowerCase()=='image' && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                        {
                            self.sendreceArray.push(data) 
                        }
                    }
                    else if(searchByType=='Documents' && chatkeyword != '')
                    {
                        if(data.type.toLowerCase()=='pdf' || data.type.toLowerCase()=='word' || data.type.toLowerCase()=='excel' || data.type.toLowerCase()=='ods'|| data.type.toLowerCase()=='odt' || data.type.toLowerCase()=='ppt' || data.type.toLowerCase()=='plain' || data.type.toLowerCase()=='html' || data.type.toLowerCase()=='xd')
                        {
                            if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()) || data.name.toLowerCase().includes(chatkeyword.toLowerCase()))
                            {
                                self.sendreceArray.push(data)
                            }
                        }
                    }
                    else if(searchByType=='Videos' && chatkeyword != '')
                    {
                        if(data.type.toLowerCase()=='video' && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                            {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType=='Links' && chatkeyword != '')
                    {
                        if(data.type.toLowerCase()=='link' && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType == '' && chatkeyword != ''){
                        if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType == 'All' && chatkeyword != ''){
                        if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType == 'All' && chatkeyword == ''){
                        self.sendreceArray = self.sendreceArrayBKP
                    }
                    else if(searchByType == '' && chatkeyword == ''){
                        if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                        {
                            self.sendreceArray = self.sendreceArrayBKP
                        }
                    }
                })
                if(self.sendreceArray.length == 0){
                    self.searchErrorMessage = "No Result found"
                }else{
                    self.searchErrorMessage = '';
                }
            })
            self.$root.$on('onupdateSearchsBusiness',(chatkeyword,searchByType)=>{
                self.searchType = self.searchbaroption
                self.searchkeyword = chatkeyword
                self.searchErrorMessage = '';
                self.searchedChat = self.sendreceArrayBKP
                self.sendreceArray = []
                self.searchedChat.forEach(data=>{
                    if(searchByType=='Photos' && chatkeyword == '')
                    {
                        if(data.type.toLowerCase()=='image')
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType=='Videos' && chatkeyword == '')
                    {
                        if(data.type.toLowerCase()=='video')
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType=='Links' && chatkeyword == '')
                    {
                        if(data.type.toLowerCase()=='link')
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType=='Documents' && chatkeyword == '')
                    {
                        if(data.type.toLowerCase()=='pdf' || data.type.toLowerCase()=='word' || data.type.toLowerCase()=='excel' || data.type.toLowerCase()=='ods'|| data.type.toLowerCase()=='odt' || data.type.toLowerCase()=='ppt' || data.type.toLowerCase()=='plain' || data.type.toLowerCase()=='html' || data.type.toLowerCase()=='xd')
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType=='Photos' && chatkeyword != '')
                    {
                        if(data.type.toLowerCase()=='image' && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                        {
                            self.sendreceArray.push(data) 
                        }
                    }
                    else if(searchByType=='Documents' && chatkeyword != '')
                    {
                        if(data.type.toLowerCase()=='pdf' || data.type.toLowerCase()=='word' || data.type.toLowerCase()=='excel' || data.type.toLowerCase()=='ods'|| data.type.toLowerCase()=='odt' || data.type.toLowerCase()=='ppt' || data.type.toLowerCase()=='plain' || data.type.toLowerCase()=='html' || data.type.toLowerCase()=='xd')
                        {
                            if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()) ||  data.name.toLowerCase().includes(chatkeyword.toLowerCase()))
                            {
                                self.sendreceArray.push(data)
                            }
                        }
                    }
                    else if(searchByType=='Videos' && chatkeyword != '')
                    {
                        if(data.type.toLowerCase()=='video' && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType=='Links' && chatkeyword != '')
                    {
                        if(data.type.toLowerCase()=='link' && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType == 'All' && chatkeyword != ''){
                        if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType == '' && chatkeyword != ''){
                        if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                        {
                            self.sendreceArray.push(data)
                        }
                    }
                    else if(searchByType == 'All' && chatkeyword == ''){
                        self.sendreceArray = self.sendreceArrayBKP
                    }
                    else if(searchByType == '' && chatkeyword == ''){
                        if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                        {
                            self.sendreceArray = self.sendreceArrayBKP
                        }
                    }
                })
                if(self.sendreceArray.length == 0){
                    self.searchErrorMessage = "No Result found."
                }
                else{
                    self.searchErrorMessage = '';
                }
            })
            self.$root.$on('branchBusinessOpen',(value) => {
                self.openBranchFromMessage(value);
            })

            setTimeout(() => {
                self.showLoader = false
                self.showLoaders = false
            }, 5000)
        } catch (error) {
            console.log("ERROR: ", error);
        }
    }, 
    // watch: {
    //     $route: function () {
    //         this.init();
    //     }
    // },
    methods: {
        checkBranchExists(branchName) {
            var self = this;

            let found = false;

            if(!self.storeChatData.mainBranches.length) {
                return false;
            }

            self.storeChatData.mainBranches.forEach(mb=>{
                if(mb.name === branchName) {
                    found = true;
                } else {
                    mb.subBranches.forEach(sb =>{
                        if(sb.name === branchName) {
                            found = true;
                        } else {
                            sb.subSubBranches.forEach(ssb=>{
                                if(ssb.name === branchName) {
                                    found = true;
                                }
                            })
                        }
                    })
                }
            })

            return found;
        },
        detachEvents() {
            this.$root.$off('changecompanydropdown');
            this.$root.$off('changeCompany');
            this.$root.$off('getActiveComapnyList');
            this.$root.$off('showPreviewPoll');
            this.$root.$off('hidePreviewPoll');
            this.$root.$off('showBusinessPreviewPoll');
            this.$root.$off('hideBusinessPreviewPoll');
            this.$root.$off("hideViewDetail");
            this.$root.$off('activeChat-GroupFalse');
            this.$root.$off('searchByTypeValue');
            this.$root.$off('onupdateSearchsBusiness');
            this.$root.$off('branchBusinessOpen');
            this.$root.$off("openBusinessChat");
            this.$root.$off("activeGroup");
        },
        assignDocIdFunction(id){
            this.chatObjectId = id
            this.getChatArray('default',id);
            this.getSetBusinessChatData({companyId:Vue.prototype.$companyName.id,collection:'businessMessages',chatId:id});
        },
        ...mapActions({setCurrentCollection:'setCurrentCollection'}),
        ...mapActions({setStoreChatId:'setStoreChatId'}),
        ...mapActions({getSetBusinessChatData:'getSetBusinessChatData'}),
        // DISPLAY DROPDOWN FOR BRANCH AND COPYMESSAGE IN RECEIVER SIDE (Shraddha)
        checkTypeAndBranch(type,branch){
            if(type == 'Text'){
                return true;
            }else if(type == 'link'){
                return true;
            }else if(branch == '' && type.toLowerCase() != 'selfiedeclientext' ){
                return true;
            }else{
                return false;
            }
        },
        checkChrecterLengthMessage(groupName){
            var self = this;
            if(groupName.length == 5000){
                self.charecterErrorMessageForMessage = 'The message may not be greater than 5000 characters';
            }else{
                self.charecterErrorMessageForMessage = '';
            }
        },
        // OUTSIDE DIV EVENT CLICK FOR CLOSE TOOLTIP(Shraddha)
        insideDivClick(data,index){
            window.addEventListener('click', function(e){ 
                if (document.getElementById('clickbox'+index) != undefined){
                    if (document.getElementById('clickbox'+index).contains(e.target)){
                    }else{
                        data.flag = false
                    }
                }
            });
        },
        insideDivClickActiveBranch(index){
            var self = this
            window.addEventListener('click', function(e){
                if (document.getElementById("mainBranchDropdown") != undefined){    
                    if (document.getElementById("mainBranchDropdown").contains(e.target)){
                    } else{
                        self.selectDropdown  = false 
                    }
                }
            });
        },
        insideDivClickActiveBranch1(index){
            var self = this
            window.addEventListener('click', function(e){ 
                if (document.getElementById("subBranchDropdown") != undefined){  
                    if (document.getElementById("subBranchDropdown").contains(e.target)){
                    }else{
                        self.selectSubDropdown = false
                    }
                }
            });
        },
        insideDivClickActiveBranch2(index){
            var self = this
            window.addEventListener('click', function(e){
                if (document.getElementById("subSubBranchDropdown") != undefined){   
                    if (document.getElementById("subSubBranchDropdown").contains(e.target)){
                    } else{
                        self.selectSubSubDropdown = false
                    }
                }
            });
        },
        //OPEN DROP DOWN IN BRANCH (Shraddha)
        selectedMainBranchDropdown(data){
            var self = this;
            if(data == 'Delete'){
                self.handleDeleteBranch(self.mainBranches, self.mainBranches[self.mainBranchActiveIndex].name, 'main');
            }
            else if(data == 'Mute'){
                self.muteUnmuteChat('mainBranch',self.mainBranchActiveIndex,'mute');
            }
            else{
                self.muteUnmuteChat('mainBranch',self.mainBranchActiveIndex,'unmute');
            }
        },
        selectedSubBranchDropdown(data){
            var self = this;
            if(data == 'Delete'){
                self.handleDeleteBranch(self.mainBranches, self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name, 'sub');
            }
            else if(data == 'Mute'){
                self.muteUnmuteChat('subBranch',self.subBranchActiveIndex,'mute');
            }
            else{
                self.muteUnmuteChat('subBranch',self.subBranchActiveIndex,'unmute');
            }
        },
        selectedSubSubBranchDropdown(data){
            var self = this;
            if(data == 'Delete'){
                self.handleDeleteBranch(self.mainBranches, self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name, 'innerSub');
            }
            else if(data == 'Mute'){
                self.muteUnmuteChat('subSubBranch',self.subSubBranchActiveIndex,'mute');
            }
            else{
                self.muteUnmuteChat('subSubBranch',self.subSubBranchActiveIndex,'unmute');
            }
        },
        selectedMainBranchActiveDropdown(mainBranches,name,msgid,index,data){
            var self = this;
            if(data == 'Delete'){
                self.handleDeleteBranch(mainBranches, name, 'main');
            }else if(data == 'Mute'){
                self.muteUnmuteChat('mainBranch',index,'mute');
            }else{
                self.muteUnmuteChat('mainBranch',index,'unmute');
            }
        },
        selectedSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,name,msgid,index,data){
            var self = this;
            if(data == 'Delete'){
                self.handleDeleteBranch(mainBranches, name, 'sub');
            }else if(data == 'Mute'){
                self.muteUnmuteChat('subBranch',index,'mute');
            }else{
                self.muteUnmuteChat('subBranch',index,'unmute');
            }
        },
        selectedSubSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,subBranchActiveIndex,name,msgid,index,data){
            var self = this;
            if(data == 'Delete'){
                self.handleDeleteBranch(mainBranches, name, 'innerSub');
            }else if(data == 'Mute'){
                self.muteUnmuteChat('subSubBranch',index,'mute');
            }else{
                self.muteUnmuteChat('subSubBranch',index,'unmute');
            }
        },
        openDropdownBranch(){
            var self = this;
            if(self.selectDropdown == false){
                self.selectDropdown = true
            }else{
                self.selectDropdown = false
            }
        },
        openSubDropdownBranch(){
            var self = this;
            if(self.selectSubDropdown == false){
                self.selectSubDropdown = true
            }else{
                self.selectSubDropdown = false
            }
        },
        openSubSubDropdownBranch(){
            var self = this;
            if(self.selectSubSubDropdown == false){
                self.selectSubSubDropdown = true
            }else{
                self.selectSubSubDropdown = false
            }
        },
        openActiveDropdownBranch(data){
            if(data.flag == false){
                data.flag = true
            }else{
                data.flag = false
            }
        },
        openActiveSubDropdownBranch(data){
            if(data.flag == false){
                data.flag = true
            }else{
                data.flag = false
            }
        },
        openActiveSubSubDropdownBranch(data){
            if(data.flag == false){
                data.flag = true
            }else{
                data.flag = false
            }
        },
        // CHECK IF SURPRISE SELFIE SEND OR NOT (Shraddha)
        surpriseSelfieSend(){
            var self = this;
            var txt = "";
            var splitName = '';
            self.selfieUserData.selfieUsers.forEach(data => {
                for (let x in data) {
                    if(x == self.chatObjectId){
                        txt = x;
                        splitName = data[x]
                    }
                }
            })
            if(txt == ''){
                var databaseTime = new Date(self.selfieUserData.selfieTime.seconds * 1000)
                var tsYesterday =  new Date(databaseTime.setHours(databaseTime.getHours() + 6));
                if(tsYesterday <= new Date()){
                    self.surpriseSelfie = true
                }else{
                    if(self.selfieUserData.usedSelfie < self.selfieUserData.noOfSelfie){
                        self.surpriseSelfie = true
                    }else{
                        self.surpriseSelfie = false
                        self.$root.$emit("audioplay");
                            self.$notify({
                            title:"Error",
                            text:'Selfie limit has been over.',
                            type:'error'
                        })
                    }
                }
            }else{
                self.surpriseSelfie = false
                self.$root.$emit("audioplay");
                self.$notify({
                    title:"Error",
                    text:`Request already sent to ${splitName} `,
                    type:'error'
                })
            }
        },
        // EVENT FIRE WHEN AUDIO IS PLAY(Shraddha)
        playAudio(msgid){
            var self = this;
            if(self.audioActiveId == ''){
                self.audioActiveId = msgid;
            }else{
                var activeId = document.getElementById(self.audioActiveId);
                activeId.pause();
                self.audioActiveId = msgid
            }
        },
        // CLOSE DROP DOWN(Shraddha)
        closeDropDown(){
            var self = this;
            self.$root.$emit("closeSidebar");
        },
        renderFiles(e)
        {
            var self = this
            var clipboardItems = e.clipboardData.items;
            var files = [];
            clipboardItems.forEach((data,index)=>{
                files.push(data.getAsFile())
                if(files[index]==null)
                {
                    files.splice(files[index],1)
                }
            })
            if(files.length!=0)
            {
                self.uploadFiles(files,'e')
            }
        }, 
        showStartDots()
        {
            var self = this
            var show = false
            if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
            {
                show = 6<=self.startIndex
            }
            if(self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
            {
                show = 6<=self.startIndex
            }
            if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
            {
                show = 6<=self.startIndex
            }
            return show
        },
        showEndDots()
        {
            var self = this
            var show = false
            if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
            {
                show = self.mainBranches.length>self.endIndex
            }
            if(self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
            {
                show = self.mainBranches[self.mainBranchActiveIndex].subBranches.length>self.endIndex
            }
            if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
            {
                show = self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches.length>self.endIndex
            }
            return show
        },
        pagination(branches)
        {
            var self = this
            var index = 1
            var dataArray=[]
            var paginatedData=[]
            if(branches.length<6)
            {
                self.totalPages=0
            }
            if(branches.length!=0)
            {
                branches.forEach(data=>{
                    dataArray.push(data)
                    if(index % 6 == 0)
                    {
                        paginatedData.push(dataArray)
                        dataArray=[];
                    }
                    else if(index == branches.length)
                    {
                        paginatedData.push(dataArray)
                        dataArray=[];
                        self.totalPages=paginatedData.length
                    }
                    index++;
                })
            }
            return paginatedData
        },
        changePage(direction){
            var self = this
            if(direction =='prev')
            {
                if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                {
                    if(0<self.startIndex+6)
                    {
                        self.startIndex-=6
                        self.endIndex-=6
                    }
                }
                if(self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                {
                    if(0<self.startIndex+6)
                    {
                        self.startIndex-=6
                        self.endIndex-=6
                    }
                }
                if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
                {
                    if(0<self.startIndex+6)
                    {
                        self.startIndex-=6
                        self.endIndex-=6
                    }
                }
            }
            if(direction =='next')
            {
                if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                {
                    if(self.mainBranches.length>self.endIndex)
                    {
                        self.startIndex+=6
                        self.endIndex+=6
                    }
                }
                if(self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                {
                    if(self.mainBranches[self.mainBranchActiveIndex].subBranches.length>self.endIndex)
                    {
                        self.startIndex+=6
                        self.endIndex+=6
                    }
                }
                if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
                {
                    if(self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches.length>self.endIndex)
                    {
                        self.startIndex+=6
                        self.endIndex+=6
                    }
                }
            }
        },
        checkMessagesSeen()
        {
            this.unreadMessages=0
        },
        checkActiveTab(){
            if(Object.keys(this.checkPollAnswer2Data).length > 0){
                this.checkPollAnswer2(this.checkPollAnswer2Data);
            }
        },
        //Moved from social to business Shiv Joshi
        checkEmpty()
        {
            if(this.sendingMessage=='' && this.msgTextTmp=='')
            {
                return false;
            }
            else{
                return true;
            }
        },
        record()
        {
            this.getDetectRTCData().then((res) => {
                if(res == true){
                    this.starttime = new Date();
                    recorder.start().then((val) => {
                        this.startRecording=true
                        this.handleSuccess(val)
                    }).catch((e) => {
                        console.error(e);
                    });
                }
                else{
                    this.$root.$emit("audioplay");
                    this.$notify({
                        title:"Error",
                        text:'Microphone is not connected.',
                        type:'error'
                    })
                }
            });
        },
        getDetectRTCData(){
            var DetectRTC = require('detectrtc')
            return new Promise((resolve,reject) => {
                DetectRTC.load(function() {
                    resolve(DetectRTC.hasMicrophone)
                })
            })
        },
        stopRecord()
        {
            var self = this
            const playerAudio = document.getElementById('adioPlay');
            recorder.stop().getMp3().then(([buffer, blob]) => {
            this.stoptime = new Date()
            self.startRecording=false
            // do what ever you want with buffer and blob
            // Example: Create a mp3 file and play
            const file = new File(buffer, 'me-at-thevoice.mp3', {
                type: blob.type,
                lastModified: Date.now()
            });
            playerAudio.src = URL.createObjectURL(file)
            self.sendAudio2(URL.createObjectURL(file),file)
            }).catch((e) => {
                alert('We could not retrieve your message');
            });
        },
        cancelRecord()
        {
            recorder.stop().getMp3().then(()=>{
                this.startRecording=false
            })
            .catch(error=>{
            })
        },
        handleSuccess(stream)
        {
            const player = document.getElementById('player');
            if ("srcObject" in player) {
                player.srcObject = stream;
            }
            else {
                player.src = window.URL
                .createObjectURL(stream);
            }
            // It will play the player
            player.onloadedmetadata = function (ev) {
                player.play();
            };
        },
        timeDifference(date1, date2) {
            const today = date1
            const endDate = date2;
            const days = parseInt((endDate - today) / (1000 * 60 * 60 * 24));
            const hours = parseInt(Math.abs(endDate - today) / (1000 * 60 * 60) % 24);
            const minutes = parseInt(Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60) % 60);
            const seconds = parseInt(Math.abs(endDate.getTime() - today.getTime()) / (1000) % 60); 
            this.audiotime = this.addZero(minutes)+':'+this.addZero(seconds)+':00';
        },
        sendAudio2(audLink, recChunks){
            var self = this;
            self.timeDifference(self.starttime,self.stoptime)
            self.image_list = []
            let docType = ''
            let input = event.target;
            let found = false
            let count = recChunks.length;
            let index = 0;
            if(self.sendreceArray.length!=0) {
                self.image_list.push(recChunks);
                self.showRecordedMessage=true;
                let from = {
                    avtar:self.userData.profileUrl,
                    id:self.userData.id,
                    name:self.userData.firstName,
                    path: self.currCollection!=="default" ? self.currCollection : "",
                };
                let fromArray = [];
                let datausage = 0;
                docType="Audio";
                const imgsLength = self.image_list.length;

                if(self.sendreceArray.length!=0)
                {
                    let container = document.getElementById('scrollParent')
                    container.scrollTop = container.scrollHeight;
                }
                uploadImageFile(firebaseData.firebasecollection.PROFILEIMAGES,'recordedMessage.mp3',self.image_list[0].size,self.image_list[0],docType,'',res=>{
                    self.image.push(res);
                    if(self.image.length == imgsLength) {
                        let count = 0;
                        const getrequestDataFunction = (row) => {
                            if(count >=  self.image.length){
                                self.showRecordedMessage=false;
                                self.isInArray = '';
                                self.image_list=[]
                                self.image = []
                                self.preview_list=[]
                                return;
                            }
                            const key = row.imageUrl;
                            const imageName = row.imageName;
                            const docType = row.docType;
                            const imageSize = row.imageSize

                            db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc => {
                                var users = doc.data().users
                                users.forEach(data => {
                                if(data.id == firebase.auth().currentUser.uid){
                                    data.dataUsage = data.dataUsage + Number(imageSize)
                                }
                                })
                                db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                                users : users
                                })
                            })

                            if(self.userData.SocialreadReceipts || self.activeGroup)
                            {
                                fromArray.push(from);
                            }

                            if(self.chatObjectId != undefined && self.chatObjectId != '') {
                                self.addMessageAndCallAPI({
                                createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                from: from,
                                type: docType,
                                name: imageName,
                                msg: key,
                                messageSeen: fromArray,
                                audioTime: self.audiotime,
                                },self.chatObjectId,self.currCollection);
                                count += 1;
                                getrequestDataFunction(self.image[count]);
                            } else {
                                self.currCollection = "default";
                                self.connectedUserArray.push(self.userid)
                                self.connectedUserArray.push(self.chatObject.id)
                                let messageSeenIds=[]
                                messageSeenIds.push({id:firebase.auth().currentUser.uid,count:0})
                                messageSeenIds.push({id:self.chatObject.id,count:0})

                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).add({
                                    connectedUser : self.connectedUserArray,
                                    id:'',
                                    isGroup : self.activeGroup,
                                    lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                    title:'',
                                    updateChat:self.updateChat,
                                    msgFromBranch:false,
                                    muteArray:[],
                                    mainBranches:[],
                                    updateBranchData:{},
                                    SurpriseSelfie : {},
                                    ToggleSelfie : [],
                                    messageSeenIds:messageSeenIds,
                                    blockBy : self.isInArray || ''
                                }).then((doc) => {
                                    self.chatObjectId = doc.id;
                                    self.getSetBusinessChatData({companyId:Vue.prototype.$companyName.id,collection:'businessMessages',chatId:doc.id});
                                    db.collection(firebaseData.firebasecollection.USER).doc(self.userid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.chatObjectId).update({
                                        chatId:doc.id
                                    })
                                    db.collection(firebaseData.firebasecollection.USER).doc(self.chatObjectId).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.userid).update({
                                        chatId:doc.id
                                    })
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(doc.id).update({
                                        id : doc.id
                                    })
                                    self.addMessageAndCallAPI({
                                        createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        from: from,
                                        type: docType,
                                        name: imageName,
                                        msg: key,
                                        messageSeen: fromArray,
                                        audioTime: self.audiotime,
                                    },self.chatObjectId,self.currCollection);
                                    setTimeout(()=>{
                                        self.getChatArray('default', docId)
                                    },1000)
                                    count += 1;
                                    getrequestDataFunction(self.image[count]);
                                })
                                .catch(error=>{
                                    console.error(error);
                                })
                            }
                        }
                        getrequestDataFunction(self.image[count]);
                    }
                });
            } else {
                this.$root.$emit("audioplay");
                this.$notify({
                title:"Error",
                text:"Audio is not supported.",
                type: "error"
                });
                return false;
            }
        },
        openBranchFromMessage(branchName)
        {
            var self = this
            for (let i = 0; i < self.mainBranches.length; i++) {
                if(self.mainBranches[i].name==branchName)
                {
                    self.mainBranchActive=true
                    self.mainBranchActiveIndex=i
                    self.subBranchActive=false
                    self.subBranchActiveIndex=0
                    self.subSubBranchActive=false
                    self.subSubBranchActiveIndex=0
                }
                else
                {
                    for (let j = 0; j < self.mainBranches[i].subBranches.length; j++) {
                        if(self.mainBranches[i].subBranches[j].name==branchName)
                        {
                            self.subBranchActive=true
                            self.subBranchActiveIndex=j
                        }
                        else
                        {
                            for (let k = 0; k < self.mainBranches[i].subBranches[j].subSubBranches.length; k++) {
                                if(self.mainBranches[i].subBranches[j].subSubBranches[k].name==branchName)
                                {
                                    self.subSubBranchActive=true
                                    self.subSubBranchActiveIndex=k
                                }
                            }
                        }
                    }
                }
            }
            self.getChatArray(branchName,self.chatObjectId)
        },
        getMessageCount(messageSeenIdsArray)
        {
            var uid = firebase.auth().currentUser.uid
            var count = 0
            messageSeenIdsArray.forEach(data=>{
                if(data.id==uid)
                {
                    count = data.count
                }
            });
            return count
        },
        sortArray(mainBranchArray)
        {
            var tmp = mainBranchArray||[]
            var tmp2 = ''
            if(tmp.length!=0)
            {
                for (let index = 0; index < tmp.length; index++) {
                    for (let j = index+1; j < tmp.length; j++) {
                        if(tmp[index].lastMsgTime.seconds < tmp[j].lastMsgTime.seconds)
                        {
                            tmp2 = tmp[index]
                            tmp[index] = tmp[j]
                            tmp[j]=tmp2
                        }
                    }
                }
            }
            return tmp
        },
        // selectCompanyName(val)
        // {
        //     this.companyName=val
        //     localStorage.setItem('SelectedCompany',JSON.stringify(val))
        //     Vue.prototype.$companyName = val
        //     this.init();
        //     this.activeChat = false;
        //     this.activeGroup = false
        //     this.$root.$emit('changeHeader',val)
        //     this.$root.$emit('getActiveComapnyList')
        //     this.$root.$emit('getUserList')
        //     this.$root.$emit('hideViewDetails','','');
        //     this.$root.$emit('getCompanyAdmin',val)
        //     this.$root.$emit('callchannell')
        //     db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).update({
        //         currentSelectedCompany : val.id
        //     })
        // },
        callChannel(){
            var self = this;
            self.$root.$emit('callchannell')
        },
        listenForEnter(e) {
            if(!e.shiftKey && e.key=='Enter')
            {
                if(!this.sendingMessageFlag && this.msgTextTmp!='')
                {
                    this.sendingMessageFlag=true
                    this.sendMessage()
                }
                if(this.sendingMessage=='' && this.msgTextTmp=='')
                {
                    this.checkEmpty()
                }
                e.preventDefault()
            }
        },
        checkMuted(userIdArray)
        {
            var muted = false
            userIdArray.forEach(data=>{
                if(data==firebase.auth().currentUser.uid) {
                    muted = true
                }
            })
            if(muted) {
                return true
            } else {
                return false
            }
        },
        checkBranchMuted(path,mainBranches)
        {
            var self = this
            var muted = false
            var splitPath = path
            var mainBranchesData = mainBranches
            if(splitPath.length==2)
            {
                mainBranchesData.forEach(data=>{
                    if(data.name == splitPath)
                    {
                        muted = self.checkMuted(data.muteArray)
                    }
                    else
                    {
                        data.subBranches.forEach(data2=>{
                            if(data2.name == splitPath)
                            {
                                muted = self.checkMuted(data2.muteArray)
                            }
                            else
                            {
                                data2.subSubBranches.forEach(data3=>{
                                    if(data3.name == splitPath)
                                    {
                                        muted = self.checkMuted(data3.muteArray)
                                    }
                                })
                            }
                        })
                    }
                })
            }
            return muted
        },
        // CHECK EXPIRY TIME OBJECT FOR POLL (Shraddha)
        checkDate(expireyTimeObjects)
        {
            var self = this;
            if(expireyTimeObjects.date == self.splitDateObject.date && expireyTimeObjects.month == self.splitDateObject.month &&expireyTimeObjects.year == self.splitDateObject.year){
                return 'Today'
            }else{
                return `${expireyTimeObjects.date}-${expireyTimeObjects.month}-${expireyTimeObjects.year}`
            }
        },
        //CHECK POLL EXPIRY DATE(Shraddha)
        checkExpirey(expireyTimeObjects)
        {
            var self = this
            var result = false
            if(expireyTimeObjects.date < self.splitDateObject.date && expireyTimeObjects.month <= self.splitDateObject.month && expireyTimeObjects.year <= self.splitDateObject.year)
            {
                result = true
            } else if(expireyTimeObjects.date <= self.splitDateObject.date && expireyTimeObjects.month == self.splitDateObject.month && expireyTimeObjects.year == self.splitDateObject.year){
                if(expireyTimeObjects.hrs < self.todayTime.hrs) {
                    result = true
                }else if(expireyTimeObjects.hrs == self.todayTime.hrs && expireyTimeObjects.min <= self.todayTime.min){
                    result=true
                }
            } else {
                result = false
            }
            return result
        },
        muteUnmuteChat(branchName, index, operation)
        {
            var self = this
            self.selectDropdown=false;
            self.selectSubDropdown=false;
            self.selectSubSubDropdown=false;
            self.selectMainBranchActiveDropdown=false;
            self.selectSubBranchActiveDropdown=false;
            self.selectSubSubBranchActiveDropdown=false;
            if(operation=='mute')
            {
                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                .doc(self.chatObjectId)
                .get().then((doc)=>{
                    var mainBranchData = []
                    mainBranchData = self.sortArray(doc.data().mainBranches)
                    var muted = false
                    if(branchName=='mainBranch')
                    {
                        mainBranchData[index].muteArray.forEach(data=>{
                            if(data==self.userid)
                            {
                                muted = true
                            }
                        })
                    } else if(branchName=='subBranch') {
                        mainBranchData[self.mainBranchActiveIndex].subBranches[index].muteArray.forEach(data=>{
                            if(data==self.userid)
                            {
                                muted = true
                            }
                        })
                    } else if(branchName=='subSubBranch') {
                        mainBranchData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[index].muteArray.forEach(data=>{
                            if(data==self.userid)
                            {
                                muted = true
                            }
                        })
                    }

                    if(!muted)
                    {
                        if(branchName=='mainBranch')
                        {
                            mainBranchData[index].muteArray.push(self.userid)
                            mainBranchData[index].subBranches.forEach(data=>{
                                var subMuted = false
                                data.muteArray.forEach(ids=>{
                                    if(ids == self.userid)
                                    {
                                        subMuted = true
                                    }
                                })
                                if(!subMuted)
                                {
                                    data.muteArray.push(self.userid)
                                    data.subSubBranches.forEach(data2=>{
                                        var subSubMuted = false
                                        data2.muteArray.forEach(ids=>{
                                            if(ids == self.userid)
                                            {
                                                subSubMuted = true
                                            }
                                        })
                                        if(!subSubMuted)
                                        {
                                            data2.muteArray.push(self.userid)
                                        }
                                    })
                                }
                            })
                        }
                        else if(branchName=='subBranch')
                        {
                            mainBranchData[self.mainBranchActiveIndex].subBranches[index].muteArray.push(self.userid)
                            mainBranchData[self.mainBranchActiveIndex].subBranches[index].subSubBranches.forEach(data=>{
                                var subSubMuted = false
                                data.muteArray.forEach(ids=>{
                                    if(ids == self.userid)
                                    {
                                     subSubMuted = true
                                    }
                                })
                                if(!subSubMuted)
                                {
                                    data.muteArray.push(self.userid)
                                }
                            })
                        }
                        else if(branchName=='subSubBranch')
                        {
                            mainBranchData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[index].muteArray.push(self.userid)
                        }
                        setTimeout( () => {
                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                            .doc(doc.id)
                            .update({
                                mainBranches:mainBranchData
                            })
                            .then(()=>{
                                self.$root.$emit("audioplay");
                                self.$notify({
                                title:"Success",
                                text:'Muted successfully.',
                                type:'success'
                                })
                            })
                            .catch(error=>{
                                console.log(error)
                            })
                        }, 300)
                    }

                })
                .catch(error=>{
                    console.log(error)
                })
            }
            else if(operation=='unmute')
            {
                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                .doc(self.chatObjectId)
                .get().then((doc)=>{
                    var mainBranchData = []
                    mainBranchData = self.sortArray(doc.data().mainBranches)
                    var muted = false
                    if(branchName=='mainBranch')
                    {
                        mainBranchData[index].muteArray.forEach(data=>{
                            if(data==self.userid)
                            {
                                muted = true
                            }
                        })
                    }
                    else if(branchName=='subBranch')
                    {
                        mainBranchData[self.mainBranchActiveIndex].subBranches[index].muteArray.forEach(data=>{
                            if(data==self.userid)
                            {
                                muted = true
                            }
                        })
                    }
                    else if(branchName=='subSubBranch')
                    {
                        mainBranchData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[index].muteArray.forEach(data=>{
                            if(data==self.userid)
                            {
                                muted = true
                            }
                        })
                    }
                    if(muted)
                    {
                        if(branchName=='mainBranch')
                        {
                            for (let i = 0; i < mainBranchData[index].muteArray.length; i++) {
                                if(mainBranchData[index].muteArray[i]==self.userid)
                                {
                                    mainBranchData[index].muteArray.splice(i,1)
                                }
                                for (let j = 0; j < mainBranchData[index].subBranches.length; j++) {
                                    for (let k = 0; k < mainBranchData[index].subBranches[j].muteArray.length; k++) {
                                        if(mainBranchData[index].subBranches[j].muteArray[k]==self.userid)
                                        {
                                            mainBranchData[index].subBranches[j].muteArray.splice(k,1)
                                        }
                                    }
                                    for (let l = 0; l < mainBranchData[index].subBranches[j].subSubBranches.length; l++) {
                                        for (let m = 0; m < mainBranchData[index].subBranches[j].subSubBranches[l].muteArray.length; m++) {
                                            if(mainBranchData[index].subBranches[j].subSubBranches[l].muteArray[m]==self.userid)
                                            {
                                                mainBranchData[index].subBranches[j].subSubBranches[l].muteArray.splice(m,1)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        else if(branchName=='subBranch')
                        {
                            for (let i = 0; i < mainBranchData[self.mainBranchActiveIndex].subBranches[index].muteArray.length; i++) {
                                if(mainBranchData[self.mainBranchActiveIndex].subBranches[index].muteArray[i]==self.userid)
                                {
                                    mainBranchData[self.mainBranchActiveIndex].subBranches[index].muteArray.splice(i,1)
                                }
                                for (let l = 0; l < mainBranchData[self.mainBranchActiveIndex].subBranches[index].subSubBranches.length; l++) {
                                    for (let m = 0; m < mainBranchData[self.mainBranchActiveIndex].subBranches[index].subSubBranches[l].muteArray.length; m++) {
                                        if(mainBranchData[self.mainBranchActiveIndex].subBranches[index].subSubBranches[l].muteArray[m]==self.userid)
                                        {
                                            mainBranchData[self.mainBranchActiveIndex].subBranches[index].subSubBranches[l].muteArray.splice(m,1)
                                        }
                                    }
                                }
                            }
                        }
                        else if(branchName=='subSubBranch')
                        {
                            for (let i = 0; i < mainBranchData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[index].muteArray.length; i++) {
                                if(mainBranchData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[index].muteArray[i]==self.userid)
                                {
                                    mainBranchData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[index].muteArray.splice(i,1)
                                }
                            }
                        }
                        setTimeout(()=>{
                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                            .doc(doc.id)
                            .update({
                                mainBranches:mainBranchData
                            })
                            .then(()=>{
                                self.$root.$emit("audioplay");
                                self.$notify({
                                title:"Success",
                                text:'Unmuted successfully.',
                                type:'success'
                                })
                            })
                            .catch(error=>{
                                console.log(error)
                            })
                        }, 300)
                    }

                })
                .catch(error=>{
                    console.log(error)
                })
            }
        },
        // CHECK DATE IS PAST DATE OR NOT(Shraddha)
        beforeDateExpired(dateexpired){
            var todayDatesplit = new Date().getDate();
            var todayMonthsplit = new Date().getMonth();
            var todayYearsplit = new Date().getFullYear();
            var result = false
            if(todayDatesplit > dateexpired.Datesplit && todayMonthsplit >= dateexpired.monthsplit && todayYearsplit >= dateexpired.yearsplit) {
                result = true
            } else {
                result = false
            }
            return result
        },
        previewImage: function (event) {
            var input = event.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.previewSenderImage = e.target.result;
                    this.preview = e.target.result;
                };
                this.image = input.files[0];
                reader.readAsDataURL(input.files[0]);
            }
        },
        //PREVIEW ADD IMAGE (Shraddha)
        previewListAddImage(event){
            var self=this
            var files = event.target.files
            self.uploadFiles(files,event)
        },
        //UPLOAD FILE FROM DEVICE OR DRAG AND DROP (Shraddha)
        uploadFiles: function(f,event) {
            this.previewImageSize = 0;
            this.dropFiles = true;
            var docType = ''
            this.msgTextTmp=''
            this.sendingMessage = ''
            if (f.length > 0) {
                var index = 0;
                var counting = 0;
                const getrequestDataFunction = (row) => {
                    if(counting >= f.length){
                        return;
                    }
                    const imgs = row;
                    var found = false
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.previewImageSize = this.previewImageSize + imgs.size;
                        if(Vue.prototype.$companyName.isAdmin == false){
                            if(this.companyuserlimit.dataUsage < this.companyuserlimit.userUsageLimit){  
                                if(imgs.size<this.companyuserlimit.max_file_size && (this.companyuserlimit.dataUsage + imgs.size) < this.companyuserlimit.userUsageLimit && (this.companyuserlimit.dataUsage +  this.previewImageSize) < this.companyuserlimit.userUsageLimit)
                                {
                                    if(this.send_Files_Type.length > 0){
                                        var imageExtention = imgs.name.split('.');
                                        var vedioextention = imgs.type.split('/');
                                        var docExtention = imgs.type.split('/');
                                        if(docExtention[0] == 'image'){
                                            docType = 'Image'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }else if(imgs.type == 'application/pdf'){
                                            docType = 'pdf'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }else if(vedioextention[0] == 'video'){
                                            docType = 'Video'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }else if(imageExtention[1] == 'csv' || imageExtention[1] == "xlsx" || imageExtention[1] == "xls" ){
                                            docType = 'excel'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }else if(imageExtention[1] == 'ods'){
                                            docType = 'ods'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }else if(imageExtention[1] == 'docx' || imageExtention[1] == 'doc' ){
                                            docType = 'word'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }else if(imageExtention[1] == 'odt'){
                                            docType = 'odt'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }else if(imageExtention[1] == 'ppt' || imageExtention[1] == 'pptx'){
                                            docType = 'ppt'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }else if(imageExtention[1] == 'html' || imageExtention[1] == 'htm'){
                                            docType = 'html'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }else if(imageExtention[1] == 'txt'){
                                            docType = 'plain'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }else if(imageExtention[1] == 'xd'){
                                            docType = 'xd'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                        } else {
                                            if(f.length == index+1){
                                                this.$root.$emit("audioplay");
                                                this.$notify({
                                                    title:"Error",
                                                    text:imgs.name+" not supported.",
                                                    type: "error"
                                                })
                                            }
                                        }
                                    }else{
                                        this.new_files_type.forEach(data => {
                                            if(data.files == 'picture'){
                                                var docExtention = imgs.type.split('/');
                                                if(docExtention[0] == 'image'){
                                                    docType = 'Image'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else {
                                                    self.$notify({
                                                    title:"Error",
                                                    text: imgs.name+' '+ "not supported.",
                                                    type: "error"
                                                    })
                                                }
                                            } else if (data.files == 'document'){
                                                var imageExtention = imgs.name.split('.');
                                                if(imgs.type == 'application/pdf'){
                                                    docType = 'pdf'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                } else if(imageExtention[1] == 'csv' || imageExtention[1] == "xlsx" || imageExtention[1] == "xls" ){
                                                    docType = 'excel'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                } else if(imageExtention[1] == 'ods'){
                                                    docType = 'ods'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                } else if(imageExtention[1] == 'docx' || imageExtention[1] == 'doc' ){
                                                    docType = 'word'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }else if(imageExtention[1] == 'odt'){
                                                    docType = 'odt'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }else if(imageExtention[1] == 'ppt' || imageExtention[1] == 'pptx'){
                                                    docType = 'ppt'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }else if(imageExtention[1] == 'html' || imageExtention[1] == 'htm'){
                                                    docType = 'html'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }else if(imageExtention[1] == 'txt'){
                                                    docType = 'plain'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }else if(imageExtention[1] == 'xd'){
                                                    docType = 'xd'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else {
                                                    self.$notify({
                                                    title:"Error",
                                                    text: imgs.name+' '+ "not supported.",
                                                    type: "error"
                                                    })
                                                }
                                            } else if (data.files == 'video'){
                                                var vedioextention = imgs.type.split('/');
                                                if(vedioextention[0] == 'video'){
                                                    docType = 'Video'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true 
                                                }
                                                else {
                                                    self.$notify({
                                                    title:"Error",
                                                    text: imgs.name+' '+ "not supported.",
                                                    type: "error"
                                                    })
                                                }
                                            } else {
                                                if(f.length == index+1){
                                                    this.$root.$emit("audioplay");
                                                    this.$notify({
                                                        title:"Error",
                                                        text:imgs.name+" not supported.",
                                                        type: "error"
                                                    })
                                                }
                                            }
                                        })
                                    }
                                }
                                else
                                {
                                    this.$root.$emit("audioplay");
                                    this.$notify({
                                        title:"Error",
                                        text:imgs.name+' file size is maximum.',
                                        type:"error"
                                    })
                                    this.previewImageSize = this.previewImageSize - imgs.size;
                                }
                            }else{
                                this.$root.$emit("audioplay");
                                this.$notify({
                                    title:"Error",
                                    text:'Cloudstorage limit has been over',
                                    type:"error"
                                })
                                this.dropFiles = false;
                                // this.image_list = [];
                                // this.image = [];
                                // this.preview_list = [];
                            }
                        }else{
                            if(this.companyuserlimit.dataUsage  < this.companyuserlimit.userUsageLimit){ 
                                if(imgs.size<this.companyuserlimit.max_file_size && (this.companyuserlimit.dataUsage + imgs.size) < this.companyuserlimit.userUsageLimit && (this.companyuserlimit.dataUsage +  this.previewImageSize) < this.companyuserlimit.userUsageLimit)
                                { 
                                    if(this.send_Files_Type.length > 0) {
                                        var imageExtention = imgs.name.split('.');
                                        var vedioextention = imgs.type.split('/');
                                        var docExtention = imgs.type.split('/');
                                        if(docExtention[0] == 'image'){
                                            docType = 'Image'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }
                                        else if(imgs.type == 'application/pdf'){
                                            docType = 'pdf'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }
                                        else if(vedioextention[0] == 'video'){
                                            docType = 'Video'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }
                                        else if(imageExtention[1] == 'csv' || imageExtention[1] == "xlsx" || imageExtention[1] == "xls" ){
                                            docType = 'excel'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }
                                        else if(imageExtention[1] == 'ods'){
                                            docType = 'ods'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }
                                        else if(imageExtention[1] == 'docx' || imageExtention[1] == 'doc' ){
                                            docType = 'word'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }
                                        else if(imageExtention[1] == 'odt'){
                                            docType = 'odt'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }
                                        else if(imageExtention[1] == 'ppt' || imageExtention[1] == 'pptx'){
                                            docType = 'ppt'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }
                                        else if(imageExtention[1] == 'html' || imageExtention[1] == 'htm'){
                                            docType = 'html'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }
                                        else if(imageExtention[1] == 'txt'){
                                            docType = 'plain'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                            found = true
                                        }
                                        else if(imageExtention[1] == 'xd'){
                                            docType = 'xd'
                                            this.preview_list.push({file:e.target.result,docType : docType});
                                            this.image_list.push({file:imgs,docType : docType,text:''});
                                        } else {
                                            if(this.preview_list.length == 0 && f.length == index+1){
                                                this.$root.$emit("audioplay");
                                                this.$notify({
                                                    title:"Error",
                                                    text:imgs.name+" not supported.",
                                                    type: "error"
                                                })
                                            }
                                        }
                                    } else {
                                        this.new_files_type.forEach(data => {
                                            if(data.files == 'picture'){
                                                var docExtention = imgs.type.split('/');
                                                if(docExtention[0] == 'image'){
                                                    docType = 'Image'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else {
                                                    self.$notify({
                                                    title:"Error",
                                                    text: imgs.name+' '+ "not supported.",
                                                    type: "error"
                                                    })
                                                }
                                            } else if (data.files == 'document'){
                                                var imageExtention = imgs.name.split('.');
                                                if(imgs.type == 'application/pdf'){
                                                    docType = 'pdf'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else if(imageExtention[1] == 'csv' || imageExtention[1] == "xlsx" || imageExtention[1] == "xls" ){
                                                    docType = 'excel'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else if(imageExtention[1] == 'ods'){
                                                    docType = 'ods'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else if(imageExtention[1] == 'docx' || imageExtention[1] == 'doc' ){
                                                    docType = 'word'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else if(imageExtention[1] == 'odt'){
                                                    docType = 'odt'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else if(imageExtention[1] == 'ppt' || imageExtention[1] == 'pptx'){
                                                    docType = 'ppt'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else if(imageExtention[1] == 'html' || imageExtention[1] == 'htm'){
                                                    docType = 'html'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else if(imageExtention[1] == 'txt'){
                                                    docType = 'plain'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else if(imageExtention[1] == 'xd'){
                                                    docType = 'xd'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else {
                                                    self.$notify({
                                                    title:"Error",
                                                    text: imgs.name+' '+ "not supported.",
                                                    type: "error"
                                                    })
                                                }
                                            } else if (data.files == 'video'){
                                                var vedioextention = imgs.type.split('/');
                                                if(vedioextention[0] == 'video'){
                                                    docType = 'Video'
                                                    this.preview_list.push({file:e.target.result,docType : docType});
                                                    this.image_list.push({file:imgs,docType : docType,text:''});
                                                    found = true
                                                }
                                                else {
                                                    self.$notify({
                                                    title:"Error",
                                                    text: imgs.name+' '+ "not supported.",
                                                    type: "error"
                                                    })
                                                }
                                            } else {
                                                if(f.length == index+1){
                                                    this.$root.$emit("audioplay");
                                                    this.$notify({
                                                        title:"Error",
                                                        text:imgs.name+" not supported.",
                                                        type: "error"
                                                    })
                                                }
                                            }
                                        })
                                    }
                                } else {
                                    this.$root.$emit("audioplay");
                                    this.$notify({
                                        title:"Error",
                                        text:imgs.name+' file size is maximum.',
                                        type:"error"
                                    })
                                    this.previewImageSize = this.previewImageSize - imgs.size;
                                }
                            } else {
                                this.$root.$emit("audioplay");
                                this.$notify({
                                    title:"Error",
                                    text:'Cloudstorage limit has been over',
                                    type:"error"
                                })
                                this.dropFiles = false;
                            }
                        }
                        index++;
                    };
                    if(!found){
                        reader.readAsDataURL(imgs);
                    }
                    counting += 1;
                    getrequestDataFunction(f[counting]);
                }
                getrequestDataFunction(f[counting]);
            }
            setTimeout(()=>{
                if(this.preview_list.length!=0) {
                    this.dropFiles = true;
                }
            },500)
        },
        // REMOVE DROP IMAGES (Shraddha)
        removeFromPreview(index){
            var self = this;
            self.previewIndex=0;
            self.preview_list.splice(index,1);
            self.image_list.splice(index,1);
            if(self.image_list.length == 0){
                self.dropFiles = false;
            }
        },
        // CLOSE ALL PREVIEW(Shraddha)
        closeAllPreview(){
            var self = this;
            self.previewIndex=0;
            self.preview_list = [];
            self.image_list = [];
            self.dropFiles = false;
        },
        sendAllDropMedia2(){
            var self = this;

            self.sendingMessage = '';
            self.msgTextTmp = '';
            const from = {
                avtar:self.userData.profileUrl,
                id:self.userData.id,
                name:self.userData.firstName,
            };
            let fromArray = [];
            let datausage = 0;
            let imgsLength = self.image_list.length;

            let userData = self.userData;
            let image_list = self.image_list;
            let activeGroup = self.activeGroup;
            let chatObjectId = self.chatObjectId;
            let userid = self.userid;
            let chatObject = self.chatObject;
            let currCollection = self.currCollection;
            let isInArray = self.isInArray;
            let image = self.image;

            if(image_list.length > 0) {
                self.sendingMessage = '';

                for(var i=0;i<image_list.length;i++) {
                    uploadImageFile(firebaseData.firebasecollection.PROFILEIMAGES,image_list[i].file.name,image_list[i].file.size,image_list[i].file,image_list[i].docType,image_list[i].text,res=>{
                        image.push(res);
                        //console.log('awdawd213123',self.preview_list,self.image.length,self.image_list.length,imgsLength,res)
                        if(image.length==imgsLength) {
                            let count = 0;
                            const getrequestDataFunction = (row) => {
                                if(count >=  image.length){
                                    self.image_list = [];
                                    self.image = [];
                                    self.preview_list = [];
                                    return;
                                }
                                    const key = row.imageUrl;
                                    const imageName = row.imageName;
                                    const docType = row.docType;
                                    const Text = row.Text;
                                    const imageSize = row.imageSize;

                                    db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc => {
                                        if(Vue.prototype.$companyName.isAdmin == true){
                                            var userUsage = doc.data().dataUsage;
                                                userUsage = userUsage + Number(imageSize)
                                            db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                                                dataUsage : userUsage
                                            })
                                        }else{
                                            var users = doc.data().users
                                            users.forEach(data => {
                                            if(data.id == firebase.auth().currentUser.uid){
                                                data.dataUsage = data.dataUsage + Number(imageSize)
                                            }
                                            })
                                            db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                                            users : users
                                            })
                                        }
                                    })

                                if(userData.SocialreadReceipts || activeGroup)
                                {
                                    fromArray=[from];
                                }

                                if(chatObjectId != undefined && chatObjectId != '') {
                                    self.addMessageAndCallAPI({
                                        createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        from: from,
                                        type: docType,
                                        name: imageName,
                                        mediaUrl:key,
                                        msg: Text,
                                        messageSeen: fromArray,
                                    },chatObjectId,currCollection);
                                    count += 1;
                                    getrequestDataFunction(image[count]);

                                } else {
                                    self.currCollection = "default";
                                    currCollection = "default";
                                    self.connectedUserArray = [];
                                    let connectedUserArray = [];
                                    connectedUserArray.push(userid);
                                    connectedUserArray.push(chatObject.id);
                                    self.connectedUserArray = connectedUserArray;
                                    let messageSeenIds=[];
                                    messageSeenIds.push({id:firebase.auth().currentUser.uid,count:0});
                                    messageSeenIds.push({id:chatObject.id,count:0});

                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).add({
                                        connectedUser : connectedUserArray,
                                        id:'',
                                        isGroup : activeGroup,
                                        lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                        title:'',
                                        updateChat:{},
                                        msgFromBranch:false,
                                        muteArray:[],
                                        mainBranches:[],
                                        updateBranchData:{},
                                        SurpriseSelfie : {},
                                        ToggleSelfie : [],
                                        messageSeenIds:messageSeenIds,
                                        blockBy : isInArray || ''
                                    }).then((doc) => {
                                        self.chatObjectId = doc.id;
                                        chatObjectId = doc.id;
                                        self.getSetBusinessChatData({companyId:Vue.prototype.$companyName.id,collection:'businessMessages',chatId:doc.id});

                                        db.collection(firebaseData.firebasecollection.USER).doc(userid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(chatObject.id).update({
                                            chatId:doc.id
                                        })
                                        db.collection(firebaseData.firebasecollection.USER).doc(chatObject.id).collection(Vue.prototype.$companyName.id+'_businessContact').doc(userid).update({
                                            chatId:doc.id
                                        })
                                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(doc.id).update({
                                            id : doc.id
                                        })
                                        self.addMessageAndCallAPI({
                                            createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                            updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                            from: from,
                                            type: docType,
                                            name: imageName,
                                            mediaUrl:key,
                                            msg: Text,
                                            messageSeen: fromArray,
                                        },chatObjectId,currCollection);
                                        setTimeout(()=>{
                                            self.getChatArray('default', doc.id)
                                        },1000)
                                        count += 1;
                                        getrequestDataFunction(image[count]);
                                    })
                                    .catch(error=>{
                                        console.error(error);
                                    })
                                }
                            }
                            getrequestDataFunction(image[count]);
                        }
                    })
                }
                self.dropFiles = false;
            }
        },
        reset: function () {
            this.image = [];
            this.preview = null;
            this.image_list = [];
            this.preview_list = [];
        },
        async init() {
            var self = this;
            try {
                self.new_files_type =[]
                self.send_Files_Type = []
                const db = firebase.firestore()
                self.loginCustomeId = localStorage.getItem("customerDocKey");
                await db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).onSnapshot(doc => {
                    if(doc.exists){
                        var users = doc.data().users
                        var plans = doc.data().plan
                        if(Vue.prototype.$companyName.isAdmin == false){
                            users.forEach(userdata => {
                                if(userdata.id == self.loginCustomeId){
                                    self.selfieUserData = userdata
                                    self.dataUsage = Number(userdata.dataUsage)
                                    if(userdata.messages_history == 'unlimited'){
                                        self.messageHistory = userdata.messages_history
                                    }else{
                                        self.messageHistory = Number(userdata.messages_history)
                                    }
                                    self.companyuserlimit = {
                                        planName:userdata.planName,
                                        planId:userdata.planId,
                                        max_file_size:Number(userdata.max_file_size),
                                        userUsageLimit:userdata.userUsageLimit,
                                        dataUsage:Number(userdata.dataUsage),
                                        device_plan:userdata.device_plan
                                    }
                                    if(self.companyuserlimit.device_plan != undefined){
                                        if(self.companyuserlimit.device_plan.includes('all') || self.companyuserlimit.device_plan.includes('desktop')){
                                            self.displayChat = true;
                                        }else{
                                            self.displayChat = false;
                                        }
                                    }
                                    self.$root.$emit('hideAreaOfSidebar',self.displayChat);
                                }
                            })
                        }
                        if(Vue.prototype.$companyName.isAdmin == true){
                            self.selfieUserData = doc.data();
                            self.dataUsage = doc.data().dataUsage
                            if(doc.data().messages_history == 'unlimited'){
                                self.messageHistory = doc.data().messages_history
                            }else{
                                self.messageHistory = Number(doc.data().messages_history)
                            }
                            self.companyuserlimit = {
                                max_file_size:doc.data().max_file_size,
                                userUsageLimit:doc.data().userUsageLimit,
                                dataUsage:doc.data().dataUsage,
                                device_plan:doc.data().device_plan,
                            }
                            if(self.companyuserlimit.device_plan.includes('all') || self.companyuserlimit.device_plan.includes('desktop')){
                                self.displayChat = true;
                            }else{
                                self.displayChat = false;
                            }
                            self.$root.$emit('hideAreaOfSidebar',self.displayChat);
                        }
                        plans.forEach(plan => {
                            var file_types = plan.file_type_plan
                            file_types.forEach(files => {
                                self.file_type_Array.push({'files':files})
                            })
                            const ids = self.file_type_Array.map(o => o.files)
                            const filtered = self.file_type_Array.filter(({files}, index) => !ids.includes(files, index + 1))
                            self.new_files_type = filtered
                        })
                        self.new_files_type.forEach(newfiles => {
                            if(newfiles.files == 'all'){
                                self.send_Files_Type.push(newfiles.files)
                            }
                        })
                    }
                })
                // BACK FROM VIEW CONTACT (Shraddha)
                var val = localStorage.getItem('backtocontact');
                self.$root.$off("openBusinessChat");
                self.$root.$on("openBusinessChat",(value,chatId,activeGroup,mainBranches,collection)=>{
                if(self.chatObjectId !== chatId || chatId==="" || self.chatObjectId === "" || self.currCollection !== collection) {
                    self.image_list = [];
                    self.image = [];
                    self.preview_list = [];
                    self.dropFiles = false;
                }
                if(self.chatSnapshotListener !== null) {
                    self.chatSnapshotListener();
                }
                if(self.snapShotListener !== null)
                {
                    // console.log("Detach default snapshot")
                    if (Object.keys(self.lastMessageData).length > 0 && self.messageCount > 0) {
                    self.updateCount({...self.lastMessageData}, self.messageCount);
                    }
                    self.snapShotListener();
                }

                // Reset Values
                self.currCollection = collection;
                self.botUserEmail = Vue.prototype.$email;
                self.surpriseSelfie = false;
                self.searchErrorMessage = '';
                self.mainBranchActive=false;
                self.mainBranchActiveIndex=0;
                self.currentPage=0;
                self.subBranchActive=false;
                self.subBranchActiveIndex=0;
                self.subSubBranchActive=false;
                self.subSubBranchActiveIndex=0;
                self.senderArray = [];
                self.mainBranches=[];
                self.sendreceArray = [];
                self.chatObject = value;
                self.chatObjectId = chatId;

                self.activeChat = true;
                self.activeGroup = activeGroup.isGroup;
                self.activeChannel = activeGroup.isChannel
                if (self.activeGroup == undefined) {
                    self.activeGroup = false;
                }
                if (self.activeChannel == undefined) {
                    self.activeChannel = false;
                }
                if(!self.activeChannel && !self.activeGroup){
                    self.$root.$emit("businessBranchUser",value);
                }

                self.groupData = activeGroup;
                self.$root.$emit('clerarMainSearchKeyword');

                self.searchbaroption = 'All';
                self.searchkeyword = '';
                if ( collection!== "default") {
                    self.$root.$emit('setBranch',self.mainBranchActiveIndex,self.mainBranches,self.chatObjectId);
                }
                if (self.groupData && chatId ) {
                    self.$root.$emit('groupsArray',collection,self.groupData,chatId);
                }
                if (chatId && chatId === self.storeChatData.id) {
                    self.connectedUserLength = '';
                    self.connectedUsers  = [];
                    self.connectedUserLength = self.storeChatData.connectedUser.length;
                    self.connectedUsers  = self.storeChatData.connectedUser;

                    self.sendreceArrayBKP=[];

                    self.getChatArray(collection,chatId);

                    //snapshot to update chat data.
                    self.chatSnapshotListener = db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                    .doc(chatId)
                    .onSnapshot(doc=>{
                        if(!doc.exists || doc.id !== chatId && chatId !== self.storeChatData.id) {
                        return;
                        }

                        self.mainBranches = self.sortArray(doc.data().mainBranches)
                        self.mainBranches.forEach(mb => {
                        mb.subBranches = self.sortArray(mb.subBranches)

                        mb.subBranches.forEach((sb => {
                            sb.subSubBranches = self.sortArray(sb.subSubBranches)
                        }))
                        })
                        self.messageSeenIds=doc.data().messageSeenIds||[]

                        var muted = false
                        doc.data().muteArray.forEach(uids=>{
                        if(uids==firebase.auth().currentUser.uid)
                        {
                            muted = true
                        }
                        })
                        self.mainBranches.forEach((data,index)=>{
                        if(data.name == self.currCollection)
                        {
                            self.mainBranchActive=true
                            self.mainBranchActiveIndex=index
                        }
                        self.mainBranches[index].subBranches.forEach((data2,index2) => {
                            if(data2.name == self.currCollection)
                            {
                                self.mainBranchActive=true
                                self.mainBranchActiveIndex=index
                                self.subBranchActive=true
                                self.subBranchActiveIndex=index2
                            }
                            self.mainBranches[index].subBranches[index2].subSubBranches.forEach((data3,index3) => {
                            if(data3.name == self.currCollection)
                                {
                                    self.mainBranchActive=true
                                    self.mainBranchActiveIndex=index
                                    self.subBranchActive=true
                                    self.subBranchActiveIndex=index2
                                    self.subSubBranchActive=true
                                    self.subSubBranchActiveIndex=index3
                                }
                            })
                        })
                        })
                        self.isGroupMuted=muted;
                    })
                }
                })
                // EMIT METHOD IS ON THAT COME FROM CONTACTDETAIL PAGE (Shraddha)
                self.$root.$on("activeGroup",(value) => {
                    if(value == 'yes'){
                        self.activeGroup = false
                        self.activeChat = false
                        self.chatObject = {}
                    }
                })

                // CHECK LOGIN USER IS ADMIN OR CUSTOMER(Shraddha)
                self.loginUser = localStorage.getItem("IsLoginUser")
                if(this.loginUser == 'Admin'){
                    self.isAdmin = true;
                }else{
                    self.isAdmin = false;
                }

                // GET PROFILE PICTURE (Shraddha)
                self.userData = {};
                self.userid = firebase.auth().currentUser.uid;
                await db.collection(firebaseData.firebasecollection.USER).doc(self.userid).get().then(doc=>{
                    self.imageUrl = doc.data().profileUrl;
                    self.userData = doc.data()
                })
                self.showLoader = false
                self.showLoaders = false
            } catch (error) {
                console.error("ERROR: ", error);
                self.$router.replace('/chat');
            }
        },
        getMenuScreen(value) {
            var self = this;
            self.searchErrorMessage = '';
            self.$root.$emit('searchBarClearEvent');
            self.setStoreChatId('')
            self.activeChat = false
            self.selectedActiveMenu = value;
            self.chatObject = {}
            self.groupData = {}
            self.activeGroup = false
            self.chatObjectId=''
            self.currCollection=''
            self.sendreceArrayBKP=[]
            self.sendreceArray=[]
            if(value == 'adminUser'){
                setTimeout( () => {
                    self.$root.$emit('getCompanyAdmin',self.companyName)
                },300)
            }
            localStorage.setItem('backtocontact',value);
        },
        sendMessage(){
            var self = this;
            self.usedSelfie = 0;
            if(self.charecterErrorMessageForMessage == '') {
                let sendingMessage=self.msgTextTmp.trim();
                self.msgTextTmp = '';
                self.sendingMessageFlag = false;

                const res = sendingMessage.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                let docType = "";
                let name = "";
                if(!self.surpriseSelfie){
                    if(res == null){
                        docType = "Text";
                        name = "Text";
                    } else {
                        docType = "Link";
                        name = "Link";
                    }
                } else {
                    docType = "SurpriseSelfieText";
                    name = "SurpriseSelfieText";
                }

                const from = {
                    avtar: self.userData.profileUrl,
                    id: self.userData.id,
                    name: self.userData.firstName,
                    path: self.currCollection === "default" ? "" : self.currCollection,
                }

                let fromArray = [];
                if(self.userData.SocialreadReceipts || self.activeGroup) {
                    fromArray.push(from);
                }

                if(self.chatObjectId !== "" && self.chatObjectId !== undefined) {
                    self.addMessageAndCallAPI({
                        createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        from: from,
                        type: docType,
                        name: name,
                        msg: sendingMessage,
                        messageSeen: fromArray,
                        surpriseSelfieUsers : self.surpriseSelfie ? [firebase.auth().currentUser.uid] : []
                    }, self.chatObjectId);
                    self.surpriseSelfie = false;
                } else {
                    self.connectedUserArray = []
                    self.connectedUserArray.push(self.userid)
                    self.connectedUserArray.push(self.chatObject.id)
                    let messageSeenIds=[]
                    messageSeenIds.push({id:firebase.auth().currentUser.uid,count:0,time:'',IsEmailSend:false})
                    messageSeenIds.push({id:self.chatObject.id,count:0,time:new Date(),IsEmailSend:false})

                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).add({
                        connectedUser : self.connectedUserArray,
                        blockUserArray:[],
                        id:'',
                        isGroup : self.activeGroup,
                        lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        title:'',
                        muteArray:[],
                        mainBranches:[],
                        updateChat:{},
                        msgFromBranch:false,
                        SurpriseSelfie : {},
                        ToggleSelfie : [],
                        updateBranchData:{},
                        messageSeenIds:messageSeenIds,
                        blockBy : self.isInArray || ''
                    }).then((docRef)=>{
                        const docId = docRef.id;
                        self.chatObjectId = docId;
                        self.getSetBusinessChatData({companyId:Vue.prototype.$companyName.id,collection:'businessMessages',chatId:docId});
                        self.currCollection = "default";
                        //add chat id in users documents

                        const chatUserId = self.chatObject.id;

                        db.collection(firebaseData.firebasecollection.USER).doc(self.userid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(chatUserId).update({
                            chatId: docId
                        })
                        db.collection(firebaseData.firebasecollection.USER).doc(chatUserId).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.userid).update({
                            chatId: docId
                        })
                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(docId).update({
                            id: docId
                        })
                        .then(() => {
                            // Add the message in the chats' sub collection
                            self.addMessageAndCallAPI({
                                createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                from: from,
                                type: docType,
                                name: name,
                                msg: sendingMessage,
                                messageSeen: fromArray,
                                surpriseSelfieUsers: self.surpriseSelfie ? [firebase.auth().currentUser.uid] : []
                            }, docId);
                            self.surpriseSelfie = false;
                            setTimeout(()=>{
                                self.getChatArray('default', docId)
                            },1000)
                        })
                        .catch(error=>{
                            console.error(error);
                            self.sendingMessageFlag=false
                        })
                    })
                    .catch(error=>{
                        console.error(error);
                        self.sendingMessageFlag=false
                    })
                }
            }
        },

        addCountInObj(messageSeenIds, sid, count) {
            const ids = messageSeenIds;
            ids.forEach((data)=>{
              if (data.id !== sid) {
                data.count+=count;
                data.time = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
              }
            });
            return ids;
        },

        updatedBranches(mainBranches, sid, messageCollection, count) {
            var self = this;
            const arr = mainBranches;
            arr.forEach((data)=>{
              if (data.name === messageCollection) {
                data.messageSeenIds = self.addCountInObj(data.messageSeenIds, sid, count);
                data.lastMsgTime = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
              } else {
                data.subBranches.forEach((data2)=>{
                  if (data2.name === messageCollection) {
                    data2.messageSeenIds = self.addCountInObj(data2.messageSeenIds, sid, count);
                    data2.lastMsgTime = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
                    data.lastMsgTime = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
                  } else {
                    data2.subSubBranches.forEach((data3)=>{
                      if (data3.name === messageCollection) {
                        data3.messageSeenIds = self.addCountInObj(data3.messageSeenIds, sid, count);
                        data3.lastMsgTime = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
                        data2.lastMsgTime = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
                        data.lastMsgTime = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
                      }
                    });
                  }
                });
              }
            });

            return arr;
        },

        updateCount(data, count){
            const self = this;

            self.messageCount = 0;
            self.lastMessageData = {};

            const {socialChat, companyId,
              chatId, messageCollection,
              senderData, messageData,
            } = data;

            const messageFromBranch = messageCollection!="default";
            const obj = {
              from: senderData,
              msg: messageData.msg,
              name: messageData.name,
              type: messageData.type,
            };

            const updateBranchData = {
              from: senderData,
              msg: messageData.msg,
              name: messageData.name,
              type: messageData.type,
            };

            let query = "";

            if (socialChat) {
              query = db.collection("socialMessages").doc(chatId);
            } else {
              query = db.collection(companyId).doc(companyId)
                .collection("businessMessages").doc(chatId);
            }

            query
                .get()
                .then((doc)=>{
                  const sid = senderData.id;

                  let messageSeenIds = doc.data().messageSeenIds || [];
                  let mainBranches = doc.data().mainBranches || [];

                  let finalUpdate = {};

                  try {
                    if (messageCollection === "default") {
                      messageSeenIds = self.addCountInObj(messageSeenIds, sid, count);
                      obj.fromBranch = false;

                      finalUpdate = {
                        messageSeenIds: messageSeenIds,
                        mainBranches: mainBranches,
                        updateChat: obj,
                        messageFromBranch: messageFromBranch,
                        lastMsgtime: firebaseTimestamp.firestore.Timestamp.fromDate(new Date()),
                      };
                    } else {
                      obj.fromBranch = true;
                      mainBranches = self.updatedBranches(mainBranches, sid, messageCollection, count);

                      finalUpdate = {
                        messageSeenIds: messageSeenIds,
                        mainBranches: mainBranches,
                        updateBranchData: updateBranchData,
                        messageFromBranch: messageFromBranch,
                        lastMsgtime: firebaseTimestamp.firestore.Timestamp.fromDate(new Date()),
                      };
                    }
                  } catch (error) {
                    console.error("Error", error);
                    // reject(error);
                  }

                  query
                      .update(finalUpdate)
                      .then(()=>{
                        // console.log("Count Added",finalUpdate);
                        // resolve(finalUpdate);
                      })
                      .catch((error)=>{
                        console.error("Query Update Error", error);
                        // reject(error);
                      });
                })
                .catch((error)=>{
                  console.error("QueryResult Error", error);
                  // reject(error);
                });
        },

        addMessageAndCallAPI(chatData,...args){
            var self = this;
            if(chatData.type == 'SurpriseSelfieText'){
              self.updateSurpriseSelfieEntryInDb();
            }
            const companyId = Vue.prototype.$companyName.id || null;

            let id = args.length ? args[0] : self.chatObjectId;

            const collection = args.length > 1 ? args[1] : self.currCollection;

            try{
              self.blockUserArray = self.storeChatData.blockUserArray || []
              self.blockUserArray.forEach(inArray => {
                if(inArray.blockTo == firebase.auth().currentUser.uid){
                  self.isInArray = inArray.blockBy
                }
              })
              const query = db.collection(companyId).doc(companyId).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(id).collection(collection);


              self.messageCount+=1;
              self.lastMessageData = {
                chatId: id,
                socialChat: false,
                companyId: companyId,
                messageCollection: collection,
                senderData: chatData.from,
                messageData: {msg: chatData.msg, type: chatData.type,name:chatData.name},
              }

              if(self.unreadMessages>0) {
                self.unreadMessages=0;
              }

              if(self.unreadMessages > 0) {
                self.setCountToZero()
              }

              // Store message in specified collection
              query
                .add({...chatData, blockBy: self.isInArray || ""})
                .then((docRef)=>{
                  query
                    .doc(docRef.id)
                    .update({
                      id: docRef.id
                    })
                    .catch(error=>{
                      console.error(error);
                    })
                    self.sendingMessageFlag=false;
                    self.$axios.post(self.$apiurl+'sendpushNotification',{
                        userId:self.userData.id,msgCollection:collection,
                        chatId:id,msgId:docRef.id,companyId:Vue.prototype.$companyName.id,
                        userName: self.userData.firstName || "",
                        userProfile: self.userData.profileUrl || ""
                    })
                })
                .catch(error=>{
                  console.error(error);
                  self.sendingMessageFlag=false;
                })
            } catch(error) {
              console.error(error);
              self.sendingMessageFlag=false;
            }
        },
        // UPDATE ENTRY WHEN SURPRISE SELFIE TEXT(Shraddha)
        updateSurpriseSelfieEntryInDb(){
            debugger // eslint-disable-line
            var self = this;
            console.log(self.chatObject,'receiverUser',self.selfieUserData);
              var databaseTime = new Date(self.selfieUserData.selfieTime.seconds * 1000)
              var afterSixHourTime =  new Date(databaseTime.setHours(databaseTime.getHours() + 6));
              if(afterSixHourTime <= new Date()){
                self.selfieUserData.usedSelfie = 0;
              }
            
              if(self.selfieUserData.usedSelfie == 0){
                var usedselfie = self.selfieUserData.usedSelfie + 1
                self.usedSelfie = usedselfie
                var selfieuser = [];
                selfieuser = self.selfieUserData.selfieUsers || []
                var dynamicObj = {
                  [self.chatObjectId] : self.chatObject.firstName
                }
                selfieuser.push(dynamicObj)
                 let updatedObjectForSurpriseSelfie = {
                    usedSelfie : usedselfie,
                    selfieUsers : selfieuser,
                    selfieTime : firebaseTimestamp.firestore.Timestamp.fromDate(new Date()),
                }
                self.updateEntryBusinessCompany(updatedObjectForSurpriseSelfie);
              }
              else{
                var usedselfie = self.selfieUserData.usedSelfie + 1
                self.usedSelfie = usedselfie
                var selfieuser = [];
                selfieuser = self.selfieUserData.selfieUsers || []
                var dynamicObj = {
                  [self.chatObjectId] : self.chatObject.firstName
                }
                selfieuser.push(dynamicObj)
                let updatedObjectForSurpriseSelfie = {
                    usedSelfie : usedselfie,
                    selfieUsers : selfieuser,
                }
                self.updateEntryBusinessCompany(updatedObjectForSurpriseSelfie);
              }
              if(self.usedSelfie == self.selfieUserData.noOfSelfie){
                try{
                  db.collection(firebaseData.firebasecollection.BUSINESSSELFIENOTIFICATION).add({
                    userId : firebase.auth().currentUser.uid,
                    selfieTime : self.selfieUserData.selfieTime,
                    notificationTime : afterSixHourTime,
                    companyName : Vue.prototype.$companyName.companyName,
                    sent:false
                  }).catch(error => {
                    console.log(error)
                  })
                }catch(error){
                  console.log(error)
                }
              }
        },
        // UPDATE ENTRY IN BUSINESS COMPANY WHEN SURPRISE SELFIE TEXT(Shraddha)
        updateEntryBusinessCompany(updatedObject){
             debugger // eslint-disable-line
             var self = this;
             let selfieUsers = [];
            console.log('updatedObject')
            if(Vue.prototype.$companyName.isAdmin){
                try{
                    db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update(
                        updatedObject
                    ).catch(error => {
                    console.log(error)
                    })
                }catch(error){
                    console.log(error);
                }
            }else{
                try{
                    db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc => {
                        selfieUsers = doc.data().users
                        selfieUsers.forEach(data => {
                            if(data.id == self.selfieUserData.id){
                                data.usedSelfie  = updatedObject.usedSelfie
                                data.selfieUsers = updatedObject.selfieUsers,
                                data.selfieTime = updatedObject.selfieTime ? updatedObject.selfieTime : data.selfieTime
                            }
                        })
                    }).then(() => {
                        console.log('selfieUsers',selfieUsers);
                        db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                            users : selfieUsers
                        }).catch((error) => {
                            console.log(error)
                        })
                    }).catch(error => {
                        console.log(error)
                    })
                }catch(error){
                    console.log(error)
                }
            }
        },
        compareObjects(object1, object2, key) {
            const obj1 = object1[key];
            const obj2 = object2[key];
            if (obj1 < obj2) {
                return -1
            }
            if (obj1 > obj2) {
                return 1
            }
            return 0
        },
        addZero(val)
        {
            if(val<10) {
                return '0'+val;
            } else {
                return val;
            }
        },
        //FOR GET CHAT MESSAGE(Shraddha)
        getChatArray(collection, chatObjectId){
          var self = this;
          self.senderArray = [];
          self.unreadMessages = 0
          if(self.currCollection !== collection) {
            self.image_list = [];
            self.image = [];
            self.preview_list = [];
            self.dropFiles = false;
          }
          self.currCollection = collection 

        //   self.sendreceArray=self.sendreceArrayBKP
          self.sendreceArray=[]
          // Detach previous snapshot listener
          if(self.snapShotListener !== null)
          {
            // console.log("Detach snapshot")
            self.snapShotListener();
          }
          //Set Current collection in store
          self.setCurrentCollection(self.currCollection)
          self.getSetBusinessChatData({companyId:Vue.prototype.$companyName.id,collection:'businessMessages',chatId:chatObjectId})

          self.searchedChat = []
          self.$root.$emit('resetSearchType')
          const db = firebase.firestore()

          var activeBranchName = ''
          if(self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive){
              activeBranchName = self.mainBranches[self.mainBranchActiveIndex].name
          } else if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive){
              activeBranchName = self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name
          } else if(self.mainBranchActive && self.subBranchActive && self.subSubBranchActive) {
              activeBranchName = self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name
          }

          // SENDER AND RECEIVER ARRAY GET(Shraddha)
          self.checkUnreadMessages(self.currCollection,chatObjectId,activeBranchName);
            // self.setCountToZero()
        //   if(collection!='default')
        //   {
        //     self.changeMessageCount(self.chatObjectId,collection)
        //   }
        //   else
        //   {
        //     self.changeDefaultMessageCount(self.chatObjectId,collection)
        //   }
          self.showLoader = true;

          let query;
          let DataSearch = [];
          if(self.searchbaroption == '' || self.searchbaroption == 'All' ) {
              query = db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.storeChatId).collection(collection).orderBy('createdAt','desc').limit(15);
          } else {
              if(self.searchbaroption == 'Links'){
                  DataSearch = ['Link']
              }
              else if(self.searchbaroption == 'Photos'){
                  DataSearch = ['Image']
              }
              else if(self.searchbaroption == 'Videos'){
                  DataSearch = ['Video']
              }
              else if(self.searchbaroption == 'Documents'){
                  DataSearch = ['pdf','excel','word','odt','ods','plain','xd','ppt','html']
              }

              if(DataSearch.length) {
                  query = db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.storeChatId).collection(collection).orderBy('createdAt','desc').where('type','in',DataSearch).limit(15);
              } else {
                  query = db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.storeChatId).collection(collection).orderBy('createdAt','desc').limit(15);
              }
          }

          self.senderArray = [];
          self.senderchatId ='';
          self.snapShotListener = query.onSnapshot({includeMetadataChanges:true},(querySnapshot) => {
              if(self.storeChatId != "" && self.chatObjectId == self.storeChatData.id){
                  if(self.currCollection !='' && self.currCollection != undefined) {
                      if(self.chatObjectId == chatObjectId || self.chatObjectId==undefined){
                          var index = 1;
                          if(querySnapshot.empty) {
                              self.showLoader = false;
                          }
                          self.showLoader=false
                          querySnapshot.docChanges().forEach(change=>{
                              const docdata = change.doc;
                              if(docdata.id){
                                  self.lastVisible = docdata;
                              }

                              if(change.type =='added'){
                                  if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid)
                                  {
                                      if(self.userid == docdata.data().from.id){
                                          self.senderArray.push({
                                              messageType: 'sender',
                                              sendermsg :docdata.data().msg,
                                              mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                              createdAt : docdata.data().createdAt || new Date() ,
                                              Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                                              senderName : docdata.data().from.name,
                                              type:docdata.data().type,
                                              name:docdata.data().name,
                                              msg:docdata.data().msg,
                                              question:docdata.data().questions,
                                              polldata:docdata.data().uploadPollData,
                                              defaultid:docdata.data().id,
                                              messageSeen:docdata.data().messageSeen,
                                              chatId:chatObjectId,
                                              answered:false,
                                              mypollanswer:'',
                                              expireyTimeObjects:{
                                                  date:'',
                                                  month:'',
                                                  year:'',
                                                  hrs:'',
                                                  min:''
                                              },
                                              beforeDate:{
                                                  Datesplit:'',
                                                  monthsplit:'',
                                                  yearsplit:''
                                              },
                                              expired:false,
                                              branch:docdata.data().branch||'',
                                          })
                                      }else{
                                          self.senderArray.push({
                                              messageType: 'receiver',
                                              receivermsg :docdata.data().msg,
                                              mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                              Time: docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : '',
                                              createdAt:docdata.data().createdAt || new Date(),
                                              senderName : docdata.data().from.name,
                                              type:docdata.data().type,
                                              name:docdata.data().name,
                                              msg:docdata.data().msg,
                                              question:docdata.data().questions,
                                              polldata:docdata.data().uploadPollData,
                                              defaultid:docdata.data().id,
                                              messageSeen:docdata.data().messageSeen,
                                              chatId:chatObjectId,
                                              answered:false,
                                              mypollanswer:'',
                                              expireyTimeObjects:{
                                                  date:'',
                                                  month:'',
                                                  year:'',
                                                  hrs:'',
                                                  min:''
                                              },
                                              beforeDate:{
                                                  Datesplit:'',
                                                  monthsplit:'',
                                                  yearsplit:''
                                              },
                                              expired:false,
                                              branch:docdata.data().branch||'',
                                          })

                                          if(!docdata.metadata.hasPendingWrites && self.senderArray[self.senderArray.length-1].defaultid) {
                                              self.senderArray[self.senderArray.length-1].messageSeen.length < self.storeChatData.connectedUser.length ? self.markMessageRead(self.senderArray[self.senderArray.length-1]) : '';
                                          }
                                      }

                                      if(self.senderArray[self.senderArray.length-1].type=="TextPoll" || self.senderArray[self.senderArray.length-1].type=="ImagePoll")
                                      {
                                          self.senderArray[self.senderArray.length-1].polldatapolldata = docdata.data().uploadPollData;
                                          self.chatObjectId!="" ?self.checkPollAnswer2(self.senderArray[self.senderArray.length-1]):''
                                      }

                                      self.senderArray[self.senderArray.length-1].pending = docdata.metadata.hasPendingWrites;
                                  }
                                  if(index == querySnapshot.size)
                                  {
                                      self.showLoader=false
                                  }
                                  index++
                              }
                              if(change.type=='modified') {
                                  // --------------------- If changes are from LOCAL ---------------------
                                  if(docdata.metadata.hasPendingWrites) {
                                      const ind =  self.senderArray.findIndex((x)=>(x.defaultid === undefined && x.msg === docdata.data().msg && x.messageType === 'sender'))
                                      if(ind > -1) {
                                          self.senderArray[ind].pending = false;
                                          self.senderArray[ind].defaultid = docdata.id;
                                          self.senderArray[ind].createdAt = docdata.data().createdAt;
                                          self.senderArray[ind].messageSeen = docdata.data().messageSeen;
                                      }
                                  } else {
                                      // --------------------- If changes are from SERVER ---------------------
                                      if(docdata.data().from.id === self.userid) {
                                          const ind =  self.senderArray.findIndex((x)=>{
                                              // console.log("Condition",x.defaultid === undefined , x.msg === docdata.data().msg , x.messageType === 'receiver')
                                              // console.log("Values",x.defaultid, x.msg,docdata.data().msg, x.messageType)
                                              return (x.defaultid === docdata.id)
                                          });

                                          if(ind > -1 && docdata.data().messageSeen) {
                                              self.senderArray[ind].messageSeen = docdata.data().messageSeen;
                                          }
                                      } else {
                                          const ind =  self.senderArray.findIndex((x)=>{
                                              // console.log("Condition",x.defaultid === undefined , x.msg === docdata.data().msg , x.messageType === 'receiver')
                                              // console.log("Values",x.defaultid, x.msg,docdata.data().msg, x.messageType)
                                              return (x.defaultid === undefined && x.msg === docdata.data().msg && x.messageType === 'receiver')
                                          });

                                          if(ind > -1 && self.senderArray[ind].messageSeen.length < self.storeChatData.connectedUser.length) {
                                              self.senderArray[ind].pending = false;
                                              self.senderArray[ind].defaultid = docdata.id;
                                              self.senderArray[ind].createdAt = docdata.data().createdAt;
                                              self.senderArray[ind].messageSeen = docdata.data().messageSeen;
                                              self.markMessageRead(self.senderArray[ind]);
                                          }
                                      }
                                      if(self.senderArray[self.senderArray.length-1].type=="TextPoll" || self.senderArray[self.senderArray.length-1].type=="ImagePoll")
                                      {
                                          self.senderArray[self.senderArray.length-1].polldata = docdata.data().uploadPollData;
                                          self.senderArray[self.senderArray.length-1].question = docdata.data().questions;
                                          self.chatObjectId!="" ?self.checkPollAnswer2(self.senderArray[self.senderArray.length-1]):''
                                      }
                                  }
                              }
                              if(change.type=='removed') {
                                  let ind = self.senderArray.findIndex((x)=>x.defaultid === docdata.id)
                                  if(ind > -1) {
                                      self.senderArray.splice(ind,1);
                                }

                                ind = self.sendreceArray.findIndex((x)=>x.defaultid === docdata.id)

                                if(ind > -1) {
                                    self.sendreceArray.splice(ind,1);
                                }
                              }
                              if(!docdata.metadata.hasPendingWrites) {
                                if(self.sendreceArray.length && self.unreadMessages > 0) {
                                   self.unreadMessages = 0
                                }
                                //   if(self.sendreceArray.length) {
                                  // console.log("Count "+ self.unreadMessages);
                                //   self.unreadMessages=0;
                                //     if (document.hasFocus()) {
                                //         if(self.currCollection === "default") {
                                //             self.changeDefaultMessageCount(self.chatObjectId,collection);
                                //         } else {
                                //             self.changeMessageCount(self.chatObjectId,collection);
                                //         }
                                //     }
                                //   }
                              }
                          // console.log(`Check Change:\nChange type: ${change.type},\nFrom Cache: ${docdata.metadata.fromCache}\nFrom local: ${docdata.metadata.hasPendingWrites}\nDoc: `,docdata.data())
                          })
                        setTimeout(()=>{
                            self.setCountToZero();
                        }, 1 * 800);
                      }
                  }
                //   self.sendreceArray = self.senderArray;
                //   self.sendreceArray.sort((book1, book2) => {
                //       return self.compareObjects(book1, book2, 'createdAt')
                //   })
                self.senderArray.forEach((data)=>{
                    if(self.sendreceArray.findIndex(x => x.defaultid === data.defaultid) === -1) {
                        self.sendreceArray.push(data)
                    }
                })
                // var ids = new Set(self.sendreceArray.map(d => d.createdAt.seconds));
                // var merged = [...self.sendreceArray, ...self.senderArray.filter(d => !ids.has(d.createdAt.seconds))];
                // self.sendreceArray = merged
                // debugger // eslint-disable-line
                self.sendreceArray.sort((book1, book2) => {
                return self.compareObjects(book1, book2, 'createdAt')
                })
                  self.sendreceArrayBKP = self.sendreceArray;
              }
          })
        },
        markMessageRead(data) {
            var self = this;
            if(self.chatObjectId == ""){
                return;
            }
            if(data.messageType == 'sender') {
                return;
            }

            var answered = false;
            data.beforeTime = data.createdAt ? ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1))) : '';
            data.beforeDate.Datesplit = data.createdAt ? (new Date(data.createdAt.seconds * 1000).getDate()) : '';
            data.beforeDate.monthsplit = data.createdAt ? (new Date(data.createdAt.seconds * 1000).getMonth()) : '';
            data.beforeDate.yearsplit = data.createdAt ? (new Date(data.createdAt.seconds * 1000).getFullYear()) : '';

            if(data.messageSeen.length >= self.storeChatData.connectedUser.length){
                 return;
            }
            var userId = firebase.auth().currentUser.uid;            
            var seen = false;
            var connected  = false;
            var uidObj = '';

            self.connectedUsers.forEach(uids=>{
                if(uids == userId){
                    connected = true;
                    uidObj = uids;
                }
            });

            data.messageSeen.forEach((val)=>{
                if(val.id == userId){
                    seen=true;
                }
            });

            var doReadReceipt = false;
            if(self.userData.SocialreadReceipts || self.activeGroup) {
                 doReadReceipt = true;
            }

            if(!seen && connected && doReadReceipt) {
                if (document.hasFocus()) {
                    self.checkPollAnswer2Data = {}
                    data.messageSeen.push({
                        id:userId,
                        name:self.userData.firstName,
                        avtar:self.userData.profileUrl,
                        messageSeenTime:new Date(),
                    });
                    if(data.defaultid!='')
                    {
                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(data.chatId).collection(self.currCollection).doc(data.defaultid).update({
                            messageSeen: data.messageSeen
                        })
                        .then(()=>{
                        // console.log("Read Receipts added",data.msg);
                        })
                        .catch(error=>{
                            console.error(error.message);
                        })
                    }
                }
            }
        },
        changeMessageCount(docId,collection)
        {
            var self = this;
            var mainBranchesData = self.storeChatData.mainBranches || [];
            mainBranchesData.forEach(data=>{
                if(data.name == collection)
                {
                    data.messageSeenIds.forEach(ids=>{
                        if(ids.id==firebase.auth().currentUser.uid)
                        {
                            ids.IsEmailSend=true
                            ids.count=0
                            ids.time = ''
                        }
                    })
                }
                else
                {
                    data.subBranches.forEach(data2=>{
                        if(data2.name == collection)
                        {
                            data2.messageSeenIds.forEach(ids2=>{
                                if(ids2.id==firebase.auth().currentUser.uid)
                                {
                                    ids2.IsEmailSend=true
                                    ids2.count=0
                                    ids2.time = ''
                                }
                            })
                        }
                        else
                        {
                            data2.subSubBranches.forEach(data3=>{
                                if(data3.name == collection)
                                {
                                    data3.messageSeenIds.forEach(ids3=>{
                                        if(ids3.id==firebase.auth().currentUser.uid)
                                        {
                                            ids3.IsEmailSend=true
                                            ids3.count=0
                                            ids3.time = ''
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(docId).update({
                mainBranches : mainBranchesData
            })
            .then(()=>{
            })
            .catch(error=>{
                console.log(error)
            })
        },
        changeDefaultMessageCount(docId,collection)
        {
            var messageSeenIds = this.storeChatData.messageSeenIds || []
            messageSeenIds.forEach(data=>{
                if(data.id==firebase.auth().currentUser.uid)
                {
                    data.IsEmailSend = false
                    data.count = 0
                    data.time = ''
                }
            })
            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(docId).update({
                messageSeenIds : messageSeenIds
            })
            .then(()=>{
            })
            .catch(error=>{
                console.log(error)
            })
        },
        checkUnreadMessages(collection,chatObjectId,activeBranchName)
        {
            var self = this
            var uid = firebase.auth().currentUser.uid
            if(collection != 'default' && activeBranchName == collection)
            {
                // console.log('Branch checkUnread')
                if(self.chatObjectId == chatObjectId || self.chatObjectId==undefined)
                {
                    self.storeChatData.mainBranches.forEach(data=>{
                        if(data.name == self.currCollection)
                        {
                            data.messageSeenIds.forEach(ids=>{
                                if(ids.id == firebase.auth().currentUser.uid)
                                {
                                    self.unreadMessages=ids.count
                                    // self.changeMessageCount(self.chatObjectId,data.name)
                                }
                            })
                        }
                        else
                        {
                        data.subBranches.forEach(data2=>{
                            if(data2.name == self.currCollection)
                            {
                                data2.messageSeenIds.forEach(ids2=>{
                                    if(ids2.id == firebase.auth().currentUser.uid)
                                    {
                                    self.unreadMessages= ids2.count
                                    
                                    // self.changeDefaultMessageCount(self.chatObjectId,data2.name)
                                    }
                                })
                            }
                            else
                            {
                                data2.subSubBranches.forEach(data3=>{
                                    if(data3.name == self.currCollection)
                                    {
                                        data3.messageSeenIds.forEach(ids3=>{
                                            if(ids3.id == firebase.auth().currentUser.uid)
                                            {
                                                self.unreadMessages= ids3.count
                                                // self.changeDefaultMessageCount(self.chatObjectId,data3.name)
                                            }
                                        })
                                    }
                                })
                            }
                        })
                        }
                    })
                }
            }
            else if(collection=='default' && !self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
            {
                // console.log('Default checkUnread')
                if(self.chatObjectId == chatObjectId || self.chatObjectId==undefined)
                {
                    self.storeChatData.messageSeenIds.forEach(data=>{
                        if(data.id==firebase.auth().currentUser.uid)
                        {
                            self.unreadMessages = data.count
                            // self.changeDefaultMessageCount(self.chatObjectId,collection)
                        }
                    })
                }
            }
        },
        checkPollAnswer2(data)
        {
            var self = this;

            if(self.chatObjectId=="")
            {
              return 0;
            }

            var answered = false;
            data.beforeTime = data.createdAt ? ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1))) : ''
            data.beforeDate.Datesplit = data.createdAt ?(new Date(data.createdAt.seconds * 1000).getDate()) : new Date()
            data.beforeDate.monthsplit = data.createdAt ?(new Date(data.createdAt.seconds * 1000).getMonth()) : new Date()
            data.beforeDate.yearsplit = data.createdAt ?(new Date(data.createdAt.seconds * 1000).getFullYear()) : new Date()
            var userId = firebase.auth().currentUser.uid;

            if(data.type=="TextPoll" || data.type=="ImagePoll")
            {
              var timesplit = data.question.time.split(':')
              var datesplit = data.question.date.split('-')
    
              //console.log('ABCD',timesplit,datesplit)
    
              data.expireyTimeObjects.hrs = timesplit[0]
              data.expireyTimeObjects.min = timesplit[1]
    
              data.expireyTimeObjects.year = datesplit[0]
              data.expireyTimeObjects.month = datesplit[1]
              data.expireyTimeObjects.date = datesplit[2]

              db
                .collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                .doc(self.chatObjectId)
                .collection(self.currCollection)
                .doc(data.defaultid)
                .collection('answer')
                .get().then(querySnapshot=>{
                  if(!querySnapshot.empty)
                  {
                    querySnapshot.forEach(doc=>{
                      if(doc.id==self.userid)
                      {
                        self.mypollanswer = doc.data().ans
                        answered=true
                      }
                    })
                  }
                  if(answered)
                  {
                    data.mypollanswer = self.mypollanswer
                    data.answered=true
                  }
                  else
                  {
                    data.answered=false
                  }
                  data.expired = self.checkExpirey(data.expireyTimeObjects)
                })
                .catch(error=>{console.log(error)})
            }
        },
        //SCROLL METHODS FOR GET MORE MESSAGES(Shraddha)
        async scroollmethod() {
            
            var self = this;
            var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let query;
            const db = firebase.firestore();

            let chatObjectId = self.chatObjectId;

            if(self.searchbaroption == '' || self.searchbaroption == 'All' ){
                // console.log('H1')
                if(self.messageHistory != 'unlimited' && self.messageHistory != '' && self.messageHistory != undefined){
                    if(self.senderArray.length <= Number(self.messageHistory)) {
                        // console.log('111')
                        query = db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                        .doc(chatObjectId)
                        .collection(self.currCollection)
                        .orderBy('createdAt','desc')
                        .startAfter(self.lastVisible)
                        .limit(15);
                    }
                }else{
                    // console.log('222')
                    query = db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                    .doc(chatObjectId)
                    .collection(self.currCollection)
                    .orderBy('createdAt','desc')
                    .startAfter(self.lastVisible)
                    .limit(15);
                }
            }else{
                // console.log('H2')
                var DataSearch = [];
                if(self.searchbaroption == 'Links'){
                    DataSearch = ['Link']
                }
                else if(self.searchbaroption == 'Photos'){
                    DataSearch = ['Image']
                }
                else if(self.searchbaroption == 'Videos'){
                    DataSearch = ['Video']
                }
                else if(self.searchbaroption == 'Documents'){
                    DataSearch = ['pdf','excel','word','odt','ods','plain','xd','ppt','html']
                }

                if(self.messageHistory != 'unlimited' && self.messageHistory != '' && self.messageHistory != undefined){
                    if(self.senderArray.length < Number(self.messageHistory)) {
                        // console.log('333')
                        query = db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                        .doc(chatObjectId)
                        .collection(self.currCollection)
                        .orderBy('createdAt','desc')
                        .startAfter(self.lastVisible)
                        .limit(15);
                    }
                }else{
                    // console.log('444')
                    // SENDER AND RECEIVER ARRAY GET(Shraddha)
                    query = db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                    .doc(chatObjectId)
                    .collection(self.currCollection)
                    .orderBy('createdAt','desc')
                    .startAfter(self.lastVisible)
                    .where('type','in',DataSearch)
                    .limit(15);
                }
            }

            await query.get().then((snap) => {
                self.senderArray = [];
                snap.forEach((docdata) => {
                    self.lastVisible = docdata;
                    if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid){
                        if(self.userid == docdata.data().from.id){
                            self.senderArray.push({
                                messageType: 'sender',
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                sendermsg :docdata.data().msg,
                                createdAt : docdata.data().createdAt || new Date(),
                                Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())),
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                    date:'',
                                    month:'',
                                    year:'',
                                    hrs:'',
                                    min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                },
                                expired:false,
                                branch:docdata.data().branch||'',
                            });
                        }else{
                            self.senderArray.push({
                                messageType: 'receiver',
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                receivermsg :docdata.data().msg,
                                Time : docdata.data().createdAt ?  new Date(docdata.data().createdAt.seconds * 1000).toLocaleTimeString().replace(/:\d+ /, ' ') : '',
                                createdAt:docdata.data().createdAt || new Date(),
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                    date:'',
                                    month:'',
                                    year:'',
                                    hrs:'',
                                    min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                },
                                expired:false,
                                branch:docdata.data().branch||'',
                            });
                        }  
                    }
                })
            });
            await self.senderArray.forEach(async (data) => {
                var answered = false;
                data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                if(data.type=="TextPoll" || data.type=="ImagePoll" )
                {
                    await db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                    .doc(chatObjectId)
                    .collection(self.currCollection)
                    .doc(data.defaultid)
                    .collection('answer')
                    .get().then(querySnapshot=>{
                        if(!querySnapshot.empty)
                        {
                            querySnapshot.forEach(doc=>{
                                if(doc.id==self.userid)
                                {
                                    answered=true
                                }
                            })
                        }
                        if(answered)
                        {
                            data.answered=true
                        }
                        else
                        {
                            data.answered=false
                        }
                    })
                    .catch(error=>{console.log(error)})
                }
                if(data.messageType=='receiver'){
                    var seen = false
                    var connected  = false
                    var userId = firebase.auth().currentUser.uid
                    self.connectedUsers.forEach(uids=>{
                        if(uids==userId)
                        {
                            connected = true
                        }
                    })
                    data.messageSeen.forEach((val)=>{
                        if(val.id == userId)
                        {
                            seen=true
                        }
                    })
                    if(!seen && connected)
                    {
                        data.messageSeen.push({
                            id:self.userid,
                            name:self.userData.firstName,
                            avtar:self.userData.profileUrl,
                            messageSeenTime:new Date(),
                        })
                        await db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                        .doc(chatObjectId)
                        .collection(self.currCollection)
                        .doc(data.defaultid)
                        .update({
                            messageSeen: data.messageSeen
                        })
                        .catch(error=>{
                            console.log(error)
                        })
                    }
                }
            })
            // self.sendreceArray = await self.sendreceArray.concat(self.senderArray);
            // console.log(self.sendreceArray,'###############',self.senderArray)
            // self.sendreceArray.sort((book1, book2) => {
            //     return self.compareObjects(book1, book2, 'createdAt')
            // })
            var ids = new Set(self.sendreceArray.map(d => d.createdAt.seconds));
            var merged = [...self.sendreceArray, ...self.senderArray.filter(d => !ids.has(d.createdAt.seconds))];
            self.sendreceArray = merged
            self.sendreceArray.sort((book1, book2) => {
                return self.compareObjects(book1, book2, 'createdAt')
            })

            self.sendreceArrayBKP=self.sendreceArray
            if(self.searchType == 'All')
            {
                self.sendreceArrayBKP = self.sendreceArray
            }
        },
        // Replace End 
        openDropdown(){
            document.getElementById("myDropdown").classList.toggle("show");
            window.onclick = function(event) {
                if (!event.target.matches('.dropbtn')) {
                    var dropdowns = document.getElementsByClassName("dropdown-content");
                    for (let i = 0; i < dropdowns.length; i++) {
                        var openDropdown = dropdowns[i];
                        if (openDropdown.classList.contains('show')) {
                            openDropdown.classList.remove('show');
                        }
                    }
                }
            }
        },
        //FOR ZOOM IMAGE(Shraddha)
        imageZoom(imagetime){
            var self = this;
            self.imageTimeForZoom = imagetime;
        },
        //FOR DOWNLOAD DOCUMENT(Shraddha)
        downloadDocument(url,name){
            var naming = name.split('.');
            this.$axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download',name);
                document.body.appendChild(fileLink);
                fileLink.click();
            })
            .catch(error=>{
                this.$root.$emit("audioplay");
                this.$notify({
                    title:"Error",
                    text:error.message,
                    type:"error"
                })
            })
        },
        //SEND BULK MESSAGE(Shraddha)
        BulkData(event,msg){
            var self=this;
            self.bulkDataArray = []
            self.BulkMessage = ''
            self.bulkDataArray = event
            self.BulkMessage = msg
            self.sendMessage();
        },
        //SELECT OPTION(Shraddha)
        selectOption(option,defaultid,chatId){
            var self=this;
            if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive) {
                updateAnswerCollection2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,'default',chatId,defaultid,self.userid,option,res=>{
                    selectPollOption2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,'default',chatId,defaultid,res=>{
                        self.uploadData = res.uploadPollData 
                        self.pollquestions = res.questions 
                        var index =  self.uploadData.findIndex(item => {
                            return item.text == option  
                        })
                        self.pollquestions.totalCount = self.pollquestions.totalCount + 1
                        self.uploadData[index].count  =  self.uploadData[index].count + 1
                        updatePollAnswer2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,'default',chatId,defaultid,self.uploadData,self.pollquestions,res=>{
                            self.getChatArray('default',chatId);
                        })
                    })
                })
            } else if(self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive) {
                updateAnswerCollection2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,self.mainBranches[self.mainBranchActiveIndex].name,chatId,defaultid,self.userid,option,res=>{
                    selectPollOption2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,self.mainBranches[self.mainBranchActiveIndex].name,chatId,defaultid,res=>{
                        self.uploadData = res.uploadPollData 
                        self.pollquestions = res.questions 
                        var index =  self.uploadData.findIndex(item => {
                            return item.text == option  
                        })
                        self.pollquestions.totalCount = self.pollquestions.totalCount + 1
                        self.uploadData[index].count  =  self.uploadData[index].count + 1
                        updatePollAnswer2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,self.mainBranches[self.mainBranchActiveIndex].name,chatId,defaultid,self.uploadData,self.pollquestions,res=>{
                            self.getChatArray(self.mainBranches[self.mainBranchActiveIndex].name,chatId);
                        })
                    })
                })
            } else if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive) {
                updateAnswerCollection2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name,chatId,defaultid,self.userid,option,res=>{
                    selectPollOption2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name,chatId,defaultid,res=>{
                        self.uploadData = res.uploadPollData 
                        self.pollquestions = res.questions 
                        var index =  self.uploadData.findIndex(item => {
                            return item.text == option  
                        })
                        self.pollquestions.totalCount = self.pollquestions.totalCount + 1
                        self.uploadData[index].count  =  self.uploadData[index].count + 1
                        updatePollAnswer2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name,chatId,defaultid,self.uploadData,self.pollquestions,res=>{
                            self.getChatArray(self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name,chatId);
                        })
                    })
                })
            } else if(self.mainBranchActive && self.subBranchActive && self.subSubBranchActive) {
                updateAnswerCollection2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,chatId,defaultid,self.userid,option,res=>{
                    selectPollOption2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,chatId,defaultid,res=>{
                        self.uploadData = res.uploadPollData 
                        self.pollquestions = res.questions 
                        var index =  self.uploadData.findIndex(item => {
                            return item.text == option  
                        })
                        self.pollquestions.totalCount = self.pollquestions.totalCount + 1
                        self.uploadData[index].count  =  self.uploadData[index].count + 1
                        updatePollAnswer2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,chatId,defaultid,self.uploadData,self.pollquestions,res=>{
                            self.getChatArray(self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,chatId);
                        })
                    })
                })
            }
        },
        // SEARCH IN PERTICULAR CHAT(Shraddha)
        updatesearch(event,value){
            var self = this;
            self.searchbaroption = value
            self.searchkeyword = event
            self.getChatArray(self.currCollection,self.chatObjectId)
        },
        //COPY MESSAGE(Shraddha)
        copymessage(copymessage){
            var copyText = copymessage  
            var input = document.createElement("textarea");
            input.value = copyText;
            document.body.appendChild(input);
            input.select();
            document.execCommand("Copy");
            input.remove();
        },
        //READ RECIPT (Shraddha)
        readrecipt(sendermsg){
            var self = this;
            self.$root.$emit('openSidebarForReadRecipt',sendermsg);
        },
        //FOR ACTIVE SOCIAL MENU(Shraddha)
        SocialMenuActiveTab(){
            var self=this;
            self.activeChat = false
            self.businessMenuActive = false
            self.setStoreChatId('')
            db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).update({
                userRole : 'Social'
            })
            self.$root.$emit("socialMenuActive", self.businessMenuActive,self.activeChat)
            self.selectedActiveMenu = 'activeList'
            self.$router.push('/chat');
        },
        //FOR ACTIVE BUSINESS MENU(Shraddha)
        businessMenuActiveTab(){
            var self=this;
            self.businessMenuActive = true;
            self.activeChat = false
            self.$root.$emit("businessMenuActive", self.businessMenuActive,self.activeChat)
            self.selectedActiveMenu = 'admin'
        },
        //MESSAGE DELETE (Shraddha)
        messageDelete(chatData){
            var self = this;
            if(chatData.type == "SurpriseSelfieText") {
                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatData.chatId).collection(self.currCollection).doc(chatData.defaultid).get().then(docData => {
                if(docData.data().surpriseSelfieUsers.length > 1){
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    Swal.fire({
                        title: 'Are you sure you want to delete message?',
                        type: "warning",
                        customClass:{
                            container :"business_confirmation_popup"
                        },
                        showCancelButton: true,
                        confirmButtonColor: "#ffab3f",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                        if (result.value) {
                            var self = this;
                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatData.chatId).collection(self.currCollection).doc(chatData.defaultid).delete().then(() => {
                                self.$root.$emit("audioplay");
                                self.$notify({
                                    title:"Success",
                                    text:"Message delete successfully.",
                                    type:"success"
                                })
                            }) 
                        }
                    })
                }else {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    Swal.fire({
                        title: 'Are you sure you want to delete message?',
                        type: "warning",
                        customClass:{
                            container :"business_confirmation_popup"
                        },
                        showCancelButton: true,
                        confirmButtonColor: "#ffab3f",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                        if (result.value) {
                            var self = this;
                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatData.chatId).collection(self.currCollection).doc(chatData.defaultid).delete().then(() => {
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Success",
                                text:"Message delete successfully.",
                                type:"success"
                            })
                            if(!Vue.prototype.$companyName.isAdmin){
                            db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(docs => {
                                var users = docs.data().users
                                users.forEach(data => {
                                if(data.id == firebase.auth().currentUser.uid){
                                    if(data.usedSelfie != 0){
                                        data.usedSelfie--
                                    }
                                    var txt = "";
                                    data.selfieUsers.forEach((data,index) => {
                                        for (let x in data) {
                                        if(x == self.chatObjectId){
                                            txt = index;
                                        }
                                        }
                                    })
                                    data.selfieUsers.splice(txt,1)
                                }
                                })
                                db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                                users:users
                                }).then(() => {
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatData.chatId).collection(self.currCollection).doc(chatData.defaultid).update({
                                    surpriseSelfieUsers:[]
                                })
                                })
                            })
                            }else{
                                db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(docs => {
                                    var adminData = docs.data() 
                                    if(adminData.usedSelfie != 0){
                                        adminData.usedSelfie--
                                    }
                                    var txt = "";
                                    adminData.selfieUsers.forEach((data,index) => {
                                        for (let x in data) {
                                            if(x == self.chatObjectId){
                                                txt = index;
                                            }
                                        }
                                    })
                                    adminData.selfieUsers.splice(txt,1)
                                    db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                                        usedSelfie : adminData.usedSelfie,
                                        selfieUsers : adminData.selfieUsers
                                    })
                                })
                            }
                            }) 
                        }
                        })
                    }
                })
            }else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                });
                Swal.fire({
                    title: 'Are you sure you want to delete message?',
                    type: "warning",
                    customClass:{
                        container :"business_confirmation_popup"
                    },
                    showCancelButton: true,
                    confirmButtonColor: "#ffab3f",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                    if (result.value) {
                    var self = this;
                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatData.chatId).collection(self.currCollection).doc(chatData.defaultid).delete().then(() => {
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"Success",
                            text:"Message delete successfully.",
                            type:"success"
                        })
                    }) 
                    }
                })
            }
        },
        // Delete Branch (Common Code) - Shiv
        async handleDeleteBranch(mainbranch, BranchName, type){
            var self = this;
            self.selectDropdown=false;
            self.selectSubDropdown=false;
            self.selectSubSubDropdown=false;
            self.selectMainBranchActiveDropdown=false;
            self.selectSubBranchActiveDropdown=false;
            self.selectSubSubBranchActiveDropdown=false;
            const docId = self.chatObjectId;
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });

            let newActiveBranch = BranchName

            Swal.fire({
                title: `Are you sure you want to delete ${BranchName} branch?`,
                type: "warning",
                customClass:{
                    container :"social_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: "#ffab3f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.value) {
                    let mainBranches = mainbranch;

                    let level = "";
                    let deleteArr = [];

                    mainBranches.forEach((mb,mbIndex)=>{
                        if(mb.name === BranchName) {
                            if(self.mainBranchActive && self.mainBranchActiveIndex === mbIndex){
                                newActiveBranch = "default";
                                self.mainBranchActive = false;
                                self.mainBranchActiveIndex = 0;
                                self.subBranchActive = false;
                                self.subBranchActiveIndex = 0;
                                self.subSubBranchActive = false;
                                self.subSubBranchActiveIndex = 0;
                            }

                            level = "mb";
                            deleteArr = mb.subBranches;

                            mainBranches.splice(mbIndex,1);
                        } else {
                            mb.subBranches.forEach((sb,sbIndex)=>{
                                if(sb.name === BranchName) {
                                    if(self.subBranchActive && self.subBranchActiveIndex === sbIndex){
                                        newActiveBranch = mb.name;
                                        self.subBranchActive = false;
                                        self.subBranchActiveIndex = 0;
                                        self.subSubBranchActive = false;
                                        self.subSubBranchActiveIndex = 0;
                                    }
                                    level = "sb";
                                    deleteArr = sb.subSubBranches;
                                    mb.subBranches.splice(sbIndex,1);
                                } else {
                                    sb.subSubBranches.forEach((ssb,ssbIndex)=>{
                                        if(ssb.name === BranchName) {
                                            if(self.subSubBranchActive && self.subSubBranchActiveIndex === ssbIndex){
                                                newActiveBranch = sb.name;
                                                self.subSubBranchActive = false;
                                                self.subSubBranchActiveIndex = 0;
                                            }
                                            level = "ssb";
                                            deleteArr = [];
                                            sb.subSubBranches.splice(ssbIndex,1);
                                        }
                                    })
                                }
                            })
                        }
                    })
                    await db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(docId).update({
                        mainBranches: mainBranches
                    }).then(()=>{
                        if(self.currCollection === BranchName) {
                            self.getChatArray(newActiveBranch, docId);
                        }
                        if(level === "mb") {
                            self.deleteChat(BranchName,docId);
                            deleteArr.forEach(vals=>{
                                self.deleteChat(vals.name,docId);
                                if(vals.subSubBranches.length) {
                                    vals.subSubBranches.forEach(ssb=>{
                                        self.deleteChat(ssb.name,docId);
                                    })
                                }
                            })
                        } else if(level === "sb") {
                            self.deleteChat(BranchName,docId);
                            deleteArr.forEach(vals=>{
                                self.deleteChat(vals.name,docId);
                            })
                        } else if(level === "ssb") {
                            self.deleteChat(BranchName,docId);
                        }

                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"Success",
                            text:"Branch delete successfully.",
                            type:"success"
                        });
                    })
                    .catch(error=>{
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title: "Error",
                            text: error.message,
                            type: "error"
                        });
                    })
                }
            })
        },
        // Delete chat of deleted branch (Common Code) - Shiv
        async deleteChat(BranchName, docId) {
            var self = this;
            await db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(docId).collection(BranchName).get().then(querySnapshot=>{
                // Check if any message in the branch.
                if(!querySnapshot.empty){
                    // Delete messages if found.
                    querySnapshot.forEach(async (doc)=>{
                        await db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(docId).collection(BranchName).doc(doc.id).delete()
                    })
                }
                // Change selfie count.
                self.SelfieBranchDelete(BranchName);
            })
            .catch(error=>{
                self.$root.$emit("audioplay");
                self.$notify({
                    title: "Error",
                    text: error.message,
                    type: "error"
                });
            })
        },
        SelfieBranchDelete(BranchName){
            var self = this;
            var splitArray = [];
            var splitArrays = [];
            var adminSplitArray = [];
            if(self.chatObject.id != undefined){
                if(Vue.prototype.$companyName.isAdmin){
                    self.selfieUserData.selfieUsers.forEach(data => {
                        for (let x in data) {
                            if(x != self.chatObjectId && data[x] != BranchName){
                                splitArray.push(data);
                            }
                        }
                    })
                    db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc => {
                        var users = doc.data().users;
                        users.forEach(userData =>{
                            if(userData.id == self.chatObject.id){
                                var selfieUsers = userData.selfieUsers
                                selfieUsers.forEach(data => {
                                    for (let x in data) {
                                        if(x != self.chatObjectId && data[x] != BranchName){
                                            splitArrays.push(data);
                                        }
                                    }
                                }) 
                                userData.selfieUsers = splitArrays;
                                userData.usedSelfie = userData.usedSelfie - 1;
                            }
                        })
                        db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                            selfieUsers : splitArray,
                            users : users,
                            usedSelfie:self.selfieUserData.usedSelfie -1
                        })
                    })
                }else{
                    db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc => {
                        var users = doc.data().users;
                        users.forEach(userData =>{
                            if(userData.id == firebase.auth().currentUser.uid){
                                var selfieUsers = userData.selfieUsers
                                selfieUsers.forEach(data => {
                                    for (let x in data) {
                                        if(x != self.chatObjectId && data[x] != BranchName){
                                            splitArray.push(data);
                                        }
                                    }
                                }) 
                                userData.selfieUsers = splitArray;
                                userData.usedSelfie = userData.usedSelfie - 1;
                            }
                            if(doc.data().companyOwnerId == self.chatObject.id){
                                adminSplitArray = [];
                                var selfieUserss = doc.data().selfieUsers
                                selfieUserss.forEach(datas => {
                                    for (let x in datas) {
                                        if(x != self.chatObjectId && datas[x] != BranchName){
                                            adminSplitArray.push(datas);
                                        }
                                    }
                                })
                                db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                                    selfieUsers : adminSplitArray,
                                    usedSelfie:self.selfieUserData.usedSelfie -1
                                })
                            }
                            else{
                                if(userData.id == self.chatObject.id){
                                    var selfieUsers = userData.selfieUsers
                                    selfieUsers.forEach(data => {
                                        for (let x in data) {
                                            if(x != self.chatObjectId && data[x] != BranchName){
                                                splitArrays.push(data);
                                            }
                                        }
                                    }) 
                                    userData.selfieUsers = splitArrays;
                                    userData.usedSelfie = userData.usedSelfie - 1;
                                }
                            }
                        })
                        db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                            users: users
                        })
                    })
                }
            }
        },
        // COUNT ALL BRANCH (Shraddha)
        countAllbranch(branchData, branchString){
            var self = this
            var mainCount = 0; 
            var subCount = 0;
            var subSubCount = 0;
            var uid = firebase.auth().currentUser.uid
            if(branchString == 'mainbranch'){
                if(branchData.name != self.currCollection){
                    branchData.messageSeenIds.forEach(ids=>{
                        if(ids != 0){
                            if(ids.id == firebase.auth().currentUser.uid){
                                mainCount+=ids.count
                            }
                        }
                    })
                }
                if(branchData.subBranches){
                    branchData.subBranches.forEach(data2=>{
                        if(data2.name != self.currCollection){
                            data2.messageSeenIds.forEach(ids2=>{
                                if(ids2 != 0){
                                    if(ids2.id == firebase.auth().currentUser.uid){
                                        subCount += ids2.count
                                    }
                                }
                            })
                        }
                        if(data2.subSubBranches){
                            data2.subSubBranches.forEach(data3=>{
                                if(data3.name != self.currCollection){
                                    data3.messageSeenIds.forEach(ids3=>{
                                        if(ids3 != 0){
                                            if(ids3.id == firebase.auth().currentUser.uid){
                                                subSubCount += ids3.count
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            } else if(branchString == 'subbranch'){
                if(branchData.name != self.currCollection){
                    branchData.messageSeenIds.forEach(ids=>{
                        if(ids != 0){
                            if(ids.id == firebase.auth().currentUser.uid)
                            {
                                subCount += ids.count
                            }
                        }
                    })
                }
                if(branchData.subSubBranches){
                    branchData.subSubBranches.forEach(data3=>{
                        if(data3.name != self.currCollection){
                            data3.messageSeenIds.forEach(ids3=>{
                                if(ids3 != 0){
                                    if(ids3.id == firebase.auth().currentUser.uid)
                                    {
                                        subSubCount+= ids3.count
                                    }
                                }
                            })
                        }
                    })
                }
            } else if (branchString == 'subsubbranch'){
                if(branchData.name != self.currCollection){
                    branchData.messageSeenIds.forEach(ids=>{
                        if(ids != 0){
                            if(ids.id == firebase.auth().currentUser.uid)
                            {
                                subSubCount+=ids.count
                            }
                        }
                    })
                }
            }
            if(branchString == 'mainbranch'){
                return mainCount + subCount + subSubCount;
            } else if(branchString == 'subbranch'){
                return subCount + subSubCount;
            } else if (branchString == 'subsubbranch'){
                return subSubCount;
            }
        },

        setCountToZero() {
            var self = this;

            // if(self.unreadMessages === 0) {
              // console.log("NO NEW MESSAGES!");
              // return;
            // }

            self.sendreceArray.forEach((data)=>{
              if(Object.keys(self.storeChatData).length) {
                if(data.messageSeen.length < self.storeChatData.connectedUser.length && data.defaultid !== "" && data.defaultid !== undefined) {
                  self.markMessageRead(data);
                }
              }
            })

            let collectionName = self.currCollection;
            let mainBranches = self.storeChatData.mainBranches;
            let branchCount = self.storeChatData.branchCount || {};

            // let query = db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.chatObjectId);

            // if(mainBranches.length && !Object.keys(branchCount).length) {
            //   self.addCountObj(collectionName, mainBranches, branchCount);
            //   return;
            // }

            if(collectionName === "default" && self.chatObjectId && collectionName) {
              self.changeDefaultMessageCount(self.chatObjectId,collectionName);
            } else {
              self.changeMessageCount(self.chatObjectId,collectionName);
              // self.updateCountObject(collectionName, mainBranches, 0);
            }
        }
    },
    computed: {
        companyValue(){
            this.$companyName = this.companyName
        },
        ...mapGetters({companiesFromDB:"getCompanies2"}),
        ...mapGetters({storeSelectedCompany:"getSelectedCompany"}),
        ...mapGetters({newMessageInSocial:"getNewMessageInSocial"}),
        ...mapGetters({newMessageInBusiness:"getNewMessageInBusiness"}),
        ...mapGetters({storeChatData:"getChatData"}),
        ...mapGetters({storeChatId:"getChatId"}),
        ...mapGetters({newMessageInDirectMessage:"getNewDirectMessage"}),
        ...mapGetters({newMessageIngroup:"getNewMessageInGroup"}),
    },
    mounted() {
        var self = this;

        // clearInterval(self.messageInterval);
        self.messageInterval = setInterval(() => {
            if(self.messageCount > 0) {
                self.updateCount({...self.lastMessageData}, self.messageCount);
            }
        },1 * 500) //sec * mls

        window.addEventListener("focus",(e)=>{
            self.sendreceArray.forEach((data)=>{
                if(Object.keys(self.storeChatData).length) {
                    if(data.messageSeen.length < self.storeChatData.connectedUser.length && data.defaultid !== "" && data.defaultid !== undefined) {
                        self.markMessageRead(data);
                    }
                }
            })

            if(self.currCollection === "default" && self.chatObjectId && self.currCollection) {
              self.changeDefaultMessageCount(self.chatObjectId,self.currCollection);
            } else if(self.currCollection !== "default" && self.chatObjectId && self.currCollection){
              self.changeMessageCount(self.chatObjectId,self.currCollection);
            }
        })

        window.addEventListener("dragenter", function (e) {
            document.querySelector("#dropzone").style.visibility = "";
            document.querySelector("#dropzone").style.opacity = 1;
            document.querySelector("#textnode").style.fontSize = "48px";
        });
        window.addEventListener("dragleave", function (e) {
            e.preventDefault();
            document.querySelector("#dropzone").style.visibility = "hidden";
            document.querySelector("#dropzone").style.opacity = 0;
            document.querySelector("#textnode").style.fontSize = "42px";
            
        });
        window.addEventListener("dragover", function (e) {
            e.preventDefault();
            document.querySelector("#dropzone").style.visibility = "";
            document.querySelector("#dropzone").style.opacity = 1;
            document.querySelector("#textnode").style.fontSize = "48px";
        });
        window.addEventListener("drop", function (e) {
            e.preventDefault();
            document.querySelector("#dropzone").style.visibility = "hidden";
            document.querySelector("#dropzone").style.opacity = 0;
            document.querySelector("#textnode").style.fontSize = "42px";
            var files = e.dataTransfer.files;
            self.uploadFiles(files,e);
        });
    }
};
</script>
<style>
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right: 89px;
  bottom: 89px;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown a:hover {background-color: #ddd;}
.show {display: block;}
.highlight-msg{
  background-color: yellow
}
.messageNotSeen{
  background-color: #d3ffdb;
  position: relative;
  border-top-left-radius: 0;
  padding: 6px 0px;
  text-align: center;
  border-radius: 12px;
  font-weight: bold;
}
.userLeftMessage{
  margin:15px 0px;
  padding: 6px 0px;
  text-align: center;
  border-radius: 12px;
  font-weight: bold;
  color: white;
}
.userLeftMessage span{
  background-color: #7b7b7b;
  border-top-left-radius: 0;
  padding: 3px 10px;
  text-align: center;
  border-radius: 12px;
  font-weight: bold;
  color: white;
}
.stopRecordBtn{
  height: 45px;
  margin: 5px 5px;
  background-color: coral;
  color: white;
}
.greenDotNewMessage{
  background-color: #85DA27;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  vertical-align: middle;
  margin-left: 10px;
}
</style>