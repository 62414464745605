<template>
<div>
<div class="content_management_wrapper content-table" v-if="isCustomerView == false">
     <div class="admin_Page_title" v-if="!dashboard">
         <h4>User Management</h4> 
         <img class="title_icon" @click="backtodashboard()" src="@/assets/images/home_icon.png" alt="homeIcon">
         <span class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
         <span class="current_page">User Management</span>
     </div>
    <div class="Page_table_serch_area">
        <form class="search_table" @submit.prevent>
            <div class="form-group">
                <label>Search</label>
                <input type="search" placeholder="Search here" class="form-control search" id="myInput" v-model="keyword" @keyup="searchFilter()" >
                <img class="search_icon" src="@/assets/images/search.png">
            </div>
           
            <div class="filter-admin">
            <div class="form-group mr-40">
                <label>SocialPlan</label>
                <select class="form-control arrow_side" v-model="selectedFilterSocialPlan" @change="filterByuserType()">
                    <!-- <option selected>Select</option> -->
                    <option value="" hidden disabled>Select</option>
                    <option>All</option>
                    <option>Core</option>
                    <option>Pro</option>
                </select>
            </div>
            <div class="form-group mr-40">
                <label>BusinessPlan</label>
                <select class="form-control arrow_side" v-model="selectedFilterBusinessPlan" @change="filterByuserType()">
                    <!-- <option selected>Select</option> -->
                    <option value="" hidden disabled>Select</option>
                    <option>All</option>
                    <option>Start Up</option>
                    <option>Pro</option>
                    <option>Bolt On</option>
                </select>
            </div>
            <div class="form-group">
                 <label>Filter by status</label>
                <select class="form-control arrow_side" v-model="selectedFilterOption" @change="filterByuserType()">
                    <!-- <option selected>Select</option> -->
                    <option value="" hidden disabled>Select</option>
                    <option>All</option>
                    <option>Active</option> 
                    <option>Inactive</option>
                </select>
            </div>
            </div>
        </form>
    </div>
    <div class="table_wrapper">
        <table class="table" id="myTable">
            <thead>
                <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Social Plan</th>
                    <th>Business Plan</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <!-- {{tableListData}} -->
                <!-- {{customerListing}} -->
                <tr v-for="(rawData, indextr) in  tableListData[currentPage]" v-bind:key="indextr">
                    <td>{{(currentPage*10)+(indextr+1) &lt; 10 ?'0'+(indextr+1):(currentPage*10)+(indextr+1)}}</td>
                    <td v-if="rawData.profileUrl"><div class="user_name"><img :src= rawData.profileUrl><span>{{rawData.firstName}} {{rawData.lastName}}</span></div></td>
                    <td v-else><div class="user_name"><img src="@/assets/images/blank-profile-picture.jpg"><span>{{rawData.firstName}} {{rawData.lastName}}</span></div></td>
                    <!-- <td :data="rawData.userType">{{rawData.userType}}</td> -->
                    <!-- <td>{{rawData.socialPlanName||''}}</td> -->
                    <td :data="rawData.socialPlan">{{rawData.socialPlan}}</td>
                    <td v-if="rawData.businessPlan.length > 0" :data="rawData.businessPlan">{{rawData.businessPlan.join(',')}}</td>
                    <td v-else>-</td>
                    <td :data="rawData.email">{{rawData.email}}</td>
                    <td :data="rawData.phoneNo">{{rawData.phoneNo}}</td>
                    <td v-if="rawData.isDeleted"><strong class="inactive">Deactive</strong></td>
                    <td v-else><strong :class="rawData.userStatus=='Active'?'active':'inactive'">{{rawData.userStatus}}</strong><button v-if="rawData.userStatus == 'Active'" class="btn btn-yellow" @click="IsUserBlock(rawData)">Block</button><button v-else class="btn btn-yellow" @click="IsUserBlock(rawData)">Unblock</button></td>
                    <td><span @click="getCustomerData(rawData.id)" class="display-normal-eye"><img class="display_eye" alt="eye" src="@/assets/images/eye_hover.png"> <img alt="eye" class="display_normal_eye" src="@/assets/images/normal_eye.png"></span></td>
                </tr>
             </tbody>
        </table>
            <!-- <div style="text-align: center" v-if="tableListData.length==0">
                No Data Found
            </div> -->
            
            <span class="no-result-found" v-if="noUserMessage != '' && tableListData.length == 0">{{noUserMessage}}</span>
            <!-- <span class="no-result-found">ABCD{{noUserMessage}}</span> -->
    </div>
    <div class="pagination_wrapper" v-if="tableListData.length>1">
                <ul class="pagination">
                    <li @click="(changePage('prev'))"><a><img src="@/assets/images/left-arrow-pagination.png"></a></li>
                    <li v-for="(data,index) in totalpages" :key="index" @click="currentPage=index" :class="currentPage==index?'active':''">
                        <a>{{data}}</a>
                    </li>
                    <li @click="(changePage('next'))"><a><img src="@/assets/images/right-arrow-pagination.png"></a></li>
                </ul>
            </div>
    <!-- <FrontFooter /> -->
</div>
<div v-if="isCustomerView == true">
    <ContentManagmentDetail :rowId=rowId :isCustomerView=isCustomerView @UpdateList="OnClickUpdateList"></ContentManagmentDetail>
</div>
</div>
</template>
<script>
import firebase from "../firebaseInit";
import firebaseData from '../../utils/firebaseCollection.js';
import FrontFooter from "@/components/forntfooter.vue";
import ContentManagmentDetail from "@/views/Admin_Panel/ContentManagementDetail.vue";
import {getContactManagmentData,getContactManagment} from "@/utils/getQueeryActiveChatList.js";
import Swal from 'sweetalert2'
const db = firebase.firestore()
export default({
    name:'ContentManagement',
    components: {
      firebaseData,
      FrontFooter,
      ContentManagmentDetail
    },
    props: [
        'dashboard'
    ],
    data(){
        return{
            months:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            customerListing:[],
            keyword:'',
            isCustomerView:false,
            rowId:'',
            totalpages:0,
            currentPage:0,
            tableData:[],
            UserStatus:'',
            tableListData:[],
            searchFilterData : false,
            noUserMessage: "",
            dateFilter:'',
            filterType:'',
            selectedFilterSocialPlan:'All',
            selectedFilterBusinessPlan:'All',
            selectedFilterOption:'All',
            filerData:[],
            totalWeek:[],
            DatesArray:[],
            allDateArray:[],
            companies:[]
        }
    },
   
    created(){
         //debugger // eslint-disable-line
        var self=this;
        self.contentPage = true
        self.$root.$on("CustomerView",(value)=>{
            self.isCustomerView = value
        });
        self.$root.$on("isUserDetailView",(value) => {
           self.isCustomerView = value
           self.init()
        })
        if(self.dashboard){
            self.$root.$on('filterUsersByDateDashboard',val=>{
                // console.log('vallllll',val)
                // debugger // eslint-disable-line
                this.selectedFilterSocialPlan='All',
                this.selectedFilterBusinessPlan='All',
                this.selectedFilterOption='All'
                var tmp = self.customerListing
                var today = new Date().getDate()
                var month = new Date().getMonth()
                var year = new Date().getFullYear()
                var arrVal = '';
                self.tableListData = []
                var index = 1
                var dataArrays=[]
                var arr=[]
                self.filerData = [];

                self.currentPage=0
                
                if(val=='today')
                {
                    self.filerData = []
                    self.customerListing.forEach(data=>{
                        if(new Date(data.createdAt.seconds*1000).getDate() == today && new Date(data.createdAt.seconds*1000).getMonth() == month && new Date(data.createdAt.seconds*1000).getFullYear() == year)
                        {
                            // self.tableListData.push(data)
                            arr.push(data)
                            self.filerData.push(data)
                        }

                    
                        var totalArrayLength = '';
                        self.totalpages = '';
                        totalArrayLength = arr.length
                        for (let index1 = 0; index1 < totalArrayLength; index1++) {
                            if((totalArrayLength/10)<=index1)
                            {
                                self.totalpages=index1
                            //console.log(self.totalpages , totalArrayLength)
                                break;
                            }
                        }
                        var index = 1
                        var dataArrays=[]
                        self.tableData=[]
                        //console.log('self.customerListing',self.customerListing,arr)
                        arr.forEach(data=>{
                            dataArrays.push(data)
                            if(index % 10 == 0)
                            {
                                self.tableData.push(dataArrays)
                                dataArrays=[];
                            }
                            else if(index ==arr.length)
                            {
                                self.tableData.push(dataArrays)
                                dataArrays=[];
                            }

                            index++;
                        })
                        self.tableListData = self.tableData
                        //console.log(' self.tableData',  self.tableListData)
                        if(self.tableListData.length == 0){
                            self.noUserMessage = 'No User Found';
                        }else{
                            self.noUserMessage = '';
                        }
                    
                    })
                    
                    self.tableListData = self.tableData
                }
                if(val=='week')
                {
                    // debugger // eslint-disable-line
                    self.filerData = []
                    self.totalWeek = [];
                    var startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay()+1).getDate()

                    // var startDate = new Date(year,month, today - new Date().getDay()+1).getDate()
                    // console.log(startDate)
                    for (let i = 0; i < 7; i++) {
                    //   var arrVal = `${new Date(new Date().getFullYear(), new Date().getMonth(),startDate+i).getDate()} ${self.months[new Date(new Date().getFullYear(), new Date().getMonth(),startDate+i).getMonth()]}`;
                        arrVal = `${self.addZero(new Date(new Date().getFullYear(), new Date().getMonth(),startDate+i).getDate())} ${self.months[new Date(new Date().getFullYear(), new Date().getMonth(),startDate+i).getMonth()]}`
                        self.totalWeek.push(arrVal)
                        //  console.log('dashboard week',self.totalWeek,startDate)
                        var compareDate =self.totalWeek[i].split(' ')
                        self.customerListing.forEach(data=>{
                            if(new Date(data.createdAt.seconds*1000).getMonth()+1 == month+1 && new Date(data.createdAt.seconds*1000).getFullYear() == year)
                            {
                                if(new Date(data.createdAt.seconds*1000).getDate() == Number(compareDate[0]) && new Date(data.createdAt.seconds*1000).getDate()<=today)
                                {
                                    var found = false;
                                    if(self.filerData.length == 0){
                                        arr.push(data)
                                        self.filerData.push(data)
                                    }else{
                                        self.filerData.forEach(datas => {
                                            if(datas.id == data.id){
                                                found = true
                                            }
                                        })
                                        if(!found){
                                            arr.push(data)
                                            self.filerData.push(data) 
                                        }
                                    }
                                }
                            }
                            var totalArrayLength = '';
                            self.totalpages = '';
                            totalArrayLength = arr.length
                            for (let index1 = 0; index1 < totalArrayLength; index1++) {
                                if((totalArrayLength/10)<=index1)
                                {
                                    self.totalpages=index1
                                //console.log(self.totalpages , totalArrayLength)
                                    break;
                                }
                            }
                            var index = 1
                            var dataArrays=[]
                            self.tableData=[]
                            //console.log('self.customerListing',self.customerListing,arr)
                        arr.forEach(data=>{
                            dataArrays.push(data)
                            if(index % 10 == 0)
                            {
                                self.tableData.push(dataArrays)
                                dataArrays=[];
                            }
                            else if(index ==arr.length)
                            {
                                self.tableData.push(dataArrays)
                                dataArrays=[];
                            }

                            index++;
                        })
                        self.tableListData = self.tableData
                        //console.log(' self.tableData',  self.tableListData)
                        if(self.tableListData.length == 0){
                            self.noUserMessage = 'No User Found';
                        }else{
                            self.noUserMessage = '';
                        }
                    
                    })
                    
                    }
                self.tableListData = self.tableData
                
                }
                else if(val=='month')
                {
                    self.filerData = []
                    self.customerListing.forEach(data=>{
                        if(new Date(data.createdAt.seconds*1000).getMonth() == month && new Date(data.createdAt.seconds*1000).getFullYear() == year)
                        {
                            // self.tableListData.push(data)
                            arr.push(data)
                            self.filerData.push(data)
                        }
                    
                        var totalArrayLength = '';
                        self.totalpages = '';
                        totalArrayLength = arr.length
                        for (let index1 = 0; index1 < totalArrayLength; index1++) {
                            if((totalArrayLength/10)<=index1)
                            {
                                self.totalpages=index1
                            //console.log(self.totalpages , totalArrayLength)
                                break;
                            }
                        }
                            var index = 1
                            var dataArrays=[]
                            self.tableData=[]
                            //console.log('self.customerListing',self.customerListing,arr)
                        arr.forEach(data=>{
                            dataArrays.push(data)
                            if(index % 10 == 0)
                            {
                                self.tableData.push(dataArrays)
                                dataArrays=[];
                            }
                            else if(index ==arr.length)
                            {
                                self.tableData.push(dataArrays)
                                dataArrays=[];
                            }

                            index++;
                        })
                        self.tableListData = self.tableData
                        //console.log(' self.tableData',  self.tableListData)
                        if(self.tableListData.length == 0){
                            self.noUserMessage = 'No User Found';
                        }else{
                            self.noUserMessage = '';
                        }
                    
                    })
                    
                    self.tableListData = self.tableData
                }
                else if(val=='year')
                {
                    self.filerData = []
                    self.customerListing.forEach(data=>{
                        if(new Date(data.createdAt.seconds*1000).getFullYear() == year)
                        {
                            // self.tableListData.push(data)
                            arr.push(data)
                            self.filerData.push(data)
                        }
                    
                        var totalArrayLength = '';
                        self.totalpages = '';
                        totalArrayLength = arr.length
                        for (let index1 = 0; index1 < totalArrayLength; index1++) {
                            if((totalArrayLength/10)<=index1)
                            {
                                self.totalpages=index1
                            //console.log(self.totalpages , totalArrayLength)
                                break;
                            }
                        }
                            var index = 1
                            var dataArrays=[]
                            self.tableData=[]
                            //console.log('self.customerListing',self.customerListing,arr)
                        arr.forEach(data=>{
                            dataArrays.push(data)
                            if(index % 10 == 0)
                            {
                                self.tableData.push(dataArrays)
                                dataArrays=[];
                            }
                            else if(index ==arr.length)
                            {
                                self.tableData.push(dataArrays)
                                dataArrays=[];
                            }

                            index++;
                        })
                        self.tableListData = self.tableData
                        //console.log(' self.tableData',  self.tableListData)
                        if(self.tableListData.length == 0){
                            self.noUserMessage = 'No User Found';
                        }else{
                            self.noUserMessage = '';
                        }
                    
                    })
                    
                    self.tableListData = self.tableData
                }
                // self.$root.$emit('filterUsersByDateDashboard','year');
            })
        }
        self.$root.$on('filterUsersByFromToDateDashboard',(fromDate,toDate)=>{
            console.log(fromDate,toDate)
            self.getAllDate(fromDate,toDate);
            self.filerData = [];
            self.selectedFilterSocialPlan='All',
            self.selectedFilterBusinessPlan='All',
            self.selectedFilterOption='All'
            self.currentPage=0
            self.tableListData = []
            var arr=[]
            self.customerListing.forEach(data=>{
                var userDate = new Date(data.createdAt.seconds*1000).getDate()
                var userMonth = new Date(data.createdAt.seconds*1000).getMonth()+1
                var userYear = new Date(data.createdAt.seconds*1000).getFullYear()
                if(self.checkDate(userDate,userMonth,userYear)){
                    arr.push(data)
                    self.filerData.push(data)
                }
                var totalArrayLength = '';
                self.totalpages = '';
                totalArrayLength = arr.length
                for (let index1 = 0; index1 < totalArrayLength; index1++) {
                    if((totalArrayLength/10)<=index1)
                    {
                        self.totalpages=index1
                    //console.log(self.totalpages , totalArrayLength)
                        break;
                    }
                }
                var index = 1
                var dataArrays=[]
                self.tableData=[]
                //console.log('self.customerListing',self.customerListing,arr)
                arr.forEach(data=>{
                    dataArrays.push(data)
                    if(index % 10 == 0)
                    {
                        self.tableData.push(dataArrays)
                        dataArrays=[];
                    }
                    else if(index ==arr.length)
                    {
                        self.tableData.push(dataArrays)
                        dataArrays=[];
                    }

                    index++;
                })
                self.tableListData = self.tableData
                //console.log(' self.tableData',  self.tableListData)
                if(self.tableListData.length == 0){
                    self.noUserMessage = 'No User Found';
                }else{
                    self.noUserMessage = '';
                }
            })
            self.tableListData = self.tableData
        })
        self.init();
        // })
    },
    watch : {
        $route : function (){
            this.init();
        }
    },
    methods:{
        //GET CUSTOMER DATA FROM USERS COLLECTION  (Shraddha)
        init(){
            // debugger // eslint-disable-line
            var self=this;
            var totalArrayLength = '';
            getContactManagment(firebaseData.firebasecollection.USER,totalArrayLength,self.customerListing,res=>{
                self.customerListing = res
                getContactManagmentData(self.tableData,self.customerListing,response => {
                    self.tableListData = response
                    self.totalpages=self.tableListData.length
                })
            })
        },
        //FOR SEARCH EVENT(Shraddha)
        searchFilter()
        {
            // //debugger // eslint-disable-line
            this.searchFilterData = true
            if(this.keyword == ''){
                this.searchFilterData = false
            }
            var arr=[]
            this.customerListing.forEach(datas=>{
                var fullname = datas.firstName+' '+datas.lastName
                if(fullname.toLowerCase().includes(this.keyword.toLowerCase())){
                    arr.push(datas)
                    this.currentPage = 0
                }
                else if(datas.email.toLowerCase().includes(this.keyword.toLowerCase())){
                    arr.push(datas)
                    this.currentPage = 0
                }
                else if(datas.phoneNo.toLowerCase().includes(this.keyword.toLowerCase())){
                    arr.push(datas)
                    this.currentPage = 0
                }
                var totalArrayLength = '';
                this.totalpages = '';
                totalArrayLength = arr.length
                for (let index1 = 0; index1 < totalArrayLength; index1++) {
                    if((totalArrayLength/10)<=index1){
                        this.totalpages=index1
                       //console.log(this.totalpages , totalArrayLength)
                        break;
                    }
                }
                    var index = 1
                    var dataArrays=[]
                    this.tableData=[]
                    //console.log('this.customerListing',this.customerListing,arr)
                arr.forEach(data=>{
                    dataArrays.push(data)
                    if(index % 10 == 0){
                        this.tableData.push(dataArrays)
                        dataArrays=[];
                    }
                    else if(index ==arr.length){
                        this.tableData.push(dataArrays)
                        dataArrays=[];
                    }
                    index++;
                })
                this.tableListData = this.tableData
                //console.log(' this.tableData',  this.tableListData)
                if(this.tableListData.length == 0){
                    this.noUserMessage = 'No User Found';
                }else{
                    this.noUserMessage = '';
                }
            })
        },
        // FOR GET ROWID (Shraddha)
        getCustomerData(data){
            //console.log('data',data);
            this.rowId = data;
            this.isCustomerView = true;
        },
        //FOR PAGINATION
        changePage(direction){
            if(direction =='prev' && this.currentPage>0)
            {
                this.currentPage--
            }
            if(direction =='next' && this.currentPage<this.totalpages-1)
            {
                this.currentPage++
            }
        },
        //BLOCK BUTTON CLICK(Shraddha)
        IsUserBlock(data){
            debugger // eslint-disable-line
            var self=this;
            var Id = data.id
            var rawId = Id;
            const db = firebase.firestore();
            self.UserStatus = '';
            db.collection(firebaseData.firebasecollection.USER).doc(rawId).get().then(doc=>{
                self.UserStatus = doc.data().userStatus;
                //console.log('self.UserStatus',self.UserStatus);
                if(self.UserStatus == 'Active'){
                    self.UserStatus = 'Inactive'
                }else if(self.UserStatus == 'Inactive'){
                    self.UserStatus = 'Active'
                }
            }).then( () => {
                if(self.UserStatus != ''){
                    if(self.UserStatus == 'Inactive'){
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                       Swal.fire({
                            title:
                            "Are you sure you want to block" +
                            " " +
                            data.firstName +
                            " " +
                            data.lastName +
                            " " +
                            "?",
                            type: "warning",
                            showCancelButton: true,
                            customClass:{
                            container :"social_confirmation_popup"
                            },
                            confirmButtonColor: "#ffab3f",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ok",
                        }).then((result) => {
                            if (result.value) {
                                db.collection(firebaseData.firebasecollection.USER).doc(rawId)
                                .update({
                                    'userStatus': self.UserStatus
                                }).then(() => {
                                    self.$axios.post(self.$apiurl+'disableUser',{uid:rawId})
                                    .then(res => {
                                        // console.log(res);
                                        self.$notify({
                                            title:"Success",
                                            text:data.firstName +' '+data.lastName+' blocked.',
                                            type:"success",
                                            duration: 10000
                                        })
                                    })
                                })
                                
                                self.init();
                            }
                        })
                    }else if(self.UserStatus == 'Active'){
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                         Swal.fire({
                            title: 'are you sure you want to unblock'+' '+data.firstName+' '+data.lastName+ '?',
                            type: "warning",
                            customClass:{
                                container :"social_confirmation_popup"
                            },
                            showCancelButton: true,
                            confirmButtonColor: "#ffab3f",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, unblock it!",
                        }).then((result) => {
                            if (result.value) {
                                db.collection(firebaseData.firebasecollection.USER).doc(rawId)
                                .update({
                                    'userStatus': self.UserStatus
                                }).then(() => {
                                     self.$axios.post(self.$apiurl+'enableUser',{uid:rawId})
                                    .then(res => {
                                        self.$notify({
                                            title:"Success",
                                            text:data.firstName +' '+data.lastName+' unblocked.',
                                            type:"success",
                                            duration: 10000
                                        })
                                    })
                                })
                                self.init();
                            }
                        })
                    }
                }
            })
        },
        //UPDATE VARIBLE WHEN BACK USERDETAIL PAGE TO USER LISTING(Shraddha)
        OnClickUpdateList(event){
            this.isCustomerView = event;
        },
        // FILTER BY USERTYPE(Shraddha)
        filterByuserType(){
            // debugger // eslint-disable-line
            var self = this;
            var filterArray = [];
            var index = 1
            var dataArrays=[]
            self.tableData=[]
            // if(self.filerData.length != 0){
            //     self.customerListing = self.filerData
            // }
            if(!self.dashboard){
              self.filerData = self.customerListing  
            }
            console.log('selectedFilterUserTypeOption',self.filerData,self.customerListing)
            if(self.selectedFilterSocialPlan != ''){
                let counting = 0;
                const getrequestDataFunction = (row) => {
                    if(counting >= self.filerData.length){
                        return;
                    }
                    const socialplan = row.socialPlan;
                    const businessPlan = row.businessPlan
                    const userStatus = row.userStatus;

                        if(self.selectedFilterSocialPlan == 'All' && self.selectedFilterBusinessPlan == 'All' && self.selectedFilterOption == 'All' ){
                            filterArray = self.filerData
                        }
                        else if(self.selectedFilterSocialPlan == 'All' && self.selectedFilterBusinessPlan == 'All' && self.selectedFilterOption != 'All' ){
                            if(userStatus == self.selectedFilterOption){
                                filterArray.push(row)
                            }
                        }
                        else if(self.selectedFilterSocialPlan != 'All' && self.selectedFilterBusinessPlan == 'All' && self.selectedFilterOption == 'All' ){
                            if(socialplan == self.selectedFilterSocialPlan){
                                filterArray.push(row)
                            }
                        }
                        else if(self.selectedFilterSocialPlan == 'All' && self.selectedFilterBusinessPlan != 'All' && self.selectedFilterOption == 'All' ){
                            if(businessPlan.includes(self.selectedFilterBusinessPlan)){
                                filterArray.push(row)
                            }
                        }
                        else if(self.selectedFilterSocialPlan != 'All' && self.selectedFilterBusinessPlan == 'All' && self.selectedFilterOption != 'All' ){
                            if(socialplan == self.selectedFilterSocialPlan && userStatus == self.selectedFilterOption){
                                filterArray.push(row)
                            }
                        }
                        else if(self.selectedFilterSocialPlan != 'All' && self.selectedFilterBusinessPlan != 'All' && self.selectedFilterOption != 'All' ){
                            if(socialplan == self.selectedFilterSocialPlan && userStatus == self.selectedFilterOption && businessPlan.includes(self.selectedFilterBusinessPlan)){
                                filterArray.push(row)
                            }
                        }
                        else if(self.selectedFilterSocialPlan == 'All' && self.selectedFilterBusinessPlan != 'All' && self.selectedFilterOption != 'All' ){
                            if(userStatus == self.selectedFilterOption && businessPlan.includes(self.selectedFilterBusinessPlan)){
                                filterArray.push(row)
                            }
                        }
                        else if(self.selectedFilterSocialPlan != 'All' && self.selectedFilterBusinessPlan != 'All' && self.selectedFilterOption == 'All' ){
                            if(socialplan == self.selectedFilterSocialPlan && businessPlan.includes(self.selectedFilterBusinessPlan)){
                                filterArray.push(row)
                            }
                        }
                        
                    counting += 1;
                    getrequestDataFunction(self.filerData[counting]);
                }
            
                getrequestDataFunction(self.filerData[counting]);
                    
                    //console.log('self.customerListing',self.customerListing,filterArray)
                    filterArray.forEach(data=>{
                    dataArrays.push(data)
                    if(index % 10 == 0)
                    {
                        self.tableData.push(dataArrays)
                        dataArrays=[];
                    }
                    else if(index ==filterArray.length)
                    {
                        self.tableData.push(dataArrays)
                        dataArrays=[];
                    }

                    index++;
                })
                self.tableListData = self.tableData
                self.currentPage = 0
                self.totalpages =  self.tableListData.length
                if(this.tableListData.length == 0){
                    this.searchFilterData = true
                    this.noUserMessage = 'No User Found';
                }else{
                    this.noUserMessage = '';
                }
            }
        },
        addZero(val){
            if(val<10){
                return '0'+val;
            }
            else{
                return val;
            }
        },
        checkDate(date,month,year){
            // debugger // eslint-disable-line
            var self = this;
            var valid = false;
            var userDates = date+'/'+month+'/'+year;
            // console.log('this.allDateArray',self.allDateArray,userDates);
            if(self.allDateArray.includes(userDates)){
                valid = true;
            }else{
                valid = false;
            }
            console.log('valid',valid)
            return valid
        },
        getDates(start, end) {
            for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
                arr.push(new Date(dt));
            }
            return arr;
        },
        monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        },
        getAllDate(fromDate,toDate){
            // debugger // eslint-disable-line
            this.DatesArray = [];
            this.allDateArray = [];
            if(fromDate != '' && toDate!='')
            {
                var date1 = new Date(fromDate);
                var date2 = new Date(toDate);
                this.DatesArray=this.getDates(date1,date2);
                this.DatesArray.forEach((datas) => {
                    this.allDateArray.push(new Date(datas).getDate()+'/'+(new Date(datas).getMonth()+1)+'/'+new Date(datas).getFullYear())
                    // console.log('this.allDateArray', this.allDateArray)
                })
                
            }
        },
        //BACK TO DASHBOARD (Shraddha)
        backtodashboard(){
            var self = this;
            self.$root.$emit('openDashboard',0);
        }
    },
})
</script>

