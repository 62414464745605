<template>
  <div class="chat-list-wrapper">
    <div class="search-area">
      <form @submit.prevent>
        <div class="form-group">
          <input
            type="search"
            class="form-control"
            placeholder="Search"
            v-model="keyword"
            @keyup="searchFilter()"
          />
          <button @click.prevent>
            <img src="../assets/images/search.png" />
          </button>
        </div>
      </form>
      <img src="../assets/images/addcontact.png" v-b-modal.modal-center title="Add contact" />
    </div>
    <div class="contact-list-info" :class="{'newRequestSocial':IncomingRequestArray.length!=0}">
      <div>
        <b-tabs content-class="">
          <b-tab title="Contacts" active @click="getSetContactList()">
            <!-- {{contactListingData.length}} -->
            <!-- {{contactListingData}} -->
            <!-- <span>{{contactListingData.length == 0 ? '' : contactListingData.length > 9 ? '9+' : contactListingData.length}}</span> -->
            <span style="color:red;" v-if="contactListingData.length == 0">No Data Found.</span>
            <div class="contact-list">
              <ul class="mainContactListItem">
                <li v-for="(accseptRequest, index) in searchFilter(contactListingData.filter(x => x.FullName !== ''))" v-bind:key="index" v-show="removeUserId != accseptRequest.id">
                  <div class="list-item" @click="IsChatCustomer(accseptRequest.id, accseptRequest.chatid)">
                    <div v-bind:class="[{blockadd: accseptRequest.block == true},{'blockadd':accseptRequest.isDeleted}]">
                      <img v-if="accseptRequest.profileUrl" class="contactprofile" :src="accseptRequest.profileUrl" />
                       <span class="contactprofile profilecharecter" v-else>{{accseptRequest.firstName.charAt(0).toUpperCase()}}</span>
                      
                      <div v-if="accseptRequest.online" class="greenDot" style="height:8px;width: 8px;margin-top: 7px;"></div>
                      <div v-else-if="botUser.id == accseptRequest.id" class="greenDot" style="height:8px;width: 8px;margin-top: 7px;"></div>
                      <span>
                        <span v-if="(accseptRequest.firstName.length + accseptRequest.lastName.length) <= 15">{{accseptRequest.firstName}} {{accseptRequest.lastName}}</span>
                        <span :title="(accseptRequest.firstName + accseptRequest.lastName)" v-else>{{(accseptRequest.firstName + accseptRequest.lastName).slice(0, 15)+'...'}}</span>
                        <!-- {{ accseptRequest.firstName }}
                        {{ accseptRequest.lastName }} -->

                      </span>
                    </div>
                    <!-- <img src="../assets/images/dotimg.png" class="dotimg" /> -->
                    <b-dropdown id="dropdown-offset" text="" v-if="botUser.id != accseptRequest.id" >
                      <li>
                        <router-link v-bind:to="{
                            name: 'userprofile',
                            params: { id: accseptRequest.id }
                          }" 
                          class="dropdown-item"
                          >View</router-link
                        >
                      </li>
                      <b-dropdown-item v-if="!accseptRequest.block"
                        href="#"
                        @click="
                          IsChatCustomer(
                            accseptRequest.id,
                            accseptRequest.chatid
                          )
                        "
                        >Chat</b-dropdown-item
                      >
                      <b-dropdown-item v-if="!accseptRequest.block" 
                        href="#"
                        @click="
                          IsBlockCustomer(accseptRequest.id, accseptRequest,accseptRequest.chatid)
                        "
                        >Block</b-dropdown-item
                      >
                       <b-dropdown-item v-else
                        href="#"
                        @click="
                          unblockUser(accseptRequest,index,accseptRequest.chatid)
                        "
                        >Unblock</b-dropdown-item
                      >
                      <b-dropdown-item 
                        href="#"
                        @click="removeFromContact(accseptRequest)"
                        >Remove</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                </li>
              </ul>
            </div>
          </b-tab>
          <b-tab title="Incoming" @click="getIncomingRequest()">
            <!-- {{IncomingRequestArray}} -->
            <!-- <span>{{IncomingRequestArray.length == 0 ? '' :IncomingRequestArray.length > 9 ? '9+' : IncomingRequestArray.length}}</span> -->
            <div class="incoming-list">
              <ul>
                <li
                  v-for="(incomeRequest, index) in IncomingRequestArray"
                  v-bind:key="index"
                >
                  <div class="list-item">
                    <div>
                      <img :src="incomeRequest.profileUrl" />
                    </div>
                    <div class="rightname-btn">
                      <span>
                        <span v-if="(incomeRequest.firstName.length + incomeRequest.lastName.length) <= 15">{{incomeRequest.firstName}} {{incomeRequest.lastName}}</span>
                        <span :title="(incomeRequest.firstName + incomeRequest.lastName)" v-else>{{(incomeRequest.firstName + incomeRequest.lastName).slice(0, 15)+'...'}}</span>
                        <!-- {{ incomeRequest.firstName }}
                        {{ incomeRequest.lastName }} -->
                        </span
                      >
                      <button
                        class="btn btn-yellow-small"
                        @click="accseptIncomingRequest(incomeRequest)"
                      >
                        Accept
                      </button>
                      <button
                        class="btn btn-white"
                        @click="removeIncomingRequest(incomeRequest.id)"
                      >
                        Decline
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </b-tab>
          <b-tab title="Outgoing">
            <!-- {{outgoingRequestArray}} -->
            <!-- <span>{{outgoingRequestArray.length == 0 ? '' : outgoingRequestArray.length>9 ? '9+' :outgoingRequestArray.length}}</span> -->
            <div class="incoming-list remove-list">
              <ul>
                <li
                  v-for="(sendRequest, index) in outgoingRequestArray"
                  v-bind:key="index"
                >
                  <div class="list-item">
                    <div>
                      <img :src="sendRequest.profileUrl" />
                    </div>
                    <div class="rightname-btn">
                      <span>
                        <span v-if="(sendRequest.firstName.length + sendRequest.lastName.length) <= 15">{{sendRequest.firstName}} {{sendRequest.lastName}}</span>
                        <span :title="(sendRequest.firstName + sendRequest.lastName)" v-else>{{(sendRequest.firstName + sendRequest.lastName).slice(0, 15)+'...'}}</span>
                        <!-- {{ sendRequest.firstName }}
                        {{ sendRequest.lastName }} -->
                        </span
                      >
                      <button
                        class="btn btn-yellow-small"
                        @click="removeOutgoingRequest(sendRequest.id)"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <Nocontact v-if="activeChat" v-show="false" />
  </div>
</template>
<script>
import firebase from "../views/firebaseInit";
import firebaseData from "../utils/firebaseCollection.js";
import {
  Contactlist,
  getContactCount,
  outgoingUserdata,
  incomingUserData,
  getIncomingrequestCounting,
  getUserData,
  getCountingFunction,
  getDefaultCollection,
  getBotuserData,
  getLoginUserData
} from "../utils/getQueeryActiveChatList.js";
import Nocontact from "../components/nocontact.vue";
import Swal from "sweetalert2";
import Vue from "vue";

import {mapActions,mapGetters} from 'vuex'
const db = firebase.firestore()
export default {
  name: "contactDetail",
  props: ["activeChat"],
  components: {
    firebaseData,
    Nocontact,
  },

  data() {
    return {
      // FOR OUTGOING REQUEST (Shraddha)
      receiverId: [],
      outgoingRequestArray: [],
      // FOR INCOMING REQUEST(Shraddha)
      senderId: [],
      IncomingRequestArray: [],
      // FOR GET USER COLLECTION(Shraddha)
      getUserData: [],
      // ACCSEPT REQUEST STORE(Shraddha)
      acseptUserData: [],
      //FOR SEARCH
      keyword: "",
      //CHAT OBJECT
      chatObject: {},
      groupObject: {},
      // contactListingData: [],
      searchContactArray: [],
      removeUserId:'',
      chatid:'',
      botUser:{},
      userData:{}
    };
  },
  created() {
    // //debugger // eslint-disable-line

    this.getOutgoingRequest();
    this.getIncomingRequest();
    // this.getContactList();
    this.getSetContactList()
    this.$root.$on("getRequest", (value) => {
      // //debugger // eslint-disable-line
      if (value == "yes") {
        this.getOutgoingRequest();
        this.getIncomingRequest();
        this.getSetContactList()
        // this.getContactList();
      }
    });
    this.$root.$on("getContact", (value) => {
      // //debugger // eslint-disable-line
      if (value == "yes") {
        this.getOutgoingRequest();
        this.getIncomingRequest();
        this.getSetContactList()
        // this.getContactList();
      }
    });
    getBotuserData(firebaseData.firebasecollection.USER,(res) => {
      this.botUser = res;
    })
    getLoginUserData(firebaseData.firebasecollection.USER,(res) => {
      this.userData = res;
    })
  },
  // watch : {
  //     $route : function (){
  //         this.getOutgoingRequest();
  //         this.getIncomingRequest();
  //         this.getContactList();
  //     }
  // },
  methods: {
    ...mapActions({getSetChatData:"getSetSocialChatData"}),
    ...mapActions({getSetContactList:"getSetContactList"}),
    
    unblockPopup(userDetails) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      Swal.fire({
        title:
          "Unblock " +
          userDetails.firstName +
          " " +
          userDetails.lastName +
          " to send a message.",
        type: "warning",
        confirmButtonColor: "#fde9cf",
      });
    },
    //GET OUTGOING REQUEST (Shraddha)
    getOutgoingRequest() {
      // //debugger // eslint-disable-line
      var self = this;
      self.outgoingRequestArray = [];
      const db = firebase.firestore();
      var senderId = localStorage.getItem("customerDocKey");
      outgoingUserdata(
        firebaseData.firebasecollection.SOCIALREQUEST,
        self.outgoingRequestArray,
        senderId,
        (res) => {
          self.outgoingRequestArray = res;
          // console.log('self.outgoingRequestArray ',self.outgoingRequestArray )
          getUserData(
            firebaseData.firebasecollection.USER,
            self.getUserData,
            (res) => {
              self.getUserData = res;
              getCountingFunction(
                self.outgoingRequestArray,
                self.getUserData,
                (res) => {
                  self.outgoingRequestArray = res;
                }
              );
            }
          );
        }
      );
    },
    //GET INCOMING REQUEST (Shraddha)
    getIncomingRequest() {
      // //debugger // eslint-disable-line
      var self = this;
      self.IncomingRequestArray = [];
      var senderId = localStorage.getItem("customerDocKey");
      incomingUserData(firebaseData.firebasecollection.SOCIALREQUEST,self.IncomingRequestArray,senderId,(res) => {
            self.IncomingRequestArray = res;
            getUserData(
              firebaseData.firebasecollection.USER,
              self.getUserData,
              (res) => {
                self.getUserData = res;
                getIncomingrequestCounting(
                  self.IncomingRequestArray,
                  self.getUserData,
                  (res) => {
                    self.IncomingRequestArray = res;
                  }
                );
              }
            );
          }
        );
    },
    //REMOVE OUTGOINGREQUEST WHEN DECLINE(Shraddha)
    removeOutgoingRequest(removeId) {
      // //debugger // eslint-disable-line
      var self = this;
      const db = firebase.firestore();
      // //console.log(removeId);
      db.collection(firebaseData.firebasecollection.SOCIALREQUEST)
        .doc(removeId)
        .delete();
      self.getOutgoingRequest();
      self.$root.$emit("audioplay");
      self.$notify({
        title: "Success",
        text: "Request is declined successfully",
        type: "success",
      });
    },
    //REMOVE INCOMINGREQUEST WHEN DECLINE(Shraddha)
    removeIncomingRequest(removeId) {
      // //debugger // eslint-disable-line
      var self = this;
      const db = firebase.firestore();
      // //console.log(removeId);
      db.collection(firebaseData.firebasecollection.SOCIALREQUEST)
        .doc(removeId)
        .delete();
      self.getIncomingRequest();
      self.$root.$emit("audioplay");
      self.$notify({
        title: "Success",
        text: "Request is declined successfully",
        type: "success",
      });
    },
    //WHEN USER ACCSEPT THE REQUEST(Shraddha)
    accseptIncomingRequest(request) {
      debugger // eslint-disable-line
      var self = this;
      const db = firebase.firestore();
      var requestData = "";
      var requestId = request.id
      //console.log(requestId)
      db.collection(firebaseData.firebasecollection.SOCIALREQUEST)
        .doc(requestId)
        .get()
        .then(async(doc) => {
          requestData = doc.data();
          // Create Chatroom
          let connectedUserArray = []
          connectedUserArray.push(requestData.senderId)
          connectedUserArray.push(requestData.receiverId)
          let messageSeenIds=[]
          messageSeenIds.push({id:firebase.auth().currentUser.uid,count:0,time:'',IsEmailSend:false})
          messageSeenIds.push({id:requestData.senderId,count:0,time:new Date(),IsEmailSend:false})
          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
          .add({
            id: "",
            connectedUser : connectedUserArray,
            blockUserArray:[],
            id:'',
            isGroup: false,
            lastMsgtime: "",
            title:'',
            muteArray:[],
            mainBranches:[],
            updateChat:{},
            msgFromBranch:false,
            SurpriseSelfie : {},
            ToggleSelfie : [],
            updateBranchData:{},
            messageSeenIds: messageSeenIds,
            blockBy : ''
          })
          .then((docRef) => {
            let docId = docRef.id;
            db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
            .doc(docId).update({id: docId})
            // //console.log(requestData);
            db.collection(firebaseData.firebasecollection.USER)
              .doc(requestData.senderId)
              .collection("socialContact")
              .doc(requestData.receiverId)
              .set({
                id: requestData.receiverId,
                isDeleted: false,
                chatId: docId
              });
            db.collection(firebaseData.firebasecollection.USER)
              .doc(requestData.receiverId)
              .collection("socialContact")
              .doc(requestData.senderId)
              .set({
                id: requestData.senderId,
                isDeleted: false,
                chatId: docId
              });
          })
          .catch((error) => {
            console.error("ERROR in create chatroom: ", error);
          })
        })
        .then(() => {
          self.$root.$emit("audioplay");
          self.$notify({
            title: "Success",
            text: `You’re now connected with ${request.firstName} ${request.lastName}.`,
            type: "success",
          });

          self.$axios.post(self.$apiurl+'sendAcseptNotification',{receiverId:request.senderId,userData:`${self.userData.firstName} ${self.userData.lastName}`})
          .then((res)=>{
            // console.log('res',res)
          })
          db.collection(firebaseData.firebasecollection.SOCIALREQUEST)
            .doc(requestId)
            .delete();
          // self.removeIncomingRequest(requestId);
          self.getSetContactList();
        });
    },
    // //GET CONTACT LIST(Shraddha)
    // getContactList() {
    //   debugger // eslint-disable-line
    //   var self = this;
    //   self.acseptUserData = [];
    //   const db = firebase.firestore();
    //   var user = localStorage.getItem("customerDocKey");
    //   Contactlist(
    //     firebaseData.firebasecollection.USER,
    //     self.acseptUserData,
    //     (res) => {
    //       self.acseptUserData = res;
    //       // console.log(' self.acseptUserData', self.acseptUserData)
    //       getContactCount(
    //         firebaseData.firebasecollection.USER,
    //         self.getUserData,
    //         self.acseptUserData,
    //         (res) => {
    //           self.contactListingData = res;
    //           getDefaultCollection(
    //             firebaseData.firebasecollection.SOCIALMESSAGE,
    //             self.contactListingData,
    //             (res) => {
    //               self.contactListingData = res;
    //               self.searchContactArray = res;
                 
    //               // console.log('self.contactListingData',self.contactListingData)
    //             }
    //           );
    //         }
    //       );
    //     }
    //   );
    // },
    //REMOVE FROM CONTACT LIST(Shraddha)
    removeFromContact(data) {
      // debugger // eslint-disable-line
      var self = this;
      self.removeUserId = data.id;
      var removeid = data.id
      const db = firebase.firestore();
      // //console.log(removeid);
      var user = firebase.auth().currentUser;
      var removeChatid = "";
      //console.log(user.uid)
      db.collection(firebaseData.firebasecollection.USER)
        .doc(user.uid)
        .collection("socialContact")
        .doc(removeid)
        .get()
        .then((snap) => {
          if (snap.data().chatId != undefined) {
            removeChatid = snap.data().chatId;
            //console.log('removeChatid',removeChatid)
            db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
              .doc(removeChatid)
              .delete()
              .then(() => {
                db.collection(firebaseData.firebasecollection.USER)
                  .doc(user.uid)
                  .collection("socialContact")
                  .doc(removeid)
                  .delete()
                  .then(() => {
                    db.collection(firebaseData.firebasecollection.USER)
                      .doc(removeid)
                      .collection("socialContact")
                      .doc(user.uid)
                      .delete()
                      .then(() => {
                        self.$root.$emit("audioplay");
                        self.$notify({
                          title: "Success",
                          text: data.firstName+' '+data.lastName+' removed successfully.',
                          type: "success",
                        });
                        self.getSetContactList();
                        self.$root.$emit("activeGroup", "yes");
                      });
                  });
              });
          } else {
            db.collection(firebaseData.firebasecollection.USER)
              .doc(user.uid)
              .collection("socialContact")
              .doc(removeid)
              .delete()
              .then(() => {
                db.collection(firebaseData.firebasecollection.USER)
                  .doc(removeid)
                  .collection("socialContact")
                  .doc(user.uid)
                  .delete()
                  .then(() => {
                    self.$root.$emit("audioplay");
                    self.$notify({
                      title: "Success",
                      text: data.firstName+' '+data.lastName+' removed successfully.',
                      type: "success",
                    });
                    self.getSetContactList();
                    self.$root.$emit("activeGroup", "yes");
                  });
              });
          }
        });
    },
    IsBlockCustomer(blockid, accseptRequest,chatingId) {
      // debugger // eslint-disable-line
      var self = this;
      const db = firebase.firestore();
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      Swal.fire({
        title:
          "Are you sure you want to block" +
          " " +
          accseptRequest.firstName +
          " " +
          accseptRequest.lastName +
          " " +
          "?",
        type: "warning",
        showCancelButton: true,
        customClass:{
          container :"social_confirmation_popup"
        },
        confirmButtonColor: "#ffab3f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.value) {
          var user = firebase.auth().currentUser;
          db.collection(firebaseData.firebasecollection.USER)
            .doc(user.uid)
            .collection("socialContact")
            .doc(blockid)
            .get()
            .then(() => {
              db.collection(firebaseData.firebasecollection.USER)
                .doc(user.uid)
                .collection("socialBlockUser")
                .doc(blockid)
                .set({
                  id: blockid,
                })
                .then(() => {
                  db.collection(firebaseData.firebasecollection.USER)
                    .doc(user.uid)
                    .collection("socialContact")
                    .doc(blockid)
                    .update({
                      block: true,
                      blockFrom: user.uid,
                    })
                    .then(() => {
                      self.$root.$emit("audioplay");
                      self.$notify({
                        title: "Success",
                        text: `${accseptRequest.firstName} ${accseptRequest.lastName} blocked.`,
                        type: "success",
                      });
                      self.getSetContactList();
                      self.$root.$emit("activeGroup", "yes");
                    });
                });
                if(chatingId != undefined){
                var blockArray = []
                  db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatingId).get().then(doc => {
                      blockArray = doc.data().blockUserArray || []
                      blockArray.push({
                        blockBy : user.uid,
                        blockTo : blockid
                      })
                  }).then(() => {
                    db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatingId).update({
                      blockUserArray: blockArray
                    })
                  })
                }
            });
        }
      });
    },
    // CHAT WITH CUSTOMER (Shraddha)
    IsChatCustomer(objectid, chatid) {
      var self = this;
      // self.$root.$emit('getChatid',chatid);
      self.getSetChatData({collection:'socialMessages', chatId:chatid})
      const db = firebase.firestore();
      var mainBranchArray = [];
      let initial = true;
      // console.log("Console",objectid,chatid);
      if (objectid != "") {
        db.collection(firebaseData.firebasecollection.USER)
          .doc(objectid)
          .onSnapshot((querySnapshot) => {
            self.chatObject = querySnapshot.data();
            mainBranchArray = querySnapshot.data().mainBranches || [];
            // self.$root.$emit(
            //   "chatObjefct",
            //   self.chatObject,
            //   chatid,
            //   self.groupObject,
            //   mainBranchArray
            // );
            if(initial) {
              self.$root.$emit('openChat',self.chatObject,chatid,self.groupObject,mainBranchArray,'default');
            }
            initial = false;
          });
      } else {
        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
          .doc(chatid)
          .get()
          .then((doc) => {
            self.groupObject = doc.data();
            mainBranchArray = querySnapshot.data().mainBranches || [];
            // self.$root.$emit(
            //   "chatObjefct",
            //   self.chatObject,
            //   chatid,
            //   self.groupObject,
            //   mainBranchArray
            // );
            self.$root.$emit('openChat',self.chatObject,chatid,self.groupObject,mainBranchArray,'default');
          });
      }
    },
    // SEARCH FILTER BY CUSTOMER(Shraddha)
    searchFilter(contactListingData) {
      // debugger // eslint-disable-line
      var self = this;
      var arr=[]
      if(self.keyword == ''){
        return contactListingData
      }else{
        contactListingData.forEach((datas) => {
          if (datas.FullName.toLowerCase().includes(self.keyword.toLowerCase())) {
            arr.push(datas)
          }
        });
        if(arr.length > 0){
          return arr;
        }else{
          return contactListingData
        }
       }
   
    },
    //UNBLOCK USER CODE(Shraddha)
    unblockUser(blockuser,index,chattingId){
          // debugger // eslint-disable-line
          var self=this
          const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
          });
          Swal.fire({
              title: 'are you sure you want to unblock'+' '+blockuser.firstName+' '+blockuser.lastName+ '?',
              type: "warning",
              customClass:{
                  container :"social_confirmation_popup"
              },
              showCancelButton: true,
              confirmButtonColor: "#ffab3f",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, unblock it!",
          }).then((result) => {
              if (result.value) {
                  db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialContact').doc(blockuser.id).get().then(doc => {
                      //console.log('doc',doc)
                      if(doc.data().chatId != undefined){
                          self.chatid = doc.data().chatId
                      }else{
                      self.chatid = '' 
                      }
                  }).then(() => {
                    var blockArray = []
                      if(self.chatid != ''){
                          db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialContact').doc(blockuser.id).set({
                              id: blockuser.id,
                              chatId : self.chatid
                          }).then(()=>{
                              db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialBlockUser').doc(blockuser.id).delete().then(()=>{
                                  self.$root.$emit("audioplay");
                                  self.$notify({
                                      title:"Success",
                                      text:blockuser.firstName+' '+blockuser.lastName+' unblocked.',
                                      type:"success"
                                  })
                                  self.getSetContactList();
                                  self.$root.$emit("activeGroup", "yes");
                              })
                          })
                          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chattingId).get().then(doc => {
                              blockArray = doc.data().blockUserArray
                              var index = blockArray.findIndex(product => {
                                  return product.blockBy == firebase.auth().currentUser.uid && product.blockTo ==  blockuser.id
                              })
                              blockArray.splice(index,1);
                          }).then(() => {
                            db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chattingId).update({
                              blockUserArray : blockArray,
                            

                            })
                          })
                      }else{
                      db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialContact').doc(blockuser.id).set({
                              id: blockuser.id,
                          }).then(()=>{
                              db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialBlockUser').doc(blockuser.id).delete().then(()=>{
                              self.$root.$emit("audioplay");
                              self.$notify({
                                  title:"Success",
                                  text:blockuser.firstName+' '+blockuser.lastName+' unblocked.',
                                  type:"success"
                              }) 
                              self.getSetContactList();
                              self.$root.$emit("activeGroup", "yes");
                              
                              })
                          }) 
                      }
                  })
              }
          })
    },
  },
  computed:{
     ...mapGetters({contactListingData:"getSocialContactList"}),
    
      getFilterData() {
          var self = this;
        console.log('self.contactListingData',self.contactListingData)
         self.searchContactArray = contactListingData
          return self.contactListingData.filter(function(rawData) {
        return (
          rawData["firstName"]
            .toLowerCase()
            .indexOf(self.keyword.toLowerCase()) >= 0 ||
          rawData["lastName"]
            .toLowerCase()
            .indexOf(self.keyword.toLowerCase()) >= 0 ||
          rawData["fullname"]
            .toLowerCase()
            .indexOf(self.keyword.toLowerCase()) >= 0
        );
      });

      },
  }
};
</script>
<style scoped>
.greenDot{
  background-color: #85DA27;
  border-radius: 24px;
  vertical-align: middle;
  color: white;
  display: inherit;
  margin-left: -4px;
  position:inherit;
}
</style>
