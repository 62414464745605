<template>
    <div class="admin_deshboard_wapper">
        <div class="admin_Page_title a_flex">
            <div class="a_flex dashboard_title">
                <h4>Dashboard</h4> 
                <!-- <img class="title_icon" src="@/assets/images/home_icon.png" alt="homeIcon"> -->
            </div>
            <div class="">
                <ul>
                    <li style="cursor:pointer" :class="{'active':selectedDateFilter=='today'}" @click.prevent="selectedDateFilter='today',fromDate='',toDate='',filterUserByDate(),getCountFilter()">Today</li>
                    <li style="cursor:pointer" :class="{'active':selectedDateFilter=='week'}" @click.prevent="selectedDateFilter='week',fromDate='',toDate='',filterUserByDate(),getCountFilter()">This Week</li>
                    <li style="cursor:pointer" :class="{'active':selectedDateFilter=='month'}" @click.prevent="selectedDateFilter='month',fromDate='',toDate='',filterUserByDate(),getCountFilter()">This Month</li>
                    <li style="cursor:pointer" :class="{'active':selectedDateFilter=='year'}" @click.prevent="selectedDateFilter='year',fromDate='',toDate='',filterUserByDate(),getCountFilter()">This Year</li>
                    <li style="border: none"  >From : <input type="date"  class="form-control" v-model="fromDate" @change="filterByDate" onkeydown="return false"></li>
                    <li>To : <input type="date"  class="form-control" :min="fromDate" v-model="toDate"  @change="filterByDate" @clear="()=>{console.log('Clean')}" onkeydown="return false"></li>
                </ul>
            </div>
        </div>
        <div class="dashboard_top_row">
            <div class="row">
                <div class="col-md-3">
                    <div class="ws_box">
                        <div>
                            <!-- {{socialRecurringRevenue}}
                            {{businessRecurringRevenue}} -->
                            <h3>${{(socialRecurringRevenue + businessRecurringRevenue).toFixed(2)}}</h3>
                            <span v-if="fromDate == '' && toDate == ''">{{getFilterType}} Recurring Revenue</span>
                             <span v-else>({{allDateArray.length}} days Recurring Revenue)</span>
                        </div>
                        <img src="@/assets/images/admin_monthly_recurring.png" alt="dooey">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="ws_box">
                        <div>
                            <!-- {{socialRevenue}}
                            {{businessRevenue}} -->
                            <h3>${{(socialRevenue + businessRevenue).toFixed(2)}}</h3>
                            <span v-if="fromDate == '' && toDate == ''">{{getFilterType}} Total Revenue</span>
                             <span v-else>({{allDateArray.length}} Total Revenue)</span>
                        </div>
                        <img src="@/assets/images/admin_total_revenue.png" alt="dooey">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="ws_box">
                        <div>
                            <h3>{{totalUsers}}</h3>
                             <span>Total Users</span>
                        </div>
                        <img src="@/assets/images/admin_total_user.png" alt="dooey">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="ws_box">
                        <div>
                            <h3>{{newUsers}}</h3>
                            <span v-if="fromDate == '' && toDate == ''">New Users ({{getFilterType}})</span>
                            <span v-else>New Users({{allDateArray.length}} days)</span>
                        </div>
                        <img src="@/assets/images/admin_total_user.png" alt="dooey">
                    </div>
                </div>
            </div>
        </div>
        
        <div class="SB_account_wrapper">
            <div class="row">
            <div class="col-md-6">
                <div class="Account_box_main">
                    <h3 class="account_title">Social Accounts</h3>
                    <div class="row">
                        <!-- {{socialPlans}} -->
                        <div :class="'col-md-'+(12/socialPlans.length)" v-for="(data,index) in socialPlans" :key="index">
                            <div class="ws_box">
                                <div>
                                    <h3>{{data.counter}}</h3>
                                    <span>{{data.plan_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="no-result-found" v-if="socialPlans.length==0">
                            No Plans Found
                        </div>
                        <!-- <div class="col-md-4">
                            <div class="ws_box">
                                <div>
                                    <h3>760</h3>
                                    <span>Social Pro</span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
           
            <div class="col-md-6">
                <div class="Account_box_main">
                    <h3 class="account_title">Business Accounts</h3>
                    <div class="row">
                        <div :class="'col-md-'+(12/businessPlans.length)" v-for="(data,index) in businessPlans" :key="index">
                            <div class="ws_box">
                                <div>
                                   
                                    <!-- <h3 v-if="data.counter == 0">{{(data.counter)}}</h3> -->
                                    <!-- <h3 v-else-if="data.plan_type == 'free' && data.counter != 0">{{(data.counter)}}</h3> -->
                                    <h3>{{(data.counter)}}</h3>
                                    <span>{{data.plan_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="no-result-found" v-if="businessPlans.length==0">
                            No Plans Found
                        </div>
                        <!-- <div class="col-md-4">
                            <div class="ws_box">
                                <div>
                                    <h3>725</h3>
                                    <span>Business Pro</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="ws_box">
                                <div>
                                    <h3>520</h3>
                                    <span>Business Bolt On</span>
                                </div>
                            </div>
                        </div> -->
                     </div>
                </div>
            </div>
        </div>
        </div>
        <div class="new_user_title">
            <h3>New Users</h3>
        </div>
        
      <ContentManagement :dashboard = true></ContentManagement>      
    </div>
   
</template>
<script>
import ContentManagement from "@/views/Admin_Panel/ContentManagement.vue";
import firebase from "../firebaseInit";
import firebaseData from '../../utils/firebaseCollection.js'
import Vue from 'vue'
const db = firebase.firestore();
import {getAdminUserData,getAdminCompaniesData,getAdminRevenueData} from '@/utils/getSidebarMedia.js';


export default ({
    components:{
        ContentManagement
    },
    data(){
        return{
            // variables
            months:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            selectedDateFilter:'week',
            monthlyRecurringRevenue:0,

            //Users
            totalRevenue:0,
            newUsers:0,
            totalUsers:0,
            socialUsers:0,
            businessUsers:0,
            usersDataArray:[],

            //Revenue
            socialRevenue:0,
            businessRevenue:0,
            //Recurring Revenue
            socialRecurringRevenue:0,
            businessRecurringRevenue:0,

            //Plans
            socialPlans:[],
            businessPlans:[],

            socialFreePlanName:'',
            businessFreePlanName:'',

            //Accounts
            // socialAccount:[],
            // businessAccount:[],
            

            fromDate:'',
            toDate : '',

            companies:[],

            revenues:[],
            totalWeek:[],
            DatesArray:[],
            allDateArray:[],
            diffDays:0,
            diffMonth:0,
            // minDate:new Date(new Date().getFullYear(), 0, 1),
            // maxDate:new Date(new Date().getFullYear(), 11,31)
            // minDate:new Date().getFullYear()+"-01-01",
            // maxDate:new Date().getFullYear()+"-12-31",
           
        }
    },
    created(){
        // get Values
        var self = this
        
        self.$root.$on('adminDashboardFilters',()=>{
            self.getCountFilter()
            self.filterUserByDate()
            
        })

        self.getPlans(res=>{
            self.getUsers(res2=>{
                self.getRevenue(res3=>{
                    self.getCompanies(res4=>{
                        self.getCountFilter()
                        self.filterUserByDate()
                    })
                })
            })
        })
       
    },
    methods:{
        
        filterByDate(e)
        {
            
            // debugger // eslint-disable-line
            console.log('Eve',e,this.fromDate)
            if(this.fromDate != '' && this.toDate!='')
            {
                // this.filterCountByDate()
                this.$root.$emit('filterUsersByFromToDateDashboard',this.fromDate,this.toDate)
            }
            else
            {
                // this.filterCountByDate()
                this.$root.$emit('filterUsersByDateDashboard',this.selectedDateFilter)
            }
            this.allDateArray = [];
            this.DatesArray = [];
            this.diffDays = 0;
            if(this.fromDate != '' && this.toDate!='')
            {
                var date1 = new Date(this.fromDate);
                var date2 = new Date(this.toDate);
                var Difference_In_Time = date2.getTime() - date1.getTime();
                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                this.diffDays = Difference_In_Days  + 1;
                this.diffMonth = this.monthDiff(date1,date2) + 1
                this.DatesArray=this.getDates(date1,date2);
                this.DatesArray.forEach((datas) => {
                    this.allDateArray.push(new Date(datas).getDate()+'/'+(new Date(datas).getMonth()+1)+'/'+new Date(datas).getFullYear())
                    // console.log('this.allDateArray', this.allDateArray)
                })
                this.filterCountByDate()
            }
            
        },
        getDates(start, end) {
            for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
                arr.push(new Date(dt));
            }
            return arr;
        },
        monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        },
        // methods
        filterUserByDate()
        {
            // debugger // eslint-disable-line
            // console.log('filterUserByDate')
            this.$root.$emit('filterUsersByDateDashboard',this.selectedDateFilter)
            
        },
        getPlans(cb)
        {
            // debugger // eslint-disable-line

            var self = this

            db.collection(firebaseData.firebasecollection.PLANMANAGEMENT)
                .onSnapshot(querySnapshot=>{
                    if(!querySnapshot.empty)
                    {
                        self.socialPlans=[]
                        self.businessPlans=[]
                        querySnapshot.forEach(doc=>{
                            // console.log(doc.data().plan_type)
                            if(doc.data().plan_type=='social')
                            {
                                var isThere=false
                                self.socialPlans.forEach(splans=>{
                                    if(splans.plan_name==doc.data().plan_name)
                                    {
                                        isThere=true
                                    }
                                })
                                if(!isThere)
                                {
                                    self.socialPlans.push({plan_name:doc.data().plan_name,counter:0})
                                }
                                if(doc.data().price==0)
                                {
                                    self.socialFreePlanName=doc.data().plan_name
                                }
                            }
                            else if(doc.data().plan_type=='business' && !doc.data().plan_name.toLowerCase().includes('add on'))
                            {
                                var isThere=false
                                self.businessPlans.forEach(bplans=>{
                                    if(bplans.plan_name==doc.data().plan_name)
                                    {
                                        isThere=true
                                    }
                                })
                                if(!isThere)
                                {
                                    self.businessPlans.push({plan_name:doc.data().plan_name,counter:0,userid:'',plan_type:doc.data().payment_status})
                                }
                                if(doc.data().price==0)
                                {
                                    self.businessFreePlanName=doc.data().plan_name
                                }
                            }
                        })
                    }
                    else
                    {
                        self.plans=['Pro','Bolt On','Start Up','Core','Bolt']
                    }
                    cb(true)
                })
                // .catch(error=>{
                //     // console.log(error)
                // })
        },
        getUsers(cb)
        {
            var self = this
           
            db.collection(firebaseData.firebasecollection.USER)
                .onSnapshot(querySnapshot=>{
                    if(!querySnapshot.empty)
                    {
                        self.usersDataArray=[]
                        self.totalUsers=0

                        querySnapshot.forEach(doc=>{
                            if(!doc.data().isAdmin && !doc.data().isDeleted && doc.data().email != Vue.prototype.$email)
                            {
                                self.totalUsers++
                                self.usersDataArray.push(doc.data())
                            }
                        })
                    }
                    cb(true)
                })
                // .catch(error=>{
                //     // console.log(error)
                // })
        },
        getRevenue(cb)
        {
            var self = this
            getAdminRevenueData(firebaseData.firebasecollection.REVENUEMANAGEMENT,(res) => {
                self.revenues=[];
                self.revenues = res;
                cb(true)
            })
            
        },
        getCompanies(cb)
        {
            var self = this
            getAdminCompaniesData(firebaseData.firebasecollection.COMPANY,(res) => {
            self.companies=[];
            self.companies = res;
            cb(true)
        })
           

            // db.collection(firebaseData.firebasecollection.COMPANY)
            //     .orderBy('createdAt','asc')
            //     .onSnapshot(querySnapshot=>{
            //         if(!querySnapshot.empty)
            //         {
            //             self.companies=[]
            //             querySnapshot.forEach(doc=>{
            //                 self.companies.push(doc.data())
            //             })

            //         }
            //         cb(true)
            //     })
            //     // .catch(error=>{
            //     //     // console.log(error)
            //     // })
        },
        getCountFilter()
        {
            
        //    debugger // eslint-disable-line
            var self = this
            self.fromDate ='';
            self.toDate = '';
            self.socialRecurringRevenue=0;
            self.businessRecurringRevenue=0;
            if(self.selectedDateFilter=='week')
            {
            // var weekStart = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay()+1).getDate()
            // var weekStart = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay()+1).getDate()
                var arrVal = '';
                self.totalWeek = [];
                var curr = new Date; // get current date
                for (let i = 0; i <= 6; i++) {
                let first = (curr.getDate() - curr.getDay() + i)+1;
                var weekStart = first;
                let day = new Date(curr.setDate(first)).toISOString().slice(0, 7)
                var weekMonth = day.split('-');
                self.totalWeek.push(first+' '+self.months[Number(weekMonth[1]-1)]);
                }
                // console.log('weekDays',self.totalWeek)
                // self.xAxis_arr=[]
                
                //Users
                //Revenues
                self.socialRevenue=0
                self.businessRevenue=0
                self.newUsers=0
                self.socialUsers=0
                self.businessUsers=0
                self.socialPlans.forEach(data2=>{
                    data2.counter=0
                })
                self.businessPlans.forEach(data2=>{
                    data2.counter=0
                    data2.userid = ''
                })

                for (let i = 0; i < 7; i++) {
                    //   var arrVal = `${self.addZero(new Date(new Date().getFullYear(), new Date().getMonth(),weekStart+i).getDate())} ${self.months[new Date(new Date().getFullYear(), new Date().getMonth(),weekStart+i).getMonth()]}`
                    // arrVal = `${self.addZero(new Date(new Date().getFullYear(), new Date().getMonth(),weekStart+i).getDate())} ${self.months[new Date(new Date().getFullYear(), new Date().getMonth(),weekStart+i).getMonth()]}`
                    //  self.totalWeek.push(arrVal)
                    // console.log('dashboard week',self.totalWeek,weekStart)
                      var compareDate =self.totalWeek[i].split(' ')
                       var index = self.months.findIndex(product => {
                            return product == compareDate[1]  
                        })
                        // console.log(index+1,compareDate[0])
                    // var arrVal = `${self.addZero(i+1)} ${self.months[new Date().getMonth()]}`
                    // self.xAxis_arr.push(arrVal)

                    self.usersDataArray.forEach(data=>{
                        if(data.email!=Vue.prototype.$email){
                            var userDate = new Date(data.createdAt.seconds*1000).getDate()
                            var userMonth = new Date(data.createdAt.seconds*1000).getMonth()+1
                            var userYear = new Date(data.createdAt.seconds*1000).getFullYear()

                            if(userDate==Number(compareDate[0]) && userMonth == new Date().getMonth()+1 && userYear == new Date().getFullYear())
                            {
                                self.newUsers++

                                self.socialPlans.forEach(data2=>{
                                    if(data.socialPlanName == data2.plan_name)
                                    {
                                        data2.counter++
                                    }
                                })
                                if(data.companiesOwned)
                                {
                                    data.companiesOwned.forEach(ids=>{
                                        self.companies.forEach(companyData=>{
                                            if(companyData.id == ids && companyData.isDeleted!=1)
                                            {
                                                companyData.plan.forEach(planData=>{
                                                    // console.log('planData',planData)
                                                    var dollersplit = planData.price.split('$');
                                                    var companyCreateDate = new Date(planData.purchase_date).getDate()
                                                    var companyCreateMonth = new Date(planData.purchase_date).getMonth()+1
                                                    var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                                                    if(companyCreateDate==Number(compareDate[0]) && companyCreateMonth == new Date().getMonth()+1 && companyCreateYear == new Date().getFullYear())
                                                    {
                                                    self.businessPlans.forEach(planCount=>{
                                                            if(planCount.userid != data.id){
                                                                if(planCount.plan_type == 'free'){
                                                                    if(companyData.plan.length == 1){
                                                                        if(planCount.plan_name.toLowerCase() == planData.plan_name.toLowerCase() )
                                                                        {
                                                                            planCount.userid = data.id
                                                                            planCount.counter++
                                                                        }
                                                                    }  
                                                                }else{
                                                                    
                                                                    if(planCount.plan_name.toLowerCase() == planData.plan_name.toLowerCase() )
                                                                    {
                                                                        planCount.userid = data.id
                                                                        planCount.counter++
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                    })
                                }
                            }
                        }

                    })

                    self.revenues.forEach(data=>{
                        var revenueDate = new Date(data.purchaseDate).getDate()
                        var revenueMonth = new Date(data.purchaseDate).getMonth()+1
                        var revenueYear = new Date(data.purchaseDate).getFullYear()
                        var revenueExpireDate = new Date(data.expireyDate).getDate()
                        var revenueExpireMonth = new Date(data.expireyDate).getMonth()+1
                        var revenueExpireYear = new Date(data.expireyDate).getFullYear()
                        if(revenueDate==Number(compareDate[0])  && revenueMonth == index+1 && revenueYear == new Date().getFullYear())
                        {
                            // console.log('123123123',revenueDate,i,revenueMonth,index+1,revenueYear,new Date().getFullYear())
                            // console.log('123123123',planDate,weekStart+i,planMonth,new Date().getMonth(),planYear,data.id)
                           if(data.userType.toLowerCase()=='social')
                            {
                                self.socialRevenue+=parseFloat(data.price)
                            }
                            else if(data.userType.toLowerCase() == 'business' || data.type == 'Add on')
                            {
                                self.businessRevenue+=parseFloat(data.price)
                            }
                        }
                        if(revenueExpireDate==Number(compareDate[0]) && revenueExpireMonth == index+1 && revenueExpireYear == new Date().getFullYear())
                        {
                            if(data.userType.toLowerCase()=='social')
                            {
                               self.socialRecurringRevenue += Number(data.price)||0
                            }
                            else if(data.userType.toLowerCase() == 'business' || data.type == 'Add on')
                            {
                                self.businessRecurringRevenue += Number(data.price)||0
                            }
                        }
                    })
                
                    // self.companies.forEach(data=>{
                    //     data.plan.forEach(planData=>{
                    //         var companyCreateDate = new Date(planData.purchase_date).getDate()
                    //         var companyCreateMonth = new Date(planData.purchase_date).getMonth()
                    //         var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                    //         if(companyCreateDate==weekStart+i && companyCreateMonth == new Date().getMonth() && companyCreateYear == new Date().getFullYear())
                    //         {
                    //             self.businessPlans.forEach(planCount=>{
                    //                 if(planCount.plan_name == planData.plan_name)
                    //                 {
                    //                     planCount.counter++
                    //                 }
                    //             })
                    //         }
                    //     })
                    // })
                }
                // console.log(self.xAxis_arr)
            }
            else if(self.selectedDateFilter=='month')
            {
                // debugger // eslint-disable-line
                var last = new Date(new Date().getFullYear(), new Date().getMonth()+1,0).getDate()
                // self.xAxis_arr=[]
                //Users
                //Revenues
                var adoneSplit = 0;
                self.socialRevenue=0
                self.businessRevenue=0
                self.newUsers=0
                self.socialUsers=0
                self.businessUsers=0
                self.socialPlans.forEach(data2=>{
                    data2.counter=0

                })
                self.businessPlans.forEach(data2=>{
                    data2.counter=0
                    data2.userid = ''
                })
                self.usersDataArray.forEach(data=>{
                        if(data.email!=Vue.prototype.$email){
                            var userMonth = new Date(data.createdAt.seconds*1000).getMonth()+1
                            var userYear = new Date(data.createdAt.seconds*1000).getFullYear()
    
                            if(userMonth == new Date().getMonth()+1 && userYear == new Date().getFullYear())
                            {
                                self.newUsers++
                                self.socialPlans.forEach(data2=>{
                                    if(data.socialPlanName == data2.plan_name)
                                    {
                                       
                                        data2.counter++
                                    }
                                })
                                // self.socialRecurringRevenue += Number(data.socialRevenue)||0
                                if(data.companiesOwned)
                                {
                                    // console.log(data.firstName,self.companies)
                                    data.companiesOwned.forEach(ids=>{
                                        self.companies.forEach(companyData=>{
                                            if(companyData.id == ids && companyData.isDeleted!=1)
                                            {
                                                companyData.plan.forEach(planData=>{
                                                    var dollersplit = planData.price.split('$');
                                                    var companyCreateMonth = new Date(planData.purchase_date).getMonth()+1
                                                    var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                                                    if(companyCreateMonth == new Date().getMonth()+1 && companyCreateYear == new Date().getFullYear())
                                                    {
                                                        // console.log('companyCreateMonth',companyCreateMonth,new Date().getMonth()+1)
                                                        // self.businessRecurringRevenue+=Number(dollersplit[1]) * planData.noOfUser
                                                       
                                                        self.businessPlans.forEach(planCount=>{
                                                           if(planCount.userid != data.id){
                                                                if(planCount.plan_type == 'free'){
                                                                    if(companyData.plan.length == 1){
                                                                        if(planCount.plan_name.toLowerCase() == planData.plan_name.toLowerCase() )
                                                                        {
                                                                            planCount.userid = data.id
                                                                            planCount.counter++
                                                                        }
                                                                    }  
                                                                }else{
                                                                    if(planCount.plan_name.toLowerCase() == planData.plan_name.toLowerCase() )
                                                                    {
                                                                        planCount.userid = data.id
                                                                        planCount.counter++
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    }
                                                })
                                                //  self.businessRecurringRevenue = self.businessRecurringRevenue + Number(adoneSplit);
                                            }
                                        })
                                        // self.companies.forEach(companyData=>{
                                        //     if(companyData.id == ids && companyData.isDeleted!=1)
                                        //     {
                                        //         self.businessRecurringRevenue+=parseFloat(planData.price) * planData.noOfUser
                                        //     }
                                        // })
                                    })
                                }
                            }
                        }
                    })
                for (let i = 0; i <= last; i++) {

                    // var arrVal = `${self.addZero(i+1)} ${self.months[new Date().getMonth()]}`
                    // self.xAxis_arr.push(arrVal)
              

                  

                    self.revenues.forEach(data=>{
                        var revenueDate = new Date(data.purchaseDate).getDate()
                        var revenueMonth = new Date(data.purchaseDate).getMonth()
                        var revenueYear = new Date(data.purchaseDate).getFullYear()
                        var revenueExpireDate = new Date(data.expireyDate).getDate()
                        var revenueExpireMonth = new Date(data.expireyDate).getMonth()+1
                        var revenueExpireYear = new Date(data.expireyDate).getFullYear()
                        if(revenueDate==i+1 && revenueMonth == new Date().getMonth() && revenueYear == new Date().getFullYear())
                        {
                            // console.log('123123123',planDate,weekStart+i,planMonth,new Date().getMonth(),planYear,data.id)
                            if(data.userType.toLowerCase()=='social')
                            {
                                self.socialRevenue+=parseFloat(data.price)
                            }
                            else if(data.userType.toLowerCase() == 'business')
                            {
                                self.businessRevenue+=parseFloat(data.price)
                            }
                        }
                        if(revenueExpireDate==i+1 && revenueExpireMonth == new Date().getMonth()+1 && revenueExpireYear == new Date().getFullYear())
                        {
                            // console.log(revenueExpireMonth,new Date().getMonth()+1)
                            if(data.userType.toLowerCase()=='social')
                            {
                               self.socialRecurringRevenue += Number(data.price)||0
                            }
                            else if(data.userType.toLowerCase() == 'business' || data.type == 'Add on')
                            {
                                self.businessRecurringRevenue += Number(data.price)||0
                            }
                        }
                    })
                
                    // self.companies.forEach(data=>{
                    //     data.plan.forEach(planData=>{
                    //         var companyCreateDate = new Date(planData.purchase_date).getDate()
                    //         var companyCreateMonth = new Date(planData.purchase_date).getMonth()
                    //         var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                    //         if(companyCreateDate==i+1 && companyCreateMonth == new Date().getMonth() && companyCreateYear == new Date().getFullYear())
                    //         {
                    //             self.businessPlans.forEach(planCount=>{
                    //                 if(planCount.plan_name == planData.plan_name)
                    //                 {
                    //                     planCount.counter++
                    //                 }
                    //             })
                    //         }
                    //     })
                    // })
                }
                // console.log(self.xAxis_arr)
            }
            else if(self.selectedDateFilter=='year')
            {
                // self.xAxis_arr=[]
                //Users
                //Revenues
                var adoneSplit = 0;
                self.socialRevenue=0
                self.businessRevenue=0
                self.newUsers=0
                self.socialUsers=0
                self.businessUsers=0
                self.socialPlans.forEach(data2=>{
                    data2.counter=0
                })
                self.businessPlans.forEach(data2=>{
                    data2.counter=0
                    data2.userid = ''
                })

                for (let i = 0; i <= 12; i++) {
                    // var arrVal = `${self.addZero(i+1)} ${self.months[new Date().getMonth()]}`
                    // self.xAxis_arr.push(arrVal)

                    self.usersDataArray.forEach(data=>{
                        if(data.email!=Vue.prototype.$email){
                            var userMonth = new Date(data.createdAt.seconds*1000).getMonth()
                            var userYear = new Date(data.createdAt.seconds*1000).getFullYear()

                            if(userMonth == i && userYear == new Date().getFullYear())
                            {
                                self.newUsers++
                                self.socialPlans.forEach(data2=>{
                                    if(data.socialPlanName == data2.plan_name)
                                    {
                                        data2.counter++
                                    }
                                })
                                // self.socialRecurringRevenue += Number(data.socialRevenue)||0
                                if(data.companiesOwned)
                                {
                                    data.companiesOwned.forEach(ids=>{
                                        // console.log(data.firstName)
                                        self.companies.forEach(companyData=>{
                                            if(companyData.id == ids && companyData.isDeleted!=1)
                                            {
                                                // if(companyData.addone_Array){
                                                //     companyData.addone_Array.forEach(adone => {
                                                //     var adone_prices = adone.addon_price.split('$');
                                                //     adoneSplit = adoneSplit + adone_prices[1];
                                                //     }) 
                                                // }
                                                companyData.plan.forEach(planData=>{
                                                    var dollersplit = planData.price.split('$');
                                                    var companyCreateMonth = new Date(planData.purchase_date).getMonth()
                                                    var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                                                    if(companyCreateMonth == i && companyCreateYear == new Date().getFullYear())
                                                    {
                                                        // self.businessRecurringRevenue+=Number(dollersplit[1]) * planData.noOfUser
                                                        self.businessPlans.forEach(planCount=>{
                                                            if(planCount.userid != data.id){
                                                                if(planCount.plan_type == 'free'){
                                                                    if(companyData.plan.length == 1){
                                                                        // console.log(companyData,'companyData')
                                                                        if(planCount.plan_name.toLowerCase() == planData.plan_name.toLowerCase() )
                                                                        {
                                                                            planCount.userid = data.id
                                                                            planCount.counter++
                                                                        }
                                                                    }  
                                                                }else{
                                                                    
                                                                    if(planCount.plan_name.toLowerCase() == planData.plan_name.toLowerCase() )
                                                                    {
                                                                        planCount.userid = data.id
                                                                        planCount.counter++
                                                                    }
                                                                    
                                                                }
                                                            }
                                                        })
                                                    }
                                                })
                                                // self.businessRecurringRevenue = self.businessRecurringRevenue + Number(adoneSplit);
                                            }
                                        })
                                        // self.companies.forEach(companyData=>{
                                        //     if(companyData.id == ids && companyData.isDeleted!=1)
                                        //     {
                                        //         self.businessRecurringRevenue+=parseFloat(planData.price) * planData.noOfUser
                                        //     }
                                        // })
                                    })
                                }
                            }
                        }

                    })

                    self.revenues.forEach(data=>{
                        var revenueMonth = new Date(data.purchaseDate).getMonth()
                        var revenueYear = new Date(data.purchaseDate).getFullYear()
                        var revenueExpireMonth = new Date(data.expireyDate).getMonth()
                        var revenueExpireYear = new Date(data.expireyDate).getFullYear()

                        if(revenueMonth == i && revenueYear == new Date().getFullYear())
                        {
                          if(data.userType.toLowerCase()=='social')
                            {
                                self.socialRevenue+=parseFloat(data.price)
                            }
                            else if(data.userType.toLowerCase() == 'business')
                            {
                                self.businessRevenue+=parseFloat(data.price)
                            }
                        }
                        if(revenueExpireMonth == i && revenueExpireYear == new Date().getFullYear())
                        {
                            if(data.userType.toLowerCase()=='social')
                            {
                               self.socialRecurringRevenue += Number(data.price)||0
                            }
                            else if(data.userType.toLowerCase() == 'business' || data.type == 'Add on')
                            {
                                self.businessRecurringRevenue += Number(data.price)||0
                            }
                        }
                        
                    })
                    // self.companies.forEach(data=>{
                    //     data.plan.forEach(planData=>{
                    //         var companyCreateMonth = new Date(planData.purchase_date).getMonth()
                    //         var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                    //         if(companyCreateMonth == i && companyCreateYear == new Date().getFullYear())
                    //         {
                    //             self.businessPlans.forEach(planCount=>{
                    //                 if(planCount.plan_name == planData.plan_name)
                    //                 {
                    //                     planCount.counter++
                    //                 }
                    //             })
                    //         }
                    //     })
                    // })
                }
                // console.log(self.xAxis_arr)
            }
            else if(self.selectedDateFilter=='today')
            {
                debugger // eslint-disable-line 
                // self.xAxis_arr=[]
                //Users
                //Revenues
                var adoneSplit = 0;
                self.socialRevenue=0
                self.businessRevenue=0
                self.newUsers=0
                self.socialUsers=0
                self.businessUsers=0
                self.socialPlans.forEach(data2=>{
                    data2.counter=0
                })
                self.businessPlans.forEach(data2=>{
                    data2.counter=0
                    data2.userid = ''
                })

                for (let i = 0; i < 1; i++) {
                    // var arrVal = `${self.addZero(i+1)} ${self.months[new Date().getMonth()]}`
                    // self.xAxis_arr.push(arrVal)

                    self.usersDataArray.forEach(data=>{
                        if(data.email!=Vue.prototype.$email){
                            var userDate = new Date(data.createdAt.seconds*1000).getDate()
                            var userMonth = new Date(data.createdAt.seconds*1000).getMonth()
                            var userYear = new Date(data.createdAt.seconds*1000).getFullYear()

                            if(userDate==new Date().getDate() && userMonth == new Date().getMonth() && userYear == new Date().getFullYear())
                            {
                                self.newUsers++
                                self.socialPlans.forEach(data2=>{
                                    if(data.socialPlanName == data2.plan_name)
                                    {
                                        data2.counter++
                                    }
                                })
                                // self.socialRecurringRevenue += Number(data.socialRevenue)||0
                                if(data.companiesOwned)
                                {
                                    data.companiesOwned.forEach(ids=>{
                                        self.companies.forEach(companyData=>{
                                            if(companyData.id == ids && companyData.isDeleted!=1)
                                            {
                                                // if(companyData.addone_Array.length != 0){
                                                //    companyData.addone_Array.forEach(adone => {
                                                //        var adone_prices = adone.addon_price.split('$');
                                                //        adoneSplit = adoneSplit + adone_prices[1];
                                                //     }) 
                                                // }
                                                companyData.plan.forEach(planData=>{
                                                    var dollersplit = planData.price.split('$');
                                                    
                                                    var companyCreateDate = new Date(planData.purchase_date).getDate()
                                                    var companyCreateMonth = new Date(planData.purchase_date).getMonth()
                                                    var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                                                    if(companyCreateDate==new Date().getDate() && companyCreateMonth == new Date().getMonth() && companyCreateYear == new Date().getFullYear())
                                                    {
                                                        // self.businessRecurringRevenue+=(Number(dollersplit[1]) * planData.noOfUser) + adoneSplit;    
                                                        self.businessPlans.forEach(planCount=>{
                                                            if(planCount.plan_type == 'free'){
                                                                if(companyData.plan.length == 1){
                                                                    if(planCount.plan_name.toLowerCase() == planData.plan_name.toLowerCase() )
                                                                    {
                                                                        planCount.userid = data.id
                                                                        planCount.counter++
                                                                    }
                                                                }  
                                                            }else{
                                                                
                                                                if(planCount.plan_name.toLowerCase() == planData.plan_name.toLowerCase() )
                                                                {
                                                                    planCount.userid = data.id
                                                                    planCount.counter++
                                                                }
                                                                
                                                            }
                                                        })
                                                    }
                                                })
                                            
                                            }
                                        })
                                    })
                                }
                            }
                        }
                    })

                    self.revenues.forEach(data=>{
                        var revenueDate = new Date(data.purchaseDate).getDate()
                        var revenueMonth = new Date(data.purchaseDate).getMonth()
                        var revenueYear = new Date(data.purchaseDate).getFullYear()
                        var revenueExpireDate = new Date(data.expireyDate).getDate()
                        var revenueExpireMonth = new Date(data.expireyDate).getMonth()
                        var revenueExpireYear = new Date(data.expireyDate).getFullYear()

                        if(revenueDate== new Date().getDate() && revenueMonth == new Date().getMonth() && revenueYear == new Date().getFullYear())
                        {
                            // console.log('123123123',planDate,weekStart+i,planMonth,new Date().getMonth(),planYear,data.id)
                           if(data.userType.toLowerCase()=='social')
                            {
                                self.socialRevenue+=parseFloat(data.price)
                            }
                            else if(data.userType.toLowerCase() == 'business')
                            {
                                self.businessRevenue+=parseFloat(data.price)
                            }
                        }
                        if(revenueExpireDate== new Date().getDate() && revenueExpireMonth == new Date().getMonth() && revenueExpireYear == new Date().getFullYear())
                        {
                            if(data.userType.toLowerCase()=='social')
                            {
                               self.socialRecurringRevenue += Number(data.price)||0
                            }
                            else if(data.userType.toLowerCase() == 'business' || data.type == 'Add on')
                            {
                                self.businessRecurringRevenue += Number(data.price)||0
                            }
                        }
                    })
                    // self.companies.forEach(data=>{
                    //     data.plan.forEach(planData=>{
                    //         var companyCreateDate = new Date(planData.purchase_date).getDate()
                    //         var companyCreateMonth = new Date(planData.purchase_date).getMonth()
                    //         var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                    //         if(companyCreateDate==new Date().getDate() && companyCreateMonth == new Date().getMonth() && companyCreateYear == new Date().getFullYear())
                    //         {
                    //             self.businessPlans.forEach(planCount=>{
                    //                 if(planCount.plan_name == planData.plan_name)
                    //                 {
                    //                     planCount.counter++
                    //                 }
                    //             })
                    //         }
                    //     })
                    // })
                }
                // console.log(self.xAxis_arr)
            }
        },
        filterCountByDate()
        {
            debugger // eslint-disable-line
            var self = this
            
            //Revenues
            var adoneSplit = 0;
            self.socialRevenue=0
            self.businessRevenue=0
            self.newUsers=0
            self.socialUsers=0
            self.businessUsers=0
            
            self.socialPlans.forEach(data2=>{
                data2.counter=0
            })
            self.businessPlans.forEach(data2=>{
                data2.counter=0
                data2.userid = ''
            })
             self.socialRecurringRevenue=0
            self.businessRecurringRevenue=0

            // for (let i = 0; i < 7; i++) {
                // var arrVal = `${self.addZero(i+1)} ${self.months[new Date().getMonth()]}`
                // self.xAxis_arr.push(arrVal)

                self.usersDataArray.forEach((data)=>{
                     if(!data.isAdmin){
                        // var compareDate = self.allDateArray[i].split('/');
                        var userDate = new Date(data.createdAt.seconds*1000).getDate()
                        var userMonth = new Date(data.createdAt.seconds*1000).getMonth()+1
                        var userYear = new Date(data.createdAt.seconds*1000).getFullYear()
                        // console.log('user',self.checkDate(userDate,userMonth,userYear))
                        if(self.checkDate(userDate,userMonth,userYear))
                        {
                            // if(userDate == Number(compareDate[0]) && userMonth == Number(compareDate[1])){
                                self.newUsers++

                                self.socialPlans.forEach(data2=>{
                                    if(data.socialPlanName == data2.plan_name)
                                    {
                                        data2.counter++
                                    }
                                })
                                // self.socialRecurringRevenue += Number(data.socialRevenue)||0
                            // }
                            // console.log('self.newUsers',self.newUsers)

                        }
                        if(data.companiesOwned)
                        {
                            data.companiesOwned.forEach(ids=>{
                                self.companies.forEach(data=>{
                                    if(data.id == ids && data.isDeleted!=1)
                                    {
                                        //  if(data.addone_Array){
                                        //        data.addone_Array.forEach(adone => {
                                        //            var adone_prices = adone.addon_price.split('$');
                                        //            adoneSplit = adoneSplit + adone_prices[1];
                                        //         }) 
                                        //     }
                                        data.plan.forEach(planData=>{
                                            var dollersplit = planData.price.split('$');
                                            var companyCreateDate = new Date(planData.purchase_date).getDate()
                                            var companyCreateMonth = new Date(planData.purchase_date).getMonth()+1
                                            var companyCreateYear = new Date(planData.purchase_date).getFullYear()

                                            if(self.checkDate(companyCreateDate,companyCreateMonth,companyCreateYear))
                                            {
                                                // self.businessRecurringRevenue+=Number(dollersplit[1]) * planData.noOfUser
                                                self.businessPlans.forEach(planCount=>{
                                                    if(planCount.userid != data.id){
                                                        if(planCount.plan_type == 'free'){
                                                            if(data.plan.length == 1){
                                                                if(planCount.plan_name.toLowerCase() == planData.plan_name.toLowerCase() )
                                                                {
                                                                    planCount.userid = data.id
                                                                    planCount.counter++
                                                                }
                                                            }  
                                                        }else{
                                                            
                                                            if(planCount.plan_name.toLowerCase() == planData.plan_name.toLowerCase() )
                                                            {
                                                                planCount.userid = data.id
                                                                planCount.counter++
                                                            }
                                                            
                                                        }
                                                    }
                                                })
                                            
                                            }   
                                        })
                                        // self.businessRecurringRevenue = self.businessRecurringRevenue + Number(adoneSplit);
                                    }
                                })
                        
                            })
                        }
                    }

                })

                self.revenues.forEach(data=>{
                    var revenueDate = new Date(data.purchaseDate).getDate()
                    var revenueMonth = new Date(data.purchaseDate).getMonth()+1
                    var revenueYear = new Date(data.purchaseDate).getFullYear()
                    var revenueExpireDate = new Date(data.expireyDate).getDate()
                    var revenueExpireMonth = new Date(data.expireyDate).getMonth()+1
                    var revenueExpireYear = new Date(data.expireyDate).getFullYear()
                    if(self.checkDate(revenueDate,revenueMonth,revenueYear))
                    {
                        // console.log('123123123',planDate,weekStart+i,planMonth,new Date().getMonth(),planYear,data.id)
                            if(data.userType.toLowerCase()=='social')
                            {
                                self.socialRevenue+=parseFloat(data.price)
                                
                            }
                            else if(data.userType.toLowerCase() == 'business'|| data.type == 'Add on')
                            {
                                self.businessRevenue+=parseFloat(data.price)
                                
                            }
                    }
                    if(self.checkDate(revenueExpireDate,revenueExpireMonth,revenueExpireYear))
                    {
                        if(data.userType.toLowerCase()=='social')
                        {
                            self.socialRecurringRevenue += Number(data.price)||0
                        }
                        else if(data.userType.toLowerCase() == 'business' || data.type == 'Add on')
                        {
                            self.businessRecurringRevenue += Number(data.price)||0
                        }
                    }
                       
                })
                // self.companies.forEach(data=>{
                //     data.plan.forEach(planData=>{
                //         var companyCreateDate = new Date(planData.purchase_date).getDate()
                //         var companyCreateMonth = new Date(planData.purchase_date).getMonth()+1
                //         var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                //         if(self.checkDate(companyCreateDate,companyCreateMonth,companyCreateYear))
                //         {
                //             console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$')
                //             // self.businessPlans.forEach(planCount=>{
                //             //     if(planCount.plan_name == planData.plan_name)
                //             //     {
                //             //         planCount.counter++
                //             //     }
                //             // })
                           
                //         }else{
                //             console.log('********************')
                //         }
                //     })
                // })
            // }
            // console.log('self.businessPlans',self.businessPlans)
        },
        checkDate(date,month,year)
        {
            // debugger // eslint-disable-line
            var self = this;
            var valid = false;
            var userDates = date+'/'+month+'/'+year;
            // console.log('this.allDateArray',self.allDateArray,userDates);
            if(self.allDateArray.includes(userDates)){
                valid = true;
            }else{
                valid = false;
            }
            // console.log('valid',valid)
            return valid
        },
        addZero(val)
        {
            if(val<10)
            {
                return '0'+val;
            }
            else
            {
                return val;
            }
        },
    },

    computed:
    {
        getFilterType()
        {
            if(this.selectedDateFilter=='today')
            {
                return 'Today'
            }
            else if(this.selectedDateFilter=='week')
            {
                return 'Last 7 days'
            }
            else if(this.selectedDateFilter=='month')
            {
                var last = new Date(new Date().getFullYear(), new Date().getMonth()+1,0).getDate()
                return `Last ${last} days`
            }
            else if(this.selectedDateFilter=='year')
            {
                return 'Last 12 months'
            }
        }
    }
})
</script>
