<template>
  <div class="no-active-user">
    <h4>You have no active chats</h4>
    <img src="@/assets/images/afterimg.png" />
    <p>To start a conversation create a new chat</p>
    <button class="btn btn-yellow" v-b-modal.new-chat>New Chat</button>
  </div>
</template>
<script>
export default {
  name: "noactivechat",
};
</script>
