<template>
    <div class="manage_notification_wrapper">
        <div class="admin_Page_title">
                <h4>Revenue Management</h4> 
                <img class="title_icon" @click="backtodashboard()" src="@/assets/images/home_icon.png" alt="homeIcon">
                <span class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
                <span class="current_page">Revenue Management</span>
                <!-- <span v-if="isPackageDivShow == true " class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
                <span v-if="isPackageDivShow == true && isEditForm == false "  class="current_page">Add New Package</span>
                <span v-if="isPackageDivShow == true && isEditForm == true " class="current_page">Update Package</span> -->

        </div>
        <div class="managenotification_table_wrapper">
            <div class="admin_Page_title">
                <h4>Transcation History</h4> 
            </div>
            <div class="manage_notification table_wrapper revenue_tabel_wrapper">
                <table class="table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Name</th>
                           
                            <th>Plan</th>
                            <th>User Type</th>
                            <th>Purchase Date</th>
                            <th>Expiry Date</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(data,indextr) in tableListData[currentPage]" :key="indextr">
                            <td>{{(currentPage*10)+(indextr+1) &lt; 10 ?'0'+(indextr+1):(currentPage*10)+(indextr+1)}}</td>
                            <td>{{data.name}}</td>
                            <td v-if="data.type">{{data.type}}({{data.plan}})</td>
                            <td v-else>{{data.plan}}</td>
                            <td style="text-transform: capitalize;">{{data.userType}}</td>
                            <td>{{addZero(new Date(data.purchaseDate).getDate())}} {{months[new Date(data.purchaseDate).getMonth()]}} {{addZero(new Date(data.purchaseDate).getFullYear())}}</td>
                            <td>{{addZero(new Date(data.expireyDate).getDate())}} {{months[new Date(data.expireyDate).getMonth()]}} {{addZero(new Date(data.expireyDate).getFullYear())}}</td>
                            <td v-if="data.currency_symbol">{{decodeHtmlCharCodes(data.currency_symbol)}}{{data.price}}</td>
                            <td v-else>${{data.price}}</td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="revenueData.length==0" class="no-result-found" style="float: none;">
                    No Data Found
                </div>
            </div>
            
        </div>
        <div class="pagination_wrapper">
            <ul class="pagination">
                <li @click="(changePage('prev'))"><a><img src="@/assets/images/left-arrow-pagination.png"></a></li>
                <li v-for="(data,index) in totalpages" :key="index" @click="currentPage=index" :class="currentPage==index?'active':''">
                    <a>{{data}}</a>
                </li>
                <li @click="(changePage('next'))"><a><img src="@/assets/images/right-arrow-pagination.png"></a></li>
            </ul>
        </div>
    </div>
</template>
<script>
import firebase from "../firebaseInit";
import firebaseData from '../../utils/firebaseCollection.js'

const db = firebase.firestore()
export default {
    name:'revenue_management',
    data(){
        return{
            revenueData:[],
            totalpages:0,
            currentPage:0,
            tableListData:[],
            months:['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'],
        }
    },
    created(){
        //get Revenue Data
        var self = this
        self.getRevenueData()
    },
    methods:
    {
        addZero(val)
        {
            if(val<10)
            {
                return '0'+val;
            }
            else
            {
                return val;
            }
        },
        decodeHtmlCharCodes(str) { 
            return str.replace(/(&#(\d+);)/g, function(match, capture, charCode) {
                return String.fromCharCode(charCode);
            });
        },
        getRevenueData(){
            var self = this
            db.collection(firebaseData.firebasecollection.REVENUEMANAGEMENT).orderBy('purchaseDate').onSnapshot(querySnapshot=>{
                self.revenueData=[]
                if(!querySnapshot.empty){
                    querySnapshot.forEach(doc=>{
                        if(doc.data().price != 0){
                            self.revenueData.push(doc.data())
                        }
                    })
                    self.pagination(self.revenueData)
                }
            })
        },
        pagination(notifications){
            var self = this
            var index = 1
            var dataArray=[]
            self.tableListData=[]
            notifications.forEach(data=>{
                dataArray.push(data)
                if(index % 10 == 0)
                {
                    self.tableListData.push(dataArray)
                    dataArray=[];
                }
                else if(index == notifications.length)
                {
                    self.tableListData.push(dataArray)
                    dataArray=[];
                }

                index++;
            })
            self.totalpages=self.tableListData.length
        },
        changePage(direction){
            if(direction =='prev' && this.currentPage>0){
                this.currentPage--
            }
            if(direction =='next' && this.currentPage<this.totalpages-1){
                this.currentPage++
            }
        },
        //BACK TO DASHBOARD (Shraddha)
        backtodashboard(){
            var self = this;
            self.$root.$emit('openDashboard',0);
        }
    }
}
</script>