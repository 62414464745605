<template>
  <div class="no-active-user">
    <h4>You have no contacts</h4>
    <img src="@/assets/images/afterimg.png" />
    <p>To chat you need contacts. Lets's get started by adding some contacts</p>
    <button class="btn btn-yellow" id="addContactModalBtn" v-b-modal.modal-center>
      Add New Contact
    </button>

    <!-- Modal  -->
    <b-modal id="modal-centers" class="modal-contact" hide-footer centered @close="closeFire()" :no-close-on-backdrop="true">
        <div class="contact-modal">
            <div class="contact-modal add_member_business">
              <h4>Add Team Member</h4>
              <!-- <p>Please enter email or phone number to add contact.</p> -->
              <ValidationObserver ref="log" v-slot="{ handleSubmit }">
              <form class="select_email_phone" @submit.prevent="handleSubmit(sendRequest)">
                <ul>
                  <li>
                    <div class="radio radio_business">
                      <input id="text" name="radio" value="email" type="radio" checked="checked" v-model="emailradio">
                      <label for="text" class="radio-label">Email</label>
                    </div>
                  </li>
                  <li>
                    <div class="radio radio_business">
                      <input id="image" name="radio" value="phone" type="radio" v-model="emailradio" >
                      <label for="image" class="radio-label">Phone Number</label>
                    </div>
                  </li>
                </ul>
                  <ValidationProvider v-if="emailradio == 'email'" rules="required|email" name="email" v-slot="{ errors }">
                  <div class="form-group">
                      <label>Email</label>
                      <input class="form-control" placeholder="Enter email" maxlength="51" v-model="email" @keyup="checkChrecterLengthEmail(email)">
                      <span class="InvalidInput">{{ errors[0] }}</span>
                      <span v-if="charecterErrorMessageForEmail != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForEmail}}</span>

                  </div>
                  </ValidationProvider>
                  
                  <ValidationProvider v-if="emailradio == 'phone'"   name="phone number" >
                    <div class="form-group">
                      <label class="pn_label">Phone Number</label>
                      <!-- <vue-tel-input v-model="phonenumber" @input="checkPhoneNumber"  name="phone number"></vue-tel-input> -->
                      <VuePhoneNumberInput v-model="phonenumber" aria-placeholder="red" @update="checkPhoneNumber"  :maxlength = "15" />

                      <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                      <span class="InvalidInput" v-if="phoneerror != ''">{{phoneerror}}</span>

                      <!-- <div class="phone_number_wrapper">
                        <select v-model="selectedCode">
                          <option v-for="(data,index) in allCountryCode" v-bind:key="index">{{data.dial_code}}</option>
                        </select>
                        <input type="text" class="form-control" id="phone" maxlength="15" placeholder="Enter phone number" v-model="phonenumber" autocomplete="off"  />
                        <span class="InvalidInput">{{ errors[0] }}</span>
                      </div> -->
                      <!-- <span style="color:red;" v-if="phonenumberErrorMessage!= '' ">{{phonenumberErrorMessage}}</span> -->
                    </div>
                  </ValidationProvider>
                   <ValidationProvider rules="required" name="Plan selection" v-slot="{ errors }">
                  <div class="form-group">
                     <ul>
                       
                        <li v-for="(plan,index) in PlanArray" v-bind:key="index">
                          <div class="radio radio_business">
                            <input type="radio" :id="'plan'+index" name="Plan selection" :value="plan.plan_name" v-model="packageId" @change="changePackageName(plan)">
                            <label class="radio-label" :for="'plan'+index">{{plan.plan_name}}</label>
                          </div>
                        </li>
                        <span class="InvalidInput">{{ errors[0] }}</span>
                     </ul>
                  </div>
                   </ValidationProvider>
                  <div class="btn-wrapper">
                      <button type="submit" class="btn btn-blue" v-bind:class="{disabled: isSpinner == true}">Add</button>
                  </div>
              </form>
              </ValidationObserver>
          </div>
        </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import firebase from "../../views/firebaseInit"
import firebaseData from '../../utils/firebaseCollection.js';

// import intlTelInput from 'intl-tel-input';
// import { VueTelInput } from 'vue-tel-input';
// import Vue from "vue";
// import { VueTelInput } from 'vue-tel-input';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
import {getLoginUserData,getAllEmailArray,getAllPhoneArray} from '@/utils/getQueeryActiveChatList.js'
import {getSocialContactData2,getSocialphonenumberData2,checkIfRequestIsAlreadySend2,getComapnyFromId2,Invitation2,CompanyInfo} from '../../businessUtills/getQueeryActiveChatList.js';

const db = firebase.firestore()
export default {
  name: "nocontact",
    components: {
        firebaseData,
        // intlTelInput,
        // VueTelInput,
        VuePhoneNumberInput
    },
  data(){
    return{
      email:'',
      senderId:'',
      receiverId:'',
      contactListArray:[],
      isInArray:'',
      isPhonenumberArray:'',
      phonenumberArray:[],
      userPhonenumber:'',
      socialRequestArray:[],
      allemailArray:[],
      allphoneNumberArray : [],
      isSpinner:false,
      receiverid:'',
      PlanArray : [],
      packageId:'',
      planObject:{},
      send:true,
      inviteEmail:'',
      emailradio : 'email',
      phonenumber : '',
      mainNumber : '',
      userData:{},
      phoneerror:'',
      phoneValid : '',
      charecterErrorMessageForEmail:'',
      inViteUserData:[],
      companyAdminInfo:{}
     
     
    }
   
  },
  created(){
  // //debugger // eslint-disable-line
    var self=this;
    self.companyAdminInfo = {};
     var selectCompany = JSON.parse(localStorage.getItem('SelectedCompany'))
        if(selectCompany != '' && selectCompany !=undefined){
            Vue.prototype.$companyName =  selectCompany
            this.Comapnyadmin = selectCompany.isAdmin
            self.companyName = selectCompany
        }
        getComapnyFromId2(firebaseData.firebasecollection.COMPANY,Vue.prototype.$companyName.id,res => {
          self.PlanArray = res
          const ids = self.PlanArray.map(o => o.plan_id)
          const filtered = self.PlanArray.filter(({plan_id}, index) => !ids.includes(plan_id, index + 1))
          self.PlanArray = filtered
            
        })
        getLoginUserData(firebaseData.firebasecollection.USER,(res) => {
          self.userData = res;
          // console.log(' self.userData', self.userData);
        })
      
        self.$root.$on('getActiveComapnyList',() => {
        self.senderId = localStorage.getItem("customerDocKey");
        getSocialContactData2(firebaseData.firebasecollection.USER,self.senderId,(res)=>{
          self.contactListArray = res
          // console.log('self.contactListArray',self.contactListArray)
        })
        getSocialphonenumberData2(firebaseData.firebasecollection.USER,self.senderId,(res)=>{
          self.phonenumberArray = res
        })
        checkIfRequestIsAlreadySend2(Vue.prototype.$companyName.id,res => {
          self.socialRequestArray = res
        })
      })
    self.senderId = localStorage.getItem("customerDocKey");
    getSocialContactData2(firebaseData.firebasecollection.USER,self.senderId,(res)=>{
      self.contactListArray = res
    })
    getSocialphonenumberData2(firebaseData.firebasecollection.USER,self.senderId,(res)=>{
      self.phonenumberArray = res
    })
    checkIfRequestIsAlreadySend2(Vue.prototype.$companyName.id,res => {
      self.socialRequestArray = res
    })
    getAllEmailArray(firebaseData.firebasecollection.USER,(res) => {
      self.allemailArray=res;
      // console.log('self.allemailArray',self.allemailArray)
    })
    getAllPhoneArray(firebaseData.firebasecollection.USER,(res) => {
      self.allphoneNumberArray=res;
    })
    Invitation2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSINVITE,res=>{
      self.inViteUserData = res
    })
    CompanyInfo(firebaseData.firebasecollection.COMPANY,Vue.prototype.$companyName.id,res => {
      self.companyAdminInfo = res;
    })
  },
  
  methods:{
     // CHECK CHARECTER LIMIT IN EMAIL (Shraddha)
    checkChrecterLengthEmail(groupName){
        // debugger // eslint-disable-lines
        var self = this;
        // self.email=groupName.replace(/[^A-Za-z]/ig,'')
        if(groupName.length < 51){
            self.email = groupName
            self.charecterErrorMessageForEmail = '';
        }else{
            self.charecterErrorMessageForEmail = 'The email may not be greater than 50 characters';
            self.email = (groupName).slice(0, 50)
        }
    },
    checkPhoneNumber(phone)
    {
      // debugger // eslint-disable-line
      // console.log(phone)
        this.mainNumber = phone.formattedNumber;
        this.phonenumber = phone.nationalNumber
        this.phoneValid = phone.isValid
          // console.log("number,phone",this.phonenumber, this.phoneValid);
            if(this.phoneValid == false  && this.phonenumber != ''){
              if(this.phonenumber != undefined){
                this.phoneerror = 'please enter valid phone number'
              }
            }else{
              this.phoneerror = ''
            }
    },
    //CHANGE PACKAGE VALUE(Shraddha)
    changePackageName(planData){
      //  debugger // eslint-disable-line
      var self=this;
      self.planObject = planData
      // console.log(self.planObject)
      if(planData.payment_status != 'free'){
      var noOfUserInPlan = []
      var noofuser = 0
     
      db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc => {
            var plans = doc.data().plan
            var users = doc.data().users
            plans.forEach(plandata => {
              if(plandata.plan_id == self.planObject.plan_id && plandata.plan_status != 'deactivated'){
                noofuser = noofuser + Number(plandata.noOfUser)
              }
            })
            users.forEach(data => {
              if(data.planId == self.planObject.plan_id){
                noOfUserInPlan.push(data)
              }
            })
            if(self.companyAdminInfo.plan_id == self.planObject.plan_id){
            // console.log(noOfUserInPlan.length,noofuser)
              if(noofuser > (noOfUserInPlan.length + 1)){
                self.send = true
              }else{
                self.send = false
              }
            }else{
              if(noofuser > (noOfUserInPlan.length)){
                self.send = true
              }else{
                self.send = false
              }
            }
          
      })
    }else{
      self.send = true
    }
    
    },
    // SEND REQUEST TO DOOEYCUSTOMER(Shraddha)
    sendRequest(){
      // debugger // eslint-disable-line
      var self=this;
      if(self.charecterErrorMessageForEmail == ''){
        self.inviteEmail = self.email;
        if(self.emailradio == "phone"){
          self.email = "";
        }
        if(self.emailradio == "email"){
          self.phonenumber = '';
          self.mainNumber = '';
        }
        if(self.send == true){
          self.isSpinner = true
          var emailArray = false;
          var phoneArray = false;
        if(self.email != '' || self.phonenumber != ''){
          const db = firebase.firestore()
          self.senderId = localStorage.getItem("customerDocKey");
          var senderEmail = JSON.parse(localStorage.getItem("rememberUser")).email
          db.collection(firebaseData.firebasecollection.USER).doc(self.senderId).get().then(doc => {
            if(doc.exists){
            self.userPhonenumber = doc.data().phoneNo
            }
        
          // console.log('sender info.',self.senderId,senderEmail,self.email);
          self.receiverId = '';
          var type = isNaN(self.email)
          // console.log(type); 
          
              self.isInArray =  self.contactListArray.some(function(item) {
                  return item == self.email
              });
            
            self.isPhonenumberArray =  self.phonenumberArray.some(function(item) {
                  return item == self.phonenumber.replace(/\s/g, '') && item != ''
            });

              emailArray =  self.allemailArray.some(function(item) {
                  return item.email == self.email
              });

              phoneArray =  self.allphoneNumberArray.some(function(item) {
                  return item.phoneNo == self.phonenumber.replace(/\s/g, '') && item != ''
              });
              // console.log(emailArray,phoneArray,self.allemailArray,self.email)
          // IF INPUT HAS AN EMAIL ADDRESS(Shraddha)
            if(emailArray == true || phoneArray == true){
                if(type == true){
                    if(senderEmail != self.email){
                      if(self.isInArray == false){
                        db.collection(firebaseData.firebasecollection.USER)
                        .get()
                        .then(querySnapshot=>{
                            querySnapshot.forEach(doc=>{
                                if(doc.data().email==self.email){
                                  self.receiverId = doc.data().id 
                                  self.receiverid = self.receiverId
                                
                                }
                            })
                        }).then( () => {
                          var requestArray =  self.socialRequestArray.some(function(item) {
                              return item.senderId == self.senderId && item.receiverId == self.receiverId
                          });
                          var requestIncomingArray =  self.socialRequestArray.some(function(item) {
                              return item.senderId == self.receiverId && item.receiverId == self.senderId
                          });
                          if(requestIncomingArray == false){
                            if(requestArray == false){
                                if(self.receiverId != ''){
                                  db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).get().then(doc => {
                                    if(doc.exists){
                                      if(doc.data().receiverId == self.receiverId || doc.data().senderId == self.senderId){

                                      }else{
                                        
                                      }

                                    }
                                  })
                                  db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).add({
                                    id:'',
                                    receiverId:self.receiverId,
                                    senderId:self.senderId,
                                    status:0
                                  }).then( (docid) => {
                                    // console.log(docid)
                                      db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).doc(docid.id).update({
                                        id:docid.id
                                      }).then( () => {
                                        self.$axios.post(self.$apiurl+'sendEmail',{email:self.email,type:'Request',requestType:'Business',userId:self.senderId,companyId:Vue.prototype.$companyName.id,receiverId:self.receiverid,planId:self.planObject.plan_id})
                                          .then((res)=>{
                                            // console.log('resresres',res)
                                            self.isSpinner = false;
                                            // self.$axios.post(self.$apiurl+'sendRequestUserNotification',{receiverId:self.receiverid,userData : `${self.userData.firstName} ${self.userData.lastName}`,companyData:Vue.prototype.$companyName})
                                            // .then((res)=>{
                                            // console.log('resresres',res)
                                            // })
                                          })
                                          // debugger // eslint-disable-line
                                          db.collection(firebaseData.firebasecollection.USER)
                                            .doc(self.receiverid)
                                            .get().then(doc=>{
                                                
                                                var users = []
                                                if(doc.exists)
                                                {
                                                    db.collection('companies')
                                                    .doc(Vue.prototype.$companyName.id)
                                                    .get().then(doc2=>{
                                                        var plan = doc2.data().plan
                                                        users = doc2.data().users || []
                                                        plan.forEach(plans => {
                                                            if(plans.plan_id == self.planObject.plan_id && plans.order_id == self.planObject.order_id){
                                                                users.push({
                                                                    id:self.receiverid,
                                                                    profileUrl :doc.data().profileUrl,
                                                                    name:`${doc.data().firstName} ${doc.data().lastName}`,
                                                                    planName:plans.plan_name,
                                                                    planId:self.planObject.plan_id,
                                                                    email:self.inviteEmail,
                                                                    phone:'',
                                                                    usedSelfie : 0,
                                                                    selfieUsers:[],
                                                                    selfieTime : '',
                                                                    noOfSelfie:0,
                                                                    max_file_size : plans.max_file_size,
                                                                    device_plan:plans.device_plan,
                                                                    status:'Pending to accept'
                                                                })
                                                            }
                                                        })
                                                          // console.log('users',users)
                                                            db.collection('companies')
                                                            .doc(Vue.prototype.$companyName.id)
                                                            .update({
                                                                users:users
                                                            }).then(()=>{
                                                              self.$root.$emit("audioplay");
                                                              self.$notify({
                                                                title:"Success",
                                                                text:'Invite sent to '+self.inviteEmail,
                                                                type:"success",
                                                                duration: 10000
                                                              })
                                                              self.isSpinner = false;
                                                              return false
                                                            })
                                                          
                                                          
                                                            .catch(error=>{
                                                                console.log(error)
                                                                
                                                            })
                                                            
                                                    })


                                                }
                                            })
                                        
                                          self.email = '';
                                          self.packageId = '';
                                           self.isSpinner = false;
                                          self.$refs.log.reset();
                                          self.$bvModal.hide('modal-centers');
                                          self.$root.$emit('getRequest','yes');
                                         
                                          
                                          
                                      })
                                  })
                                }
                            }else{
                                self.email = ''
                                
                                self.packageId = ''
                                self.isSpinner = false;
                                self.$refs.log.reset()
                                self.$root.$emit("audioplay");
                                self.$notify({
                                  title:"ERROR",
                                  text:'Request is already send',
                                  type:"error"
                                })
                            }
                          }else{
                            self.email = ''
                            self.packageId = ''
                          
                            self.isSpinner = false;
                              self.$refs.log.reset()
                              self.$root.$emit("audioplay");
                              self.$notify({
                                title:"ERROR",
                                text:'Request has been already arrived',
                                type:"error"
                              })
                          }
                        })
                      }else{
                        self.email = ''
                          self.packageId = ''
                          
                          self.isSpinner = false;
                        self.$refs.log.reset()
                        self.$root.$emit("audioplay");
                        self.$notify({
                          title:"ERROR",
                          text:'User already exists',
                          type:"error"
                        })
                      }
                    }else{
                      self.email = ''                   
                      self.packageId = ''
                      self.isSpinner = false;
                      self.$refs.log.reset()
                      self.$root.$emit("audioplay");
                      self.$notify({
                          title:"ERROR",
                          text:'You can not send request to yourself',
                          type:"error"
                      })
                    }
                  
                  // IF INPUT HAS AN PHONE ADDRESS(Shraddha)
                }
                else if(type == false){
                  if(self.phoneerror == ''){
                    if(self.userPhonenumber != self.phonenumber.replace(/\s/g, '')){
                      if(self.isPhonenumberArray == false){
                        var receiverName = ''
                        var profileUrl = ''
                        var users = []
                            db.collection(firebaseData.firebasecollection.USER)
                              .get()
                              .then(querySnapshot=>{
                                  querySnapshot.forEach(doc=>{
                                      if(doc.data().phoneNo==self.phonenumber.replace(/\s/g, '')){
                                        self.receiverId = doc.data().id 
                                        self.receiverid = self.receiverId
                                        profileUrl = doc.data().profileUrl
                                        receiverName = `${doc.data().firstName} ${doc.data().lastName}`
                                      }
                                  })
                              })
                              .then(() => {
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).add({
                                  id:'',
                                  receiverId:self.receiverId,
                                  senderId:firebase.auth().currentUser.uid,
                                  status:0
                                }).then( (docid) => {
                                  // console.log(docid)
                                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).doc(docid.id).update({
                                      id:docid.id
                                    }).then( () => {
                                      db.collection('companies')
                                .doc(Vue.prototype.$companyName.id)
                                .get().then(doc2=>{
                                    var plan = doc2.data().plan
                                    users = doc2.data().users || []
                                    plan.forEach(plans => {
                                        if(plans.plan_id == self.planObject.plan_id && plans.order_id == self.planObject.order_id){
                                            users.push({
                                                id:self.receiverId,
                                                profileUrl:profileUrl,
                                                name:receiverName,
                                                planName:plans.plan_name,
                                                planId:self.planObject.plan_id,
                                                email:'',
                                                phone:self.phonenumber,
                                                usedSelfie : 0,
                                                selfieUsers:[],
                                                selfieTime : '',
                                                noOfSelfie:0,
                                                device_plan:plans.device_plan,
                                                status:'Pending to accept'
                                            })
                                        }
                                    })
                                    // console.log('users',users)
                                    db.collection('companies')
                                    .doc(Vue.prototype.$companyName.id)
                                    .update({
                                        users:users
                                    }).then(()=>{
                                      // self.phonenumber = ''
                                      self.isSpinner = false;
                                        return false
                                      })
                                      .catch(error=>{
                                          console.log(error)
                                          
                                      })
                                    })
                                  })
                                })
                              })
                                
                              .then( () => {
                        const obj = {
                          phoneNumber: self.mainNumber,
                          type:'Request',
                          userId:self.senderId,
                          companyId:Vue.prototype.$companyName.id,
                          receiverId:self.receiverid,
                          planId:self.planObject.plan_id,
                          receiverName : receiverName,
                          senderName :`${self.userData.firstName} ${self.userData.lastName}`

                        }
                        self.$axios.post(self.$apiurl+'sendSMSNotification',obj).then((result)=>{
                          // console.log("RESULT SMSSSS",result);
                          //  self.$axios.post(self.$apiurl+'sendRequestUserNotification',{receiverId:self.receiverid,userData : `${self.userData.firstName} ${self.userData.lastName}`,companyData:Vue.prototype.$companyName})
                          //   .then((res)=>{
                          //   console.log('resresres',res)
                          //   })
                          self.$root.$emit("audioplay");
                          self.$notify({
                              title:"Success",
                              text:'Invite sent to '+self.phonenumber.replace(/\s/g, ''),
                              type:"success",
                              duration: 10000
                            })
                            
                            self.isSpinner = false;
                            self.$refs.log.reset()
                            self.$bvModal.hide('modal-centers');
                        })
                  })
                      }else{
                        self.isSpinner = false;
                        self.phonenumber = ''
                          self.$refs.log.reset()
                          self.$root.$emit("audioplay");
                          self.$notify({
                            title:"ERROR",
                            text:'User exists in your contact.',
                            type:"error"
                          })
                          self.$bvModal.hide('modal-centers');
                      }
                    }else{
                    
                      self.isSpinner = false;
                      self.phonenumber = ''
                      self.$refs.log.reset()
                      self.$root.$emit("audioplay");
                      self.$notify({
                          title:"ERROR",
                          text:'You can not send request to yourSelf',
                          type:"error"
                      })
                      self.$bvModal.hide('modal-centers');
                    }
                  }else{
                    self.isSpinner = false
                  }
                
                }
            }
            else{
              if(isNaN(self.email)){
                let isInArray = self.inViteUserData.some(function(item) {
                    return item.byMail == senderEmail && item.sendBy == self.senderId
                });
                if(!isInArray){
                  self.$axios.post(self.$apiurl+'sendEmail',{email:self.email,phoneNo:'',type:'Invitation',requestType:'Business',userId:self.senderId,companyId:Vue.prototype.$companyName.id,planId:self.planObject.plan_id})
                  .then((res)=>{
                    // console.log('resresres',res,self.receiverId)
                  
                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSINVITE).add({
                      byMail:senderEmail,
                      byPhone:'',
                      id:'',
                      sendBy:self.senderId
                    }).then((docref) => {
                      db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSINVITE).doc(docref.id).update({
                        id:docref.id
                      })
                  
                    self.$root.$emit("audioplay");
                    self.$notify({
                      title:"Success",
                      text:'Invitation sent to '+self.email,
                      type:"success"
                    })
                    self.email = '';
                    self.inviteEmail = ''
                    self.packageId = ''
                    self.$refs.log.reset()
                    self.isSpinner = false;
                    self.$bvModal.hide('modal-centers');
                    })
                  
                    
                })
                }else{
                   self.$root.$emit("audioplay");
                   self.$notify({
                      title:"Error",
                      text:'Invitation is already send',
                      type:"error"
                    })
                    self.email = '';
                    self.senderEmail = ''
                    self.inviteEmail = ''
                    self.packageId = ''
                    self.$refs.log.reset()
                    self.isSpinner = false;
                   
                }
              }
              else{
                let isInArray = self.inViteUserData.some(function(item) {
                    return item.byPhone == self.phonenumber.replace(/\s/g, '') && item.sendBy == self.senderId
                });
                if(!isInArray){
                  if(self.phoneerror == ''){ 
                  const obj = {
                    phoneNumber: self.mainNumber,
                    type:'Invitation',
                    userId:self.senderId,
                    companyId:Vue.prototype.$companyName.id,
                    planId:self.planObject.plan_id,
                    senderName : `${self.userData.firstName} ${self.userData.lastName}`
                  }
                  self.$axios.post(self.$apiurl+'sendSMSNotification',obj).then((result)=>{
                    // console.log("RESULT SMSSSS",result);
                    self.$root.$emit("audioplay");
                    self.$notify({
                        title:"Success",
                        text:'Invitation sent to '+self.phonenumber.replace(/\s/g, ''),
                        type:"success",
                        duration: 10000
                      })
                      db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSINVITE).add({
                        byMail:'',
                        byPhone:self.phonenumber.replace(/\s/g, ''),
                        id:'',
                        sendBy:self.senderId
                      }).then((docref) => {
                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSINVITE).doc(docref.id).update({
                          id:docref.id
                        })
                    
                      self.phonenumber = ''
                      self.email = '';
                      self.inviteEmail = ''
                      self.packageId = ''
                      self.$refs.log.reset()
                      self.isSpinner = false;
                      self.$bvModal.hide('modal-centers');
                      })
                  })
                  
                  }else{
                    self.isSpinner = false
                  }
                }else{
                   self.$root.$emit("audioplay");
                   self.$notify({
                      title:"Error",
                      text:'Invitation is already send',
                      type:"error"
                    })
                    self.email = '';
                    self.phonenumber = ''
                    self.inviteEmail = ''
                    self.packageId = ''
                    self.$refs.log.reset()
                    self.isSpinner = false;
                }
              }
            }
            })
            }
          
        }else{
          self.$root.$emit("audioplay");
          self.$notify({
            title:"Error",
            text:'User limit in your plan has been over.',
            type:"error",
            duration: 10000
          })
        }
      }
      
    },
    closeFire(){
        // //debugger // eslint-disable-line   
        var self=this;
        self.email = ''
        self.packageId = ''
        self.phoneerror = ''
        self.$refs.log.reset();
        self.$bvModal.hide('modal-centers')
    }
  }
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>;