<template>
  <div class="no-active-user">
    <h4>You have no contacts</h4>
    <img src="@/assets/images/afterimg.png" />
    <p>To chat you need contacts. Lets's get started by adding some contacts</p>
    <button class="btn btn-yellow" id="addContactModalBtn" v-b-modal.modal-center>
      Add New Contact
    </button>

    <!-- Modal  -->
    <b-modal id="modal-center" class="modal-contact" hide-footer centered @close="closeFire()" :no-close-on-backdrop="true">
        <div class="contact-modal">
            <div class="contact-modal add_member_social">
              <h4>Add New Contact</h4>
              <p>Please enter email or phone number to add contact.</p>
              <ValidationObserver ref="log" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(sendRequest)" class="select_email_phone">
                <ul>
                  <li>
                    <div class="radio radio_business">
                      <input id="text" name="radio" value="email" type="radio" checked="checked" v-model="emailradio">
                      <label for="text" class="radio-label">Email</label>
                    </div>
                  </li>
                  <li>
                    <div class="radio radio_business">
                      <input id="image" name="radio" value="phone" type="radio" v-model="emailradio" >
                      <label for="image" class="radio-label">Phone Number</label>
                    </div>
                  </li>
                </ul>
                <ValidationProvider v-if="emailradio == 'email'" rules="required|email" name="email" v-slot="{ errors }">
                  <div class="form-group">
                      <label>Email *</label>
                      <input class="form-control" placeholder="Enter email" maxlength="51"  name="email" v-model="email" @keyup="checkChrecterLengthEmail(email)">
                      <span class="InvalidInput">{{ errors[0] }}</span>
                      <span v-if="charecterErrorMessageForEmail != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForEmail}}</span>
                     
                  </div>
                </ValidationProvider>
                <ValidationProvider v-else  name="phone number" v-slot="{ errors }">
                  <div class="form-group mb-5">
                    <label class="pn_label">Phone Number</label>
                    <VuePhoneNumberInput v-model="phonenumber" aria-placeholder="red" @update="checkPhoneNumber"  :maxlength = "15" />

                    <span class="InvalidInput">{{ errors[0] }}</span>
                    <span class="InvalidInput" v-if="phoneerror != ''">{{phoneerror}}</span>
                   
                  </div>
                </ValidationProvider>
                <div class="btn-wrapper">
                    <button type="submit" class="btn btn-yellow" v-bind:class="{disabled: isSpinner == true}">Add</button>
                </div>
              </form>
              </ValidationObserver>
          </div>
        </div>
    </b-modal>
  </div>
</template>
<script>
import firebase from "../views/firebaseInit"
import firebaseData from '../utils/firebaseCollection.js';
import {getSocialContactData,getSocialphonenumberData,checkIfRequestIsAlreadySend,getLoginUserData,getAllEmailArray,getAllPhoneArray} from '../utils/getQueeryActiveChatList.js';
import { VueTelInput } from 'vue-tel-input';
import Vue from "vue";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {Invitation} from '@/utils/getQueeryActiveChatList.js'

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
const db = firebase.firestore()
export default {
  name: "nocontact",
    components: {
      firebaseData,
      // VueTelInput,
      VuePhoneNumberInput
    },
  data(){
    return{
      email:'',
      senderId:'',
      receiverId:'',
      contactListArray:[],
      isInArray:'',
      isPhonenumberArray:'',
      phonenumberArray:[],
      userPhonenumber:'',
      socialRequestArray:[],
      allemailArray:[],
      allphoneNumberArray : [],
      isSpinner:false,
      isEmail:true,
      emailradio : 'email',
      phonenumber : '',
      mainNumber : '',
      phoneerror:'',
      phoneValid : '',
      charecterErrorMessageForEmail:'',
      inViteUserData:[]
     
    }
   
  },
  created(){
  // //debugger // eslint-disable-line
    var self=this;
    self.senderId = localStorage.getItem("customerDocKey");
    getLoginUserData(firebaseData.firebasecollection.USER,(res) => {
      self.userData = res;
    })
    getSocialContactData(firebaseData.firebasecollection.USER,self.senderId,(res)=>{
      self.contactListArray = res
    })
    getSocialphonenumberData(firebaseData.firebasecollection.USER,self.senderId,(res)=>{
      self.phonenumberArray = res
    })
    checkIfRequestIsAlreadySend(firebaseData.firebasecollection.SOCIALREQUEST,res => {
      self.socialRequestArray = res
    })
    getAllEmailArray(firebaseData.firebasecollection.USER,(res) => {
      self.allemailArray=res;
    })
    getAllPhoneArray(firebaseData.firebasecollection.USER,(res) => {
      self.allphoneNumberArray=res;
    })
    Invitation(firebaseData.firebasecollection.SOCIALINVITE,res=>{
        self.inViteUserData = res
    })
  },
  methods:{
    // CHECK CHARECTER LIMIT IN EMAIL (Shraddha)
    checkChrecterLengthEmail(groupName){
        // debugger // eslint-disable-lines
        var self = this;
        // self.email=groupName.replace(/[^A-Za-z]/ig,'')
        if(groupName.length < 51){
            self.email = groupName
            self.charecterErrorMessageForEmail = '';
        }else{
            self.charecterErrorMessageForEmail = 'The email may not be greater than 50 characters';
            self.email = (groupName).slice(0, 50)
        }
    },
    // SEND REQUEST TO DOOEYCUSTOMER(Shraddha)
    checkPhoneNumber(phone)
    {
      // debugger // eslint-disable-line
      // console.log(phone)
        this.mainNumber = phone.formattedNumber;
        this.phonenumber = phone.nationalNumber;
        this.phonenumber = this.phonenumber.replace(/\s/g, '');
        this.phoneValid = phone.isValid
          // console.log("number,phone",this.phonenumber, this.phoneValid);
            if(this.phoneValid == false  && this.phonenumber != ''){
              if(this.phonenumber != undefined){
                this.phoneerror = 'please enter valid phone number'
              }
            }else{
              this.phoneerror = ''
            }
    },
    sendRequest(){
      // debugger // eslint-disable-line
      var self=this;
      
      if(self.charecterErrorMessageForEmail == ''){
        self.isSpinner = true;
        if(self.emailradio == "phone"){
          self.email = "";
        }
        if(self.emailradio == "email"){
          self.phonenumber = '';
          self.mainNumber = '';
        }
        var emailArray = '';
        var phoneArray = '';
        if(self.email != '' || self.phonenumber != ''){
        const db = firebase.firestore()
        self.senderId = localStorage.getItem("customerDocKey");
        var senderEmail = JSON.parse(localStorage.getItem("rememberUser")).email
        // var userPhonenumber = firebase.auth().currentUser.uid
        db.collection(firebaseData.firebasecollection.USER).doc(self.senderId).get().then(doc => {
          self.userPhonenumber = doc.data().phoneNo
          self.userPhonenumber = Number(self.userPhonenumber)
      
        // //console.log('sender info.',self.senderId,senderEmail,self.email);
        self.receiverId = '';
        var type = isNaN(self.email)
        // console.log(type); 
        
            self.isInArray =  self.contactListArray.some(function(item) {
                return item == self.email
            });
          //console.log(self.isInArray,self.contactListArray,self.email)
          
          self.isPhonenumberArray =  self.phonenumberArray.some(function(item) {
                return item == self.phonenumber.replace(/\s/g, '') && item != '' 
          });
          //console.log(self.isPhonenumberArray,self.phonenumberArray,self.email)

            emailArray =  self.allemailArray.some(function(item) {
                return item.email == self.email
            });

            phoneArray =  self.allphoneNumberArray.some(function(item) {
                return item.phoneNo == self.phonenumber.replace(/\s/g, '') && item != ''  
            });
        // IF INPUT HAS AN EMAIL ADDRESS(Shraddha)
              if(emailArray == true || phoneArray == true){
                  // console.log(type)
                  if(type == true){
                      if(senderEmail != self.email){
                        if(self.isInArray == false){
                          db.collection(firebaseData.firebasecollection.USER)
                          .get()
                          .then(querySnapshot=>{
                              querySnapshot.forEach(doc=>{
                                  if(doc.data().email==self.email){
                                    self.receiverId = doc.data().id 
                                    //  //console.log('receiver info.',self.receiverId);
                                  }
                              })
                          }).then( () => {
                            var requestArray =  self.socialRequestArray.some(function(item) {
                                return item.senderId == self.senderId && item.receiverId == self.receiverId
                            });
                            var requestIncomingArray =  self.socialRequestArray.some(function(item) {
                                return item.senderId == self.receiverId && item.receiverId == self.senderId
                            });
                            if(requestIncomingArray == false){
                              if(requestArray == false){
                                  if(self.receiverId != ''){
                                    db.collection(firebaseData.firebasecollection.SOCIALREQUEST).get().then(snap => {
                                      if(doc.data().receiverId == self.receiverId || doc.data().senderId == self.senderId){
                                      }else{                                  
                                      }
                                    })
                                    db.collection(firebaseData.firebasecollection.SOCIALREQUEST).add({
                                      id:'',
                                      receiverId:self.receiverId,
                                      senderId:self.senderId,
                                      status:0
                                    }).then( (docid) => {
                                      //console.log(docid)
                                        db.collection(firebaseData.firebasecollection.SOCIALREQUEST).doc(docid.id).update({
                                          id:docid.id
                                        }).then( () => {
                                          self.isSpinner = false;
                                          self.$root.$emit("audioplay");
                                            self.$notify({
                                              title:"Success",
                                              text:'Invite sent to '+self.email,
                                              type:"success",
                                              duration: 10000
                                            })
                                            // self.$axios.post(self.$apiurl+'sendEmail',{email:self.email,type:'Request',requestType:'Social',id:inviteId,userId:self.senderId})
                                            //   .then(()=>{
                                                self.$axios.post(self.$apiurl+'sendRequestUserNotification',{receiverId:self.receiverId,userData : `${self.userData.firstName} ${self.userData.lastName}`})
                                                .then((res)=>{
                                                  // console.log('resresres',res)
                                                })
                                            // })
                                            self.email = '';
                                            self.$refs.log.reset()
                                            self.$bvModal.hide('modal-center');
                                            self.$root.$emit('getRequest','yes');
                                            
                                        })
                                    })
                                  }
                              }else{
                                  self.email = ''
                                  self.isSpinner = false;
                                  self.$refs.log.reset()
                                  self.$root.$emit("audioplay");
                                  self.$notify({
                                    title:"ERROR",
                                    text:'Request is already send.',
                                    type:"error"
                                  })
                              }
                            }else{
                              self.email = ''
                              self.isSpinner = false;
                                self.$refs.log.reset()
                                self.$notify({
                                  title:"ERROR",
                                  text:'Request has been already arrived.',
                                  type:"error"
                                })
                            }
                          })
                        }else{
                          self.email = ''
                            self.isSpinner = false;
                          self.$refs.log.reset()
                          self.$root.$emit("audioplay");
                          self.$notify({
                            title:"ERROR",
                            text:'User exists in your contact.',
                            type:"error"
                          })
                        }
                      }else{
                        self.email = ''
                        self.isSpinner = false;
                        self.$refs.log.reset()
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"ERROR",
                            text:'You can not send request to yourself',
                            type:"error"
                        })
                      }
                    
                    // IF INPUT HAS AN PHONE ADDRESS(Shraddha)
                  }
                  else if(type == false){
                    if(self.phoneerror == ''){
                      if(self.userPhonenumber != self.phonenumber.replace(/\s/g, '')){
                          if(self.isPhonenumberArray == false){
                          if(phoneArray == true){

                            db.collection(firebaseData.firebasecollection.USER)
                            .get()
                            .then(querySnapshot=>{
                                querySnapshot.forEach(doc=>{
                                    if(doc.data().phoneNo == self.phonenumber.replace(/\s/g, '')){
                                      self.receiverId = doc.data().id 
                                    }
                                })
                            }).then (() => {
                              var requestArray =  self.socialRequestArray.some(function(item) {
                                    return item.senderId == self.senderId && item.receiverId == self.receiverId
                              });

                              var requestIncomingArray =  self.socialRequestArray.some(function(item) {
                                    return item.senderId == self.receiverId && item.receiverId == self.senderId
                                });
                                if(requestIncomingArray == false){
                                  if(requestArray == false){
                                      if(self.receiverId != ''){
                                        db.collection(firebaseData.firebasecollection.SOCIALREQUEST).add({
                                          id:'',
                                          receiverId:self.receiverId,
                                          senderId:self.senderId,
                                          status:0
                                        }).then( (docid) => {
                                          //console.log(docid)
                                            db.collection(firebaseData.firebasecollection.SOCIALREQUEST).doc(docid.id).update({
                                              id:docid.id
                                            }).then( () => {
                                              self.isSpinner = false;
                                              self.$root.$emit("audioplay");
                                                self.$notify({
                                                  title:"Success",
                                                  text:'Invite sent to '+self.phonenumber.replace(/\s/g, ''),
                                                  type:"success",
                                                  duration: 10000
                                                })
                                              self.$bvModal.hide('modal-center');
                                              self.$refs.log.reset() 
                                            }).then(() => {
                                              const obj = {
                                                phoneNumber:self.mainNumber,
                                                RequestType : 'social',
                                                type : 'Request',
                                                userData : `${self.userData.firstName} ${self.userData.lastName}`
                                              }
                                              self.$axios.post(self.$apiurl+'sendSMSNotification',obj).then((result)=>{
                                                // console.log("RESULT SMSSSS",result);
                                                self.$axios.post(self.$apiurl+'sendRequestUserNotification',{receiverId:self.receiverId,userData : `${self.userData.firstName} ${self.userData.lastName}`})
                                                .then((res)=>{
                                                  // console.log('resresres',res)
                                                  self.emailradio = '';
                                                  self.phonenumber = ''
                                                  self.$bvModal.hide('modal-center');
                                                  self.$refs.log.reset()
                                                  self.$root.$emit('getRequest','yes');
                                                })
                                              
                                              })
                                            })
                                        })
                                      }
                                    }else{
                                    self.emailradio = ''
                                    self.phonenumber = ''
                                    self.isSpinner = false;
                                        self.$refs.log.reset()
                                        self.$root.$emit("audioplay");
                                        self.$notify({
                                        title:"ERROR",
                                        text:'Request is already send',
                                        type:"error"
                                    })
                                    }
                                }else{
                                  self.emailradio = ''
                                  self.phonenumber = ''
                                  self.isSpinner = false;
                                    self.$refs.log.reset()
                                    self.$root.$emit("audioplay");
                                    self.$notify({
                                      title:"ERROR",
                                      text:'Request has been already arrived',
                                      type:"error"
                                    })
                                }
                            })
                          }else{
                            self.email = '';
                            
                            const obj = {
                              phoneNumber:self.mainNumber,
                              RequestType : 'social',
                              userData : `${self.userData.firstName} ${self.userData.lastName}`
                              
                              // message:"Invitation sent."
                            }
                            // console.log('obj',obj)
                            self.$axios.post(self.$apiurl+'sendSMSNotification',obj).then((result)=>{
                              // console.log("RESULT SMSSSS",result);
                            })
                            self.isSpinner = false;
                              self.$refs.log.reset()
                            //   self.$notify({
                            //   title:"ERROR",
                            //   text:'User is already exist',
                            //   type:"error"
                            // })
                          }
                          }else{
                            self.emailradio = ''
                            self.phonenumber = ''
                            self.isSpinner = false;
                              self.$refs.log.reset()
                              self.$notify({
                                title:"ERROR",
                                text:'User exists in your contact.',
                                type:"error"
                              })
                          }
                      }
                      else{
                          self.emailradio = ''
                          self.phonenumber = ''
                            self.isSpinner = false;
                          self.$refs.log.reset()
                          self.$root.$emit("audioplay");
                          self.$notify({
                              title:"ERROR",
                              text:'You can not send request to yourSelf',
                              type:"error"
                          })
                      }
                    }else{
                      self.isSpinner = false;
                    }
                  }
              }
          else{
            if(isNaN(self.email))
            {
              var inviteId = ''
              // console.log('self.inViteUserData',self.inViteUserData)
              let isInArray = self.inViteUserData.some(function(item) {
                  return item.byMail == self.email && item.sendBy == self.senderId
              });
              if(!isInArray){
              //console.log(self.$apiurl,self.$apiurl+'sendEmail')
                  db.collection(firebaseData.firebasecollection.SOCIALINVITE).add({
                    byMail:self.email,
                    byPhone:'',
                    id:'',
                    sendBy:self.senderId
                  }).then((docref) => {
                    inviteId = docref.id
                    db.collection(firebaseData.firebasecollection.SOCIALINVITE).doc(docref.id).update({
                      id:docref.id
                    }).then(() => {
                        self.$axios.post(self.$apiurl+'sendEmail',{email:self.email,type:'Invitation',requestType:'Social',id:inviteId,userId:self.senderId})
                        .then((res)=>{
                          console.log('res',res)
                          self.$root.$emit("audioplay");
                          self.$notify({
                            title:"SUCCESS",
                            text:'Invitation sent to '+self.email,
                            type:"success"
                          })
                          // console.log('resresres',res)
                          self.email = '';
                          self.$refs.log.reset()
                          self.isSpinner = false;
                          self.$bvModal.hide('modal-center');
                      })
                    })
                  })
              }else{
                self.email = ''
                self.isSpinner = false;
                self.$refs.log.reset()
                self.$root.$emit("audioplay");
                self.$notify({
                  title:"ERROR",
                  text:'Invitation is already send',
                  type:"error"
                })
                
              }
            }
            else
            {
              let isInArray = self.inViteUserData.some(function(item) {
                return item.byPhone == self.phonenumber.replace(/\s/g, '') && item.sendBy == self.senderId
              });
              if(!isInArray){
                if(self.phoneerror == ''){
                var inviteId = ''
                db.collection(firebaseData.firebasecollection.SOCIALINVITE).add({
                    byMail:'',
                    byPhone:self.phonenumber.replace(/\s/g, ''),
                    id:'',
                    sendBy:self.senderId
                  }).then((docref) => {
                    inviteId = docref.id
                    db.collection(firebaseData.firebasecollection.SOCIALINVITE).doc(docref.id).update({
                      id:docref.id
                    }).then(() => {
                      const obj = {
                        phoneNumber:self.mainNumber,
                        RequestType : 'social',
                        type : 'Invitation',
                        userData : `${self.userData.firstName} ${self.userData.lastName}`,
                        id : inviteId
                      }
                      self.$axios.post(self.$apiurl+'sendSMSNotification',obj).then((result)=>{
                        // console.log("RESULT SMSSSS",result);
                        
                        self.$root.$emit("audioplay");
                        self.$notify({
                          title:"Success",
                          text:'Invitation sent to '+self.phonenumber.replace(/\s/g, ''),
                          type:"success"
                        })
                        self.email = '';
                        self.phonenumber = ''
                        self.$refs.log.reset()
                        self.isSpinner = false
                        self.$bvModal.hide('modal-center');
                        
                      })
                    })
                  })
                }else{
                  self.isSpinner = false;
                }
              }else{
                self.phonenumber = ''
                self.isSpinner = false;
                self.$refs.log.reset()
                self.$root.$emit("audioplay");
                self.$notify({
                  title:"ERROR",
                  text:'Invitation is already send',
                  type:"error"
                })
                
              }
            }
          }
          })
        }
      }
    },
    // CLOSE EVENT OF MODEL CLOSE (Shraddha)
    closeFire(){
        // //debugger // eslint-disable-line   
        var self=this;
        self.email = ''
        self.phoneerror = ''
        
        self.$refs.log.reset();
        self.$bvModal.hide('modal-center')
    },
    //CHECK IF CHARECTER OR NOT (Shraddha)
    isCharAt(evt)
    {
      // debugger // eslint-disable-line
     
      // console.log('number',this.phonenumber)
      // console.log('testkeycodephonenumber',(/[0-9]/).test(this.phonenumber))
      const char = String.fromCharCode(evt.keyCode);
        if (!/[0-9 ||.]/.test(char)) {
             evt.preventDefault();
        }
    },
    
  },
  computed:{
    isNumber()
    {
      // console.log('test',isNaN(this.email.charAt(0)))
      return isNaN(this.email)
    },
  }
};
</script>