<template>
<div class="chat-list-wrapper">
            <div class="search-area">
              <form class="search-bulkmsg" :class="{'sidebar_serach_data' : !showSearchList || showSearchList || textSearch}"  @submit.prevent>
                <div class="form-group">
                   <label class="selected_label" v-if="Mainsearchkeyword"  > {{Mainsearchkeyword}} <button type="button" class="opensidebarbtn"  @click="openSearchBar()">X</button></label>
                  
                  <input
                    type="search"
                    class="form-control"
                    placeholder="Search"
                    @keyup="searchFilter()"
                    @click="searchClick()"
                    v-model="searchkeyword"
                  />
                 
                  <!-- <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#searchCollapse"
                  > -->
                  <button>
                    <img src="../assets/images/search.png" title="Find"/>
                  </button>
                  <button type="button" v-if="closeSearchList" @click="closeSearchBar()">X</button>
                </div>
                <div v-if="showSearchList" class="all_media_name_wrapper">
                  <ul>
                    <li v-for="(searchData,index) in  searchArray" v-bind:key="index" @click="addInSearchBar(searchData.title)">
                      {{searchData.title}} 
                    </li>
                  </ul>
                </div>
              </form>
              
            
               <img src="../assets/images/bulkmsg.png" v-if="searchChatListArray.length == 0 && !showSearchList && errorMessageForSearch == ''  && Mainsearchkeyword == ''" class="vertical_add_bulk_img"  v-b-modal.bulkmessage @click="setBulkMesagesFocus()" title="Send bulk message"/>
               <img src="../assets/images/chat-plus.png" v-if="searchChatListArray.length == 0 && !showSearchList && errorMessageForSearch == ''  && Mainsearchkeyword == ''" class="vertical_add_bulk_img"  v-b-modal.new-chat @click="setchatMesagesFocus()" title = "Create group" />
            </div>
            <!-- <div class="collapse navbar-collapse searchCollapseClass" id="searchCollapse">
              <ul class="navbar-nav">
                <li v-for="(data,index) in searchOptions" :key="index" @click.prevent="searchType=data">{{data}}</li>
              </ul>
            </div> -->
            <!-- {{storeCollectionName}} -->
            <div class="active-chat-list" v-if="searchChatListArray.length == 0   && Mainsearchkeyword == ''" >
              <span style="padding:10px;color:red;" v-if="searchkeyword != '' && searchChatListArray.length == 0 && getDisplayChatList.length == 0  ">No Result found.</span>

              <ul>
                
                <li v-for="(activeChatList,index) in getDisplayChatList" v-bind:key="index" v-bind:style="activeChatList.block?'display:none':''" v-show="checkRenderConditions(activeChatList)" :class="[{'cls-bg-orange': getTotalCount(activeChatList)>0 && activeChatList.showMessageCount}]" >
                    <!-- {{activeChatList}} -->
                    <div class="user-profile-info" style="cursor: pointer" @click="IsChatCustomer(!activeChatList.isGroup ? activeChatList.customer_id : '',activeChatList.msgid)">
                      <div :class="{'blockadd':activeChatList.isDelete}" class="u-img-time" style="cursor:pointer">
                        <img v-if="activeChatList.isGroup == false && activeChatList.profileUrl" class="chatprofile" :src="activeChatList.profileUrl"/>
                        <span v-if="activeChatList.isGroup == false && activeChatList.profileUrl == '' " class="chatprofile profilecharecter">{{activeChatList.firstName.charAt(0).toUpperCase()}}</span>
                        <img v-if="activeChatList.isGroup == true && activeChatList.groupProfile" class="chatprofile" :src="activeChatList.groupProfile"/>
                        <span v-if="activeChatList.isGroup == true && activeChatList.groupProfile == '' " class="chatprofile profilecharecter">{{activeChatList.title.charAt(0).toUpperCase()}}</span>
                        <div>
                          <span class="g-name" style="display: flex;" v-if="activeChatList.isGroup == false">
                            <span v-if="(activeChatList.firstName.length + activeChatList.lastName.length) <= 15">{{activeChatList.firstName}} {{activeChatList.lastName}}</span>
                            <span :title="(activeChatList.firstName + activeChatList.lastName)" v-else>{{(activeChatList.firstName + activeChatList.lastName).slice(0, 15)+'...'}}</span>
                            <!-- {{activeChatList.firstName}} {{activeChatList.lastName}} -->
                            <div v-if="activeChatList.isOnline" class="greenDot" style="height:8px;width: 8px;margin-top: 7px;"></div>
                            <div v-else-if="botUser.id == activeChatList.customer_id" class="greenDot" style="height:8px;width: 8px;margin-top: 7px;"></div>
                  
                            <!-- <div v-else-if="!activeChatList.isOnline" style="margin-top: 7px;" class="redDot"></div> -->
                            <!-- {{getTotalCount(activeChatList) ? getTotalCount(activeChatList) : 0}} -->
                            <!-- {{getTotalCount(activeChatList)}} -->
                            <div v-if="getTotalCount(activeChatList)>0 && activeChatList.showMessageCount" class="greenDot orange_label" style="height:24px;width: 24px;">
                              <label v-if="getTotalCount(activeChatList)>0" style="">{{getTotalCount(activeChatList)>9?'9+':getTotalCount(activeChatList)}}</label>
                            </div>
                            <div v-else>
                              
                            </div>
                          </span>
                          <span class="g-name" style="display: flex;" v-else>
                            <span v-if="(activeChatList.title.length) <= 15">{{activeChatList.title}}</span>
                            <span :title="(activeChatList.title)" v-else>{{(activeChatList.title).slice(0, 15)+'...'}}</span>
                            <!-- {{activeChatList.title}} -->
                            <div v-if="activeChatList.isGroup && getTotalCount(activeChatList)>0 && activeChatList.showMessageCount" class="greenDot orange_label" style="height:24px;width: 24px;">
                              <label v-if="getTotalCount(activeChatList)>0" style="">{{getTotalCount(activeChatList)>9?'9+':getTotalCount(activeChatList)}}</label>
                            </div>
                          </span>
                          <!-- <div v-if="activeChatList.lastmsgTime">
                            <span class="g-timedate" v-if="!beforeDate(activeChatList.lastmsgTime)" >{{activeChatList.lastMsgArrivedTime}}</span>
                            <span class="g-timedate" v-else>{{activeChatList.lastMsgDate}}</span>
                          </div>
                          <div v-else>
                            <span class="g-timedate">{{activeChatList.currentTime}}</span>
                          </div> -->
                        </div>
                      </div>
                      <div class="d-flex m_icon_wrapper">
                        <div class="m_icon" v-if="activeChatList.isGroup && checkMute(activeChatList.muteArray)">
                          <img src="@/assets/images/group-muted.png">
                        </div>
                        <b-dropdown id="dropdown-offset" text="" class="" v-if="activeChatList.isGroup == true">
                            <b-dropdown-item href="#" @click="sendSidebarData(activeChatList.msgid)"  v-b-toggle.sidebar-right>Rename</b-dropdown-item>
                            <b-dropdown-item href="#" v-if="!checkMute(activeChatList.muteArray||[])" @click.prevent="muteGroup(activeChatList.msgid)">Mute</b-dropdown-item>
                            <b-dropdown-item href="#" v-else @click.prevent="unmuteGroup(activeChatList.msgid)">Unmute</b-dropdown-item>
                            <b-dropdown-item href="#" @click="confirmationPopup(activeChatList)">Delete</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown id="dropdown-offset" text="" class="" v-if="activeChatList.isGroup == false && botUser.id != activeChatList.customer_id">
                          <li> <router-link v-bind:to="{ name: 'userprofile', params: { id: activeChatList.customer_id }}" class="dropdown-item">View</router-link></li>
                            <b-dropdown-item href="#" @click="IsChatCustomer(activeChatList.customer_id,activeChatList.msgid)">Chat</b-dropdown-item>
                            <b-dropdown-item href="#" @click="IsBlockCustomer(activeChatList.customer_id,activeChatList,activeChatList.msgid)" >Block</b-dropdown-item>
                            <b-dropdown-item href="#" @click="confirmationPopup(activeChatList)" >Remove</b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                    <p class="some-txt" style="cursor:pointer" @click="IsChatCustomer(activeChatList.isGroup ? '' :activeChatList.customer_id,activeChatList.msgid)" v-if="activeChatList.blockBy != userData.id">
                      <!-- <span style="color:#ffab3f;" v-if="activeChatList.isGroup && activeChatList.lastmsg.from && userData.id != activeChatList.lastmsg.from.id">{{activeChatList.lastmsg.from.name+' '}}:</span>
                      {{activeChatList.block?'':checkType(activeChatList)}} -->
                     <!-- {{activeChatList.block?'':activeChatList.lastmsg.type == 'SurpriseSelfie'?'Image':activeChatList.lastmsg.type=='Text' ||activeChatList.lastmsg.type=='SurpriseSelfieText'?activeChatList.lastmsg.msg:activeChatList.lastmsg.type}}
                    :activeChatList.lastmsg.type.toLowerCase() == 'selfiedeclientext' ? activeChatList.lastmsg.msg == activeChatList.docid ? "you declined surprise selfie request.": "Sorry, your request for surprise selfie has been declined": -->
                    

                    </p> 
                    <div class="tag-list-wrapper">
                    <div class="tag-list">
                      <hooper :itemsToShow="3">
                          <slide v-for="(val,i) in sortArray(activeChatList.mainBranches)" :key="i" :class="{'activeChatListSocialBranch' : countAllbranch(val,'mainbranch')>0}">
                            <div class="main_span_bg_wrapper">
                              <span class="main_span_bg" style="cursor:pointer" @click.prevent="openBranch(activeChatList.isGroup ? '' : activeChatList.customer_id,activeChatList.msgid,val.name,i)">{{val.name}} <span class="count_span" v-if="countAllbranch(val,'mainbranch')>0">{{countAllbranch(val,'mainbranch')>9?'9+':countAllbranch(val,'mainbranch')}}</span>
                              </span>
                          
                          <!-- <button @click="MainbranchDelete(activeChatList.mainBranches,val.name,activeChatList.msgid)">X</button> -->
                          </div>
                          </slide>
                          <!-- <slide>
                          <span>Checkout Latest</span>
                          </slide>
                          <slide>
                          <span>Checkout Latest</span>
                          </slide> -->
                      </hooper>
                    </div>
                    <img src="../assets/images/plus.svg" style="cursor: pointer;width: 26px;" @click.prevent="createBranchMethod(activeChatList.msgid)" :class="{'hideFooterForDooeyAdmin': botUser.id == activeChatList.customer_id}" class="black-add-branch" title="Create branch">
                    </div>
                    <!-- <div class="tag-list">
                      <span v-for="(val,i) in activeChatList.mainBranches" :key="i">{{val.name}}</span>
                    </div> -->
                </li>
              </ul>
            </div>
             <div class="active-chat-list media_list_search_main" style="overflow: auto;max-height: 70vh;" v-else>

                <span style="padding:10px;color:red;" v-if="errorMessageForSearch == '' && searchChatListArray.length == 0 && Mainsearchkeyword != '' ">No Result found.</span>

                <span style="padding:10px;color:red;" v-if="errorMessageForSearch != '' && searchChatListArray.length == 0 ">{{errorMessageForSearch}}</span>

              <div class="media_list__main-overflow" v-if="searchChatListArray.length != 0 && checkHeader(searchChatListArray) != 0">
                <h3>Recent</h3>
                <ul :class="getClasses()">
                  <div v-for="(searchData,index) in searchChatListArray" :key="index" class="downloadIconWrapper" v-show="searchData.createDate > searchData.lastWeekEndDate && searchData.createDate <= new Date()">
                    <li v-if="searchData.createDate > searchData.lastWeekEndDate && searchData.createDate <= new Date()"  >
                        <div class="text-search_main" v-if="searchData.type.toLowerCase() == 'text'">
                          <div class="namedate_flex">
                            <!-- <span>{{searchData.firstName}}</span> -->
                              <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                            <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                          </div>  
                          <span class="link_span_main">{{searchData.msg}}</span>
                        </div>
                       <p v-if="Mainsearchkeyword == 'Photos' ">
                          <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'image'">
                            <img :src="searchData.mediaUrl" height="100px;" width="100px;">
                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                          </span>
                          <span class="name_date-search_wrapper">
                            <span class="name_date">
                              <span v-if="searchkeyword != ''" >
                                <!-- {{searchData.firstName}} -->
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                </span>
                              <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                            </span>
                            <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                          </span>
                        </p>
                        <p v-if="searchData.type.toLowerCase()=='video' ">
                          <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'video'">
                            <video controls="controls" 
                                  class="video-stream" 
                                x-webkit-airplay="allow" 
                                :src="searchData.mediaUrl"
                                style=""
                            ></video>
                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                          </span>
                          <span class="name_date-search_wrapper">
                            <span class="name_date">
                              <span v-if="searchkeyword != ''">{{searchData.firstName}}</span>
                              <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                            </span>
                              <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                          </span>
                        </p>
                        <p v-if="Mainsearchkeyword=='Links' ">
                          <span v-if="searchData.type.toLowerCase() == 'link'" class="link_name_date">
                              <!-- <span>{{searchData.firstName}}</span> -->
                                <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                            <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                          <span>{{checkDate(searchData.createDate)}}</span>
                          </span>
                              <span class="link_span_main" v-if="searchkeyword == ''">{{searchData.msg}}</span>
                          <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>

                        </p>
                        <p  v-if="Mainsearchkeyword == 'Documents'" class="document_wrapper">
                            <span v-if="searchData.type.toLowerCase() == 'pdf' " class="doc_span_main">
                               <a  v-bind:key="searchData" class="" target="_blank">
                                <img src="@/assets/images/PDF_file_icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </a>
                              <div>
                              <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                <span>{{checkDate(searchData.createDate)}}</span>
                              </div>
                            </span>
                            <span v-if="searchData.type.toLowerCase() == 'excel' "  class="doc_span_main">
                              <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                <img src="@/assets/images/Exl-icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </a>
                              <div>
                              <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                               <span>{{checkDate(searchData.createDate)}}</span>
                               </div>
                            </span>
                            <span v-if="searchData.type.toLowerCase() == 'word' "  class="doc_span_main">
                              <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                <img src="@/assets/images/Word_Icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </a>
                              <div>
                              <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                               <span>{{checkDate(searchData.createDate)}}</span>
                               </div>
                            </span>
                            <span v-if="searchData.type.toLowerCase() == 'odt' "  class="doc_span_main">
                              <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                <img src="@/assets/images/ODT.png" class="pdf_icon" height="100px;" width="100px;"/>
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </a>
                              <div>
                              <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                               <span>{{checkDate(searchData.createDate)}}</span>
                               </div>
                            </span>
                            <span v-if="searchData.type.toLowerCase() == 'ods' "  class="doc_span_main">
                              <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                <img src="@/assets/images/ODS.png" class="pdf_icon" height="100px;" width="100px;"/>
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </a>
                              <div>
                              <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                              <span>{{checkDate(searchData.createDate)}}</span>
                              </div>
                            </span>
                            <span v-if="searchData.type.toLowerCase() == 'plain' "  class="doc_span_main">
                               <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                <img src="@/assets/images/834209.png" class="pdf_icon" height="100px;" width="100px;"/>
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </a>
                              <div>
                              <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                              <span>{{checkDate(searchData.createDate)}}</span>
                              </div>
                            </span>
                            <span v-if="searchData.type.toLowerCase() == 'ppt' "  class="doc_span_main">
                              <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                <img src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon" height="100px;" width="100px;"/>
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </a>
                              <div>
                              <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                               <span>{{checkDate(searchData.createDate)}}</span>
                               </div>
                            </span>
                            <span v-if="searchData.type.toLowerCase() == 'html' "  class="doc_span_main">
                              <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                <img src="@/assets/images/images.png" class="pdf_icon" height="100px;" width="100px;"/>
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </a>
                              <div>
                              <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                              <span>{{checkDate(searchData.createDate)}}</span>
                              </div>
                            </span>
                        </p>  
                    </li>
                  </div>
                </ul>
              </div>
              <div class="media_list__main-overflow" v-if="searchChatListArray.length != 0 && checkHeaders(searchChatListArray) != 0">
                 <h3>Last Week</h3>
                  <ul :class="getClasses()">
                <div v-for="(searchData,index) in searchChatListArray" :key="index" class="downloadIconWrapper" v-show="searchData.createDate >= searchData.lastWeekStartDate && searchData.createDate <= searchData.lastWeekEndDate">
               
                  <li v-if="searchData.createDate >= searchData.lastWeekStartDate && searchData.createDate <= searchData.lastWeekEndDate" >
                  
                   <div class="text-search_main" v-if="searchData.type.toLowerCase() == 'text'">
                          <div class="namedate_flex">
                            <!-- <span>{{searchData.firstName}}</span> -->
                              <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                            <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                          </div>  
                          <span class="link_span_main">{{searchData.msg}}</span>
                    </div>
                    <p v-if="Mainsearchkeyword == 'Photos' ">
                      <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'image'">
                        <img :src="searchData.mediaUrl" height="100px;" width="100px;">
                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                      </span>
                      <span class="name_date-search_wrapper">
                        <span class="name_date">
                          <span v-if="searchkeyword != ''" >
                            <!-- {{searchData.firstName}} -->
                              <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                            <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                            </span>
                          <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                        </span>
                        <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                      </span>
                    </p>
                    <p v-if="searchData.type.toLowerCase()=='video' ">
                      <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'video'">
                        <video controls="controls" 
                              class="video-stream" 
                            x-webkit-airplay="allow" 
                            :src="searchData.mediaUrl"
                            style=""
                        ></video>
                        <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                      </span>
                      <span class="name_date-search_wrapper">
                        <span class="name_date">
                          <span v-if="searchkeyword != ''">{{searchData.firstName}}</span>
                          <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                        </span>
                          <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                      </span>
                    </p>
                    <p v-if="Mainsearchkeyword=='Links' ">
                      <span v-if="searchData.type.toLowerCase() == 'link'" class="link_name_date">
                          <!-- <span>{{searchData.firstName}}</span> -->
                            <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                            <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                      <span>{{checkDate(searchData.createDate)}}</span>
                      </span>
                          <span class="link_span_main" v-if="searchkeyword == ''">{{searchData.msg}}</span>
                      <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>

                    </p>
                    <p  v-if="Mainsearchkeyword == 'Documents'" class="document_wrapper">
                        <span v-if="searchData.type.toLowerCase() == 'pdf' " class="doc_span_main">
                            <a  v-bind:key="searchData" class="" target="_blank">
                            <img src="@/assets/images/PDF_file_icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                          </a>
                          <div>
                          <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                          </div>
                        </span>
                        <span v-if="searchData.type.toLowerCase() == 'excel' "  class="doc_span_main">
                          <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                            <img src="@/assets/images/Exl-icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                          </a>
                          <div>
                          <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                            </div>
                        </span>
                        <span v-if="searchData.type.toLowerCase() == 'word' "  class="doc_span_main">
                          <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                            <img src="@/assets/images/Word_Icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                          </a>
                          <div>
                          <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                            </div>
                        </span>
                        <span v-if="searchData.type.toLowerCase() == 'odt' "  class="doc_span_main">
                          <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                            <img src="@/assets/images/ODT.png" class="pdf_icon" height="100px;" width="100px;"/>
                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                          </a>
                          <div>
                          <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                            </div>
                        </span>
                        <span v-if="searchData.type.toLowerCase() == 'ods' "  class="doc_span_main">
                          <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                            <img src="@/assets/images/ODS.png" class="pdf_icon" height="100px;" width="100px;"/>
                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                          </a>
                          <div>
                          <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                          <span>{{checkDate(searchData.createDate)}}</span>
                          </div>
                        </span>
                        <span v-if="searchData.type.toLowerCase() == 'plain' "  class="doc_span_main">
                            <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                            <img src="@/assets/images/834209.png" class="pdf_icon" height="100px;" width="100px;"/>
                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                          </a>
                          <div>
                          <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                          <span>{{checkDate(searchData.createDate)}}</span>
                          </div>
                        </span>
                        <span v-if="searchData.type.toLowerCase() == 'ppt' "  class="doc_span_main">
                          <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                            <img src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon" height="100px;" width="100px;"/>
                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                          </a>
                          <div>
                          <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                            </div>
                        </span>
                        <span v-if="searchData.type.toLowerCase() == 'html' "  class="doc_span_main">
                          <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                            <img src="@/assets/images/images.png" class="pdf_icon" height="100px;" width="100px;"/>
                            <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                          </a>
                          <div>
                          <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                          <span>{{checkDate(searchData.createDate)}}</span>
                          </div>
                        </span>
                    </p> 
                   
                  </li>
                
                 </div>
                 </ul>
              </div>
              <div class="media_list__main-overflow" v-if="searchChatListArray.length != 0 && checkHeaderss(searchChatListArray) != 0">
                 <h3>Last Month</h3>
                  <ul :class="getClasses()">
                 <div v-for="(searchData,index) in searchChatListArray" :key="index" class="downloadIconWrapper" v-show="searchData.createDate > searchData.lastMonthDate && searchData.createDate < searchData.lastWeekStartDate">
                  <li  v-if="searchData.createDate > searchData.lastMonthDate && searchData.createDate < searchData.lastWeekStartDate" >
                     <div class="text-search_main" v-if="searchData.type.toLowerCase() == 'text'">
                          <div class="namedate_flex">
                            <!-- <span>{{searchData.firstName}}</span> -->
                              <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                            <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                          </div>  
                          <span class="link_span_main">{{searchData.msg}}</span>
                      </div>
                      <p v-if="Mainsearchkeyword == 'Photos' ">
                        <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'image'">
                          <img :src="searchData.mediaUrl" height="100px;" width="100px;">
                          <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                        </span>
                        <span class="name_date-search_wrapper">
                          <span class="name_date">
                            <span v-if="searchkeyword != ''" >
                              <!-- {{searchData.firstName}} -->
                                <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                            <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                              </span>
                            <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                          </span>
                          <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                        </span>
                      </p>
                      <p v-if="searchData.type.toLowerCase()=='video' ">
                        <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'video'">
                          <video controls="controls" 
                                class="video-stream" 
                              x-webkit-airplay="allow" 
                              :src="searchData.mediaUrl"
                              style=""
                          ></video>
                          <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                        </span>
                        <span class="name_date-search_wrapper">
                          <span class="name_date">
                            <span v-if="searchkeyword != ''">{{searchData.firstName}}</span>
                            <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                          </span>
                            <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                        </span>
                      </p>
                      <p v-if="Mainsearchkeyword=='Links' ">
                        <span v-if="searchData.type.toLowerCase() == 'link'" class="link_name_date">
                            <!-- <span>{{searchData.firstName}}</span> -->
                              <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                            <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                        <span>{{checkDate(searchData.createDate)}}</span>
                        </span>
                            <span class="link_span_main" v-if="searchkeyword == ''">{{searchData.msg}}</span>
                        <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>

                      </p>
                      <p v-if="Mainsearchkeyword == 'Documents'" class="document_wrapper">
                          <span v-if="searchData.type.toLowerCase() == 'pdf' " class="doc_span_main">
                              <a  v-bind:key="searchData" class="" target="_blank">
                              <img src="@/assets/images/PDF_file_icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                              <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                            </a>
                            <div>
                            <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                              <span>{{checkDate(searchData.createDate)}}</span>
                            </div>
                          </span>
                          <span v-if="searchData.type.toLowerCase() == 'excel' "  class="doc_span_main">
                            <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                              <img src="@/assets/images/Exl-icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                              <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                            </a>
                            <div>
                            <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                              <span>{{checkDate(searchData.createDate)}}</span>
                              </div>
                          </span>
                          <span v-if="searchData.type.toLowerCase() == 'word' "  class="doc_span_main">
                            <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                              <img src="@/assets/images/Word_Icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                              <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                            </a>
                            <div>
                            <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                              <span>{{checkDate(searchData.createDate)}}</span>
                              </div>
                          </span>
                          <span v-if="searchData.type.toLowerCase() == 'odt' "  class="doc_span_main">
                            <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                              <img src="@/assets/images/ODT.png" class="pdf_icon" height="100px;" width="100px;"/>
                              <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                            </a>
                            <div>
                            <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                              <span>{{checkDate(searchData.createDate)}}</span>
                              </div>
                          </span>
                          <span v-if="searchData.type.toLowerCase() == 'ods' "  class="doc_span_main">
                            <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                              <img src="@/assets/images/ODS.png" class="pdf_icon" height="100px;" width="100px;"/>
                              <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                            </a>
                            <div>
                            <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                            </div>
                          </span>
                          <span v-if="searchData.type.toLowerCase() == 'plain' "  class="doc_span_main">
                              <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                              <img src="@/assets/images/834209.png" class="pdf_icon" height="100px;" width="100px;"/>
                              <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                            </a>
                            <div>
                            <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                            </div>
                          </span>
                          <span v-if="searchData.type.toLowerCase() == 'ppt' "  class="doc_span_main">
                            <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                              <img src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon" height="100px;" width="100px;"/>
                              <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                            </a>
                            <div>
                            <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                              <span>{{checkDate(searchData.createDate)}}</span>
                              </div>
                          </span>
                          <span v-if="searchData.type.toLowerCase() == 'html' "  class="doc_span_main">
                            <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                              <img src="@/assets/images/images.png" class="pdf_icon" height="100px;" width="100px;"/>
                              <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                            </a>
                            <div>
                            <span>{{searchData.firstName}}<label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                            </div>
                          </span>
                      </p> 
                  </li>
                 </div>
                </ul>

              </div>
            </div>
            <!-- <BulkmessageComponent></BulkmessageComponent> -->
            <NewChat></NewChat>
            <!-- BULK MESSAGE MODE -->
           <b-modal id="bulkmessage" centered hide-footer title=""  @close="closeFire()" :no-close-on-backdrop="true"> 
            <div class="contact-modal newchat-modal">
            <div class="contact-modal">
            <h4>Bulk Message</h4>
             <ValidationObserver ref="log" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(sendBulkMessage)">
              <ValidationProvider rules="required" name="message" v-slot="{ errors }">
                <div class="form-group addtextarea">
                    <label>Message *</label>
                    <textarea class="form-control" placeholder="Enter message" maxlength="5000" v-model="sendingMessage" ref="bulkmessage" @keyup="checkChrecterInMessage(sendingMessage)" ></textarea>
                    <span class="InvalidInput">{{ errors[0] }}</span>
                    <span class="InvalidInput" v-if="charecterErrorMessageForMessage != '' && errors.length == 0">{{charecterErrorMessageForMessage}}</span>
                </div>
              </ValidationProvider>
              
                <div class="form-group">
                    <label class="sc">Select Contacts</label>
                    <div class="select-contact-list">
                        <ul>
                            <li v-for="(bulkList,index) in bulkMemberList" v-bind:key="index">
                            <span v-if="(bulkList.firstName.length + bulkList.lastName.length) <= 15">{{bulkList.firstName}} {{bulkList.lastName}}</span>
                            <span :title="(bulkList.firstName + bulkList.lastName)" v-else>{{(bulkList.firstName + bulkList.lastName).slice(0, 15)+'...'}}</span>
                              <!-- {{bulkList.firstName}} {{bulkList.lastName}}  -->
                              <span class="remove-content"><img src="@/assets/images/user_added.png"></span>
                              <span class="remove-content" @click="removemember(index)"><img src="@/assets/images/user_cancel.png"></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="form-group modal-search">
                    <input type="search" input-id="tags-basic" remove-on-delete  class="form-control" placeholder="Search contact" v-model="keyword">
                    <img src="../assets/images/search-icon.png" title="Find" class="search-icon">
                </div>
                
               <div class="choose-contact">
                   <div class="scrollbar">
                       <ul class="member-list-sidebar">
                          <li v-for="(bulkUserList,index) in getFilterData" v-bind:key="index" v-show="getFilterData.length!=0 && !bulkUserList.isGroup && botUser.id != bulkUserList.id">
                            <div v-if="bulkUserList.block != true"><img :src="bulkUserList.profileUrl"><span class="ml-1">{{bulkUserList.firstName}} {{bulkUserList.lastName}}</span></div>
                            <button type="button" v-if="bulkUserList.block != true" class="btn btn-white" @click="addBrodcastMmeber(bulkUserList)">Add</button>
                          </li>
                          <li v-if="getFilterData.length==0" style="justify-content:center">
                            <span class="ml-1">
                            No User Found
                            </span>
                          </li>
                        </ul>
                    </div>
                </div>
               
                <div class="btn-wrapper">
                    <button type="button" class="btn btn-yellow" @click="sendBulkMessage()">send</button>
                </div>
            </form>
             </ValidationObserver>
        </div>
        </div>
           </b-modal>
          </div>
  
</template>

<script>
  

// import BulkmessageComponent from "../components/allModal/bulkmessages.vue"
import NewChat from "@/components/allModal/newchat.vue";
import firebaseData from '../utils/firebaseCollection.js';
import firebase from "../views/firebaseInit"
import Swal from 'sweetalert2';
import Vue from "vue";
import {getActiveChat,getCount,getCounting,Contactlist,getContactCount,getActiveMsgArray,getAcseptUserData,getChatListData,getBotuserData} from '../utils/getQueeryActiveChatList.js'
import {exitGroup} from '../utils/getSidebarMedia.js'
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import firebaseTimestamp from 'firebase'
import {mapActions,mapGetters} from 'vuex'

const db = firebase.firestore()

export default ({
  components:{
    // BulkmessageComponent,
    NewChat,
    firebaseData,
    Hooper,
    Slide
  },
  data(){
    return{
      searchType:'',
      searchOptions:['Image','Video','Document','Message'],
      acseptUserData:[],
      getUserData:[],
      socialMessage:[],
      activeChatListArray:[],
      chatObject:{},
      groupObject:{},
      chatListArray:[],
      userSearchList:[],
      getBulkData:[],
      bulkMemberList:[],
      sendingMessage:'',
      keyword:'',
      searchkeyword:'',
      searchContactArray:[],
       searchArray: [
        { title: "Photos" },
        { title: "Videos" },
        { title: "Links" },
        { title: "Documents" }
      ],
      showSearchList:false,
      closeSearchList:false,
      searchChatListArray:[],
      Mainsearchkeyword : '',
      textSearch : false,
      errorMessageForSearch : '',
      charecterErrorMessageForMessage:'',
      displayChat:false,
      botUser:{},
    }
  },
  created(){
    //  debugger // eslint-disable-line
    var self=this;
    self.chatListArray = self.socialChatList
    getBotuserData(firebaseData.firebasecollection.USER,(res) => {
      self.botUser = res;
    })
    // db.collection(firebaseData.firebasecollection.USER).where('email','==',Vue.prototype.$email).get().then(docs => {
    //         if(!docs.empty){
    //             docs.forEach(doc => {
    //                 self.botUser = doc.data();
    //             })
    //         }
    //     })
    self.searchChatListArray = []
    if(Object.keys(self.userData).length==0)
    {
      self.setUserStoreData(firebase.auth().currentUser.uid)
      .then(result=>{
        // console.log('getChatlist',result)
        self.getSetSocialChatList()
        // self.getSetBusinessChatList()
        // self.getSetIncomingRequests()
        self.getSetContactList()
        // self.getSetSocialContacts()
      })
    }else{
      self.getSetSocialChatList()
      // self.getSetBusinessChatList()
      // self.getSetIncomingRequests()
      self.getSetContactList()
    }
    self.$root.$on("hideAreaOfSidebar",(val) => {
      //  debugger // eslint-disable-line
        self.displayChat = val 
    })

    //  self.getContactList();
     self.init();
    
       self.$root.$on("groupcreate",(value)=>{
         //console.log('val',value)
         if(value == 'Yes'){
           self.socialMessage = [];
            self.acseptUserData=[];
            self.activeChatListArray = [];
          //  self.getContactList();
         }
      });

      self.$root.$on("grouplist",(value)=>{
         //console.log('grouplistvalue',value, self.chatListArray)
         if(value == 'Yes'){
           self.socialMessage = []
            self.acseptUserData=[];
            self.activeChatListArray = [];
          //  self.getContactList();
         }
      });

      self.$root.$on("changeGroupInfo",(objectid,chatId)=>{
         self.IsChatCustomer(objectid,chatId);
      })
      
  },
  watch : {
      $route : function (){
        // this.getContactList();
      }
  },
  methods:{
    //CHECK CHARECTER IN MESSAGE FEILD (Shraddha)
    checkChrecterInMessage(groupName){
      //  debugger // eslint-disable-lines
        var self = this;
        if(groupName.length == 5000){
          self.charecterErrorMessageForMessage = 'The message may not be greater than 5000 characters';
        }else{
          self.charecterErrorMessageForMessage = '';
        }
    },
    checkType(activeChatList){
      // debugger // eslint-disable-line
      if(activeChatList.lastmsg.type == 'SurpriseSelfie'){
        return 'Image'
      }
      else if(activeChatList.lastmsg.type=='Text' || activeChatList.lastmsg.type=='SurpriseSelfieText' ){
        return activeChatList.lastmsg.msg
      }
      else if(activeChatList.lastmsg.type == 'SelfieDeclienText'){
        {
          if(activeChatList.lastmsg.msg == firebase.auth().currentUser.uid){
            return 'You declined surprise selfie request'
          }else{
            return "Sorry, your request for surprise selfie has been declined"
          }
        }

      }
      else{
        return activeChatList.lastmsg.type
      }
      
    },
    getClasses()
    {
      var className = ''

      if(this.searchkeyword != '')
      {
        className += ' search_result-media ';
      }

      // console.log("this.searchData",this.Mainsearchkeyword)

      if(this.Mainsearchkeyword!= '')
      {
        className += ' '+this.Mainsearchkeyword+'-ul-wrapper ';
      }

      return className;
    },
    //Shiv methods start ****************
    ...mapActions({setUserStoreData:"setUserData"}),
    ...mapActions({getSetSocialChatList:"getSetSocialChatList"}),
    ...mapActions({getSetContactList:"getSetContactList"}),
    // ...mapActions({getSetBusinessChatList:"getSetBusinessChatList"}),
    // ...mapActions({getSetSocialContacts:"getSetSocialContacts"}),
    // ...mapActions({getSetChatMessages:"getSetChatMessages"}),
    ...mapActions({getSetChatData:"getSetSocialChatData"}),
    // ...mapActions({getSetIncomingRequests:"getSetIncomingRequests"}),
    checkHeader(searchArray){
    //  debugger // eslint-disable-line
      // console.log(searchArray);
      var count = 0;
      searchArray.forEach(data => {
        if(data.createDate > data.lastWeekEndDate && data.createDate <= new Date()){
          count++;
        }
      })
      return count
    },
    checkHeaders(searchArray){
    //  debugger // eslint-disable-line
      // console.log(searchArray);
      var count = 0;
      searchArray.forEach(data => {
        if(data.createDate >= data.lastWeekStartDate && data.createDate <= data.lastWeekEndDate){
          count++;
        }
      })
      return count
    },
    checkHeaderss(searchArray){
    //  debugger // eslint-disable-line
      // console.log(searchArray);
      var count = 0;
      searchArray.forEach(data => {
       
       if(data.createDate > data.lastMonthDate && data.createDate < data.lastWeekStartDate){
           count++;
        }
      })
      return count
    },
    checkRenderConditions(activeChatList)
    {
      var flag = false
      flag = activeChatList.isGroup && activeChatList.title != '' || !activeChatList.isGroup && activeChatList.firstName != activeChatList.lastName != ''
      if(activeChatList.block || activeChatList.isDeleted)
      {
        flag=false
      }
      return flag
    },
    getMainMessageCount(chatData)
    {
      // console.log('chatData',chatData)
      var total = 0
      var uid = firebase.auth().currentUser.uid

      //add main chat message seen counts
      // console.log('abcdCalled-',this.storeChatId,"-Test")
      if(this.storeChatId==chatData.msgid && this.storeChatId!="")
      {
        if(this.storeCollectionName != 'default')
        {
          chatData.messageSeenIds.forEach(data=>{
            if(data.id == uid)
            {
              total += data.count
            }
          })
        }
        return total
      }
      else
      {
        // console.log('Else')
        chatData.messageSeenIds.forEach(data=>{
          if(data.id == uid)
          {
            total += data.count
          }
        })
        // console.log("Count",total)
        return total
      }
    },
    getMessageCount(messageSeenIdsArray)
    {
      var uid = firebase.auth().currentUser.uid
      var count = 0
      messageSeenIdsArray.forEach(data=>{
        if(data.id==uid)
        {
          count = data.count
        }
      })

      return count
    },
    sortArray(mainBranchArray)
    {
      //console.log('sortCalled')
      // console.log('arr',mainBranchArray)

      var tmp = mainBranchArray||[]
      var tmp2 = ''

      for (let index = 0; index < tmp.length; index++) {
        for (let j = index+1; j < tmp.length; j++) {
          if(tmp[index].lastMsgTime.seconds < tmp[j].lastMsgTime.seconds)
          {
            tmp2 = tmp[index]
            tmp[index] = tmp[j]
            tmp[j]=tmp2
          }
        }
      }
      return tmp
    },
    openBranch(objectid,chatId,branchName,index)
    {
      var self = this
      var mainBranches = ''

      self.getSetChatData({collection:'socialMessages',chatId:chatId})
      .then(()=>{
        self.activeChatListArray.forEach(data=>{
          if(data.msgid==chatId)
          {
            mainBranches = self.sortArray(data.mainBranches)
          }
        })
        if(objectid != ''){
          db.collection(firebaseData.firebasecollection.USER).doc(objectid).get().then((doc) =>{
            self.chatObject = doc.data()
            self.groupObject = {}
            self.$root.$emit('openChat',self.chatObject,chatId,self.groupObject,mainBranches,branchName);
          })
        }else{
          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatId).get().then((doc) =>{
            self.groupObject = doc.data()
            self.chatObject = {}
            self.$root.$emit('openChat',self.chatObject,chatId,self.groupObject,mainBranches,branchName);
          })
        }
      })
      .catch(error=>{
        console.error(error);
      })
    },
    createBranchMethod(chatId)
    {
      // console.log(chatId)
      this.$bvModal.show('create-branch')
      this.$root.$emit('createBranchEvent',chatId)
    },
    checkMute(array)
    {
      var uid = firebase.auth().currentUser.uid
      var muted = false
      //console.log(array)

      array.forEach(data=>{
        if(data == uid)
        {
          muted = true
        }
        else
        {
          muted = false
        }
      })

      return muted
    },
    muteGroup(docId)
    {
      var self = this
      var muted = false
      var uid = firebase.auth().currentUser.uid
      var muteArrayData = []
      var mainBranchesData = []
      db
        .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
        .doc(docId)
        .get().then(doc=>{
          muteArrayData = doc.data().muteArray||[]

          mainBranchesData = doc.data().mainBranches

          muteArrayData.forEach(data=>{
            if(data == uid)
            {
              muted = true
            }
          })

          //console.log("Muted",muted)

          if(!muted)
          {
            muteArrayData.push(uid)

            mainBranchesData.forEach(data=>{
              var mainMuted = false
              data.muteArray.forEach(dt=>{
                if(dt == uid)
                {
                  mainMuted = true
                }
              })
              if(!mainMuted)
              {
                data.muteArray.push(uid)

                data.subBranches.forEach(data2=>{
                  var subMuted = false
                  data2.muteArray.forEach(dt2=>{
                    if(dt2 == uid)
                    {
                      subMuted = true
                    }
                  })
                  if(!subMuted)
                  {
                    data2.muteArray.push(uid)

                    data2.subSubBranches.forEach(data3=>{
                      var subSubMuted = false
                      data3.muteArray.forEach(dt3=>{
                        if(dt3 == uid)
                        {
                          subSubMuted = true
                        }
                      })
                      if(!subSubMuted)
                      {
                        data3.muteArray.push(uid)
                      }
                    })
                  }
                })
              }
            })
            //console.log('mainBranchesData123',mainBranchesData)
            db
              .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
              .doc(docId)
              .update({
                muteArray:muteArrayData,
                mainBranches:mainBranchesData
              })
              .then(()=>{
                self.$root.$emit("audioplay");
                self.$notify({
                  title:"Success",
                  text:'Mute successfully.',
                  type:'success'
                })
                // self.IsChatCustomer('',docId)
              })
              .catch(error=>{
                // console.log(error)
              })
          }
        })
        .catch(error=>{
          // console.log(error)
        })
    },
    unmuteGroup(docId)
    {
      var self = this
      var muted = false
      var uid = firebase.auth().currentUser.uid
      var muteArrayData = []
      var mainBranchesData = []
      db
        .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
        .doc(docId)
        .get().then(doc=>{
          muteArrayData = doc.data().muteArray||[]
          mainBranchesData = doc.data().mainBranches

          muteArrayData.forEach(data=>{
            if(data == uid)
            {
              muted = true
            }
          })

          if(muted)
          {
            for (let i = 0; i < muteArrayData.length; i++) {
              if(muteArrayData[i] == uid)
              {
                muteArrayData.splice(i,1)
              }
            }

            for (let i = 0; i < mainBranchesData.length; i++) {
              for (let j = 0; j < mainBranchesData[i].muteArray.length; j++) {
                if(mainBranchesData[i].muteArray[j]== uid)
                {
                  mainBranchesData[i].muteArray.splice(j,1)
                }
              }
              for (let l = 0; l < mainBranchesData[i].subBranches.length; l++) {
                for (let m = 0; m < mainBranchesData[i].subBranches[l].muteArray.length; m++) {
                  if(mainBranchesData[i].subBranches[l].muteArray[m]== uid)
                  {
                    mainBranchesData[i].subBranches[l].muteArray.splice(m,1)
                  }
                }
                for (let n = 0; n < mainBranchesData[i].subBranches[l].subSubBranches.length; n++) {
                  for (let o = 0; o < mainBranchesData[i].subBranches[l].subSubBranches[n].muteArray.length; o++) {
                    if(mainBranchesData[i].subBranches[l].subSubBranches[n].muteArray[o]== uid)
                    {
                      mainBranchesData[i].subBranches[l].subSubBranches[n].muteArray.splice(o,1)
                    }
                  }
                }
              }
            }
            
            db
              .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
              .doc(docId)
              .update({
                muteArray:muteArrayData,
                mainBranches:mainBranchesData
              })
              .then(()=>{
                self.$root.$emit("audioplay");
                self.$notify({
                  title:"Success",
                  text:'Unmuted successfully.',
                  type:'success'
                })
                // self.IsChatCustomer('',docId)
              })
              .catch(error=>{
                // console.log(error)
              })
          }
        })
        .catch(error=>{
          // console.log(error)
        })
    },
    //end *******************

    // CHECK IF LASTMESSAGE DATE IS PAST OR NOT(Shraddha)
    beforeDate(lastmessagedate){
      // debugger // eslint-disable-line
        var self=this;
       
          var Datesplit = lastmessagedate ? (new Date(lastmessagedate.seconds * 1000).getDate()) : ''
          var monthsplit = lastmessagedate ? (new Date(lastmessagedate.seconds * 1000).getMonth()) : ''
          var yearsplit = lastmessagedate ? (new Date(lastmessagedate.seconds * 1000).getFullYear()) : ''
          var todayDatesplit = new Date().getDate();
          var todayMonthsplit = new Date().getMonth();
          var todayYearsplit = new Date().getFullYear();
          var result = false
    
          if(todayDatesplit > Datesplit && todayMonthsplit >= monthsplit && todayYearsplit >= yearsplit)
          {
            // //console.log('else if')
            result = true;
          }
          
          else
          {
            // //console.log('else')
            result = false;
          }
       
      // //console.log('=========Expired:',result)
      return result
    },
     //GET CONTACT LIST(Shraddha)
        // getContactList(){
        //     // //debugger // eslint-disable-line
        //     var self=this;
        //     self.socialMessage = []
        //     self.activeChatListArray = [];
        //     self.acseptUserData=[];
        //     const db = firebase.firestore();
        //     var user = localStorage.getItem("customerDocKey");
        //     //console.log('useruid',user)
        //     // getChatListData(firebaseData.firebasecollection.USER,firebaseData.firebasecollection.SOCIALMESSAGE,firebase.auth().currentUser.uid,res=>{
        //     //   self.chatListArray = res
        //     //   self.searchContactArray = res
        //     //   console.log('self.chatListArray',res)

        //     //   self.chatListArray.forEach(dt=>{
        //     //     self.sortArray(dt.mainBranches)
        //     //   })
        //     // })
        //     getAcseptUserData(firebaseData.firebasecollection.USER,user,res=>{
        //       self.acseptUserData=res
        //       //console.log('xyz',self.acseptUserData);
           
        //       // db.collection(firebaseData.firebasecollection.USER).doc(user).collection('socialContact').onSnapshot(querySnapshot => {
        //       //    self.acseptUserData=[];
        //       //     querySnapshot.forEach (docdata => {
        //       //         self.acseptUserData.push({
        //       //             'docid':docdata.data().id,
        //       //             'chatId':docdata.data().chatId,
        //       //         })
        //       //     })
        //       //     //console.log(' self.acseptUserData', self.acseptUserData);
        //       // })
        //       getActiveChat(firebaseData.firebasecollection.SOCIALMESSAGE,self.socialMessage,res=>{
        //         self.socialMessage = res
        //         //console.log('abc',self.socialMessage)
        //         getCount(self.socialMessage,self.acseptUserData,self.activeChatListArray,user,res=>{
        //           self.activeChatListArray = res
        //           //console.log('def', self.activeChatListArray)
        //             getCounting(firebaseData.firebasecollection.USER,self.getUserData,self.activeChatListArray,res=>{
        //             self.chatListArray = res
        //             //console.log('ghi',self.chatListArray)
        //               getActiveMsgArray(firebaseData.firebasecollection.SOCIALMESSAGE,self.chatListArray,res=>{
        //                 self.chatListArray = res
        //                 self.searchContactArray = res
        //                 //console.log('self.chatListArray',self.chatListArray)

        //                 self.chatListArray.forEach(dt=>{
        //                   self.sortArray(dt.mainBranches)
        //                 })
        //               })
        //             })
        //         })
        //       })
        //     })
        // },
      // CHAT WITH CUSTOMER (Shraddha)
    IsChatCustomer(objectid,chatid){
        // debugger // eslint-disable-line
        var self=this;
        const db = firebase.firestore();

        self.getSetChatData({collection:'socialMessages',chatId:chatid})
        .then(()=>{
          var mainBranches = ''
          self.activeChatListArray.forEach(data=>{
            if(data.msgid==chatid)
            {
              mainBranches = data.mainBranches
            }
          })
          if(objectid != ''){
            db.collection(firebaseData.firebasecollection.USER).doc(objectid).get().then((doc) =>{
              self.chatObject = doc.data()
              self.groupObject = {}
              //console.log('Chat',self.chatObject)
              self.$root.$emit('openChat',self.chatObject,chatid,self.groupObject,mainBranches,"default");
              self.$root.$emit('chatObjefct',self.chatObject,chatid,self.groupObject,mainBranches);
            })
          }else{
            db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatid).get().then((doc) =>{
              self.groupObject = doc.data()
              self.chatObject = {}
              self.$root.$emit('chatObjefct',self.chatObject,chatid,self.groupObject,mainBranches);
              self.$root.$emit('openChat',self.chatObject,chatid,self.groupObject,mainBranches,"default");
            })
          }
        })
        .catch(error=>{
          console.error(error);
        })
    },
    // BLOCK CUSTOMER
    IsBlockCustomer(blockid,activeChatList,chatingId){
        // //debugger // eslint-disable-line
        var self=this;
        const db = firebase.firestore()
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
          Swal.fire({
              title: "Are you sure you want to block"+' '+activeChatList.firstName+' '+activeChatList.lastName+' '+"?",
              type: "warning",
              customClass:{
                container :"social_confirmation_popup"
              },
              showCancelButton: true,
              confirmButtonColor: "#ffab3f",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
        // //console.log(blockid);
            var user = firebase.auth().currentUser;
            //console.log(user.uid)
            var removeChatid = '';
            db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection('socialContact').doc(blockid).get().then(() => {
            // if(snap.data().chatId != undefined){
            //     removeChatid = snap.data().chatId
            //     db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(removeChatid).delete().then(()=>{
            //         db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection('socialBlockUser').doc(blockid).set({
            //             id:blockid
            //         }).then( () => {
            //             db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection('socialContact').doc(blockid).set({
            //                 block:true,
            //                 blockFrom : user.uid,
            //                 id:blockid
            //             }).then( () => {
            //                db.collection(firebaseData.firebasecollection.USER).doc(blockid).collection('socialContact').doc(user.uid).set({
            //                    id:user.uid
            //                }).then(()=>{
            //                   self.$notify({
            //                     title:"Success",
            //                     text:'Customer ',
            //                     type:"success"
            //                   })
            //                }) 
            //             })
            //         })  
            //     }) 
            // }else{
            db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection('socialBlockUser').doc(blockid).set({
                    id:blockid
                }).then( () => {
                    db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection('socialContact').doc(blockid).update({
                        block:true,
                        blockFrom : user.uid,
                    }).then( () => {
                      self.$root.$emit("audioplay");
                        self.$notify({
                            title:"Success",
                            text:activeChatList.firstName+' '+activeChatList.lastName+' blocked.',
                            type:"success"
                        })
                        self.$root.$emit('activeGroup' ,'yes');
                    })
                }) 
                if(chatingId != undefined){
                var blockArray = []
                  db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatingId).get().then(doc => {
                      blockArray = doc.data().blockUserArray || []
                      blockArray.push({
                        blockBy : user.uid,
                        blockTo : blockid
                      })
                    
                  }).then(() => {
                    db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatingId).update({
                      blockUserArray : blockArray
                    })
                  })
                } 
            // }
        })
      }
    })
    },
      //REMOVE FROM CONTACT LIST(Shraddha)
    removeFromContact(data){
        // //debugger // eslint-disable-line
        var self=this;
        var removeid = data.customer_id
        const db = firebase.firestore()
        // //console.log(removeid);
        var user = firebase.auth().currentUser;
        var removeChatid = ''
        //console.log(user.uid)
        db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection("socialContact").doc(removeid).get().then(snap => {
            if(snap.data().chatId != undefined){
                removeChatid = snap.data().chatId
                //console.log('removeChatid',removeChatid)
                db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(removeChatid).delete().then(()=>{
                    db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection("socialContact").doc(removeid).delete().then ( () => {
                        db.collection(firebaseData.firebasecollection.USER).doc(removeid).collection("socialContact").doc(user.uid).delete().then( () => {
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Success",
                                text:data.firstName+' '+data.lastName+' removed successfully.',
                                type:"success"
                            })
                            // self.getContactList();
                            self.$root.$emit('activeGroup' ,'yes');
                        });
                    });
                })
            }else{
                db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection("socialContact").doc(removeid).delete().then ( () => {
                    db.collection(firebaseData.firebasecollection.USER).doc(removeid).collection("socialContact").doc(user.uid).delete().then( () => {
                      db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(data.msgid).delete().catch((error) => console.error("ERROR while deleting chat: ", error));
                      self.$root.$emit("audioplay");
                      self.$notify({
                          title:"Success",
                          text:data.firstName+' '+data.lastName+' removed successfully.',
                          type:"success"
                      })
                      // self.getContactList();
                      self.$root.$emit('activeGroup' ,'yes');
                    });
                });
            }
        })
    },
    //FOR GET bULKMESSAGE LIST(Shraddha)
    init(){
      // debugger // eslint-disable-line
        var self=this;
        self.userSearchList = [];
        // self.chatListArray = self.socialChatList
        self.getBulkData = []
        // const db = firebase.firestore();
        // var user = localStorage.getItem("customerDocKey");
        // //console.log('useruid',user)
        Contactlist(firebaseData.firebasecollection.USER,self.userSearchList,res=>{
          self.userSearchList = res
          getContactCount(firebaseData.firebasecollection.USER,self.getBulkData,self.userSearchList,res=>{
            self.userSearchList = res
            //console.log('userSearchList',self.userSearchList)
              self.chatListArray = self.socialChatList
          })
        })
    },
      // ADD MEMBER FOR BRODCAST(Shraddha)
    addBrodcastMmeber(bulkobj){
      // debugger // eslint-disable-line
      var self = this;
      self.keyword = ''
      if(self.bulkMemberList.length > 0){
        let isInArray = self.bulkMemberList.some(function(item) {
            return item.docid == bulkobj.docid
        });
        if(isInArray == false){
          self.bulkMemberList.push(bulkobj);
          
        }else{
          self.$root.$emit("audioplay");
          self.$notify({
            title:"Error",
            text:'Already exists',
            type:"error"
          })
          
        }
      }else{
        self.bulkMemberList.push(bulkobj);
        
      }
    },
    // REMOVE MEMBER FROM BULKARRAY(Shraddha)
    removemember(id){
        // //debugger // eslint-disable-line
        var self = this;
        self.bulkMemberList.splice(id,1);
    },
    //SEND BULKMESSAGE(Shraddha)
    sendBulkMessage(){
        // debugger // eslint-disable-line
        var self = this;
        self.sendingMessage = self.sendingMessage.trim();
        if(self.sendingMessage != '' && self.charecterErrorMessageForMessage == ''){
          if(self.bulkMemberList.length != 0){
            self.$emit('OnupdateBulkData',self.bulkMemberList,self.sendingMessage);
            self.sendingMessage = ''
            self.keyword = ''
            self.bulkMemberList = []
            self.$refs.log.reset();
            self.$root.$emit("audioplay");
            self.$notify({
              title:"Success",
              text:'Bulk message successful.',
              type:"success"
            })
            self.$bvModal.hide('bulkmessage')
          }else{
            self.$root.$emit("audioplay");
            self.$notify({
              title:"Error",
              text:'Member list is required',
              type:"error"
            })  
          }
        }
    },
    //SEARCH IN MAIN PAGE (Shraddha)
    searchFilter(){
        // debugger // eslint-disable-line
        var self=this;
        self.showSearchList = false;
          self.errorMessageForSearch = '';
        if(self.Mainsearchkeyword == '' && self.searchkeyword != '' ){
          self.showSearchList = false;
          self.textSearch = true;
        }
        self.searchChatListArray = []
        var chatlistArray = []
        chatlistArray = self.socialChatList;
        // self.socialChatList = [];
        // self.chatListArray =[]
      
        var arr=[]
        chatlistArray .forEach(datas=>{
          
          // console.log('ABCD',datas)
          
          if(self.Mainsearchkeyword == 'Photos' && self.searchkeyword == ''){
            
            if(datas.messages.length > 0){
                var found = false
                  datas.messages.forEach(data =>{
                    if(data.type.toLowerCase() == 'image'){
                      found = true
                      var match = false
                        if(self.searchChatListArray.length == 0){
                        self.searchChatListArray.push({
                          firstName : data.firstName,
                          type : data.type,
                          mediaUrl : data.mediaUrl,
                          msg : data.msg,
                          name : data.name,
                          createdAt : data.createdAt,
                          createDate : data.createDate,
                          lastWeekStartDate : data.lastWeekStartDate,
                          lastWeekEndDate : data.lastWeekEndDate,
                          lastMonthDate : data.lastMonthDate
                        });
                      }else{
                          self.searchChatListArray.forEach(searchobject => {
                            if(searchobject.createDate == data.createDate && searchobject.mediaUrl == data.mediaUrl){
                              match = true
                            }
                          })
                          if(!match){
                            self.searchChatListArray.push({
                            firstName : data.firstName,
                            type : data.type,
                            mediaUrl : data.mediaUrl,
                            msg : data.msg,
                            name : data.name,
                            createdAt : data.createdAt,
                            createDate : data.createDate,
                            lastWeekStartDate : data.lastWeekStartDate,
                            lastWeekEndDate : data.lastWeekEndDate,
                            lastMonthDate : data.lastMonthDate
                          });
                          }
                      }
                    }else{
                      self.errorMessageForSearch = 'No result found.'
                    }
                  })  
                  if(found == true){
                      arr.push(datas);
                  } 
                  
            }
          }
          else if(self.Mainsearchkeyword == 'Documents' && self.searchkeyword == ''){
            if(datas.messages.length > 0){
                var found = false
                  datas.messages.forEach(data =>{
                    if(data.type.toLowerCase() == 'pdf' ||
                      data.type.toLowerCase() == 'excel' ||
                      data.type.toLowerCase() == 'word' ||
                      data.type.toLowerCase() == 'odt' ||
                      data.type.toLowerCase() == 'ods' ||
                      data.type.toLowerCase() == 'ppt' ||
                      data.type.toLowerCase() == 'html' ||
                      data.type.toLowerCase() == 'plain' ||
                      data.type.toLowerCase() == 'xd' 
                      ){
                      found = true
                      var match = false
                        if(self.searchChatListArray.length == 0){
                        self.searchChatListArray.push({
                          firstName : data.firstName,
                          type : data.type,
                          mediaUrl : data.mediaUrl,
                          msg : data.msg,
                          name : data.name,
                          createdAt : data.createdAt,
                          createDate : data.createDate,
                          lastWeekStartDate : data.lastWeekStartDate,
                          lastWeekEndDate : data.lastWeekEndDate,
                          lastMonthDate : data.lastMonthDate
                        });
                        }else{
                          self.searchChatListArray.forEach(searchobject => {
                            if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                              match = true
                            }
                          })
                          if(!match){
                            self.searchChatListArray.push({
                            firstName : data.firstName,
                            type : data.type,
                            mediaUrl : data.mediaUrl,
                            msg : data.msg,
                            name : data.name,
                            createdAt : data.createdAt,
                            createDate : data.createDate,
                            lastWeekStartDate : data.lastWeekStartDate,
                            lastWeekEndDate : data.lastWeekEndDate,
                            lastMonthDate : data.lastMonthDate
                          });
                          }
                        }
                    }else{
                      self.errorMessageForSearch = 'No result found.'
                    }
                  })
                  if(found == true){
                      arr.push(datas);
                      
                  }  
            }
          }
          else if(self.Mainsearchkeyword == 'Videos' && self.searchkeyword == ''){
            if(datas.messages.length > 0){
                var found = false
                  datas.messages.forEach(data =>{
                    if(data.type.toLowerCase() == 'video'){
                      found = true;
                        var match = false
                        if(self.searchChatListArray.length == 0){
                          self.searchChatListArray.push({
                            firstName : data.firstName,
                            type : data.type,
                            mediaUrl : data.mediaUrl,
                            msg : data.msg,
                            name : data.name,
                            createdAt : data.createdAt,
                            createDate : data.createDate,
                            lastWeekStartDate : data.lastWeekStartDate,
                            lastWeekEndDate : data.lastWeekEndDate,
                            lastMonthDate : data.lastMonthDate
                          });
                        }else{
                            self.searchChatListArray.forEach(searchobject => {
                            if(searchobject.createDate == data.createDate && searchobject.mediaUrl == data.mediaUrl){
                              match = true
                            }
                          })
                          if(!match){
                            self.searchChatListArray.push({
                              firstName : data.firstName,
                              type : data.type,
                              mediaUrl : data.mediaUrl,
                              msg : data.msg,
                              name : data.name,
                              createdAt : data.createdAt,
                              createDate : data.createDate,
                              lastWeekStartDate : data.lastWeekStartDate,
                              lastWeekEndDate : data.lastWeekEndDate,
                              lastMonthDate : data.lastMonthDate
                            });
                          }
                        }
                    }else{
                      self.errorMessageForSearch = 'No result found.'
                    }
                  })
                  if(found == true){
                      arr.push(datas);
                      
                  }  
            }
          }
          else if(self.Mainsearchkeyword == 'Links' && self.searchkeyword == ''){
            if(datas.messages.length > 0){
                var found = false
                  datas.messages.forEach(data =>{
                    if(data.type.toLowerCase() == 'link'){
                      found = true;
                      var match = false
                        if(self.searchChatListArray.length == 0){
                          self.searchChatListArray.push({
                            firstName : data.firstName,
                            type : data.type,
                            mediaUrl : data.mediaUrl,
                            msg : data.msg,
                            name : data.name,
                            createdAt : data.createdAt,
                            createDate : data.createDate,
                            lastWeekStartDate : data.lastWeekStartDate,
                            lastWeekEndDate : data.lastWeekEndDate,
                            lastMonthDate : data.lastMonthDate
                          });
                        }else{
                          self.searchChatListArray.forEach(searchobject => {
                            if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                              match = true
                            }
                          })
                          if(!match){
                            self.searchChatListArray.push({
                            firstName : data.firstName,
                            type : data.type,
                            mediaUrl : data.mediaUrl,
                            msg : data.msg,
                            name : data.name,
                            createdAt : data.createdAt,
                            createDate : data.createDate,
                            lastWeekStartDate : data.lastWeekStartDate,
                            lastWeekEndDate : data.lastWeekEndDate,
                            lastMonthDate : data.lastMonthDate
                          });
                          }
                        }
                    }else{
                      self.errorMessageForSearch = 'No result found.'
                    }
                  })
                  if(found == true){
                      arr.push(datas);
                  }  
            }
          }
          else if(self.Mainsearchkeyword == 'Photos' && self.searchkeyword != ''){
              if(datas.messages.length > 0){
                  var found = false
                  datas.messages.forEach(data =>{
                      if(data.type.toLowerCase() == 'image'){
                        if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                          found = true;
                          var match = false
                          if(self.searchChatListArray.length == 0){
                            self.searchChatListArray.push({
                                firstName : data.firstName,
                                type : data.type,
                                mediaUrl : data.mediaUrl,
                                msg : data.msg,
                                name : data.name,
                                createdAt : data.createdAt,
                                createDate : data.createDate,
                                lastWeekStartDate : data.lastWeekStartDate,
                                lastWeekEndDate : data.lastWeekEndDate,
                                lastMonthDate : data.lastMonthDate
                            });
                          }else{
                              self.searchChatListArray.forEach(searchobject => {
                            if(searchobject.createDate == data.createDate && searchobject.mediaUrl == data.mediaUrl){
                              match = true
                            }
                          })
                          if(!match){
                            self.searchChatListArray.push({
                            firstName : data.firstName,
                            type : data.type,
                            mediaUrl : data.mediaUrl,
                            msg : data.msg,
                            name : data.name,
                            createdAt : data.createdAt,
                            createDate : data.createDate,
                            lastWeekStartDate : data.lastWeekStartDate,
                            lastWeekEndDate : data.lastWeekEndDate,
                            lastMonthDate : data.lastMonthDate
                          });
                          }
                          }
                        }
                      }else{
                      self.errorMessageForSearch = 'No result found.'
                      }
                  })
                  if(found == true){
                      arr.push(datas)
                  }  
              }
          }
          else if(self.Mainsearchkeyword == 'Documents' && self.searchkeyword != ''){
            if(datas.messages.length > 0){
                var found = false
                  datas.messages.forEach(data =>{
                    if(data.type.toLowerCase() == 'pdf' ||
                      data.type.toLowerCase() == 'excel' ||
                      data.type.toLowerCase() == 'word' ||
                      data.type.toLowerCase() == 'odt' ||
                      data.type.toLowerCase() == 'ods' ||
                      data.type.toLowerCase() == 'ppt' ||
                      data.type.toLowerCase() == 'html' ||
                      data.type.toLowerCase() == 'plain' ||
                      data.type.toLowerCase() == 'xd' 
                    ){
                      if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                        found = true;
                        var match = false
                        if(self.searchChatListArray.length == 0){
                          self.searchChatListArray.push({
                            firstName : data.firstName,
                            type : data.type,
                            mediaUrl : data.mediaUrl,
                            msg : data.msg,
                            name : data.name,
                            createdAt : data.createdAt,
                            createDate : data.createDate,
                            lastWeekStartDate : data.lastWeekStartDate,
                            lastWeekEndDate : data.lastWeekEndDate,
                            lastMonthDate : data.lastMonthDate
                          });
                        }else{
                          self.searchChatListArray.forEach(searchobject => {
                            if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                              match = true
                            }
                          })
                          if(!match){
                            self.searchChatListArray.push({
                            firstName : data.firstName,
                            type : data.type,
                            mediaUrl : data.mediaUrl,
                            msg : data.msg,
                            name : data.name,
                            createdAt : data.createdAt,
                            createDate : data.createDate,
                            lastWeekStartDate : data.lastWeekStartDate,
                            lastWeekEndDate : data.lastWeekEndDate,
                            lastMonthDate : data.lastMonthDate
                          });
                          }
                        } 
                      }
                    }else{
                      self.errorMessageForSearch = 'No result found.'
                    }
                  })
                  if(found == true){
                      arr.push(datas);
                      
                  }  
            }
          }
          else if(self.Mainsearchkeyword == 'Videos' && self.searchkeyword != ''){
            if(datas.messages.length > 0){
                var found = false
                  datas.messages.forEach(data =>{
                    if(data.type.toLowerCase() == 'video'){
                      if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                        found = true;
                        var match = false
                        if(self.searchChatListArray.length == 0){
                          self.searchChatListArray.push({
                            firstName : data.firstName,
                            type : data.type,
                            mediaUrl : data.mediaUrl,
                            msg : data.msg,
                            name : data.name,
                            createdAt : data.createdAt,
                            createDate : data.createDate,
                            lastWeekStartDate : data.lastWeekStartDate,
                            lastWeekEndDate : data.lastWeekEndDate,
                            lastMonthDate : data.lastMonthDate
                          });
                        }else{
                            self.searchChatListArray.forEach(searchobject => {
                            if(searchobject.createDate == data.createDate && searchobject.mediaUrl == data.mediaUrl){
                              match = true
                            }
                          })
                          if(!match){
                            self.searchChatListArray.push({
                              firstName : data.firstName,
                              type : data.type,
                              mediaUrl : data.mediaUrl,
                              msg : data.msg,
                              name : data.name,
                              createdAt : data.createdAt,
                              createDate : data.createDate,
                              lastWeekStartDate : data.lastWeekStartDate,
                              lastWeekEndDate : data.lastWeekEndDate,
                              lastMonthDate : data.lastMonthDate
                            });
                          }
                        }
                      }
                    }else{
                      self.errorMessageForSearch = 'No result found.'
                    }
                  })
                  if(found == true){
                      arr.push(datas);
                      
                  }  
            }
          }
          else if(self.Mainsearchkeyword == 'Links' && self.searchkeyword != ''){
            if(datas.messages.length > 0){
                var found = false
                  datas.messages.forEach(data =>{
                    if(data.type.toLowerCase() == 'link'){
                      if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                        found = true;
                        var match = false
                        if(self.searchChatListArray.length == 0){
                          self.searchChatListArray.push({
                            firstName : data.firstName,
                            type : data.type,
                            mediaUrl : data.mediaUrl,
                            msg : data.msg,
                            name : data.name,
                            createdAt : data.createdAt,
                            createDate : data.createDate,
                            lastWeekStartDate : data.lastWeekStartDate,
                            lastWeekEndDate : data.lastWeekEndDate,
                            lastMonthDate : data.lastMonthDate
                          });
                        }else{
                          self.searchChatListArray.forEach(searchobject => {
                            if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                              match = true
                            }
                          })
                          if(!match){
                            self.searchChatListArray.push({
                            firstName : data.firstName,
                            type : data.type,
                            mediaUrl : data.mediaUrl,
                            msg : data.msg,
                            name : data.name,
                            createdAt : data.createdAt,
                            createDate : data.createDate,
                            lastWeekStartDate : data.lastWeekStartDate,
                            lastWeekEndDate : data.lastWeekEndDate,
                            lastMonthDate : data.lastMonthDate
                          });
                          }
                        }
                      }
                    }else{
                      self.errorMessageForSearch = 'No result found.'
                    }
                  })
                  if(found == true){
                      arr.push(datas);
                  }  
            }
          }
          else{
            if(!datas.isGroup)
            {
              var fullname = datas.firstName+' '+datas.lastName
              if(datas.firstName.toLowerCase().includes(self.searchkeyword.toLowerCase()) || datas.lastName.toLowerCase().includes(self.searchkeyword.toLowerCase()) || fullname.toLowerCase().includes(self.searchkeyword.toLowerCase()))
              {
                
                // arr.push(datas)
                // console.log('log1',arr);
                  
              }
              
              else{
                  if(datas.messages.length > 0){
                    var found = false
                    
                    datas.messages.forEach(data =>{
                      if(data.type.toLowerCase() == 'text' ){
                        if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                          found = true;
                          var match = false;
                          if(self.searchChatListArray.length == 0){
                            self.searchChatListArray.push({
                                firstName : data.firstName,
                                type : data.type,
                                mediaUrl : data.mediaUrl,
                                msg : data.msg,
                                name : data.name,
                                createdAt : data.createdAt,
                                createDate : data.createDate,
                                lastWeekStartDate : data.lastWeekStartDate,
                                lastWeekEndDate : data.lastWeekEndDate,
                                lastMonthDate : data.lastMonthDate
                            });
                          }else{
                              self.searchChatListArray.forEach(searchobject => {
                            if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                              match = true
                            }
                          })
                          if(!match){
                              self.searchChatListArray.push({
                              firstName : data.firstName,
                              type : data.type,
                              mediaUrl : data.mediaUrl,
                              msg : data.msg,
                              name : data.name,
                              createdAt : data.createdAt,
                              createDate : data.createDate,
                              lastWeekStartDate : data.lastWeekStartDate,
                              lastWeekEndDate : data.lastWeekEndDate,
                              lastMonthDate : data.lastMonthDate
                            });
                          }
                          }
                        }else{
                          self.errorMessageForSearch = 'No result found.'
                        }
                      }
                    })
                    if(found == true){
                        arr.push(datas)
                    } 
                  }
                  // console.log('log4',arr); 
              }
            }
            else if(datas.isGroup)
            {
              if(datas.title.toLowerCase().includes(self.searchkeyword.toLowerCase()))
              {
                
                // arr.push(datas)
                // console.log('log5',arr);
                  
              }
              else {
                if(datas.messages.length > 0){
                  var found = false
                  datas.messages.forEach(data =>{
                      if(data.type.toLowerCase() == 'text' ){
                        if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                          found = true;
                          
                          var match = false;
                          if(self.searchChatListArray.length == 0){
                        
                          self.searchChatListArray.push({
                                firstName : datas.title,
                                type : data.type,
                                mediaUrl : data.mediaUrl,
                                msg : data.msg,
                                name : data.name,
                                createdAt : data.createdAt,
                                createDate : data.createDate,
                                lastWeekStartDate : data.lastWeekStartDate,
                                lastWeekEndDate : data.lastWeekEndDate,
                                lastMonthDate : data.lastMonthDate
                            });
                            }else{
                              self.searchChatListArray.forEach(searchobject => {
                            if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                              match = true
                            }
                          })
                          if(!match){
                              self.searchChatListArray.push({
                              firstName : datas.title,
                              type : data.type,
                              mediaUrl : data.mediaUrl,
                              msg : data.msg,
                              name : data.name,
                              createdAt : data.createdAt,
                              createDate : data.createDate,
                              lastWeekStartDate : data.lastWeekStartDate,
                              lastWeekEndDate : data.lastWeekEndDate,
                              lastMonthDate : data.lastMonthDate
                            });
                          }
                          }
                          }else{
                            self.errorMessageForSearch = 'No result found.'
                          }
                      }
                  })
                  if(found == true){
                      arr.push(datas);
                  }  
                }
                // console.log('log6',arr);
              }
        
            }
          }

        })  
        
        
        if(arr.length > 0){
          chatlistArray  = arr
          self.chatListArray = arr
        
        }
        if(arr.length == 0){
          self.chatListArray = arr
          chatlistArray = []
          
        }
        if(self.searchkeyword == ''){
          self.chatListArray = self.socialChatList
        }
        // self.getDisplayChatList = self.chatListArray
        // console.log('arr',arr,self.chatListArray)
    },
      // SEARCH CLICK FOR ARRAY DISPLAY FOR SEARCHING(Shraddha)
    searchClick(){
      // debugger // eslint-disable-line
      var self = this;
      if(self.Mainsearchkeyword == ''){
      self.showSearchList = true;
      // self.searchkeyword = '';
      self.errorMessageForSearch = '';
      
      }else{
        self.showSearchList = false;
        // self.searchkeyword = '';
        self.errorMessageForSearch = '';
      }
      
      
    },
    // ADD VALUE IN SEARCHBAR (Shraddha)
    addInSearchBar(title){
      //  debugger // eslint-disable-line
      var self = this;
      self.Mainsearchkeyword = title;
      self.closeSearchList = true;
      self.searchChatListArray = [];
      self.searchkeyword = '';
      self.searchFilter();
      self.showSearchList = false;
      self.errorMessageForSearch = '';
    },
    // CLOSE SERACH BAR(Shraddha)
    closeSearchBar(){
      var self = this;
      self.Mainsearchkeyword = '';
      self.showSearchList = false;
      self.closeSearchList = false;
      self.searchChatListArray = [];
      self.searchkeyword = '';
      self.errorMessageForSearch = '';
    },
    //OPEN SEARCHBAR (Shraddha)
    openSearchBar(){
      var self = this;
      self.showSearchList = true;
      self.searchkeyword = '';
      self.errorMessageForSearch = '';
    },
    // SET BULKMESSAGE FOCUS (Shraddha)
    setBulkMesagesFocus(){
      // //debugger // eslint-disable-line
      setTimeout(()=>{
        this.$nextTick(() => {
            this.$refs.bulkmessage.focus();
        })
      },1000)
    },
    //SET CHATMESSAGE FOCUS(Shraddha)
    setchatMesagesFocus(){
      var self=this;
      self.$root.$emit('setchatfocus','yes');
    },
    //EXIT FROM GROUP(Shraddha)
    confirmationPopup(groupData)
    {
      var self = this
      // console.log('groupData',groupData,groupData.adminId == firebase.auth().currentUser.uid)
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      if(groupData.isGroup)
      {
        if(groupData.adminId == firebase.auth().currentUser.uid)
        {
          Swal.fire({
            title: "Are you sure you want to delete the "+groupData.title+' group'+"?",
            type: "warning",
              customClass:{
              container :"social_confirmation_popup"
            },
            showCancelButton: true,
            confirmButtonColor: "#ffab3f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              self.deleteGroup(groupData)
            }
          })
        }
        else
        {
          Swal.fire({
            title: "Are you sure you want to exit the "+groupData.title+' group'+"?",
            type: "warning",
              customClass:{
              container :"social_confirmation_popup"
            },
            showCancelButton: true,
            confirmButtonColor: "#ffab3f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              self.isExitGroup(groupData)
            }
          })
        }
      }
      else
      {
        const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
        Swal.fire({
            title: `${groupData.firstName} ${groupData.lastName} will be removed from your chat contacts, Are you sure you want to remove this chat?`,
            type: "warning",
              customClass:{
              container :"social_confirmation_popup"
            },
            showCancelButton: true,
            confirmButtonColor: "#ffab3f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
        }).then((result) => {
          if (result.value) {
            self.removeFromContact(groupData)
          }
        })
      }
    },
    isExitGroup(groupData){
      // //debugger // eslint-disable-line
      var self = this;
      //console.log('groupDatagroupDatagroupData',groupData)
      var chatId = groupData.msgid
      var userData = self.userData
      var objectid = ''
      var chattedId = ''
      var uid = firebase.auth().currentUser.uid
      const db = firebase.firestore()
      var loginUser = localStorage.getItem('customerDocKey')
      
      exitGroup(loginUser,groupData.connectedUser,res => {
        //console.log('rererererer',res)
        var from={
          avatar:'',
          id:userData.id,
          name:`${userData.firstName} ${userData.lastName} left.`,
          path:''
        }
        groupData.messageSeenIds.forEach(data=>{
          if(data.id != self.userid)
          {
            data.count++
          }
        })
        //remove user from messageSeenIds
        var messageSeenIds = groupData.messageSeenIds||[]
        messageSeenIds.forEach((data,index)=>{
          if(data.id == uid)
          {
            messageSeenIds.splice(index,1)
          }
        })

        //remove user from mainBranches messageSeenIds
        var mainBranches = groupData.mainBranches||[]
        mainBranches.forEach((data)=>{
          data.messageSeenIds.forEach((msgid,index)=>{
            if(msgid.id == uid)
            {
              data.messageSeenIds.splice(index,1)
            }
          })
          data.subBranches.forEach((data2)=>{
            data2.messageSeenIds.forEach((msgid2,index2)=>{
              if(msgid2.id == uid)
              {
                data2.messageSeenIds.splice(index2,1)
              }
            })
            data2.subSubBranches.forEach((data3)=>{
              data3.messageSeenIds.forEach((msgid3,index3)=>{
                if(msgid3.id == uid)
                {
                  data3.messageSeenIds.splice(index3,1)
                }
              })
            })
          })            
        })
        // console.log(`${userData.firstName} ${userData.lastName} left.`,from)
        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatId).collection('default').add({
          createdAt:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
          messageSeen:[from],
          msg:`${userData.firstName} ${userData.lastName} left.`,
          from:from,
          type:'Left',
          name:'Left',
        })
        .then((docRef)=>{
          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatId).collection('default').doc(docRef.id).update({
            id:docRef.id
          })
        })
          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatId).update({
            connectedUser : res,
            messageSeenIds:messageSeenIds,
            mainBranches:mainBranches
          }).then(()=>{
            self.$root.$emit('activeGroup' ,'yes');
            self.$root.$emit('hideViewDetail',objectid,chattedId)
            self.$root.$emit("audioplay");
            self.$notify({
              title:"Success",
              text:'Group delete Successfully',
              type:"success"
            })
          })
      })
    },
    deleteGroup(groupData){
      var self = this
      db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
        .doc(groupData.msgid)
        .update({
          isDeleted:true
        })
        .then(()=>{
          self.$root.$emit('activeGroup' ,'yes');
          self.$root.$emit('hideViewDetail','','')
          self.$root.$emit("audioplay");
          self.$notify({
            title:"Success",
            text:'Group delete Successfully',
            type:"success"
          })
        })
        .catch(error=>{
          // console.log(error)
        })
    },
    sendSidebarData(chatid){
      // debugger // eslint-disable-line
      var self=this;
        self.groupObject = {}
      const db = firebase.firestore()
        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatid).get().then((doc) =>{
          self.groupObject = doc.data()
          self.getSetChatData({collection:'socialMessages',chatId:chatid})
          self.$root.$emit('sendgroupArray','default',self.groupObject,chatid)
        })
    },
    // BULK MESSAGE CLOSE EVENT(Shraddha)
    closeFire(){
      var self = this;
      self.sendingMessage = '';
      self.keyword = ''
      self.bulkMemberList = []
      self.$refs.log.reset();
    },
    //DOWNLOAD DOCUMENT (Shraddha) 
    downloadDocument(url,name){
      // console.log("url,,,,,,,,,",url);
      var naming = name.split('.');      
      this.$axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download',name);
            document.body.appendChild(fileLink);
            fileLink.click(); 
        })
        .catch(error=>{
            this.$notify({
                title:"Error",
                text:error.message,
                type:"error"
            })           
        })
    },
    //CHECK DATE FOR SEARCH(Shraddha)
    checkDate(expireyTimeObjects){ 
      // console.log('expireyTimeObjects',expireyTimeObjects)
      var expireyTimeObjectsdate = (new Date(expireyTimeObjects).getDate());
      var expireyTimeObjectsmonth = (new Date(expireyTimeObjects).getMonth());
      var expireyTimeObjectsyear = (new Date(expireyTimeObjects).getFullYear());

      var todayDatesplit = new Date().getDate();
      var todayMonthsplit = new Date().getMonth();
      var todayYearsplit = new Date().getFullYear();
      // console.log(expireyTimeObjectsdate,expireyTimeObjectsmonth,expireyTimeObjectsyear,todayDatesplit,todayMonthsplit,todayYearsplit)
      if(expireyTimeObjectsdate == todayDatesplit && expireyTimeObjectsmonth == todayMonthsplit &&expireyTimeObjectsyear == todayYearsplit)
      {
        return this.addZero(new Date(expireyTimeObjects).getHours())+':'+this.addZero(new Date(expireyTimeObjects).getMinutes())
      }
      else if(expireyTimeObjectsdate+1 == todayDatesplit && expireyTimeObjectsmonth == todayMonthsplit &&expireyTimeObjectsyear == todayYearsplit)
      {
        return 'Yesterday'
      }
      else
      {
        return this.addZero(expireyTimeObjectsdate)+'/'+ this.addZero(expireyTimeObjectsmonth+1)
      }
    },
    addZero(val)
    {
        if(val<10)
        {
          return '0'+val;
        }
        else
        {
          return val;
        }
    },
    //MAIN BRANCH DELETE (Shraddha)
    MainbranchDelete(mainbranch,BranchName,chatId,msgid){
      // debugger // eslint-disable-line
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      Swal.fire({
          title: 'Are you sure you want to delete branch?',
          type: "warning",
          customClass:{
              container :"social_confirmation_popup"
          },
          showCancelButton: true,
          confirmButtonColor: "#ffab3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
      }).then((result) => {
          if (result.value) {
          var self = this;
        
          var allBranch = [];
          allBranch.push(BranchName)
          var index = mainbranch.findIndex(item => {
              return item.name == BranchName
          })
          
          mainbranch.forEach((data) => {
            if(mainbranch[index].subBranches){
              var subbranches = mainbranch[index].subBranches
              var found = false;
              subbranches.forEach((subdata) => {
                allBranch.forEach(foundData => {
                  if(foundData == subdata.name){
                    found = true;
                  }
                })
                if(!found){
                allBranch.push(subdata.name)
                  if(subdata.subSubBranches){
                    var subSubBranch = subdata.subSubBranches
                    var match = false;
                    subSubBranch.forEach((subSubData => {
                      allBranch.forEach(foundDatas => {
                        if(foundDatas == subSubData.name){
                          match = true;
                        }
                      })
                      if(!match){
                        allBranch.push(subSubData.name)
                      }
                    }))
                  }
                }
              })
            }
          })

          mainbranch.splice(index,1);
          
          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatId).update({
            mainBranches : mainbranch, 
          }).then(() => {
            // console.log('mainbranchdelete',allBranch,mainbranch,BranchName)
            allBranch.forEach(deleteBranchName => { 
              db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatId).collection(deleteBranchName).get().then(docData => {
                docData.forEach(doc => {
                  db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatId).collection(deleteBranchName).doc(doc.id).delete();
                })
              })
            })
          })
          if(msgid != ''){
            db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatId).collection('default').doc(msgid).update({
              branch : ''
            })
          }
          self.$root.$emit("audioplay");
          self.$notify({
            title:"Success",
            text:"Branch delete successfully.",
            type:"success"
          })
          self.$root.$emit('mainBranchActiveFalse',chatId);
        }
      })
      
    },
    // COUNT ALL BRANCH (Shraddha)
    countAllbranch(branchData,branchString){
      // debugger // eslint-disable-line
      var self = this
      var mainCount = 0; 
      var subCount = 0;
      var subSubCount = 0;
      var uid = firebase.auth().currentUser.uid
      // console.log(branchData,branchString)
        if(branchString == 'mainbranch'){
          if(branchData.name != self.currCollection){
            branchData.messageSeenIds.forEach(ids=>{
              if(ids != 0){
                if(ids.id == firebase.auth().currentUser.uid)
                {
                  mainCount+=ids.count
                }
              }
            })
          }
          if(branchData.subBranches){
            branchData.subBranches.forEach(data2=>{
              if(data2.name != self.currCollection){
                data2.messageSeenIds.forEach(ids2=>{
                  if(ids2 != 0){
                    if(ids2.id == firebase.auth().currentUser.uid)
                    {
                      subCount+= ids2.count
                    }
                  }
                })
              }
              if(data2.subSubBranches){
                data2.subSubBranches.forEach(data3=>{
                  if(data3.name != self.currCollection){
                    data3.messageSeenIds.forEach(ids3=>{
                      if(ids3 != 0){
                        if(ids3.id == firebase.auth().currentUser.uid)
                        {
                          subSubCount+= ids3.count
                        }
                      }
                    })
                  }
                })
              }
            })
          }
        }
        if(branchString == 'mainbranch'){
          return (mainCount + subCount + subSubCount);
        }
        else if(branchString == 'subbranch'){
          return (subCount + subSubCount);
        }
        else if (branchString == 'subsubbranch'){
          return subSubCount;
        }
    },
    // PLUS DATACOUNT IN MAIN (Shraddha)
    getTotalCount(chatData){
      //  debugger // eslint-disable-line
      var self = this
      var count = 0;
      var mainCount = 0; 
      var subCount = 0;
      var subSubCount = 0;
      var uid = firebase.auth().currentUser.uid
      if(chatData.blockBy != uid){
        chatData.messageSeenIds.forEach(ids=>{
          if(ids != 0){
            if(ids.id === firebase.auth().currentUser.uid)
            {
              count+=ids.count
            }
          }
        })
        if(chatData.mainBranches){
          chatData.mainBranches.forEach(data=>{
            data.messageSeenIds.forEach(ids=>{
              if(ids != 0){
                if(ids.id === firebase.auth().currentUser.uid)
                {
                  mainCount+=ids.count
                }
              }
            })
            if(data.subBranches){
              data.subBranches.forEach(data2=>{
                data2.messageSeenIds.forEach(ids2=>{
                  if(ids2 != 0){
                    if(ids2.id === firebase.auth().currentUser.uid)
                    {
                      subCount+= ids2.count
                    }
                  }
                })
                if(data2.subSubBranches){
                  data2.subSubBranches.forEach(data3=>{
                    data3.messageSeenIds.forEach(ids3=>{
                      if(ids3 != 0){
                        if(ids3.id === firebase.auth().currentUser.uid)
                        {
                          subSubCount+= ids3.count
                        }
                      }
                    })
                  })
                }
              })
            }
          })
        }
        return count + mainCount + subCount + subSubCount;
      }
    }
  },
   //LISTING DATA (Shraddha)
  computed: {
    ...mapGetters({userData:"getUserData"}),
    ...mapGetters({storeChatId:"getChatId"}),
    ...mapGetters({socialChatList:"getSocialChatList"}),
    ...mapGetters({storeCollectionName:"getCurrentCollection"}),
    ...mapGetters({socialContactForBulk:"getSocialContactList"}),
    // getSocialContactList
     
    getFilterData() {
      var self = this;
       console.log('self.socialContactForBulk',self.socialContactForBulk)
      return self.socialContactForBulk
        .filter(function(rawData) {
          console.log('Raw',rawData)
          return (
            rawData["FullName"].toLowerCase().indexOf(self.keyword.toLowerCase()) >= 0
          );
        });
    },
    getDisplayChatList(){
      var self = this;
      self.chatlistArray = self.socialChatList;
      return self.socialChatList.filter(function(rawData) {
        if(rawData.isGroup){
        return (
          rawData["title"]
            .toLowerCase()
            .indexOf(self.searchkeyword.toLowerCase()) >= 0
          )
        }else{
          return (
          rawData["fullname"]
            .toLowerCase()
            .indexOf(self.searchkeyword.toLowerCase()) >= 0
          )
        }
      });
    }
  },
})
</script>
<style scoped>
.greenDot{
  background-color: #85DA27;
  /* height: 20px;
  width: 20px; */
  border-radius: 24px;
  vertical-align: middle;
  /* margin-left: 10px; */
  color: white;
  display: inherit;
}
.greenDot label {
  height: 100%;
  width: 100%;
  text-align: center;
  line-height: 25px;
  font-size: 11px;
  font-weight: 500;
}
.greenDot.orange_label {
       background-color: #85DA27;
      color: #000;
      line-height: 24px;
}
.redDot{
  background-color: #808080;
  height: 8px;
  width: 8px;
  border-radius: 10px;
  vertical-align: middle;
  margin-left: 10px;
  display: inherit;
}

.searchCollapseClass li{
  border-bottom: 1px solid;
  padding: 4px 10px;
  cursor:pointer;
}
.searchCollapseClass li:last-child{
  border-bottom: none;
}
.searchCollapseClass{
  position: fixed;
  background: white;
  width: 172px;
  z-index: 10;
}
.user_search_list ul
{
  height:auto!important;
}
</style>