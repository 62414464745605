<template>
    <div>
        <b-card no-body>
            <b-tabs pills card vertical class="main_tab_div" v-model="tabIndex">

            <b-tab title="Dashboard" :class="{active : tabIndex == 0}" @click="applyFilters(),changeMenu(0)" >
                <b-card-text>
                  <AdminDashboard class="main_content_admin"></AdminDashboard>
                  <AdminFooter></AdminFooter>
                </b-card-text>
            </b-tab>
            
            <b-tab title="User Management" :class="{active : tabIndex == 1}" @click="redirectUsermanagment(),changeMenu(1)">
                <b-card-text>
                    <ContentManagement class="main_content_admin"></ContentManagement>     
                    <AdminFooter></AdminFooter> 
                </b-card-text>
            </b-tab>
           
            <b-tab title="Manage Notification" :class="{active : tabIndex == 2}" @click="changeMenu(2)">
                <b-card-text>
                    <ManageNotification class="main_content_admin"></ManageNotification>
                    <AdminFooter></AdminFooter>
                </b-card-text>
            </b-tab>
            <b-tab title="Revenue Management" :class="{active : tabIndex == 3}" @click="changeMenu(3)">
                <b-card-text>
                    <RevenueNotification class="main_content_admin"></RevenueNotification>
                      <AdminFooter></AdminFooter>
                </b-card-text>
            </b-tab>
            <b-tab title="Report"  @click.prevent="clickStartFilter(),changeMenu(4)" :class="{active : tabIndex == 4}">
                <b-card-text>
                    <AdminReport class="main_content_admin"></AdminReport>
                    <AdminFooter></AdminFooter>
                </b-card-text>
            </b-tab>
            <b-tab title="Feedback"  :class="{active : tabIndex == 5}" @click="redirectUsermanagment(),changeMenu(5)">
                <b-card-text>
                    <Feedback class="main_content_admin"></Feedback>
                    <AdminFooter></AdminFooter>
                </b-card-text>
            </b-tab>
              <b-tab title="Settings" id="setting" :class="{active : tabIndex == 6}" @click="changeMenu(6)">
                <b-card-text>
                    <AdminSetting class="main_content_admin"></AdminSetting>
                    <AdminFooter></AdminFooter>
                </b-card-text>
            </b-tab>
            </b-tabs>
        </b-card>
        
</div>
</template>
<script>
import ContentManagement from "@/views/Admin_Panel/ContentManagement.vue";
// import ContentManagementMain from "@/views/Admin_Panel/ContentManagementMain.vue";
import AdminDashboard from "@/views/Admin_Panel/Admin_deshboard.vue";
import PackageManagementMain from "@/views/Admin_Panel/PackageManagementMain.vue";
import AdminSetting from "@/views/Admin_Panel/AdminSetting.vue";
import InvestorsSignup from "@/views/Admin_Panel/Investors_Signup.vue";
import ManageNotification from "@/views/Admin_Panel/Manage_Notification.vue";
import RevenueNotification from "@/views/Admin_Panel/Revenue_Notification.vue";
import AdminReport from "@/views/Admin_Panel/Admin_Report.vue";
import AdminFooter from "@/components/adminlayout/adminfooter.vue";
import Feedback from "@/views/Admin_Panel/Feedback.vue";
export default {
    components:{
        ContentManagement,
        AdminDashboard,
        PackageManagementMain,
        AdminSetting,
        InvestorsSignup,
        ManageNotification,
        RevenueNotification,
        AdminReport,
        AdminFooter,
        Feedback
    },
    data(){
        return{
            tabIndex:0
        }
    },
    created(){
        var self = this;
        self.tabIndex = localStorage.getItem("AdminMenu");
            if(self.tabIndex != undefined && self.tabIndex != ''){
                if(self.tabIndex == '0'){
                    self.tabIndex = 0;  
                }else{
                    self.tabIndex = Number(self.tabIndex);
                } 
            }else{
                self.tabIndex = 0;
            } 
        self.changeMenu(self.tabIndex);
        self.$root.$on('openDashboard',(val) => {
            // debugger // eslint-disable-line
            self.changeMenu(val);
        })
    },
    mounted(){
        setTimeout(() => {
            this.clickStartFilter();
        },1000)
    },
    methods:{
        applyFilters(){
            this.$root.$emit('adminDashboardFilters');
        },
        clickStartFilter(){
            this.$root.$emit('clickStartReportFilter','week');
        },
        redirectUsermanagment(){
            var self=this;
            self.$root.$emit('isUserDetailView',false);
        },
        changeMenu(index){
            // debugger // eslint-disable-line
            var self = this;
            localStorage.setItem("AdminMenu",index);
            self.tabIndex = localStorage.getItem("AdminMenu");
            if(self.tabIndex == '0'){
              self.tabIndex = 0;  
            }else{
              self.tabIndex = Number(self.tabIndex);
            }
        }
    }
}
</script>