<template>
<div>
  <Header v-if="isAdmin == false" :imageUrl= userData :chatObject= chatObject :activeGroup = activeGroup :activeChat= activeChat :groupData="groupData"></Header>
    <section class="profile-wrapper user_profile">
        <div class="container-fluid">
            <div class="row">
                <div class="backbtn-wrapper">
                   <button class="btn btn-white btn-back" @click="backtoContact()"><img src="@/assets/images/tag-arrow.png" class="back-arrow"> Back To Chat</button>
                </div>
                <div class="col-md-2">
                    <div class="profile-email-wrapper">
                       <img :src="customerData.profileUrl" class="useprofile_pic">
                    </div>
                </div>
                <div class="col-md-10">
                  <div class="myprofile-form_wrapper">
                    <div class="user-profile-title">
                        <h4 style="position:relative;">{{customerData.firstName}} {{customerData.lastName}}<div v-if="customerData.isOnline" class="greenDots" style="height:8px;width: 8px;margin-top: 7px;"></div></h4>
                        
                        <!-- <button class="btn btn-blue">Send Business Contact Request</button> -->
                    </div>

                    <div class="row">
                        <div class="col-sm-3">
                            <div class="user-data">
                                <b>Email</b>
                                <p><a href="mailto:">{{customerData.email}}</a></p>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="user-data">
                                <b>Phone</b>
                                <p><a :href="'tel:'+customerData.phoneNo">{{customerData.phoneNo}}</a></p>
                                
                            </div>
                        </div>
                         <div class="col-sm-3" >
                            <div class="user-data">
                                <b>Business Name</b>
                                <p v-if="customerData.BusinessName">{{customerData.BusinessName}}</p>
                                <p v-else>-</p>
                            </div>
                        </div>
                         <div class="col-sm-3" >
                            <div class="user-data">
                                <b>Job title</b>
                                <p v-if="customerData.jobTitle">{{customerData.jobTitle}}</p>
                                <p v-else>-</p>
                            </div>
                        </div>
                        <div class="col-sm-7">
                            <div class="user-data">
                                <b>Bio</b>
                                <p v-if="customerData.BioDiscription">{{customerData.BioDiscription}}</p>
                                <p v-else>-</p>
                            </div>
                        </div>
                        <div class="col-sm-3"></div>
                        <div class="col-sm-7">
                            <div class="btn-wrapper">
                                 <button class="btn btn-blue" @click="removeFromContactList(customerData)" v-if="storeSelectedCompany.isAdmin">Remove</button>
                                 <button class="btn btn-white black" v-if="blockstatus != true" @click="IsBlockCustomer(customerData.id,customerData,chatId)">Block</button>
                                <button class="btn btn-white black" v-else @click="unblockUser(customerData)">Unblock</button> 
                            </div>
                        </div>
                    </div>
                    
                  </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import Vue from 'vue'
import Header from "@/components/businessComponent/businessHeader.vue";
import firebase from "../../views/firebaseInit"
import firebaseData from '../../utils/firebaseCollection.js';
import {getLoginUserData} from "@/utils/getQueeryActiveChatList.js";
import Swal from 'sweetalert2'
import {mapGetters} from 'vuex'
const db = firebase.firestore()
export default {
  name: "businessuserprofile",
  components: {
    Header,
    firebaseData
  },
    data(){
      return{
          customerData:{},
          imageUrl:{},
          userData:{},
          loginUser:'',
          isAdmin:false,
          activeGroup:false,
           groupData:{},
           chatObject:{},
           activeChat:false,
           blockstatus:'',
           chatid:'',
           chatId:''
      }
    },
    created(){
        var self=this;
        self.getCustomerData();
      const db = firebase.firestore()
       // GET PROFILE PICTURE (Shraddha)
      self.userid = localStorage.getItem('customerDocKey');
    getLoginUserData(firebaseData.firebasecollection.USER,(res) => {
        self.userData = res;
        self.imageUrl = res.profileUrl;
        // console.log('self.userData',self.userData,self.imageUrl)
    })
      // CHECK LOGIN USER IS ADMIN OR CUSTOMER(Shraddha)
      self.loginUser = localStorage.getItem("IsLoginUser")
    //   console.log('loginUser',self.loginUser);
      if(this.loginUser == 'Admin'){
        self.isAdmin = true
      }else{
        self.isAdmin = false
      }
    //   console.log(this.isAdmin)
    },
    watch : {
        $route : function (){
            this.getCustomerData();
        }
    },
    methods:{
         //UNBLOCK USER CODE(Shraddha)
        unblockUser(blockuser,index){
            // debugger // eslint-disable-line
            var self=this
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
            Swal.fire({
                title: 'are you sure you want to unblock'+' '+blockuser.firstName+' '+blockuser.lastName+ '?',
                type: "warning",
                customClass:{
                    container :"business_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: "#77bef6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, unblock it!",
            }).then((result) => {
                if (result.value) {
                    // console.log(firebase.auth().currentUser.uid)
                    db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockuser.id).get().then(doc => {
                        //console.log('doc',doc)
                        if(doc.data().chatId != undefined){
                            self.chatid = doc.data().chatId
                        }else{
                        self.chatid = '' 
                        }
                    }).then(() => {
                        var blockArray = []
                        if(self.chatid != ''){
                            db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockuser.id).set({
                                id: blockuser.id,
                                chatId : self.chatid
                            }).then(()=>{
                                db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockuser.id).delete().then(()=>{
                                self.$root.$emit("audioplay");
                                self.$notify({
                                        title:"Success",
                                        text:blockuser.firstName+' '+blockuser.lastName+' unblocked.',
                                        type:"success"
                                    }) 
                                     self.$root.$emit('activeGroup' ,'yes');
                                })
                            })
                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatid).get().then(doc => {
                                blockArray = doc.data().blockUserArray
                                var index = blockArray.findIndex(product => {
                                    return product.blockBy == firebase.auth().currentUser.uid && product.blockTo ==  blockuser.id
                                })
                                blockArray.splice(index,1);
                            }).then(() => {
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(self.chatid).update({
                                    blockUserArray : blockArray,
                                    
                                })
                            })
                        }else{
                        db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockuser.id).set({
                                id: blockuser.id,
                            }).then(()=>{
                                db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockuser.id).delete().then(()=>{
                                self.$root.$emit("audioplay");
                                self.$notify({
                                        title:"Success",
                                        text:blockuser.firstName+' '+blockuser.lastName+' unblocked.',
                                        type:"success"
                                    }) 
                                })
                                 self.$root.$emit('activeGroup' ,'yes');
                            }) 
                        }
                    })
                }
            })
        },
        //GET CUSTOMER DATA FROM USER COLLECTION USING ROUTE ID(Shraddha)
        getCustomerData(){
            // //debugger // eslint-disable-line
            var self=this;
            self.customerData = {}
            const db = firebase.firestore()
            var key = self.$route.params.id;
            db.collection(firebaseData.firebasecollection.USER).doc(key).
            onSnapshot((doc)=>{
                if(doc.exists) {
                    self.customerData = doc.data()
                } else {
                    self.$router.replace("/chat");
                }
                // console.log('self.customerData',self.customerData )
            })
            db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(self.$route.params.id).
            onSnapshot(doc=>{
                self.blockstatus = doc.data().block || ''
                self.chatId = doc.data().chatId || ''
                
            })
        },
        // REMOVE USER FROM CONTACT LIST(Shraddha)
        removeFromContactList(data){
           // //debugger // eslint-disable-line
            var self=this;
            var removeid = data.id
            const db = firebase.firestore()
            // console.log(removeid);
            var user = firebase.auth().currentUser;
            var removeChatid = ''
            // console.log(user.uid)
            db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(removeid).get().then(snap => {
                if(snap.data().chatId != undefined){
                    removeChatid = snap.data().chatId
                    // console.log('removeChatid',removeChatid)
                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(removeChatid).delete().then(()=>{
                        db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(removeid).delete().then ( () => {
                            db.collection(firebaseData.firebasecollection.USER).doc(removeid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(user.uid).delete().then( () => {
                                self.$root.$emit("audioplay");
                                self.$notify({
                                    title:"Success",
                                    text:data.firstName+' '+data.lastName+' removed successfully.',
                                    type:"success"
                                })
                               self.backtoContact();
                            });
                        });
                    })
                }else{
                    db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(removeid).delete().then ( () => {
                        db.collection(firebaseData.firebasecollection.USER).doc(removeid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(user.uid).delete().then( () => {
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Success",
                                text:data.firstName+' '+data.lastName+' removed successfully.',
                                type:"success"
                            })
                            self.backtoContact('contact');
                        });
                    });
                }
            })
        },
      // BLOCK USER (Shraddha)
        IsBlockCustomer(blockid,customerData,chatingId){
             debugger // eslint-disable-line
            var self=this;
            const db = firebase.firestore()
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
          Swal.fire({
              title:"Are you sure you want to block"+' '+customerData.firstName+' '+customerData.lastName+' '+"?",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#77bef6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
            // console.log(blockid);
            var user = firebase.auth().currentUser;
            // console.log(user.uid)
            var removeChatid = '';
            db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockid).get().then(() => {
                // if(snap.data().chatId != undefined){
                //     removeChatid = snap.data().chatId
                //     db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(removeChatid).delete().then(()=>{
                //         db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockid).set({
                //             id:blockid
                //         }).then( () => {
                //             db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockid).set({
                //                 block:true,
                //                 blockFrom : user.uid,
                //                 id:blockid
                //             }).then( () => {
                //                db.collection(firebaseData.firebasecollection.USER).doc(blockid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(user.uid).set({
                //                    id:user.uid
                //                }).then(()=>{
                // self.$root.$emit("audioplay");//                     
                // self.$notify({
                //                     title:"Success",
                //                     text:'customer is block successfully',
                //                     type:"success"
                //                 })
                //                 self.$root.$emit('activeGroup' ,'yes');
                //                }) 
                //             })
                //         })  
                //     }) 
                // }else{
                  db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockid).set({
                        id:blockid
                    }).then( () => {
                        db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockid).update({
                            block:true,
                            blockFrom : user.uid,
                        }).then( () => {
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Success",
                                text:customerData.firstName+' '+customerData.lastName+' blocked.',
                                type:"success"
                            })
                            self.$root.$emit('activeGroup' ,'yes');
                        })
                    })
                    if(chatingId != undefined){
                    var blockArray = []
                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatingId).get().then(doc => {
                        blockArray = doc.data().blockUserArray || []
                        blockArray.push({
                            blockBy : user.uid,
                            blockTo : blockid
                        })
                        
                    }).then(() => {
                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatingId).update({
                        blockUserArray : blockArray
                        })
                    })
                    }      
                // }
            })
            }
          })
        },
        // FOR BACK TO HOME
        backtoContact(){
           // //debugger // eslint-disable-line
       
           this.$router.push('/businesschat'); 
        }
    },
    computed:{
        ...mapGetters({storeSelectedCompany:"getSelectedCompany"}),
    }
};
</script>
<style>
.greenDots{
  background-color: #85DA27;
  border-radius: 24px;
  vertical-align: middle;
  color: white;
  display: inherit;
  position: absolute;
  top: -2px;
  right: -15px;
}
</style>