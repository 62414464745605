<template>
    <div class="manage_notification_wrapper">
        <div class="admin_Page_title">
                <h4>Manage Notification</h4> 
                <img class="title_icon" @click="backtodashboard()" src="@/assets/images/home_icon.png" alt="homeIcon">
                <span class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
                <span class="current_page">Manage Notification</span>
                <!-- <span v-if="isPackageDivShow == true " class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
                <span v-if="isPackageDivShow == true && isEditForm == false "  class="current_page">Add New Package</span>
                <span v-if="isPackageDivShow == true && isEditForm == true " class="current_page">Update Package</span> -->
        </div>
        <div class="managenotification_table_wrapper">
            <div class="admin_Page_title">
                <h4>Notification</h4> 
                <button class="btn btn-yellow" v-b-modal.modal-center>Send new Notification</button>
            </div>
            <div class="invester_table manage_notification table_wrapper">
                <table class="table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Message</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(rawData, indextr) in  tableListData[currentPage]" v-bind:key="indextr">
                            <td>{{(currentPage*10)+(indextr+1) &lt; 10 ?'0'+(indextr+1):(currentPage*10)+(indextr+1)}}</td>
                            <td>{{rawData.message}}</td>
                            <td><img src="@/assets/images/delete_grey.png" @click.prevent="deleteMessage(indextr)"></td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="notifications.length==0" class="no-result-found" style="float: none;">
                    No Data Found
                </div>
                
            </div>
            
        </div>
        <div class="pagination_wrapper" v-show="totalpages>1">
            <ul class="pagination">
                <li @click="(changePage('prev'))"><a><img src="@/assets/images/left-arrow-pagination.png"></a></li>
                <li v-for="(data,index) in totalpages" :key="index" @click="currentPage=index" :class="currentPage==index?'active':''">
                    <a>{{data}}</a>
                </li>
                <li @click="(changePage('next'))"><a><img src="@/assets/images/right-arrow-pagination.png"></a></li>
            </ul>
        </div>

        <b-modal id="modal-center" class="modal-contact" hide-footer centered @close="closeFire()" :no-close-on-backdrop="true">
            <div class="contact-modal">
                <div class="contact-modal">
                    <h4>Send New Notification</h4>
                    <!-- <p>SEND NOTIFICATION TO</p> -->
                    <ValidationObserver ref="log" v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(sendMessage)">
                        <span v-if="planerror!= ''" style="color:red;">{{planerror}}</span>
                        <ValidationProvider rules="" name="social plan" v-slot="{ errors }">
                            <div class="form-group">
                                <!-- <label>Email / Phone *</label> -->
                                <div>
                                    <div class="solcial_plan_checkboxWrapper">
                                        <h5>Social Plan</h5>
                                        <div style="display:flex;">
                                            <input type="checkbox" id="socialAll" name="socialAll" @change="selectAllFileTypeSocial($event)" style="margin-right:5px;"> All
                                            <div v-for="(dt,ind) in planSocial" :key="ind">
                                                <span  class="custom-selectCheck" style="margin-left:15px;">
                                                    <input type="checkbox"  :name="dt.plan_id" v-model="planSelectedSocial" :value="dt.plan_id" @change="addFileTypeSocial()"> {{dt.plan_name}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="InvalidInput" style="justify-content:center">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="" name="business plan" v-slot="{ errors }">
                            <div class="form-group">
                                <div>
                                <div class= "business_plan_checkboxWrapper" >
                                    <h5>Business Plan</h5>
                                    <div style="display:flex;">
                                        <input type="checkbox" id="businessAll" name="businessAll" @change="selectAllFileTypeBusiness($event)" style="margin-right:5px;" > All
                                        <div v-for="(dt,index) in planBusiness" :key="index" >
                                            <span  class="custom-selectCheck"  style="margin-left:15px;">
                                                <input type="checkbox"  :name="dt.plan_id" v-model="planSelectedBusiness" :id="dt.plan_id" :value="dt.plan_id" @change="addFileTypeBusiness()"> {{dt.plan_name}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span class="InvalidInput" style="justify-content:center">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required|min:3" name="message" v-slot="{ errors }">
                        <div class="form-group" style="margin-top:15px">
                            <h5>Message*</h5>
                            <input type="text" name="message" style="width:100%;" placeholder="Enter message" v-model="message">
                            <span class="InvalidInput">{{ errors[0]=='The message field must be at least 3 characters'?'Minimum 3 character is required.':errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                        <div class="btn-wrapper">
                            <button type="submit" class="btn btn-yellow" v-bind:class="{disabled: isSpinner == true}">Send</button>
                        </div>
                    </form>
                    </ValidationObserver>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import firebaseData from '../../utils/firebaseCollection.js'
import firebaseTimestamp from "firebase";
import firebase from "../firebaseInit";
import Swal from 'sweetalert2'
const db = firebase.firestore()

export default {
    name:"manage_notification",
    data(){
        return{
            plans:[],
            planSelected:[],
            isSpinner:false,
            message:'',
            notifications:[],
            totalpages:0,
            currentPage:0,
            tableListData:[],
            planSelectedSocial:[],
            planSelectedBusiness:[],
            planSocial:[],
            planBusiness : [],
            planerror:''
        }
    },
    created(){
        var self = this;
        self.getNotification()
        self.getPlans()
    },
    methods:
    {
        getPlans()
        {
            // debugger // eslint-disable-line
            var self = this
            self.planSocial = [];
            self.planBusiness = [];
            db.collection(firebaseData.firebasecollection.PLANMANAGEMENT)
            .get()
            .then(querySnapshot=>{
                if(!querySnapshot.empty)
                {
                    querySnapshot.forEach(doc=>{
                        if(doc.data().plan_type == 'social'){
                            self.planSocial.push({
                                plan_name:doc.data().plan_name,
                                plan_id:doc.data().plan_id,
                                plan_type:doc.data().plan_type
                            })
                        }
                        if(doc.data().plan_type == 'business'){
                            self.planBusiness.push({
                                plan_name:doc.data().plan_name,
                                plan_id:doc.data().plan_id,
                                plan_type:doc.data().plan_type
                            })
                        }
                    })
                    
                }
                else
                {
                    self.planSocial = [
                        {plan_name:'Core',plan_id:'246',plan_type:'social'},
                        {plan_name:'Pro',plan_id:'247',plan_type:'social'}
                    ]
                    self.planbusiness=[
                        {plan_name:'Pro',plan_id:'249',plan_type:'business'},
                        {plan_name:'Bolt On',plan_id:'250',plan_type:'business'},
                        {plan_name:'Start Up',plan_id:'248',plan_type:'business'},
                        {plan_name:'Add on',plan_id:'251',plan_type:'business'}
                    ]
                }
            })
            .catch(error=>{
                console.log(error)
            })
        },
        addFileTypeSocial()
        {
            // debugger // eslint-disable-line
            this.planerror = '';
            if(this.planSelectedSocial.length == this.planSocial.length){
                document.getElementById('socialAll').checked = true;
            }else{
                document.getElementById('socialAll').checked = false;
            }
        },
        addFileTypeBusiness()
        {
            this.planerror = '';
            if(this.planSelectedBusiness.length == this.planBusiness.length){
                document.getElementById('businessAll').checked = true;
            }else{
                document.getElementById('businessAll').checked = false;
            }
        },
        selectAllFileTypeSocial(event){
            this.planSelectedSocial = [];
            this.planerror = '';
            if(event.target.checked == true){
                for(var i=0; i<this.planSocial.length;i++){
                    this.planSelectedSocial.push(this.planSocial[i].plan_id);
                    console.log('social',this.planSocial[i].plan_id)
                }
            }
        },
        selectAllFileTypeBusiness(event){
            this.planSelectedBusiness = [];
            if(event.target.checked == true){
                for(var i=0; i<this.planBusiness.length;i++){
                    this.planSelectedBusiness.push(this.planBusiness[i].plan_id);
                    console.log('business',this.planBusiness[i].plan_id)
                }
            }
        },
        sendMessage()
        {
            // debugger // eslint-disable-line
            var self = this
            self.planSelected = [];
            var message = self.message
            self.planSelected = self.planSelectedSocial.concat(self.planSelectedBusiness);
            if(self.planSelected.length > 0 && self.planerror == ''){
                self.planerror = '';
                // console.log('All plans',self.planSelected)
                self.isSpinner = true
                message = message.trim()
                self.$axios.post(firebaseData.firebasecollection.APIDOMAIN +'/sendMessage',{plans:self.planSelected,message:message})
                .then(result=>{
                    // console.log(result)
                    self.$notify({
                        title:"Success",
                        text:'Notification sent successfully to selected plan users.',
                        type:"success"
                    })
                    self.$refs.log.reset();
                    self.$bvModal.hide('modal-center')
                    db.collection(firebaseData.firebasecollection.NOTIFICATIONS)
                        .add({
                            createdAt:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                            message:message,
                        })
                        .then((docRef)=>{
                            db.collection(firebaseData.firebasecollection.NOTIFICATIONS)
                                .doc(docRef.id)
                                .update({
                                    id:docRef.id
                                })
                                .then(()=>{
                                    
                                    self.planSelected=[];
                                    self.isSpinner = false;
                                    self.closeFire();
                                    self.getNotification();
                                })
                                .catch(error=>{
                                    console.log(error)
                                    self.isSpinner = false
                                })
                        })
                        .catch(error=>{
                            console.log(error)
                            self.isSpinner = false
                        })

                })
                .catch(error=>{
                console.log(error)
                self.$notify({
                    title:"Error",
                    text:error.message,
                    type:"error"
                })
                self.isSpinner = false
            })
            }else{
                self.planerror = 'Plan selection is required';
            }
        },
        closeFire(){
            this.planSelectedSocial=[];
            this.planSelectedBusiness=[];
            this.message=''
            this.$refs.log.reset();
            this.planerror = '';
        },
        getNotification()
        {
            // debugger // eslint-disable-line
            var self = this
            db.collection(firebaseData.firebasecollection.NOTIFICATIONS)
                .orderBy('createdAt')
                .onSnapshot(querySnapshot=>{
                    self.notifications=[]
                    if(!querySnapshot.empty)
                    {
                        querySnapshot.forEach(doc=>{
                            self.notifications.push(doc.data())
                        })
                    }
                    self.pagination(self.notifications)
                })
        },
        deleteMessage(index)
        {
            var self = this
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
            Swal.fire({
                title: "Are you sure want to delete it?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ffab3f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    var ind = (self.currentPage*10)+(index)
                    console.log(ind,self.notifications[ind])
                    db.collection(firebaseData.firebasecollection.NOTIFICATIONS)
                    .doc(self.notifications[ind].id)
                    .delete()
                    .then(()=>{
                        self.$notify({
                            title:"Success",
                            text:'Notification message deleted successfully.',
                            type:"success"
                        })
                        self.getNotification()
                    })
                    .catch(error=>{
                        console.log(error)
                    })
                }
            })
        },
        pagination(notifications)
        {
            var self = this
            var index = 1
            var dataArray=[]
            self.tableListData=[]
            notifications.forEach(data=>{
                dataArray.push(data)
                if(index % 10 == 0)
                {
                    self.tableListData.push(dataArray)
                    dataArray=[];
                }
                else if(index == notifications.length)
                {
                    self.tableListData.push(dataArray)
                    dataArray=[];
                }

                index++;
            })
            self.totalpages=self.tableListData.length
        },
        changePage(direction){
            if(direction =='prev' && this.currentPage>0){
                this.currentPage--
            }
            if(direction =='next' && this.currentPage<this.totalpages-1){
                this.currentPage++
            }
        },
        //BACK TO DASHBOARD (Shraddha)
        backtodashboard(){
            var self = this;
            self.$root.$emit('openDashboard',0);
        }
    }
}
</script>