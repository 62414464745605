<!-- Shraddha Chaniyara, 22/06/2021 added validation and update new password in db. -->

<template>
    <div class="resetpassword-wrapper login-wrapper" >
        <div class="logo">
            <router-link to="/"><img src="@/assets/images/logo.png" class="logo-login"></router-link>
        </div>
      
        <form  class="login-form c_form"  style="position:relative;">
            <!-- <sync-loader style="text-align: center;" v-if="showLoader" :loading="true" color="#ffab3f"></sync-loader> -->
             <!-- <div class="loader_div" >
                <img src="@/assets/images/Orange_GIF.gif" class="lodar_gif">
            </div> -->
            <div  class="form-title text-center">
                <h2>Account Verification Pending</h2>
                <img src="@/assets/images/afterimg.png">
                <!-- <h5 style="margin-top:30px">Please verify your account by clicking on verification link sent to your Email.</h5> -->
                <h5 style="margin-top:30px">We have sent a verification email to {{email}} please open to confirm registration</h5>
            </div>
            <div class="btn-wrapper">
                <router-link to="/"><button class="btn btn-yellow">Go to Login page</button></router-link>
            </div>
        </form>
        <FrontFooter/>
    </div>
</template>

<script>

import FrontFooter from '@/components/forntfooter.vue'
import firebase from './firebaseInit'
import firebaseData from '../../src/utils/firebaseCollection.js';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'
const db = firebase.firestore()

export default {
 name:"verifyPage",
    components:{
        FrontFooter,
        firebaseData,
        SyncLoader
    },
    data(){
        return{
            routid:this.$route.params.id,
            email:''
        }
    },
    created(){
      db.collection(firebaseData.firebasecollection.USER).doc(this.routid).get().then(doc=>{
          this.email = doc.data().email
      })
    },
    methods:{
     
      
    }
}
</script>