<template>
    <div>
        <!-- <b-dropdown id="admin_sb">
            <template #button-content>
                <h5>Alian Software<span>Admin</span></h5>
            </template>
            <b-dropdown-item href="#">Alian Software</b-dropdown-item>
            <b-dropdown-item href="#">Business General </b-dropdown-item>
        </b-dropdown> -->
        <a class="link_add-channel" v-b-modal.add-channel v-if="selectedActiveMenu=='admin' && storeSelectedCompany.isAdmin">+ Add Channel</a>
        <div class="business_contactList-area" >
            <div class="search-area" v-if="selectedActiveMenu=='admin'">
                <form :class="{'sidebar_serach_data' : !showSearchList || showSearchList || textSearch}"  @submit.prevent><div class="form-group">
                  <label class="selected_label" v-if="Mainsearchkeyword"  > {{Mainsearchkeyword}} <button type="button" class="opensidebarbtn"  @click="openSearchBar()">X</button></label>

                   
                    <input type="search" placeholder="Search" class="form-control" @click="searchClick()"  @keyup="searchFilter()"
                    v-model="searchkeyword">
                    <button class=""  @click.prevent><img src="@/assets/images/search.png" title="Find"></button>
                    <button type="button" v-if="closeSearchList" @click="closeSearchBar()">X</button>
                    </div>
                    <div v-if="showSearchList" class="all_media_name_wrapper">
                     
                      <ul>
                        <li v-for="(searchData,index) in searchArray" v-bind:key="index" @click="addInSearchBar(searchData.title)">
                          {{searchData.title}}
                        </li>
                      </ul>
                    </div> 
                </form>
            </div>
            <div class="search-area" v-else-if="selectedActiveMenu=='adminChat'">
              <form :class="{'sidebar_serach_data' : !showSearchList || showSearchList || textSearch}"  @submit.prevent>
                <div class="form-group">
                    <label class="selected_label businessSelected" v-if="Mainsearchkeyword"  > {{Mainsearchkeyword}} <button type="button" class="opensidebarbtn"  @click="openSearchBar()">X</button></label>
                   
                  <input
                    type="search"
                    class="form-control"
                    placeholder="Search"
                    v-model="searchkeyword"
                    @click="searchClick()"
                    @keyup="searchFilter()"
                  />
                  <button  @click.prevent><img src="@/assets/images/search.png" title="Find"/></button>
                  <button type="button" v-if="closeSearchList" @click="closeSearchBar()">X</button>
                </div>
                <div v-if="showSearchList" class="all_media_name_wrapper">
                  <ul>
                    <li v-for="(searchData,index) in  searchArray" v-bind:key="index" @click="addInSearchBar(searchData.title)">
                      {{searchData.title}}
                    </li>
                  </ul>
                </div>
              </form>
              <img src="@/assets/images/blue-chat-plus.png" v-if="searchChatListArray.length == 0 && !showSearchList" title="Create group" v-b-modal.new-chat/>
            </div>
            <a class="link_create-group" v-b-modal.new-chat v-if="selectedActiveMenu=='admin'" @click.prevent="callGroup()">+ Create Group</a>
           <!-- {{getDisplayChatList.length}}
           {{searchChatListArray.length}} 
           {{adminDisplayArray.length}}
           {{errorMessageForSearch}}
           {{errorMessageForSearchAdmin}}
           {{Mainsearchkeyword}}  -->
           
            <div class="business-active-chat-list active-chat-list" v-if="searchChatListArray.length == 0 && adminDisplayArray.length == 0  && Mainsearchkeyword == ''" >
              <span style="padding:10px; color:red;" v-if="searchkeyword != '' &&  getDisplayChatList.length == 0  ">No Result found.</span>

              <h5 v-if="selectedActiveMenu=='adminChat'">Direct Message</h5>
              <ul>
               <!-- {{searchChatListArray.length}}
               {{adminDisplayArray.length}}
               {{errorMessageForSearch}}
               {{errorMessageForSearchAdmin}} -->
                <!-- {{chatListArray}} -->
                <li v-for="(activeChatList,index) in getDisplayChatList" v-bind:key="index" v-bind:style="activeChatList.block?'display:none':''" :class="{'cls-bg-blue': getTotalCount(activeChatList) > 0 && activeChatList.showMessageCount, 'deleteduser':activeChatList.isDelete}">
                  <div class="chat-list-bottom-border" v-if="checkRenderConditions(activeChatList)">
                    <div class="user-profile-info" style="cursor:pointer" @click.prevent="IsChatCustomer(activeChatList.isGroup || activeChatList.isChannel ? '' : activeChatList.customer_id,activeChatList.msgid)">
                      <div class="u-img-time" style="cursor:pointer">
                        <!-- <img v-if="activeChatList.isGroup == false && activeChatList.profileUrl" class="chatprofile" :src="activeChatList.profileUrl"/> -->
                        <!-- <span v-if="activeChatList.isGroup == false && activeChatList.profileUrl == '' " class="chatprofile profilecharecter">{{activeChatList.firstName.charAt(0).toUpperCase()}}</span> -->
                        <!-- <img v-if="activeChatList.isChannel == true && activeChatList.groupProfile" class="chatprofile" :src="activeChatList.groupProfile"/>
                        <span v-if="activeChatList.isChannel == true && activeChatList.groupProfile == '' " class="chatprofile profilecharecter">{{activeChatList.title.charAt(0).toUpperCase()}}</span> -->
                        <img v-if="activeChatList.groupProfile" class="chatprofile" :src="activeChatList.groupProfile"/>
                        <span v-if="activeChatList.groupProfile == '' " class="chatprofile profilecharecter">{{activeChatList.title.charAt(0).toUpperCase()}}</span>
                        <div>
                          <!-- <span class="g-name" v-if="activeChatList.isChannel == false">{{activeChatList.firstName}} {{activeChatList.lastName}} 
                          </span> -->
                          <span class="g-name" style="display: flex;" v-if="activeChatList.isChannel">
                            <span v-if="(activeChatList.title.length) <= 15">{{activeChatList.title}}</span>
                            <span :title="(activeChatList.title)" v-else>{{(activeChatList.title).slice(0, 15)+'...'}}</span>
                            <!-- {{activeChatList.title}} -->
                            
                            <div v-if="activeChatList.isChannel && getTotalCount(activeChatList)>0" class="greenDot blue-count" style="height:24px;width: 24px;">
                              <label v-if="getTotalCount(activeChatList)>0" style="">{{getTotalCount(activeChatList)>9?'9+':getTotalCount(activeChatList)}}</label>
                            </div>
                          </span>
                          <span class="g-name" style="display: flex;" v-else>
                             <span v-if="(activeChatList.title.length) <= 15">{{activeChatList.title}}</span>
                            <span :title="(activeChatList.title)" v-else>{{(activeChatList.title).slice(0, 15)+'...'}}</span>
                            <!-- {{activeChatList.title}} -->
                            <div v-if="activeChatList.isGroup && getTotalCount(activeChatList)>0" class="greenDot blue-count" style="height:24px;width: 24px;">
                              <label v-if="getTotalCount(activeChatList)>0" style="">{{getTotalCount(activeChatList)>9?'9+':getTotalCount(activeChatList)}}</label>
                            </div>
                          </span>
                          <!-- <div v-if="activeChatList.lastmsgTime">
                          
                            <span class="g-timedate" v-if="!beforeDate(activeChatList.lastmsgTime)" >{{activeChatList.lastMsgArrivedTime}}</span>
                            <span class="g-timedate" v-else>{{activeChatList.lastMsgDate}}</span>
                          </div>
                          <div v-else>
                            <span class="g-timedate">{{activeChatList.currentTime}}</span>
                          </div> -->
                        </div>
                      </div>
                      <div class="d-flex m_icon_wrapper">
                        <div class="m_icon" v-if="checkMute(activeChatList.muteArray)">
                          <img src="@/assets/images/group-muted.png">
                        </div>
                      <b-dropdown id="dropdown-offset" text="" class="">
                          <b-dropdown-item href="#" @click.prevent="sendSidebarData(activeChatList.msgid)"  v-b-toggle.sidebar-right>Rename</b-dropdown-item>
                          <b-dropdown-item href="#" v-if="!checkMute(activeChatList.muteArray||[])" @click.prevent="muteGroup(activeChatList.msgid)">Mute</b-dropdown-item>
                          <b-dropdown-item href="#" v-else @click.prevent="unmuteGroup(activeChatList.msgid)">Unmute</b-dropdown-item>
                          <b-dropdown-item href="#" @click.prevent="confirmationPopup(activeChatList)">Delete</b-dropdown-item>
                      </b-dropdown>
                        <!-- <b-dropdown id="dropdown-offset" text="" class="" v-if="activeChatList.isGroup == false">
                          <li> <router-link v-bind:to="{ name: 'businessuserprofile', params: { id: activeChatList.customer_id }}"  class="dropdown-item">View</router-link></li>
                            <b-dropdown-item href="#" @click.prevent="IsChatCustomer(activeChatList.customer_id,activeChatList.msgid)">Chat</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="IsBlockCustomer(activeChatList.customer_id,activeChatList)" >Block</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="removeFromContact(activeChatList.customer_id)" >Remove</b-dropdown-item>
                      </b-dropdown> -->
                      </div>
                    </div>
                    <p class="some-txt" style="cursor:pointer"></p>
                    <!-- <p class="some-txt" style="cursor:pointer" @click.prevent="IsChatCustomer(activeChatList.customer_id,activeChatList.msgid)">
                      <span style="color:#77bef6" v-if="activeChatList.lastmsg.from && userData.id != activeChatList.lastmsg.from.id">{{activeChatList.lastmsg.from.name+' '}}:</span>

                       {{activeChatList.block?'':checkType(activeChatList)}}
                    </p> -->
                    <div class="tag-list-wrapper">
                    <div class="tag-list">
                      <hooper :itemsToShow="3">
                          <slide v-for="(val,i) in sortArray(activeChatList.mainBranches)" :key="i" :class="{'activeChatListBusinessBranch' : countAllbranch(val,'mainbranch')>0}">
                         <div class="main_span_bg_wrapper business">
                          <span class="main_span_bg" style="cursor:pointer" @click.prevent="openBranch(activeChatList.isGroup || activeChatList.isChannel ? '' : activeChatList.customer_id,activeChatList.msgid,val.name,i)">{{val.name}} <span class="count_span" v-if="countAllbranch(val,'mainbranch')>0">{{countAllbranch(val,'mainbranch')>9?'9+':countAllbranch(val,'mainbranch')}}</span></span>
                          <!-- <button @click="MainbranchDelete(activeChatList.mainBranches,val.name,activeChatList.msgid,val.msgid || '')">X</button> -->
                        </div>
                          </slide>
                          <!-- <slide>
                          <span>Checkout Latest</span>
                          </slide>
                          <slide>
                          <span>Checkout Latest</span>
                          </slide> -->
                      </hooper>
                    </div>
                    <img src="@/assets/images/plus.svg" style="cursor: pointer;width: 26px;" @click.prevent="createBranchMethod(activeChatList.msgid)" class="black-add-branch" title="Create branch">
                    </div>
                    <!-- <div class="tag-list">
                      <span v-for="(val,i) in activeChatList.mainBranches" :key="i">{{val.name}}</span>
                    </div> -->
                  </div>
                  <div class="chat-list-bottom-border" v-if="selectedActiveMenu=='adminChat' && !activeChatList.isChannel && !activeChatList.isGroup">
                      <div class="user-profile-info" style="cursor: pointer" @click.prevent="IsChatCustomer(activeChatList.isGroup || activeChatList.isChannel ? '' : activeChatList.customer_id,activeChatList.msgid)">
                        <div class="u-img-time" style="cursor:pointer">
                          <img class="chatprofile" :src="activeChatList.profileUrl"/>
                          <!-- <span class="chatprofile profilecharecter">{{activeChatList.firstName.charAt(0).toUpperCase()}}</span> -->
                          <div>
                            <span class="g-name" style="display: flex;" v-if="activeChatList.isGroup == false">
                            <span v-if="(activeChatList.firstName.length + activeChatList.lastName.length) <= 15">{{activeChatList.firstName}} {{activeChatList.lastName}}</span>
                            <span :title="(activeChatList.firstName + activeChatList.lastName)" v-else>{{(activeChatList.firstName + activeChatList.lastName).slice(0, 15)+'...'}}</span>
                              <!-- {{activeChatList.firstName}} {{activeChatList.lastName}} -->
                              <div v-if="activeChatList.isOnline" class="greenDot" style="height:8px;width: 8px;margin-top: 7px;"></div>
                              <div v-else-if="botUser.id == activeChatList.customer_id" class="greenDot" style="height:8px;width: 8px;margin-top: 7px;"></div>
                                <!-- <label v-if="getMessageCount(activeChatList.messageSeenIds)==0" style="padding: 0px 6px;font-weight: 500;">{{getMessageCount(activeChatList.messageSeenIds)>9?'9+':getMessageCount(activeChatList.messageSeenIds)}}</label> -->
                             
                              <!-- <div v-else-if="!activeChatList.isOnline" style="margin-top: 7px;" class="redDot"></div> -->
                              <div v-if="getTotalCount(activeChatList)>0" class="greenDot blue-count" style="height:24px;width: 24px;">
                                <label v-if="getTotalCount(activeChatList)>0" style="">{{getTotalCount(activeChatList)>9?'9+':getTotalCount(activeChatList)}}</label>
                              </div>
                            </span>
                            <!-- <div v-if="activeChatList.lastmsgTime">
                              <span class="g-timedate" v-if="!beforeDate(activeChatList.lastmsgTime)" >{{activeChatList.lastMsgArrivedTime}}</span>
                              <span class="g-timedate" v-else>{{activeChatList.lastMsgDate}}</span>
                            </div>
                            <div v-else>
                              <span class="g-timedate">{{activeChatList.currentTime}}</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="d-flex m_icon_wrapper">
                        <b-dropdown id="dropdown-offset" text="" class="" v-if="activeChatList.isGroup == true">
                            <b-dropdown-item href="#" @click.prevent="sendSidebarData(activeChatList.msgid)"  v-b-toggle.sidebar-right>Rename</b-dropdown-item>
                            <b-dropdown-item href="#" v-if="!checkMute(activeChatList.muteArray||[])" @click.prevent="muteGroup(activeChatList.msgid)">Mute</b-dropdown-item>
                            <b-dropdown-item href="#" v-else @click.prevent="unmuteGroup(activeChatList.msgid)">Unmute</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="confirmationPopup(activeChatList)">Delete</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown id="dropdown-offset" text="" class="" v-if="activeChatList.isGroup == false && activeChatList.customer_id != botUser.id">
                            <li> <router-link v-bind:to="{ name: 'businessuserprofile', params: { id: activeChatList.customer_id }}"  class="dropdown-item">View</router-link></li>
                              <b-dropdown-item href="#" @click.prevent="IsChatCustomer(activeChatList.customer_id,activeChatList.msgid)">Chat</b-dropdown-item>
                              <b-dropdown-item href="#" @click.prevent="IsBlockCustomer(activeChatList.customer_id,activeChatList,activeChatList.msgid)" >Block</b-dropdown-item>
                              <b-dropdown-item href="#" v-if="storeSelectedCompany.isAdmin" @click.prevent="confirmationPopup(activeChatList)" >Remove</b-dropdown-item>
                        </b-dropdown>
                        </div>
                      </div>
                      <p class="some-txt" style="cursor:pointer" @click.prevent="IsChatCustomer(activeChatList.isGroup || activeChatList.isChannel ? '' : activeChatList.customer_id,activeChatList.msgid)"  v-if="activeChatList.blockBy != userData.id">
                        <!-- Lorem ipsum dolor sit amet, consect quis nostrud
                        exercitation ullamco... -->
                        <!-- {{activeChatList.block?'':activeChatList.lastmsg.type.toLowerCase() == 'selfiedeclientext' ? activeChatList.lastmsg.msg == activeChatList.docid ? "you declined surprise selfie request": "Sorry, your request for surprise selfie has been declined."  :activeChatList.lastmsg.type == 'SurpriseSelfie'?'Image':activeChatList.lastmsg.type=='Text'||activeChatList.lastmsg.type=='Link'||activeChatList.lastmsg.type=='SurpriseSelfieText'?activeChatList.lastmsg.msg:activeChatList.lastmsg.type}} -->
                       <!-- {{activeChatList.block?'':checkType(activeChatList)}} -->
                      </p>
                      <div class="tag-list-wrapper">
                      <div class="tag-list">
                        <hooper :itemsToShow="3">
                            <slide v-for="(val,i) in sortArray(activeChatList.mainBranches)" :key="i" :class="{'activeChatListBusinessBranch' : countAllbranch(val,'mainbranch')>0}">
                             <div class="main_span_bg_wrapper business">
                            <span class="main_span_bg" style="cursor:pointer" @click.prevent="openBranch(activeChatList.isGroup || activeChatList.isChannel ? '' : activeChatList.customer_id,activeChatList.msgid,val.name,i)">{{val.name}} <span class="count_span" v-if="countAllbranch(val,'mainbranch')>0">{{countAllbranch(val,'mainbranch')>0?`${countAllbranch(val,'mainbranch')>9?'9+':countAllbranch(val,'mainbranch')}`:''}}</span></span>
                            <!-- <button @click="MainbranchDelete(activeChatList.mainBranches,val.name,activeChatList.msgid,val.msgid || '')">X</button> -->
                             </div>
                            </slide>
                            <!-- <slide>
                            <span>Checkout Latest</span>
                            </slide>
                            <slide>
                            <span>Checkout Latest</span>
                            </slide> -->
                        </hooper>
                      </div>
                      <img src="../../assets/images/add-branch.png" style="cursor: pointer;" @click.prevent="createBranchMethod(activeChatList.msgid)" :class="{'hideFooterForDooeyAdmin': botUser.id == activeChatList.customer_id}" class="black-add-branch" title="Create branch">
                      </div>
                   
                   
                  </div>
                    
                </li>
              </ul>
            </div>
              <div class="business-active-chat-list active-chat-list" style="overflow: auto;max-height: 70vh;" v-else>
                <div v-if="selectedActiveMenu=='adminChat'">
                  <span style="padding:10px; color:red;" v-if="Mainsearchkeyword != '' && errorMessageForSearch == '' && searchChatListArray.length == 0 ">No result found</span>
                  <span style="padding:10px; color:red;" v-if="errorMessageForSearch != '' && searchkeyword != '' && searchChatListArray.length == 0 ">{{errorMessageForSearch}}</span>
                  
                  <div class="media_list__main-overflow" v-if="searchChatListArray.length != 0 && checkHeader(searchChatListArray) != 0 ">
                    <h3>Recent</h3>
                    <ul :class="getClasses()"  >
                      <div v-for="(searchData,index) in searchChatListArray" :key="index" class="downloadIconWrapper" v-show="!searchData.isChannel && !searchData.isGroup">
                          <li v-if="searchData.createDate > searchData.lastWeekEndDate && searchData.createDate <= new Date()"  >
                            <div class="text-search_main" v-if="searchData.type.toLowerCase() == 'text'">
                          <div class="namedate_flex">
                            <!-- <span>{{searchData.firstName}}</span> -->
                            <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                            <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                          </div>  
                          <span class="link_span_main">{{searchData.msg}}</span>
                            </div>
                            <p v-if="Mainsearchkeyword == 'Photos' ">
                              <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'image'">
                                <img :src="searchData.mediaUrl" height="100px;" width="100px;">
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </span>
                              <span class="name_date-search_wrapper">
                                <span class="name_date">
                                  <span v-if="searchkeyword != ''" >
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                  </span>
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                                </span>
                                <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                              </span>
                            </p>
                            <p v-if="searchData.type.toLowerCase()=='video' ">
                              <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'video'">
                                <video controls="controls" 
                                      class="video-stream" 
                                    x-webkit-airplay="allow" 
                                    :src="searchData.mediaUrl"
                                    style="max-width:100%;height:150px;width:200px;object-fit:contain"
                                ></video>
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </span>
                              <span class="name_date-search_wrapper">
                                <span class="name_date">
                                  <span v-if="searchkeyword != ''">
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                    <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    </span>
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                                </span>
                                  <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                              </span>
                            </p>
                            <p v-if="Mainsearchkeyword=='Links' ">
                              <span v-if="searchData.type.toLowerCase() == 'link'" class="link_name_date">
                                <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                  <span>{{searchData.firstName}}</span>
   
   <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>                           <span>{{checkDate(searchData.createDate)}}</span>
                              </span>
                                  <span class="link_span_main" v-if="searchkeyword == ''">{{searchData.msg}}</span>
                              <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>

                            </p>
                            <p  v-if="Mainsearchkeyword == 'Documents'" class="document_wrapper">
                                <span v-if="searchData.type.toLowerCase() == 'pdf' " class="doc_span_main">
                                  <a  v-bind:key="searchData" class="" target="_blank">
                                    <img src="@/assets/images/PDF_file_icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                    <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'excel' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/Exl-icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'word' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/Word_Icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'odt' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/ODT.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'ods' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/ODS.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                    <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'plain' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/834209.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'ppt' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'html' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/images.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                            </p>  
                          </li>
                      </div>
                    </ul>
                  </div>
                  <div class="media_list__main-overflow" v-if="searchChatListArray.length != 0 && checkHeaders(searchChatListArray) != 0 ">
                    <h3 >Last Week</h3>
                      <ul :class="getClasses()">
                        <div v-for="(searchData,index) in searchChatListArray" :key="index" class="downloadIconWrapper" v-show="!searchData.isChannel && !searchData.isGroup">
                          <li v-if="searchData.createDate >= searchData.lastWeekStartDate && searchData.createDate <= searchData.lastWeekEndDate" >
                             <div class="text-search_main" v-if="searchData.type.toLowerCase() == 'text'">
                          <div class="namedate_flex">
                            <!-- <span>{{searchData.firstName}}</span> -->
                            <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                          </div>  
                          <span class="link_span_main">{{searchData.msg}}</span>
                            </div>
                            <p v-if="Mainsearchkeyword == 'Photos' ">
                              <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'image'">
                                <img :src="searchData.mediaUrl" height="100px;" width="100px;">
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </span>
                              <span class="name_date-search_wrapper">
                                <span class="name_date">
                                  <span v-if="searchkeyword != ''" >{{searchData.firstName}}</span>
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                                </span>
                                <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                              </span>
                            </p>
                            <p v-if="searchData.type.toLowerCase()=='video' ">
                              <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'video'">
                                <video controls="controls" 
                                      class="video-stream" 
                                    x-webkit-airplay="allow" 
                                    :src="searchData.mediaUrl"
                                    style="max-width:100%;height:150px;width:200px;object-fit:contain"
                                ></video>
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </span>
                              <span class="name_date-search_wrapper">
                                <span class="name_date">
                                  <span v-if="searchkeyword != ''">{{searchData.firstName}}</span>
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                                </span>
                                  <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                              </span>
                            </p>
                            <p v-if="Mainsearchkeyword=='Links' ">
                              <span v-if="searchData.type.toLowerCase() == 'link'" class="link_name_date">
                                  <!-- <span>{{searchData.firstName}}</span> -->
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                              <span>{{checkDate(searchData.createDate)}}</span>
                              </span>
                                  <span class="link_span_main" v-if="searchkeyword == ''">{{searchData.msg}}</span>
                              <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>

                            </p>
                            <p  v-if="Mainsearchkeyword == 'Documents'" class="document_wrapper">
                                <span v-if="searchData.type.toLowerCase() == 'pdf' " class="doc_span_main">
                                  <a  v-bind:key="searchData" class="" target="_blank">
                                    <img src="@/assets/images/PDF_file_icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                    <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'excel' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/Exl-icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'word' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/Word_Icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'odt' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/ODT.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'ods' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/ODS.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'plain' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/834209.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'ppt' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'html' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/images.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                            </p>    
                          </li>
                        </div>
                      </ul>
                  </div>
                  <div class="media_list__main-overflow" v-if="searchChatListArray.length != 0 && checkHeaderss(searchChatListArray) != 0 ">
                    <h3 >Last Month</h3>
                      <ul :class="getClasses()">
                        <div v-for="(searchData,index) in searchChatListArray" :key="index" class="downloadIconWrapper" v-show="!searchData.isChannel && !searchData.isGroup">
                          <li  v-if="searchData.createDate > searchData.lastMonthDate && searchData.createDate < searchData.lastWeekStartDate" >
                           <div class="text-search_main" v-if="searchData.type.toLowerCase() == 'text'">
                          <div class="namedate_flex">
                            <!-- <span>{{searchData.firstName}}</span> -->
                            <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                            <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                          </div>  
                          <span class="link_span_main">{{searchData.msg}}</span>
                            </div>
                            <p v-if="Mainsearchkeyword == 'Photos' ">
                              <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'image'">
                                <img :src="searchData.mediaUrl" height="100px;" width="100px;">
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </span>
                              <span class="name_date-search_wrapper">
                                <span class="name_date">
                                  <span v-if="searchkeyword != ''" >{{searchData.firstName}}</span>
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                                </span>
                                <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                              </span>
                            </p>
                            <p v-if="searchData.type.toLowerCase()=='video' ">
                              <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'video'">
                                <video controls="controls" 
                                      class="video-stream" 
                                    x-webkit-airplay="allow" 
                                    :src="searchData.mediaUrl"
                                    style="max-width:100%;height:150px;width:200px;object-fit:contain"
                                ></video>
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </span>
                              <span class="name_date-search_wrapper">
                                <span class="name_date">
                                  <span v-if="searchkeyword != ''">{{searchData.firstName}}</span>
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                                </span>
                                  <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                              </span>
                            </p>
                            <p v-if="Mainsearchkeyword=='Links' ">
                              <span v-if="searchData.type.toLowerCase() == 'link'" class="link_name_date">
                                  <!-- <span>{{searchData.firstName}}</span> -->
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                              <span>{{checkDate(searchData.createDate)}}</span>
                              </span>
                                  <span class="link_span_main" v-if="searchkeyword == ''">{{searchData.msg}}</span>
                              <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>

                            </p>
                            <p  v-if="Mainsearchkeyword == 'Documents'" class="document_wrapper">
                                <span v-if="searchData.type.toLowerCase() == 'pdf' " class="doc_span_main">
                                  <a  v-bind:key="searchData" class="" target="_blank">
                                    <img src="@/assets/images/PDF_file_icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                    <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'excel' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/Exl-icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'word' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/Word_Icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'odt' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/ODT.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'ods' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/ODS.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'plain' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/834209.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'ppt' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'html' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/images.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                            </p>   
                          </li> 
                        </div>
                      </ul>
                  </div>
                </div>
                <div v-if="selectedActiveMenu=='admin'">
                  <span style="padding:10px; color:red;" v-if="Mainsearchkeyword != '' && errorMessageForSearchAdmin == '' && adminDisplayArray.length == 0 ">No result found</span>
                  <span style="padding:10px; color:red;" v-if="errorMessageForSearchAdmin != '' && adminDisplayArray.length == 0 ">{{errorMessageForSearchAdmin}}</span>
                  <div class="media_list__main-overflow" v-if="adminDisplayArray.length != 0 && checkHeader(adminDisplayArray) != 0 ">
                    <h3>Recent</h3>
                    <ul :class="getClasses()" >
                      <div v-for="(searchData,index) in adminDisplayArray" :key="index" class="downloadIconWrapper" v-show="searchData.isChannel || searchData.isGroup">
                        <li v-if="searchData.createDate > searchData.lastWeekEndDate && searchData.createDate <= new Date()"  >
                          <div class="text-search_main" v-if="searchData.type.toLowerCase() == 'text'">
                          <div class="namedate_flex">
                            <!-- <span>{{searchData.firstName}}</span> -->
                            <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                            <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                          </div>  
                          <span class="link_span_main">{{searchData.msg}}</span>
                          </div>
                          <p v-if="Mainsearchkeyword == 'Photos' ">
                            <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'image'">
                              <img :src="searchData.mediaUrl" height="100px;" width="100px;">
                              <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                            </span>
                            <span class="name_date-search_wrapper">
                              <span class="name_date">
                                <span v-if="searchkeyword != ''" >{{searchData.firstName}}</span>
                                <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                              </span>
                              <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                            </span>
                          </p>
                          <p v-if="searchData.type.toLowerCase()=='video' ">
                            <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'video'">
                              <video controls="controls" 
                                    class="video-stream" 
                                  x-webkit-airplay="allow" 
                                  :src="searchData.mediaUrl"
                                  style="max-width:100%;height:150px;width:200px;object-fit:contain"
                              ></video>
                              <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                            </span>
                            <span class="name_date-search_wrapper">
                              <span class="name_date">
                                <span v-if="searchkeyword != ''">{{searchData.firstName}}</span>
                                <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                              </span>
                                <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                            </span>
                          </p>
                          <p v-if="Mainsearchkeyword=='Links' ">
                            <span v-if="searchData.type.toLowerCase() == 'link'" class="link_name_date">
                                <!-- <span>{{searchData.firstName}}</span> -->
                                <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                            </span>
                                <span class="link_span_main" v-if="searchkeyword == ''">{{searchData.msg}}</span>
                            <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>

                          </p>
                          <p  v-if="Mainsearchkeyword == 'Documents'" class="document_wrapper">
                              <span v-if="searchData.type.toLowerCase() == 'pdf' " class="doc_span_main">
                                <a  v-bind:key="searchData" class="" target="_blank">
                                  <img src="@/assets/images/PDF_file_icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                  <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                </a>
                                <div>
                                <span>
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                  <!-- {{searchData.firstName}} -->
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                </div>
                              </span>
                              <span v-if="searchData.type.toLowerCase() == 'excel' "  class="doc_span_main">
                                <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                  <img src="@/assets/images/Exl-icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                  <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                </a>
                                <div>
                                <span>
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                  <!-- {{searchData.firstName}} -->
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                <span>{{checkDate(searchData.createDate)}}</span>
                                </div>
                              </span>
                              <span v-if="searchData.type.toLowerCase() == 'word' "  class="doc_span_main">
                                <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                  <img src="@/assets/images/Word_Icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                  <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                </a>
                                <div>
                                <span>
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                  <!-- {{searchData.firstName}} -->
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                <span>{{checkDate(searchData.createDate)}}</span>
                                </div>
                              </span>
                              <span v-if="searchData.type.toLowerCase() == 'odt' "  class="doc_span_main">
                                <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                  <img src="@/assets/images/ODT.png" class="pdf_icon" height="100px;" width="100px;"/>
                                  <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                </a>
                                <div>
                                <span>
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                  <!-- {{searchData.firstName}} -->
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                <span>{{checkDate(searchData.createDate)}}</span>
                                </div>
                              </span>
                              <span v-if="searchData.type.toLowerCase() == 'ods' "  class="doc_span_main">
                                <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                  <img src="@/assets/images/ODS.png" class="pdf_icon" height="100px;" width="100px;"/>
                                  <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                </a>
                                <div>
                                <span>
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                  <!-- {{searchData.firstName}} -->
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                <span>{{checkDate(searchData.createDate)}}</span>
                                </div>
                              </span>
                              <span v-if="searchData.type.toLowerCase() == 'plain' "  class="doc_span_main">
                                <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                  <img src="@/assets/images/834209.png" class="pdf_icon" height="100px;" width="100px;"/>
                                  <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                </a>
                                <div>
                                <span>
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                  <!-- {{searchData.firstName}} -->
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                <span>{{checkDate(searchData.createDate)}}</span>
                                </div>
                              </span>
                              <span v-if="searchData.type.toLowerCase() == 'ppt' "  class="doc_span_main">
                                <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                  <img src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon" height="100px;" width="100px;"/>
                                  <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                </a>
                                <div>
                                <span>
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                  <!-- {{searchData.firstName}} -->
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                <span>{{checkDate(searchData.createDate)}}</span>
                                </div>
                              </span>
                              <span v-if="searchData.type.toLowerCase() == 'html' "  class="doc_span_main">
                                <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                  <img src="@/assets/images/images.png" class="pdf_icon" height="100px;" width="100px;"/>
                                  <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                </a>
                                <div>
                                <span>
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                  <!-- {{searchData.firstName}} -->
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                <span>{{checkDate(searchData.createDate)}}</span>
                                </div>
                              </span>
                          </p>   
                        </li>
                      </div>
                    </ul>
                  </div>
                  <div class="media_list__main-overflow" v-if="adminDisplayArray.length != 0 && checkHeaders(adminDisplayArray) != 0 ">
                    <h3 >Last Week</h3>
                      <ul :class="getClasses()">
                        <div v-for="(searchData,index) in adminDisplayArray" :key="index" class="downloadIconWrapper" v-show="searchData.isChannel || searchData.isGroup">
                            <li v-if="searchData.createDate >= searchData.lastWeekStartDate && searchData.createDate <= searchData.lastWeekEndDate" >
                               <div class="text-search_main" v-if="searchData.type.toLowerCase() == 'text'">
                          <div class="namedate_flex">
                            <!-- <span>{{searchData.firstName}}</span> -->
                            <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                            <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                            <span>{{checkDate(searchData.createDate)}}</span>
                          </div>  
                          <span class="link_span_main">{{searchData.msg}}</span>
                              </div>
                              <p v-if="Mainsearchkeyword == 'Photos' ">
                                <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'image'">
                                  <img :src="searchData.mediaUrl" height="100px;" width="100px;">
                                  <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                </span>
                                <span class="name_date-search_wrapper">
                                  <span class="name_date">
                                    <span v-if="searchkeyword != ''" >{{searchData.firstName}}</span>
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                                  </span>
                                  <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                                </span>
                              </p>
                              <p v-if="searchData.type.toLowerCase()=='video' ">
                                <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'video'">
                                  <video controls="controls" 
                                        class="video-stream" 
                                      x-webkit-airplay="allow" 
                                      :src="searchData.mediaUrl"
                                      style="max-width:100%;height:150px;width:200px;object-fit:contain"
                                  ></video>
                                  <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                </span>
                                <span class="name_date-search_wrapper">
                                  <span class="name_date">
                                    <span v-if="searchkeyword != ''">{{searchData.firstName}}</span>
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                                  </span>
                                    <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                                </span>
                              </p>
                              <p v-if="Mainsearchkeyword=='Links' ">
                                <span v-if="searchData.type.toLowerCase() == 'link'" class="link_name_date">
                                    <!-- <span>{{searchData.firstName}}</span> -->
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                <span>{{checkDate(searchData.createDate)}}</span>
                                </span>
                                    <span class="link_span_main" v-if="searchkeyword == ''">{{searchData.msg}}</span>
                                <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>

                              </p>
                              <p  v-if="Mainsearchkeyword == 'Documents'" class="document_wrapper">
                                  <span v-if="searchData.type.toLowerCase() == 'pdf' " class="doc_span_main">
                                    <a  v-bind:key="searchData" class="" target="_blank">
                                      <img src="@/assets/images/PDF_file_icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                      <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                    </a>
                                    <div>
                                    <span>
                                      <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                      <!-- {{searchData.firstName}} -->
                                      <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                      <span>{{checkDate(searchData.createDate)}}</span>
                                    </div>
                                  </span>
                                  <span v-if="searchData.type.toLowerCase() == 'excel' "  class="doc_span_main">
                                    <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                      <img src="@/assets/images/Exl-icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                      <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                    </a>
                                    <div>
                                    <span>
                                      <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                      <!-- {{searchData.firstName}} -->
                                      <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                    <span>{{checkDate(searchData.createDate)}}</span>
                                    </div>
                                  </span>
                                  <span v-if="searchData.type.toLowerCase() == 'word' "  class="doc_span_main">
                                    <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                      <img src="@/assets/images/Word_Icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                      <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                    </a>
                                    <div>
                                    <span>
                                      <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                      <!-- {{searchData.firstName}} -->
                                      <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                    <span>{{checkDate(searchData.createDate)}}</span>
                                    </div>
                                  </span>
                                  <span v-if="searchData.type.toLowerCase() == 'odt' "  class="doc_span_main">
                                    <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                      <img src="@/assets/images/ODT.png" class="pdf_icon" height="100px;" width="100px;"/>
                                      <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                    </a>
                                    <div>
                                    <span>
                                      <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                      <!-- {{searchData.firstName}} -->
                                      <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                    <span>{{checkDate(searchData.createDate)}}</span>
                                    </div>
                                  </span>
                                  <span v-if="searchData.type.toLowerCase() == 'ods' "  class="doc_span_main">
                                    <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                      <img src="@/assets/images/ODS.png" class="pdf_icon" height="100px;" width="100px;"/>
                                      <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                    </a>
                                    <div>
                                    <span>
                                      <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                      <!-- {{searchData.firstName}} -->
                                      <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                    <span>{{checkDate(searchData.createDate)}}</span>
                                    </div>
                                  </span>
                                  <span v-if="searchData.type.toLowerCase() == 'plain' "  class="doc_span_main">
                                    <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                      <img src="@/assets/images/834209.png" class="pdf_icon" height="100px;" width="100px;"/>
                                      <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                    </a>
                                    <div>
                                    <span>
                                      <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                      <!-- {{searchData.firstName}} -->
                                      <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                    <span>{{checkDate(searchData.createDate)}}</span>
                                    </div>
                                  </span>
                                  <span v-if="searchData.type.toLowerCase() == 'ppt' "  class="doc_span_main">
                                    <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                      <img src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon" height="100px;" width="100px;"/>
                                      <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                    </a>
                                    <div>
                                    <span>
                                      <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                      <!-- {{searchData.firstName}} -->
                                      <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                    <span>{{checkDate(searchData.createDate)}}</span>
                                    </div>
                                  </span>
                                  <span v-if="searchData.type.toLowerCase() == 'html' "  class="doc_span_main">
                                    <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                      <img src="@/assets/images/images.png" class="pdf_icon" height="100px;" width="100px;"/>
                                      <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                    </a>
                                    <div>
                                    <span>
                                      <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                      <!-- {{searchData.firstName}} -->
                                      <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                    <span>{{checkDate(searchData.createDate)}}</span>
                                    </div>
                                  </span>
                              </p>  
                            </li>
                        </div>
                      </ul>
                  </div>
                  <div class="media_list__main-overflow" v-if="adminDisplayArray.length != 0 && checkHeaderss(adminDisplayArray) != 0 ">
                    <h3 >Last Month</h3>
                    <ul :class="getClasses()">
                      <div v-for="(searchData,index) in adminDisplayArray" :key="index" class="downloadIconWrapper" v-show="searchData.isChannel || searchData.isGroup">
                          <li  v-if="searchData.createDate > searchData.lastMonthDate && searchData.createDate < searchData.lastWeekStartDate" >
                            <div class="text-search_main" v-if="searchData.type.toLowerCase() == 'text'">
                              <div class="namedate_flex">
                                <!-- <span>{{searchData.firstName}}</span> -->
                                <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                <span>{{checkDate(searchData.createDate)}}</span>
                              </div>  
                              <span class="link_span_main">{{searchData.msg}}</span>
                            </div>
                            <p v-if="Mainsearchkeyword == 'Photos' ">
                              <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'image'">
                                <img :src="searchData.mediaUrl" height="100px;" width="100px;">
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </span>
                              <span class="name_date-search_wrapper">
                                <span class="name_date">
                                  <span v-if="searchkeyword != ''" >{{searchData.firstName}}</span>
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                                </span>
                                <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                              </span>
                            </p>
                            <p v-if="searchData.type.toLowerCase()=='video' ">
                              <span class="search_img_span" v-if="searchData.type.toLowerCase() == 'video'">
                                <video controls="controls" 
                                      class="video-stream" 
                                    x-webkit-airplay="allow" 
                                    :src="searchData.mediaUrl"
                                    style="max-width:100%;height:150px;width:200px;object-fit:contain"
                                ></video>
                                <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                              </span>
                              <span class="name_date-search_wrapper">
                                <span class="name_date">
                                  <span v-if="searchkeyword != ''">{{searchData.firstName}}</span>
                                  <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>
                                </span>
                                  <span v-if="searchkeyword != ''">{{checkDate(searchData.createDate)}}</span>
                              </span>
                            </p>
                            <p v-if="Mainsearchkeyword=='Links' ">
                              <span v-if="searchData.type.toLowerCase() == 'link'" class="link_name_date">
                                  <!-- <span>{{searchData.firstName}}</span> -->
                                  <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                              <span>{{checkDate(searchData.createDate)}}</span>
                              </span>
                                  <span class="link_span_main" v-if="searchkeyword == ''">{{searchData.msg}}</span>
                              <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label>

                            </p>
                            <p  v-if="Mainsearchkeyword == 'Documents'" class="document_wrapper">
                                <span v-if="searchData.type.toLowerCase() == 'pdf' " class="doc_span_main">
                                  <a  v-bind:key="searchData" class="" target="_blank">
                                    <img src="@/assets/images/PDF_file_icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                    <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'excel' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/Exl-icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'word' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/Word_Icon.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'odt' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/ODT.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'ods' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/ODS.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'plain' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/834209.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'ppt' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                                <span v-if="searchData.type.toLowerCase() == 'html' "  class="doc_span_main">
                                  <a  v-bind:key="searchData" class="downloadIconWrapper" target="_blank">
                                    <img src="@/assets/images/images.png" class="pdf_icon" height="100px;" width="100px;"/>
                                    <img src="@/assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(searchData.mediaUrl,searchData.name)"/>
                                  </a>
                                  <div>
                                  <span>
                                    <span v-if="(searchData.firstName.length) <= 15">{{searchData.firstName}}</span>
                                  <span :title="(searchData.firstName)" v-else>{{(searchData.firstName).slice(0, 15)+'...'}}</span>
                                    <!-- {{searchData.firstName}} -->
                                    <label class="doc_name_label" v-if="searchkeyword != ''" style="display:block" :class="{'highlight-msg':searchkeyword!='' && searchData.msg != '' && searchData.msg.toLowerCase().includes(searchkeyword.toLowerCase())}">{{searchData.msg}}</label></span>
                                  <span>{{checkDate(searchData.createDate)}}</span>
                                  </div>
                                </span>
                            </p>  
                          </li> 
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
               <div>
               </div>
           
            <!-- <BulkmessageComponent></BulkmessageComponent> -->
            <NewChat></NewChat>
            <!-- <NewChannel/> -->
            <channel></channel>
            <!-- BULK MESSAGE MODE -->
           <b-modal id="bulkmessage" centered hide-footer title=""  @close="closeFire()" :no-close-on-backdrop="true"> 
            <div class="contact-modal newchat-modal">
            <div class="contact-modal">
            <h4>Bulk Message</h4>
             <ValidationObserver ref="log" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(sendBulkMessage)">
              <ValidationProvider rules="required" name="message" v-slot="{ errors }">
                <div class="form-group addtextarea">
                    <label>Message *</label>
                    <textarea class="form-control" placeholder="Enter message" v-model="sendingMessage" ref="bulkmessage" ></textarea>
                    <span class="InvalidInput">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
                <div class="form-group">
                    <label class="sc">Select Contacts</label>
                    <div class="select-contact-list">
                        <ul>
                            <li v-for="(bulkList,index) in bulkMemberList" v-bind:key="index">
                              {{bulkList.firstName}} {{bulkList.lastName}} 
                              <span class="remove-content"><img src="@/assets/images/user_added.png"></span>
                              <span class="remove-content" @click.prevent="removemember(index)"><img src="@/assets/images/user_cancel.png"></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="form-group modal-search">
                    <input type="search" input-id="tags-basic" remove-on-delete  class="form-control" placeholder="Search contact" v-model="keyword">
                    <img src="../../assets/images/search-icon.png" class="search-icon">
                </div>

                <div class="choose-contact">
                   <div class="scrollbar">
                       <ul class="member-list-sidebar">
                          <li v-for="(bulkUserList,index) in getFilterData" v-bind:key="index" >
                            <div v-if="bulkUserList.block != true"><img :src="bulkUserList.profileUrl"><span class="ml-1">{{bulkUserList.firstName}} {{bulkUserList.lastName}}</span></div>
                            <button type="button" v-if="bulkUserList.block != true" class="btn btn-white" @click.prevent="addBrodcastMmeber(bulkUserList)">Add</button>
                           </li>
                           <li v-if="getFilterData.length==0" style="justify-content:center">
                            <span class="ml-1">
                            No User Found
                            </span>
                          </li>
                        </ul>
                    </div>
                </div>
               
                <div class="btn-wrapper">
                    <button type="button" class="btn btn-yellow" @click.prevent="sendBulkMessage()">send</button>
                </div>
            </form>
             </ValidationObserver>
        </div>
        </div>
      </b-modal>
        </div>
    </div>
</template>

<script>
  

// import BulkmessageComponent from "../components/allModal/bulkmessages.vue"
import Vue from 'vue'
import NewChat from "@/components/businessComponent/allBusinessModel/newchat.vue";
import channel from "@/components/businessComponent/allBusinessModel/channel.vue";
import firebaseData from '../../utils/firebaseCollection.js';
import firebase from "../../views/firebaseInit"
import firebaseTimestamp from "firebase";
import Swal from 'sweetalert2'
import {getActiveChat2,getCount2,getCounting2,Contactlist2,getContactCount2,getActiveMsgArray2,getAcseptUserData2} from '../../businessUtills/getQueeryActiveChatList.js'
import {exitGroup} from '../../businessUtills/getSidebarMedia.js'
import {getBotuserData} from "@/utils/getQueeryActiveChatList.js";
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

import { mapActions,mapGetters } from "vuex";

const db = firebase.firestore()

export default ({
  components:{
    // BulkmessageComponent,
    NewChat,
      firebaseData,
      Hooper,
      Slide,
      channel
  },
  props: ["selectedActiveMenu","searchVal"],
  data(){
    return{
      acseptUserData:[],
      getUserData:[],
      socialMessage:[],
      activeChatListArray:[],
      chatObject:{},
      groupObject:{},
      chatListArray:[],
      userSearchList:[],
      getBulkData:[],
      bulkMemberList:[],
      sendingMessage:'',
      keyword:'',
      searchkeyword:'',
      searchContactArray:[],
      Comapnyadmin:'',
      searchArray: [
        { title: "Photos" },
        { title: "Videos" },
        { title: "Links" },
        { title: "Documents" }
      ],
      showSearchList:false,
      closeSearchList:false,
      searchChatListArray:[],
      Mainsearchkeyword : '',
      showSearchListAdmin:false,
      closeSearchListAdmin:false,
      errorMessageForSearch : '',
      adminDisplayArray:[],
      errorMessageForSearchAdmin : '',
      botUser:{}
    }
  },
  created(){
    //  debugger // eslint-disable-line
    var self=this;
    getBotuserData(firebaseData.firebasecollection.USER,(res) => {
        self.botUser = res;
    })
    self.chatListArray = self.storeBusinessChatList;

    self.$root.$on("searchBarClearEvent",() => {
      //  debugger // eslint-disable-line
      self.searchkeyword = '',
      self.Mainsearchkeyword = ''
      self.searchChatListArray = [];
      self.adminDisplayArray = [];
      self.showSearchList = false;
      self.errorMessageForSearch = '';
      self.closeSearchList = false;
      self.errorMessageForSearchAdmin = '';
      self.chatListArray = self.storeBusinessChatList;
    })
    self.$root.$on("hideAreaOfSidebar",(val) => {
      //  debugger // eslint-disable-line
        self.displayChat = val 
    })
    
    if(Object.keys(self.userData).length==0)
    {
      self.setUserStoreData(firebase.auth().currentUser.uid)
      .then(result=>{
        // console.log('getChatlist',result)
        // self.getSetSocialChatList();
        self.searchChatListArray = [];
        self.getSetBusinessChatList()
        // self.getSetSocialContacts()
      })
    }else{
      // self.getSetSocialChatList();
      // console.log('has Chatlist')
       self.searchChatListArray = [];
      // self.getSetBusinessChatList()
    }


    //  self.getContactList();
       self.$root.$on('changeHeader',(val) => {
      //  debugger // eslint-disable-line
       Vue.prototype.$companyName = val
       self.companyName = val
       self.Comapnyadmin = val.isAdmin
       self.searchChatListArray = [];
        self.chatListArray = self.storeBusinessChatList;
      })
      self.$root.$on('callchannell',()=>{
        // debugger // eslint-disable-line
        self.selectedActiveMenu=='admin';
        self.searchChatListArray = [];
        self.chatListArray = self.storeBusinessChatList;
        // self.getContactList();
        self.init();
      })
     self.init();
       self.$root.$on("groupcreate",(value)=>{
         //console.log('val',value)
         if(value == 'Yes'){
           self.socialMessage = []
            self.acseptUserData=[];
            self.activeChatListArray = [];
            self.searchChatListArray = [];
          //  self.getContactList();
         }
      });
    
      self.$root.$on("grouplist",(value)=>{
        // debugger // eslint-disable-line
         //console.log('grouplistvalue',value, self.chatListArray)
         if(value == 'Yes'){
           self.socialMessage = []
            self.acseptUserData=[];
            self.activeChatListArray = [];
            self.searchChatListArray = [];
          //  self.getContactList();
         }
      });

      self.$root.$on("changeGroupInfo",(objectid,chatId)=>{
         self.IsChatCustomer(objectid,chatId);
         self.searchChatListArray = [];
      })
  },
  methods:{
    checkType(activeChatList){
      // debugger // eslint-disable-line
      if(activeChatList.lastmsg.type == 'SurpriseSelfie'){
        return 'Image'
      }
      else if(activeChatList.lastmsg.type=='Text' || activeChatList.lastmsg.type=='SurpriseSelfieText' ){
        return activeChatList.lastmsg.msg
      }
      else if(activeChatList.lastmsg.type == 'SelfieDeclienText'){
        {
          if(activeChatList.lastmsg.msg == firebase.auth().currentUser.uid){
            return 'You declined surprise selfie request'
          }else{
            return "Sorry, your request for surprise selfie has been declined"
          }
        }

      }
      else{
        return activeChatList.lastmsg.type
      }
      
    },
    checkHeader(searchArray){
    //  debugger // eslint-disable-line
      // console.log(searchArray);
      var count = 0;
      searchArray.forEach(data => {
        if(data.createDate > data.lastWeekEndDate && data.createDate <= new Date()){
          count++;
        }
      })
      return count
    },
    checkHeaders(searchArray){
    //  debugger // eslint-disable-line
      // console.log(searchArray);
      var count = 0;
      searchArray.forEach(data => {
        if(data.createDate >= data.lastWeekStartDate && data.createDate <= data.lastWeekEndDate){
          count++;
        }
      })
      return count
    },
    checkHeaderss(searchArray){
    //  debugger // eslint-disable-line
      // console.log(searchArray);
      var count = 0;
      searchArray.forEach(data => {
       
        if(data.createDate > data.lastMonthDate && data.createDate < data.lastWeekStartDate){
           count++;
        }
      })
      return count
    },
    // FOR APPLY DYNEMIC CLASS(Shraddha)
    getClasses()
    {
      var className = ''

      if(this.searchkeyword != '')
      {
        className += ' search_result-media ';
      }

      // console.log("this.searchData",this.Mainsearchkeyword)

      if(this.Mainsearchkeyword!= '')
      {
        className += ' '+this.Mainsearchkeyword+'-ul-wrapper ';
      }

      return className;
    },
    checkRenderConditions(activeChatList)
    {
     
      var flag = false
      flag = this.selectedActiveMenu=='admin' && activeChatList.isChannel || this.selectedActiveMenu=='admin' && activeChatList.isGroup
      if(activeChatList.block || activeChatList.isDeleted)
      {
        flag=false
      }
      // console.log('Render',flag);
       
      return flag
    },
    getMainMessageCount(chatData)
    {
      // console.log('chatData',chatData)
      var total = 0
      var uid = firebase.auth().currentUser.uid

      //add main chat message seen counts
      // console.log('abcdCalled-',this.storeChatId,"-Test")
      if(this.storeChatId==chatData.msgid && this.storeChatId!="")
      {
        if(this.storeCollectionName != 'default')
        {
          chatData.messageSeenIds.forEach(data=>{
            if(data.id == uid)
            {
              total += data.count
            }
          })
        }
        
        return total
      }
      else
      {
        // console.log('Else')
        chatData.messageSeenIds.forEach(data=>{
          if(data.id == uid)
          {
            total += data.count
          }
        })
        // console.log("Count",total)
        return total
      }
    },
    // FOR ACTIVE BUSINESS OR NOT (Shraddha)
    ...mapActions({setUserStoreData:"setUserData"}),
    // ...mapActions({getSetSocialChatList:"getSetSocialChatList"}),
    ...mapActions({getSetBusinessChatList:"getSetBusinessChatList"}),
    ...mapActions({getSetBusinessChatData:'getSetBusinessChatData'}),
    getMessageCount(messageSeenIdsArray)
    {
      var uid = firebase.auth().currentUser.uid
      var count = 0
      if(messageSeenIdsArray.length!=0)
      {
        messageSeenIdsArray.forEach(data=>{
          if(data.id==uid)
          {
            count = data.count
          }
        })
      }      

      return count
    },
    sortArray(mainBranchArray)
    {
      //console.log('sortCalled')
      //console.log('arr',mainBranchArray)

      var tmp = mainBranchArray
      var tmp2 = ''

      for (let index = 0; index < tmp.length; index++) {
        for (let j = index+1; j < tmp.length; j++) {
          if(tmp[index].lastMsgTime.seconds < tmp[j].lastMsgTime.seconds)
          {
            tmp2 = tmp[index]
            tmp[index] = tmp[j]
            tmp[j]=tmp2
          }
        }
      }
      return tmp
      
    },
    openBranch(objectid,chatId,branchName,index)
    {
      var self = this
      var mainBranches = ''

      self.activeChatListArray.forEach(data=>{
        if(data.msgid==chatId)
        {
          //console.log('=',data,chatId)
          mainBranches = data.mainBranches
        }
      })
      //console.log(chatId,name,index)
      // self.$root.$emit('openBranch',chatId,name,index,self.mainBranches)

      self.getSetBusinessChatData({companyId:Vue.prototype.$companyName.id,collection:'businessMessages',chatId:chatId})

      //console.log('check',objectid);
      if(objectid != ''){
        db.collection(firebaseData.firebasecollection.USER).doc(objectid).get().then((doc) =>{
          self.chatObject = doc.data()
          self.groupObject = {}
          self.$root.$emit('openBusinessChat',self.chatObject,chatId,self.groupObject,mainBranches,branchName);
          // self.$root.$emit('openBranchs',chatId,name,index,self.chatObject,self.groupObject,mainBranches);
        })
      }else{
        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatId).get().then((doc) =>{
          self.groupObject = doc.data()
          self.chatObject = {}
          self.$root.$emit('openBusinessChat',self.chatObject,chatId,self.groupObject,mainBranches,branchName);
          // self.$root.$emit('openBranchs',chatId,name,index,self.chatObject,self.groupObject,mainBranches);
        })
      }
    },
    createBranchMethod(chatId)
    {
      this.$bvModal.show('create-branch')
      this.$root.$emit('createBranchEvent',chatId)
    },
    checkMute(array)
    {
      var uid = firebase.auth().currentUser.uid
      var muted = false
      //console.log(array)

      array.forEach(data=>{
        if(data == uid)
        {
          muted = true
        }
        else
        {
          muted = false
        }
      })

      return muted
    },
    muteGroup(docId)
    {
      var self = this
      var muted = false
      var uid = firebase.auth().currentUser.uid
      var muteArrayData = []
      var mainBranchesData = []
      db
        .collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
        .doc(docId)
        .get().then(doc=>{
          muteArrayData = doc.data().muteArray||[]

          mainBranchesData = doc.data().mainBranches

          muteArrayData.forEach(data=>{
            if(data == uid)
            {
              muted = true
            }
          })

          //console.log("Muted",muted)

          if(!muted)
          {
            muteArrayData.push(uid)

            mainBranchesData.forEach(data=>{
              var mainMuted = false
              data.muteArray.forEach(dt=>{
                if(dt == uid)
                {
                  mainMuted = true
                }
              })
              if(!mainMuted)
              {
                data.muteArray.push(uid)

                data.subBranches.forEach(data2=>{
                  var subMuted = false
                  data2.muteArray.forEach(dt2=>{
                    if(dt2 == uid)
                    {
                      subMuted = true
                    }
                  })
                  if(!subMuted)
                  {
                    data2.muteArray.push(uid)

                    data2.subSubBranches.forEach(data3=>{
                      var subSubMuted = false
                      data3.muteArray.forEach(dt3=>{
                        if(dt3 == uid)
                        {
                          subSubMuted = true
                        }
                      })
                      if(!subSubMuted)
                      {
                        data3.muteArray.push(uid)
                      }
                    })
                  }
                })
              }
            })
            // console.log('mainBranchesData123',mainBranchesData)
            db
              .collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
              .doc(docId)
              .update({
                muteArray:muteArrayData,
                mainBranches:mainBranchesData
              })
              .then(()=>{
                self.$root.$emit("audioplay");
                self.$notify({
                  title:"Success",
                  text:'Mute successfully.',
                  type:'success'
                })
                self.IsChatCustomer('',docId)
              })
              .catch(error=>{
                console.log(error)
              })
          }
        })
        .catch(error=>{
          console.log(error)
        })
    },
    unmuteGroup(docId)
    {
      var self = this
      var muted = false
      var uid = firebase.auth().currentUser.uid
      var muteArrayData = []
      var mainBranchesData = []
      db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
        .doc(docId)
        .get().then(doc=>{
          muteArrayData = doc.data().muteArray||[]
          mainBranchesData = doc.data().mainBranches

          muteArrayData.forEach(data=>{
            if(data == uid)
            {
              muted = true
            }
          })

          if(muted)
          {
            for (let i = 0; i < muteArrayData.length; i++) {
              if(muteArrayData[i] == uid)
              {
                muteArrayData.splice(i,1)
              }
            }

            for (let i = 0; i < mainBranchesData.length; i++) {
              for (let j = 0; j < mainBranchesData[i].muteArray.length; j++) {
                if(mainBranchesData[i].muteArray[j]== uid)
                {
                  mainBranchesData[i].muteArray.splice(j,1)
                }
              }
              for (let l = 0; l < mainBranchesData[i].subBranches.length; l++) {
                for (let m = 0; m < mainBranchesData[i].subBranches[l].muteArray.length; m++) {
                  if(mainBranchesData[i].subBranches[l].muteArray[m]== uid)
                  {
                    mainBranchesData[i].subBranches[l].muteArray.splice(m,1)
                  }
                }
                for (let n = 0; n < mainBranchesData[i].subBranches[l].subSubBranches.length; n++) {
                  for (let o = 0; o < mainBranchesData[i].subBranches[l].subSubBranches[n].muteArray.length; o++) {
                    if(mainBranchesData[i].subBranches[l].subSubBranches[n].muteArray[o]== uid)
                    {
                      mainBranchesData[i].subBranches[l].subSubBranches[n].muteArray.splice(o,1)
                    }
                  }
                }
              }
            }
            
            db
              .collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
              .doc(docId)
              .update({
                muteArray:muteArrayData,
                mainBranches:mainBranchesData
              })
              .then(()=>{
                self.$root.$emit("audioplay");
                self.$notify({
                  title:"Success",
                  text:'Unmuted successfully.',
                  type:'success'
                })
                self.IsChatCustomer('',docId)
              })
              .catch(error=>{
                console.log(error)
              })
          }
        })
        .catch(error=>{
          console.log(error)
        })
    },
    // CHECK IF LASTMESSAGE DATE IS PAST OR NOT(Shraddha)
    beforeDate(lastmessagedate){
      // //debugger // eslint-disable-line
        var self=this;
          var Datesplit = lastmessagedate?(new Date(lastmessagedate.seconds * 1000).getDate()):''
          var monthsplit = lastmessagedate?(new Date(lastmessagedate.seconds * 1000).getMonth()):''
          var yearsplit = lastmessagedate?(new Date(lastmessagedate.seconds * 1000).getFullYear()):''
          var todayDatesplit = new Date().getDate();
          var todayMonthsplit = new Date().getMonth();
          var todayYearsplit = new Date().getFullYear();
        var result = false
      if(todayDatesplit > Datesplit && todayMonthsplit >= monthsplit && todayYearsplit >= yearsplit)
      {
        result = true
      }
      
      else
      {
        result = false
      }
      return result
    },
    //CALL GROUP EMIT FOR CALL CREATED WHEN CLICK CREATE GROUP POPUP(Shraddha)
    callGroup(){
      //debugger // eslint-disable-line
      var self=this;
      self.$root.$emit('callGroup')
    },
   
      // CHAT WITH CUSTOMER (Shraddha)
        IsChatCustomer(objectid,chatid){
            // debugger // eslint-disable-line
            var self=this;
            const db = firebase.firestore()
            self.getSetBusinessChatData({companyId:Vue.prototype.$companyName.id,collection:'businessMessages',chatId:chatid})

            var mainBranches = ''
            self.activeChatListArray.forEach(data=>{
              if(data.msgid==chatid)
              {
                mainBranches = data.mainBranches
              }
            })
            
            // console.log('businesschatlist',objectid);
            if(objectid != ''){
              db.collection(firebaseData.firebasecollection.USER).doc(objectid).get().then((doc) =>{
                self.chatObject = doc.data()
                self.groupObject = {}
                // console.log('Chat',self.chatObject)
                self.$root.$emit('openBusinessChat',self.chatObject,chatid,self.groupObject,mainBranches,"default");
                self.$root.$emit('chatObjefct',self.chatObject,chatid,self.groupObject,mainBranches);
              })
            }else{
              db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatid).get().then((doc) =>{
                self.groupObject = doc.data()
                self.chatObject = {}
                // console.log('Group',self.groupObject)
                self.$root.$emit('openBusinessChat',self.chatObject,chatid,self.groupObject,mainBranches,"default");
                self.$root.$emit('chatObjefct',self.chatObject,chatid,self.groupObject,mainBranches);
              })
            }
        },
       // BLOCK CUSTOMER
        IsBlockCustomer(blockid,activeChatList,chatingId){
            // debugger // eslint-disable-line
            var self=this;
            const db = firebase.firestore()
             const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
              Swal.fire({
                  title: "Are you sure you want to block"+' '+activeChatList.firstName+' '+activeChatList.lastName+' '+"?",
                  type: "warning",
                   customClass:{
                    container :"business_confirmation_popup"
                  },
                  showCancelButton: true,
                  confirmButtonColor: "#77bef6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ok",
              }).then((result) => {
                if (result.value) {
            // console.log(blockid);
                var user = firebase.auth().currentUser;
                // console.log(user.uid)
                var removeChatid = '';
                db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockid).get().then(() => {
                // if(snap.data().chatId != undefined){
                //     removeChatid = snap.data().chatId
                //     db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(removeChatid).delete().then(()=>{
                //         db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockid).set({
                //             id:blockid
                //         }).then( () => {
                //             db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockid).set({
                //                 block:true,
                //                 blockFrom : user.uid,
                //                 id:blockid
                //             }).then( () => {
                //                db.collection(firebaseData.firebasecollection.USER).doc(blockid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(user.uid).set({
                //                    id:user.uid
                //                }).then(()=>{
                // self.$root.$emit("audioplay");//                   
                // self.$notify({
                //                     title:"Success",
                //                     text:'Customer Block Successfully',
                //                     type:"success"
                //                   })
                //                }) 
                //             })
                //         })  
                //     }) 
                // }else{
               db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockid).set({
                        id:blockid
                    }).then( () => {
                        db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockid).update({
                            block:true,
                            blockFrom : user.uid,
                        }).then( () => {
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Success",
                                text:activeChatList.firstName+' '+activeChatList.lastName+' blocked.',
                                type:"success"
                            })
                            self.$root.$emit('activeGroup' ,'yes');
                        })
                    })
                     if(chatingId != undefined){
                        var blockArray = []
                      db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatingId).get().then(doc => {
                          blockArray = doc.data().blockUserArray || []
                          blockArray.push({
                            blockBy : user.uid,
                            blockTo : blockid
                          })
                        
                      }).then(() => {
                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatingId).update({
                          blockUserArray : blockArray
                        })
                      })
                    }  
                // }
            })
          }
        })
        },
         //REMOVE FROM CONTACT LIST(Shraddha)
        removeFromContact(data){
            // debugger // eslint-disable-line
            var self=this;
            var removeid = data.customer_id
            const db = firebase.firestore()
            var currentSelectedCompany = ''
            // //console.log(removeid);
            var user = firebase.auth().currentUser;
            var removeChatid = ''
            // console.log(user.uid)
            db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc=>{
                var users = doc.data().users
                var index = users.findIndex(usr => {
                    return usr.id == removeid  
                })
                users.splice(index,1)
                // console.log(users)
                    db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                        users:users
                    })
                }).then(() => {
                    db.collection(firebaseData.firebasecollection.USER).doc(removeid).get().then(docs =>{
                        var companies = docs.data().companies
                        var index1 = companies.findIndex(usr => {
                            return usr.id == Vue.prototype.$companyName.id  
                        })
                        companies.splice(index1,1)
                        // console.log(companies)

                        if(companies.length == 0){
                          currentSelectedCompany = 'social'
                        }else{
                          currentSelectedCompany = companies[0].id
                        }
                        db.collection(firebaseData.firebasecollection.USER).doc(removeid).update({
                            companies : companies,
                            currentSelectedCompany : currentSelectedCompany
                        })
                    })
                }).then(() => {
                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).onSnapshot(querySnapshot => {
                          querySnapshot.forEach(doc=>{
                            if(doc.exists){
                              if(doc.data().isGroup == true || doc.data().isChannel == true){
                                var connectedUsers = doc.data().connectedUser
                                var index = connectedUsers.findIndex(item => {
                                    return item == removeid  
                                })
                                if(index != -1){
                                  connectedUsers.splice(index,1);
                                }
                              }
                              db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(doc.id).update({
                                connectedUser : connectedUsers
                              })
                            }
                          })
                        })
                      }).then(() => {
                      db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(removeid).get().then(snap => {
                        if(snap.data().chatId != undefined){
                            removeChatid = snap.data().chatId
                            // console.log('removeChatid',removeChatid)
                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(removeChatid).delete().then(()=>{
                                db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(removeid).delete().then ( () => {
                                    db.collection(firebaseData.firebasecollection.USER).doc(removeid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(user.uid).delete().then( () => {
                                        self.$root.$emit("audioplay");
                                        self.$notify({
                                            title:"Success",
                                            text:data.firstName+' '+data.lastName+' removed successfully.',
                                            type:"success"
                                        })
                                        // self.getContactList();
                                        self.$root.$emit('activeGroup' ,'yes');
                                    });
                                });
                            })
                            
                        }else{
                          db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(data.msgid).delete().catch((error) => console.error("ERROR in delete business chat: ", error));
                          db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(removeid).delete().then ( () => {
                              db.collection(firebaseData.firebasecollection.USER).doc(removeid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(user.uid).delete().then( () => {
                                  self.$root.$emit("audioplay");
                                  self.$notify({
                                      title:"Success",
                                      text:data.firstName+' '+data.lastName+' removed successfully.',
                                      type:"success"
                                  })
                                  // self.getContactList();
                                  self.$root.$emit('activeGroup' ,'yes');
                              });
                          });
                        }
                      })
            })
        },
        //FOR GET bULKMESSAGE LIST(Shraddha)
        init(){
          // debugger // eslint-disable-line
            var self=this;
            self.userSearchList = []
            self.getBulkData = []
            // const db = firebase.firestore();
            // var user = localStorage.getItem("customerDocKey");
            // //console.log('useruid',user)
            if(Vue.prototype.$companyName){
              Contactlist2(firebaseData.firebasecollection.USER,self.userSearchList,res=>{
                self.userSearchList = res
                getContactCount2(firebaseData.firebasecollection.USER,self.getBulkData,self.userSearchList,res=>{
                  self.userSearchList = res
                  self.chatListArray = self.storeBusinessChatList;
                  //console.log('userSearchList',self.userSearchList)
                })
              })
            }
            
        },
         // ADD MEMBER FOR BRODCAST(Shraddha)
        addBrodcastMmeber(bulkobj){
          // //debugger // eslint-disable-line
          var self = this;
         self.keyword = ''
          if(self.bulkMemberList.length > 0){
            let isInArray = self.bulkMemberList.some(function(item) {
                return item.docid == bulkobj.docid
            });
            if(isInArray == false){
              self.bulkMemberList.push(bulkobj);
              
            }else{
              self.$root.$emit("audioplay");
              self.$notify({
                title:"Error",
                text:'Already exists',
                type:"error"
              })
              
            }
          }else{
            self.bulkMemberList.push(bulkobj);
            
          }
        },
        // REMOVE MEMBER FROM BULKARRAY(Shraddha)
        removemember(id){
            // //debugger // eslint-disable-line
            var self = this;
           self.bulkMemberList.splice(id,1);
        },
        //SEND BULKMESSAGE(Shraddha)
        sendBulkMessage(){
            // //debugger // eslint-disable-line
            var self = this;
            self.sendingMessage = self.sendingMessage.trim();
            if(self.sendingMessage != ''){
              if(self.bulkMemberList.length != 0){
                self.$emit('OnupdateBulkData',self.bulkMemberList,self.sendingMessage);
                self.sendingMessage = ''
                self.keyword = ''
                self.bulkMemberList = []
                self.$refs.log.reset();
                self.$root.$emit("audioplay");
                self.$notify({
                  title:"Success",
                  text:'Message send successfully',
                  type:"success"
                })
                self.$bvModal.hide('bulkmessage')
              }else{
                self.$root.$emit("audioplay");
                self.$notify({
                  title:"Error",
                  text:'Member list is required',
                  type:"error"
                })
              }
            }
        },
         // SEARCH FILTER BY CUSTOMER(Shraddha)
        searchFilter(){
          // debugger // eslint-disable-line
          var self=this;
          self.errorMessageForSearch = '';
          self.errorMessageForSearchAdmin = '';
          self.showSearchList = false;
          if(self.Mainsearchkeyword == '' && self.searchkeyword != '' ){
            self.showSearchList = false;
            self.textSearch = true;
          }
          self.searchChatListArray = [];
          self.adminDisplayArray = [];
          var chatlistArray = []
          chatlistArray = self.storeBusinessChatList;
          // self.socialChatList = [];
          // self.chatListArray =[]
        
          var arr=[]
          chatlistArray .forEach(datas=>{
              if(!datas.isGroup && !datas.isChannel && self.selectedActiveMenu=="adminChat"){
                var fullname = datas.firstName+' '+datas.lastName
                if(self.Mainsearchkeyword == 'Photos' && self.searchkeyword == ''){
                  
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'image'){
                            found = true
                            var match = false
                            if(self.searchChatListArray.length == 0){
                              self.searchChatListArray.push({
                                firstName : data.firstName,
                                type : data.type,
                                mediaUrl : data.mediaUrl,
                                msg : data.msg,
                                name : data.name,
                                createdAt : data.createdAt,
                                createDate : data.createDate,
                                lastWeekStartDate : data.lastWeekStartDate,
                                lastWeekEndDate : data.lastWeekEndDate,
                                lastMonthDate : data.lastMonthDate,
                                 isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                              });
                            }else{
                              self.searchChatListArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.mediaUrl == data.mediaUrl){
                                  match = true
                                }
                              })
                              if(!match){
                                  self.searchChatListArray.push({
                                  firstName : data.firstName,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }
                            }
                          }else{
                            self.errorMessageForSearch = 'No result found.'
                          }
                        })  
                        if(found == true){
                            arr.push(datas);
                        } 
                      
                  }
                }
                else if(self.Mainsearchkeyword == 'Documents' && self.searchkeyword == ''){
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'pdf' ||
                            data.type.toLowerCase() == 'excel' ||
                            data.type.toLowerCase() == 'word' ||
                            data.type.toLowerCase() == 'odt' ||
                            data.type.toLowerCase() == 'ods' ||
                            data.type.toLowerCase() == 'ppt' ||
                            data.type.toLowerCase() == 'html' ||
                            data.type.toLowerCase() == 'plain' ||
                            data.type.toLowerCase() == 'xd' 
                            ){
                            found = true
                            var match = false
                            if(self.searchChatListArray.length == 0){
                              self.searchChatListArray.push({
                                firstName : data.firstName,
                                type : data.type,
                                mediaUrl : data.mediaUrl,
                                msg : data.msg,
                                name : data.name,
                                createdAt : data.createdAt,
                                createDate : data.createDate,
                                lastWeekStartDate : data.lastWeekStartDate,
                                lastWeekEndDate : data.lastWeekEndDate,
                                lastMonthDate : data.lastMonthDate,
                                 isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                              });
                            }else{
                              self.searchChatListArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                                  match = true
                                }
                              })
                              if(!match){
                                  self.searchChatListArray.push({
                                  firstName : data.firstName,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }
                            }
                          }else{
                            self.errorMessageForSearch = 'No result found.'
                          }
                        })
                        if(found == true){
                            arr.push(datas);
                          
                        }  
                  }
                }
                else if(self.Mainsearchkeyword == 'Videos' && self.searchkeyword == ''){
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'video'){
                            found = true;
                              var match = false
                              if(self.searchChatListArray.length == 0){
                                self.searchChatListArray.push({
                                  firstName : data.firstName,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }else{
                                self.searchChatListArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.mediaUrl == data.mediaUrl){
                                  match = true
                                }
                              })
                                if(!match){
                                  self.searchChatListArray.push({
                                    firstName : data.firstName,
                                    type : data.type,
                                    mediaUrl : data.mediaUrl,
                                    msg : data.msg,
                                    name : data.name,
                                    createdAt : data.createdAt,
                                    createDate : data.createDate,
                                    lastWeekStartDate : data.lastWeekStartDate,
                                    lastWeekEndDate : data.lastWeekEndDate,
                                    lastMonthDate : data.lastMonthDate,
                                     isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                  });
                                }
                              }
                          }else{
                            self.errorMessageForSearch = 'No result found.'
                          }
                        })
                        if(found == true){
                            arr.push(datas);
                            
                        }  
                  }
                }
                else if(self.Mainsearchkeyword == 'Links' && self.searchkeyword == ''){
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'link'){
                            found = true;
                            var match = false
                            if(self.searchChatListArray.length == 0){
                                self.searchChatListArray.push({
                                  firstName : data.firstName,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                            }else{
                              self.searchChatListArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                                  match = true
                                }
                              })
                              if(!match){
                                  self.searchChatListArray.push({
                                  firstName : data.firstName,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }
                            }
                          }else{
                            self.errorMessageForSearch = 'No result found.'
                          }
                        })
                        if(found == true){
                            arr.push(datas);
                        }  
                  }
                }
                else if(self.Mainsearchkeyword == 'Photos' && self.searchkeyword != ''){
                    if(datas.messages.length > 0){
                        var found = false
                        datas.messages.forEach(data =>{
                            if(data.type.toLowerCase() == 'image'){
                              if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                                found = true;
                                var match = false
                                if(self.searchChatListArray.length == 0){
                                  self.searchChatListArray.push({
                                      firstName : data.firstName,
                                      type : data.type,
                                      mediaUrl : data.mediaUrl,
                                      msg : data.msg,
                                      name : data.name,
                                      createdAt : data.createdAt,
                                      createDate : data.createDate,
                                      lastWeekStartDate : data.lastWeekStartDate,
                                      lastWeekEndDate : data.lastWeekEndDate,
                                      lastMonthDate : data.lastMonthDate,
                                       isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                  });
                                }else{
                                  self.searchChatListArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.mediaUrl == data.mediaUrl){
                                  match = true
                                }
                              })
                              if(!match){
                                  self.searchChatListArray.push({
                                  firstName : data.firstName,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }
                                }
                              }else{
                                self.errorMessageForSearch = 'No result found.'
                              }
                            }
                        })
                        if(found == true){
                            arr.push(datas)
                        }  
                    }
                }
                else if(self.Mainsearchkeyword == 'Documents' && self.searchkeyword != ''){
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'pdf' ||
                            data.type.toLowerCase() == 'excel' ||
                            data.type.toLowerCase() == 'word' ||
                            data.type.toLowerCase() == 'odt' ||
                            data.type.toLowerCase() == 'ods' ||
                            data.type.toLowerCase() == 'ppt' ||
                            data.type.toLowerCase() == 'html' ||
                            data.type.toLowerCase() == 'plain' ||
                            data.type.toLowerCase() == 'xd' 
                            ){
                            if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                              found = true;
                              var match = false
                              if(self.searchChatListArray.length == 0){
                                self.searchChatListArray.push({
                                  firstName : data.firstName,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }else{
                                self.searchChatListArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                                  match = true
                                }
                              })
                              if(!match){
                                  self.searchChatListArray.push({
                                  firstName : data.firstName,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }
                              } 
                            }else{
                              self.errorMessageForSearch = 'No result found.'
                            }
                          }
                        })
                        if(found == true){
                            arr.push(datas);
                          
                        }  
                  }
                }
                else if(self.Mainsearchkeyword == 'Videos' && self.searchkeyword != ''){
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'video'){
                            if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                              found = true;
                              var match = false
                              if(self.searchChatListArray.length == 0){
                                self.searchChatListArray.push({
                                  firstName : data.firstName,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }else{
                                  self.searchChatListArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.mediaUrl == data.mediaUrl){
                                  match = true
                                }
                              })
                                if(!match){
                                  self.searchChatListArray.push({
                                    firstName : data.firstName,
                                    type : data.type,
                                    mediaUrl : data.mediaUrl,
                                    msg : data.msg,
                                    name : data.name,
                                    createdAt : data.createdAt,
                                    createDate : data.createDate,
                                    lastWeekStartDate : data.lastWeekStartDate,
                                    lastWeekEndDate : data.lastWeekEndDate,
                                    lastMonthDate : data.lastMonthDate,
                                     isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                  });
                                }
                              }
                            }else{
                              self.errorMessageForSearch = 'No result found.'
                            }
                          }
                        })
                        if(found == true){
                            arr.push(datas);
                            
                        }  
                  }
                }
                else if(self.Mainsearchkeyword == 'Links' && self.searchkeyword != ''){
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'link'){
                            if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                              found = true;
                              var match = false
                              if(self.searchChatListArray.length == 0){
                                self.searchChatListArray.push({
                                  firstName : data.firstName,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }else{
                                self.searchChatListArray.forEach(searchobject => {
                                  if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                                  match = true
                                  }
                                })
                              if(!match){
                                  self.searchChatListArray.push({
                                  firstName : data.firstName,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }
                              }
                            }else{
                              self.errorMessageForSearch = 'No result found.'
                            }
                          }
                        })
                        if(found == true){
                            arr.push(datas);
                        }  
                  }
                }
                else if(self.Mainsearchkeyword == '' && self.searchkeyword != ''){
                
                    // if(datas.firstName.toLowerCase().includes(self.searchkeyword.toLowerCase()))
                    // {
                    //   arr.push(datas)
                    // }
                    // else if(datas.lastName.toLowerCase().includes(self.searchkeyword.toLowerCase()))
                    // {
                    //   arr.push(datas)
                    // }
                    if(fullname.toLowerCase().includes(self.searchkeyword.toLowerCase()))
                    {
                      // arr.push(datas)
                    }
                    else if(datas.messages.length > 0){
                        var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'text' ){
                            if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                              found = true;
                              var match = false;
                              if(self.searchChatListArray.length == 0){
                                self.searchChatListArray.push({
                                    firstName : data.firstName,
                                    type : data.type,
                                    mediaUrl : data.mediaUrl,
                                    msg : data.msg,
                                    name : data.name,
                                    createdAt : data.createdAt,
                                    createDate : data.createDate,
                                    lastWeekStartDate : data.lastWeekStartDate,
                                    lastWeekEndDate : data.lastWeekEndDate,
                                    lastMonthDate : data.lastMonthDate,
                                     isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }else{
                                  self.searchChatListArray.forEach(searchobject => {
                                  if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                                    match = true
                                  }
                              })
                              if(!match){
                                  self.searchChatListArray.push({
                                  firstName : data.firstName,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }
                              }
                            }else{
                              self.errorMessageForSearch = 'No result found.'
                            }
                          }
                        })
                        if(found == true){
                            arr.push(datas)
                        }  
                    }
                }
              }else if(self.selectedActiveMenu=="admin" && (datas.isGroup || datas.isChannel)){
                if(self.Mainsearchkeyword == 'Photos' && self.searchkeyword == ''){
                  
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'image'){
                            found = true
                            var match = false
                            if(self.adminDisplayArray.length == 0){
                              self.adminDisplayArray.push({
                                firstName : datas.title,
                                type : data.type,
                                mediaUrl : data.mediaUrl,
                                msg : data.msg,
                                name : data.name,
                                createdAt : data.createdAt,
                                createDate : data.createDate,
                                lastWeekStartDate : data.lastWeekStartDate,
                                lastWeekEndDate : data.lastWeekEndDate,
                                lastMonthDate : data.lastMonthDate,
                                isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                              });
                            }else{
                              self.adminDisplayArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.mediaUrl == data.mediaUrl){
                                  match = true
                                }
                              })
                              if(!match){
                                  self.adminDisplayArray.push({
                                  firstName : datas.title,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                  isGroup : datas.isGroup,
                                  isChannel : datas.isChannel
                                });
                              }
                            }
                          }else{
                            self.errorMessageForSearchAdmin = 'No result found.'
                          }
                        })  
                        if(found == true){
                            arr.push(datas);
                        } 
                      
                  }
                }
                else if(self.Mainsearchkeyword == 'Documents' && self.searchkeyword == ''){
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'pdf' ||
                            data.type.toLowerCase() == 'excel' ||
                            data.type.toLowerCase() == 'word' ||
                            data.type.toLowerCase() == 'odt' ||
                            data.type.toLowerCase() == 'ods' ||
                            data.type.toLowerCase() == 'ppt' ||
                            data.type.toLowerCase() == 'html' ||
                            data.type.toLowerCase() == 'plain' ||
                            data.type.toLowerCase() == 'xd' 
                            ){
                            found = true
                            var match = false
                            if(self.adminDisplayArray.length == 0){
                              self.adminDisplayArray.push({
                                firstName : datas.title,
                                type : data.type,
                                mediaUrl : data.mediaUrl,
                                msg : data.msg,
                                name : data.name,
                                createdAt : data.createdAt,
                                createDate : data.createDate,
                                lastWeekStartDate : data.lastWeekStartDate,
                                lastWeekEndDate : data.lastWeekEndDate,
                                lastMonthDate : data.lastMonthDate,
                                 isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                              });
                            }else{
                              self.adminDisplayArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                                  match = true
                                }
                              })
                              if(!match){
                                  self.adminDisplayArray.push({
                                  firstName : datas.title,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }
                            }
                          }else{
                            self.errorMessageForSearchAdmin = 'No result found.'
                          }
                        })
                        if(found == true){
                            arr.push(datas);
                          
                        }  
                  }
                }
                else if(self.Mainsearchkeyword == 'Videos' && self.searchkeyword == ''){
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'video'){
                            found = true;
                              var match = false
                              if(self.adminDisplayArray.length == 0){
                                self.adminDisplayArray.push({
                                  firstName : datas.title,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }else{
                                self.adminDisplayArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.mediaUrl == data.mediaUrl){
                                  match = true
                                }
                              })
                                if(!match){
                                  self.adminDisplayArray.push({
                                    firstName : datas.title,
                                    type : data.type,
                                    mediaUrl : data.mediaUrl,
                                    msg : data.msg,
                                    name : data.name,
                                    createdAt : data.createdAt,
                                    createDate : data.createDate,
                                    lastWeekStartDate : data.lastWeekStartDate,
                                    lastWeekEndDate : data.lastWeekEndDate,
                                    lastMonthDate : data.lastMonthDate,
                                     isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                  });
                                }
                              }
                          }else{
                            self.errorMessageForSearchAdmin = 'No result found.'
                          }
                        })
                        if(found == true){
                            arr.push(datas);
                            
                        }  
                  }
                }
                else if(self.Mainsearchkeyword == 'Links' && self.searchkeyword == ''){
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'link'){
                            found = true;
                            var match = false
                            if(self.adminDisplayArray.length == 0){
                                self.adminDisplayArray.push({
                                  firstName : datas.title,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                            }else{
                              self.adminDisplayArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                                  match = true
                                }
                              })
                              if(!match){
                                  self.adminDisplayArray.push({
                                  firstName : datas.title,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }
                            }
                          }else{
                            self.errorMessageForSearchAdmin = 'No result found.'
                          }
                        })
                        if(found == true){
                            arr.push(datas);
                        }  
                  }
                }
                else if(self.Mainsearchkeyword == 'Photos' && self.searchkeyword != ''){
                    if(datas.messages.length > 0){
                        var found = false
                        datas.messages.forEach(data =>{
                            if(data.type.toLowerCase() == 'image'){
                              if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                                found = true;
                                var match = false
                                if(self.adminDisplayArray.length == 0){
                                  self.adminDisplayArray.push({
                                      firstName : datas.title,
                                      type : data.type,
                                      mediaUrl : data.mediaUrl,
                                      msg : data.msg,
                                      name : data.name,
                                      createdAt : data.createdAt,
                                      createDate : data.createDate,
                                      lastWeekStartDate : data.lastWeekStartDate,
                                      lastWeekEndDate : data.lastWeekEndDate,
                                      lastMonthDate : data.lastMonthDate,
                                      isGroup : datas.isGroup,
                                      isChannel : datas.isChannel
                                  });
                                }else{
                                  self.adminDisplayArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.mediaUrl == data.mediaUrl){
                                  match = true
                                }
                              })
                              if(!match){
                                  self.adminDisplayArray.push({
                                  firstName : datas.title,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                  isGroup : datas.isGroup,
                                  isChannel : datas.isChannel
                                });
                              }
                                }
                              }else{
                                self.errorMessageForSearchAdmin = 'No result found.'
                              }
                            }
                        })
                        if(found == true){
                            arr.push(datas)
                        }  
                    }
                }
                else if(self.Mainsearchkeyword == 'Documents' && self.searchkeyword != ''){
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'pdf' ||
                            data.type.toLowerCase() == 'excel' ||
                            data.type.toLowerCase() == 'word' ||
                            data.type.toLowerCase() == 'odt' ||
                            data.type.toLowerCase() == 'ods' ||
                            data.type.toLowerCase() == 'ppt' ||
                            data.type.toLowerCase() == 'html' ||
                            data.type.toLowerCase() == 'plain' ||
                            data.type.toLowerCase() == 'xd' 
                            ){
                            if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                              found = true;
                              var match = false
                              if(self.adminDisplayArray.length == 0){
                                self.adminDisplayArray.push({
                                  firstName : datas.title,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }else{
                                self.adminDisplayArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                                  match = true
                                }
                              })
                              if(!match){
                                  self.adminDisplayArray.push({
                                  firstName : datas.title,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }
                              } 
                            }else{
                              self.errorMessageForSearchAdmin = 'No result found.'
                            }
                          }
                        })
                        if(found == true){
                            arr.push(datas);
                          
                        }  
                  }
                }
                else if(self.Mainsearchkeyword == 'Videos' && self.searchkeyword != ''){
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'video'){
                            if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                              found = true;
                              var match = false
                              if(self.adminDisplayArray.length == 0){
                                self.adminDisplayArray.push({
                                  firstName : datas.title,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }else{
                                  self.adminDisplayArray.forEach(searchobject => {
                                if(searchobject.createDate == data.createDate && searchobject.mediaUrl == data.mediaUrl){
                                  match = true
                                }
                              })
                                if(!match){
                                  self.adminDisplayArray.push({
                                    firstName : datas.title,
                                    type : data.type,
                                    mediaUrl : data.mediaUrl,
                                    msg : data.msg,
                                    name : data.name,
                                    createdAt : data.createdAt,
                                    createDate : data.createDate,
                                    lastWeekStartDate : data.lastWeekStartDate,
                                    lastWeekEndDate : data.lastWeekEndDate,
                                    lastMonthDate : data.lastMonthDate,
                                     isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                  });
                                }
                              }
                            }else{
                              self.errorMessageForSearchAdmin = 'No result found.'
                            }
                          }
                        })
                        if(found == true){
                            arr.push(datas);
                            
                        }  
                  }
                }
                else if(self.Mainsearchkeyword == 'Links' && self.searchkeyword != ''){
                  if(datas.messages.length > 0){
                    var found = false
                        datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'link'){
                            if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                              found = true;
                              var match = false
                              if(self.adminDisplayArray.length == 0){
                                self.adminDisplayArray.push({
                                  firstName : datas.title,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }else{
                                self.adminDisplayArray.forEach(searchobject => {
                                  if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                                  match = true
                                  }
                                })
                              if(!match){
                                  self.adminDisplayArray.push({
                                  firstName : datas.title,
                                  type : data.type,
                                  mediaUrl : data.mediaUrl,
                                  msg : data.msg,
                                  name : data.name,
                                  createdAt : data.createdAt,
                                  createDate : data.createDate,
                                  lastWeekStartDate : data.lastWeekStartDate,
                                  lastWeekEndDate : data.lastWeekEndDate,
                                  lastMonthDate : data.lastMonthDate,
                                   isGroup : datas.isGroup,
                                isChannel : datas.isChannel
                                });
                              }
                              }
                            }else{
                              self.errorMessageForSearchAdmin = 'No result found.'
                            }
                          }
                        })
                        if(found == true){
                            arr.push(datas);
                        }  
                  }
                }
                else if(self.Mainsearchkeyword == '' && self.searchkeyword != ''){
                  if(datas.title.toLowerCase().includes(self.searchkeyword.toLowerCase()))
                  {
                    // arr.push(datas)
                  }
                  else if(datas.messages.length > 0){ 
                    var found = false
                      datas.messages.forEach(data =>{
                          if(data.type.toLowerCase() == 'text' ){
                            if(data.msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                              found = true;
                              
                              var match = false;
                              if(self.adminDisplayArray.length == 0){
                                self.adminDisplayArray.push({
                                      firstName : datas.title,
                                      type : data.type,
                                      mediaUrl : data.mediaUrl,
                                      msg : data.msg,
                                      name : data.name,
                                      createdAt : data.createdAt,
                                      createDate : data.createDate,
                                      lastWeekStartDate : data.lastWeekStartDate,
                                      lastWeekEndDate : data.lastWeekEndDate,
                                      lastMonthDate : data.lastMonthDate,
                                      isGroup : datas.isGroup,
                                  isChannel : datas.isChannel
                                  });
                                }else{
                                  self.adminDisplayArray.forEach(searchobject => {
                                  if(searchobject.createDate == data.createDate && searchobject.msg == data.msg){
                                    match = true
                                  }
                                })
                                if(!match){
                                    self.adminDisplayArray.push({
                                    firstName : datas.title,
                                    type : data.type,
                                    mediaUrl : data.mediaUrl,
                                    msg : data.msg,
                                    name : data.name,
                                    createdAt : data.createdAt,
                                    createDate : data.createDate,
                                    lastWeekStartDate : data.lastWeekStartDate,
                                    lastWeekEndDate : data.lastWeekEndDate,
                                    lastMonthDate : data.lastMonthDate,
                                    isGroup : datas.isGroup,
                                    isChannel : datas.isChannel
                                  });
                                }
                              }
                            }else{
                              self.errorMessageForSearchAdmin = 'No result found.'
                            }
                          }
                      })
                      if(found == true){
                          arr.push(datas);
                      }  
                  }
                }
              }

            })  
          if(self.adminDisplayArray.length == 0 &&  self.Mainsearchkeyword != ''){
            self.errorMessageForSearchAdmin = 'No Result found'
          }
          if(self.searchChatListArray.length == 0 &&  self.Mainsearchkeyword != ''){
            self.errorMessageForSearch = 'No Result found'
          }
          if(arr.length > 0){
            chatlistArray  = arr
            self.chatListArray = arr
          }
          // if(arr.length == 0 && ){
          //   self.chatListArray = arr
          //   chatlistArray = []
            
          // }
          
          if(self.searchkeyword == '' && self.Mainsearchkeyword == ''){
            self.chatListArray = self.storeBusinessChatList
          }
          if(arr.length == 0 && self.searchChatListArray.length == 0){
            self.errorMessageForSearch = 'No Result found'
            chatlistArray = []
          }
          if(arr.length == 0 && self.adminDisplayArray.length == 0){
            self.errorMessageForSearchAdmin = 'No Result found'
            chatlistArray = []
          }
          // console.log('arr',arr,self.searchChatListArray,self.adminDisplayArray,self.errorMessageForSearchAdmin,self.errorMessageForSearch)
        },
        
          // SEARCH CLICK FOR ARRAY DISPLAY FOR SEARCHING(Shraddha)
        searchClick(){
          // debugger // eslint-disable-line
          var self = this;
          if(self.Mainsearchkeyword == ''){
          self.showSearchList = true;
          // self.searchkeyword = '';
           self.errorMessageForSearch = '';
           self.errorMessageForSearchAdmin = '';
          //  self.searchFilter();
          }else{
            self.showSearchList = false;
            // self.searchkeyword = '';
             self.errorMessageForSearch = '';
             self.errorMessageForSearchAdmin = '';
            //  self.searchFilter();
          }
          
          
        },
        // ADD VALUE IN SEARCHBAR (Shraddha)
        addInSearchBar(title){
          //  debugger // eslint-disable-line
          var self = this;
          self.Mainsearchkeyword = title;
          self.closeSearchList = true;
          self.searchChatListArray = [];
          self.adminDisplayArray = [];
          self.searchkeyword = '';
          self.searchFilter();
          self.showSearchList = false;
           self.errorMessageForSearch = '';
           self.errorMessageForSearchAdmin = '';
        },
        // CLOSE SERACH BAR(Shraddha)
        closeSearchBar(){
          var self = this;
          self.Mainsearchkeyword = '';
          self.showSearchList = false;
          self.closeSearchList = false;
          self.searchChatListArray = [];
          self.adminDisplayArray = [];
          self.searchkeyword = '';
          self.errorMessageForSearch = '';
          self.errorMessageForSearchAdmin = '';
          
        },
        //OPEN SEARCHBAR (Shraddha)
        openSearchBar(){
          var self = this;
          self.showSearchList = true;
          self.searchkeyword = '';
           self.errorMessageForSearch = '';
           self.errorMessageForSearchAdmin = '';
        },
     
        // SET BULKMESSAGE FOCUS (Shraddha)
        setBulkMesagesFocus(){
          // //debugger // eslint-disable-line
          setTimeout(()=>{
            this.$nextTick(() => {
                this.$refs.bulkmessage.focus();
            })
          },1000)
        },
        //SET CHATMESSAGE FOCUS(Shraddha)
        setchatMesagesFocus(){
          var self=this;
          self.$root.$emit('setchatfocus','yes');
        },
        //EXIT FROM GROUP(Shraddha)
        confirmationPopup(groupData)
        {
          var self = this
          // console.log('groupData',groupData,groupData.adminId == firebase.auth().currentUser.uid)
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          if(groupData.isGroup || groupData.isChannel)
          {
            if(groupData.adminId == firebase.auth().currentUser.uid)
            {
              Swal.fire({
                title: `Are you sure you want to delete the ${groupData.title} ${groupData.isGroup?'group':'channel'}?`,
                type: "warning",
                customClass:{
                  container :"business_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: "#77bef6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.value) {
                  self.deleteGroup(groupData)
                }
              })
            }
            else
            {
              Swal.fire({
                title: `Are you sure you want to exit the ${groupData.title} ${groupData.isGroup?'group':'channel'}?`,
                type: "warning",
                 customClass:{
                  container :"business_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: "#77bef6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.value) {
                  self.isExitGroup(groupData)
                }
              })
            }
          }
          else
          {
            const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
            Swal.fire({
                title: `${groupData.firstName} ${groupData.lastName} will be removed from your chat contacts, Are you sure you want to remove this chat?`,
                type: "warning",
                 customClass:{
                  container :"business_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: "#77bef6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                self.removeFromContact(groupData)
              }
            })
          }
        },
        isExitGroup(groupData){
          // debugger // eslint-disable-line
          var self = this;
          //console.log('groupDatagroupDatagroupData',groupData)
          var chatId = groupData.msgid
          var userData = self.userData
          var uid = firebase.auth().currentUser.uid
          var objectid = ''
          var chattedId = ''
          const db = firebase.firestore()
          var loginUser = localStorage.getItem('customerDocKey')
         
        exitGroup(loginUser,groupData.connectedUser,res => {
            // console.log('rererererer',res)

            //Left Group message
            var from={
              avatar:'',
              id:userData.id,
              name:`${userData.firstName} ${userData.lastName}`,
              path:''
            }
            groupData.messageSeenIds.forEach(data=>{
              if(data.id != self.userid)
              {
                data.count++
              }
            })
            //remove user from messageSeenIds
            var messageSeenIds = groupData.messageSeenIds||[]
            messageSeenIds.forEach((data,index)=>{
              if(data.id == uid)
              {
                messageSeenIds.splice(index,1)
              }
            })

            //remove user from mainBranches messageSeenIds
            var mainBranches = groupData.mainBranches||[]
            mainBranches.forEach((data)=>{
              data.messageSeenIds.forEach((msgid,index)=>{
                if(msgid.id == uid)
                {
                  data.messageSeenIds.splice(index,1)
                }
              })
              data.subBranches.forEach((data2)=>{
                data2.messageSeenIds.forEach((msgid2,index2)=>{
                  if(msgid2.id == uid)
                  {
                    data2.messageSeenIds.splice(index2,1)
                  }
                })
                data2.subSubBranches.forEach((data3)=>{
                  data3.messageSeenIds.forEach((msgid3,index3)=>{
                    if(msgid3.id == uid)
                    {
                      data3.messageSeenIds.splice(index3,1)
                    }
                  })
                })
              })            
            })
            // console.log(`${userData.firstName} ${userData.lastName} left.`,from)
            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatId).collection('default').add({
              createdAt:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
              messageSeen:[from],
              msg:`${userData.firstName} ${userData.lastName} left.`,
              from:from,
              type:'Left',
              name:'Left',
              id:''
            })

            .then((docRef)=>{
              db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatId).collection('default').doc(docRef.id).update({
                id:docRef.id
              })



              db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatId).update({
                connectedUser : res,
                messageSeenIds:messageSeenIds,
                mainBranches:mainBranches
              }).then(()=>{
                self.$root.$emit('activeGroup' ,'yes');
                self.$root.$emit('hideViewDetail',objectid,chattedId)
                self.$root.$emit("audioplay");
                self.$notify({
                  title:"Success",
                  text:'Group delete Successfully',
                  type:"success"
                })
              })
            })
          })
        },
        deleteGroup(groupData)
        {
          var self = this
          db
            .collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
            .doc(groupData.msgid)
            .update({
              isDeleted:true
            })
            .then(()=>{
              self.$root.$emit('activeGroup' ,'yes');
              self.$root.$emit('hideViewDetail','','')
              self.$root.$emit("audioplay");
              self.$notify({
                title:"Success",
                text:'Group delete Successfully',
                type:"success"
              })
            })
            .catch(error=>{
              console.log(error)
            })
        },
        sendSidebarData(chatid){
          // //debugger // eslint-disable-line
          var self=this;
           self.groupObject = {}
          const db = firebase.firestore()
           db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatid).get().then((doc) =>{
                self.groupObject = doc.data()
                self.getSetBusinessChatData({companyId:Vue.prototype.$companyName.id,collection:'businessMessages',chatId:chatid})
                self.$root.$emit('sendgroupArray','default',self.groupObject,chatid)
            })
        },
        // BULK MESSAGE CLOSE EVENT(Shraddha)
        closeFire(){
           var self = this;
           self.sendingMessage = '';
           self.keyword = ''
           self.bulkMemberList = []
           self.$refs.log.reset();
        },
         //DOWNLOAD DOCUMENT (Shraddha) 
        downloadDocument(url,name){
          // console.log("url,,,,,,,,,",url);
          var naming = name.split('.');      
          this.$axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download',name);
                document.body.appendChild(fileLink);
                fileLink.click(); 
            })
            .catch(error=>{
                this.$notify({
                    title:"Error",
                    text:error.message,
                    type:"error"
                })           
            })
        },
         //CHECK DATE FOR SEARCH(Shraddha)
    checkDate(expireyTimeObjects)
    { 
      // console.log('expireyTimeObjects',expireyTimeObjects)
      var expireyTimeObjectsdate = (new Date(expireyTimeObjects).getDate());
      var expireyTimeObjectsmonth = (new Date(expireyTimeObjects).getMonth());
      var expireyTimeObjectsyear = (new Date(expireyTimeObjects).getFullYear());

      var todayDatesplit = new Date().getDate();
      var todayMonthsplit = new Date().getMonth();
      var todayYearsplit = new Date().getFullYear();
      // console.log(expireyTimeObjectsdate,expireyTimeObjectsmonth,expireyTimeObjectsyear,todayDatesplit,todayMonthsplit,todayYearsplit)
      if(expireyTimeObjectsdate == todayDatesplit && expireyTimeObjectsmonth == todayMonthsplit &&expireyTimeObjectsyear == todayYearsplit)
      {
        return this.addZero(new Date(expireyTimeObjects).getHours())+':'+this.addZero(new Date(expireyTimeObjects).getMinutes())
      }
      else if(expireyTimeObjectsdate+1 == todayDatesplit && expireyTimeObjectsmonth == todayMonthsplit &&expireyTimeObjectsyear == todayYearsplit)
      {
        return 'Yesterday'
      }
      else
      {
        return this.addZero(expireyTimeObjectsdate)+'/'+ this.addZero(expireyTimeObjectsmonth+1)
      }
    },
    addZero(val)
    {
        if(val<10)
        {
          return '0'+val;
        }
        else
        {
          return val;
        }
    },
      //MAIN BRANCH DELETE (Shraddha)
    MainbranchDelete(mainbranch,BranchName,chatId,msgid){
      // debugger // eslint-disable-line
       const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      Swal.fire({
          title: 'Are you sure you want to delete branch?',
          type: "warning",
          customClass:{
              container :"social_confirmation_popup"
          },
          showCancelButton: true,
          confirmButtonColor: "#ffab3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
      }).then((result) => {
          if (result.value) {
          var self = this;
         
          var allBranch = [];
          allBranch.push(BranchName)
          var index = mainbranch.findIndex(item => {
              return item.name == BranchName
          })
          
          mainbranch.forEach((data) => {
            if(mainbranch[index].subBranches){
              var subbranches = mainbranch[index].subBranches
              var found = false;
              subbranches.forEach((subdata) => {
                allBranch.forEach(foundData => {
                  if(foundData == subdata.name){
                    found = true;
                  }
                })
                if(!found){
                allBranch.push(subdata.name)
                  if(subdata.subSubBranches){
                    var subSubBranch = subdata.subSubBranches
                    var match = false;
                    subSubBranch.forEach((subSubData => {
                      allBranch.forEach(foundDatas => {
                        if(foundDatas == subSubData.name){
                          match = true;
                        }
                      })
                      if(!match){
                        allBranch.push(subSubData.name)
                      }
                    }))
                  }
                }
              })
            }
          })

          mainbranch.splice(index,1);
          
          db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatId).update({
            mainBranches : mainbranch, 
          }).then(() => {
            // console.log('mainbranchdelete',allBranch,mainbranch,BranchName)
            allBranch.forEach(deleteBranchName => { 
              db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatId).collection(deleteBranchName).get().then(docData => {
                docData.forEach(doc => {
                  db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatId).collection(deleteBranchName).doc(doc.id).delete();
                })
              })
            })
          })
          if(msgid != ''){
            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatId).collection('default').doc(msgid).update({
              branch : ''
            })
          }
          self.$root.$emit("audioplay");
          self.$notify({
            title:"Success",
            text:"Branch delete successfully.",
            type:"success"
          })
          self.$root.$emit('mainBranchActiveFalseBusiness',chatId);
        }
      })
      
    },
      // COUNT ALL BRANCH (Shraddha)
    countAllbranch(branchData,branchString){
      // debugger // eslint-disable-line
       var self = this
      var mainCount = 0; 
      var subCount = 0;
      var subSubCount = 0;
      var uid = firebase.auth().currentUser.uid
      // console.log(branchData,branchString)
        if(branchString == 'mainbranch'){
          if(branchData.name != self.currCollection){
            branchData.messageSeenIds.forEach(ids=>{
              if(ids != 0){
                if(ids.id == firebase.auth().currentUser.uid)
                {
                  mainCount+=ids.count
                }
              }
            })
          
          }
          if(branchData.subBranches){
            branchData.subBranches.forEach(data2=>{
              if(data2.name != self.currCollection){
                data2.messageSeenIds.forEach(ids2=>{
                  if(ids2 != 0){
                    if(ids2.id == firebase.auth().currentUser.uid)
                    {
                      subCount+= ids2.count
                    }
                  }
                })
                
              }
              if(data2.subSubBranches){
                data2.subSubBranches.forEach(data3=>{
                  if(data3.name != self.currCollection){
                    data3.messageSeenIds.forEach(ids3=>{
                      if(ids3 != 0){
                        if(ids3.id == firebase.auth().currentUser.uid)
                        {
                          subSubCount+= ids3.count
                        }
                      }
                    })
                  }
                })
              }
            })
          }
        } 
      
        
        if(branchString == 'mainbranch'){
          return (mainCount + subCount + subSubCount);
        }
        else if(branchString == 'subbranch'){
          return (subCount + subSubCount);
        }
        else if (branchString == 'subsubbranch'){
          return subSubCount;
        }
    },
      // PLUS DATACOUNT IN MAIN (Shraddha)
      getTotalCount(chatData){
        //  debugger // eslint-disable-line
        var self = this
        var count = 0;
        var mainCount = 0; 
        var subCount = 0;
        var subSubCount = 0;
        var uid = firebase.auth().currentUser.uid
        if(chatData.blockBy != uid){
          chatData.messageSeenIds.forEach(ids=>{
            if(ids != 0){
              if(ids.id == firebase.auth().currentUser.uid)
              {
                count+=ids.count
              }
            }
          })
          if(chatData.mainBranches){
            chatData.mainBranches.forEach(data=>{
              data.messageSeenIds.forEach(ids=>{
                if(ids != 0){
                  if(ids.id == firebase.auth().currentUser.uid)
                  {
                    mainCount+=ids.count
                  }
                }
              })
              if(data.subBranches){
                data.subBranches.forEach(data2=>{
                  data2.messageSeenIds.forEach(ids2=>{
                    if(ids2 != 0){
                      if(ids2.id == firebase.auth().currentUser.uid)
                      {
                        subCount+= ids2.count
                      }
                    }
                  })
                  if(data2.subSubBranches){
                    data2.subSubBranches.forEach(data3=>{
                      data3.messageSeenIds.forEach(ids3=>{
                        if(ids3 != 0){
                          if(ids3.id == firebase.auth().currentUser.uid)
                          {
                            subSubCount+= ids3.count
                          }
                        }
                      })
                    })
                  }
                })
              }
            })
          }

          return count + mainCount + subCount + subSubCount;
        }
      }
    },
   //LISTING DATA (Shraddha)
  computed: {
    ...mapGetters({userData:"getUserData"}),
    ...mapGetters({storeChatId:"getChatId"}),
    ...mapGetters({storeCollectionName:"getCurrentCollection"}),
    ...mapGetters({storeSelectedCompany:"getSelectedCompany"}),
    ...mapGetters({storeSocialChatList:"getSocialChatList"}),
    ...mapGetters({storeBusinessChatList:"getBusinessChatList"}),
    getFilterData() {
      var self = this;
      return self.userSearchList.filter(function(rawData) {
        return (
          rawData["firstName"]
            .toLowerCase()
            .indexOf(self.keyword.toLowerCase()) >= 0 ||
          rawData["lastName"]
            .toLowerCase()
            .indexOf(self.keyword.toLowerCase()) >= 0 
        );
      });
    },
    getDisplayChatList(){
      var self = this;
      self.chatlistArray = self.storeBusinessChatList;
      // console.log('self.storeBusinessChatList',self.storeBusinessChatList)
      return self.storeBusinessChatList.filter(function(rawData) {
        if(rawData.isGroup || rawData.isChannel){
         
        return (
          rawData["title"]
            .toLowerCase()
            .indexOf(self.searchkeyword.toLowerCase()) >= 0
          )
        }else{
          return (
          rawData["fullname"]
            .toLowerCase()
            .indexOf(self.searchkeyword.toLowerCase()) >= 0
          )
        }
      });
    }
  },
})
</script>
<style scoped>
.greenDot{
  background-color: #85DA27;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  vertical-align: middle;
  margin-left: 10px;
}
.redDot{
  background-color: #f53434;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  vertical-align: middle;
  margin-left: 10px;
}
.greenDot{
  background-color: #85DA27;
  /* height: 20px;
  width: 20px; */
  border-radius: 24px;
  vertical-align: middle;
  /* margin-left: 10px; */
  color: white;
  display: inherit;
}
.greenDot.blue-count {
        background-color: #85DA27;
      color: #000;
}
.greenDot label {
    height: 100%;
    width: 100%;
    text-align: center;
    line-height: 25px;
    font-size: 11px;
    font-weight: 500;
}
.redDot{
  background-color: #808080;
  height: 8px;
  width: 8px;
  border-radius: 10px;
  vertical-align: middle;
  margin-left: 10px;
  display: inherit;
}
.user_search_list ul
{
  height:auto!important;
}
</style>