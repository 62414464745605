import firebase from "../views/firebaseInit";
import Vue from 'vue'
const storageRef = firebase.storage().ref()
export const uploadImageFile = function(folderName,imageName,imagesize,file,docType,text,cb)
{
    //Set ImageName
    var ImageNameSplit = imageName.split('.')
    var str = (
        Math.random().toString(36).substring(2, 8) +
        Math.random().toString(36).substring(2, 8)
    ).toUpperCase();
    var ImageName=""
    if(ImageNameSplit.length>2)
    {
        for (let index = 0; index < ImageNameSplit.length-1; index++) {
            ImageName += `${ImageNameSplit[index]}`
        }
        ImageName+=`_${str}.${ImageNameSplit[ImageNameSplit.length-1]}`
    }
    else
    {
        ImageName=`${ImageNameSplit[0]}_${str}.${ImageNameSplit[1]}`
    }
    
    // console.log(ImageName)
    var uploadTask = storageRef.child(folderName+"/"+ImageName).put(file)
    uploadTask.on('stateChanged',
    (snapshot)=>{
        var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
        // console.log(progress)
    },
    (error)=>{
        console.log(error)
        // self.isSpinner=false;
        // self.$notify({
        //     title:"Error",
        //     text:error.message,
        //     type:"error"
        // })
    },
    ()=>{
        uploadTask.snapshot.ref.getDownloadURL()
        .then(URL=>{
            cb({imageUrl:URL,imageName:ImageName,docType:docType,imageSize:imagesize,Text:text})    
        })
    })
}