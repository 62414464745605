<template>
<div>
    <div class="manage_notification_wrapper" v-if="!isFeedView">
        <div class="admin_Page_title">
                <h4>Feedback</h4> 
                <img class="title_icon" @click="backtodashboard()" src="@/assets/images/home_icon.png" alt="homeIcon">
                <span class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
                <span class="current_page">Feedback</span>
                <!-- <span v-if="isPackageDivShow == true " class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
                <span v-if="isPackageDivShow == true && isEditForm == false "  class="current_page">Add New Package</span>
                <span v-if="isPackageDivShow == true && isEditForm == true " class="current_page">Update Package</span> -->
        </div>
        <!-- {{isFeedView}} -->
        <div class="managenotification_table_wrapper">
            <div class="invester_table manage_notification managefeedback table_wrapper content-table">
                <table class="table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Feedback</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(rawData, indextr) in  tableListData[currentPage]" v-bind:key="indextr" style="cursor:pointer;" @click="getFeedBack(rawData.docId)">
                            <td>{{(currentPage*10)+(indextr+1) &lt; 10 ?'0'+(indextr+1):(currentPage*10)+(indextr+1)}}</td>
                            <td>{{rawData.userName}}</td>
                            <td>{{rawData.email}}</td>
                            <td>{{rawData.phone}}</td>
                            <td v-if="rawData.feedBack.length < 100">{{rawData.feedBack}}</td>
                            <td v-else  >{{(rawData.feedBack).slice(0, 100)+'...'}}</td>
                            <td ><span @click="getFeedBack(rawData.docId)" class="display-normal-eye"><img class="display_eye" alt="eye" src="@/assets/images/eye_hover.png"><img alt="eye" class="display_normal_eye" src="@/assets/images/normal_eye.png"></span></td>

                        </tr>
                    </tbody>
                </table>
                <div v-if="tableListData.length==0" class="no-result-found" style="float: none;">
                    No Data Found
                </div>
                
            </div>
            
        </div>
        <div class="pagination_wrapper" v-show="totalpages>1">
            <ul class="pagination">
                <li @click="(changePage('prev'))"><a><img src="@/assets/images/left-arrow-pagination.png"></a></li>
                <li v-for="(data,index) in totalpages" :key="index" @click="currentPage=index" :class="currentPage==index?'active':''">
                    <a>{{data}}</a>
                </li>
                <li @click="(changePage('next'))"><a><img src="@/assets/images/right-arrow-pagination.png"></a></li>
            </ul>
        </div>


    </div>
    <div v-else>
        <FeedBack :rowId=rowId :isFeedView=isFeedView @UpdateList="OnClickUpdateList"></FeedBack>
    </div>
</div>
</template>
<script>
import firebaseData from '../../utils/firebaseCollection.js'
import firebaseTimestamp from "firebase";
import firebase from "../firebaseInit";
import FeedBack from "@/views/Admin_Panel/FeedBackDetail.vue"
const db = firebase.firestore()

export default {
    name:"Feedback",
    components: {
        FeedBack
    },
    data(){
        return{
            isSpinner:false,
            message:'',
            notifications:[],
            totalpages:0,
            currentPage:0,
            tableListData:[],
            isFeedView:false,
            rowId:''
        }
    },
    created(){
        var self = this;
        self.$root.$on("isUserDetailView",(value) => {
            self.isFeedView = value
            self.getFeedBackData();
        })
        self.getFeedBackData();
    },
    methods:{
        getFeedBackData(){
            // debugger // eslint-disable-line
            var self = this
            db.collection(firebaseData.firebasecollection.FEEDBACK).orderBy('sendDate','desc').onSnapshot(querySnapshot=>{
                self.notifications=[]
                if(!querySnapshot.empty){
                    querySnapshot.forEach(doc=>{
                        self.notifications.push(doc.data())
                    })
                }
                self.pagination(self.notifications)
            })
        },
        pagination(notifications){
            // debugger // eslint-disable-line
            var self = this
            var index = 1
            var dataArray=[]
            self.tableListData=[]
            notifications.forEach(data=>{
                dataArray.push(data)
                if(index % 10 == 0){
                    self.tableListData.push(dataArray)
                    dataArray=[];
                }
                else if(index == notifications.length){
                    self.tableListData.push(dataArray)
                    dataArray=[];
                }
                index++;
            })
            self.totalpages=self.tableListData.length
        },

        changePage(direction){
            if(direction =='prev' && this.currentPage>0)
            {
                this.currentPage--
            }
            if(direction =='next' && this.currentPage<this.totalpages-1)
            {
                this.currentPage++
            }
        },
        getFeedBack(id){
            // debugger // eslint-disable-line
            var self = this;
            self.rowId = id
            self.isFeedView = true
            // console.log('self.rowId',self.rowId)
            
        },
        OnClickUpdateList(event){
             var self = this;
             self.isFeedView = event;
        },
        //BACK TO DASHBOARD (Shraddha)
        backtodashboard(){
            var self = this;
            self.$root.$emit('openDashboard',0);
        }
    }
}
</script>