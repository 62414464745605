<template>
    
</template>
<script>
import Vue from 'vue'


export default({
    data() {
      return{

      }  
    },
    created(){
        debugger // eslint-disable-line
        console.log(Vue.prototype.$currentOs,"CURRENT OPERATING SYSTEM....")
        let getOs = Vue.prototype.$currentOs;

        
        let redirect_url = window.location.origin;
        // let application_url = 'dooey://app';
        
        if(getOs=='iOS'){
          redirect_url = 'https://apps.apple.com/us/app/dooey-messenger/id1196280355';
        }
        if(getOs=='Android'){
            redirect_url = 'https://play.google.com/store/apps/details?id=com.dooeyapp.dev&hl=en&gl=US';
        }
        setTimeout(function(){
            window.location.replace(redirect_url);
        },1000);
    }
})
</script>
