import firebase from "../views/firebaseInit";
const db = firebase.firestore();
import Vue from 'vue'
var user = localStorage.getItem("customerDocKey");

export const getMediaData = function(SOCIALMESSAGE,collectionName,MediaDataArray,chatId,cb)
{
    if(chatId){
        db.collection(SOCIALMESSAGE).doc(chatId).collection(collectionName).orderBy('createdAt','desc').onSnapshot(querySnapshot => {
            MediaDataArray = []
            querySnapshot.forEach(doc => {
                MediaDataArray.push(doc.data())
            })
            cb(MediaDataArray)
        })
    }
   
}


export const getCountFunction = function(MediaArray,AllMediaData,cb)
{
    let count = 0;
    AllMediaData = []
    const getrequestDataFunction = (row) => {
        if(count >= MediaArray.length){
            cb(AllMediaData)
            return;
        }
        
        if(row.type == 'pdf' || row.type == 'excel' || row.type == 'word'){
            let imageName = row.name.split('_');
            AllMediaData.push({
                'media' : row.mediaUrl,
                'type' : row.type,
                'imagename':row.name,
                'imageOnlyname':imageName[0],
                'name':row.from.name,
                'createdAt':row.createdAt ? (new Date(row.createdAt.seconds * 1000).getDate()+'/'+ [(new Date(row.createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(row.createdAt.seconds * 1000).getFullYear()) : '',
                'createDate':row.createdAt ? new Date(row.createdAt.seconds * 1000) : '',
                'lastWeekStartDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                'lastWeekEndDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                'lastMonthDate' : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30) 
            })
        }

        count += 1;
        getrequestDataFunction(MediaArray[count]);
    }
    // cb(AllMediaData)   
    getrequestDataFunction(MediaArray[count]); 
}
export const getMediaImages = function(MediaArray,mediaImages,cb)
{
    let count = 0;
    mediaImages = []
    const getrequestDataFunction = (row) => {
        if(count >= MediaArray.length){
            cb(mediaImages)
            return;
        }
        
        if(row.type == 'Image' || row.type == 'Video'){
            mediaImages.push({
                'media' : row.mediaUrl,
                'type' : row.type,
                'name':row.from.name,
                'imagename':row.name,
                'createdAt':row.createdAt ? new Date(row.createdAt.seconds * 1000) : '',
                'lastWeekStartDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                'lastWeekEndDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                'lastMonthDate' : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30)
            })
        }

        count += 1;
        getrequestDataFunction(MediaArray[count]);
    }
    // cb(mediaImages)   
    getrequestDataFunction(MediaArray[count]); 
}
export const getLinkData = function(MediaArray,cb){
    let count = 0;
    var mediaImages = []
    const getrequestDataFunction = (row) => {
        if(count >= MediaArray.length){
            cb(mediaImages)
            return;
        }
        
        if(row.type == 'Link'){
            mediaImages.push({
                'media' : row.msg,
                'type' : row.type,
                'name':row.from.name,
                'imagename':row.name,
                'createdAt':row.createdAt ? new Date(row.createdAt.seconds * 1000) : '',
                'lastWeekStartDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                'lastWeekEndDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                'lastMonthDate':new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
                'createDate':row.createdAt ? (new Date(row.createdAt.seconds * 1000).getDate()+'/'+ [(new Date(row.createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(row.createdAt.seconds * 1000).getFullYear()) : '',
            })
        }

        count += 1;
        getrequestDataFunction(MediaArray[count]);
    }
    // cb(mediaImages)   
    getrequestDataFunction(MediaArray[count]); 
}

export const getRecentData = function(SOCIALMESSAGE,collectionName,recentMediaData,chatId,cb)
{
    if(chatId){
        db.collection(SOCIALMESSAGE).doc(chatId).collection(collectionName).where('type','in',['Image','Video']).limit(5).onSnapshot(querySnapshot => {
            recentMediaData = []
            querySnapshot.forEach(doc => {
                recentMediaData.push(doc.data())
            })
            cb(recentMediaData)
        })
    }
}

export const getRecentThreeData = function(SOCIALMESSAGE,collectionName,recentThreeData,chatId,cb)
{
    if(chatId){
        db.collection(SOCIALMESSAGE).doc(chatId).collection(collectionName).where('type','in',['Image','Video']).onSnapshot(querySnapshot => {
            recentThreeData = []
            querySnapshot.forEach(doc => {
                recentThreeData.push(doc.data())
            })
            cb(recentThreeData)
        })
    }
}
export const getRecentThreeLink = function(SOCIALMESSAGE,collectionName,chatId,cb)
{
    if(chatId){
        db.collection(SOCIALMESSAGE).doc(chatId).collection(collectionName).where('type','==','Link').onSnapshot(querySnapshot => {
            var recentThreeData = []
            querySnapshot.forEach(doc => {
                recentThreeData.push({
                    'media' : doc.data().msg,
                    'type' : doc.data().type,
                    'name':doc.data().from.name,
                    'imagename':doc.data().name,
                    'createdAt':doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000) : '',
                    'lastWeekStartDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                    'lastWeekEndDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                    'lastMonthDate' : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
                    'createDate':doc.data().createdAt ? (new Date(doc.data().createdAt.seconds * 1000).getDate()+'/'+ [(new Date(doc.data().createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(doc.data().createdAt.seconds * 1000).getFullYear()) : '',
                })
            })
            cb(recentThreeData)
        })
    }
}

export const exitGroup = function(User,connectedUser,cb){
    //console.log('connectedUser',connectedUser,User);
    let count = 0;
    const getrequestDataFunction = (row) => {
        if(count >= connectedUser.length){
            return;
        }
        var invIndex = connectedUser.findIndex(item => {
            return item === User 
        })
        if(invIndex != -1){
            connectedUser.splice(invIndex,1)
        }
       
        count += 1;
        getrequestDataFunction(connectedUser[count]);
    }

    getrequestDataFunction(connectedUser[count]);
    cb(connectedUser)    
}

export const getgroupmember = function(USER,connectedUser,groupMember,cb){
    let count = 0;
    groupMember = []
    const getrequestDataFunction = (row) => {
        if(count >= connectedUser.length){
            cb(groupMember)
            return;
        }
        const key = row
        if(key){
            db.collection(USER).doc(key).get().then((snap) => {
                groupMember.push(snap.data());
            })
            //console.log('groupMembergroupMember',groupMember)
            count += 1;
            getrequestDataFunction(connectedUser[count]);
        } else {
            count += 1;
            getrequestDataFunction(connectedUser[count]);
        }
    }
    getrequestDataFunction(connectedUser[count]); 
   
}

export const getRecentThreedocument = function(SOCIALMESSAGE,collectionName,recentThreeData,chatId,cb){
    if(chatId){
        db.collection(SOCIALMESSAGE).doc(chatId).collection(collectionName).where('type','in',['pdf','excel','word']).onSnapshot(querySnapshot => {
            recentThreeData = []
            querySnapshot.forEach(doc => {
                let imageName = doc.data().name.split('_');
                recentThreeData.push({
                    'media' : doc.data().mediaUrl,
                    'type' : doc.data().type,
                    'imagename':doc.data().name,
                    'imageOnlyname':imageName[0],
                    'name':doc.data().from.name,
                    'createdAt':doc.data().createdAt ? (new Date(doc.data().createdAt.seconds * 1000).getDate()+'/'+ [(new Date(doc.data().createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(doc.data().createdAt.seconds * 1000).getFullYear()) : '',
                    'createDate':doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000) : '',
                    'lastWeekStartDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                    'lastWeekEndDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                    'lastMonthDate' : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30) 
                })
            })
            cb(recentThreeData)
        })
    }  
}

export const blockgetdata = function(USER,userid,cb){
    db.collection(USER).doc(userid).collection('socialBlockUser').onSnapshot(querySnapshot => {
        var blockUserId = []
        querySnapshot.forEach(doc=>{
            if(doc.exists){
                blockUserId.push(doc.data())
            }
        })
       cb(blockUserId) 
    })
}
export const blockList = function(blockUserId,USER,cb){
         let counting = 0;
         var blockUserList = []
         const getrequestDataFunction = (row) => {
              if(counting >= blockUserId.length){
                  return;
              }
              const key = row.id;
              db.collection(USER).doc(key).get().then(docdata => {
                  blockUserList.push(docdata.data())
              })
              
              counting += 1;
              getrequestDataFunction(blockUserId[counting]);
          }
       
        // setTimeout(()=>{
            cb(blockUserList)
        // },500)
        getrequestDataFunction(blockUserId[counting]); 
       
}
export const getAdminUserData = function(USER,cb){
    db.collection(USER).onSnapshot((querySnapshot)=>{
        if(!querySnapshot.empty){
            var usersDataArray=[];
            querySnapshot.forEach(doc=>{
                if(!doc.data().isAdmin && !doc.data().isDeleted && doc.data().email != Vue.prototype.$email){
                    usersDataArray.push(doc.data())
                }
            })
            cb(usersDataArray)
        }
    })
}
export const getAdminCompaniesData = function(COMPANY,cb){
    db.collection(COMPANY).orderBy('createdAt','asc').onSnapshot(querySnapshot=>{
        if(!querySnapshot.empty){
            var companiesArr=[];
            querySnapshot.forEach(doc=>{
                companiesArr.push(doc.data())
            })
            cb(companiesArr)
        }
    })
}
export const getAdminRevenueData = function(REVENUEMANAGEMENT,cb){
    db.collection(REVENUEMANAGEMENT).orderBy('purchaseDate').onSnapshot(querySnapshot=>{
        if(!querySnapshot.empty){
            var revenues=[]
            querySnapshot.forEach(doc=>{
                revenues.push(doc.data())
            })
            cb(revenues)
        }
    })
}

