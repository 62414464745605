<template>
<div>
  <Header v-if="isAdmin == false" :imageUrl= userData :chatObject= chatObject :activeGroup = activeGroup :activeChat= activeChat :groupData="groupData" />
    <section class="profile-wrapper general_setting" :class="{loaderBackground : showLoader}">
        <div class="container-fluid">
            <div class="row mb-4">
              <div class="col-md-12">
                    <div class="backbtn-wrapper">
                         <router-link to="/chat"><button class="btn btn-white"><img src="@/assets/images/tag-arrow.png" class="back-arrow"> Back To Chat</button></router-link>
                    </div>
              </div>
            </div>
              <!-- <div class="row">
                <div class="col-md-12">
                    <b-tabs content-class="mt-3" align="left" class="active-chat-tab" v-model="tabIndexs" >
                    <b-tab title="Social"  @click="SocialMenuActiveTab()" >
                        
                    </b-tab>
                    <b-tab title="Business"  @click="businessMenuActiveTab()" >

                    </b-tab>
                    </b-tabs>
                </div>
            </div> -->
            <div class="row">
              
                <div class="col-md-2">
                        <h4>Account Settings</h4>
                </div>
                <div class="col-md-10">
                  <div class="myprofile-form_wrapper"> 
                        <h4>{{activeTab}}</h4>
                  </div>
                </div>
            </div>
            <div class="col-md-12" style="position: relative;">
                <b-card no-body :class="{'businessgeneral' :businessMenuActive == true }">
                    <b-tabs v-model="tabIndex" pills card vertical>
                    <b-tab title="Read Receipts" @click.prevent="activeTab='Read Receipts'">
                        <div class="row">
                            <div class="col-md-5 read_receipts_col">
                                <div class="notifocation-wrapper">
                                    <h4>Read Receipts</h4>
                                    <div class="button-switch">
                                        <input type="checkbox" id="switch-blue" checked="checked" class="switch" @change="saveReadRecipt()" v-model="readReceiptValue">
                                    </div>
                                </div>
                                <div class="btn-wrapper read_receipte-btn">
                                    <!-- <button class="btn btn-white" @click="cancelReadRecipt()" >Cancel</button>
                                    <button class="btn btn-yellow" v-if="businessMenuActive == false" @click="saveReadRecipt()" v-bind:class="{disabled: isSpinner == true}">Save</button>
                                    <button class="btn btn-blue" v-else @click="saveReadRecipt()" v-bind:class="{disabled: isSpinner == true}">Save</button> -->

                                </div>
                            </div>
                            <div class="col-md-7"></div>
                        </div>
                        
                    </b-tab>
                    <b-tab title="Subscription Details" @click.prevent="activeTab ='Subscription Details'" class="upgrade_account upgrade_account_wrapper ">
                        <!-- <div style="position: fixed;top: 50%;left: 60%; z-index: 50;" v-if="showLoader">
                          <sync-loader :loading="true" color="#ffab3f"></sync-loader>
                        </div> -->
                         <div class="loader_div" v-if="showLoader" >
                            <!-- <sync-loader :loading="true" color="#77bef6"></sync-loader> -->
                            <img src="@/assets/images/Orange_GIF.gif" class="lodar_gif">
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="social_account_box_wrapper">
                                    <h4>Social Account</h4>
                                    <div class ="currentPlanDetail" v-if="socialPlanObject.payment_status.toLowerCase() !== 'paid'">
                                        <p>Current Plan</p>
                                        <button type="Submit" @click="upgradePlan()" class="btn btn-yellow">Upgrade Plan</button>
                                    </div>
                                    <div class="planList" >
                                        <p class="plan_name"> {{socialPlanObject.plan_name}}</p>
                                        <div v-if="socialPlanObject.payment_status == 'paid' && socialPlanObject.plan_status != 'deactivated'">    
                                        <p  class="plan_price">{{decodeHtmlCharCodes(socialPlanObject.price)}}/{{socialPlanObject.plan_duration}}</p>
                                    </div>
                                    </div>
                                    <div class="nextbillingcycle" v-if="socialPlanObject.payment_status == 'paid' && socialPlanObject.plan_status != 'deactivated'">
                                    <p> Next Billing On : {{socialPlanObject.next_billing_date}}</p>
                                    <button type="Submit" class="btn btn-deactive" @click="deactivatePlan(socialPlanObject)">Deactivate Plan</button>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" style="text-align:center" >
                                <div class="upgrad-button_wrapper" style="padding: 25px 0px; margin-bottom: 30px;">
                                    <div class="company_list_title">
                                        <h5>Company List</h5>
                                    </div>
                                    <div v-if="userData.companies && userData.companies.length" class="company_name_list_wrapper">
                                        <ul class="company_name_list">
                                            <li style="" v-for="(company, index) in userData.companies" :key="index">{{company.companyName}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="upgrad-button_wrapper">
                                    <button type="submit" v-if="!userData.companies" v-b-modal.modal-company class="btn btn-blue">Upgrade To Business</button>
                                    <button type="submit" v-else v-b-modal.modal-company class="btn btn-blue">Create A New Company</button>
                                </div>
                            </div>
                            <b-modal id="modal-company" class="modal-contact"  hide-footer centered @close="closeFire($event)" :no-close-on-backdrop="true">
                                <div class="contact-modal">
                                    <!-- <div v-if="showLoader" style="position: sticky;top: 50%;">
                                        <sync-loader :loading="true" color="#ffab3f" style="text-align: center"></sync-loader>
                                    </div> -->
                                     <div class="loader_div" v-if="showLoader"  >
                                        <!-- <sync-loader :loading="true" color="#77bef6"></sync-loader> -->
                                        <img src="@/assets/images/Blue_GIF.gif" class="lodar_gif">
                                    </div>
                                    <div class="contact-modal">
                                        <h4>Create Business Account</h4>
                                        <ValidationObserver ref="log" v-slot="{ handleSubmit }">
                                            <form @submit.prevent="handleSubmit(createCompany)">
                                                <ValidationProvider rules="required" name="company name" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label>COMPANY NAME*</label>
                                                    <input class="form-control" placeholder="Enter company name" maxlength="51"  @blur="companyname = companyname.trim()" @keyup="isCompanyNameUniq(),checkChrecterLengthCompanyName(companyname)"  name="company name" v-model="companyname" autocomplete="off">
                                                    <span class="InvalidInput">{{ errors[0] }}</span>
                                                    <span v-if="errorMessage!= ''" style="color:red;">{{errorMessage}}</span>
                                                    <span v-if="charecterErrorMessageForCompanyName != '' && errors.length == 0" class="InvalidInput">{{charecterErrorMessageForCompanyName}}</span>

                                                </div>
                                                </ValidationProvider>
                                                <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
                                                <div class="form-group mt-3">
                                                    <label>EMAIL*</label>
                                                    <input class="form-control" placeholder="Enter email "  name="email" v-model="companyemail" autocomplete="off" disabled>
                                                    <span class="InvalidInput">{{ errors[0] }}</span>
                                                </div>
                                                </ValidationProvider>
                                                <ValidationProvider rules="required" name="company address" v-slot="{ errors }">
                                                <div class="form-group mt-3">
                                                    <label>COMPANY ADDRESS*</label>
                                                    <input class="form-control" placeholder="Enter company address " maxlength="101"  name="company address" v-model="companyaddress" autocomplete="off" @keyup="checkChrecterLengthCompanyAddress(companyaddress)">
                                                    <span class="InvalidInput">{{ errors[0] }}</span>
                                                    <span v-if="charecterErrorMessageForCompanyAddress != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForCompanyAddress}}</span>

                                                </div>
                                                </ValidationProvider>
                                                <div class="btn-wrapper">
                                                    <button type="submit" class="btn btn-blue" v-bind:class="{disabled: isSpinner == true}"  >NEXT</button>
                                                </div>
                                            </form>
                                        </ValidationObserver>
                                    </div>
                                </div>
                            </b-modal>
                        </div>
                    </b-tab>
                    
                    <b-tab title="Blocked Contacts" @click.prevent="activeTab ='Blocked Contacts'">
                        <div class="blocked_content">
                            <div class="row">
                                <div class="col-md-10">
                                    <ul class="member-list-sidebar">
                                        <li v-for="(blockuser,index) in blockUserList" v-bind:key="index">
                                            <div style="display: flex;align-items: center;">
                                                <img :src="blockuser.profileUrl"><span class="ml-1">{{blockuser.firstName}} {{blockuser.lastName}}</span>
                                            </div>
                                            <button class="btn btn-yellow" v-if="businessMenuActive == false" type="button" @click="unblockUser(blockuser,index)">unblock</button>
                                            <button class="btn btn-blue" v-else type="button" @click="unblockUser(blockuser,index)">Unblock</button>
                                        </li>
                                        <span v-if="blockUserList.length==0 ">No blocked contacts</span>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    </b-tabs>
                </b-card>
                <div class="ifnotAdminDataUsage" style="position: absolute; top: 230px;">
                    <div>
                        <div class="d-flex dataUsage">
                            <span>{{bytes(socialPlanObject.dataUsage)}} /</span>
                            <span>{{bytes(socialPlanObject.cloud_storage)}}</span>
                        </div>
                    </div> 
                    <b-progress :value="niceBytes(socialPlanObject.dataUsage)" :max="niceBytes(socialPlanObject.cloud_storage)" class="mb-1"></b-progress>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>

import Header from "@/components/header.vue";
import firebase from "./firebaseInit";
import firebaseData from '../utils/firebaseCollection.js'
import firebaseTimestamp from "firebase";
import fb from 'firebase';
import {blockgetdata,blockList} from '../utils/getSidebarMedia.js';
import {getLoginUserData,getSocialPlanData} from "@/utils/getQueeryActiveChatList.js";
import Swal from 'sweetalert2'
import intlTelInput from 'intl-tel-input';
import Vue from 'vue';
import moment from 'moment';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'


const db = firebase.firestore()

export default {
    name: "headerpage",
    components:
    {
        Header,
        firebaseData,
        SyncLoader
    },
    data() {
        return {
            activeTab:'',
            selectedActiveMenu: "",
            showLoader:false,
            selected: 'USD',
            options: ['USD' ,'EUR' ,'GBP'],
            quantity: 1,
            tabIndex:'',
            tabIndexs:'',
            packages:[],
            readReceiptValue:true,
            userid:'',
            isSpinner:false,
            readValue:'',
            isAdmin:'',
            loginUser:'',
            chatObject:{
                firstName:'',
                lastName:'',
                profileUrl:'',
                userid:''
            },
            groupData:{},
            activeGroup:false,
            userData:{},
            activeChat:false,
            showSocialBuySucess:false,
            showBusinessBuySucess:false,
            currentSocialPlan:'',
            currentPassword:'',
            newPassword:'',
            confirmNewPassword:'',
            isSpinner:false,
            blockUserId:[],
            blockUserList:[],
            message:'',
            firstname:'',
            lastname:'',
            email:'',
            phone:'',
            iscustomerSupportSpinner:false,
            businessMenuActive :false,
            chatid:'',
            businessPlan:[],
            blockUserMessage : '',
            companyname:'',
            companyaddress:'',
            companyemail:'',
            companyName:'',
            CompanyEmail:'',
            CompanyAddress:'',
            companyDocKey:'',
            plans:[],
            socialPlanObject:{},
            isactivetab:this.$route.params.activetab,
            errorMessage:'',
            createCompanyName:[],
            charecterErrorMessageForCompanyName:'',
            charecterErrorMessageForCompanyAddress:''
        }
    },
    created(){
        // debugger // eslint-disable-line
         var self = this;
         
         self.userid = localStorage.getItem('customerDocKey');
        if(self.isactivetab == 'Subscription_Details'){
            self.activeTab = 'Subscription Details'
            self.tabIndex = 1
            self.tabIndexs = 1
        }else{
            self.activeTab =  'Read Receipts'
            self.tabIndex = 0
        }

        self.init();
        self.$root.$on('setBusinessMenu',(value) => {
             self.businessMenuActive = value
        })
        self.$root.$on("activeBusinessTab",(value)=>{
          self.tabIndexs = 1
        })
    },
    watch : {
        $route : function (){
          this.init();
        }
    },
    methods: {
        // GET DATA(Shraddha)
        init(){
        //debugger // eslint-disable-line
         var self = this;
        var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

          // FOR GET SOCIAL PLAN DETAIL(Shraddha)
        getSocialPlanData(firebaseData.firebasecollection.USER,self.userid,res => {
            self.socialPlanObject = res;
            var time = self.socialPlanObject.next_billing_date
            var dateString = moment(time).format('DD-MM-YYYY');
            var finaldate = dateString.split('-')[0]
            var finalmonth = parseInt(dateString.split('-')[1]) - 1
            var finalyear = dateString.split('-')[2]
            var monthname = months[finalmonth]
            var displaydate = finaldate+' '+monthname+' '+finalyear
            self.socialPlanObject.next_billing_date = displaydate
            // console.log('self.socialPlanObject',self.socialPlanObject)
        })  
        getLoginUserData(firebaseData.firebasecollection.USER,(res) => {
            self.createCompanyName = []
            self.readReceiptValue = res.SocialreadReceipts
            self.readValue = res.SocialreadReceipts
            self.imageUrl = res.profileUrl;
            self.userData = res;
            self.companyemail = res.email
            var companies = res.companies
            if(companies){
                companies.forEach(data => {
                    if(data.isAdmin == true){
                        self.createCompanyName.push(data.companyName);
                    }
                })
            }
            // console.log('self.userData',self.userData,self.imageUrl)
        })
        blockgetdata(firebaseData.firebasecollection.USER,self.userid,res => {
            self.blockUserId = res
             //console.log('blockUserId',self.blockUserId)
            blockList(self.blockUserId,firebaseData.firebasecollection.USER,res=>{
               self.blockUserList = res 
               for (let i = 0; i < self.blockUserList.length; i++) {
                    for (let j = i+1; j < self.blockUserList.length; j++) {
                        if(self.blockUserList[i].id==self.blockUserList[j].id)
                        {
                            self.blockUserList.splice(i,1)
                        }
                    }
                }
            //    console.log('blockUserList',self.blockUserList)
            })
        })
        // CHECK LOGIN USER IS ADMIN OR CUSTOMER(Shraddha)
            self.loginUser = localStorage.getItem("IsLoginUser")
            if(this.loginUser == 'Admin'){
                self.isAdmin = true
            }else{
                self.isAdmin = false
            }        
        },
        decodeHtmlCharCodes(str) { 
            return str.replace(/(&#(\d+);)/g, function(match, capture, charCode) {
                return String.fromCharCode(charCode);
            });
        },

        // CONVERT BYTES INTO GB (Shraddha)
        niceBytes(x){
            let gbValue = (x / (1024 * 1024 * 1024)).toFixed(2);
            return (gbValue + 'GB')
           
        },
        bytes(x){
            const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            let l = 0, n = parseInt(x, 10) || 0;
            while(n >= 1024 && ++l){
                n = n/1024;
            }
            return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        }, 
        // CHECK CHARECTER LIMIT IN COMPANY (Shraddha)
        checkChrecterLengthCompanyName(groupName){
            // debugger // eslint-disable-lines
            var self = this;
            if(groupName.length < 51){
            self.companyname = groupName
            self.charecterErrorMessageForCompanyName = '';
            }else{
            self.charecterErrorMessageForCompanyName = 'The company name may not be greater than 50 characters';
            self.companyname = (groupName).slice(0, 50)
            }
        },
        checkChrecterLengthCompanyAddress(groupName){
            // debugger // eslint-disable-lines
            var self = this;
            if(groupName.length < 101){
            self.companyaddress = groupName
            self.charecterErrorMessageForCompanyAddress = '';
            }else{
            self.charecterErrorMessageForCompanyAddress = 'The company address may not be greater than 100 characters';
            self.companyaddress = groupName.slice(0,100)
            }
        },      
        // READ RECIPT VALUE CHANGED(Shraddha)
        saveReadRecipt(){
            // //debugger // eslint-disable-line
            var self = this;
            self.isSpinner == true
            db.collection(firebaseData.firebasecollection.USER).doc(self.userid).update({
                SocialreadReceipts : self.readReceiptValue
            }).then(() => {
                if(self.readReceiptValue == true){
                    self.$root.$emit("audioplay");
                    self.$notify({
                        title:"Success",
                        text:'Read Receipts turned on.',
                        type:"success"
                    })
                    self.isSpinner = false
                }else{
                    self.$root.$emit("audioplay");
                    self.$notify({
                        title:"Success",
                        text:'Read Receipts turned off.',
                        type:"success"
                    })
                    self.isSpinner = false 
                }
            })
        },
        //CANCEL READRECIPT ( Shraddha)
        cancelReadRecipt(){
            var self=this;
            self.readReceiptValue = self.readValue
        },
        unblockUser(blockuser,index,){
        // debugger // eslint-disable-line
        var self=this
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
        });
        Swal.fire({
            title: 'are you sure you want to unblock'+' '+blockuser.firstName+' '+blockuser.lastName+ '?',
            type: "warning",
            customClass:{
                container :"social_confirmation_popup"
            },
            showCancelButton: true,
            confirmButtonColor: "#ffab3f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, unblock it!",
        }).then((result) => {
            if (result.value) {
                self.blockUserList.splice(index,1);
                self.blockUserId.splice(index,1);
                db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialContact').doc(blockuser.id).get().then(doc => {
                    //console.log('doc',doc)
                    if(doc.data().chatId != undefined){
                        self.chatid = doc.data().chatId
                    }else{
                    self.chatid = '' 
                    }
                }).then(() => {
                  var blockArray = []
                    if(self.chatid != ''){
                        db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialContact').doc(blockuser.id).set({
                            id: blockuser.id,
                            chatId : self.chatid
                        }).then(()=>{
                            db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialBlockUser').doc(blockuser.id).delete().then(()=>{
                                self.$root.$emit("audioplay");
                                self.$notify({
                                    title:"Success",
                                    text:blockuser.firstName+' '+blockuser.lastName+' unblocked.',
                                    type:"success"
                                })
                                self.getSetContactList();
                                self.$root.$emit("activeGroup", "yes");
                            })
                        })
                        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.chatid).get().then(doc => {
                            blockArray = doc.data().blockUserArray
                            var index = blockArray.findIndex(product => {
                                return product.blockBy == firebase.auth().currentUser.uid && product.blockTo ==  blockuser.id
                            })
                            blockArray.splice(index,1);
                        }).then(() => {
                          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.chatid).update({
                            blockUserArray : blockArray,
                           

                          })
                        })
                    }else{
                        db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialContact').doc(blockuser.id).set({
                            id: blockuser.id,
                        }).then(()=>{
                            db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialBlockUser').doc(blockuser.id).delete().then(()=>{
                                self.$root.$emit("audioplay");
                                self.$notify({
                                    title:"Success",
                                    text:blockuser.firstName+' '+blockuser.lastName+' unblocked.',
                                    type:"success"
                                }) 
                            })
                        }) 
                    }
                })
            }
        })
    },
        // ONLY ENTER NUMBER(PHONE NUMBER) (Shraddha)
        isCharAt(evt)
        {
        // //debugger // eslint-disable-line
        const char = String.fromCharCode(evt.keyCode);
            if (!/[0-9 ||.]/.test(char)) {
                evt.preventDefault();
            }
        },
         //DEACTIVATE PLAN (Shraddha)
        deactivatePlan(businessplan){
        //    debugger // eslint-disable-line
           var self=this;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Swal.fire({
                title: "Are you sure you want to deactivate"+' '+businessplan.plan_name+' '+"plan?",
                type: "warning",
                customClass:{
                    container :"social_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: "#ffab3f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            }).then((result) => {
                if (result.value) {
                    self.$axios.post(self.$WPbaseurl+'wp-json/plan/cancel',{
                        wp_user_id:self.userData.wp_id,
                        user_id:self.userData.id,
                        company_id:'',
                        plan_id:businessplan.plan_id,
                        order_id:businessplan.order_id,
                        freeplan_orderid : self.userData.socialfreeplan_orderid,
                        plan_type:'social'
                    })
                    .then((res)=>{
                        // console.log(res)
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"Success",
                            text: businessplan.plan_name+' deactivated.',
                            type:"success",
                            duration: 10000
                        })
                    })
                }
            }) 
        },
        // CLOSE COMPANY POPUP(Shraddha)
        closeFire(e){
            var self = this;
            if(!self.showLoader)
            {
                self.companyname = '',
                self.companyemail = '',
                self.companyaddress = '',
                self.$refs.log.reset()
            }
            else
            {
                e.preventDefault()
            }
        },
        //SAVE COMPANY DATA(Shraddha)
        createCompany(){
            // debugger // eslint-disable-line
            var self=this
            if(self.charecterErrorMessageForCompanyName == '' && self.charecterErrorMessageForCompanyAddress == ''){
                self.companyname = self.companyname.toLowerCase()
                if(self.errorMessage == ''){
                self.showLoader=true
                localStorage.setItem('CompanyDetail','');
                self.isSpinner = true
                // console.log(self.companyname,self.companyemail,self.companyaddress)
                self.companyName = self.companyname,
                self.CompanyEmail = self.companyemail,
                self.CompanyAddress = self.companyaddress

            
                // self.buyBusinessPackage();
                var companydetail = {
                    'companyname':self.companyname,
                    'CompanyEmail':self.CompanyEmail,
                    'CompanyAddress':self.CompanyAddress,
                    'isAdmin':true,
                    
                }
                localStorage.setItem('CompanyDetail',JSON.stringify(companydetail))
                // self.$root.$emit('CompanyDetail',self.companyName,self.CompanyEmail,self.CompanyAddress)
                self.$axios.post(self.$WPbaseurl+'wp-json/business/business-plan',{company_id:'',company_name:self.companyname,company_email:self.CompanyEmail,company_address:self.CompanyAddress,wp_user_id:self.userData.wp_id,user_id:self.userData.id})
                .then((res)=>{
                    if(Vue.prototype.$adminDetails.userUpgradeNotification && self.userData.companiesOwned == undefined || Vue.prototype.$adminDetails.userUpgradeNotification && self.userData.companiesOwned.length == 0){
                        self.$axios.post(self.$apiurl+'sendMailToAdmin',{adminId:Vue.prototype.$adminDetails.id,Message:`${self.userData.firstName} ${self.userData.lastName} has upgraded to Business Account.`,operation:'upgrade'})
                        .then(()=>{
                        res.send({saved:true})
                        })
                    }
                    // console.log(res)
            
                // self.$router.push({ name: "businessgeneralsettings", params: { activeTab: 'Subscription Details'} })
                // self.$router.push('/businessgeneralsettings');
                self.$bvModal.hide('modal-company');
                self.companyname = '',
                self.companyemail = '',
                self.companyaddress = ''
                window.location.href = self.$WPbaseurl+`business-plan/?uid=${self.userData.wp_id}&clear-cart`;
                self.$refs.log.reset()
                self.isSpinner = false
                self.showLoader=false
                })
                }
            }
            
        },
        // UPGRADE PLAN AND REDIRECT IN WORDPRESS(Shraddha)
        upgradePlan(){
            // debugger // eslint-disable-line
            var self=this
            self.showLoader=true
            window.location.href = self.$WPbaseurl+`social-plan/?uid=${self.userData.wp_id}&is_cart_empty=true`;
            self.showLoader=false
        },
        SocialMenuActiveTab(){
        // //debugger // eslint-disable-line
            var self=this;
            self.activeChat = false
            self.businessMenuActive = false
            self.$root.$emit("socialMenuActive", self.businessMenuActive,self.activeChat)
            self.selectedActiveMenu = 'activelist'
            self.$router.push('/chat')
           
           
        },
        // FOR ACTIVEBUSINESS MENU
        businessMenuActiveTab(){
        //debugger // eslint-disable-line
            var self=this;
            // console.log(self.userData)
                // self.businessMenuActive = true;
                self.activeChat = false
                self.$root.$emit("businessMenuActive", self.businessMenuActive,self.activeChat)
                if(self.userData.companies != undefined && self.userData.companies.length > 0){
                    self.selectedActiveMenu = 'admin'
                    self.$router.push('/businesschat')
                }else{
                    self.tabIndex = 1
                }
            // if(self.userData.companies != undefined){
            //     self.$root.$emit("getActiveComapnyList")
            //     self.$root.$emit("getCompanyAdmin")
            //     self.$router.push('/businessgeneralsettings')
            // }else{
            //     self.tabIndex = 1
            // }
        },
        //COMOPANY NAME UNIQUE(Shraddha)
        isCompanyNameUniq(){
        //    debugger // eslint-disable-line
           var self=this;
            // console.log('companyName',self.createCompanyName)
            let isInArray = self.createCompanyName.some(function(item) {
                return item == self.companyname.toLowerCase()
            });
            if(isInArray == true && self.companyname != ''){
                self.errorMessage = 'Company name is already exist'         
           }else{
                self.errorMessage = ''
            }
        } ,
    },
   
};
</script>

