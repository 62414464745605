<template>
    <div class="user_management_detail_wrapper packeg_management_wrapper">
        <div>
            <div class="admin_Page_title">
                <h4>Package Management</h4> 
                <img class="title_icon" src="@/assets/images/home_icon.png" alt="homeIcon">
                <span class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
                <span class="current_page" @click="BacktoPackageList()">Package Management</span>
                <span v-if="isPackageDivShow == true " class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
                <span v-if="isPackageDivShow == true && isEditForm == false "  class="current_page">Add New Package</span>
                <span v-if="isPackageDivShow == true && isEditForm == true " class="current_page">Update Package</span>

            </div>
            <div class="packeg_management_list_wrapper" v-if="isPackageDivShow == false">
                <div class="admin_Page_title">
                     <h4>Package Management</h4> 
                    <div v-if="isPackageDivShow == false">
                        <button class="btn btn-yellow" @click="AddNewPackage()">ADD NEW PACKAGE</button>
                    </div>
                </div>
            <div class="accordion" role="tablist" v-if="isPackageDivShow == false">
                <b-card no-body class="mb-1" v-for="(packagelist,index) in packageListing" v-bind:key="index">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <div class="header-data_area">
                        <b-card-text><span>Name :</span>  {{packagelist.PackageName}}</b-card-text>
                        <b-card-text><span>Price :</span> {{packagelist.PackageType=='Paid'?packagelist.Price:'FREE'}}/<span>{{packagelist.PriceType }}</span></b-card-text>
                        <b-card-text><span>Device :</span> 
                            <span v-for="(devicelist,index) in packagelist.Device" v-bind:key="index">
                                {{index != packagelist.Device.length-1 ? devicelist+',' : devicelist+'.'}}
                            </span>
                        </b-card-text>
                        </div>
                        <div class="header_button">
                        <b-button @click="EditPackage(packagelist.Id)" class="btn-withou-bg"><img src="@/assets/images/edit_grey.png"></b-button>
                        <b-button @click="DeletePackage(packagelist.Id,index)" class="btn-withou-bg"> <img src="@/assets/images/delete_grey.png"></b-button>
                        <b-button block v-b-toggle="'collapse' + index" class="btn-withou-bg"><img src="@/assets/images/dropdown-arrow.png"></b-button>
                        </div>
                    </b-card-header>
                    <b-collapse :id ="'collapse' + index" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <!-- <b-button @click="EditPackage(packagelist.Id)">Edit</b-button>
                            <b-button @click="DeletePackage(packagelist.Id,index)">Delete</b-button>
                            <b-button block v-b-toggle="'collapse' + index">upArrow</b-button> -->
                            <div class="body-accordion">
                            <!-- <b-card-text><span>Name</span> : {{packagelist.PackageName}}</b-card-text>
                            <b-card-text><span>Price</span> : {{packagelist.PackageType=='Paid'?packagelist.Price:'FREE'}}/<span>{{packagelist.PriceType }}</span></b-card-text>
                            <b-card-text><span>Device</span> :
                                <span v-for="devicelist in packagelist.Device" v-bind:key="devicelist">
                                    {{devicelist}},
                                </span>
                            </b-card-text> -->
                            <b-card-text><span>Msg Branch :</span>  {{packagelist.MsgBranch}}</b-card-text>
                            <b-card-text><span>FullShare :</span>  {{packagelist.FullShare}}</b-card-text>
                            <b-card-text>
                                <span>File type :</span>
                                <span v-for="(fileType,index) in packagelist.FileType" v-bind:key="index">
                                    {{index != packagelist.FileType.length-1 ? fileType+',' : fileType+'.'}}
                                </span>
                            </b-card-text>
                            <b-card-text><span>Admin Rights :</span> {{packagelist.AdminRights}}</b-card-text>
                            <b-card-text><span>Channel :</span> {{packagelist.Channel=='Yes'? packagelist.UnlimitedChannel?'Unlimited':packagelist.NoOfChannel:0}}</b-card-text>
                            <b-card-text><span>Messages History :</span> {{packagelist.MessagesHistory}}</b-card-text>
                            <b-card-text><span>Cloud Storage :</span> {{packagelist.CloudStorage}} {{packagelist.CloudStorageSize}} {{packagelist.CloudStorageDescription}}</b-card-text>
                            <b-card-text><span>Max File Size :</span> {{packagelist.MaxFileSize}} {{packagelist.FileSize}}</b-card-text>
                            <b-card-text><span>Description :</span> {{packagelist.Description}}</b-card-text>
                            </div>
                            <div class="blank_space_button"></div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
            </div>
        </div>

        <div class="container" v-if="isPackageDivShow == true">
            <ValidationObserver ref="log" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(savePackage),showError=true,showError2=true,showError3=true,showError4=true" v-if="isEditForm == false">
                <div class="admin_Page_title">
                    <h4>Add New Package</h4>
                </div> 
                <div class="row">
                    <div class="col-md-4">
                         <ValidationProvider rules="required" name="plan type" v-slot="{ errors }">
                        <div class="form-group">
                            <label>PLAN TYPE*</label>
                            <select class="form-control arrow_side" name="plan type" v-model="plantype">
                                <option value="" hidden disabled>Select plan type</option>
                                <option v-for="option in planType" v-bind:key="option.value">
                                    {{ option.value }}
                                </option>
                            </select>
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                        <ValidationProvider rules="required" name="package_name" v-slot="{ errors }">
                        <div class="form-group">
                            <label>NAME*</label>
                            <input type="text" class="form-control" placeholder="Enter name" v-model="packagename" autocomplete="off" />
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                         </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                         <ValidationProvider rules="required" name="package_type" v-slot="{ errors }">
                        <div class="form-group">
                            <label>PACKAGE TYPE*</label>
                            <select class="form-control arrow_side" name="package type" v-model="packagetype">
                                <option value="" hidden disabled>Select package type</option>
                                <option v-for="option in packageType" v-bind:key="option.value">
                                    {{ option.value }}
                                </option>
                            </select>
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                    </div>
                     <div class="col-md-4" v-if="packagetype == 'Paid'">
                         <div class="price-wrapper d-flex">
                        <ValidationProvider rules="required" name="price" v-slot="{ errors }">
                        <div class="form-group">
                            <label>PRICE*</label>
                            <input type="text" class="form-control" placeholder="Enter price"  v-model="price" autocomplete="off" @keypress="isCharAt($event)" />
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        
                        </ValidationProvider>
                        <div class="radio-wrapper">
                            <div class="radio">
                                <input type="radio"  id="Monthly" name="pricetype" value="Monthly" v-model="pricetype">
                                <label for="Monthly" class="radio-label">Monthly</label>
                            </div>
                            <div class="radio">
                                <input type="radio"  id="Yearly" name="pricetype" value="Yearly" v-model="pricetype">
                                <label for="Yearly" class="radio-label">Yearly</label>
                            </div>
                        </div>
                         </div>
                    </div>
                     <div class="col-md-4">
                        <!-- <ValidationProvider rules="required" name="file type" v-slot="{ errors }"> -->
                        <div class="form-group">
                            <label>FILE TYPE*</label>
                            <span @click="showOptions=!showOptions">
                                <input class="form-control arrow_side" type="text" disabled name="file type" :placeholder="filetype">
                            </span>
                            <div class="custom-select" v-show="showOptions">
                                <span class="custom-selectCheck">
                                    <input type="checkbox" id="All" name="All" @change="selectAllFileType($event)"> All
                                </span>
                                <span v-for="(dt,ind) in FileType" :key="ind" class="custom-selectCheck">
                                    <input type="checkbox" :name="dt.value" v-model="filetype" :value="dt.value" @change="addFileType()"> {{dt.value}}
                                </span>
                            </div>
                            <span class="InvalidInput" v-if="checkFileTypeError && showError4">The no. of channel field is required.</span>
                            <!-- <select class="form-control arrow_side" name="file type" v-model="filetype" >
                               <option value="" hidden disabled>Select file type</option>
                               <option v-for="option in FileType" v-bind:key="option.value">
                                    {{ option.value }}
                                </option>
                            </select>
                            <span class="InvalidInput">{{ errors[0] }}</span> -->
                        </div>
                        <!-- </ValidationProvider> -->
                    </div>
                    <div class="col-md-4">
                        <ValidationProvider rules="required" name="device" v-slot="{ errors }">
                        <div class="form-group d-flex checkbox_device_wrapper">
                            <label style="width:100%;">DEVICE*</label>
                            <div>
                               <label class="checkbox-c opacity-zero">All <input type="checkbox" name= "all" id= "all" @change="selectAll($event)"><span class="checkmark"></span></label> 
                            </div>
                            <div style="" v-for="device in devices" v-bind:key="device.name">

                               <label class="checkbox-c opacity-zero"> {{device.name}} <input type="checkbox" :name = device.name  v-model="deviceIds" :value = device.name @change="selectOther($event)"><span class="checkmark"></span></label>
                               
                            </div>
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        
                        </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label style="width:100%;">MSG BRANCH*</label>
                            <div class="radio">
                            <input type="radio"  id="Yes" name="msgbranch" value="Yes" v-model="msgbranch">
                                <label for="Yes" class="radio-label">Yes</label>
                            </div>
                            <div class="radio">
                            <input type="radio"  id="No" name="msgbranch" value="No" v-model="msgbranch">
                                <label for="No" class="radio-label">No</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label style="width:100%;">FULLSHARE*</label>
                            <div class="radio">
                                <input type="radio"  id="Yes1" name="fullshare" value="Yes" v-model="fullshare">
                                <label for="Yes1" class="radio-label">Yes</label>
                            </div>
                            <div class="radio">
                                <input type="radio"  id="No1" name="fullshare" value="No" v-model="fullshare">
                                <label for="No1" class="radio-label">No</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <ValidationProvider rules="required" name="channel" v-slot="{ errors }">
                        <div class="form-group">
                            <label>CHANNEL*</label>
                            <select class="form-control arrow_side" name="channel" v-model="channel" >
                                <option value="" hidden disabled>Select</option>
                               <option v-for="option in channelOptions" v-bind:key="option.value">
                                    {{ option.value }}
                                </option>
                            </select>
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-4" v-if="channel=='Yes'">
                        <!-- <ValidationProvider rules="required" name="no. of channel" v-slot="{ errors }"> -->
                            <div class="message_history d-flex">
                            <div class="form-group">
                            <label>NO. OF CHANNEL</label>
                            <input type="text" class="form-control" :disabled="unlimitedChannel" name="no. of channel" maxlength="5" @keypress="isCharAt($event)" placeholder="Enter no. of channel" v-model="noOfChannel" autocomplete="off" />
                            <span class="InvalidInput" v-if="checkChannel && showError">The no. of channel field is required.</span>
                             </div>
                            <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                            <label class="checkbox-c opacity-zero">Unlimited
                            <input type="checkbox" id="Unlimited" name="msgbranch" value="Unlimited" v-model="unlimitedChannel">
                            <span data-v-695af586="" class="checkmark"></span>
                        <!--         <label for="Unlimited">Unlimited</label> -->
                            </label>
                       </div>
                        
                        <!-- </ValidationProvider> -->
                    </div>
                    <div class="col-md-4" v-if="plantype=='Business' && channel=='Yes'">
                        <!-- <ValidationProvider rules="required" name="channel number limit" v-slot="{ errors }"> -->
                            <div class="message_history d-flex">
                        <div class="form-group">
                            <label>CHANNEL MEMBER LIMIT</label>
                            <input type="text" class="form-control" :disabled="unlimitedMember" name="channel number limit" @keypress="isCharAt($event)" maxlength="4" placeholder="Enter channel memeber limit" v-model.number="channelMemberLimit" autocomplete="off" />
                            <span class="InvalidInput" v-if="checkChannelMemberLimit && showError2">The channel member limit field is required.</span>
                             </div>
                            <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                            <label class="checkbox-c opacity-zero">Unlimited
                            <input type="checkbox"  id="Unlimited2" name="msgbranch" value="Unlimited" v-model="unlimitedMember">
                        <!--         <label for="Unlimited2">Unlimited</label> -->
                        <span data-v-695af586="" class="checkmark"></span>
                            </label>
                       
                        </div>
                        
                        <!-- </ValidationProvider> -->
                    </div>
                     <div class="col-md-4">
                        <ValidationProvider rules="required|numeric|min_value:1|max:5" name="file_size" v-slot="{ errors }">
                        <div class="form-group">
                            <label>MAX FILE SIZE*</label>
                            <div class="d-flex file_size">
                                <input type="text" class="form-control" placeholder="Enter max file size"  v-model="filesize" autocomplete="off" @keypress="isCharAt($event)" />
                                <select class="form-control arrow_side" name="channel" v-model="fileSizeValue" >
                                    <option value="" hidden disabled>Size</option>
                                    <option v-for="option in sizeOptions" v-bind:key="option.value">
                                        {{ option.value }}
                                    </option>
                                 </select>
                            </div>
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                        
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label style="width:100%;">ADMIN RIGHTS*</label>
                            <div class="radio">
                                <input type="radio"  id="Yes2" name="adminrights" value="Yes" v-model="adminrights">
                                <label for="Yes2" class="radio-label">Yes</label>
                            </div>
                            <div class="radio">
                                <input type="radio"  id="No2" name="adminrights" value="No" v-model="adminrights">
                                <label for="No2" class="radio-label">No</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <ValidationProvider rules="required" name="cloud_storage" v-slot="{ errors }">
                        <div class="form-group">
                            <label>CLOUD STORAGE*</label>
                            <div class="d-flex file_size">
                            <input type="text" class="form-control" placeholder="Enter cloud storage"  v-model="cloudstorage" autocomplete="off" @keypress="isCharAt($event)" />
                            <select class="form-control arrow_side" name="channel" v-model="cloudStorageSize" >
                                <option value="" hidden disabled>Size</option>
                                <option v-for="option in sizeOptions" v-bind:key="option.value">
                                    {{ option.value }}
                                </option>
                            </select>
                            </div>
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                         </ValidationProvider>
                         
                    </div>
                    <div class="col-md-6">
                        <!-- <ValidationProvider rules="required" name="cloud storage description" v-slot="{ errors }"> -->
                        <div class="form-group">
                            <label>CLOUD STORAGE DESCRIPTION</label>
                            <input type="text" class="form-control" name="cloud storage description" placeholder="Enter description" v-model="cloudStorageDescription" autocomplete="off" />
                            <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                        </div>
                        <!-- </ValidationProvider> -->
                    </div>
                    <div class="col-md-4">
                        <!-- <ValidationProvider rules="numeric|min_value:1|max:5" name="messages history" v-slot="{ errors }"> -->
                            <div class="message_history d-flex">
                        <div class="form-group">
                            <label>MESSAGES HISTORY*</label>
                            <input type="text" class="form-control" :disabled="unlimitedHistory" name="messages history" maxlength="6" placeholder="Enter message history" @keypress="isCharAt($event)" v-model="messagesHistory" autocomplete="off" />
                            <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                              <span class="InvalidInput" v-if="checkMessagesHistory && showError3">The channel member limit field is required.</span>
                        </div>

                        <!-- </ValidationProvider> -->
                        <label class="checkbox-c opacity-zero">Unlimited<input type="checkbox" style="margin-right:5px" id="UnlimitedHistory" name="msgbranch" value="Unlimited" v-model="unlimitedHistory"><span class="checkmark"></span></label>
                    <!--         <label for="UnlimitedHistory">Unlimited</label> -->
                    </div>
                  
                    </div>
                    <div class="col-md-6">
                         <ValidationProvider rules="required" name="description" v-slot="{ errors }">
                        <div class="form-group">
                            <label>DESCRIPTION*</label>
                            <input type="text" class="form-control" name="cloud storage description" placeholder="Enter description" v-model="description" autocomplete="off" />
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="btn-wrapper">
                    <b-button type="button" variant="primary" disabled v-if="isSpinner">                                        
                        Loading....
                    </b-button>
                     <button class="btn btn-white btn-cancel" @click="ClosePackgeDiv()">Cancel</button>
                    <button class="btn btn-yellow" v-if="!isSpinner" >Submit</button>
                </div>
            </form>
            </ValidationObserver>
             <ValidationObserver ref="log" v-slot="{ handleSubmit}">
            <form @submit.prevent="handleSubmit(UpdatePackage),showError=true,showError2=true,showError3=true,showError4=true" v-if="isEditForm == true">
                <div class="admin_Page_title">
                    <h4>Edit Package</h4>
                </div> 
                <div class="row">
                    <div class="col-md-4">
                         <ValidationProvider rules="required" name="plan type" v-slot="{ errors }">
                        <div class="form-group">
                            <label>PLAN TYPE*</label>
                            <select class="form-control arrow_side" name="plan type" v-model="plantype">
                                <option value="" hidden disabled>Select plan type</option>
                                <option v-for="option in planType" v-bind:key="option.value">
                                    {{ option.value }}
                                </option>
                            </select>
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                        <ValidationProvider rules="required" name="package_name" v-slot="{ errors }">
                        <div class="form-group">
                            <label>NAME*</label>
                            <input type="text" class="form-control" placeholder="Enter name" v-model="packagename" autocomplete="off" />
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                         </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                         <ValidationProvider rules="required" name="package_type" v-slot="{ errors }">
                        <div class="form-group">
                            <label>PACKAGE TYPE*</label>
                            <select class="form-control arrow_side" name="package type" v-model="packagetype">
                                <option value="" hidden disabled>Select package type</option>
                                <option v-for="option in packageType" v-bind:key="option.value">
                                    {{ option.value }}
                                </option>
                            </select>
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                    </div>
                     <div class="col-md-4" v-if="packagetype == 'Paid'">
                         <div class="price-wrapper d-flex">
                        <ValidationProvider rules="required" name="price" v-slot="{ errors }">
                        <div class="form-group">
                            <label>PRICE*</label>
                            <input type="text" class="form-control" placeholder="Enter price"  v-model="price" autocomplete="off" @keypress="isCharAt($event)" />

                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                         <div class="radio-wrapper">
                             <div class="radio">
                            <input type="radio"  id="Monthly" name="pricetype" value="Monthly" v-model="pricetype">
                            <label for="Monthly" class="radio-label">Monthly</label>
                            </div>
                            <div class="radio">
                            <input type="radio"  id="Yearly" name="pricetype" value="Yearly" v-model="pricetype">
                            <label for="Yearly" class="radio-label">Yearly</label>
                            </div>
                         </div>
                         </div>
                    </div>
                     <div class="col-md-4">
                        <!-- <ValidationProvider rules="required" name="file type" v-slot="{ errors }"> -->
                        <div class="form-group">
                            <label>FILE TYPE*</label>
                            <span @click="showOptions=!showOptions">
                                <input class="form-control arrow_side" type="text" disabled name="file type" :placeholder="filetype">
                            </span>
                            <div class="custom-select" v-show="showOptions">
                                <span class="custom-selectCheck">
                                    <input type="checkbox" id="All" name="All" @change="selectAllFileType($event)"> All
                                </span>
                                <span v-for="(dt,ind) in FileType" :key="ind" class="custom-selectCheck">
                                    <input type="checkbox" :name="dt.value" v-model="filetype" :value="dt.value" @change="addFileType()"> {{dt.value}}
                                </span>
                            </div>
                            <span class="InvalidInput" v-if="checkFileTypeError && showError4">The no. of channel field is required.</span>
                            <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                        </div>
                        <!-- </ValidationProvider> -->
                    </div>
                    <div class="col-md-4">
                        <ValidationProvider rules="required" name="device" v-slot="{ errors }">
                            <!-- <label class="checkbox-c opacity-zero">Send Message 1 to 1<input type="checkbox" checked="checked"><span class="checkmark"></span></label> -->
                        <div class="form-group d-flex checkbox_device_wrapper">
                            <label style="width:100%;">DEVICE*</label>
                            <div>
                                <label class="checkbox-c opacity-zero">All
                            <input style="margin-top:12px" type="checkbox" name= "all" id= "all" @change="selectAll($event)"> 
                            <span class="checkmark"></span>
                                </label>
                            </div>
                            <div style="width: fit-content;display: inline; margin-left:5px" v-for="device in devices" v-bind:key="device.name">
                                <label class="checkbox-c opacity-zero"> {{device.name}}
                                <input type="checkbox" :name = device.name  v-model="deviceIds" :value = device.name @change="selectOther($event)">
                                 <span class="checkmark"></span>
                               
                                </label>
                            </div>
                             <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                       
                        </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label style="width:100%;">MSG BRANCH*</label>
                            <div class="radio">
                                <input type="radio"  id="Yes" name="msgbranch" value="Yes" v-model="msgbranch">
                                <label for="Yes" class="radio-label">Yes</label>
                            </div>
                            <div class="radio">
                            <input type="radio"  id="No" name="msgbranch" value="No" v-model="msgbranch">
                                <label for="No" class="radio-label">No</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label style="width:100%;">FULLSHARE*</label>
                            <div class="radio">
                                <input type="radio"  id="F_Yes" name="fullshare" value="Yes" v-model="fullshare">
                                   <label for="F_Yes" class="radio-label">Yes</label>
                            </div>
                            <div class="radio">
                                <input type="radio"  id="F_No" name="fullshare" value="No" v-model="fullshare">
                                    <label for="F_No" class="radio-label">No</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <ValidationProvider rules="required" name="channel" v-slot="{ errors }">
                        <div class="form-group">
                            <label>CHANNEL*</label>
                            <select class="form-control arrow_side" name="channel" v-model="channel" >
                                <option value="" hidden disabled>Select</option>
                                <option v-for="option in channelOptions" v-bind:key="option.value">
                                    {{ option.value }}
                                </option>
                            </select>
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-4" v-if="channel=='Yes'">
                        <!-- <ValidationProvider rules="required" name="no. of channel" v-slot="{ errors }"> -->
                            <div class="message_history d-flex">
                            <div class="form-group">
                            <label>NO. OF CHANNEL</label>
                            <input type="text" class="form-control" :disabled="unlimitedChannel" name="no. of channel" maxlength="5" @keypress="isCharAt($event)"  placeholder="Enter no. of channel" v-model.number="noOfChannel" autocomplete="off" />
                            <span class="InvalidInput" v-if="checkChannel && showError">The no. of channel field is required.</span>
                            </div>
                            <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                            <label class="checkbox-c opacity-zero">Unlimited
                             <input type="checkbox"  id="Unlimited" name="msgbranch" value="Unlimited" v-model="unlimitedChannel">
                             <span data-v-695af586="" class="checkmark"></span>
                        <!--         <label for="Unlimited3">Unlimited</label> -->
                            </label>
                            </div>
                        
                        
                        <!-- </ValidationProvider> -->
                    </div>
                    <div class="col-md-4" v-if="plantype=='Business' && channel=='Yes'">
                        <!-- <ValidationProvider rules="required" name="channel number limit" v-slot="{ errors }"> -->
                            <div class="message_history d-flex">
                                <div class="form-group">
                                    <label>CHANNEL MEMBER LIMIT</label>
                                    <input type="text" class="form-control" :disabled="unlimitedMember" name="channel number limit" @keypress="isCharAt($event)" maxlength="4" placeholder="Enter channel memeber limit" v-model.number="channelMemberLimit" autocomplete="off" />
                                    <span class="InvalidInput" v-if="checkChannelMemberLimit && showError2">The channel member limit field is required.</span>
                                    <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                                </div>
                                   <label class="checkbox-c opacity-zero">Unlimited
                                    <input type="checkbox" id="Unlimited4" name="msgbranch" value="Unlimited" v-model="unlimitedMember">
                                    <span data-v-695af586="" class="checkmark"></span>
                                <!--     <label for="Unlimited4">Unlimited</label> -->
                                   </label>
                                </div>
                             
                        
                        <!-- </ValidationProvider> -->
                    </div>
                     <div class="col-md-4">
                        <ValidationProvider rules="required|numeric|min_value:1|max:5" name="file_size" v-slot="{ errors }">
                        <div class="form-group">
                           
                            <label>MAX FILE SIZE*</label>
                              <div class="d-flex file_size">
                            <input type="text" class="form-control" placeholder="Enter max file size" v-model="filesize" autocomplete="off" @keypress="isCharAt($event)" />
                          <select class="form-control arrow_side" name="channel" v-model="fileSizeValue" >
                            <option value="" hidden disabled>Size</option>
                            <option v-for="option in sizeOptions" v-bind:key="option.value">
                                {{ option.value }}
                            </option>
                        </select>
                             </div>
                             
                          <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                        
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label style="width:100%;">ADMIN RIGHTS*</label>
                            <div class="radio">
                                <input type="radio"  id="Yes2" name="adminrights" value="Yes" v-model="adminrights">
                                <label for="Yes2" class="radio-label">Yes</label>
                            </div>
                            <div class="radio">
                                <input type="radio"  id="No2" name="adminrights" value="No" v-model="adminrights">
                                <label for="No2" class="radio-label">No</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <ValidationProvider rules="required" name="cloud_storage" v-slot="{ errors }">
                        <div class="form-group">
                            <label>CLOUD STORAGE*</label>
                            <div class="d-flex file_size">
                                 <input type="text" class="form-control" placeholder="Enter cloud storage"  v-model="cloudstorage" autocomplete="off" @keypress="isCharAt($event)" />
                               
                                 <select class="form-control arrow_side" name="channel" v-model="cloudStorageSize" >
                                        <option value="" hidden disabled>Size</option>
                                        <option v-for="option in sizeOptions" v-bind:key="option.value">
                                            {{ option.value }}
                                        </option>
                                 </select>
                            </div>
                             <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                         </ValidationProvider>
                         
                    </div>
                    <div class="col-md-6">
                        <!-- <ValidationProvider rules="required" name="cloud storage description" v-slot="{ errors }"> -->
                        <div class="form-group">
                            <label>CLOUD STORAGE DESCRIPTION</label>
                            <input type="text" class="form-control" name="cloud storage description" placeholder="Enter description" v-model="cloudStorageDescription" autocomplete="off" />
                            <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                        </div>
                        <!-- </ValidationProvider> -->
                    </div>
                    <div class="col-md-4">
                        <!-- <ValidationProvider rules="numeric|min_value:1|max:5" name="messages history" v-slot="{ errors }"> -->
                              <div class="message_history d-flex">
                        <div class="form-group">
                            <label>MESSAGES HISTORY*</label>
                            <input type="text" class="form-control" :disabled="unlimitedHistory" maxlength="6" name="messages history" placeholder="Enter message history" @keypress="isCharAt($event)" v-model="messagesHistory" autocomplete="off" />
                            <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                             <span class="InvalidInput" v-if="checkMessagesHistory && showError3">The channel member limit field is required.</span>
                        </div>
                        <!-- </ValidationProvider> -->
                        
                       <label class="checkbox-c opacity-zero">Unlimited <input type="checkbox" style="" id="UnlimitedHistory" name="msgbranch" value="Unlimited" v-model="unlimitedHistory"><span class="checkmark"></span></label>
                    <!--         <label for="UnlimitedHistory"></label> -->
                              </div>
                    </div>
                    <div class="col-md-6">
                         <ValidationProvider rules="required" name="description" v-slot="{ errors }">
                        <div class="form-group">
                            <label>DESCRIPTION*</label>
                            <input type="text" class="form-control" name="cloud storage description" placeholder="Enter description" v-model="description" autocomplete="off" />
                            <span class="InvalidInput">{{ errors[0] }}</span>
                        </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="btn-wrapper">
                    <b-button type="button" variant="primary" disabled v-if="isSpinner">                                        
                        Loading....
                    </b-button>
                     <button class="btn btn-yellow" @click="ClosePackgeDiv()">Cancel</button>
                    <button class="btn btn-yellow" v-if="!isSpinner" >Submit</button>
                </div>
            </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import firebase from "../firebaseInit";
import firebaseTimestamp from "firebase";
import firebaseData from '../../utils/firebaseCollection.js';
import FrontFooter from "@/components/forntfooter.vue";
import Swal from 'sweetalert2'
export default ({
    name:'packageManagmentMain',
     components: {
      firebaseData,
      FrontFooter,
    },
    data(){
        return{
            isSpinner:false,
            isPackageDivShow :false,
            packagename:'',
            plantype:'',
            packagetype:'',
            packageType:[],
            price:'',
            pricetype:'Monthly',
            filetype:[],
            all:'',
            channel:'',
            channelOptions:[{name:'Yes',value:'Yes'},{name:'No',value:'No'}],
            noOfChannel:null,
            unlimitedChannel:false,
            channelMemberLimit:null,
            unlimitedMember:false,
            fullshare:'Yes',
            adminrights:'Yes',
            msgbranch:'Yes',
            filesize:'',
            fileSizeValue:'',
            sizeOptions:[],
            cloudstorage:'',
            cloudStorageSize:'',
            cloudStorageDescription:'',
            messagesHistory:null,
            unlimitedHistory:false,
            description:'',
            deviceIds:[],
            planType:[],
            FileType:[],
            devices:[],
            packageListing:[],
            isEditForm:false,
            updateId:'',
            showError:false,
            showError2:false,
            showError3:false,
            showError4:false,

            showOptions:false
        }
    },
    created(){
        // //debugger // eslint-disable-line
        var self=this;
        const db = firebase.firestore();
        //GET DATA FROM PACKAGEMANAGMENTDATA COLLECTION(Shraddha)
        db.collection(firebaseData.firebasecollection.PACKAGEMANAGMENTDATA).doc('PlanType').get().then((doc) => {
           self.planType = [];
           self.planType = doc.data().planType;
        })
        db.collection(firebaseData.firebasecollection.PACKAGEMANAGMENTDATA).doc('PackageType').get().then((doc) => {
           self.packageType = [];
           self.packageType = doc.data().packageType;
        })
        db.collection(firebaseData.firebasecollection.PACKAGEMANAGMENTDATA).doc('Size').get().then((doc) => {
           self.sizeOptions = [];
           self.sizeOptions = doc.data().size;
        })
        db.collection(firebaseData.firebasecollection.PACKAGEMANAGMENTDATA).doc('FileType').get().then((doc) => {
           self.FileType = [];
           self.FileType = doc.data().fileType;
        })
        db.collection(firebaseData.firebasecollection.PACKAGEMANAGMENTDATA).doc('Device').get().then((doc) => {
           self.devices = [];
           self.devices = doc.data().devices;
        })
        //END
        self.init();
    },
    methods:{
        // FOR ADDNEWPACKAGE BUTTON CLICK(Shraddha)
        AddNewPackage(){
            // //debugger // eslint-disable-line
            var self=this;
            self.isPackageDivShow = true;
            self.isEditForm = false;
            //console.log(self.isEditForm )
        },
        // CANCEL BUTTON CLICK ON NEW PACKAGE PAGE(Shraddha)
        ClosePackgeDiv(){
            var self=this;
            self.isPackageDivShow = false;
            self.resetForm();
            self.isEditForm = false;
            self.$refs.log.reset();   
        },
        // SAVE NEW PACKAGE ON DATABASE COLLECTION (Shraddha)
        savePackage(){
            // //debugger // eslint-disable-line
            var self=this;
            const db = firebase.firestore();
            //console.log('form data',self.packagename,self.plantype,self.price,self.filetype,self.channel,self.deviceIds,self.fullshare,self.adminrights,self.msgbranch,self.filesize,self.cloudstorage,self.description);
            //console.log(self.plantype=='Social' , !self.checkChannel , !self.checkMessagesHistory,'Business', self.plantype=='Business' , !self.checkChannelMemberLimit , !self.checkChannel , !self.checkMessagesHistory)
            if(self.plantype=='Social' && !self.checkChannel && !self.checkMessagesHistory && !self.checkFileTypeError || self.plantype=='Business' && !self.checkChannelMemberLimit && !self.checkChannel && !self.checkMessagesHistory && !self.checkFileTypeError)
            {
                self.showError=false
                self.showError2 = false
                self.showError3 = false
                self.showError4=false

                db.collection(firebaseData.firebasecollection.PACKAGEMANAGMENT).add({
                    'createdAt':firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                    'PackageName' : self.packagename,
                    'PlanType' : self.plantype,
                    'Price' : self.price,
                    'FileType' : self.filetype,
                    'Channel' : self.channel,
                    'Device' : self.deviceIds,
                    'FullShare' : self.fullshare,
                    'AdminRights':self.adminrights,
                    'MsgBranch' : self.msgbranch,
                    'MaxFileSize':self.filesize,
                    'CloudStorage' : self.cloudstorage,
                    'Description':self.description,
                    'isDeleted' :0,
                    'PackageType': self.packagetype,
                    'PriceType':self.pricetype,
                    'FileSize':self.fileSizeValue,
                    'NoOfChannel':self.noOfChannel,
                    'UnlimitedChannel':self.unlimitedChannel,
                    'CloudStorageSize':self.cloudStorageSize,
                    'CloudStorageDescription':self.cloudStorageDescription,
                    'MessagesHistory':self.messagesHistory,
                    'UnlimitedHistory':self.unlimitedHistory,
                    'ChannelMemberLimit':self.channelMemberLimit,
                    'UnlimitedMember':self.unlimitedMember,
                    'usersLimit':0,
                    'isActive':false,
                    'planPurchaseDate':'',
                    'planExpireyDate':''
                }).then( (docRef) => {
                    db.collection(firebaseData.firebasecollection.PACKAGEMANAGMENT)
                    .doc(docRef.id)
                    .update({
                        id:docRef.id
                    })
                    .then(()=>{
                        self.$notify({
                            title:"Success",
                            text:'Package Submited Successfully.',
                            type:"success"
                        })
                        self.resetForm();
                        self.init();
                        self.isPackageDivShow = false;
                    })
                })
            }
            else
            {
                self.showError=true
                self.showError2 = true
                self.showError3 = true
                self.showError4=true
            }
        },
        // RESET PACAKGE DATA(Shraddha)
        resetForm(){
            var self = this;
            self.packagename = '';
            self.plantype = '';
            self.price = '';
            self.filetype = []; 
            self.channel ='';
            self.deviceIds = [];
            self.fullshare = 'Yes';
            self.adminrights = 'Yes';
            self.msgbranch = 'Yes';
            self.filesize = '';
            self.cloudstorage = '';
            self.description = '';

            self.packagetype= '',
            self.pricetype= 'Monthly',
            self.fileSizeValue= '',
            self.noOfChannel=null,
            self.unlimitedChannel=false,
            self.cloudStorageSize= '',
            self.cloudStorageDescription= '',
            self.messagesHistory=null,
            self.unlimitedHistory= false,

            self.channelMemberLimit=null,
            self.unlimitedMember=false
            self.showError = false
            self.showError2=false

            self.$refs.log.reset();

        },
        // FOR GET ALL PACKAGE FROM DATABASE COLLECTION(Shraddha)
        init(){
            // //debugger // eslint-disable-line
            var self=this;
            const db = firebase.firestore();
            self.packageListing = [];
            db.collection(firebaseData.firebasecollection.PACKAGEMANAGMENT).get().then((snapshot) => {
                snapshot.forEach((doc) => {
                    if(doc.data().isDeleted == 0){
                        self.packageListing.push({
                            PackageName : doc.data().PackageName,
                            PlanType : doc.data().PlanType,
                            Price : doc.data().Price,
                            FileType : doc.data().FileType,
                            Channel : doc.data().Channel,
                            Device : doc.data().Device,
                            FullShare : doc.data().FullShare,
                            AdminRights: doc.data().AdminRights,
                            MsgBranch : doc.data().MsgBranch,
                            MaxFileSize: doc.data().MaxFileSize,
                            CloudStorage : doc.data().CloudStorage,
                            Description: doc.data().Description,
                            Id : doc.id,
                            PackageType: doc.data().PackageType,
                            PriceType:doc.data().PriceType,
                            FileSize:doc.data().FileSize,
                            NoOfChannel:doc.data().NoOfChannel,
                            UnlimitedChannel:doc.data().UnlimitedChannel,
                            CloudStorageSize:doc.data().CloudStorageSize,
                            CloudStorageDescription:doc.data().CloudStorageDescription,
                            MessagesHistory:doc.data().MessagesHistory,
                            UnlimitedHistory:doc.data().UnlimitedHistory,
                            ChannelMemberLimit:doc.data().ChannelMemberLimit,
                            UnlimitedMember:doc.data().UnlimitedMember,
                        });
                    }
                })
                //console.log('self.packageListing',self.packageListing)
            });
        },
        // DELETE PACKAGE (Shraddha)
        DeletePackage(id,index){
            // //debugger // eslint-disable-line
            var self = this;
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
            Swal.fire({
                title: "Are you sure want to delete it?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ffab3f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //console.log('id',id);
                    const db = firebase.firestore();
                    self.packageListing.splice(index,1);
                    db.collection(firebaseData.firebasecollection.PACKAGEMANAGMENT)
                    .doc(id)
                    .update({
                    'isDeleted' :1  
                    })
                    .then( () => {
                        self.resetForm();
                        self.$notify({
                            title:"Success",
                            text:'Package Deleted Successfully.',
                            type:"success"
                        })
                    })
                }
            })
        },
        //EDIT PACAKGE(Shraddha)
        EditPackage(id){
            // //debugger // eslint-disable-line
            var self = this;
            self.updateId = '';
            self.isPackageDivShow = true;
            self.isEditForm = true;
            //console.log('id',id);
            self.updateId = id;
             var index = self.packageListing.findIndex(item=>{
                return item.Id == id
            })
            //console.log('index',index)
            self.packagename = self.packageListing[index].PackageName
            self.plantype = self.packageListing[index].PlanType
            self.price = self.packageListing[index].Price
            self.filetype = self.packageListing[index].FileType 
            self.channel = self.packageListing[index].Channel
            self.deviceIds = self.packageListing[index].Device
            self.fullshare = self.packageListing[index].FullShare
            self.adminrights = self.packageListing[index].AdminRights
            self.msgbranch = self.packageListing[index].MsgBranch
            self.filesize = self.packageListing[index].MaxFileSize
            self.cloudstorage = self.packageListing[index].CloudStorage
            self.description = self.packageListing[index].Description
            self.packagetype= self.packageListing[index].PackageType
            self.pricetype= self.packageListing[index].PriceType
            self.fileSizeValue= self.packageListing[index].FileSize
            self.noOfChannel= self.packageListing[index].NoOfChannel
            self.unlimitedChannel= self.packageListing[index].UnlimitedChannel
            self.cloudStorageSize= self.packageListing[index].CloudStorageSize
            self.cloudStorageDescription= self.packageListing[index].CloudStorageDescription
            self.messagesHistory= self.packageListing[index].MessagesHistory
            self.channelMemberLimit = self.packageListing[index].ChannelMemberLimit
            self.unlimitedHistory= self.packageListing[index].UnlimitedHistory

            setTimeout( () => {
                if(self.packageListing[index].Device.length==3)
                {
                    document.getElementById('all').checked = true;
                }
                if(self.packageListing[index].FileType.length==self.filetype.length)
                {
                    document.getElementById('All').checked = true;
                }
            },200)
        },
        //UPDATE PACKAGE(Shraddha)
        UpdatePackage(){
            // //debugger // eslint-disable-line
            var self = this;
            var id = self.updateId;
            const db = firebase.firestore();
            //console.log(self.isEditForm)
            //console.log(self.plantype=='Social',!self.checkChannel , !self.checkMessagesHistory , self.plantype=='Business' , !self.checkChannelMemberLimit , !self.checkChannel)
            if(self.plantype=='Social' && !self.checkChannel && !self.checkMessagesHistory && !self.checkFileTypeError || self.plantype=='Business' && !self.checkChannelMemberLimit && !self.checkChannel && !self.checkMessagesHistory && !self.checkFileTypeError)
            {
                self.showError=false
                self.showError2 = false
                self.showError3 = false
                self.showError4=false

                db.collection(firebaseData.firebasecollection.PACKAGEMANAGMENT).doc(id).update({
                   'PackageName' : self.packagename,
                   'PlanType' : self.plantype,
                   'Price' : self.price,
                   'FileType' : self.filetype,
                   'Channel' : self.channel,
                   'Device' : self.deviceIds,
                   'FullShare' : self.fullshare,
                   'AdminRights':self.adminrights,
                   'MsgBranch' : self.msgbranch,
                   'MaxFileSize':self.filesize,
                   'CloudStorage' : self.cloudstorage,
                   'Description':self.description,
                   'PackageType': self.packagetype,
                    'PriceType':self.pricetype,
                    'FileSize':self.fileSizeValue,
                    'NoOfChannel':self.noOfChannel,
                    'UnlimitedChannel':self.unlimitedChannel,
                    'CloudStorageSize':self.cloudStorageSize,
                    'CloudStorageDescription':self.cloudStorageDescription,
                    'MessagesHistory':self.messagesHistory,
                    'UnlimitedHistory':self.unlimitedHistory,
                    'ChannelMemberLimit':self.channelMemberLimit,
                    'UnlimitedMember':self.unlimitedMember,
                    // 'usersLimit':0,
                    // 'isActive':false,
                    // 'planPurchaseDate':'',
                    // 'planExpireyDate':''
                }).then( () => {
                    self.$notify({
                        title:"Success",
                        text:'Package Updated Successfully.',
                        type:"success"
                    })
                    self.resetForm();
                    self.init();
                    self.isPackageDivShow = false;
                })
            }
            else
            {
                self.showError=true
                self.showError2 = true
                self.showError3 = true
                self.showError4=true
            }
        },
        //SELECT ALL CHECKBOX(Shraddha)
        selectAll(event){
            // //debugger // eslint-disable-line
            //console.log(event);
            this.deviceIds = [];
            //console.log(this.deviceIds,this.devices)
            if(event.target.checked == true){
                for(var i=0; i<this.devices.length;i++){
                    this.deviceIds.push(this.devices[i].name); 
                }
            }
            //console.log(this.deviceIds);
            
        },
        //SELECT OTHER CHECKBOX(Shraddha)
        selectOther(evt){
            // //debugger // eslint-disable-line
            //console.log(evt,this.deviceIds,this.devices)
            if(this.deviceIds.length == this.devices.length){
                document.getElementById('all').checked = true;
            }else{
                document.getElementById('all').checked = false;  
            }
        },

        //Select all file types(Shiv)
        selectAllFileType(event){
            // //debugger // eslint-disable-line
            // //console.log(event);
            this.filetype = [];
            // //console.log(this.filetype,this.devices)
            if(event.target.checked == true){
                for(var i=0; i<this.FileType.length;i++){
                    this.filetype.push(this.FileType[i].value); 
                }
            }
            //console.log(this.filetype);
        },
        //select file types one by one(Shiv)
        addFileType()
        {
            // //debugger // eslint-disable-line
            // //console.log(evt,this.filetype,this.FileType)
            if(this.filetype.length == this.FileType.length){
                document.getElementById('All').checked = true;
            }else{
                document.getElementById('All').checked = false;  
            }
        },


        // ONLY ENTER NUMBER(PHONE NUMBER) (Shraddha)
        isCharAt(evt)
        {
        // //debugger // eslint-disable-line
        const char = String.fromCharCode(evt.keyCode);
            if (!/[0-9 ||.]/.test(char)) { 
                evt.preventDefault();
            }
        },
        // BACK TO PACKAGE LIST(Shraddha)
        BacktoPackageList(){
            var self = this;
            self.isPackageDivShow = false;
        }
    },
    computed:
    {
        checkChannel()
        {
            if(this.channel=='Yes')
            {
                if(this.unlimitedChannel || this.noOfChannel>0)
                {
                    return false
                }
                else
                {
                    return true
                }
            }
            else
            {
                return false
            }
        },
        checkChannelMemberLimit()
        {
            if(this.channel=='Yes')
            {
                if(this.unlimitedMember || this.channelMemberLimit>0)
                {
                    return false
                }
                else
                {
                    return true
                }
            }
            else
            {
                return false
            }
        },
        checkMessagesHistory()
        {
            if(this.unlimitedHistory || this.messagesHistory>0)
            {
                return false
            }
            else
            {
                return true
            }
        },
        checkFileTypeError()
        {
            if(this.filetype.length!=0)
            {
                return false
            }
            else
            {
                return true
            }
        }
    },
})
</script>
<style scoped>
.custom-selectCheck:hover{
    background-color: #3391ff !important;
    color:white
}
.custom-selectCheck{
    display: block;
    padding:2px
}
.custom-select{
    border: 1px solid rgb(188, 193, 201);
    background-color: white;
    position: absolute;
    width: 100%;
    z-index: 1;
}
</style>