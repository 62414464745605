<template>
<div>
  <Header v-if="isAdmin == false" :imageUrl= userData :chatObject= chatObject :activeGroup = activeGroup :activeChat= activeChat :groupData="groupData" />
    <section class="profile-wrapper general_setting">
        <div class="container-fluid">
             <div class="row mb-4">
              <div class="col-md-12">
                  <div class="backbtn-wrapper">
                  <router-link to="/chat"><button class="btn btn-white"><img src="@/assets/images/tag-arrow.png" class="back-arrow"> Back To Chat</button></router-link>
                  </div>
              </div>
            </div>
              <div class="row">
                <!-- <div class="col-md-12">
                    <b-tabs content-class="mt-3" align="left" class="active-chat-tab">
                    <b-tab title="Social" active @click="SocialMenuActiveTab()">

                    </b-tab>
                    <b-tab title="Business" @click="businessMenuActiveTab()">
                        <div class="admin_business_wrap">

                        </div>
                    </b-tab>
                    </b-tabs>
                </div> -->
            </div>
            <div class="row">

                <div class="col-md-2">
                        <h4>General Settings</h4>
                </div>
                <div class="col-md-10">
                  <div class="myprofile-form_wrapper"> 
                        <h4>{{activeTab}}</h4>
                  </div>
                </div>
            </div>
            <div class="col-md-12">
                <b-card no-body :class="{'businessgeneral' :businessMenuActive == true }">
                    <b-tabs v-model="tabIndex" pills card vertical>
                   
                    <b-tab title="Change Password" @click.prevent="activeTab ='Change Password'">
                        <div class="change_password">
                            <div class="row">
                                <div class="col-md-3">
                                    <ValidationObserver ref="passwordChange" v-slot="{ handleSubmit }">
                                    <form @submit.prevent="handleSubmit(saveNewPassword)" style="position:relative;">
                                        <ValidationProvider rules="required" name="current password" v-slot="{ errors }"> 
                                        <div class="form-group mb-5">
                                            <!-- <label>Current Password *</label> -->
                                            <input :type="passwordType" maxlength="25" name="current password" placeholder="Current Password*" class="form-control" v-model="currentPassword" id="password">
                                            <img v-if="passwordType=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye">
                                            <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye">
                                            <span class="InvalidInput">{{ errors[0] }}</span>
                                        </div>
                                        </ValidationProvider>
                                        <ValidationProvider rules="required" name="new password" v-slot="{ errors }">
                                        <div class="form-group mb-5">
                                            <!-- <label>New Password *</label> -->
                                            <input :type="passwordTypes" maxlength="25" name="new password" placeholder="New Password*" class="form-control" v-model="newPassword" id="confirmpassword" autocomplete="off"  @keyup="validatePassword($event)">
                                            <img v-if="passwordTypes=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPasswords();" id="togglePassword" class="password_eye">
                                            <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPasswords();" id="togglePassword" class="password_eye">
                                            <span class="InvalidInput">{{ errors[0] }}</span>
                                            <span class="InvalidInput password_validation_message" v-if="passwordErrorMessage!= '' && errors.length == 0">{{passwordErrorMessage}}</span>

                                        </div>
                                        </ValidationProvider>
                                        <ValidationProvider rules="required|confirmed:new password" name="confirm new password" v-slot="{ errors }">
                                        <div class="form-group">
                                            <!-- <label>Confirm New Password *</label> -->
                                            <input :type="passwordTypess" maxlength="25" name="confirm new password" placeholder="Confirm new Password*" class="form-control" v-model="confirmNewPassword" id="confirmnewpassword">
                                            <img v-if="passwordTypess=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPasswordss();" id="togglePassword" class="password_eye">
                                            <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPasswordss();" id="togglePassword" class="password_eye">
                                            <span class="InvalidInput">{{ errors[0]=='The confirm new password field confirmation does not match'? 'The password does not match' : errors[0] }}</span>
                                        </div>
                                        </ValidationProvider>
                                        <div class="btn-wrapper form-group">
                                            <button type="submit" class="btn btn-white" @click="cancelData()">Cancel</button>
                                            <button type="submit" v-if="businessMenuActive == false" class="btn btn-yellow" :class="{disabled : isSpinner == true}" >Save</button>
                                            <button type="submit" v-else class="btn btn-blue" :class="{disabled : isSpinner == true}" >Save</button>
                                        </div>
                                    </form>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                      <b-tab title="Deactivate Account" @click.prevent="activeTab ='Deactivate Account'">
                         <div class="deactive_account mt-4">
                             <h4>Are you sure?</h4>
                             <img src="@/assets/images/afterimg.png">
                             <p>By deactivating your account you will no longer be able to login or access 
                                    any of the data on the account.
                             </p>
                            <button class="btn btn-yellow" v-if="businessMenuActive == false" @click="deactivateAccount()">Deactivate Account</button>
                            <button class="btn btn-blue" v-else @click="deactivateAccount()">Deactivate Account</button>

                         </div>
                      </b-tab>
                       <b-tab title="Customer Support" @click.prevent="activeTab ='Customer Support'">
                           <div class="customer_support">
                               <div class="row">
                                   <ValidationObserver ref="customersupport" v-slot="{ handleSubmit }">
                                   <form @submit.prevent="handleSubmit(customerSupport)">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <ValidationProvider rules="required" name="first name" v-slot="{ errors }">
                                            <div class="col-md-6">
                                                    <div class="form-group mb-5">
                                                        <!-- <label>First Name *</label> -->
                                                        <input type="text" placeholder="Enter first name*" maxlength="21" class="form-control" v-model="firstname" @keyup="checkChrecterLengthFirstname(firstname)">
                                                        <span class="InvalidInput">{{ errors[0] }}</span>
                                                        <span v-if="charecterErrorMessageForFirstname != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForFirstname}}</span>
                                                    </div>
                                            </div>
                                            </ValidationProvider>
                                            <ValidationProvider rules="required" name="last name" v-slot="{ errors }">
                                            <div class="col-md-6">
                                                    <div class="form-group mb-5">
                                                        <!-- <label>Last Name *</label> -->
                                                        <input type="text" placeholder="Enter last name*" maxlength="21" class="form-control" v-model="lastname" @keyup="checkChrecterLengthLastname(lastname)">
                                                        <span class="InvalidInput">{{ errors[0] }}</span>
                                                        <span v-if="charecterErrorMessageForLastname != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForLastname}}</span>
                                                    </div>
                                            </div>
                                             </ValidationProvider>
                                            <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
                                                <div class="col-md-6">
                                                    <div class="form-group mb-5">
                                                        <!-- <label>Email *</label> -->
                                                        <input type="email" placeholder="Enter email*" maxlength="51" class="form-control" v-model="email" @keyup="checkChrecterLengthEmail(email)">
                                                        <span class="InvalidInput">{{ errors[0] }}</span>
                                                        <span v-if="charecterErrorMessageForEmail != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForEmail}}</span>
                                                    </div>
                                            </div>
                                             </ValidationProvider>
                                            <!-- <ValidationProvider  name="phone" > -->
                                                <div class="col-md-6">
                                                    <div class="form-group mb-5">
                                                        <!-- <label>Phone number *</label> -->
                                                        <!-- <input type="text" id="phone" placeholder="Enter phone number" class="form-control" v-model="phone" @keypress="isCharAt($event)"> -->
                                                        <!-- <vue-tel-input v-model="phonenumber" @input="checkPhoneNumber"  name="phone number"></vue-tel-input> -->
                                                        <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                                                        <VuePhoneNumberInput v-model="phonenumber" aria-placeholder="red" @update="checkPhoneNumber"  :maxlength = "15" />
                                                        <span class="InvalidInput" v-if="phoneerror != ''">{{phoneerror}}</span>
                                                        <span class="InvalidInput" v-if="errorphone != ''">{{errorphone}}</span>

                                                    </div>
                                                </div>
                                             <!-- </ValidationProvider> -->
                                            <ValidationProvider rules="required|min:3" name="message" v-slot="{ errors }">
                                            <div class="col-md-12">
                                                    <div class="form-group addtextarea">
                                                        <!-- <label>Message *</label> -->
                                                        <textarea placeholder="Enter Message*" class="form-control" maxlength="5000" v-model="message" @keyup="checkChrecterLengthMessage(message)"></textarea>
                                                        <span class="InvalidInput">{{ errors[0]=='The message field must be at least 3 characters'?'Minimum 3 character is required.':errors[0] }}</span>
                                                        <span v-if="charecterErrorMessageForMessage != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForMessage}}</span>
                                                    </div>
                                            </div>
                                             </ValidationProvider>
                                            <div class="btn-wrapper">
                                                <button type="submit" v-if="businessMenuActive == false" class="btn btn-yellow" :class="{disabled : iscustomerSupportSpinner == true}">submit</button>
                                                <button type="submit" v-else class="btn btn-blue" :class="{disabled : iscustomerSupportSpinner == true}">submit</button>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                   </form>
                                   </ValidationObserver>
                               </div>
                           </div>
                       </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>
    </section>
    </div>
</template>
<script>

import Header from "@/components/header.vue";
import firebase from "./firebaseInit";
import firebaseData from '../utils/firebaseCollection.js'
import firebaseTimestamp from "firebase";
import fb from 'firebase';
import {blockgetdata,blockList} from '../utils/getSidebarMedia.js'
import Swal from 'sweetalert2'
import Vue from 'vue';
import moment from 'moment';
// import intlTelInput from 'intl-tel-input';
// import { VueTelInput } from 'vue-tel-input';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);


const db = firebase.firestore()

export default {
    name: "headers",
    components:
    {
        Header,
        firebaseData,
        // VueTelInput,
        // intlTelInput
        VuePhoneNumberInput
    },
    data() {
        return {
            activeTab:'Change Password',

            selected: 'USD',
            options: ['USD' ,'EUR' ,'GBP'],
            quantity: 1,
            tabIndex:0,
            packages:[],
            readReceiptValue:true,
            userid:'',
            isSpinner:false,
            readValue:'',
            isAdmin:'',
            loginUser:'',
            chatObject:{
                firstName:'',
                lastName:'',
                profileUrl:'',
                userid:''
            },
            groupData:{},
            activeGroup:false,
            userData:{},
            activeChat:false,

            showSocialBuySucess:false,
            showBusinessBuySucess:false,

            currentSocialPlan:'',
            currentPassword:'',
            newPassword:'',
            confirmNewPassword:'',
            isSpinner:false,
            blockUserId:[],
            blockUserList:[],
            
            message:'',
            firstname:'',
            lastname:'',
            email:'',
            phone:'',
            iscustomerSupportSpinner:false,
            businessMenuActive :false,
            chatid:'',
            showOtp:false,
            businessPlan:[],

            
            blockUserMessage : '',
            companyname:'',
            companyaddress:'',
            companyemail:'',
            companyName:'',
            CompanyEmail:'',
            CompanyAddress:'',
            companyDocKey:'',
            plans:[],
            socialPlanObject:{},
            isactivetab:this.$route.params.activetab,
            passwordType:"password",
            passwordTypes:"password",
            passwordTypess:"password",
            phoneerror:'',
            phoneValid : '' ,
            phonenumber:'',
            mainNumber:'',
            errorphone:'',
            passwordErrorMessage:'',
            charecterErrorMessageForFirstname:'',
            charecterErrorMessageForLastname:'',
            charecterErrorMessageForEmail:'',
            charecterErrorMessageForMessage:''
        }
    },
    created(){
        //debugger // eslint-disable-line
         var self = this;
        self.userid = localStorage.getItem('customerDocKey');
        //  console.log('this.phoneerror',self.phoneerror,self.userid)
        // if(self.isactivetab == 'Subscription_Details'){
        //     self.activeTab = 'Subscription Details'
        //     self.tabIndex = 1
        // }else{
        //     self.activeTab =  'Read Receipts'
        //     self.tabIndex = 0
        // }

         self.init();
        // console.log('active Tab',self.isactivetab)

        self.$root.$on('getActiveComapnyList',() => {
            self.init();
        })
        // self.$root.$on('getCompanyAdmin',(val) => {
        //     //debugger // eslint-disable-line
        //     self.Comapnyadmin = val.isAdmin
        // })
         self.$root.$on('setBusinessMenu',(value) => {
            // //debugger // eslint-disable-line
             self.businessMenuActive = value
            //  self.init();
         })
        
        
            
    },
    watch : {
        $route : function (){
          this.init();
        }
    },
    methods: {
        //CHECK GROUP NAME CHRECTER LENGTH(Shraddha)
    checkChrecterLengthFirstname(groupName){
        // debugger // eslint-disable-line
        var self = this;
        self.firstname=groupName.replace(/[^A-Za-z]/ig,'')
        if(groupName.length < 21){
            self.firstname = groupName.replace(/[^A-Za-z]/ig,'')
            self.charecterErrorMessageForFirstname = '';
        }else{
            self.charecterErrorMessageForFirstname = 'The firstname may not be greater than 20 characters';
            self.firstname = (groupName).slice(0, 20)
        }
    },
    checkChrecterLengthLastname(groupName){
        // debugger // eslint-disable-lines
        var self = this;
        self.lastname=groupName.replace(/[^A-Za-z]/ig,'')
        if(groupName.length < 21){
          self.charecterErrorMessageForLastname = '';
          self.lastname=groupName.replace(/[^A-Za-z]/ig,'')
        }else{
          self.charecterErrorMessageForLastname = 'The lastname may not be greater than 20 characters';
          self.lastname = (groupName).slice(0, 20)
        }
    },
    checkChrecterLengthEmail(groupName){
        // debugger // eslint-disable-lines
        var self = this;
        // self.email=groupName.replace(/[^A-Za-z0-9]/ig,'')
        if(groupName.length < 51){
            self.email = groupName
            self.charecterErrorMessageForEmail = '';
        }else{
            self.charecterErrorMessageForEmail = 'The email may not be greater than 50 characters';
            self.email = (groupName).slice(0, 50)
        }
    },
    checkChrecterLengthMessage(groupName){
        // debugger // eslint-disable-lines
        var self = this;
        self.message=groupName.replace(/[^A-Za-z]/ig,'')
        if(groupName.length == 5000){
          self.charecterErrorMessageForMessage = 'The message may not be greater than 5000 characters';
        }else{
          self.charecterErrorMessageForMessage = '';
        }
    },
    // PASSWORD VALIDATIONS
    validatePassword(evt){
    // //debugger // eslint-disable-line
    var self=this;
    //console.log(evt.target.value)
    var regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if(regex.test(evt.target.value)){
        // //console.log('dfd',regex.test(evt.target.value))
        self.passwordErrorMessage = ''
    }else{
        if(evt.target.value != ''){
        self.passwordErrorMessage = 'Password must contains at least 8 characters, including one upper/lowercase, numbers and one special character(@#$%).';
        self.$refs.log.reset();
        }else{
        self.passwordErrorMessage = ''
        }
    }
    },
    // GET DATA(Shraddha)
    checkPhoneNumber(phone)
    {
    // debugger // eslint-disable-line
    // console.log(phone)
        this.mainNumber = phone.formattedNumber;
        this.phonenumber = phone.nationalNumber
        this.phoneValid = phone.isValid
        // console.log("number,phone",this.phonenumber, this.phoneValid);
            if(this.phoneValid == false  && this.phonenumber != ''){
            if(this.phonenumber != undefined){
                this.phoneerror = 'please enter valid phone number'
            }
            }else{
            this.phoneerror = ''
            }
    },
    init(){
    //debugger // eslint-disable-line
    var self = this;
    db.collection(firebaseData.firebasecollection.USER).doc(self.userid).onSnapshot(doc=>{
        self.imageUrl = doc.data().profileUrl;
        self.userData = doc.data()
    })
    // CHECK LOGIN USER IS ADMIN OR CUSTOMER(Shraddha)
        self.loginUser = localStorage.getItem("IsLoginUser")
        //console.log('loginUser',self.loginUser);
        if(this.loginUser == 'Admin'){
            self.isAdmin = true
        }else{
            self.isAdmin = false
        }        
        
        
    },
     //SAVE NEW PASSWORD (Shraddha)
    saveNewPassword()
    {
        debugger // eslint-disable-line
        var self = this

        self.isSpinner=true;
        if(self.passwordErrorMessage == ''){
            var randomeCode = Math.floor(Math.random() * 99999) + 100000;
            localStorage.removeItem("randomeString","");
            localStorage.setItem("randomeString",randomeCode);
            const user = firebase.auth().currentUser
            var cred = fb.auth.EmailAuthProvider.credential(
                user.email,
                self.currentPassword
            )
            // //console.log('cred',cred)
            user.reauthenticateWithCredential(cred)
                .then(()=>{
                    user.updatePassword(self.newPassword)
                    .then(()=>{
                        self.isSpinner=false;
                        self.cancelData();
                        self.$refs.passwordChange.reset()
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"Success",
                            text:'Password updated successfully.',
                            type:"success"
                        })
                        console.log(localStorage.getItem("randomeString"))
                        if(localStorage.getItem("randomeString") == undefined || localStorage.getItem("randomeString") == ""){
                            firebase.auth().signOut();
                            self.$router.push('/');
                        }
                            
                        
                    })
                    .catch(error=>{
                        self.isSpinner=false;
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"Error",
                            text:error.message,
                            type:"error"
                        })
                    })
                })
                .catch(error=>{
                self.isSpinner=false;
                self.$root.$emit("audioplay");
                self.$notify({
                    title:"Error",
                    text:error.message,
                    type:"error"
                })
            })
        
        }
    },
    //CHANGE PASSWORD FUNCTIONALITY(Shraddha)
    cancelData(){
        var self = this;
        self.currentPassword = '',
        self.newPassword = '',
        self.confirmNewPassword = '',
        self.$refs.passwordChange.reset()
    },
    //DEACTIVATE_ACCOUNT(Shraddha)
    deactivateAccount(){
        debugger // eslint-disable-line
        var self=this

        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
        });
        Swal.fire({
            title: 'Are you sure you want to deactivate the account ?',
            type: "warning",
            showCancelButton: true,
            customClass:{
                container :"social_confirmation_popup"
            },
            html:
            '<textarea id="swal-input1" class="swal2-input" maxlength = "100" placeholder="Enter Reason(optional)"></textarea>',
            preConfirm: () => {
                return [
                document.getElementById('swal-input1').value
                ]
            },
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, deactivate it!",
            // input: 'text',
            
        }).then((result) => {
            if(result.isConfirmed){
                const user = firebase.auth().currentUser
                var phonenumber = self.userData.countryCode+' '+self.userData.phoneNo
                
                console.log(phonenumber);
                self.$axios.post(firebaseData.firebasecollection.APIDOMAIN +'/deleteUser',{uid:user.uid,reason:result.value[0],phone:phonenumber})
                .then(result=>{
                    console.log(result)
                    if(result.data.deleted)
                    {
                        self.$axios.post(self.$WPbaseurl+'wp-json/delete/user',{uid:self.userData.wp_id})
                            .then((res)=>{
                            console.log('wordpress delete user api response',res)
                        })
                       
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"Success",
                            text:'Deactivate successfully.',
                            type:"success"
                        })
                        firebase.auth().signOut()
                        self.$router.push('/')
                    }
                })
            }
        })
    },
     // ONLY ENTER NUMBER(PHONE NUMBER) (Shraddha)
    isCharAt(evt)
    {
        // //debugger // eslint-disable-line
        const char = String.fromCharCode(evt.keyCode);
            if (!/[0-9 ||.]/.test(char)) {
                evt.preventDefault();
            }
        },
        // CUSTOMER SUPPORT(Shraddha)
        customerSupport(){
            debugger // eslint-disable-line
            var self=this
            if(self.phoneerror == '' && self.errorphone == '' && self.charecterErrorMessageForMessage == '' && self.charecterErrorMessageForFirstname == '' && self.charecterErrorMessageForLastname == ''&& self.charecterErrorMessageForEmail == '' ){
                self.iscustomerSupportSpinner == true
            //console.log(firebaseData.firebasecollection.CUSTOMERSUPPORT)
                db.collection(firebaseData.firebasecollection.CUSTOMERSUPPORT).doc(self.userid).set({
                    firstName:self.firstname,
                    lastName : self.lastname,
                    email : self.email,
                    id:self.userid,
                    mobileNo:self.mainNumber,
                    message:self.message
                }).then(() => {
                    if(Vue.prototype.$adminDetails.userSupportTicket){
                        self.$axios.post(self.$apiurl+'sendMailToAdmin',{adminId:Vue.prototype.$adminDetails.id,message:`You received support ticket from  "${self.userData.email}"<p>Message :${self.message}</p>`,operation:'ticket'})
                        .then((result) => {
                            // console.log('eterte',result)
                        })
                    }
                    self.$root.$emit("audioplay");
                    self.$notify({
                        title:"Success",
                        text:'Data saved successfully.',
                        type:"success"
                    })
                    self.iscustomerSupportSpinner == false
                    self.firstname = '',
                    self.lastname = '',
                    self.phonenumber = '',
                    self.email = '',
                    self.message = '',
                    self.$refs.customersupport.reset() 
                })
            }
        },
        // TOGGLE PASSWORD (Shraddha)        
        showPassword(){
            if(this.passwordType=='password')
            {
                this.passwordType='text'
            }
            else
            {
                this.passwordType='password'
            }
        },
        showPasswords(){
           if(this.passwordTypes=='password')
            {
                this.passwordTypes='text'
            }
            else
            {
                this.passwordTypes='password'
            }
        },
        showPasswordss(){
           if(this.passwordTypess=='password')
            {
                this.passwordTypess='text'
            }
            else
            {
                this.passwordTypess='password'
            }
        },     
    },
    
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>;