<template>
    <div class="copyright-footer mt-5">
        <p>© Dooey Ltd. {{footerText}}</p>
    </div>
</template>
<script>


export default ({
   data(){
       return{
           footerText : new Date().getFullYear()
       }
   },
  
})
</script>
