<template>
    <div class="admin_report_wrapper">
        <div class="row">
            <div class="col-md-12">
                  <div class="admin_Page_title">
                <h4>Report</h4> 
                <img class="title_icon" @click="backtodashboard()" src="@/assets/images/home_icon.png" alt="homeIcon">
                <span class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
                <span class="current_page">Report</span>
                </div>
            </div>
           
           <!-- {{maxLimit}} -->
            <div class="col-md-12 admin_deshboard_wapper">
                <div class="admin_Page_title a_flex">
                    <ul>
                        <li style="cursor:pointer" id="today" :class="{'active':selectedDateFilter=='today'}" @click.prevent="selectedDateFilter='today',getAxis()">Today</li>
                        <li style="cursor:pointer" id="week" :class="{'active':selectedDateFilter=='week'}" @click.prevent="selectedDateFilter='week',getAxis()">This Week</li>
                        <li style="cursor:pointer" id="month" :class="{'active':selectedDateFilter=='month'}" @click.prevent="selectedDateFilter='month',getAxis()">This Month</li>
                        <li style="cursor:pointer" id="year" :class="{'active':selectedDateFilter=='year'}" @click.prevent="selectedDateFilter='year',getAxis()">This Year</li>
                        <li style="border: none" >From : <input type="date" class="form-control"  v-model="fromDate" @change="filterByDate" onkeydown="return false"></li>
                        <li>To : <input type="date"  class="form-control" :min="fromDate" :max="maxLimit" v-model="toDate" @change="filterByDate" @clear="()=>{console.log('Clean')}" onkeydown="return false"></li>
                    </ul>
                </div>
            </div> 
        </div>
        <!-- {{xAxis_arr}} -->
        <!-- {{users}}  -->
        <!--  {{businessAccount}} -->
        <div class="report_wrapper">
            <div class="row">
                <div class="col-md-6">
                  
                    <div class="chart" style="display: block; text-align: center;" v-if="xAxis_arr && xAxis_arr.length && socialRevenue && socialRevenue.length">
                        <span class="chart_title">  Revenue</span>
                        <LineChart :xAxis_arr="xAxis_arr" :yAxis_arr="socialRevenue" :yAxis_arr2="businessRevenue"/>
                    </div>
					<!-- <img src="@/assets/images/graph.png"> -->
                </div>
                
                <div class="col-md-6">
                    <div class="chart" style="display: block; text-align: center;" v-if="xAxis_arr && xAxis_arr.length && users && users.length">
                         <span class="chart_title">   Users</span>
                        <LineChart2 :xAxis_arr="xAxis_arr" :yAxis_arr="users"  />
                    </div>
                    <!-- <img src="@/assets/images/graph2.png"> -->
                </div>
                <div class="col-md-6">
                 
                    <div class="chart" style="display: block; text-align: center;" v-if="xAxis_arr && xAxis_arr.length && socialAccount && socialAccount.length">
                         <span class="chart_title"> Social Account</span>
                        <BarChart :xAxis_arr="xAxis_arr" :yAxis_arr="socialAccount" />
                    </div>
                  <!-- <img src="@/assets/images/graph3.png"> -->
                </div>
                <div class="col-md-6">
                  
                    <div class="chart" style="display: block; text-align: center;" v-if="xAxis_arr && xAxis_arr.length && businessAccount && businessAccount.length">
                        <span class="chart_title">   Business Account</span>
                        <BarChart2 :xAxis_arr="xAxis_arr" :yAxis_arr="businessAccount"/>
                    </div>
                     <!-- <img src="@/assets/images/graph.png"> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LineChart from '@/views/Admin_Panel/LineChart.vue';
import LineChart2 from '@/views/Admin_Panel/LineChart2.vue';
import BarChart from '@/views/Admin_Panel/BarChart.vue';
import BarChart2 from '@/views/Admin_Panel/BarChart2.vue';
import firebaseData from '../../utils/firebaseCollection.js';
import firebase from '../firebaseInit';
import {getAdminUserData,getAdminCompaniesData,getAdminRevenueData} from '@/utils/getSidebarMedia.js';

const db = firebase.firestore()

export default {
name: 'VueChartJS',
 components:{
    LineChart,
    LineChart2,
    BarChart,
    BarChart2,
    firebaseData
 },
 data()
 {
    return{
        months:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        xAxis_arr:[],
        yAxis_arr:[],
        users:[],
        socialRevenue:[],
        businessRevenue:[],
        socialAccount:[],
        businessAccount:[],

        usersDataArray:[],
        companiesArr:[],
        revenues:[],

        plansArr:[],
        socialPlans:[],
        businessPlans:[],
        socialFreePlanName:'',
        businessFreePlanName:'',

        socialGraphColors:['#ffab3f','#d94c00','#8b3f00','#d7a501','#e3c563'],
        businessGraphColors:['#77bef6','#1c59b3','#001f4c','#ccc'],

        selectedDateFilter:'week',
        fromDate:'',
        toDate:'',
        diffDays:0,
        diffMonth:0,
        allDateArray : [],
        DatesArray:[],
        maxLimit:'',
        xaxisYear : [],
        // minDate:new Date().getFullYear()+"-01-01",
        // maxDate:new Date().getFullYear()+"-03-31",
        // monthss :[]
    }
 },

 mounted()
 {
    var self = this
    self.getDetails()

    self.$root.$on('clickStartReportFilter',(val)=>{
        // debugger // eslint-disable-line
        document.getElementById(val).click();
    })
    self.$root.$on('filterUsersByDateDashboard',(val) => {
        // debugger // eslint-disable-line
       document.getElementById(val).click();
    })

    // setTimeout( () => {
    //     self.getAxis()
    // },1000)
 },
 methods: 
 {
    getDetails()
    {
        var self = this

        //get Users Data
        // try{
        getAdminUserData(firebaseData.firebasecollection.USER,(res) => {
            self.usersDataArray=[];
            self.usersDataArray = res;
            self.$root.$emit('renderCharts')
        })
        getAdminCompaniesData(firebaseData.firebasecollection.COMPANY,(res) => {
            self.companiesArr=[];
            self.companiesArr = res;
            // console.log('self.companiesArr',self.companiesArr);
        })
        getAdminRevenueData(firebaseData.firebasecollection.REVENUEMANAGEMENT,(res) => {
            self.revenues=[];
            self.revenues = res;
            // console.log('self.revenues',self.revenues);
        })
        //get Plans
        // debugger // eslint-disable-line
        db.collection(firebaseData.firebasecollection.PLANMANAGEMENT)
            .onSnapshot(querySnapshot=>{
                if(!querySnapshot.empty)
                {
                    self.socialPlans=[]
                    self.businessPlans=[]
                    querySnapshot.forEach(doc=>{
                        if(doc.data().plan_type=='social')
                        {
                            self.socialPlans.push({label:doc.data().plan_name,data:[],backgroundColor:self.socialGraphColors[self.socialPlans.length]})
                            if(doc.data().price==0)
                            {
                                self.socialFreePlanName=doc.data().plan_name
                            }
                        }
                        else if(doc.data().plan_type=='business')
                        {
                            self.businessPlans.push({label:doc.data().plan_name,data:[],backgroundColor:self.businessGraphColors[self.businessPlans.length]})
                            if(doc.data().price==0)
                            {
                                self.businessFreePlanName=doc.data().plan_name
                            }
                        }
                        self.plansArr.push(doc.data())
                    })
                    // console.log('Plans',self.socialPlans,self.businessPlans)
                }
            })
            // .catch(error=>{
            //     console.log(error)
            // })
    },
    getAxis()
    {
        var self = this
        self.fromDate = '';
        self.toDate = '';
        self.$root.$emit("clickStartReportFilter",self.selectedDateFilter);
        self.getDetails()

        if(self.selectedDateFilter=='week')
        {
       
            // debugger // eslint-disable-line
            // var weekStart = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay()+1).getDate()
            // var arrVal = ''
            self.xAxis_arr=[]
            //Users
            self.users=[]
            //Accounts
            self.socialAccount=self.socialPlans
            self.businessAccount=self.businessPlans
            //Revenues
            self.socialRevenue=[]
            self.businessRevenue=[]
            var arrVal = '';
            self.totalWeek = [];
            var curr = new Date; // get current date
            for (let i = 0; i <= 6; i++) {
                let first = (curr.getDate() - curr.getDay() + i)+1;
                var weekStart = first;
                let day = new Date(curr.setDate(first)).toISOString().slice(0, 7)
                var weekMonth = day.split('-');
                self.xAxis_arr.push(first+' '+self.months[Number(weekMonth[1]-1)]);
                // console.log('self.xAxis_arr',self.xAxis_arr)
            }
            for (let i = 0; i < 7; i++) {
                self.users.push(0)
                self.socialAccount.forEach(data=>{
                    data.data.push(0)
                })
                self.businessAccount.forEach(data=>{
                    data.data.push(0)
                })
                self.socialRevenue.push(0)
                self.businessRevenue.push(0)
                // var arrVal = `${self.addZero(new Date(new Date().getFullYear(), new Date().getMonth(),weekStart+i).getDate())} ${self.months[new Date(new Date().getFullYear(), new Date().getMonth(),weekStart+i).getMonth()]}`
                // arrVal = `${self.addZero(new Date(new Date().getFullYear(), new Date().getMonth(),weekStart+i).getDate())} ${self.months[new Date(new Date().getFullYear(), new Date().getMonth(),weekStart+i).getMonth()]}`

                // self.xAxis_arr.push(arrVal)
                var compareDate = self.xAxis_arr[i].split(' ')
                self.usersDataArray.forEach(data=>{
                    var userDate = new Date(data.createdAt.seconds*1000).getDate()
                    var userMonth = new Date(data.createdAt.seconds*1000).getMonth()+1
                    var userYear = new Date(data.createdAt.seconds*1000).getFullYear()
                    if(userDate==Number(compareDate[0]) && userMonth == new Date().getMonth()+1 && userYear == new Date().getFullYear())
                    {
                        self.socialAccount.forEach(data2=>{
                            if(data.socialPlanName.toLowerCase() == data2.label.toLowerCase())
                            {
                                data2.data[i]+=1
                            }
                        })
                        
                        self.users[i]+=1
                    }

                })

                self.revenues.forEach(data=>{
                    var revenueDate = new Date(data.purchaseDate).getDate()
                    var revenueMonth = new Date(data.purchaseDate).getMonth()+1
                    var revenueYear = new Date(data.purchaseDate).getFullYear()

                    if(revenueDate==Number(compareDate[0]) && revenueMonth == new Date().getMonth()+1 && revenueYear == new Date().getFullYear())
                    {
                        
                        if(data.userType.toLowerCase()=='social')
                        {
                            self.socialRevenue[i]+=parseFloat(data.price)
                        }
                        else if(data.userType.toLowerCase() == 'business')
                        {
                            self.businessRevenue[i]+=parseFloat(data.price)
                        }
                    }
                })
            
                self.companiesArr.forEach(data=>{
                    if(data.addone_Array){
                       data.addone_Array.forEach(adone => {
                            var companyCreateDate = new Date(adone.purchase_date).getDate()
                            var companyCreateMonth = new Date(adone.purchase_date).getMonth()+1
                            var companyCreateYear = new Date(adone.purchase_date).getFullYear()
                            if(companyCreateDate==Number(compareDate[0]) && companyCreateMonth == new Date().getMonth()+1 && companyCreateYear == new Date().getFullYear())
                            {
                                self.businessAccount.forEach(data2=>{
                                    if(adone.addon_name.toLowerCase() == data2.label.toLowerCase())
                                    {
                                        data2.data[i]+=1
                                    }
                                })
                            }
                       }) 
                    }
                    data.plan.forEach(planData=>{
                        var companyCreateDate = new Date(planData.purchase_date).getDate()
                        var companyCreateMonth = new Date(planData.purchase_date).getMonth()+1
                        var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                        if(companyCreateDate==Number(compareDate[0]) && companyCreateMonth == new Date().getMonth()+1 && companyCreateYear == new Date().getFullYear())
                        {
                            self.businessAccount.forEach(data2=>{
                                if(planData.plan_name.toLowerCase() == data2.label.toLowerCase())
                                {
                                    data2.data[i]+=1
                                }
                            })
                        }
                    })
                })
            }
                // console.log('sdsdsds',self.users,self.socialRevenue,self.businessRevenue,self.businessAccount)
            // console.log('123123123',self.socialAccount,self.users)
            // console.log('456456456',self.businessAccount)
            // console.log(arrVal)
        }
        else if(self.selectedDateFilter=='month')
        {
            // debugger // eslint-disable-line
            var last = new Date(new Date().getFullYear(), new Date().getMonth()+1,0).getDate()
            self.xAxis_arr=[]
            //Users
            self.users=[]
            //Accounts
            self.socialAccount=self.socialPlans
            self.businessAccount=self.businessPlans
            //Revenues
            self.socialRevenue=[]
            self.businessRevenue=[]

            for (let i = 0; i <= last-1; i++) {
                self.users.push(0)
                self.socialAccount.forEach(data=>{
                    data.data.push(0)
                })
                self.businessAccount.forEach(data=>{
                    data.data.push(0)
                })
                self.socialRevenue.push(0)
                self.businessRevenue.push(0)
                var arrVal = `${self.addZero(i+1)} ${self.months[new Date().getMonth()]}`
                self.xAxis_arr.push(arrVal)

                self.usersDataArray.forEach(data=>{
                    var userDate = new Date(data.createdAt.seconds*1000).getDate()
                    var userMonth = new Date(data.createdAt.seconds*1000).getMonth()+1
                    var userYear = new Date(data.createdAt.seconds*1000).getFullYear()

                    if(userDate==i+1 && userMonth == new Date().getMonth()+1 && userYear == new Date().getFullYear())
                    {
                        self.socialAccount.forEach(data2=>{
                            if(data.socialPlanName.toLowerCase() == data2.label.toLowerCase())
                            {
                                data2.data[i]+=1
                            }
                        })
                        
                        self.users[i]+=1
                    }
                })

                self.revenues.forEach(data=>{
                    var revenueDate = new Date(data.purchaseDate).getDate()
                    var revenueMonth = new Date(data.purchaseDate).getMonth()+1
                    var revenueYear = new Date(data.purchaseDate).getFullYear()

                    if(revenueDate==i+1 && revenueMonth == new Date().getMonth()+1 && revenueYear == new Date().getFullYear())
                    {
                        if(data.userType.toLowerCase()=='social')
                        {
                            self.socialRevenue[i]+=parseFloat(data.price)

                        }
                        else if(data.userType.toLowerCase() == 'business')
                        {
                            self.businessRevenue[i]+=parseFloat(data.price)

                        }
                    }
                })
            
                self.companiesArr.forEach(data=>{
                    if(data.addone_Array){
                       data.addone_Array.forEach(adone => {
                           var companyCreateDate = new Date(adone.purchase_date).getDate()
                           var companyCreateMonth = new Date(adone.purchase_date).getMonth()+1
                           var companyCreateYear = new Date(adone.purchase_date).getFullYear()
                           if(companyCreateDate==i+1 && companyCreateMonth == new Date().getMonth()+1 && companyCreateYear == new Date().getFullYear())
                           {
                               self.businessAccount.forEach(data2=>{
                                   if(adone.addon_name.toLowerCase() == data2.label.toLowerCase())
                                   {
                                       data2.data[i]+=1
                                   }
                               })
                           }
                       }) 
                    }
                    data.plan.forEach(planData=>{
                        var companyCreateDate = new Date(planData.purchase_date).getDate()
                        var companyCreateMonth = new Date(planData.purchase_date).getMonth()+1
                        var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                        if(companyCreateDate==i+1 && companyCreateMonth == new Date().getMonth()+1 && companyCreateYear == new Date().getFullYear())
                        {
                            self.businessAccount.forEach(data2=>{
                                if(planData.plan_name.toLowerCase() == data2.label.toLowerCase())
                                {
                                    data2.data[i]+=1
                                }
                            })
                        }
                    })
                })
            }
            // console.log('sdsdsds',self.users,self.socialRevenue,self.businessRevenue,self.businessAccount)
           
        }
        else if(self.selectedDateFilter=='year')
        {
            self.xAxis_arr = [];
            self.xAxis_arr=self.months
            //users
            self.users=[]
            //Accounts
            self.socialAccount=self.socialPlans
            self.businessAccount=self.businessPlans
            //Revenues
            self.socialRevenue=[]
            self.businessRevenue=[]

            for (let i = 0; i < 12; i++) {
                self.users.push(0)
                self.socialAccount.forEach(data=>{
                    data.data.push(0)
                })
                self.businessAccount.forEach(data=>{
                    data.data.push(0)
                })
                self.socialRevenue.push(0)
                self.businessRevenue.push(0)

                self.usersDataArray.forEach(data=>{
                    
                    var userMonth = new Date(data.createdAt.seconds*1000).getMonth()
                    var userYear = new Date(data.createdAt.seconds*1000).getFullYear()

                    if(userMonth == i && userYear == new Date().getFullYear())
                    {
                        self.users[i]+=1
                        self.socialAccount.forEach(data2=>{
                            if(data.socialPlanName.toLowerCase() == data2.label.toLowerCase())
                            {
                                data2.data[i]+=1
                            }
                        })
                    }
                })

                self.revenues.forEach(data=>{
                    var revenueMonth = new Date(data.purchaseDate).getMonth()
                    var revenueYear = new Date(data.purchaseDate).getFullYear()
                    if(revenueMonth == i && revenueYear == new Date().getFullYear())
                    {
                        if(data.userType.toLowerCase()=='social')
                        {
                            self.socialRevenue[i]+=parseFloat(data.price)
                        }
                        else if(data.userType.toLowerCase() == 'business')
                        {
                            self.businessRevenue[i]+=parseFloat(data.price)

                        }
                    }
                })

                self.companiesArr.forEach(data=>{
                    if(data.addone_Array){
                       data.addone_Array.forEach(adone => {
                           var companyCreateMonth = new Date(adone.purchase_date).getMonth()
                           var companyCreateYear = new Date(adone.purchase_date).getFullYear()
                            if(companyCreateMonth == i && companyCreateYear == new Date().getFullYear())
                            {
                                self.businessAccount.forEach(data2=>{
                                    if(adone.addon_name.toLowerCase() == data2.label.toLowerCase())
                                    {
                                        data2.data[i]+=1
                                    }
                                })
                            }
                       }) 
                    }
                    data.plan.forEach(planData=>{
                        // var companyCreateDate = new Date(planData.purchase_date).getDate()
                        var companyCreateMonth = new Date(planData.purchase_date).getMonth()
                        var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                        if(companyCreateMonth == i && companyCreateYear == new Date().getFullYear())
                        {
                            self.businessAccount.forEach(data2=>{
                                if(planData.plan_name.toLowerCase() == data2.label.toLowerCase())
                                {
                                    data2.data[i]+=1
                                }
                            })
                        }
                    })
                })
            }
        }
        else if(self.selectedDateFilter = 'today')
        {
            debugger // eslint-disable-line
            self.xAxis_arr=[]
            self.xAxis_arr = [`${new Date().getDate()} ${self.months[new Date().getMonth()]}`]
            //Users
            self.users=[0];
            //Accounts
            self.socialAccount=self.socialPlans
            self.businessAccount=self.businessPlans
            self.socialAccount.forEach(data=>{
                data.data.push(0)
            })
            self.businessAccount.forEach(data=>{
                data.data.push(0)
            })
            var compareDate = self.xAxis_arr[0].split(' ')
            // self.socialAccount = [0]
            // self.businessAccount=[0]
            //Revenues
            self.socialRevenue=[0]
            self.businessRevenue=[0]

            self.usersDataArray.forEach(data=>{
                var userDate = new Date(data.createdAt.seconds*1000).getDate()
                var userMonth = new Date(data.createdAt.seconds*1000).getMonth() + 1
                var userYear = new Date(data.createdAt.seconds*1000).getFullYear()

                if(userDate==new Date().getDate() && userMonth == new Date().getMonth()+1 && userYear == new Date().getFullYear())
                {
                    self.socialAccount.forEach(data2=>{
                        if(data.socialPlanName.toLowerCase() == data2.label.toLowerCase())
                        {
                            data2.data[0]+=1
                        }
                    })
                    self.users[0]+=1
                }
            })
            self.revenues.forEach(data=>{
                var revenueDate = new Date(data.purchaseDate).getDate()
                var revenueMonth = new Date(data.purchaseDate).getMonth() + 1
                var revenueYear = new Date(data.purchaseDate).getFullYear()

                if(revenueDate== new Date().getDate() && revenueMonth == new Date().getMonth()+1 && revenueYear == new Date().getFullYear())
                {
                    // console.log('123123123',planDate,weekStart+i,planMonth,new Date().getMonth(),planYear,data.id)
                    if(data.userType.toLowerCase()=='social')
                    {
                        self.socialRevenue[0]+=1

                        // self.socialAccount.forEach(data2=>{
                        //     if(data.plan == data2.label)
                        //     {
                        //         data2.data[0]+=1
                        //     }
                        // })
                    }
                    else if(data.userType.toLowerCase() == 'business')
                    {
                        self.businessRevenue[0]+=1

                        // self.businessAccount.forEach(data2=>{
                        //     if(data.plan == data2.label)
                        //     {
                        //         data2.data[0]+=1
                        //     }
                        // })
                    }
                }
            })
        
            self.companiesArr.forEach(data=>{
                if(data.addone_Array){
                    data.addone_Array.forEach(adone => {
                            var companyCreateDate = new Date(adone.purchase_date).getDate()
                            var companyCreateMonth = new Date(adone.purchase_date).getMonth()+1
                            var companyCreateYear = new Date(adone.purchase_date).getFullYear()
                            if(companyCreateDate==Number(compareDate[0]) && companyCreateMonth == new Date().getMonth()+1 && companyCreateYear == new Date().getFullYear())
                            {
                                self.businessAccount.forEach(data2=>{
                                    if(adone.addon_name.toLowerCase() == data2.label.toLowerCase())
                                    {
                                        data2.data[0]+=1
                                    }
                                })
                            }
                       })  
                }
                data.plan.forEach(planData=>{
                    var companyCreateDate = new Date(planData.purchase_date).getDate()
                    var companyCreateMonth = new Date(planData.purchase_date).getMonth() + 1
                    var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                    if(companyCreateDate==Number(compareDate[0]) && companyCreateMonth == new Date().getMonth()+1  && companyCreateYear == new Date().getFullYear())
                    {
                        self.businessAccount.forEach(data2=>{
                            if(planData.plan_name.toLowerCase() == data2.label.toLowerCase())
                            {
                                data2.data[0]+=1
                            }
                        })
                    }
                })
                 
            })
        }
        // console.log('sdsdsds',self.businessAccount)
    },
    addZero(val)
    {
        if(val<10)
        {
            return '0'+val;
        }
        else
        {
            return val;
        }
    },
    filterByDate(e){
        debugger // eslint-disable-line
        var self = this;
        var fromd = new Date(self.fromDate).getDate();
        var fromm = new Date(self.fromDate).getMonth()+1;
        var fromy = new Date(self.fromDate).getFullYear();
        self.maxLimit = '';
        self.maxLimit = fromy+1+'-'+self.addZero(fromm)+'-'+self.addZero(fromd);
        // document.getElementById("max").setAttribute("max", new Date().getFullYear()+"-12-31");
        self.allDateArray = [];
        self.DatesArray = [];
        self.diffDays = 0;
        if(self.fromDate != '' && self.toDate!='')
        {
            var date1 = new Date(self.fromDate);
            var date2 = new Date(self.toDate);
            var Difference_In_Time = date2.getTime() - date1.getTime();
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            self.diffDays = Difference_In_Days  + 1;
            self.diffMonth = self.monthDiff(date1,date2) + 1
            self.DatesArray=self.getDates(date1,date2);
            self.DatesArray.forEach((datas) => {
                self.allDateArray.push(new Date(datas).getDate()+'/'+(new Date(datas).getMonth()+1)+'/'+new Date(datas).getFullYear())
            })
            // console.log('self.DatesArray',self.allDateArray);
            self.filterCountByDate()
        }
    },
    getDates(start, end) {
      for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
      }
      return arr;
    },
    monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
    },
    diff(from, to) {
        var arr = [];
        this.xaxisYear = [];
        var datFrom = new Date('1 ' + from);
        var datTo = new Date('1 ' + to);
        var fromYear =  datFrom.getFullYear();
        var toYear =  datTo.getFullYear();
        var fromD = datFrom.getDate();
        var diffYear = (12 * (toYear - fromYear)) + datTo.getMonth();

        for (var i = datFrom.getMonth(); i <= diffYear; i++) {
            arr.push(this.months[i%12]);
        }
        for (var i = datFrom.getMonth(); i <= diffYear; i++) {
            this.xaxisYear.push(this.months[i%12] + " " + Math.floor(fromYear+(i/12)));
        }         

        return arr;
    },
    filterCountByDate(){
        debugger // eslint-disable-line
            var self = this
            self.getDetails()
             self.xAxis_arr = [];
             var fromYear = new Date(self.fromDate).getFullYear();
             var toYear = new Date(self.toDate).getFullYear();
             var fromMonth = self.months[new Date(self.fromDate).getMonth()];
             var toMonth = self.months[new Date(self.toDate).getMonth()];

            //   console.log('diff.month',fromYear,fromMonth,toYear,toMonth);
                var dt1 = fromMonth+' '+fromYear;
                var dt2 = toMonth+' '+toYear;
                self.xAxis_arr=self.diff(dt1, dt2)
                console.log('diff.month', self.xAxis_arr,self.xaxisYear);
            //users
            self.users=[]
            //Accounts
            self.socialAccount=self.socialPlans
            self.businessAccount=self.businessPlans
            //Revenues
            self.socialRevenue=[]
            self.businessRevenue=[]

            for (let i = 0; i < self.xaxisYear.length; i++) {
                self.users.push(0)
                self.socialAccount.forEach(data=>{
                    data.data.push(0)
                })
                self.businessAccount.forEach(data=>{
                    data.data.push(0)
                })
                self.socialRevenue.push(0)
                self.businessRevenue.push(0)
                var compareDate =self.xaxisYear[i].split(' ');
                var index = self.months.findIndex(product => {
                    return product == compareDate[0]  
                })
                console.log('compareDate',index+1,compareDate[1]);
                self.usersDataArray.forEach(data=>{
                    
                    var userMonth = new Date(data.createdAt.seconds*1000).getMonth()+1
                    var userYear = new Date(data.createdAt.seconds*1000).getFullYear()

                    if(userMonth == index+1 && userYear ==  Number(compareDate[1]))
                    {
                        self.users[i]+=1
                        self.socialAccount.forEach(data2=>{
                            if(data.socialPlanName.toLowerCase() == data2.label.toLowerCase())
                            {
                                data2.data[i]+=1
                            }
                        })
                    }
                })

                self.revenues.forEach(data=>{
                    var revenueMonth = new Date(data.purchaseDate).getMonth()+1
                    var revenueYear = new Date(data.purchaseDate).getFullYear()
                    if(revenueMonth == index+1 && revenueYear == Number(compareDate[1]))
                    {
                        if(data.userType.toLowerCase()=='social')
                        {
                            self.socialRevenue[i]+=parseFloat(data.price)
                        }
                        else if(data.userType.toLowerCase() == 'business')
                        {
                            self.businessRevenue[i]+=parseFloat(data.price)

                        }
                    }
                })

                self.companiesArr.forEach(data=>{
                    if(data.addone_Array){
                       data.addone_Array.forEach(adone => {
                           var companyCreateMonth = new Date(adone.purchase_date).getMonth()+1
                           var companyCreateYear = new Date(adone.purchase_date).getFullYear()
                            if(companyCreateMonth == index+1 && companyCreateYear == Number(compareDate[1]))
                            {
                                self.businessAccount.forEach(data2=>{
                                    if(adone.addon_name.toLowerCase() == data2.label.toLowerCase())
                                    {
                                        data2.data[i]+=1
                                    }
                                })
                            }
                       }) 
                    }
                    data.plan.forEach(planData=>{
                        // var companyCreateDate = new Date(planData.purchase_date).getDate()
                        var companyCreateMonth = new Date(planData.purchase_date).getMonth()+1
                        var companyCreateYear = new Date(planData.purchase_date).getFullYear()
                        if(companyCreateMonth == index+1 && companyCreateYear == Number(compareDate[1]))
                        {
                            self.businessAccount.forEach(data2=>{
                                if(planData.plan_name.toLowerCase() == data2.label.toLowerCase())
                                {
                                    data2.data[i]+=1
                                }
                            })
                        }
                    })
                })
            }
            // if(self.diffMonth > 1){
            //     self.xAxis_arr = [];
            //     self.xAxis_arr=self.months
            //     //users
            //     self.users=[]
            //     //Accounts
            //     self.socialAccount=self.socialPlans
            //     self.businessAccount=self.businessPlans
            //     //Revenues
            //     self.socialRevenue=[]
            //     self.businessRevenue=[]

            //     for (let i = 0; i < 12; i++) {
            //         self.users.push(0)
            //         self.socialAccount.forEach(data=>{
            //             data.data.push(0)
            //         })
            //         self.businessAccount.forEach(data=>{
            //             data.data.push(0)
            //         })
            //         self.socialRevenue.push(0)
            //         self.businessRevenue.push(0)

            //         self.usersDataArray.forEach(data=>{
            //             var userDate = new Date(data.createdAt.seconds*1000).getDate()
            //             var userMonth = new Date(data.createdAt.seconds*1000).getMonth()+1
            //             var userYear = new Date(data.createdAt.seconds*1000).getFullYear()
            //             if(self.checkDate(userDate,userMonth,userYear))
            //             {
            //                 if(self.allDateArray.includes(userDate)){
            //                     if(userMonth == i+1 && userYear == new Date().getFullYear())
            //                     {
            //                         self.users[i]+=1
            //                         self.socialAccount.forEach(data2=>{
            //                             if(data.socialPlanName.toLowerCase() == data2.label.toLowerCase())
            //                             {
            //                                 data2.data[i]+=1
            //                             }
            //                         })
            //                     }
            //                 }
            //             }
            //         })

            //         self.revenues.forEach(data=>{
            //             var revenueDate = new Date(data.purchaseDate).getDate()
            //             var revenueMonth = new Date(data.purchaseDate).getMonth()+1
            //             var revenueYear = new Date(data.purchaseDate).getFullYear()
            //             if(self.checkDate(revenueDate,revenueMonth,revenueYear))
            //             {
            //                 if(self.allDateArray.includes(revenueDate)){
            //                     if(revenueMonth == i+1 && revenueYear == new Date().getFullYear())
            //                     {
            //                         if(data.userType.toLowerCase()=='social')
            //                         {
            //                             self.socialRevenue[i]+=parseFloat(data.price)
            //                         }
            //                         else if(data.userType.toLowerCase() == 'business')
            //                         {
            //                             self.businessRevenue[i]+=parseFloat(data.price)

            //                         }
            //                     }
            //                 }
            //             }
            //         })

            //         self.companiesArr.forEach(data=>{
            //             if(data.addone_Array){
            //                 data.addone_Array.forEach(adone => {
            //                     var companyCreateDate = new Date(adone.purchase_date).getDate()
            //                     var companyCreateMonth = new Date(adone.purchase_date).getMonth()+1
            //                     var companyCreateYear = new Date(adone.purchase_date).getFullYear()
            //                     if(self.checkDate(companyCreateDate,companyCreateMonth,companyCreateYear))
            //                     {
            //                         if(self.allDateArray.includes(companyCreateDate)){
            //                             if(companyCreateMonth == i+1 && companyCreateYear == new Date().getFullYear())
            //                             {
            //                                 self.businessAccount.forEach(data2=>{
            //                                     if(adone.addon_name.toLowerCase() == data2.label.toLowerCase())
            //                                     {
            //                                         data2.data[i]+=1
            //                                     }
            //                                 })
            //                             }
            //                         }
            //                     }
            //                 })
            //             } 
            //             data.plan.forEach(planData=>{
            //                 var companyCreateDate = new Date(planData.purchase_date).getDate()
            //                 var companyCreateMonth = new Date(planData.purchase_date).getMonth()+1
            //                 var companyCreateYear = new Date(planData.purchase_date).getFullYear()
            //                 if(self.checkDate(companyCreateDate,companyCreateMonth,companyCreateYear))
            //                 {
            //                     if(self.allDateArray.includes(companyCreateDate)){
            //                         if(companyCreateMonth == i+1 && companyCreateYear == new Date().getFullYear())
            //                         {
            //                             self.businessAccount.forEach(data2=>{
            //                                 if(planData.plan_name.toLowerCase() == data2.label.toLowerCase())
            //                                 {
            //                                     data2.data[i]+=1
            //                                 }
            //                             })
            //                         }
            //                     }
            //                 }
            //             })
            //         })
            //     }
            //     // console.log('dgdgdg',self.users,self.socialRevenue,self.businessRevenue,self.businessAccount)
            // }
            // else{
            
            // self.xAxis_arr=[]
            // //Users
            // self.users=[]
            // //Accounts
            // self.socialAccount=self.socialPlans
            // self.businessAccount=self.businessPlans
            // //Revenues
            // self.socialRevenue=[];
            // self.businessRevenue=[];
            
            // var last = new Date(new Date().getFullYear(), new Date().getMonth()+1,0).getDate()
               
            //     for (let i = 0; i <= last; i++) {
            //         self.users.push(0)
            //         self.socialAccount.forEach(data=>{
            //             data.data.push(0)
            //         })
            //         self.businessAccount.forEach(data=>{
            //             data.data.push(0)
            //         })
            //         self.socialRevenue.push(0)
            //         self.businessRevenue.push(0)
            //          var arrVal = `${self.addZero(i+1)} ${self.months[new Date().getMonth()]}`
            //          self.xAxis_arr.push(arrVal)
                   

            //         self.usersDataArray.forEach(data=>{
            //             var userDate = new Date(data.createdAt.seconds*1000).getDate()
            //             var userMonth = new Date(data.createdAt.seconds*1000).getMonth()+1
            //             var userYear = new Date(data.createdAt.seconds*1000).getFullYear()
            //             if(self.checkDate(userDate,userMonth,userYear))
            //             {
            //                 if(userDate==i && userMonth == new Date().getMonth()+1 && userYear == new Date().getFullYear())
            //                 {
            //                     self.socialAccount.forEach(data2=>{
            //                         if(data.socialPlanName.toLowerCase() == data2.label.toLowerCase())
            //                         {
            //                             data2.data[i-1]+=1
            //                         }
            //                     })
                                
            //                     self.users[i-1]+=1
            //                 }
            //             }
            //         })

            //         self.revenues.forEach(data=>{
            //             var revenueDate = new Date(data.purchaseDate).getDate()
            //             var revenueMonth = new Date(data.purchaseDate).getMonth()+1
            //             var revenueYear = new Date(data.purchaseDate).getFullYear()
            //             if(self.checkDate(revenueDate,revenueMonth,revenueYear))
            //             {
            //                 if(revenueDate==i && revenueMonth == new Date().getMonth()+1 && revenueYear == new Date().getFullYear())
            //                 {
            //                     if(data.userType.toLowerCase()=='social')
            //                     {
            //                         self.socialRevenue[i-1]+=parseFloat(data.price)

            //                     }
            //                     else if(data.userType.toLowerCase() == 'business')
            //                     {
            //                         self.businessRevenue[i-1]+=parseFloat(data.price)

            //                     }
            //                 }
            //             }
            //         })
                
            //         self.companiesArr.forEach(data=>{
            //             if(data.addone_Array){
            //                 data.addone_Array.forEach(adone=>{
            //                     var companyCreateDate = new Date(adone.purchase_date).getDate()
            //                     var companyCreateMonth = new Date(adone.purchase_date).getMonth()+1
            //                     var companyCreateYear = new Date(adone.purchase_date).getFullYear()
            //                     if(self.checkDate(companyCreateDate,companyCreateMonth,companyCreateYear))
            //                     {
            //                         if(companyCreateDate==i&& companyCreateMonth == new Date().getMonth()+1 && companyCreateYear == new Date().getFullYear())
            //                         {
            //                             self.businessAccount.forEach(data2=>{
            //                                 if(adone.addon_name.toLowerCase() == data2.label.toLowerCase())
            //                                 {
            //                                     data2.data[i-1]+=1
            //                                 }
            //                             })
            //                         }
            //                     }
            //                 })
            //             }
            //             data.plan.forEach(planData=>{
            //                 var companyCreateDate = new Date(planData.purchase_date).getDate()
            //                 var companyCreateMonth = new Date(planData.purchase_date).getMonth()+1
            //                 var companyCreateYear = new Date(planData.purchase_date).getFullYear()
            //                 if(self.checkDate(companyCreateDate,companyCreateMonth,companyCreateYear))
            //                 {
            //                     if(companyCreateDate==i&& companyCreateMonth == new Date().getMonth()+1 && companyCreateYear == new Date().getFullYear())
            //                     {
            //                         self.businessAccount.forEach(data2=>{
            //                             if(planData.plan_name.toLowerCase() == data2.label.toLowerCase())
            //                             {
            //                                 data2.data[i-1]+=1
            //                             }
            //                         })
            //                     }
            //                 }
            //             })
            //         })
            //     }
                
            
            // }
    },
    checkDate(date,month,year)
        {
            // debugger // eslint-disable-line
            var self = this;
            var valid = false;
            var userDates = date+'/'+month+'/'+year;
            // console.log('this.allDateArray',self.allDateArray,userDates);
            if(self.allDateArray.includes(userDates)){
                valid = true;
            }else{
                valid = false;
            }
            // console.log('valid',valid)
            return valid
    },
    addZero(val){
        if(val<10)
        {
            return '0'+val;
        }
        else
        {
            return val;
        }
    },
    //BACK TO DASHBOARD (Shraddha)
    backtodashboard(){
        var self = this;
        self.$root.$emit('openDashboard',0);
    }
    
 }
}
</script>
