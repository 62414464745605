<template>
    <b-modal id="create-branch" centered hide-footer title="" :no-close-on-backdrop="true" @close="resetValues()" >
     <div class="contact-modal">
            <div class="contact-modal">
            <h4>Create Branch</h4>
            <p>Give this branch a unique, descriptive name</p>
            <ValidationObserver ref="log" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(createBranch)">
                    <ValidationProvider rules="required|min:3" name="branch name" v-slot="{ errors }">
                        <div class="form-group">
                            <label>Branch Name *</label>
                            <input class="form-control" placeholder="Enter branch name" @keyup="branchName=branchName.charAt(0).toUpperCase()+branchName.substring(1),checkChrecterLengthInBranch(branchName)" maxlength="21"  name="branch name" v-model="branchName">
                            <span class="InvalidInput">{{ errors[0]=='The branch name field must be at least 3 characters'?'Minimum 3 character is required.':errors[0] }}</span>
                            <span v-if="charecterErrorMessageForBranch != '' && errors.length == 0" class="InvalidInput">{{charecterErrorMessageForBranch}}</span>
                        </div>
                        <div class="btn-wrapper">
                            <button class="btn btn-yellow" v-bind:class="{disabled: isSpinner == true}" type="submit">Create</button>
                        </div>
                    </ValidationProvider>
                </form>
            </ValidationObserver>
        </div>
        </div>
  </b-modal>
</template>
<script>
import firebase from "../../views/firebaseInit";
import firebaseTimestamp from "firebase";
import { firebasecollection } from '../../utils/firebaseCollection'
import {mapActions} from 'vuex';
const db = firebase.firestore();

export default {
    name:'createBranch',
    props: ['groupDocId','mainBranchActive','mainBranchActiveIndex','subBranchActive','subBranchActiveIndex','createBranchMessageId','mainBranches'],
    data(){
        return{
            branchName:'',
            mainbranches:this.mainBranches,
            chatObjectId:'',
            from:{},
            fromArray:[],
            sendingMessage:'',
            updateChat:{},
            image_list:[],
            isSpinner:false,
            charecterErrorMessageForBranch:'',
            connectedArray:[],
        }
    },
    // beforeDestroy(){
    //     this.$root.$off("socialBranchUser");
    // },
    created()
    {
        var self = this;
        self.$root.$on('createBranchEvent',(chatId)=>{
            self.groupDocId=chatId
            self.mainBranchActive=false
            self.mainBranchActiveIndex=0
            self.subBranchActive=false
            self.subBranchActiveIndex=0
            // console.log(self.groupDocId),
            self.createBranchMessageId=''
        })

        self.$root.$on("socialBranchUser",(value)=>{
            self.chatObjectId = value.id
        })
    },
    methods:
    {
        ...mapActions({getSetChatData:"getSetSocialChatData"}),
        // CHECK CHARECTER LIMIT IN BRANCH NAME (Shraddha)
        checkChrecterLengthInBranch(groupName){
            var self = this;
            if(groupName.length < 21){
                self.branchName = groupName
                self.charecterErrorMessageForBranch = '';
            }else{
                self.charecterErrorMessageForBranch = 'The Branch name may not be greater than 20 characters';
                self.branchName = (groupName).slice(0,20)
            }
        },
        resetValues()
        {
            this.branchName='',

            this.$refs.log.reset()
        },  
        createBranch()
        {
            // debugger // eslint-disable-line
            var self = this
            self.isSpinner = true
            // console.log(self.mainBranchActiveIndex)
            // console.log('====>',self.groupDocId,self.mainBranchActive,self.mainBranchActiveIndex,self.chatObjectId)
            // console.log('=-=-=->',self.createBranchMessageId)
            var newBranchName = true
            var userDocId = firebase.auth().currentUser.uid

            var mainBranchesData = [{
                createdAt:new Date(),
                name:self.branchName.trim(),
                subBranches:[],
                muteArray:[],
                messageSeenIds:[
                    {
                        id:userDocId,
                        count:0
                    },
                    {
                        id:self.chatObjectId,
                        count:0
                    }
                ],
                lastMsgTime :new Date(),
                flag:false
            }]


            if(self.groupDocId === undefined || self.groupDocId === ""){
                if(self.chatObjectId === "" || self.chatObjectId === undefined) {
                    return;
                }

                var messageSeenIds=[]
                messageSeenIds.push({id:userDocId,count:0})
                messageSeenIds.push({id:self.chatObjectId,count:0})
                db
                    .collection(firebasecollection.SOCIALMESSAGE)
                    .add({
                        connectedUser : [userDocId,self.chatObjectId],
                        id:'',
                        isGroup : false,
                        lastMsgTime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        title:'',
                        mainBranches:mainBranchesData,
                        muteArray:[],
                        updateChat:{},
                        msgFromBranch:false,
                        updateBranchData:{},
                        SurpriseSelfie : {},
                        ToggleSelfie : [],
                        messageSeenIds:messageSeenIds
                    })
                    .then((docRef)=>{
                        self.groupDocId=docRef.id

                        db.collection(firebasecollection.USER).doc(userDocId).collection('socialContact').doc(self.chatObjectId).update({
                            chatId:docRef.id
                        })
                        db.collection(firebasecollection.USER).doc(self.chatObjectId).collection('socialContact').doc(userDocId).update({
                            chatId:docRef.id
                        })
                        db.collection(firebasecollection.SOCIALMESSAGE).doc(docRef.id).update({
                            id : docRef.id
                        })
                        .then(()=>{
                            db
                                .collection(firebasecollection.USER)
                                .doc(self.chatObjectId)
                                .get().then((doc2=>{
                                     self.$root.$emit("audioplay");
                                     self.$notify({
                                        title:"Success",
                                        text:'Branch creation successful.',
                                        type:"success"
                                    })
                                    self.branchName=''
                                    self.$refs.log.reset()
                                    self.$bvModal.hide('create-branch')
                                    self.isSpinner = false
                                    self.getSetChatData({collection:'socialMessages', chatId:docRef.id});
                                    setTimeout(()=>{
                                        self.$root.$emit("openChat",doc2.data(),self.groupDocId,{},mainBranchesData,"default")
                                    },500)
                                }))
                                .catch(error=>
                                {console.log(error)
                                self.isSpinner = false}
                                )
                        })
                        .catch(error=>{
                            self.isSpinner = false
                            console.log(error)
                        })
                    })
                    .catch(error=>{
                        self.isSpinner = false
                        console.log(error)
                    })
            }
            else
            {
                db
                    .collection(firebasecollection.SOCIALMESSAGE)
                    .doc(self.groupDocId)
                    .get()
                    .then(doc=>{
                        var messageSeenIdsValues = []
                        self.connectedArray = [];
                        self.connectedArray = doc.data().connectedUser;
                        if(doc.exists && doc.data().mainBranches!=undefined)
                        {
                            doc.data().connectedUser.forEach(ids=>{
                                messageSeenIdsValues.push({
                                    id:ids,
                                    count:0
                                })
                            })
                            if(doc.data().mainBranches.length!=0)
                            {
                                doc.data().mainBranches.forEach(data=>{
                                    if(self.branchName.trim() == data.name)
                                    {
                                        newBranchName=false
                                        //console.log('exists!!!!!!')
                                    }
                                    if(data.subBranches){
                                        data.subBranches.forEach(data2=>{
                                            if(self.branchName.trim() == data2.name)
                                            {
                                                newBranchName=false
                                                //console.log('exists!!!!!!')
                                            }
                                            if(data2.subSubBranches){
                                                data2.subSubBranches.forEach(data3=>{
                                                    if(self.branchName.trim() == data3.name)
                                                    {
                                                        newBranchName=false
                                                        //console.log('exists!!!!!!')
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        }
                        if(newBranchName)
                        {
                            var ind = 0
                            if(!self.mainBranchActive)
                            {
                                //console.log('if')
                                db
                                    .collection(firebasecollection.SOCIALMESSAGE)
                                    .doc(self.groupDocId)
                                    .update({
                                        mainBranches:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                                            createdAt:new Date(),
                                            name:self.branchName.trim(),
                                            msgid:self.createBranchMessageId,
                                            subBranches:[],
                                            muteArray:[],
                                            messageSeenIds:messageSeenIdsValues,
                                            lastMsgTime:new Date(),
                                            flag:false
                                        })
                                    })
                                    .then(()=>{
                                        // console.log('checkMessage',self.createBranchMessageId)
                                        if(self.createBranchMessageId!= '')
                                        {
                                            db
                                                .collection(firebasecollection.SOCIALMESSAGE)
                                                .doc(self.groupDocId)
                                                .get().then((doc2)=>{
                                                    // self.mainBranches = doc2.data().mainBranches
                                                    self.sendMessage('default')
                                                })
                                                .catch(error=>{
                                                    self.isSpinner = false
                                                    console.log(error)
                                                })
                                        }
                                        else
                                        {
                                            self.$root.$emit("audioplay");
                                            self.$notify({
                                                title:"Success",
                                                text:'Branch creation successful.',
                                                type:"success"
                                            })
                                            self.isSpinner = false
                                            self.branchName=''
                                            self.$refs.log.reset()
                                            self.$bvModal.hide('create-branch')
                                        }
                                    })
                                    .catch(error=>{
                                        self.isSpinner = false
                                        self.$root.$emit("audioplay");
                                        self.$notify({
                                            title:"Error",
                                            text:error.message,
                                            type:"error"
                                        })
                                    })
                            }
                            else if(self.mainBranchActive && !self.subBranchActive)
                            {
                                //console.log('else if')
                                db
                                    .collection(firebasecollection.SOCIALMESSAGE)
                                    .doc(self.groupDocId)
                                    .get()
                                    .then(doc=>{
                                        var mainBranchesData = self.sortArray(doc.data().mainBranches)
                                        mainBranchesData[self.mainBranchActiveIndex].subBranches.push({
                                            name:self.branchName.trim(),
                                            msgid:self.createBranchMessageId,
                                            createdAt:new Date(),
                                            subSubBranches:[],
                                            muteArray:[],
                                            messageSeenIds:messageSeenIdsValues,
                                            lastMsgTime:new Date(),
                                            flag:false
                                        })
                
                                        db
                                            .collection(firebasecollection.SOCIALMESSAGE)
                                            .doc(self.groupDocId)
                                            .update({
                                                mainBranches:mainBranchesData
                                            })
                                            .then(()=>{
                                                if(self.createBranchMessageId!= '')
                                                {
                                                    db
                                                        .collection(firebasecollection.SOCIALMESSAGE)
                                                        .doc(self.groupDocId)
                                                        .get().then((doc2)=>{
                                                            // self.mainBranches = doc2.data().mainBranches
                                                            self.sendMessage(mainBranchesData[self.mainBranchActiveIndex].name)
                                                        })
                                                        .catch(error=>{
                                                            console.log(error)
                                                        })
                                                }
                                                else
                                                {
                                                    self.$root.$emit("audioplay");
                                                    self.$notify({
                                                        title:"Success",
                                                        text:'Branch creation successful.',
                                                        type:"success"
                                                    })
                                                    self.isSpinner = false
                                                    self.branchName=''
                                                    self.$refs.log.reset()
                                                    self.$bvModal.hide('create-branch')
                                                }
                                            })
                                            .catch(error=>{
                                                self.isSpinner = false
                                                 console.log(error)
                                            })
                                    })
                                    .catch(error=>{
                                        self.isSpinner = false
                                        console.log(error)
                                    })
                            }
                            else if(self.mainBranchActive && self.subBranchActive)
                            {
                                //console.log('else')
                                db
                                    .collection(firebasecollection.SOCIALMESSAGE)
                                    .doc(self.groupDocId)
                                    .get()
                                    .then(doc=>{
                                        var mainBranchesData = self.sortArray(doc.data().mainBranches)
                                        // mainBranchesData[self.mainBranchActiveIndex].subBranches = self.sortArray(mainBranchesData[self.mainBranchActiveIndex].subBranches)
                                            mainBranchesData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches.push({
                                            name:self.branchName.trim(),
                                            msgid:self.createBranchMessageId,
                                            createdAt:new Date(),
                                            muteArray:[],
                                            messageSeenIds:messageSeenIdsValues,
                                            lastMsgTime:new Date(),
                                            flag:false
                                        })
                
                                        db
                                            .collection(firebasecollection.SOCIALMESSAGE)
                                            .doc(self.groupDocId)
                                            .update({
                                                mainBranches:mainBranchesData
                                            })
                                            .then(()=>{
                                                if(self.createBranchMessageId!= '')
                                                {
                                                    db
                                                        .collection(firebasecollection.SOCIALMESSAGE)
                                                        .doc(self.groupDocId)
                                                        .get().then((doc2)=>{
                                                            // self.mainBranches = doc2.data().mainBranches
                                                            self.sendMessage(mainBranchesData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name)
                                                        })
                                                        .catch(error=>{
                                                            console.log(error)
                                                        })
                                                }
                                                else
                                                {
                                                    self.isSpinner = false
                                                    self.$root.$emit("audioplay");
                                                    self.$notify({
                                                        title:"Success",
                                                        text:'Branch creation successful.',
                                                        type:"success"
                                                    })
                                                    self.branchName=''
                                                    self.$refs.log.reset()
                                                    self.$bvModal.hide('create-branch')
                                                }
                                            })
                                            .catch(error=>{
                                                self.isSpinner = false
                                                console.log(error)
                                            })
                                    })
                                    .catch(error=>{
                                        self.isSpinner = false
                                        console.log(error)
                                    })
                            }
                        }
                        else
                        {
                            self.isSpinner = false
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Error",
                                text:'Branch name already used.',
                                type:'error'
                            })
                            self.branchName=''
                            self.$refs.$log.reset()
                        }
                    })
                    .catch(error=>{console.log(error)
                        self.isSpinner = false
                    })
            }
        },
        sendMessage(collectionName){
            debugger // eslint-disable-line
            var self = this;
            self.$refs.log.reset()
            self.$bvModal.hide('create-branch')
            self.$root.$emit('branchOpen',self.branchName);
            self.from = {}
            self.fromArray = []
            // self.sendingMessage = self.sendingMessage.trim()
            
            //console.log('connected userid',self.chatObject.id,self.chatObjectId,self.userid,self.sendingMessage,self.image_list)
            //check images atachments
            db.collection(firebasecollection.SOCIALMESSAGE)
                .doc(self.groupDocId)
                .collection(collectionName)
                .doc(self.createBranchMessageId)
                .update({
                    branch:self.branchName.trim(),
                })
                .then(()=>{
                    db
                        .collection(firebasecollection.SOCIALMESSAGE)
                        .doc(self.groupDocId)
                        .collection(collectionName)
                        .doc(self.createBranchMessageId)
                        .get()
                        .then(doc=>{
                            self.sendingMessage=doc.data()
                            self.sendingMessage.from.path=self.branchName.trim()
                            self.sendingMessage.branch=''
                            self.sendingMessage.from.id=firebase.auth().currentUser.uid
                            for (let i = 0; i < self.sendingMessage.messageSeen.length; i++) {
                                if(self.sendingMessage.messageSeen[i].id!=firebase.auth().currentUser.uid)
                                {
                                    self.sendingMessage.messageSeen.splice(i,1)
                                }
                            }
                            if(self.sendingMessage.surpriseSelfieUsers && self.sendingMessage.surpriseSelfieUsers.length > 0){
                                self.sendingMessage.surpriseSelfieUsers = self.connectedArray;
                            }

                            // console.log('Found Message')
                            db
                                .collection(firebasecollection.SOCIALMESSAGE)
                                .doc(self.groupDocId)
                                .collection(self.branchName.trim())
                                .add(self.sendingMessage)
                                .then(docRef=>{
                                    // console.log('Added Message')
                                    db
                                        .collection(firebasecollection.SOCIALMESSAGE)
                                        .doc(self.groupDocId)
                                        .collection(self.branchName.trim())
                                        .doc(docRef.id)
                                        .update({
                                            id:docRef.id
                                        })
                                        .then(()=>{
                                            // console.log('Update Message Id')
                                            self.mainBranches.forEach(data=>{
                                                if(data.name == self.branchName.trim())
                                                {
                                                    data.messageSeenIds.forEach(ids=>{
                                                        if(ids.id!= firebase.auth().currentUser.uid)
                                                        {
                                                            ids.count++
                                                        }
                                                    })
                                                }
                                                else
                                                {
                                                    if(data.subBranches){
                                                        data.subBranches.forEach(data2=>{
                                                            if(data2.name == self.branchName.trim())
                                                            {
                                                                data2.messageSeenIds.forEach(ids2=>{
                                                                    if(ids2.id!= firebase.auth().currentUser.uid)
                                                                    {
                                                                        ids2.count++
                                                                    }
                                                                })
                                                            }
                                                            else
                                                            {
                                                                if(data2.subSubBranches){
                                                                    data2.subSubBranches.forEach(data3=>{
                                                                        if(data3.name == self.branchName.trim())
                                                                        {
                                                                            data3.messageSeenIds.forEach(ids3=>{
                                                                                if(ids3.id!= firebase.auth().currentUser.uid)
                                                                                {
                                                                                    ids3.count++
                                                                                }
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        })
                                                    }
                                               }
                                            })
                                            // console.log("messageCounts",self.mainBranches)
                                            self.from = {
                                                avtar:self.sendingMessage.from.avtar,
                                                id:self.sendingMessage.from.id,
                                                name:self.sendingMessage.from.name,
                                                path:self.branchName.trim(),
                                            }
                                            self.fromArray.push(self.from);
                                            var updateBranchData = {
                                                from:self.from,
                                                msg:self.sendingMessage.msg || '',
                                                type:self.sendingMessage.type,
                                                name:self.sendingMessage.name || ''
                                            }
                                            db
                                                .collection(firebasecollection.SOCIALMESSAGE)
                                                .doc(self.groupDocId)
                                                .update({
                                                    lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    updateBranchData:updateBranchData,
                                                    msgFromBranch:true,
                                                    mainBranches:self.mainBranches
                                                })
                                                .then(()=>{
                                                    // console.log('update Chat')
                                                    self.$root.$emit("audioplay");
                                                    self.$notify({
                                                        title:"Success",
                                                        text:'Branch creation successful.',
                                                        type:"success"
                                                    })
                                                    // self.$refs.log.reset()
                                                    self.isSpinner = false
                                                    // self.$bvModal.hide('create-branch')
                                                    // self.$root.$emit('branchOpen',self.branchName)
                                                    self.branchName=''
                                                })
                                                .catch(error=>{
                                                    console.log(error)
                                                    self.isSpinner = false
                                                })
                                        })
                                        .catch(error=>{
                                            console.log(error)
                                            self.isSpinner = false
                                        })
                                })
                        })
                        .catch(error=>{
                            console.log(error)
                            self.isSpinner = false
                        })
                })
                .catch(error=>{
                    console.log(error)
                    self.isSpinner = false
                })

        },
        sortArray(mainBranchArray)
        {
        //console.log('sortCalled')
        // console.log('arr',mainBranchArray)

        var tmp = mainBranchArray
        var tmp2 = ''

        for (let index = 0; index < tmp.length; index++) {
            for (let j = index+1; j < tmp.length; j++) {
            if(tmp[index].lastMsgTime.seconds < tmp[j].lastMsgTime.seconds)
            {
                tmp2 = tmp[index]
                tmp[index] = tmp[j]
                tmp[j]=tmp2
            }
            }
        }
        return tmp
        },
    }
}
</script>