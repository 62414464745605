import Vue from 'vue'
import firebase from "../views/firebaseInit";
const db = firebase.firestore();
var user = localStorage.getItem("customerDocKey");

export const getMediaData = function(COMPANYID,SOCIALMESSAGE,collectionName,MediaDataArray,chatId,cb)
{
    db.collection(COMPANYID).doc(COMPANYID).collection(SOCIALMESSAGE).doc(chatId).collection(collectionName).orderBy('createdAt','desc').onSnapshot(querySnapshot => {
        MediaDataArray = []
        querySnapshot.forEach(doc => {
          MediaDataArray.push(doc.data())
        })
        cb(MediaDataArray)
    })
   
}

export const getCountFunction = function(MediaArray,AllMediaData,cb)
{
    let count = 0;
    AllMediaData = []
    const getrequestDataFunction = (row) => {
        if(count >= MediaArray.length){
            cb(AllMediaData)
            return;
        }
        
        if(row.type == 'pdf' || row.type == 'excel' || row.type == 'word'){
            let imageName = row.name.split('_');
            AllMediaData.push({
                'media' : row.mediaUrl,
                'type' : row.type,
                'imagename':row.name,
                'imageOnlyname':imageName[0],
                'name':row.from.name,
                'createdAt':row.createdAt ? (new Date(row.createdAt.seconds * 1000).getDate()+'/'+ [(new Date(row.createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(row.createdAt.seconds * 1000).getFullYear()) :'',
                'createDate':row.createdAt ? new Date(row.createdAt.seconds * 1000) :'',
                'lastWeekStartDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                'lastWeekEndDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                'lastMonthDate' : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30) 
            })
        }

        count += 1;
        getrequestDataFunction(MediaArray[count]);
    }
    // cb(AllMediaData)   
    getrequestDataFunction(MediaArray[count]); 
}
export const getMediaImages = function(MediaArray,mediaImages,cb)
{
    let count = 0;
    mediaImages = []
    const getrequestDataFunction = (row) => {
        if(count >= MediaArray.length){
            cb(mediaImages)
            return;
        }
        
        if(row.type == 'Image' || row.type == 'Video'){
            mediaImages.push({
                'media' : row.mediaUrl,
                'type' : row.type,
                'name':row.from.name,
                'imagename':row.name,
                'createdAt':row.createdAt ? new Date(row.createdAt.seconds * 1000) :'',
                'lastWeekStartDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                'lastWeekEndDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                'lastMonthDate' : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30)
            })
        }

        count += 1;
        getrequestDataFunction(MediaArray[count]);
    }
    // cb(mediaImages)   
    getrequestDataFunction(MediaArray[count]); 
}
export const getLinkData = function(MediaArray,cb){
    let count = 0;
    var mediaImages = []
    const getrequestDataFunction = (row) => {
        if(count >= MediaArray.length){
            cb(mediaImages)
            return;
        }
        
        if(row.type == 'Link'){
            mediaImages.push({
                'media' : row.msg,
                'type' : row.type,
                'name':row.from.name,
                'imagename':row.name,
                'createdAt':row.createdAt ? new Date(row.createdAt.seconds * 1000) :'',
                'lastWeekStartDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                'lastWeekEndDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                'lastMonthDate':new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
                'createDate':row.createdAt ? (new Date(row.createdAt.seconds * 1000).getDate()+'/'+ [(new Date(row.createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(row.createdAt.seconds * 1000).getFullYear()) :'',
            })
        }

        count += 1;
        getrequestDataFunction(MediaArray[count]);
    }
    // cb(mediaImages)   
    getrequestDataFunction(MediaArray[count]); 
}

export const getRecentData = function(COMPANYID,SOCIALMESSAGE,collectionName,recentMediaData,chatId,cb)
{
    db.collection(COMPANYID).doc(COMPANYID).collection(SOCIALMESSAGE).doc(chatId).collection(collectionName).where('type','in',['Image','Video']).limit(5).onSnapshot(querySnapshot => {
        recentMediaData = []
        querySnapshot.forEach(doc => {
            recentMediaData.push(doc.data())
        })
        cb(recentMediaData)
    })
}

export const getRecentThreeData = function(COMPANYID,SOCIALMESSAGE,collectionName,recentThreeData,chatId,cb)
{
    db.collection(COMPANYID).doc(COMPANYID).collection(SOCIALMESSAGE).doc(chatId).collection(collectionName).where('type','in',['Image','Video']).onSnapshot(querySnapshot => {
        recentThreeData = []
        querySnapshot.forEach(doc => {
            recentThreeData.push(doc.data())
        })
        cb(recentThreeData)
    })
}
export const getRecentThreeLink = function(COMPANYID,SOCIALMESSAGE,collectionName,chatId,cb)
{
    db.collection(COMPANYID).doc(COMPANYID).collection(SOCIALMESSAGE).doc(chatId).collection(collectionName).where('type','==','Link').onSnapshot(querySnapshot => {
        var recentThreeData = []
        querySnapshot.forEach(doc => {
            recentThreeData.push({
                'media' : doc.data().msg,
                'type' : doc.data().type,
                'name':doc.data().from.name,
                'imagename':doc.data().name,
                'createdAt':doc.data().createdAt  ? new Date(doc.data().createdAt.seconds * 1000) :'',
                'lastWeekStartDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                'lastWeekEndDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                'lastMonthDate' : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
                'createDate':doc.data().createdAt ? (new Date(doc.data().createdAt.seconds * 1000).getDate()+'/'+ [(new Date(doc.data().createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(doc.data().createdAt.seconds * 1000).getFullYear()) :'',
            })
        })
        cb(recentThreeData)
    })
}

export const exitGroup = function(User,connectedUser,cb){
    //console.log('connectedUser',connectedUser,User);
    let count = 0;
    const getrequestDataFunction = (row) => {
        if(count >= connectedUser.length){
            return;
        }
        var invIndex = connectedUser.findIndex(item => {
            return item === User 
        })
        if(invIndex != -1){
            connectedUser.splice(invIndex,1)
        }
       
        count += 1;
        getrequestDataFunction(connectedUser[count]);
    }

    getrequestDataFunction(connectedUser[count]);
    cb(connectedUser)    
}

export const getgroupmember = function(USER,connectedUser,groupMember,cb){
    let count = 0;
    groupMember = []
    const getrequestDataFunction = (row) => {
        if(count >= connectedUser.length){
            cb(groupMember)
            return;
        }
        const key = row
        db.collection(USER).doc(key).get().then((snap) => {
            groupMember.push(snap.data());
        })
        //console.log('groupMembergroupMember',groupMember)
        count += 1;
        getrequestDataFunction(connectedUser[count]);
    }
    cb(groupMember)  
    getrequestDataFunction(connectedUser[count]); 
   
}

export const getRecentThreedocument = function(COMPANYID,SOCIALMESSAGE,collectionName,recentThreeData,chatId,cb){
    db.collection(COMPANYID).doc(COMPANYID).collection(SOCIALMESSAGE).doc(chatId).collection(collectionName).where('type','in',['pdf','excel','word']).onSnapshot(querySnapshot => {
        recentThreeData = []
        querySnapshot.forEach(doc => {
            let imageName = doc.data().name.split('_');
            recentThreeData.push({
                'media' : doc.data().mediaUrl,
                'type' : doc.data().type,
                'imagename':doc.data().name,
                'imageOnlyname':imageName[0],
                'name':doc.data().from.name,
                'createdAt':doc.data().createdAt ? (new Date(doc.data().createdAt.seconds * 1000).getDate()+'/'+ [(new Date(doc.data().createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(doc.data().createdAt.seconds * 1000).getFullYear()) :'',
                'createDate':doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000) :'',
                'lastWeekStartDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                'lastWeekEndDate':new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                'lastMonthDate' : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30) 
            })
        })
        cb(recentThreeData)
    })
}

export const blockgetdata = function(USER,userid,companyId,cb){
    // debugger // eslint-disable-line
    db.collection(USER).doc(userid).collection(companyId+'_businessBlockUser').onSnapshot(querySnapshot => {
        var blockUserId = []
        querySnapshot.forEach(doc=>{
            if(doc.exists){
                blockUserId.push(doc.data())
            }
        })
       cb(blockUserId) 
    })
}
export const blockList = function(blockUserId,USER,cb){
    // debugger // eslint-disable-line
        let counting = 0;
         var blockUserList = []
         const getrequestDataFunction = (row) => {
              if(counting >= blockUserId.length){
                  return;
              }
              const key = row.id;
              db.collection(USER).doc(key).get().then(docdata => {
                  blockUserList.push(docdata.data())
              })
              
              counting += 1;
              getrequestDataFunction(blockUserId[counting]);
          }
       
        setTimeout(()=>{
            cb(blockUserList)
        },500)
        getrequestDataFunction(blockUserId[counting]); 
       
}

export const getPlanUserData = function(COMPANY,COMPANYID,cb){
    var companyData = []
    db.collection(COMPANY).doc(COMPANYID).onSnapshot(doc => {
        if(doc.exists){
            companyData = [];
            for(var i=0; i<doc.data().users.length;i++){
                companyData.push({
                    id:doc.data().users[i].id,
                    name:doc.data().users[i].name,
                    planId:doc.data().users[i].planId,
                    planName:doc.data().users[i].planName,
                    profileUrl:doc.data().users[i].profileUrl,
                    status:doc.data().users[i].status,
                    email:doc.data().users[i].email,
                    phone:doc.data().users[i].phone,
                    randomecode:doc.data().users[i].randomecode || '',
                    // next_billing_date:doc.data().users[i].next_billing_date,
                    selectplanOption:{},
                    dataUsage:doc.data().users[i].dataUsage,
                    selfieTime:new Date(),
                    max_file_size:'',
                    noOfSelfie:doc.data().users[i].noOfSelfie,
                    selfieUsers:[],
                    usedSelfie:0,
                    userUsageLimit:doc.data().users[i].userUsageLimit,
                    error:'',
                    device_plan:doc.data().users[i].device_plan
                })
            }
           
            cb(companyData)
        }
    })
}

