<template>
  <div class="header">
    <nav class="navbar navbar-expand-md">
      <a class="navbar-brand" href="#" @click="closeDropDownHeader()" >
        <router-link to="/chat"><img src="../assets/images/logo.png" /></router-link>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <div class="afterlogin-part" @click="closeDropDownHeader()">
          <ul :style="!activeChat && !activeGroup?'float:right':''" class="navbar-nav left">
            <li class="nav-item dropdown user_dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbardrop"
              data-toggle="dropdown"
              :style="!activeChat && !activeGroup?'padding: 4px 25px;':''"
            >
              <img :src="imageUrl.profileUrl" v-if="imageUrl.profileUrl" :style="!activeChat && !activeGroup?'left: 0px;':''" />
              <!-- <span v-else>{{imageUrl.firstName.charAt(0).toUpperCase()}}</span> -->
              <div>
                
              <b v-if="!activeChat && !activeGroup">{{imageUrl.firstName}} {{imageUrl.lastName}}</b>
              <span v-if="!activeChat && !activeGroup">plan : {{userCurrentPlan}}</span>
              </div>
             
            </a>
            <div class="dropdown-menu" @click="setBusinessMenu(businessMenuActive)">
              <router-link class="dropdown-item" v-bind:to="{ name: 'myprofile', params: { id: loginCustomeId }}">My Profile</router-link>
              <router-link class="dropdown-item" to="/generalsettings" >Account Settings</router-link>
              <router-link class="dropdown-item" to="/feedback">Give Feedback</router-link>
              <a class="dropdown-item" @click.prevent="Logout()">Logout</a>
            </div>
          </li>
          <li class="nav-item" v-if="activeGroup == false && chatObject.id">
            <div class="chat-img" :class="{'hideAfterLineInHeader': botUserEmail == chatObject.email}">
              <img :src="chatObject.profileUrl" v-if="chatObject.profileUrl != ''">
              <span class="profilecharecter" v-if="chatObject.profileUrl == ''">{{chatObject.firstName.charAt(0).toUpperCase()}}</span>
              <span v-if="(chatObject.firstName.length + chatObject.lastName.length) <= 15">{{chatObject.firstName}} {{chatObject.lastName}}</span>
              <span :title="(chatObject.firstName + chatObject.lastName)" v-else>{{(chatObject.firstName + chatObject.lastName).slice(0, 15)+'...'}}</span>
            </div>
          </li>
           <li class="nav-item" v-if="activeGroup == true && groupData.id">
            <div class="chat-img">
              <img :src="groupData.profileUrl" v-if="groupData.profileUrl != ''">
              <span v-if="groupData.profileUrl == '' " class="profilecharecter">{{groupData.title.charAt(0).toUpperCase()}}</span>
              <span v-if="groupData.title.length  <= 15">{{groupData.title}}</span>
              <span :title="groupData.title" v-else>{{(groupData.title).slice(0, 15)+'...'}}</span>
           </div>
          </li>
          <li class="nav-item view-detail-link" v-show="activeGroup && groupData.id" >
            <a href="#" v-b-toggle.sidebar-right class="nav-link">
              <img src="@/assets/images/view_detail-orange.png" class="orange_icon_header" v-if="businessMenuActive==false">
              <img src="@/assets/images/view_detail-blue.png" class="blue_icon_header" v-else>
            </a>
          </li>
          <li class="nav-item dropdown sidebar_dropdown" v-show="activeChat && chatObject && !activeGroup" :class="{'hideFooterForDooeyAdmin': botUserEmail == chatObject.email}">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbardrop3"
              data-toggle="dropdown"
            >
              <img src="@/assets/images/view_detail-orange.png" class="orange_icon_header" v-if="businessMenuActive==false">
              <img src="@/assets/images/view_detail-blue.png" class="blue_icon_header" v-else>
            </a>
            <div class="dropdown-menu user_detail_dd" >
              <router-link :class="{disabled:botUserEmail == chatObject.email}" class="dropdown-item" :to="{ name: 'userprofile', params: { id: chatObject.id }}">View Profile</router-link>
              <label :class="{disabled:botUserEmail == chatObject.email}" class="dropdown-item" v-b-toggle.sidebar-right>View Details</label>
            </div>
          </li>
          </ul>
      </div>

      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav">
         
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbardrop"
              data-toggle="dropdown"
            >
              <img src="../assets/images/profile.png" />
              <div><b>Manuel Storie</b><span>Plan : Social Pro</span></div>
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#">My Profile</a>
              <a class="dropdown-item" href="#">General Settings</a>
              <a class="dropdown-item" href="#">Give Feedback</a>
              <a class="dropdown-item" href="#">Logout</a>
            </div>
          </li> -->
          
          <li class="nav-item"  v-if="activeChat && chatObject ">
            <div class="header-serch">
              <form class="serch-from" @submit.prevent>
                <li class="nav-item dropdown" :class="{'opendropdown' :showSearchList }">
                  <div class="select"  :class="{'dropdownimg' : Mainsearchkeyword != 'All'}">
                    <label class=""  @click="searchClick()"> {{Mainsearchkeyword}} </label>
                    <img  @click="searchClick()" src="@/assets/images/down-arrow.png" style="cursor : pointer;" />
                    <div v-if="showSearchList" class="medialink_drop-down">
                        <ul>
                          <li v-for="(searchData,index) in  searchArray" v-bind:key="index"  @click="addInSearchBar(searchData.title)">
                            {{searchData.title}}
                          </li>
                        </ul>
                    </div>
                    <button type="button" v-if="closeSearchList && Mainsearchkeyword != 'All'" @click="closeSearchBar()">X</button>
                  </div>
                </li>
            <div class="form-group">
              <input type="serch" class="form-control" placeholder="Search" v-model="chatkeyword"  @keyup="searchFromMessage()">
            </div>
              <img src="../assets/images/search-icon.png" title="Find" class="search">
              </form>
            </div>
          </li>
          <li class="nav-item" v-if="activeGroup == true" @click="closeDropDownHeader()">
            <div class="member-header">
                <label>Members:</label>
                <ul class="member-list">
                  <div class="group_exit_member">
                  <li v-if="oldGroupMember.length != 0" v-b-modal.member><img class="memberprofile" :src="oldGroupMember[0].profileUrl"></li>
                  <li  v-if="oldGroupMember.length > 1" v-b-modal.member><img class="memberprofile" :src="oldGroupMember[1].profileUrl"></li> 
                  <li v-if="oldGroupMember.length > 2" v-b-modal.member><img class="memberprofile" :src="oldGroupMember[2].profileUrl"></li>
                  <li v-if="oldGroupMember.length > 3"><span class="viewall_member" id="vm" href="#" tabindex="0" v-b-modal.member>+{{oldGroupMember.length-3}}</span></li>
                  </div>
                  <li v-if="admin == true && businessMenuActive==false"><span  class="add-member" id="am" href="#" tabindex="0" v-b-modal.my-modal><img src="../assets/images/add-member-header.png"></span></li>
                  <!-- <li v-if="admin == true && businessMenuActive==true"><span class="add-member" id="adminmember" href="#" tabindex="0" v-b-modal.my-modal><img src="../assets/images/A_plusicon.png"></span></li> -->
                </ul>
              <b-tooltip target="vm" class="tooltip b-tooltip bs-tooltip-bottom bs-tooltip-bottom-docs" title="Tooltip title" triggers="hover">
               View all {{oldGroupMember.length-3}} members
              </b-tooltip>
              <b-tooltip target="am" class="tooltip b-tooltip bs-tooltip-bottom bs-tooltip-bottom-docs add_member_tooltip" title="Tooltip title" triggers="hover" style="#000!important">
               Add Members
              </b-tooltip>
            </div>
          </li>
          <li class="nav-item" @click="closeDropDownHeader()">
            <a class="nav-link" href="#">
              <router-link class="dropdown-item no-hovercolor" to="/Accountsettings" ><img src="../assets/images/setting.png" title="General setting" /></router-link>
            </a>
          </li>
          <li class="nav-item version" @click="closeDropDownHeader()"><b>v.1.0.18</b></li>
        </ul>
      </div>
    </nav>

    <!-- Modal VIewDetail -->

      <b-modal id="modal-md" hide-footer centered :no-close-on-backdrop="true" >
        <div class="view-detail-content">
          <div class="photoupload-wrapper">
            <img v-if="preview" :src="preview" class="img-fluid" />
              <img v-else src="@/assets/images/noupload.png">
              <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file">
          </div>
          <div class="form-text-viewdetail">
              <form>
                <div class="form-group">
                    <label>Chat Name *</label>
                    <input type="text" class="form-control" placeholder="Friends" value="">
                </div>
              </form>
              <div class="chat-created-info">
              <p><span>Created by :</span> <span>Manuel Storie</span></p>
              <p><span>Created on :</span> <span>26 March 2021</span></p>
              </div>
              <div class="btn-wrapper"><button class="btn btn-white" @click="$bvModal.hide('modal-md')">Cancel</button><button class="btn btn-yellow">Save</button></div>
          </div>
        </div>
      </b-modal>

    <!-- Modal viewDetail End -->

    <!-- Add Member Modal -->
    <b-modal id="my-modal" centered hide-footer :no-close-on-backdrop="true" @close="closeFire()">
      <div class="add-member-content contact-modal">
          <h4>Add Members</h4>
          <form class="modal-search" @submit.prevent>
              <div class="form-group">
                <input type="search" class="form-control" placeholder="Search member" v-model="keyword" @keyup="searchFilter()">
                <img src="../assets/images/search.png" title="Find" class="search-icon">
              </div>
          </form>
          <ul class="modal-member-list">
            <li v-for="(newmember,index) in getArrOfNewUsers(NewGroupMember)" v-bind:key="index">
              <div class="ml-item">
                <img :src="newmember.profileUrl" class="member-img">
                <span>{{newmember.firstName}} {{newmember.lastName}}</span>
                <button class="btn btn-white" @click="addMemberInGroup(newmember,index),$bvModal.hide('my-modal')" v-if="admin == true">Add</button>
              </div>
            </li>
            <span class="member-list-span" v-if="nouserFoundMessage != '' ">{{nouserFoundMessage}}</span>
          </ul>
           
      </div>
    </b-modal>
    <!-- Add Member Modal End -->

    <!-- Add Member Modal -->
    <b-modal id="member" centered hide-footer :no-close-on-backdrop="true">
      <div class="add-member-content contact-modal">
          <h4>Members</h4>
          <ul class="modal-member-list">
            <li v-for="(oldmemeber,index) in oldGroupMember" v-bind:key="index">
              <div class="ml-item">
                <img :src="oldmemeber.profileUrl" class="member-img">
                <span>{{oldmemeber.firstName}} {{oldmemeber.lastName}}</span>
                <button class="btn btn-white" @click="removeGroupMemberConfirmation(oldmemeber,index),$bvModal.hide('member')" v-if="admin==true">Remove</button>
              </div>
            </li>
          </ul>
      </div>
    </b-modal>
    <!-- Add Member Modal End -->
  
  <Sidebar v-if="activeGroup == true"   :activeGroup = activeGroup  :groupData ="groupData"  ></Sidebar>
  <Sidebar v-else :chatObject= chatObject :activeGroup = activeGroup ></Sidebar>
  </div>
</template>

<script>

import Sidebar from "@/components/sidebar.vue";
import firebase from '../views/firebaseInit';
import firebaseData from '../utils/firebaseCollection.js';
import firebaseTimestamp from "firebase";
import {getnewmember,getoldmember,getLoginUserData} from '../utils/getQueeryActiveChatList.js'
import Swal from 'sweetalert2'
import Vue from 'vue'
import { mapActions,mapGetters } from "vuex";
const db = firebase.firestore()
export default {
  name: 'headerpage',
  components:{
    Sidebar,
    firebaseData,
  },
  props: [
    // "imageUrl",
    "chatObject",
    "activeGroup",
    // "groupData",
    "activeChat",
  ],
  data(){
    return{
      imageUrl:'',
      preview: null,
      image: null,
      preview_list: [],
      image_list: [],
      loginCustomeId:'',
      groupMember :[],
      NewGroupMemberId : [],
      NewGroupMember:[],
      groupChatId : '',
      oldGroupMember:[],
      keyword:'' ,
      chatkeyword:'',
      admin:false,
      businessMenuActive:false,
      nouserFoundMessage:'',
      newArrayForNewMember:[],

      adminName:'',
      state:'close',
      userCurrentPlan : '',
      searchArray: [
        { title: "All" },
        { title: "Photos" },
        { title: "Videos" },
        { title: "Links" },
        { title: "Documents" }
      ],
      Mainsearchkeyword:'All',
      showSearchList:false,
      closeSearchList:false,
      botUserEmail:'',
    }
  },
  created(){
    // debugger // eslint-disable-line
    var self=this;
    self.botUserEmail = Vue.prototype.$email;
    self.$root.$on("clerarMainSearchKeyword",() => {
      self.Mainsearchkeyword = 'All';
      self.chatkeyword = '';
    })
    // console.log("CreatedCalled")
    self.$root.$on("closeSidebar",() => {
      self.showSearchList = false;
    })
    self.$root.$on('signOut',() => {
    //   debugger // eslint-disable-line
      self.Logout();
    })
    setTimeout(() => {
      self.loginCustomeId = firebase.auth().currentUser.uid
      getLoginUserData(firebaseData.firebasecollection.USER,(res) => {
        self.imageUrl = {
          profileUrl : res.profileUrl === "" ? Vue.prototype.$defaultProfile : res.profileUrl,
          firstName : res.firstName,
          lastName : res.lastName
        };
        // console.log('this.imageUrl',this.imageUrl);
      })
      self.acseptUserData=[];

      db.collection(firebaseData.firebasecollection.USER).doc(self.loginCustomeId).collection('socialPlan').doc(self.loginCustomeId).get().then(doc=>{
        self.userCurrentPlan = doc.data().plan_name
        // console.log(' self.userCurrentPlan', self.userCurrentPlan)
      })

      // //console.log('useruid',user)
      db.collection(firebaseData.firebasecollection.USER).doc(self.loginCustomeId).collection('socialContact').onSnapshot((querySnapshot) => {
        querySnapshot.forEach ((docdata) => {
            self.acseptUserData.push(docdata.data().id)
        })
      })
    },300)


    //emited when group user is removed from sidebar
    self.$root.$on("memberRemovedfromSidebar",(connectedUser)=>{
      // console.log("Group member updated from header")
      if(self.groupData.connectedUser != undefined){
        self.groupMember = self.groupData.connectedUser
        self.NewGroupMemberId = [];
        self.acseptUserData.forEach(value => {
          if(!self.groupMember.includes(value)) {
            self.NewGroupMemberId.push(value);
          }
        });
      }
      // console.log('self.NewGroupMemberId',self.NewGroupMemberId)
      getnewmember(firebaseData.firebasecollection.USER,self.NewGroupMemberId,self.loginCustomeId,res=>{
        self.NewGroupMember = [];
        self.NewGroupMember = res

        for (let i = 0; i < self.NewGroupMember.length; i++) {
          for (let j = i+1; j < self.NewGroupMember.length; j++) {
            if(self.NewGroupMember[i]==self.NewGroupMember[j])
            {
              self.NewGroupMember.splice(j,1)
            } 
          }
        }
        self.newArrayForNewMember = self.NewGroupMember
      })
      
      // console.log('self.NewGroupMember',self.NewGroupMember)
      self.oldGroupMember = []
        getoldmember(firebaseData.firebasecollection.USER,self.groupMember,self.loginCustomeId,res=>{
        self.oldGroupMember = res
      })
    })

    //Hide show components
    self.$root.$on("hideViewDetail",(objectId,chatId)=>{
      //  //debugger // eslint-disable-line
       //console.log(objectId,chatId)
       self.chatObject.id = "";
      //  self.groupData.id = "";
    })
    self.$root.$on("businessMenuActive",(value,activeChat)=>{
      self.businessMenuActive = value;
      // self.activeChat = activeChat
      self.chatObject.id = "";
      //  self.groupData.id = "";
    })
    self.$root.$on("socialMenuActive",(value,activeChat)=>{
      self.businessMenuActive = value;
      // self.activeChat = activeChat
      self.chatObject.id = "";
      // self.groupData.id = "";
      self.init();
    })
    
    self.$root.$on('resetSearchType',()=>{
      self.Mainsearchkeyword='All'
    })

    self.init();
    self.checkUserExistsInGroupOrChat();
  },
  watch: {
    $route: function () {
      this.init();
    }
  },
  methods: {
     ...mapActions({setUserStoreData:"setUserData"}),
     //Shiv Joshi
    closeDropDownHeader(){
      // debugger // eslint-disable-line
      var self = this;
      self.showSearchList = false;
    },
    searchChatType()
     {
    //   debugger // eslint-disable-line
      this.$root.$emit('searchByTypeValue',this.Mainsearchkeyword)
    },
    getArrOfNewUsers(NewGroupMember)
     {
      // console.log('ABCD',NewGroupMember)
      var tmp = NewGroupMember
      for (let i = 0; i <tmp.length; i++)
      {
        for (let j = i+1; j < tmp.length; j++) {
          if(tmp[i].id==tmp[j].id)
          {
            tmp.splice(j,1)
          }
        }
      }
      // console.log('ABCD2',tmp)
      return tmp
    },
     // FOR ADD NEW GROUP MEMBER AND REMOVE OLD GROUP MEMBER(Shraddha)
    init(){
      var self=this;
      self.groupMember= [];
      self.NewGroupMember = []

      firebase.auth().onAuthStateChanged(user=>{
        if(user!=null)
        {
          // console.log(user)
          db
            .collection(firebaseData.firebasecollection.USER)
            .doc(user.uid)
            .update({
              isOnline:true
            })
            .catch(error=>{
              //console.log(error)
            })
        }
        // else
        // {
        //   db
        //     .collection(firebaseData.firebasecollection.USER)
        //     .doc(user.uid)
        //     .update({
        //       isOnline:false
        //     })
        //     .catch(error=>{
        //       console.log(error)
        //     })
        // }
      })
      self.$root.$on("groupArray",(collectionName,value,id)=>{
        self.loginCustomeId = localStorage.getItem("customerDocKey");
        // //debugger // eslint-disable-line
        if(value.adminId == self.loginCustomeId){
            self.admin = true
        }
        const db = firebase.firestore();
        self.groupMember = value.connectedUser
        self.groupChatId = value.id
        //console.log('self.groupMember',self.groupMember,self.acseptUserData)
        self.NewGroupMemberId = [];
        if(self.groupMember != undefined){
          self.acseptUserData.forEach(value => {
            if(!self.groupMember.includes(value)) {
              self.NewGroupMemberId.push(value);
            }
          });
        }
        //console.log('self.NewGroupMemberId',self.NewGroupMemberId)
        
        getnewmember(firebaseData.firebasecollection.USER,self.NewGroupMemberId,self.loginCustomeId,res=>{
          self.NewGroupMember = [];
          self.NewGroupMember = res

          for (let i = 0; i < self.NewGroupMember.length; i++) {
            for (let j = i+1; j < self.NewGroupMember.length; j++) {
             if(self.NewGroupMember[i]==self.NewGroupMember[j])
             {
               self.NewGroupMember.splice(j,1)
             } 
            }
          }
          self.newArrayForNewMember = self.NewGroupMember
        })
       
        //console.log('self.NewGroupMember',self.NewGroupMember)
        self.oldGroupMember = []
        if(self.groupMember){
         getoldmember(firebaseData.firebasecollection.USER,self.groupMember,self.loginCustomeId,res=>{
          self.oldGroupMember = res
        })
        }
        //console.log('self.oldGroupMember',self.oldGroupMember);
      })
    },

    getAdmin(groupMember)
    {
      var self = this
    
      groupMember.forEach(data=>{
        if(data!=undefined)
        {
          // console.log('123123123',groupMember,data.id,self.groupData.adminId)
          if(data.id==self.groupData.adminId)
          {
            self.adminName=data
          }
        }
      })
      // console.log('admin',self.adminName,self.groupData)
    },

    checkUserExistsInGroupOrChat()
    {
      var self = this

      db
        .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
        .onSnapshot(snapshot=>{
          snapshot.docChanges().forEach(change=>{
            if(change.type=='modified')
            {
              //console.log('HeaderInfo',change.doc.id)
              //console.log('querySnapshot for Header')
              //console.log('querySnapshot for Header User Found')
              db
                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                .doc(change.doc.id)
                .get().then(docdata=>{
                  if(docdata.data().isGroup)
                  {
                    var found=false
                    if(!docdata.data().isDeleted)
                    {
                      docdata.data().connectedUser.forEach(useruid=>{
                        if(useruid==self.loginCustomeId)
                        {
                          found=true
                        }
                      })
                    }
                    if(!found)
                    {
                      self.$root.$emit('activeChat-GroupFalse')
                      self.chatObject.id = ""
                      // self.groupData.id = ""
                    }
                  }
                })
            } else if (change.type === "removed") {
                if(!Object.keys(self.groupData).length) {
                    return;
                }
                if(change.doc.id == self.groupData.id) { 
                    self.$root.$emit('activeChat-GroupFalse');
                    self.chatObject.id = "";
                }
            }
          })
        })
    }, 
    
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewMultiImage: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while(count --) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          }
          this.image_list.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index ++;
        }
      }
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    },
    //logoutUser FROM WEBSITE(Shraddha)
    Logout(){
      // debugger // eslint-disable-line

      var self = this
      var uids = self.loginCustomeId || null
      if(uids!=null)
      {
        var loginauthtoken = localStorage.getItem('authToken');
        db.collection(firebaseData.firebasecollection.USER)
            .doc(uids)
            .update({
              isOnline:false,
              LoginFCMToken : firebaseTimestamp.firestore.FieldValue.arrayRemove({'from':'Web','token': loginauthtoken}), 
            })
            .then(()=>{
              firebase.auth().signOut()
              .then(()=>{
                  localStorage.removeItem("SuperAdminKey")
                  localStorage.removeItem("customerDocKey");
                        localStorage.removeItem("IsLoginUser");
                        localStorage.removeItem('authToken')
                        localStorage.removeItem('SelectedCompany')
                        localStorage.removeItem('CompanyDetail')
                        if(localStorage.getItem('StayAdminLoggedIn')=='false')
                        {
                            localStorage.removeItem("rememberMe")
                        }
                        self.$root.$emit('updateUserFooter')
                      // //debugger // eslint-disable-line
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"Success",
                            text:'User is logout successfully.',
                            type:"success"
                        })
                        self.$router.push({'name': 'login'});
                        const favicon = document.getElementById("favicon");
                        favicon.href="../../icon.png";
                    })
              .catch(error=>{
               
                //console.log(error)
              })
            })
            .catch(error=>{
              //console.log(error)
            })
       
      }
    },
    // ADD MEMBER IN GROUP (Shraddha)
    addMemberInGroup(newmember,index){
        // //debugger // eslint-disable-line
        var self=this;
        const db = firebase.firestore();
       
        let isInArray = self.groupMember.some(function(item) {
            return item == newmember.id
        });
        if(isInArray == false){
          self.groupData.messageSeenIds.forEach(data=>{
            if(data.id != self.loginCustomeId)
            {
              data.count++
            }
          })
          self.groupMember.push(newmember.id)

          var messageSeenIds = self.groupData.messageSeenIds||[]
          messageSeenIds.push({
            id:newmember.id,
            count:1
          })

          // console.log('messageSeenIds',messageSeenIds)

          var mainBranches = self.groupData.mainBranches||[]

          mainBranches.forEach(data=>{
            data.messageSeenIds.push({
              id:newmember.id,
              count:0
            })
            data.subBranches.forEach(data2=>{
              data2.messageSeenIds.push({
                id:newmember.id,
                count:0
              })
              data2.subSubBranches.forEach(data3=>{
                data3.messageSeenIds.push({
                  id:newmember.id,
                  count:0
                })
              })
            })
          })
          //console.log('???????',self.groupMember,newmember.id,self.groupChatId)
          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.groupChatId).update({
            connectedUser:self.groupMember,
            messageSeenIds:messageSeenIds,
            mainBranches:mainBranches
          }).then(() => {
            self.NewGroupMember.splice(index,1);
            self.getAdmin(self.groupMember)
          }).then(()=>{
            var from={
              avatar:'',
              id:self.groupData.adminId,
              name:self.groupData.adminName,
              path:''
            }
            db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.groupData.id).collection('default').add({
              createdAt:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
              messageSeen:[from],
              msg:`${self.groupData.adminName} added ${newmember.firstName} ${newmember.lastName}`,
              from:from,
              type:'Left',
              name:'Left',
              id:''
            })
            .then((docRef)=>{
              db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.groupData.id).collection('default').doc(docRef.id).update({
                id:docRef.id
              })
              .then(()=>{
                var chatdata = {}
                var mainBranchArray = []
                // self.$root.$emit('chatObjefct',chatdata,self.groupChatId,self.groupData,mainBranchArray);
                // console.log("GroupMembers after add ",self.groupData)
                self.groupMember=self.groupData.connectedUser
                self.$root.$emit("getMembersOfGroup",self.groupData.connectedUser)
                //console.log('self.NewGroupMember',self.NewGroupMember)
                self.oldGroupMember = []
                // getnewmember(firebaseData.firebasecollection.USER,self.NewGroupMemberId,self.loginCustomeId,res=>{
                //   self.NewGroupMember = [];
                //   self.NewGroupMember = res

                //   for (let i = 0; i < self.NewGroupMember.length; i++) {
                //     for (let j = i+1; j < self.NewGroupMember.length; j++) {
                //     if(self.NewGroupMember[i]==self.NewGroupMember[j])
                //     {
                //       self.NewGroupMember.splice(j,1)
                //     } 
                //     }
                //   }
                //   self.newArrayForNewMember = self.NewGroupMember
                // })
                getoldmember(firebaseData.firebasecollection.USER,self.groupMember,self.loginCustomeId,res=>{
                  self.oldGroupMember = res
                })
                self.$root.$emit('groupcreate','yes'); 
                self.$root.$emit("audioplay");
                self.$notify({
                  title:"Success",
                  text:`${newmember.firstName} ${newmember.lastName} added successfully.`,
                  type:"success"
                })
                })
              })
            })
        }
    },
    // REMOVE MEMBER FROM GROUP (Shraddha)
    removeGroupMemberConfirmation(groupmember,index)
    {
      var self = this
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      Swal.fire({
        title: `Are you sure you want to remove ${groupmember.firstName} ${groupmember.lastName} from this group?`,
        type: "warning",
        customClass:{
          container :"social_confirmation_popup"
        },
        showCancelButton: true,
        confirmButtonColor: "#ffab3f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.value) {
          self.removeGroupMember(groupmember,index)
        }
      })
    },
    removeGroupMember(oldmember,index){
       // //debugger // eslint-disable-line
       var self=this;
       const db = firebase.firestore();
       
      //console.log('********',self.groupMember,oldmember.id,self.groupChatId,index)
          var replacegroupArray = []
          for(var k=0; k<self.groupMember.length;k++){
            if(self.groupMember[k] != oldmember.id){
            replacegroupArray.push(self.groupMember[k]);
            }
          }
          self.groupData.messageSeenIds.forEach(data=>{
            if(data.id != self.loginCustomeId)
            {
              data.count++
            }
          })

          var messageSeenIds = self.groupData.messageSeenIds||[]
          messageSeenIds.forEach((data,index)=>{
            if(data.id == oldmember.id)
            {
              messageSeenIds.splice(index,1)
            }
          })

          var mainBranches = self.groupData.mainBranches||[]

          mainBranches.forEach((data)=>{
            data.messageSeenIds.forEach((msgid,index)=>{
              if(msgid.id == oldmember.id)
              {
                data.messageSeenIds.splice(index,1)
              }
            })
            data.subBranches.forEach((data2)=>{
              data2.messageSeenIds.forEach((msgid2,index2)=>{
                if(msgid2.id == oldmember.id)
                {
                  data2.messageSeenIds.splice(index2,1)
                }
              })
              data2.subSubBranches.forEach((data3)=>{
                data3.messageSeenIds.forEach((msgid3,index3)=>{
                  if(msgid3.id == oldmember.id)
                  {
                    data3.messageSeenIds.splice(index3,1)
                  }
                })
              })
            })            
          })
          //console.log('replacegroupArray',replacegroupArray)
          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.groupChatId).update({
            connectedUser:replacegroupArray,
            messageSeenIds:messageSeenIds,
            mainBranches:mainBranches
          }).then(() => {
            self.oldGroupMember.splice(index,1);
            self.getAdmin(self.groupMember)
          }).then(()=>{

          var from={
            avatar:'',
            id:self.groupData.adminId,
            name:self.groupData.adminName,
            path:''
          }
          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.groupData.id).collection('default').add({
            createdAt:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
            messageSeen:[from],
            msg:`${self.groupData.adminName} removed ${oldmember.firstName} ${oldmember.lastName}`,
            from:from,
            type:'Left',
            name:'Left',
            id:''
          })
          .then((docRef)=>{
            db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.groupData.id).collection('default').doc(docRef.id).update({
              id:docRef.id
            })
            .then(()=>{
              db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.groupChatId).get().then(snap => {
                var gData = snap.data()
                var chatdata = {}
                var mainBranchArray = []
                // console.log("GroupMembers after remove ",self.groupData)
                self.groupMember = self.groupData.connectedUser
                self.$root.$emit("getMembersOfGroup",replacegroupArray)

                self.NewGroupMemberId = [];
                self.acseptUserData.forEach(value => {
                  if(!self.groupMember.includes(value)) {
                    self.NewGroupMemberId.push(value);
                  }
                });
                getnewmember(firebaseData.firebasecollection.USER,self.NewGroupMemberId,self.loginCustomeId,res=>{
                  self.NewGroupMember = [];
                  self.NewGroupMember = res

                  for (let i = 0; i < self.NewGroupMember.length; i++) {
                    for (let j = i+1; j < self.NewGroupMember.length; j++) {
                    if(self.NewGroupMember[i]==self.NewGroupMember[j])
                    {
                      self.NewGroupMember.splice(j,1)
                    } 
                    }
                  }
                  self.newArrayForNewMember = self.NewGroupMember
                })
              
                // //console.log('self.NewGroupMember',self.NewGroupMember)
                // self.oldGroupMember = []
                // getoldmember(firebaseData.firebasecollection.USER,self.groupMember,self.loginCustomeId,res=>{
                //   self.oldGroupMember = res
                // })
                // self.$root.$emit('chatObjefct',chatdata,self.groupChatId,gData,mainBranchArray);
                self.$root.$emit('groupcreate','yes');
              }).then(() => {
                  self.$root.$emit("audioplay");
                  self.$notify({
                  title:"Success",
                  text:`${oldmember.firstName} ${oldmember.lastName} removed successfully.`,
                  type:"success"
                })
              })
            })
          })
        })
    },
    searchFromMessage(){
    //   debugger // eslint-disable-line
      var self = this;
      self.showSearchList = false;
      if(self.Mainsearchkeyword == 'All'){
        if(self.chatkeyword === "")
        {
            // self.$emit('onupdateSearch',self.chatkeyword,self.Mainsearchkeyword)
        }
        self.$root.$emit('onupdateSearchs',self.chatkeyword,self.Mainsearchkeyword)
      }else{
        self.$root.$emit('onupdateSearchs',self.chatkeyword,self.Mainsearchkeyword)
      }
    },
    setBusinessMenu(businessmenuActive){
      //debugger // eslint-disable-line
     var self=this;
     self.$root.$emit('setBusinessMenu',businessmenuActive);

    },
    // SEARCH FOR ADD MEMBER
    searchFilter()
        {
        // //debugger // eslint-disable-line
           var arr=[]
           //console.log( this.newArrayForNewMember)
            this.NewGroupMember.forEach(datas=>{
                var fullname = datas.firstName+' '+datas.lastName
                if(datas.firstName.toLowerCase().includes(this.keyword.toLowerCase()))
                {
                  arr.push(datas)
                }
                else if(datas.lastName.toLowerCase().includes(this.keyword.toLowerCase()))
                {
                 arr.push(datas)
                }
                else if(fullname.toLowerCase().includes(this.keyword.toLowerCase()))
                {
                 arr.push(datas)
                }
            })
            this.NewGroupMember = arr
            if(this.NewGroupMember.length == 0){
                this.nouserFoundMessage = 'No Member Found'
            }else{
                this.nouserFoundMessage = ''
            }
            if(this.keyword == ''){
               this.NewGroupMember = this.newArrayForNewMember
               this.nouserFoundMessage = ''
            }
    },
    // CLOSE EVENT(Shraddha)
    closeFire(){
      // //debugger // eslint-disable-line
      var self=this;
      self.keyword = ''
      self.NewGroupMember = self.newArrayForNewMember
      self.nouserFoundMessage = ''
    } ,
    searchClick(){
    //   debugger // eslint-disable-line
      var self = this;
      if(!self.showSearchList){
        self.showSearchList = true;
        self.chatkeyword = '';
      }else{
        self.showSearchList = false;
        self.chatkeyword = '';
      }
      
    },
    // ADD VALUE IN SEARCHBAR (Shraddha)
    addInSearchBar(title){
      var self = this;
    //   debugger // eslint-disable-line
      self.Mainsearchkeyword = title;
      self.closeSearchList = true;
      self.showSearchList = false;
      self.chatkeyword = '';
      self.$emit('onupdateSearch',self.chatkeyword,title);
      self.$root.$emit('searchByTypeValue',self.chatkeyword,title);

    },
    // CLOSE SERACH BAR(Shraddha)
    closeSearchBar(){
      var self = this;
      self.Mainsearchkeyword = 'All';
      self.showSearchList = false;
      self.closeSearchList = false;
      self.chatkeyword = '';
      self.$emit('onupdateSearch',self.chatkeyword,self.Mainsearchkeyword)
      self.$root.$emit('searchByTypeValue',self.chatkeyword,self.Mainsearchkeyword);
      self.$root.$emit('onupdateSearchs',self.chatkeyword,self.Mainsearchkeyword)


    },
  },
   //LISTING DATA (Shraddha)
  computed: {
    ...mapGetters({userData:"getUserData"}),
    ...mapGetters({groupData:"getChatData"}),
    getFilterData() {
      var self = this;
      return self.NewGroupMember.filter(function(rawData) {
        return (
          rawData["firstName"]
            .toLowerCase()
            .indexOf(self.keyword.toLowerCase()) >= 0 ||
          rawData["lastName"]
            .toLowerCase()
            .indexOf(self.keyword.toLowerCase()) >= 0 
        );
      });
    },
  },
}
</script>