<!-- 07-08-2021,09-08-2021 Shiv joshi, made changes to create branches and sub branches in group or one to one chat -->
  <template>
      <div class="chat-app-wrapper" id="chat" :class="{loaderBackground : showLoaders}" >
        <section>
          <Header v-if="isAdmin == false" :imageUrl= userData :chatObject= chatObject :activeGroup = activeGroup :activeChat= activeChat :groupData="groupData" :businessMenuActive="businessMenuActive" @onupdateSearch = updatesearch></Header>
          <AdminHeader v-if="isAdmin == true "></AdminHeader>
        </section>
        <section class="chat-app-section" v-if="isAdmin == false " @click="closeDropDown()">
          <div class="chat-area" >
            <!-- Fisrt part -->
            <div class="vertical-menu">
              <ul>
                <li v-if="displayChat"
                  v-bind:class="{ active: selectedActiveMenu == 'activeList' }"
                  @click="getMenuScreen('activeList')"
                >
                  <img src="../assets/images/m-msg.png" class="active-img" title="Direct message" /><img
                    src="../assets/images/white-chat.png" title="Direct message"
                    class="default-img"
                  />
                  <!-- {{newMessageInSocial.length}} -->
                  
                  <span v-if="newMessageInSocial" class="socialIncomintRequestDot"></span>
                
                <li  v-if="displayChat"
                  v-bind:class="{ active: selectedActiveMenu == 'contact' }"
                  @click="getMenuScreen('contact')"
                >
                  <img
                    src="../assets/images/yellow-user.png"
                    class="active-img" title="Contacts"
                  /><img src="../assets/images/m-user.png" class="default-img" title="Contacts"/>
                  <span v-if="socialIncomingRequests.length!=0" class="socialIncomintRequestDot"></span>
                </li>
              </ul>
            </div>
            <!-- Fisrt part End -->
      
            <!-- Second part -->
            <div class="active-user-list" :class="{'hideChat' : !displayChat}">
              <div class="active-chat-tab"  :class="{'businessDotWhite':newMessageInSocial}">
              <div class="active-chat-tab" :class="{'socialDot':newMessageInBusiness}">
                <!-- {{newMessageInBusiness}} -->
                <!-- <div v-if="newMessageInBusiness" class="greenDotNewMessage">
                </div> -->
              
                <b-tabs content-class="mt-3" align="center">
                  <b-tab title="Social" active @click="SocialMenuActiveTab()">
                    <ActiveChatList v-if="selectedActiveMenu == 'activeList'" :chatObjectId="chatObjectId" @OnupdateBulkData="BulkData"></ActiveChatList>
                    <ContactDetail v-if="selectedActiveMenu == 'contact'" :activeChat="activeChat"></ContactDetail>
                  </b-tab>
                  <b-tab title="Business" @click="businessMenuActiveTab()">
                    <div class="admin_business_wrap">
                      <BusinessChatList v-if="selectedActiveMenu == 'admin'" :chatObjectId="chatObjectId" @OnupdateBulkData="BulkData" ></BusinessChatList>
                      <BusinessDirectMessage v-if="selectedActiveMenu == 'adminChat'" :activeChat="activeChat"></BusinessDirectMessage>
                      <BusinessUser v-if="selectedActiveMenu == 'adminUser'"></BusinessUser>
                    </div>
                  </b-tab>
                </b-tabs>
                </div>
              </div>
            </div>
            <div class="displayChatAccessMessage" v-if="!displayChat">
              <p>You don't have permission to view this screen. Upgrade your plan now to get access.</p>
              <router-link  class="btn btn-yellow" to="/generalsettings/Subscription_Details">Upgrade Plan</router-link>
            </div>
            <!-- Second part End -->
            
            <!-- message screen third  -->
            <div class="message-screen" :class="{'hideChat' : !displayChat}" v-if="dropFiles == false">
              <!-- {{sendreceArray.length}} -->
              <div class="loader_div only_messagescrenn" v-if="showLoaders">
              <!-- <sync-loader :loading="true" color="#77bef6"></sync-loader> -->
              <img src="@/assets/images/Orange_GIF.gif" class="lodar_gif">
              </div>
              <span class="search_error_display" v-if="searchErrorMessage != '' && sendreceArray.length == 0 ">{{searchErrorMessage}}</span>
            
              <div class="no-active-user-wrapper" v-if="activeGroup || activeChat">
                <div class="chat-screen-topbar bg-topheader" :class="{'chatbusinessheader' : businessMenuActive == true}">
                      <div class="top-bar-after-addmember">
                        
                          <div class="tag-list">
                              <ul>
                              <li>
                                  <span style="cursor:pointer" @click.prevent="subSubBranchActive=false,subBranchActiveIndex=0,subBranchActive=false,subBranchActiveIndex=0,mainBranchActiveIndex=0,mainBranchActive=false,getChatArray('default',chatObjectId),sendreceArray=[],branchName=''" :class="{'branchActiveInHeader': !mainBranchActive && !subBranchActive &&!subSubBranchActive}" >
                                    {{activeGroup?groupData.title:chatObject.firstName}}<b class="send-arrow-green"></b>
                                    
                                    <img v-if="activeGroup && isGroupMuted" src="@/assets/images/group-muted.png" class="volume-icon top-volume-icon">
                                  </span>
                              </li>
                              
                              <!-- <li v-if="mainBranches.length!=0 && !mainBranchActive"><span style="cursor:pointer" v-for="(data,index) in  mainBranches" :key="index" @click="mainBranchActiveIndex=index,getMainBranchData(),subBranchActive=false,subBranchActiveIndex=0">{{data.name}} <img src="../assets/images/volume-icon.png" class="volume-icon"></span></li> -->
                              <li v-if="mainBranches!=[] && mainBranchActive" :class="{'branchTopHeaderSocial': countAllbranch(mainBranches[mainBranchActiveIndex],'mainbranch')>0}">
                                  <span class="topbar-branchTag" :class="{'branchActiveInHeader': mainBranchActive &&!subBranchActive &&!subSubBranchActive}" >
                                    <span style="cursor:pointer" class="BranchActiveSpan"  @click="subBranchActive=false,subBranchActiveIndex=0,subSubBranchActive=false,subSubBranchActiveIndex=0,getChatArray(mainBranches[mainBranchActiveIndex].name,chatObjectId)">
                                      {{mainBranches[mainBranchActiveIndex].name}}
                                    </span>
                                  <!-- {{opendropdown}}   -->
                                  <span class="msg_count_span socialMessageCount" v-if="countAllbranch(mainBranches[mainBranchActiveIndex],'mainbranch')>0">{{countAllbranch(mainBranches[mainBranchActiveIndex],'mainbranch')>0?`${countAllbranch(mainBranches[mainBranchActiveIndex],'mainbranch')>9?'9+':countAllbranch(mainBranches[mainBranchActiveIndex],'mainbranch')}`:''}}</span>
                                  <img style="cursor:pointer" id="mainBranchDropdown" @click="openDropdownBranch(),insideDivClickActiveBranch(mainBranches[mainBranchActiveIndex].name)" src="@/assets/images/dotimg.png" class="volume-icon top-volume-icon branchImageDropdown">
                                  <!-- <select v-model="opendropdown" @change="selectedMainBranchDropdown()" v-if="selectDropdown" >
                                    <option>Delete</option>
                                    <option v-if="!checkMuted(mainBranches[mainBranchActiveIndex].muteArray)"><button title="Mute branch" >Mute</button></option>
                                    <option v-else><button title="Unmute branch" >Unmute</button></option>
                                  </select> -->
                                      <div class="message-nt BrnachDropdownSelection" v-if="selectDropdown">
                                        <span class="u_name" @click="selectedMainBranchDropdown('Delete')">Delete</span>
                                        <span v-if="!checkMuted(mainBranches[mainBranchActiveIndex].muteArray)" @click="selectedMainBranchDropdown('Mute')" class="u_name" >Mute</span>
                                        <span v-else class="u_time" @click="selectedMainBranchDropdown('Unmute')">Unmute</span>
                                      </div>
                                    <!-- <button @click="MainbranchDelete(mainBranches,mainBranches[mainBranchActiveIndex].name,mainBranches[mainBranchActiveIndex].msgid || '')">X</button> -->
                                    <!-- <img style="cursor:pointer" title="Mute branch" v-if="!checkMuted(mainBranches[mainBranchActiveIndex].muteArray)" @click.prevent="muteUnmuteChat('mainBranch',mainBranchActiveIndex,'mute')" src="../assets/images/volume-icon-black.png" class="volume-icon top-volume-icon">
                                    <img style="cursor:pointer" v-else title="Unmute branch" @click.prevent="muteUnmuteChat('mainBranch',mainBranchActiveIndex,'unmute')" src="../assets/images/volume-mute-black.png" class="volume-icon top-volume-icon"> -->
                                  </span>
                                </li>

                              <li v-if="mainBranches!=[] && mainBranchActive && subBranchActive" :class="{'branchTopHeaderSocial': countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex],'mainbranch')>0}">
                                  <span class="topbar-branchTag" :class="{'branchActiveInHeader': mainBranchActive && subBranchActive &&!subSubBranchActive}">
                                    <span style="cursor:pointer"  class="BranchActiveSpan" @click="subSubBranchActiveIndex=0,subSubBranchActive=false,getChatArray(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].name,chatObjectId)">
                                      {{mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].name}}
                                    </span>
                                    <span class="msg_count_span socialMessageCount" v-if="countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex],'subbranch')>0">{{countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex],'subbranch')>0?`${countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex],'subbranch')>9?'9+':countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex],'subbranch')}`:''}}</span>
                                    <img style="cursor:pointer" id="subBranchDropdown" @click="openSubDropdownBranch(),insideDivClickActiveBranch1(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].name)" src="@/assets/images/dotimg.png" class="volume-icon top-volume-icon branchImageDropdown" >
                                      <!-- <select v-model="openSubdropdown" @change="selectedSubBranchDropdown()" v-if="selectSubDropdown" >
                                        <option>Delete</option>
                                        <option v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].muteArray)"><button title="Mute branch" >Mute</button></option>
                                        <option v-else><button title="Unmute branch" >Unmute</button></option>
                                      </select> -->
                                      <div class="message-nt BrnachDropdownSelection"   v-if="selectSubDropdown">
                                        <span class="u_name" @click="selectedSubBranchDropdown('Delete')">Delete</span>
                                        <span v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].muteArray)" @click="selectedSubBranchDropdown('Mute')" class="u_name" >Mute</span>
                                        <span v-else class="u_time" @click="selectedSubBranchDropdown('Unmute')">Unmute</span>
                                      </div>
                                      <!-- <button @click="SubbranchDelete(mainBranches,mainBranchActiveIndex,mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].name,mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].msgid || '')">X</button>
                                      <img style="cursor:pointer" title="Mute branch" v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].muteArray)" @click.prevent="muteUnmuteChat('subBranch',subBranchActiveIndex,'mute')" class="volume-icon top-volume-icon" src="../assets/images/volume-icon-black.png">
                                      <img style="cursor:pointer" v-else title="Unmute branch" src="../assets/images/volume-mute-black.png" @click.prevent="muteUnmuteChat('subBranch',subBranchActiveIndex,'unmute')" class="volume-icon top-volume-icon"> -->
                                  </span>
                                </li>

                              <li v-if="mainBranches!=[] && mainBranchActive && subBranchActive && subSubBranchActive" :class="{'branchTopHeaderSocial': countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex],'mainbranch')>0}">
                                  <span class="topbar-branchTag" :class="{'branchActiveInHeader': mainBranchActive && subBranchActive && subSubBranchActive}">
                                    <span style="cursor:pointer" class="BranchActiveSpan" @click="getChatArray(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex].name,chatObjectId)">
                                    {{mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex].name}}
                                  </span>
                                  <span class="msg_count_span socialMessageCount" v-if="countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex],'subsubbranch')>0">{{countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex],'subsubbranch')>0?`${countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex],'subsubbranch')>9?'9+':countAllbranch(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex],'subsubbranch')}`:''}}</span>
                                  <img style="cursor:pointer" id="subSubBranchDropdown"  @click="openSubSubDropdownBranch(),insideDivClickActiveBranch2(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex].name)" src="@/assets/images/dotimg.png" class="volume-icon top-volume-icon branchImageDropdown">
                                      <!-- <select v-model="openSubSubdropdown" @change="selectedSubSubBranchDropdown()" v-if="selectSubSubDropdown" >
                                        <option>Delete</option>
                                        <option v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex].muteArray)"><button title="Mute branch" >Mute</button></option>
                                        <option v-else><button title="Unmute branch" >Unmute</button></option>
                                      </select> -->
                                      <div class="message-nt BrnachDropdownSelection"   v-if="selectSubSubDropdown">
                                        <span class="u_name" @click="selectedSubSubBranchDropdown('Delete')">Delete</span>
                                        <span v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex].muteArray)" @click="selectedSubSubBranchDropdown('Mute')" class="u_name" >Mute</span>
                                        <span v-else class="u_time" @click="selectedSubSubBranchDropdown('Unmute')">Unmute</span>
                                      </div>
                                  <!-- <button @click="SubSubbranchDelete(mainBranches,mainBranchActiveIndex,subBranchActiveIndex,mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex].name,mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex].msgid || '')">X</button>
                                    
                                    <img style="cursor:pointer" title="Mute branch" v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[subSubBranchActiveIndex].muteArray)" @click.prevent="muteUnmuteChat('subSubBranch',subSubBranchActiveIndex,'mute')" src="../assets/images/volume-icon-black.png" class="volume-icon top-volume-icon">
                                    <img style="cursor:pointer" v-else title="Unmute branch" src="../assets/images/volume-mute-black.png" @click.prevent="muteUnmuteChat('subSubBranch',subSubBranchActiveIndex,'unmute')" class="volume-icon top-volume-icon"> -->
                                  </span>
                                </li>

                              </ul>
                          </div>
                          <div class="black-tag-list">
                      
                              <ul class="top_new_list">
                                <li v-if="showStartDots()" class="branch_show" @click.prevent="changePage('prev')"><img src="@/assets/images/dotimg.png"/></li>
                                
                              <!-- {{mainCount}}{{subCount}}{{subSubCount}} -->
                                
                              
                                <div v-if="mainBranches!=[] && !mainBranchActive" >
                                
                                  <li
                                    style="cursor:pointer" :class="{'branchHeaderSocial': countAllbranch(data,'mainbranch')>0}"
                                    v-for="(data,index) in  mainBranches" :key="index"
                                    v-show="index>=startIndex && index <endIndex"
                                  >
                                  <div v-if="index>=startIndex && index <endIndex">
                                    <span class="branch_msg_span"
                                      @click="mainBranchActive=true,mainBranchActiveIndex=index,getChatArray(mainBranches[mainBranchActiveIndex].name,chatObjectId),subBranchActive=false,subBranchActiveIndex=0,sendreceArray=[],branchName=data.name,startIndex=0,endIndex=6"
                                    >
                                      {{data.name}}
                                    </span>
                                    <!-- <span class="msg_count_span" v-if="getMessageCount(data.messageSeenIds)>0">{{getMessageCount(data.messageSeenIds)>0?`${getMessageCount(data.messageSeenIds)>9?'9+':getMessageCount(data.messageSeenIds)}`:''}}</span> -->
                                    <span class="msg_count_span" v-if="countAllbranch(data,'mainbranch')>0">{{countAllbranch(data,'mainbranch')>0?`${countAllbranch(data,'mainbranch')>9?'9+':countAllbranch(data,'mainbranch')}`:''}}</span>
                                    <img style="cursor:pointer" :id="'clickbox'+index" @click="openActiveDropdownBranch(data),insideDivClick(data,index)" src="@/assets/images/dotimg.png" class="volume-icon top-volume-icon branchImageDropdown">
                                      <!-- <select v-model="openMainBranchActiveDropdown" @change="selectedMainBranchActiveDropdown(mainBranches,data.name,data.msgid,index)" v-if="data.flag" >
                                        <option>Delete</option>
                                        <option v-if="!checkMuted(mainBranches[index].muteArray)"><button title="Mute branch" >Mute</button></option>
                                        <option v-else><button title="Unmute branch" >Unmute</button></option>
                                      </select> -->
                                      <div class="message-nt BrnachDropdownSelection"  v-if="data.flag">
                                        <span class="u_name" @click="selectedMainBranchActiveDropdown(mainBranches,data.name,data.msgid,index,'Delete')">Delete</span>
                                        <span v-if="!checkMuted(mainBranches[index].muteArray)" @click="selectedMainBranchActiveDropdown(mainBranches,data.name,data.msgid,index,'Mute')" class="u_name" >Mute</span>
                                        <span v-else class="u_time" @click="selectedMainBranchActiveDropdown(mainBranches,data.name,data.msgid,index,'Unmute')">Unmute</span>
                                      </div>
                                    <!-- <button @click="MainbranchDelete(mainBranches,data.name,data.msgid || '')">X</button>
                                    <img v-if="!checkMuted(mainBranches[index].muteArray)" src="../assets/images/volume-icon-black.png" title="Mute branch" class="volume-icon" @click.prevent="muteUnmuteChat('mainBranch',index,'mute')">
                                    <img v-else src="../assets/images/volume-mute-black.png" title="Unmute branch" class="volume-icon" @click.prevent="muteUnmuteChat('mainBranch',index,'unmute')"> -->
                                  </div>
                                  </li>
                                </div>
                                <div v-if="mainBranchActive && mainBranches[mainBranchActiveIndex].subBranches.length!=0 && !subBranchActive" >
                                  <li
                                    style="cursor:pointer" :class="{'branchHeaderSocial': countAllbranch(data,'subbranch')>0}"
                                    v-for="(data,index) in  mainBranches[mainBranchActiveIndex].subBranches" :key="index"
                                    v-show="index>=startIndex && index <endIndex"
                                  >
                                  <div v-if="index>=startIndex && index <endIndex">
                                    <span class="branch_msg_span"
                                      @click.prevent="subSubBranchActive=false,subSubBranchActiveIndex=0,subBranchActive=true,subBranchActiveIndex=index,getChatArray(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].name,chatObjectId),sendreceArray=[],branchName=data.name,startIndex=0,endIndex=6"
                                    >
                                      {{data.name}}
                                    </span>
                                    <!-- <span class="msg_count_span" v-if="getMessageCount(data.messageSeenIds)>0">{{getMessageCount(data.messageSeenIds)>0?`${getMessageCount(data.messageSeenIds)>9?'9+':getMessageCount(data.messageSeenIds)}`:''}}</span> -->
                                    <span class="msg_count_span" v-if="countAllbranch(data,'subbranch')>0">{{countAllbranch(data,'subbranch')>0?`${countAllbranch(data,'subbranch')>9?'9+':countAllbranch(data,'subbranch')}`:''}}</span>
                                    <img style="cursor:pointer" :id="'clickbox'+index" @click="openActiveSubDropdownBranch(data),insideDivClick(data,index)" src="@/assets/images/dotimg.png" class="volume-icon top-volume-icon branchImageDropdown">
                                      <!-- <select v-model="openSubBranchActiveDropdown" @change="selectedSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,data.name,data.msgid,index)" v-if="data.flag" >
                                        <option>Delete</option>
                                        <option v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[index].muteArray)"><button title="Mute branch" >Mute</button></option>
                                        <option v-else><button title="Unmute branch" >Unmute</button></option>
                                      </select> -->
                                      <div class="message-nt BrnachDropdownSelection"   v-if="data.flag">
                                        <span class="u_name" @click="selectedSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,data.name,data.msgid,index,'Delete')">Delete</span>
                                        <span v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[index].muteArray)" @click="selectedSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,data.name,data.msgid,index,'Mute')" class="u_name" >Mute</span>
                                        <span v-else class="u_time" @click="selectedSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,data.name,data.msgid,index,'Unmute')">Unmute</span>
                                      </div>
                                    <!-- <button @click="SubbranchDelete(mainBranches,mainBranchActiveIndex,data.name,data.msgid)">X</button>
                                    <img v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[index].muteArray)" src="../assets/images/volume-icon-black.png" title="Mute branch" class="volume-icon" @click.prevent="muteUnmuteChat('subBranch',index,'mute')">
                                    <img v-else src="../assets/images/volume-mute-black.png" title="Unmute branch" class="volume-icon" @click.prevent="muteUnmuteChat('subBranch',index,'unmute')"> -->
                                  </div>
                                  </li>
                                </div>

                                <div v-if="mainBranchActive && mainBranches[mainBranchActiveIndex].subBranches.length!=0 && subBranchActive && !subSubBranchActive" >
                                  <li
                                  style="cursor:pointer" :class="{'branchHeaderSocial': countAllbranch(data,'subsubbranch')>0}"
                                  v-for="(data,index) in  mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches" :key="index"
                                  v-show="index>=startIndex && index <endIndex"
                                  >
                                  <div v-if="index>=startIndex && index <endIndex">
                                    <span class="branch_msg_span"
                                      @click.prevent="subSubBranchActive=true,subSubBranchActiveIndex=index,getChatArray(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[index].name,chatObjectId),sendreceArray=[],branchName=data.name,startIndex=0,endIndex=6"
                                    >
                                      {{data.name}}
                                    
                                    
                                    </span>
                                    <!-- <span class="msg_count_span" v-if="getMessageCount(data.messageSeenIds)>0">{{getMessageCount(data.messageSeenIds)>0?`${getMessageCount(data.messageSeenIds)>9?'9+':getMessageCount(data.messageSeenIds)}`:''}}</span> -->
                                    <span class="msg_count_span" v-if="countAllbranch(data,'subsubbranch')>0">{{countAllbranch(data,'subsubbranch')>0?`${countAllbranch(data,'subsubbranch')>9?'9+':countAllbranch(data,'subsubbranch')}`:''}}</span>
                                    <img style="cursor:pointer" :id="'clickbox'+index" @click="openActiveSubSubDropdownBranch(data),insideDivClick(data,index)" src="@/assets/images/dotimg.png" class="volume-icon top-volume-icon branchImageDropdown">
                                      <!-- <select v-model="openSubSubBranchActiveDropdown" @change="selectedSubSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,subBranchActiveIndex,data.name,data.msgid,index)" v-if="data.flag" >
                                        <option>Delete</option>
                                        <option v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[index].muteArray)"><button title="Mute branch" >Mute</button></option>
                                        <option v-else><button title="Unmute branch" >Unmute</button></option>
                                      </select> -->
                                      <div class="message-nt BrnachDropdownSelection"   v-if="data.flag">
                                        <span class="u_name" @click="selectedSubSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,subBranchActiveIndex,data.name,data.msgid,index,'Delete')">Delete</span>
                                        <span v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[index].muteArray)" @click="selectedSubSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,subBranchActiveIndex,data.name,data.msgid,index,'Mute')" class="u_name" >Mute</span>
                                        <span v-else class="u_time" @click="selectedSubSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,subBranchActiveIndex,data.name,data.msgid,index,'Unmute')">Unmute</span>
                                      </div>
                                    <!-- <button @click="SubSubbranchDelete(mainBranches,mainBranchActiveIndex,subBranchActiveIndex,data.name,data.msgid)">X</button>
                                    <img v-if="!checkMuted(mainBranches[mainBranchActiveIndex].subBranches[subBranchActiveIndex].subSubBranches[index].muteArray)" src="../assets/images/volume-icon-black.png" title="Mute branch" class="volume-icon" @click.prevent="muteUnmuteChat('subSubBranch',index,'mute')">
                                    <img v-else src="../assets/images/volume-mute-black.png" title="Unmute branch" class="volume-icon" @click.prevent="muteUnmuteChat('subSubBranch',index,'unmute')"> -->
                                  </div>
                                  </li>
                                </div>
                                <li v-if="showEndDots()" class="branch_show"><img @click.prevent="changePage('next')" src="@/assets/images/dotimg.png"/></li>
                                <li :class="{'hideFooterForDooeyAdmin': botUserEmail == chatObject.email}" class="add_branch_icon no_bg_button" v-if="!subSubBranchActive"> <img src="@/assets/images/plus.svg" @click.prevent="createBranchMessageId='', botUserEmail != chatObject.email ? $bvModal.show('create-branch') : '' "  class="black-add-branch" style="cursor: pointer;width: 26px;" title="Create branch"></li>
                              </ul>
                          </div>
                      </div>
                </div>
              

                <div class="main-chat-screen" ondragstart="return false" v-if="activeChat == true">
                      <div class="loader_div" v-if="showLoader && searchErrorMessage == '' ">
                      <!-- <sync-loader :loading="true" color="#77bef6"></sync-loader> -->
                      <img src="@/assets/images/Orange_GIF.gif" class="lodar_gif">
                      </div>
                      
                      <div style="visibility:hidden; opacity:0; z-index:1;" id="dropzone">
                        <div id="textnode">Drop file to share.</div>
                      </div>
                      <div class="message-body" id="scrollParent" @click="hideUnreadMessages()" @mouseover ="checkActiveTab()" v-if="sendreceArray.length != 0" v-chat-scroll="{always: false, smooth: false, scrollonremoved:true, smoothonremoved: false}" @v-chat-scroll-top-reached="scroollmethod()">
                        <div v-for="(chatmessage,index) in sendreceArray" v-bind:key="index">
                          <!-- FOR RECEIVERMESSAGES-->
                          <!-- {{chatmessage}} -->
                          <!-- {{chatmessage.type=='Text_Poll'?chatmessage.expireyTimeObjects:''}}{{chatmessage.type=='Text_Poll'?todayTime:''}}{{chatmessage.type=='Text_Poll'?splitDateObject:''}}
                          {{chatmessage.type=='Image_Poll'?chatmessage.expireyTimeObjects:''}}{{chatmessage.type=='Image_Poll'?todayTime:''}}{{chatmessage.type=='Image_Poll'?splitDateObject:''}} -->
                          <div class="messageNotSeen" v-if="unreadMessages!=0 && index == (sendreceArray.length-unreadMessages)">
                              <span>{{unreadMessages}} Unread Messages</span>
                          </div>
                          <div class="userLeftMessage" v-if="chatmessage.type.toLowerCase()=='left'">
                              <span ondragstart="return false">{{chatmessage.msg}}</span>
                          </div>
                          <div class="message left" v-if="chatmessage.messageType == 'receiver' && chatmessage.type != 'TextPoll' && chatmessage.type!='ImagePoll'  && chatmessage.type.toLowerCase() != 'left' "> 
                            <div>
                              
                              <p ondragstart="return false" v-if="chatmessage.type.toLowerCase() == 'text' ">
                                <text-highlight  :queries="searchkeyword">{{chatmessage.receivermsg}}</text-highlight>
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'link' " ><a :href="chatmessage.receivermsg" target="_blank">
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.receivermsg}}</text-highlight>
                              </a></p>
                              <!-- <p v-if="chatmessage.type.toLowerCase() == 'surpriseselfietext' " :class="surprizeSelfieTextCheck(chatmessage)">{{chatmessage.receivermsg.trim()}} <img src="@/assets/images/surpriseSelfieIcon.png" style="height:28px; width:30px; object-fit: contain;" alt=""> </p> -->
                            <p v-if="chatmessage.type.toLowerCase() == 'surpriseselfietext' "> 
                                <text-highlight  :queries="searchkeyword">{{chatmessage.receivermsg.trim()}}</text-highlight>
                                <img src="@/assets/images/surpriseSelfieIcon.png" style="height:28px; width:30px; object-fit: contain;" alt="">
                                <span style="display:block;"><span style="color:#ffab3f;">Note:</span> To view this message, please login to the mobile application.</span>
                              </p>
                              <p @click="imageZoom(chatmessage.createdAt)" class="downloadIconWrapper"  v-if="chatmessage.type.toLowerCase() == 'image'">
                                <img :src="chatmessage.mediaUrl" :class="{'surpriseSelfieImage':chatmessage.type.toLowerCase() == 'surpriseselfie'}" v-b-modal.imageZoom>
                                <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                <!-- <img v-if="chatmessage.type.toLowerCase() == 'surprizeselfie'" src="@/assets/images/surpriseSelfieIcon.png" style="height:35px; width:32px" alt=""> -->
                              </p>

                              <p @click="imageZoom(chatmessage.createdAt)" class="downloadIconWrapper"  v-if="chatmessage.type.toLowerCase() == 'surpriseselfie' ">
                                <img :src="chatmessage.msg" :class="{'surpriseSelfieImage':chatmessage.type.toLowerCase() == 'surpriseselfie'}" v-b-modal.imageZoom>
                                <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.msg,chatmessage.name)"/>
                                <!-- <img v-if="chatmessage.type.toLowerCase() == 'surprizeselfie'" src="@/assets/images/surpriseSelfieIcon.png" style="height:35px; width:32px" alt=""> -->
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'pdf' ">
                                <a  v-bind:key="chatmessage" class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/PDF_file_icon.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>

                                <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'excel' ">
                                <a  v-bind:key="chatmessage" class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/Exl-icon.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>                          </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'word' ">
                                <a  v-bind:key="chatmessage" class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/Word_Icon.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>                          </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'odt' ">
                                <a  v-bind:key="chatmessage" class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/ODT.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>                          </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'ods' ">
                                <a  v-bind:key="chatmessage" class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/ODS.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>                          </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'ppt' ">
                                <a  v-bind:key="chatmessage" class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>                          </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'html' ">
                                <a  v-bind:key="chatmessage" class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/images.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>                          </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'plain' ">
                                <a  v-bind:key="chatmessage" class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/834209.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>                          </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'xd' ">
                                <a  v-bind:key="chatmessage" class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/imagesxd.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.sendermsg,chatmessage.name)"/>
                                </a>
                                <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>                          </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'video' " style="position: relative;">
                                  <video controls="controls" 
                                        class="video-stream" 
                                        x-webkit-airplay="allow" 
                                        :src="chatmessage.mediaUrl"
                                        style="max-width:100%;height:150px;width:200px;object-fit:contain"
                                  ></video>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'audio' ">
                                <!-- {{chatmessage}} -->
                              
                                <audio :src="chatmessage.receivermsg" @play="playAudio(chatmessage.defaultid)" :id="chatmessage.defaultid" preload="auto" type="audio/mpeg" controls>
                                  <!-- <source :src="chatmessage.receivermsg" type="audio/mp3" style="max-width:100%;height:150px;width:200px;object-fit:contain"> -->
                                  Your browser does not support the audio element.
                                </audio>
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'selfiedeclientext'">
                                {{chatmessage.msg == userData.id ? "You declined surprise selfie request" : "Sorry, your request for surprise selfie has been declined." }}
                              </p>
                              <p  v-if="chatmessage.msg && chatmessage.type.toLowerCase() != 'text' && chatmessage.type.toLowerCase() != 'selfiedeclientext' && chatmessage.type.toLowerCase() != 'link' && chatmessage.type.toLowerCase() != 'surpriseselfietext'&& chatmessage.type.toLowerCase() != 'surpriseselfie' && chatmessage.type.toLowerCase() != 'audio'">
                                <text-highlight  :queries="searchkeyword">{{chatmessage.msg}}</text-highlight>
                              </p>

                              <div class="message-nt">
                                <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                                <span class="u_time" >{{chatmessage.Time}}</span>
                                <!-- <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                <span class="u_time" v-else>{{chatmessage.beforeTime}}</span> -->
                              </div>

                              </div>
                              <div>
                                <b-dropdown id="dropdown-offset" text="" class="" v-if="checkTypeAndBranch(chatmessage.type,chatmessage.branch) && botUserEmail != chatObject.email"> 
                                  <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                  <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                  </div>
                                  <b-dropdown-item href="#" v-if="chatmessage.type=='Text' || chatmessage.type=='Link' " @click="copymessage(chatmessage.receivermsg)">Copy Message</b-dropdown-item>
                                </b-dropdown>
                              </div>
                          </div>

                          <!-- <div v-for="(pollimage,index) in UserPollAnswer" v-bind:key="index"> -->
                            <!-- Question -->
                            <div v-if="chatmessage.type.toLowerCase() == 'textpoll' && chatmessage.messageType == 'receiver' && !chatmessage.expired">
                              <div class="message left poll" v-if="!chatmessage.answered">
                                  <div>
                                    <div class="poll-req">
                                          <h4>{{chatmessage.question.question}}</h4>
                                          <ul>
                                            <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index" >
                                              <a href="#" @click="selectOption(polldata.text,chatmessage.defaultid,chatmessage.chatId)">{{polldata.text}}</a>
                                            </li>

                                          </ul>
                                    </div>
                                    <div class="message-nt">
                                      <span class="poll-expaire">
                                        <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                        <img class="poll-small-icon" src="../assets/images/fd1.png" style="height:22px;width:22px">
                                        <p>
                                          <span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                      </span>
                                      <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                      <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                    </div>
                                        <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                                  </div>
                                  <div>
                                    <b-dropdown id="dropdown-offset" text="" class="">
                                    <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                      <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                    </div>
                                  </b-dropdown>
                                    <!-- <b-dropdown id="dropdown-offset" text="" class=""> -->
                                      <!-- <b-dropdown-item href="#" v-b-modal.create-branch>Create Branch</b-dropdown-item> -->
                                      <!-- <b-dropdown-item href="#">Copy Message</b-dropdown-item> -->
                                    <!-- </b-dropdown> -->
                                  </div>
                              </div>
                              <!-- Answer -->
                              <div class="message left poll-img poll-progress consume" v-else>
                                <div>
                                  <div class="poll-img-popup">
                                        <h4>{{chatmessage.question.question}}</h4>
                                        <ul>
                                          <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index">
                                            <b-progress :value="polldata.count*100/chatmessage.question.totalCount" :max="max" class="mb-1"></b-progress>
                                            <!-- {{polldata.count*100/chatmessage.question.totalCount}} -->
                                              <h5>{{Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2))}}% {{polldata.text}}</h5>
                                          </li>
                                        </ul>
                                  </div>
                                  <div class="message-nt">
                                    <span class="poll-expaire">
                                    <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                    <img class="poll-small-icon" src="../assets/images/fd1.png" style="height:22px;width:22px">
                                      <p>
                                        <span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                    </span>
                                    <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                    <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                  </div>
                                  <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                                </div>
                                  <div>
                                    <b-dropdown id="dropdown-offset" text="" class="">
                                      <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                        <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                      </div>
                                    </b-dropdown>
                                    <!-- <b-dropdown id="dropdown-offset" text="" class=""> -->
                                      <!-- <b-dropdown-item href="#" v-b-modal.create-branch>Create Branch</b-dropdown-item> -->
                                      <!-- <b-dropdown-item href="#">Copy Message</b-dropdown-item> -->
                                    <!-- </b-dropdown> -->
                                  </div>
                              </div>
                            </div>
                          <!-- </div> -->

                          <div class="message left poll poll-result2" v-if="chatmessage.type.toLowerCase() == 'textpoll' && chatmessage.messageType == 'receiver' && chatmessage.expired">
                            <div>
                              <div class="poll-req">
                                    <h4>Poll Result</h4>
                                    <h5>{{chatmessage.question.question}}</h5>
                                    <ul>
                                      <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index" :class="{active : chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text}" >
                                        <!-- <img src="../assets/images/poll-win.png" class="pollwinicon" v-if="chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text"> -->
                                        <a href="#"> 
                                          <img src="../assets/images/poll-win.png" class="pollwinicon" v-if="chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text">
                                            {{polldata.text}} ({{chatmessage.question.totalCount>0?Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2)):0}}%)
                                          </a>
                                      </li>
                                    </ul>
                              </div>
                              <div class="message-nt">
                                  <span class="poll-expaire">
                                    <p>
                                      <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                      <span>Total Votes</span>:<span>{{chatmessage.question.totalCount}}</span>
                                    </p>
                                  </span>
                                  <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                  <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                </div>
                                  <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                              </div>
                              <div>
                                  <b-dropdown id="dropdown-offset" text="" class="">
                                    <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                      <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                    </div>
                                  </b-dropdown>
                                  <!-- <b-dropdown id="dropdown-offset" text="" class=""> -->
                                  <!-- <b-dropdown-item href="#" v-b-modal.create-branch>Create Branch</b-dropdown-item> -->
                                  <!-- <b-dropdown-item href="#">Copy Message</b-dropdown-item> -->
                                <!-- </b-dropdown> -->
                              </div>
                            </div>

                          <!-- FOR RECEIVERIMAGES -->
                          <div v-if="chatmessage.type.toLowerCase() == 'imagepoll' && chatmessage.messageType == 'receiver' && !chatmessage.expired">
                            <div class="message left poll-img" v-if="!chatmessage.answered">
                            <div>
                              <div class="poll-img-popup">
                                    <h4>{{chatmessage.question.question}}</h4>
                                    <ul>
                                      <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index">
                                        <a class="lightbox" :href="'#'+chatmessage.defaultid+index">
                                        <img :src="polldata.imageUrl">
                                        <img src="../assets/images/open-eye.png" style="height:auto" class="eye">
                                        </a>
                                        <div class="lightbox-target" :id="chatmessage.defaultid+index">
                                          <div class="lg_wrapper">
                                            <img :src="polldata.imageUrl"/>
                                            <a class="lightbox-close" href="#"></a>
                                          </div>
                                        </div>
                                        <div class="radio">
                                            <input :id="'radio-'+index" name="radio" type="radio" :value="polldata.text" @change="selectOption(polldata.text,chatmessage.defaultid,chatmessage.chatId)" >
                                            <label :for="'radio-'+index" class="radio-label">{{polldata.text}}</label>
                                        </div>
                                      </li>
                                    </ul>
                              </div>
                              <div class="message-nt">
                                <span class="poll-expaire">
                                  <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                  <img class="poll-small-icon" src="../assets/images/fd1.png" style="height:22px;width:22px">
                                    <p><span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                </span>
                                  <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                                  <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                  <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                              </div>
                                  <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                            </div>
                              <div>
                                <b-dropdown id="dropdown-offset" text="" class="">
                                    <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                      <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                    </div>
                                  </b-dropdown>
                                  
                                <!-- <b-dropdown id="dropdown-offset" text="" class=""> -->
                                  <!-- <b-dropdown-item href="#" v-b-modal.create-branch>Create Branch</b-dropdown-item> -->
                                  <!-- <b-dropdown-item href="#">Copy Message</b-dropdown-item> -->
                                
                                <!-- </b-dropdown> -->
                              </div>
                            </div>
                          
                            <div class="message left poll-img most_like" v-else>
                              <div>
                                <div class="poll-img-popup">
                                      <h4>{{chatmessage.question.question}}</h4>
                                      <ul>
                                      <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index">
                                          <a class="lightbox" :href="'#'+chatmessage.defaultid+index">
                                          <img :src="polldata.imageUrl">
                                          <img src="../assets/images/open-eye.png" style="height:auto" class="eye">
                                          </a>
                                          <div class="lightbox-target" :id="chatmessage.defaultid+index">
                                            <div class="lg_wrapper">
                                              <img :src="polldata.imageUrl"/>
                                              <a class="lightbox-close" href="#"></a>
                                            </div>
                                          </div>
                                          <h5 class="mt-1">{{polldata.text}}</h5>
                                          <b-progress :value="polldata.count*100/chatmessage.question.totalCount" :max="max" class="mb-1"></b-progress>
                                            <h5>{{Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2))}}% </h5>
                                        </li>
                                      </ul>
                                      <div class="message-nt">
                                        <span class="poll-expaire">
                                        <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                        <img class="poll-small-icon" src="../assets/images/fd1.png" style="height:22px;width:22px">
                                        <p>
                                          <span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                        </span>
                                        <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                        <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                      </div>
                                      <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                                </div>
                                
                              </div>
                                <div>
                                  <b-dropdown id="dropdown-offset" text="" class="">
                                    <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                      <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                    </div>
                                  </b-dropdown>
                                  <!-- <b-dropdown id="dropdown-offset" text="" class=""> -->
                                    <!-- <b-dropdown-item href="#" v-b-modal.create-branch>Create Branch</b-dropdown-item> -->
                                    <!-- <b-dropdown-item href="#">Copy Message</b-dropdown-item> -->
                                    
                                  <!-- </b-dropdown> -->
                                </div>
                            </div>
                          </div>
                          <div class="message left poll-img most_like poll-result" v-if="chatmessage.type.toLowerCase() == 'imagepoll' && chatmessage.messageType == 'receiver' && chatmessage.expired">
                            <div>
                              <div class="poll-img-popup">
                                    <h4>Poll Result</h4>
                                    <h5>{{chatmessage.question.question}}</h5>
                                    <ul>
                                    <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index" :class="{active : chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text}">
                                        <a class="lightbox">
                                        <img :src="polldata.imageUrl">
                                        </a>
                                        <h5 class="mt-1">
                                            <img src="../assets/images/poll-win.png" class="pollwinicon" v-if="chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text">
                                            {{polldata.text}} ({{chatmessage.question.totalCount>0?Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2)):0}}%)</h5>
                                    </li>
                                    </ul>
                                    <div class="message-nt">
                                      <span class="poll-expaire">
                                        <p>
                                          <span class="u_name" v-if="activeGroup == true">{{chatmessage.senderName}}</span>
                                          <span>Total Votes</span>:<span>{{chatmessage.question.totalCount}}</span></p>
                                      </span>
                                      <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                                      <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                      <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                    </div>
                                  <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                              </div>
                              
                            </div>
                              <div>
                                  <b-dropdown id="dropdown-offset" text="" class="">
                                    <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                      <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                    </div>
                                  </b-dropdown>
                                <!-- <b-dropdown id="dropdown-offset" text="" class=""> -->
                                  <!-- <b-dropdown-item href="#" v-b-modal.create-branch>Create Branch</b-dropdown-item> -->
                                  <!-- <b-dropdown-item href="#">Copy Message</b-dropdown-item> -->
                                  
                                <!-- </b-dropdown> -->
                              </div>
                          </div>
                          <!-- FOR SENDERMESSAGES-->
                          <div class="message right" v-if="chatmessage.messageType == 'sender' && chatmessage.type.toLowerCase() != 'textpoll'  && chatmessage.type!='ImagePoll' && chatmessage.type.toLowerCase() != 'left'">
                            <div>
                              <!-- {{audioPlaying}} -->
                              <!-- searchbaroption  :'',
                                searchkeyword :'' -->
                                <!-- <p>{{chatmessage.sendermsg}}</p> -->
                                
                                <p ondragstart="return false" v-if="chatmessage.type.toLowerCase() == 'text' " >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.sendermsg}}</text-highlight>
                                </p>
                                <p v-if="chatmessage.type.toLowerCase() == 'link' "><a :href="chatmessage.sendermsg" target="_blank">
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.sendermsg}}</text-highlight>
                                  </a>
                                </p>
                                <!-- <p v-if="chatmessage.type.toLowerCase() == 'surpriseselfietext' " :class="surprizeSelfieTextCheck(chatmessage)">{{chatmessage.sendermsg.trim()}} <img src="@/assets/images/surpriseSelfieIcon.png" style="height:28px; width:30px; object-fit: contain;" alt=""> </p> -->
                                <p v-if="chatmessage.type.toLowerCase() == 'surpriseselfietext' "> 
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.sendermsg.trim()}}</text-highlight>
                                  <img src="@/assets/images/surpriseSelfieIcon.png" style="height:28px; width:30px; object-fit: contain;" alt="">
                                </p>
                                <p @click="imageZoom(chatmessage.createdAt)"   v-if="chatmessage.type.toLowerCase() == 'image' " class="downloadIconWrapper">
                                  <img :src="chatmessage.mediaUrl" :class="{'surpriseSelfieImage':chatmessage.type.toLowerCase() == 'surpriseselfie'}" v-b-modal.imageZoom>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                  <!-- <img v-if="chatmessage.type.toLowerCase() == 'surprizeselfie'" src="@/assets/images/surpriseSelfieIcon.png" style="height:28px; width:30px; object-fit: contain;" alt=""> -->
                                </p>
                                <p @click="imageZoom(chatmessage.createdAt)"  v-if="chatmessage.type.toLowerCase() == 'surpriseselfie' " class="downloadIconWrapper">
                                  <img :src="chatmessage.msg" :class="{'surpriseSelfieImage':chatmessage.type.toLowerCase() == 'surpriseselfie'}" v-b-modal.imageZoom>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.msg,chatmessage.name)"/>
                                  <!-- <img v-if="chatmessage.type.toLowerCase() == 'surprizeselfie'" src="@/assets/images/surpriseSelfieIcon.png" style="height:28px; width:30px; object-fit: contain;" alt=""> -->
                                </p>
                                <p v-if="chatmessage.type.toLowerCase() == 'pdf' " >
                                  <a  class="downloadIconWrapper" >
                                    <img src="../assets/images/PDF_file_icon.png" class="pdf_icon"/>
                                    <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                  </a>
                                    <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>
                                </p>
                                <p v-if="chatmessage.type.toLowerCase() == 'excel' " >
                                <a  class="downloadIconWrapper" >
                                  <img src="../assets/images/Exl-icon.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                  <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'word' " >
                                <a  class="downloadIconWrapper" >
                                  <img src="../assets/images/Word_Icon.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                  <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'odt' ">
                                <a  class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/ODT.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                  <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'ods' ">
                                <a  class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/ODS.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                  <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'ppt' ">
                                <a  class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                  <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'html' ">
                                <a  class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/images.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                  <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'plain' ">
                                <a  class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/834209.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                  <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'xd' ">
                                <a  class="downloadIconWrapper" target="_blank">
                                  <img src="../assets/images/imagesxd.png" class="pdf_icon"/>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </a>
                                  <label class="doc_name_label" style="display:block" >
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.name}}</text-highlight>
                                </label>
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'audio'" >
                              
                                <audio :src="chatmessage.sendermsg" @play="playAudio(chatmessage.defaultid)" :id="chatmessage.defaultid" preload="auto" type="audio/mpeg" controls  >
                                  <!-- <source :src="chatmessage.sendermsg" type="audio/mp3" style="max-width:100%;height:150px;width:200px;object-fit:contain"> -->
                                  Your browser does not support the audio element.
                                </audio>
                              
                              </p>
                              <p v-if="chatmessage.type.toLowerCase() == 'video' ">
                                  <video controls="controls" 
                                        class="video-stream" 
                                        x-webkit-airplay="allow" 
                                        :src="chatmessage.mediaUrl"
                                        style="max-width:100%;height:150px;width:200px;object-fit:contain"
                                  ></video>
                                  <img src="../assets/images/download-icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.mediaUrl,chatmessage.name)"/>
                                </p>
                                
                                <p v-if="chatmessage.type.toLowerCase() == 'selfiedeclientext'">
                                  {{chatmessage.msg == userData.id ? "You declined surprise selfie request" : "Sorry, your request for surprise selfie has been declined." }}
                                </p>
                              <p  v-if="chatmessage.msg && chatmessage.type.toLowerCase() != 'text' && chatmessage.type.toLowerCase() != 'selfiedeclientext' && chatmessage.type.toLowerCase() != 'link' && chatmessage.type.toLowerCase() != 'surpriseselfietext' && chatmessage.type.toLowerCase() != 'surpriseselfie'  && chatmessage.type.toLowerCase() != 'audio'">
                                  <text-highlight  :queries="searchkeyword">{{chatmessage.msg}}</text-highlight>
                              </p>
                                <!-- <div class="file-action">
                                  <a @click="downloadResource(file.url,file.file_name)" v-bind:key="file.url">
                                      <img src="@/assets/images/download_icon.png"/>
                                  </a>
                                  <a href="javascript:void(0);" @click="removeAttachDocument(file)">
                                      <img src="@/assets/images/close.png"/>
                                  </a>
                                </div> -->
                                <div class="message-nt msg-nt-left">
                                  <div class="time_sendmark">
                                  <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                                  <span class="u_time">{{chatmessage.Time}}</span>
                                  <!-- <span class="u_time" v-else>{{chatmessage.beforeTime}}</span> -->
                                  <!-- {{!chatmessage.pending }}{{chatmessage.messageSeen.length === connectedUserLength}} -->
                                  <span v-if="chatmessage.messageSeen.length === connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:12px;width:10px" /></span>
                                  <span v-else-if="!chatmessage.pending && chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/send-arrow.png" style="height:12px;width:10px" /></span>
                                  <span v-else><img src="@/assets/images/ClockIcon.png" style="height:12px;width:10px" /></span>
                                  <!-- <span v-if="chatmessage.messageSeen.length != connectedUserLength "><img src="@/assets/images/send-arrow.png" style="height:12px;width:10px" /></span> -->
                                  <!-- <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:15px;width:15px" /></span> -->
                                  <!-- <span v-else><img src="@/assets/images/send-arrow.png" style="height:12px;width:10px" /></span> -->
                                  </div>
                                </div>
                              </div>
                            <div>
                                <b-dropdown id="dropdown-offset" text="" class="" >
                                  <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                    <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                  </div>
                                  <!-- <b-dropdown-item href="#" v-if="!subSubBranchActive" v-b-modal.create-branch><span>Create Branch</span></b-dropdown-item> -->
                                  <b-dropdown-item href="#" v-if="chatmessage.type=='Text' || chatmessage.type=='Link' " @click="copymessage(chatmessage.sendermsg)">Copy Message</b-dropdown-item>
                                  <b-dropdown-item href="#" v-b-toggle.sidebar-right @click="readrecipt(chatmessage)">Read Receipts</b-dropdown-item>
                                  <b-dropdown-item href="#" @click="messageDelete(chatmessage)">Delete</b-dropdown-item>

                                </b-dropdown>
                            </div>
                          </div>

                          <div v-if="chatmessage.type.toLowerCase() == 'textpoll' && chatmessage.messageType == 'sender' && chatmessage.messageType == 'sender' && !chatmessage.expired">
                          
                            <div class="message right poll" v-if="!chatmessage.answered">
                                <div>
                                  <div class="poll-req">
                                        <h4>{{chatmessage.question.question}}</h4>
                                        <ul>
                                          <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index" >
                                              <a href="#" @click="selectOption(polldata.text,chatmessage.defaultid,chatmessage.chatId)">{{polldata.text}}</a>
                                          </li>
                                          <!-- <li class="active"><a href="#">Morning</a></li>
                                          <li><a href="#">After Lunch</a></li>
                                          <li><a href="#">Evening</a></li>
                                          <li><a href="#">After Dinner</a></li> -->
                                        </ul>
                                  </div>
                                  <div class="message-nt">
                                        <span class="poll-expaire">
                                          <img class="poll-small-icon" src="../assets/images/fd1.png" style="height:22px;width:22px">
                                          <p><span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                        </span>
                                        <div class="time_sendmark">
                                        <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                        <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                        <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/send-arrow.png" style="height:12px;width:10px" /></span>
                                        <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:12px;width:10px" /></span>
                                        <span v-else><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                        </div>
                                  </div>
                                    <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                                </div>
                                  <div>
                                    <b-dropdown id="dropdown-offset" text="" class="">
                                      <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                          <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                      </div>
                                      <b-dropdown-item href="#"  @click="messageDelete(chatmessage)">Delete</b-dropdown-item>
                                    </b-dropdown>
                                    <!-- <b-dropdown id="dropdown-offset" text="" class=""> -->
                                      <!-- <b-dropdown-item href="#" v-b-modal.create-branch>Create Branch</b-dropdown-item> -->
                                      <!-- <b-dropdown-item href="#">Copy Message</b-dropdown-item> -->
                                      <!-- <b-dropdown-item href="#" @click="readrecipt(chatmessage.sendermsg)">Read Receipts</b-dropdown-item>
                                    </b-dropdown> -->
                                  </div>
                            </div>
                            <div class="message right poll-img poll-progress consume" v-else>
                              <div>
                                <div class="poll-img-popup">
                                      <h4>{{chatmessage.question.question}}</h4>
                                      <ul>
                                        <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index">
                                          <b-progress :value="polldata.count*100/chatmessage.question.totalCount" :max="max" class="mb-1"></b-progress>
                                            <h5>{{Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2))}}% {{polldata.text}}</h5>
                                        </li>
                                      
                                      </ul>
                                </div>
                                <div class="message-nt">
                                  <span class="poll-expaire">
                                    <img class="poll-small-icon" src="../assets/images/fd1.png" style="height:22px;width:22px">
                                    <p><span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                  </span>
                                  <div class="time_sendmark">
                                  <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                  <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                  <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/send-arrow.png" style="height:12px;width:10px" /></span>
                                  <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:12px;width:10px" /></span>
                                  <span v-else><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                  </div>
                                </div>
                                  <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                              </div>
                                <div>
                                  <b-dropdown id="dropdown-offset" text="" class="">
                                      <b-dropdown-item href="#"  @click="messageDelete(chatmessage)">Delete</b-dropdown-item>
                                    </b-dropdown>
                                  <!-- <b-dropdown id="dropdown-offset" text="" class=""> -->
                                    <!-- <b-dropdown-item href="#" v-b-modal.create-branch>Create Branch</b-dropdown-item> -->
                                    <!-- <b-dropdown-item href="#">Copy Message</b-dropdown-item> -->
                                    <!-- <b-dropdown-item href="#" @click="readrecipt(chatmessage.sendermsg)">Read Receipts</b-dropdown-item> -->
                                  <!-- </b-dropdown> -->
                                </div>
                            </div>

                          </div>

                          <div class="message right poll poll-result2" v-if="chatmessage.type.toLowerCase() == 'textpoll' && chatmessage.messageType == 'sender' && chatmessage.expired">
                            <div>
                              <div class="poll-req">
                                    <h4>Poll Result</h4>
                                    <h5>{{chatmessage.question.question}}</h5>
                                    
                                    <ul>
                                      <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index" :class="{active : chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text}" >
                                        <a href="#">
                                            <img src="../assets/images/poll-win.png" class="pollwinicon" v-if="chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text">
                                            {{polldata.text}} ({{chatmessage.question.totalCount>0?Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2)):0}}%)
                                        </a>
                                      </li>
                                    </ul>
                              </div>
                              <div class="message-nt">
                                  <span class="poll-expaire">
                                    <p><span>Total Votes</span>:<span>{{chatmessage.question.totalCount}}</span></p>
                                  </span>
                                  <div class="time_sendmark">
                                  <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                  <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                  <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/send-arrow.png" style="height:12px;width:10px" /></span>
                                  <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:12px;width:10px" /></span>
                                  <span v-else><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                  </div>
                                </div>
                                  <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                              </div>
                              <div>
                                <b-dropdown id="dropdown-offset" text="" class="">
                                  <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                    <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                  </div>
                                      <b-dropdown-item href="#"  @click="messageDelete(chatmessage)">Delete</b-dropdown-item>
                                    </b-dropdown>
                                  <!-- <b-dropdown id="dropdown-offset" text="" class=""> -->
                                  <!-- <b-dropdown-item href="#" v-b-modal.create-branch>Create Branch</b-dropdown-item> -->
                                  <!-- <b-dropdown-item href="#">Copy Message</b-dropdown-item> -->
                                  <!-- <b-dropdown-item href="#" @click="readrecipt(chatmessage.sendermsg)">Read Receipts</b-dropdown-item> -->
                                <!-- </b-dropdown> -->
                              </div>
                          </div>


                            <!-- FOR SENDERIMAGES -->
                          <div v-if="chatmessage.type.toLowerCase() == 'imagepoll' && chatmessage.messageType == 'sender' && !chatmessage.expired">
                            <div class="message right poll-img" v-if="!chatmessage.answered">
                              <div>
                                <div class="poll-img-popup">
                                      <h4>{{chatmessage.question.question}}</h4>
                                      <ul>
                                      <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index">
                                          <a class="lightbox" :href="'#'+chatmessage.defaultid+index">
                                          <img :src="polldata.imageUrl">
                                          <img src="../assets/images/open-eye.png" style="height:auto" class="eye">
                                          </a>
                                          <div class="lightbox-target" :id="chatmessage.defaultid+index">
                                            <div class="lg_wrapper">
                                              <img :src="polldata.imageUrl"/>
                                              <a class="lightbox-close" href="#"></a>
                                            </div>
                                          </div>
                                          <div class="radio">
                                              <input :id="'radio-'+index" name="radio" type="radio" :value="polldata.text" @change="selectOption(polldata.text,chatmessage.defaultid,chatmessage.chatId)" >
                                              <label :for="'radio-'+index" class="radio-label">{{polldata.text}}</label>
                                          </div>

                                        </li>
                                      
                                      </ul>
                                </div>
                                <div class="message-nt">
                                  <span class="poll-expaire">
                                    <img class="poll-small-icon" src="../assets/images/fd1.png" style="height:22px;width:22px">
                                    <p><span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                  </span>
                                
                                  <div class="time_sendmark">
                                  <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                  <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/send-arrow.png" style="height:12px;width:10px" /></span>
                                  <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:12px;width:10px" /></span>
                                  <span v-else><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                  </div>
                                </div>
                                  <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                              </div>
                                <div>
                                  <b-dropdown id="dropdown-offset" text="" class="">
                                    <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                      <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                    </div>
                                      <b-dropdown-item href="#"  @click="messageDelete(chatmessage)">Delete</b-dropdown-item>
                                    </b-dropdown>
                                  <!-- <b-dropdown id="dropdown-offset" text="" class=""> -->
                                    <!-- <b-dropdown-item href="#" v-b-modal.create-branch>Create Branch</b-dropdown-item> -->
                                    <!-- <b-dropdown-item href="#">Copy Message</b-dropdown-item> -->
                                    <!-- <b-dropdown-item href="#" @click="readrecipt(chatmessage.sendermsg)">Read Receipts</b-dropdown-item> -->
                                  <!-- </b-dropdown> -->
                                </div>
                            </div>
                            <div class="message right poll-img most_like" v-else>
                              <div>
                                <div class="poll-img-popup">
                                      <h4>{{chatmessage.question.question}}</h4>
                                      <ul>
                                    <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index">
                                          <a class="lightbox" :href="'#'+chatmessage.defaultid+index">
                                          <img :src="polldata.imageUrl">
                                          <img src="../assets/images/open-eye.png" style="height:auto" class="eye">
                                          </a>
                                          <div class="lightbox-target" :id="chatmessage.defaultid+index">
                                            <div class="lg_wrapper">
                                              <img :src="polldata.imageUrl"/>
                                              <a class="lightbox-close" href="#"></a>
                                            </div>
                                          </div>
                                          <h5 class="mt-1">{{polldata.text}}</h5>
                                          <b-progress :value="polldata.count*100/chatmessage.question.totalCount" :max="max" class="mb-1"></b-progress>
                                            <h5>{{Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2))}}% </h5>
                                        </li>
                                      </ul>
                                      <div class="message-nt">
                                        <span class="poll-expaire">
                                          <img class="poll-small-icon" src="../assets/images/fd1.png" style="height:22px;width:22px">
                                          <p><span>Poll expires on</span>:<span>{{checkDate(chatmessage.expireyTimeObjects)}} at {{chatmessage.question.time}}</span></p>
                                        </span>
                                        <div class="time_sendmark">
                                          <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                          <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                          <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/send-arrow.png" style="height:12px;width:10px" /></span>
                                          <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:12px;width:10px" /></span>
                                          <span v-else><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                        </div>
                                      </div>
                                  <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                                </div>
                                
                              </div>
                                <div>
                                  <b-dropdown id="dropdown-offset" text="" class="">
                                    <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                      <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                    </div>
                                      <b-dropdown-item href="#"  @click="messageDelete(chatmessage)">Delete</b-dropdown-item>
                                    </b-dropdown>
                                  <!-- <b-dropdown id="dropdown-offset" text="" class=""> -->
                                    <!-- <b-dropdown-item href="#" v-b-modal.create-branch>Create Branch</b-dropdown-item> -->
                                    <!-- <b-dropdown-item href="#">Copy Message</b-dropdown-item> -->
                                    <!-- <b-dropdown-item href="#" @click="readrecipt(chatmessage.sendermsg)">Read Receipts</b-dropdown-item> -->
                                  <!-- </b-dropdown> -->
                                </div>
                            </div>
                          </div>
                          <div class="message right poll-img most_like poll-result" v-if="chatmessage.type.toLowerCase() == 'imagepoll' && chatmessage.messageType == 'sender' && chatmessage.expired">
                            <div>
                              <div class="poll-img-popup">
                                    <h4>Poll Result</h4>
                                    <h5>{{chatmessage.question.question}}</h5>
                                    <ul>
                                    <li v-for="(polldata,index) in chatmessage.polldata" v-bind:key="index" :class="{active : chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text}">

                                        <a class="lightbox">
                                          <img :src="polldata.imageUrl">
                                        </a>
                                        <div>
                                        <h5 class="mt-1" > 
                                            <img src="../assets/images/poll-win.png" class="pollwinicon" v-if="chatmessage.mypollanswer && chatmessage.mypollanswer == polldata.text">

                                          {{polldata.text}} ({{chatmessage.question.totalCount>0?Math.round(parseFloat(polldata.count*100/chatmessage.question.totalCount).toFixed(2)):0}}%)</h5>
                                        </div>
                                    </li>
                                    </ul>
                                    <div class="message-nt">
                                      <span class="poll-expaire">
                                        <p><span>Total Votes</span>:<span>{{chatmessage.question.totalCount}}</span></p>
                                      </span>
                                      <div class="time_sendmark">
                                        <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                        <span class="u_time" v-else>{{chatmessage.beforeTime}}</span>
                                        <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/send-arrow.png" style="height:12px;width:10px" /></span>
                                        <span v-else-if="chatmessage.messageSeen.length == connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:12px;width:10px" /></span>
                                        <span v-else><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                      </div>
                                    </div>
                                  <span class="u_name" style="cursor:pointer;color:#ffab3f;" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                              </div>
                              
                            </div>
                              <div>
                                <b-dropdown id="dropdown-offset" text="" class="">
                                  <div v-if="chatmessage.type.toLowerCase() != 'selfiedeclientext'">
                                    <b-dropdown-item href="#" v-if="!subSubBranchActive && !checkBranchExists(chatmessage.branch)" v-b-modal.create-branch @click.prevent="createBranchMessageId=chatmessage.defaultid">Create Branch</b-dropdown-item>
                                  </div>
                                  <b-dropdown-item href="#"  @click="messageDelete(chatmessage)">Delete</b-dropdown-item>
                                </b-dropdown>
                                <!-- <b-dropdown id="dropdown-offset" text="" class=""> -->
                                  <!-- <b-dropdown-item href="#" v-b-modal.create-branch>Create Branch</b-dropdown-item> -->
                                  <!-- <b-dropdown-item href="#">Copy Message</b-dropdown-item> -->
                                  <!-- <b-dropdown-item href="#" @click="readrecipt(chatmessage.sendermsg)">Read Receipts</b-dropdown-item> -->
                                <!-- </b-dropdown> -->
                              </div>
                          </div>
                      </div>
                    
                      <div v-if="preview_list.length!=0">
                        <div class="message right" v-for="(val,index) in preview_list" :key="index">
                          <div>

                            <!-- {{image_list[index].type}} -->
                            <!-- {{val}} -->
                            <!-- {{image_list[index].type.split('/')[1]}} -->
                              <p class="downloadIconWrapper">
                                <img v-if="val.docType == 'Image'" :src="val.file">
                                <video style="max-width:100%;height:150px;width:200px;object-fit:contain" v-else-if="val.docType=='Video'" :src="val.file"></video>
                                <img v-else-if="val.docType=='pdf'" src="../assets/images/PDF_file_icon.png">
                                <img v-else-if="val.docType=='excel'" src="../assets/images/Exl-icon.png">
                                <img v-else-if="val.docType=='word'" src="../assets/images/Word_Icon.png">
                                <img v-else-if="val.docType=='odt'" src="../assets/images/ODT.png">
                                <img v-else-if="val.docType=='ods'" src="../assets/images/ODS.png">
                                <img v-else-if="val.docType=='ppt'" src="../assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png">
                                <img v-else-if="val.docType=='html'" src="../assets/images/images.png">
                                <img v-else-if="val.docType=='plain'" src="../assets/images/834209.png">
                                <img v-else-if="val.docType=='xd'" src="../assets/images/imagesxd.png">
                              
                                <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
                                <!-- <img src="../assets/images/download_icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.sendermsg,chatmessage.name)"/> -->
                              </p>
                              <span>{{image_list[index].text}}</span>
                              <!-- <span style="float:right"><img src="@/assets/images/send-arrow_red.png" style="height:15px;width:15px" /></span> -->
                              <!-- Show Red Arrow -->
                          </div>
                        </div>
                      </div>

                      <!-- show poll before send -->
                      <div v-if="showPoll">
                        <div class="message right poll-img">
                          <div>
                            <div class="poll-img-popup">
                              <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
                                  <h4>{{showPollData.question.question.question}}</h4>
                                  <ul>
                                    {{showPollData.images}}
                                    {{showPollData.imagetexts}}
                                  <li v-for="(polldata,index) in showPollData.question.images" v-bind:key="index">
                                      <a class="lightbox" :href="'#'+index">
                                      <img :src="polldata">
                                      <img src="../assets/images/open-eye.png" style="height:auto" class="eye">
                                      </a>
                                      <div class="lightbox-target" :id="index">
                                        <div class="lg_wrapper">
                                          <img :src="polldata"/>
                                          <a class="lightbox-close" href="#"></a>
                                        </div>
                                      </div>
                                      <div class="radio">
                                          <input :id="'radio-'+index" name="radio" type="radio" disabled>
                                          <label :for="'radio-'+index" class="radio-label">{{showPollData.question.imagetexts[index]}}</label>
                                      </div>

                                    </li>
                                  
                                  </ul>
                              <!-- <span style="float:right"><img src="@/assets/images/send-arrow_red.png" style="height:15px;width:15px" /></span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- show poll end -->

                      <!-- show message -->
                      <div class="message right" v-if="showMsgText">
                            <div>
                              <!-- searchbaroption  :'',
                                searchkeyword :'' -->
                                <!-- <p>{{chatmessage.sendermsg}}</p> -->
                                <p>{{sendingMessage}}</p>
                                <div class="message-nt msg-nt-left">
                                  <div class="time_sendmark">
                                  <!-- <span class="u_name" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                                  <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                  <span class="u_time" v-else>{{chatmessage.beforeTime}}</span> -->
                                  <!-- <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                  <span v-else-if="chatmessage.messageSeen.length connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:15px;width:15px" /></span> -->
                                  <!-- <span ><img src="@/assets/images/send-arrow_red.png" style="height:15px;width:15px" /></span> -->
                                  </div>
                                </div>
                            </div>
                      </div>
                      <!-- show message -->
                      <div v-if="false">
                        <div class="message right" v-for="(data,index) in tempMessages" :key="index+'message'">
                              <div>
                                <!-- searchbaroption  :'',
                                  searchkeyword :'' -->
                                  <!-- <p>{{chatmessage.sendermsg}}</p> -->
                                  <p>{{data}}</p>
                                  <div class="message-nt msg-nt-left">
                                    <div class="time_sendmark">
                                    <!-- <span class="u_name" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                                    <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                    <span class="u_time" v-else>{{chatmessage.beforeTime}}</span> -->
                                    <!-- <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                    <span v-else-if="chatmessage.messageSeen.length connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:15px;width:15px" /></span> -->
                                    <span ><img src="@/assets/images/ClockIcon.png" style="height:15px;width:15px" /></span>
                                    </div>
                                  </div>
                              </div>
                        </div>
                      </div>
                      <!-- show message end -->

                      <!-- show Audio -->
                      <div class="message right" v-show="showRecordedMessage">
                        <div>
                          <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
                          <p>
                            <audio id="adioPlay" controls></audio>
                          </p>
                          <div class="message-nt msg-nt-left">
                              <div class="time_sendmark">
                              <!-- <span class="u_name" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                              <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                              <span class="u_time" v-else>{{chatmessage.beforeTime}}</span> -->
                              <!-- <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                              <span v-else-if="chatmessage.messageSeen.length connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:15px;width:15px" /></span> -->
                              <!-- <span ><img src="@/assets/images/send-arrow_red.png" style="height:15px;width:15px" /></span> -->
                              </div>
                            </div>
                        </div>
                      </div>
                      <!-- show Audio End -->
                    </div>

                      <div v-if="preview_list.length!=0 && sendreceArray.length==0">
                        <div class="message right" v-for="(val,index) in preview_list" :key="index">
                          <div>

                            <!-- {{image_list[index].type}} -->
                            <!-- {{val}} -->
                            <!-- {{image_list[index].type.split('/')[1]}} -->
                              <p class="downloadIconWrapper">
                                <img v-if="val.docType == 'Image'" :src="val.file">
                                <video style="max-width:100%;height:150px;width:200px;object-fit:contain" v-else-if="val.docType=='Video'" :src="val.file"></video>
                                <img v-else-if="val.docType=='pdf'" src="../assets/images/PDF_file_icon.png">
                                <img v-else-if="val.docType=='excel'" src="../assets/images/Exl-icon.png">
                                <img v-else-if="val.docType=='word'" src="../assets/images/Word_Icon.png">
                                <img v-else-if="val.docType=='odt'" src="../assets/images/ODT.png">
                                <img v-else-if="val.docType=='ods'" src="../assets/images/ODS.png">
                                <img v-else-if="val.docType=='ppt'" src="../assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png">
                                <img v-else-if="val.docType=='html'" src="../assets/images/images.png">
                                <img v-else-if="val.docType=='plain'" src="../assets/images/834209.png">
                                <img v-else-if="val.docType=='xd'" src="../assets/images/imagesxd.png">
                                <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
                                <!-- <img src="../assets/images/download_icon.png" class="downloadIcon" @click.prevent="downloadDocument(chatmessage.sendermsg,chatmessage.name)"/> -->
                              </p>
                              <!-- Show Red Arrow -->
                              <span>{{image_list[index].text}}</span>
                              <!-- <span style="float:right"><img src="@/assets/images/send-arrow_red.png" style="height:15px;width:15px" /></span> -->
                          </div>
                        </div>
                      </div>

                      <!-- show poll before send -->
                      <div v-if="showPoll && showPollData.question.images.length!=0 && sendreceArray.length==0">
                        <!-- {{showPoll}}
                        {{showPollData}} -->
                        <div class="message right poll-img">
                          <div>
                            <div class="poll-img-popup">
                              <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
                                  <h4>{{showPollData.question.question.question}}</h4>
                                  <ul>
                                    {{showPollData.images}}
                                    {{showPollData.imagetexts}}
                                  <li v-for="(polldata,index) in showPollData.question.images" v-bind:key="index">
                                      <a class="lightbox" :href="'#'+index">
                                      <img :src="polldata">
                                      <img src="../assets/images/open-eye.png" style="height:auto" class="eye">
                                      </a>
                                      <div class="lightbox-target" :id="index">
                                        <div class="lg_wrapper">
                                          <img :src="polldata"/>
                                          <a class="lightbox-close" href="#"></a>
                                        </div>
                                      </div>
                                      <div class="radio">
                                          <input :id="'radio-'+index" name="radio" type="radio" disabled>
                                          <label :for="'radio-'+index" class="radio-label">{{showPollData.question.imagetexts[index]}}</label>
                                      </div>
                                    </li>
                                  </ul>
                            </div>
                            <!-- <span style="float:right"><img src="@/assets/images/send-arrow_red.png" style="height:15px;width:15px" /></span> -->
                          </div>
                        </div>
                      </div>
                      <!-- show poll end -->

                      <!-- show message -->
                      <div class="message right" v-if="showMsgText && sendreceArray.length==0">
                            <div>
                              <!-- searchbaroption  :'',
                                searchkeyword :'' -->
                                <!-- <p>{{chatmessage.sendermsg}}</p> -->
                                <p>{{sendingMessage}}</p>
                                <div class="message-nt msg-nt-left">
                                  <div class="time_sendmark">
                                  <!-- <span class="u_name" @click.prevent="openBranchFromMessage(chatmessage.branch)" v-if="checkBranchExists(chatmessage.branch)">{{chatmessage.branch}}</span>
                                  <span class="u_time" v-if="!beforeDateExpired(chatmessage.beforeDate)">{{chatmessage.Time}}</span>
                                  <span class="u_time" v-else>{{chatmessage.beforeTime}}</span> -->
                                  <!-- <span v-if="chatmessage.messageSeen.length != connectedUserLength"><img src="@/assets/images/send-arrow.png" style="height:15px;width:15px" /></span>
                                  <span v-else-if="chatmessage.messageSeen.length connectedUserLength"><img src="@/assets/images/send-arrow-green.png" style="height:15px;width:15px" /></span> -->
                                  <!-- <span ><img src="@/assets/images/send-arrow_red.png" style="height:15px;width:15px" /></span> -->
                                  </div>
                                </div>
                            </div>
                      </div>
                      <!-- show message end -->

                      <!-- show Audio -->
                      <div class="message right" v-show="showRecordedMessage && sendreceArray.length==0">
                        <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
                        <p>
                          <audio id="adioPlay" controls></audio>
                        </p>
                        <div class="message-nt">
                          <!-- <span style="float:right"><img src="@/assets/images/send-arrow_red.png" style="height:15px;width:15px" /></span> -->
                        </div>
                      </div>
                      <!-- show Audio End -->
                    
                      <div class="message-footer" :class="{'hideFooterForDooeyAdmin': botUserEmail == chatObject.email}" >
                        <form>
                          <div class="form-footer-type socialTextAreaForMessagetYPE" >
                            <div class="form-group addtextarea" v-show="!startRecording">
                              <textarea @paste="renderFiles" class="form-control" placeholder="Type here" @keydown="listenForEnter" maxlength="5000" @keyup="checkChrecterLengthMessage(msgTextTmp)" v-model="msgTextTmp" :disabled="botUserEmail == chatObject.email"></textarea>
                              <span v-if="charecterErrorMessageForMessage != ''" style="color:red;">{{charecterErrorMessageForMessage}}</span>
                            </div>
                            <div v-show="startRecording" style="display:flex" class="form-group addtextarea">
                                <audio id="player" style="width: 100%;" controls></audio>
                                <!-- <audio id="adioPlay" v-show="showRecording" controls></audio> -->
                                <!-- <button id="stop" class="btn stopRecordBtn" @click.prevent="stopRecord()">Stop</button> -->
                              </div>
                              <button v-if="startRecording" class="btn stopRecordBtn" @click.prevent="cancelRecord()">Cancel</button>
                            <!-- <b-dropdown id="dropdown-dropup" dropup text="" class=""> -->
                              <!-- <b-dropdown-item href="#"><img src="../assets/images/fd4.png"></b-dropdown-item>
                              <b-dropdown-item href="#"><img src="../assets/images/fd3.png"></b-dropdown-item>
                              <b-dropdown-item href="#">
                                <img src="../assets/images/fd2.png" >
                                <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file" />
                              </b-dropdown-item>
                              <b-dropdown-item href="#" v-b-modal.createpoll><img src="../assets/images/fd1.png"></b-dropdown-item> -->
                            <!-- </b-dropdown>  -->
                              <button :disabled="botUserEmail == chatObject.email" @click.prevent="openDropdown()" v-if="!startRecording && !surpriseSelfie" type class="dropbtn without-bg-btn">
                                <!-- <img src="../assets/images/add-branch.png" style="cursor: pointer;" class=""> -->
                              </button>
                          
                              <div id="myDropdown" class="dropdown-content">
                                <a href="#" v-if="!activeGroup">
                                  <img src="@/assets/images/surpriseSelfieIcon.png" height="25px" width="25px" @click="surpriseSelfieSend()"  title="Surprice selfie">
                                </a>
                                <a href="#">
                                  <!-- <img src="../assets/images/fd4.png"> -->
                                  <img src="../assets/images/fd4.png" @click.prevent="record()" title="Record a message">
                                  <!-- <input type="file" accept="audio/*"  capture id="recorder" @change="previewAudio"> -->
                                  </a>
                                
                                <!-- <a href="#">
                                  <img src="../assets/images/fd2.png" >
                                  <div class="file_input_wrapper2">
                                  <input multiple type="file" accept="image/*,video/*" @change="
                                  
                                  
                                  " class="form-control-file" id="my-file" />
                                  <label for="my-file"><img src="@/assets/images/fd2.png"/></label>
                                  </div>
                                </a> -->
                                <a href="#">
                                  <!-- <img src="../assets/images/fd3.png"> -->
                                  <div class="file_input_wrapper">
                                    <label class="myFileLabel" for="my-file1" title="Add files" >
                                      <input multiple type="file"  @input="previewListAddImage"  class="form-control-file" id="my-file1"/>
                                    </label>
                                    
                                    </div>
                                </a>
                                <a href="#" v-b-modal.createpoll ><img src="../assets/images/fd1.png" title="Create poll"></a>
                              </div>


                            <button v-if="!surpriseSelfie" @click.prevent="!sendingMessageFlag?startRecording?stopRecord():sendMessage():'',!sendingMessageFlag?startRecording?'':checkEmpty():''" class="btn btn-yellow send-btn" :class="{disabled : !checkEmpty()&&!startRecording}" type="submit">Send</button>
                            <button v-else @click.prevent="!sendingMessageFlag?startRecording?stopRecord():sendMessage():'',!sendingMessageFlag?startRecording?'':checkEmpty():''" class="btn btn-yellow send-btn surpriseselfiebutton" :class="{disabled : !checkEmpty()&&!startRecording}" type="submit"><img src="@/assets/images/surpriseSelfieIcon.png" height="35px" width="35px"/></button>
                            <a v-if="surpriseSelfie" @click.prevent="surpriseSelfie = false"><img src="@/assets/images/cancel-circle.png"/></a>
                          </div>
                        </form>
                      </div>
                </div>
              </div>
              <!-- {{activeChat}} -->
                <Noactivechat v-if="selectedActiveMenu == 'activeList' && activeChat == false"></Noactivechat>
                <Nocontact v-if="selectedActiveMenu == 'contact' && activeChat == false"></Nocontact>
            </div>
            <div class="message-screen dropzone_bg_custom" v-else>
              <button @click="closeAllPreview()" class="close_preview_screen">X</button>
                <div style="visibility:hidden; opacity:0; z-index:1;" id="dropzone">
                  <div id="textnode">Drop file to share.</div>
                </div>
                
                <div v-if="preview_list.length!=0 && preview_list[previewIndex]" class="preview_image_wrapper">
                  <!-- {{preview_list[previewIndex]}} -->
                    <img v-if="preview_list[previewIndex].docType == 'Image'" :src="preview_list[previewIndex].file" alt="">
                    <video style="" v-else-if="preview_list[previewIndex].docType=='Video'" :src="preview_list[previewIndex].file"></video>
                    <img v-else-if="preview_list[previewIndex].docType=='pdf'" src="@/assets/images/PDF_file_icon.png"  class="pdf_icon">
                    <img v-else-if="preview_list[previewIndex].docType=='excel'" src="@/assets/images/Exl-icon.png" class="pdf_icon">
                    <img v-else-if="preview_list[previewIndex].docType=='word'" src="@/assets/images/Word_Icon.png" class="pdf_icon">
                    <img v-else-if="preview_list[previewIndex].docType=='odt'" src="@/assets/images/ODT.png" class="pdf_icon">
                    <img v-else-if="preview_list[previewIndex].docType=='ods'" src="@/assets/images/ODS.png" class="pdf_icon">
                    <img v-else-if="preview_list[previewIndex].docType=='ppt'" src="@/assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon">
                    <img v-else-if="preview_list[previewIndex].docType=='html'" src="@/assets/images/images.png" class="pdf_icon">
                    <img v-else-if="preview_list[previewIndex].docType=='plain'" src="@/assets/images/834209.png" class="pdf_icon">
                    <img v-else-if="preview_list[previewIndex].docType=='xd'" src="@/assets/images/imagesxd.png" class="pdf_icon">
                </div>
                <div class="preview_img_text" v-if="image_list.length > 0">
                  <input type="text" class="form-control" placeholder="Enter Text" name="addText" v-model="image_list[previewIndex].text">
                </div>
                <div>
                </div>
                <div class ="dreg_drop_wrapper">
                  <ul class="dreg_drop_message">
                    <li class="downloadIconWrapper" v-for="(val,index) in preview_list" :key="index" @click="previewIndex=index">
                          <img v-if="val && val.docType == 'Image'" :src="val.file" height="150px" width="200px" style="object-fit:cover">
                          <video style="" class="drag_drop_video" v-else-if="val && val.docType=='Video'" :src="val.file"></video>
                          <img v-else-if="val && val.docType=='pdf'" src="../assets/images/PDF_file_icon.png" class="pdf_icon">
                          <img v-else-if="val && val.docType=='excel'" src="../assets/images/Exl-icon.png" class="pdf_icon">
                          <img v-else-if="val && val.docType=='word'" src="../assets/images/Word_Icon.png" class="pdf_icon">
                          <img v-else-if="val && val.docType=='odt'" src="../assets/images/ODT.png" class="pdf_icon">
                          <img v-else-if="val && val.docType=='ods'" src="../assets/images/ODS.png" class="pdf_icon">
                          <img v-else-if="val && val.docType=='ppt'" src="../assets/images/png-transparent-computer-icons-pdf-microsoft-powerpoint-ppt-data-text-orange-logo.png" class="pdf_icon">
                          <img v-else-if="val && val.docType=='html'" src="../assets/images/images.png" class="pdf_icon">
                          <img v-else-if="val && val.docType=='plain'" src="../assets/images/834209.png" class="pdf_icon">
                          <img v-else-if="val && val.docType=='xd'" src="../assets/images/imagesxd.png" class="pdf_icon">
                          <button @click="removeFromPreview(index)">X</button>
                        </li>
                        <li class="downloadIconWrapper">
                          <div class="file_input_wrapper">
                            <label class="myFileLabel" for="my-file2" >
                                <input multiple type="file"  @input="previewListAddImage"  class="form-control-file" id="my-file2"/>
                            </label>
                          </div>
                        </li>
                  </ul>
                
                  <button class="send_drop_msg btn btn-yellow" @click="sendAllDropMedia2()">Send</button>
                </div>
            </div>
            <!-- message screen third End -->
            <CreatePoll :groupDocId="chatObjectId" :mainBranchActive="mainBranchActive" :mainBranchActiveIndex="mainBranchActiveIndex" :subBranchActive="subBranchActive" :subBranchActiveIndex="subBranchActiveIndex" :subSubBranchActive="subSubBranchActive" :subSubBranchActiveIndex="subSubBranchActiveIndex" :connectedUsers="connectedUsers" :messageSeenIds="messageSeenIds" @assignDocId="assignNewDocId" :currCollection="currCollection"></CreatePoll>
          </div>
        
        </section>
          <!--image Zoom Modal -->
        <b-modal id="imageZoom" centered hide-footer :no-close-on-backdrop="true">
          <div class="add-member-content contact-modal chatImageZoom" v-for="(imageZoom,index) in sendreceArray" v-bind:key="index">
            <img  v-if="imageZoom.createdAt ==  imageTimeForZoom && imageZoom.messageType == 'sender'" :src="imageZoom.mediaUrl"/>
            <img  v-if="imageZoom.createdAt ==  imageTimeForZoom && imageZoom.messageType == 'receiver'" :src="imageZoom.mediaUrl"/>
              <!-- <h4>Add Member</h4> -->
              <!-- <form class="modal-search">
                  <div class="form-group">
                    <input type="search" class="form-control" placeholder="Search">
                    <img src="../assets/images/search.png" class="search-icon">
                  </div>
              </form>
              <ul class="modal-member-list">
                <li v-for="(newmember,index) in NewGroupMember" v-bind:key="index">
                  <div class="ml-item">
                    <img :src="newmember.profileUrl" class="member-img">
                    <span>{{newmember.firstName}} {{newmember.lastName}}</span>
                    <button class="btn btn-white" @click="addMemberInGroup(newmember.id,index)">Add</button>
                  </div>
                </li>
              </ul> -->
          </div>
        </b-modal>
        <!--image Zoom Modal End -->
        <!-- Admin panel Design Start  -->

                <div class="Admin_Main_wrapper">
                  <AdminSidebarMenu v-if="isAdmin == true "></AdminSidebarMenu>
                </div>   
                <CreateBranch :mainBranches="mainBranches" :groupDocId="chatObjectId" :mainBranchActive="mainBranchActive" :createBranchMessageId="createBranchMessageId" :mainBranchActiveIndex="mainBranchActiveIndex" :subBranchActive="subBranchActive" :subBranchActiveIndex="subBranchActiveIndex" :connectedUsers="connectedUsers"/>
                <!-- <CreateBranch2 @ok="createBranch" :mainBranchActiveIndex="mainBranchActiveIndex" :subBranchActive="subBranchActive" :mainBranchActive="mainBranchActive" :subBranchActiveIndex="subBranchActiveIndex"/> -->
            <!-- Admin panel Design End  -->
            <!-- <Sidebar v-if="activeGroup == true"   :activeGroup = activeGroup  :groupData ="groupData"  ></Sidebar>
            <Sidebar v-else :chatObject= chatObject :activeGroup = activeGroup ></Sidebar> -->
      </div>
</template>

    <script>
      import { mapGetters,mapActions } from "vuex";
      import Sidebar from "@/components/sidebar.vue";
      import Header from "@/components/header.vue";
      import AdminHeader from "@/components/adminlayout/adminheader.vue";
      import Noactivechat from "@/components/noactivechat.vue";
      import Nocontact from "@/components/nocontact.vue";
      import ActiveChatList from "@/components/activeChatList.vue";
      import ContactDetail from "@/components/contactDetail.vue";
      import CreatePoll from "@/components/allModal/createpoll.vue";
      import AdminSidebarMenu from "@/components/adminlayout/adminSidebarMenu.vue";
      import firebase from "./firebaseInit"
      import firebaseData from '../utils/firebaseCollection.js';
      import firebaseTimestamp from "firebase";
      import {uploadImageFile} from '../utils/storageQuerry.js';
      import Vue from 'vue'
      import CreateBranch from "../components/allModal/createbranch.vue";
      import Swal from 'sweetalert2';
      import VueChatScroll from 'vue-chat-scroll';
      import {selectPollOption,updatePollAnswer,updateAnswerCollection,getMessageSeenCount,getLoginUserData,getSocialPlanData} from '../utils/getQueeryActiveChatList.js';
      const db = firebase.firestore();

      import CreateBranch2 from "../components/allModal/createbranch2.vue";

      const UpdateChatFunction = firebase.functions().httpsCallable('updateChat');

      Vue.use(VueChatScroll)
      import SyncLoader from 'vue-spinner/src/SyncLoader.vue'
      import MicRecorder from 'mic-recorder-to-mp3'
      const recorder = new MicRecorder({
        bitRate: 128
      });
      import BusinessChatList from "@/components/businessComponent/BusinessChatList.vue"
      import BusinessDirectMessage from '@/components/businessComponent/BusinessChannel.vue'
      import BusinessUser from '@/components/businessComponent/businessUser.vue'
      import TextHighlight from 'vue-text-highlight';
      Vue.component('text-highlight', TextHighlight);

      export default {
        name: "chat",
          components: {
            CreateBranch2,
            Sidebar,
            Header,
            AdminHeader,
            Noactivechat,
            Nocontact,
            ActiveChatList,
            ContactDetail,CreatePoll,AdminSidebarMenu,
            firebaseData,
            CreateBranch,
            BusinessChatList,
            BusinessDirectMessage,
            BusinessUser,
            SyncLoader
          },
          data() {
            return {
              chatSnapshotListener:null,
              snapShotListener:null,
              tempMessages:[],
              previewIndex:0,
              messageSeenIds:[],
              showLoader:false,
              showLoaders : false,
              startRecording:false,
              msgTextTmp:'',

              selectedActiveMenu: "activeList",
                max: 100,
                isSpinner:false,
                loginUser :'',
                isAdmin:false,
                imageUrl:'',
                activeChat:false,
                activeGroup:false,
                userData:{},
                chatObject:{
                  firstName:'',
                  lastName:'',
                  profileUrl:'',
                  userid:''
                },
                groupData:{},
                sendingMessage:'',
                connectedUserArray:[],
                updateChat:{},
                from:{},
                isChatting:false,
                socialMessageid:'',
                senderArray : [],
                // receiverArray : [],
                sendreceArray:[],
                senderchatId:'',
                // receiverchatId:'',
                months:['Jan','Feb','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
                chatid:'',
                chatObjectId:'',
                lastVisible:null,
                groupMember:{},
                chattedId:'',
                lastVisible:0,
                preview: null,
              image: [],
              preview_list: [],
              image_list: [],
              getChatId:'',
              firstMessageId:'',
              imageTimeForZoom:'',
              bulkDataArray:[],
              BulkMessage:'',
              businessMenuActive:false,

              mainBranches:[],
              mainBranchActive:false,
              mainBranchActiveIndex:0,
              subBranchActive:false,
              subBranchActiveIndex:0,
              subSubBranchActive:false,
              subSubBranchActiveIndex:0,
              branchName:'',

              
              isAnswerScreen:false,
              uploadData:[],
              pollquestions:{},
              connectedUserLength:'',
              todayTime:{
                hrs:'',
                min:''
              },
              DateOFToday: new Date().toISOString().split('T')[0],
              splitDateObject:{
                date:'',
                month:"",
                year:''
              },
              mypollanswer:'',
              isHighlight:false,
            searchbaroption  :'',
            searchkeyword :'',
            copyMessage:false,


            previewSenderImage:'',
            fromArray:[],

            connectedUsers:[],
            showPoll:false,
            showPollData:'',

            searchedChat:[],
            sendreceArrayBKP:[],

            sendingMessageFlag:false,
            currCollection:'',
            createBranchMessageId:'',

            unreadMessages:0,
            showMsgText:false,
            showRecordedMessage:false,
            loginCustomeId:'',
            companyuserlimit:{},
            isGroupMuted:false,

            fuid:'',
            searchType:'',

            //Branch Pagination.
            currentPage:0,
            totalPages:0,
            branchPaginatedData:[],
            subBranchPaginatedData:[],
            subSubBranchPaginatedData:[],

            startIndex:0,
            endIndex:6,
            companyuserlimit:{},
            dropFiles:false,
            messageHistory : '',
            previewImageSize:0,
            chatkeyword : '',
            searchErrorMessage:'',
            audioplay:false,
            starttime:'',
            stoptime:'',
            audiotime : '',
            audioActiveId:'',
            blockUserArray:[],
            isInArray:'',
            surpriseSelfie:false,
            socialPlanData:{},
            opendropdown:'',
            openSubdropdown:'',
            openSubSubdropdown:'',
            selectDropdown:false,
            selectSubDropdown:false,
            selectSubSubDropdown:false,
            openMainBranchActiveDropdown:'',
            openSubBranchActiveDropdown:'',
            openSubSubBranchActiveDropdown:'',
            selectMainBranchActiveDropdown:false,
            selectSubBranchActiveDropdown:false,
            selectSubSubBranchActiveDropdown:false,
            defaultId:'',
            charecterErrorMessageForMessage:'',
            usedSelfie:0,
            countValue : false,
            checkPollAnswer2Data:{},
            displayChat:true,
            botUserEmail : '',

            messageInterval:0,
            messageCount:0,
            lastMessageData:{},
            //  audioPlaying:false
            
            };
            
          
          },
          beforeDestroy() {
            this.$root.$off('showPreviewPoll');
            this.$root.$off('mainBranchActiveFalse');
            this.$root.$off('hidePreviewPoll');
            this.$root.$off('activeChat-GroupFalse');
            this.$root.$off('searchByTypeValue');
            this.$root.$off('onupdateSearchs');
            this.$root.$off('branchOpen');
            this.$root.$off("openChat");
            this.$root.$off("activeGroup");
          },
          created(){
            var self = this;
            self.botUserEmail = Vue.prototype.$email;
            self.audioActiveId = '';
            self.searchErrorMessage = '';
            self.showLoader = true;
            self.showLoaders = true;
            getSocialPlanData(firebaseData.firebasecollection.USER,firebase.auth().currentUser.uid,res => {
              self.socialPlanData = res;
              if(res.messages_history == 'unlimited'){
                self.messageHistory = res.messages_history
              }else{
                self.messageHistory = Number(res.messages_history)
              }
              self.companyuserlimit = {
                max_file_size : res.max_file_size ,
                userUsageLimit : res.userUsageLimit,
                dataUsage:res.dataUsage,
                file_type_plan : res.file_type_plan,
                device_plan : res.device_plan
              }
            
              if(self.companyuserlimit.device_plan != undefined){
                if(self.companyuserlimit.device_plan.includes('all') || self.companyuserlimit.device_plan.includes('desktop')){
                  self.displayChat = true;
                }else{
                  self.displayChat = false;
                }
              }
              else{
                self.displayChat = false;
              }
              self.$root.$emit('hideAreaOfSidebar',self.displayChat);
            })
            self.$root.$on('showPreviewPoll',(question,type,images,imagetexts)=>{
              self.showPoll=true
              self.showPollData={
                question:question,
                type:type,
                images:images,
                imagetexts:imagetexts
              }
            })
            self.$root.$on('mainBranchActiveFalse',(val) => {
              self.mainBranchActive = false
              self.getChatArray('default',val)
            })
            self.$root.$on('hidePreviewPoll',()=>{
              self.showPoll=false
            })
            var datesplit = self.DateOFToday.split('-')
            self.splitDateObject.year = datesplit[0]
            self.splitDateObject.month = datesplit[1]
            self.splitDateObject.date = datesplit[2]
            self.selectedActiveMenu='activeList'
            setInterval(()=>{
              var dt = new Date()
              self.todayTime.hrs= dt.getHours()<10?'0'+dt.getHours():dt.getHours()
              self.todayTime.min= dt.getMinutes()<10?'0'+dt.getMinutes():dt.getMinutes()
            },1000)
            self.mainBranchActive=false,
            self.mainBranchActiveIndex=0
            self.$root.$on('activeChat-GroupFalse',()=>{
              self.activeChat=false
              self.activeGroup=false
              self.chatObjectId=''
              self.sendreceArray=[];
              self.sendreceArrayBKP=[];
              self.searchErrorMessage = '';
            })
            self.chatObjectId=""
            self.init();
            self.$root.$on('searchByTypeValue',(chatkeyword,searchByType)=>{
              self.searchType = self.searchbaroption
              self.searchkeyword = chatkeyword
              self.searchErrorMessage = '';
              self.searchedChat = self.sendreceArrayBKP
              self.sendreceArray = []
              self.searchedChat.forEach(data=>{
                if(searchByType=='Photos' && chatkeyword == '')
                {
                  if(data.type.toLowerCase() == 'image')
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType=='Videos' && chatkeyword == '')
                {
                  if(data.type.toLowerCase()=='video')
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType=='Links' && chatkeyword == '')
                {
                  if(data.type.toLowerCase()=='link')
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType=='Documents' && chatkeyword == '')
                {
                  if(data.type.toLowerCase()=='pdf' || data.type.toLowerCase()=='word' || data.type.toLowerCase()=='excel' || data.type.toLowerCase()=='ods'|| data.type.toLowerCase()=='odt' || data.type.toLowerCase()=='ppt' || data.type.toLowerCase()=='plain' || data.type.toLowerCase()=='html' || data.type.toLowerCase()=='xd')
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType=='Photos' && chatkeyword != '')
                {
                  if(data.type.toLowerCase()=='image' && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                  {
                    self.sendreceArray.push(data) 
                  }
                }
                else if(searchByType=='Documents' && chatkeyword != '')
                {
                  if(data.type.toLowerCase()=='pdf' || data.type.toLowerCase()=='word' || data.type.toLowerCase()=='excel' || data.type.toLowerCase()=='ods'|| data.type.toLowerCase()=='odt' || data.type.toLowerCase()=='ppt' || data.type.toLowerCase()=='plain' || data.type.toLowerCase()=='html' || data.type.toLowerCase()=='xd')
                  {
                    if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()) || data.name.toLowerCase().includes(chatkeyword.toLowerCase()))
                      {
                        self.sendreceArray.push(data)
                      }
                  }
                }
                else if(searchByType=='Videos' && chatkeyword != '')
                {
                  if(data.type.toLowerCase()=='video' && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                  {
                    self.sendreceArray.push(data)
                  }           
                }
                else if(searchByType=='Links' && chatkeyword != '')
                {
                  if(data.type.toLowerCase()=='link' && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType == '' && chatkeyword != ''){
                  if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType == 'All' && chatkeyword != ''){
                  if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                    {
                      self.sendreceArray.push(data)
                    }
                }
                else if(searchByType == 'All' && chatkeyword == ''){
                  self.sendreceArray = self.sendreceArrayBKP
                }
                else if(searchByType == '' && chatkeyword == ''){
                  if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                  {
                    self.sendreceArray = self.sendreceArrayBKP
                  }
                }
              })
              self.showLoader = false
              if(self.sendreceArray.length == 0){
                self.searchErrorMessage = "No Result found"
              }else{
                self.searchErrorMessage = '';
              }
            })
            self.$root.$on('onupdateSearchs',(chatkeyword,searchByType)=>{
              self.searchType = self.searchbaroption
              self.searchkeyword = chatkeyword
              self.searchErrorMessage = '';
            //   self.sendreceArrayBKP = [...self.sendreceArray];
              self.searchedChat = self.sendreceArrayBKP || [];
              self.sendreceArray = [];

              self.searchedChat.forEach(data=>{
                if(searchByType=='Photos' && chatkeyword == '')
                {
                  if(data.type.toLowerCase()=='image')
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType=='Videos' && chatkeyword == '')
                {
                  if(data.type.toLowerCase()=='video')
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType=='Links' && chatkeyword == '')
                {
                  if(data.type.toLowerCase()=='link')
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType=='Documents' && chatkeyword == '')
                {
                  if(data.type.toLowerCase()=='pdf' || data.type.toLowerCase()=='word' || data.type.toLowerCase()=='excel' || data.type.toLowerCase()=='ods'|| data.type.toLowerCase()=='odt' || data.type.toLowerCase()=='ppt' || data.type.toLowerCase()=='plain' || data.type.toLowerCase()=='html' || data.type.toLowerCase()=='xd')
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType=='Photos' && chatkeyword != '')
                  {
                  if(data.type.toLowerCase()=='image' && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                  {
                    self.sendreceArray.push(data) 
                  }
                }
                else if(searchByType=='Documents' && chatkeyword != '')
                {
                  if(data.type.toLowerCase()=='pdf' || data.type.toLowerCase()=='word' || data.type.toLowerCase()=='excel' || data.type.toLowerCase()=='ods'|| data.type.toLowerCase()=='odt' || data.type.toLowerCase()=='ppt' || data.type.toLowerCase()=='plain' || data.type.toLowerCase()=='html' || data.type.toLowerCase()=='xd')
                  {
                    if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()) ||  data.name.toLowerCase().includes(chatkeyword.toLowerCase()))
                    {
                      self.sendreceArray.push(data)
                    }
                  }
                }
                else if(searchByType=='Videos' && chatkeyword != '')
                {
                  if(data.type.toLowerCase()=='video' && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                    {
                      self.sendreceArray.push(data)
                    }           
                }
                else if(searchByType=='Links' && chatkeyword != '')
                {
                  if(data.type.toLowerCase()=='link' && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType == 'All' && chatkeyword != ''){
                  if(data.msg !== undefined && data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType == '' && chatkeyword != ''){
                  if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                  {
                    self.sendreceArray.push(data)
                  }
                }
                else if(searchByType == 'All' && chatkeyword == ''){
                  self.sendreceArray = self.sendreceArrayBKP
                }
                else if(searchByType == '' && chatkeyword == ''){
                  if(data.msg.toLowerCase().includes(chatkeyword.toLowerCase()))
                  {
                    self.sendreceArray = self.sendreceArrayBKP
                  }
                }
              })
              // console.log('self.sendreceArray2',self.sendreceArray);
              self.showLoader = false
              if(self.sendreceArray.length == 0){
                self.searchErrorMessage = "No Result found."
              }
              else{
                self.searchErrorMessage = '';
              }
            })
            self.$root.$on('branchOpen',(value) => {  
                self.openBranchFromMessage(value);
            })
          },
          methods: {
            checkBranchExists(branchName) {
              var self = this;

              let found = false;

              if(!self.storeChatData.mainBranches.length) {
                  return false;
              }

              self.storeChatData.mainBranches.forEach(mb=>{
                  if(mb.name === branchName) {
                      found = true;
                  } else {
                      mb.subBranches.forEach(sb =>{
                          if(sb.name === branchName) {
                              found = true;
                          } else {
                              sb.subSubBranches.forEach(ssb=>{
                                  if(ssb.name === branchName) {
                                      found = true;
                                  }
                              })
                          }
                      })
                  }
              })

              return found;
            },
            ...mapActions({setCurrentCollection:'setCurrentCollection'}),
            ...mapActions({setStoreChatId:'setStoreChatId'}),
            ...mapActions({getSetChatData:"getSetSocialChatData"}),
            checkTypeAndBranch(type,branch){
                if(type == 'Text'){
                  return true;
                }else if(type == 'link'){
                  return true;
                }
                else if(branch == '' && type.toLowerCase() != 'selfiedeclientext'){
                  return true;
                }else{
                  return false;
                }
            },
          // CHECK CHARECTER LIMIT IN MESSAGE (Shraddha)
            checkChrecterLengthMessage(groupName){
              var self = this;
              if(groupName.length == 5000){
                self.charecterErrorMessageForMessage = 'The message may not be greater than 5000 characters';
              }else{
                self.charecterErrorMessageForMessage = '';
              }
            },
            insideDivClick(data,index){
            //  var self = this;
              window.addEventListener('click', function(e){   
               if(document.getElementById('clickbox'+index) != undefined){
                if (document.getElementById('clickbox'+index).contains(e.target)){
                  // data.flag = true;
                } else{
                  data.flag = false
                }
               }
              });
            },
            insideDivClickActiveBranch(index){
              var self = this
              window.addEventListener('click', function(e){ 
                // console.log(e.target)
                if(document.getElementById("mainBranchDropdown") != undefined){  
                  if (document.getElementById("mainBranchDropdown").contains(e.target)){
                  } else{
                      self.selectDropdown  = false 
                  }
                }
              });
            },
            insideDivClickActiveBranch1(index){
              var self = this
              window.addEventListener('click', function(e){
                if(document.getElementById("subBranchDropdown") != undefined){
                  if (document.getElementById("subBranchDropdown").contains(e.target)){
                  }else{
                    self.selectSubDropdown = false
                  }
                }
              });
            },
            insideDivClickActiveBranch2(index){
              var self = this
              window.addEventListener('click', function(e){   
                if(document.getElementById("subSubBranchDropdown") != undefined){
                  if (document.getElementById("subSubBranchDropdown").contains(e.target)){
                  }else{
                    self.selectSubSubDropdown = false
                  }
                }
              });
            },
            //OPEN DROP DOWN IN BRANCH (Shraddha)
            selectedMainBranchDropdown(data){
              // debugger // eslint-disable-line
              var self = this;
              if(data == 'Delete'){
                // self.MainbranchDelete(self.mainBranches,self.mainBranches[self.mainBranchActiveIndex].name,self.mainBranches[self.mainBranchActiveIndex].msgid || '');
                self.handleDeleteBranch(self.mainBranches, self.mainBranches[self.mainBranchActiveIndex].name, 'main');
              }
              else if(data == 'Mute'){
                self.muteUnmuteChat('mainBranch',self.mainBranchActiveIndex,'mute');
              }
              else{
                self.muteUnmuteChat('mainBranch',self.mainBranchActiveIndex,'unmute');
              }
            },
            selectedSubBranchDropdown(data){
              // debugger // eslint-disable-line
              var self = this;
              if(data == 'Delete'){
                // self.SubbranchDelete(self.mainBranches,self.mainBranchActiveIndex,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].msgid || '');
                self.handleDeleteBranch(self.mainBranches, self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name, 'sub');
              }
              else if(data == 'Mute'){
                self.muteUnmuteChat('subBranch',self.subBranchActiveIndex,'mute');
              }
              else{
                self.muteUnmuteChat('subBranch',self.subBranchActiveIndex,'unmute');
              }
            },
            selectedSubSubBranchDropdown(data){
              // debugger // eslint-disable-line
              var self = this;
              if(data == 'Delete'){
                // self.SubSubbranchDelete(self.mainBranches,self.mainBranchActiveIndex,self.subBranchActiveIndex,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].msgid || '');
                self.handleDeleteBranch(self.mainBranches, self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name, 'innerSub');
              }
              else if(data == 'Mute'){
                self.muteUnmuteChat('subSubBranch',self.subSubBranchActiveIndex,'mute');
              }
              else{
                self.muteUnmuteChat('subSubBranch',self.subSubBranchActiveIndex,'unmute');
              }
            },
            selectedMainBranchActiveDropdown(mainBranches,name,msgid,index,data){
              //  debugger // eslint-disable-line
                var self = this;
                if(data == 'Delete'){
                //   self.MainbranchDelete(mainBranches,name,msgid || '');
                  self.handleDeleteBranch(mainBranches, name, 'main');
                }
                else if(data == 'Mute'){
                  self.muteUnmuteChat('mainBranch',index,'mute');
                }
                else{
                  self.muteUnmuteChat('mainBranch',index,'unmute');
                }
            },
            selectedSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,name,msgid,index,data){
              var self = this;
              if(data == 'Delete'){
                // self.SubbranchDelete(mainBranches,mainBranchActiveIndex,name,msgid || '');
                self.handleDeleteBranch(mainBranches, name, 'sub');
              }
              else if(data == 'Mute'){
                self.muteUnmuteChat('subBranch',index,'mute');
              }
              else{
                self.muteUnmuteChat('subBranch',index,'unmute');
              }
            },
            selectedSubSubBranchActiveDropdown(mainBranches,mainBranchActiveIndex,subBranchActiveIndex,name,msgid,index,data){
              var self = this;
              if(data == 'Delete'){
                self.handleDeleteBranch(mainBranches, name, 'innerSub');
              }
              else if(data == 'Mute'){
                self.muteUnmuteChat('subSubBranch',index,'mute');
              }
              else{
                self.muteUnmuteChat('subSubBranch',index,'unmute');
              }
            },
            openDropdownBranch(){
              var self = this;
              if(self.selectDropdown == false){
                self.selectDropdown = true
              }else{
                self.selectDropdown = false
              }
            },
            openSubDropdownBranch(){
              var self = this;
              if(self.selectSubDropdown == false){
                self.selectSubDropdown = true
              }else{
                self.selectSubDropdown = false
              }
            },
            openSubSubDropdownBranch(){
              var self = this;
              if(self.selectSubSubDropdown == false){
                self.selectSubSubDropdown = true
              }else{
                self.selectSubSubDropdown = false
              }
            },
            openActiveDropdownBranch(data){
              if(data.flag == false){
                data.flag = true
              }else{
                data.flag = false
              }
            },
            openActiveSubDropdownBranch(data){
              if(data.flag == false){
                data.flag = true
              }else{
                data.flag = false
              }
            },
            openActiveSubSubDropdownBranch(data){
              if(data.flag == false){
                data.flag = true
              }else{
                data.flag = false
              }
            },
            // CHECK IF SURPRISE SELFIE SEND OR NOT (Shraddha)
            surpriseSelfieSend(){
              // debugger // eslint-disable-line
              var self = this;
              var txt = "";
              var splitName = '';
              self.socialPlanData.selfieUsers.forEach(data => {
                for (let x in data) {
                  if(x == self.chatObjectId){
                    txt = x;
                    splitName = data[x]
                  }
                }
              })
            if(txt == ''){
              var databaseTime = new Date(self.socialPlanData.selfieTime.seconds * 1000)
              var tsYesterday =  new Date(databaseTime.setHours(databaseTime.getHours() + 6));
              // console.log("self.socialPlanData",tsYesterday,tsYesterday <= new Date())
              if(tsYesterday <= new Date()){
                self.surpriseSelfie = true
              }else{
                if(self.socialPlanData.usedSelfie < self.socialPlanData.noOfSelfie){
                  self.surpriseSelfie = true
                }else{
                  self.surpriseSelfie = false
                  self.$root.$emit("audioplay");
                  self.$notify({
                    title:"Error",
                    text:'Selfie limit has been over.',
                    type:'error'
                  })      
                }
              }
            }else{
              self.surpriseSelfie = false
              self.$root.$emit("audioplay");
              // var splitName = isExistSelfieUser[0].split(':')
                self.$notify({
                  title:"Error",
                  text:`Request already sent to ${splitName} `,
                  type:'error'
                })   
            }
            },
            // EVENT FIRE WHEN AUDIO IS PLAY(Shraddha)
            playAudio(msgid){
              var self = this;
              if(self.audioActiveId == ''){
                self.audioActiveId = msgid;
              }else{
                var activeId = document.getElementById(self.audioActiveId);
                activeId.pause();
                self.audioActiveId = msgid
              }
            },
            // CLOSE DROP DOWN(Shraddha)
            closeDropDown(){
              var self = this;
              self.$root.$emit("closeSidebar");
            },
            renderFiles(e){
              var self = this
              var clipboardItems = e.clipboardData.items;
              var files = []
              clipboardItems.forEach((data,index)=>{
                files.push(data.getAsFile())
                if(files[index]==null)
                {
                  files.splice(files[index],1)
                }
              })
              if(files.length!=0)
              {
                self.uploadFiles(files,'e')
              }
            },
            assignNewDocId(id)
            {
              this.chatObjectId = id
              this.getChatArray('default',id);
              this.getSetChatData({collection:'socialMessages',chatId:id});
            },
            showStartDots(){
              var self = this
              var show = false
              if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
              {
                show = 6<=self.startIndex
              }
              if(self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
              {
                show = 6<=self.startIndex
              }
              if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
              {
                show = 6<=self.startIndex
              }
              return show
            },
            showEndDots(){
              var self = this
              var show = false
              if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
              {
                show = self.mainBranches.length>self.endIndex
              }
              if(self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
              {
                show = self.mainBranches[self.mainBranchActiveIndex].subBranches.length>self.endIndex
              }
              if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
              {
                show = self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches.length>self.endIndex
              }
              return show
            },
            pagination(branches){
              var self = this
              var index = 1
              var dataArray=[]
              var paginatedData=[]
              if(branches.length<6)
              {
                self.totalPages=0
              }
              if(branches.length!=0)
              {
                branches.forEach(data=>{
                  dataArray.push(data)
                  if(index % 6 == 0)
                  {
                    paginatedData.push(dataArray)
                    dataArray=[];
                  }
                  else if(index == branches.length)
                  {
                    paginatedData.push(dataArray)
                    dataArray=[];
                    self.totalPages=paginatedData.length
                  }
                  index++;
                })
              }
              return paginatedData
            },
            changePage(direction){
              var self = this
              if(direction =='prev')
              {
                if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                {
                  if(0<self.startIndex+6)
                  {
                    self.startIndex-=6
                    self.endIndex-=6
                  }
                }
                if(self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                {
                  if(0<self.startIndex+6)
                  {
                    self.startIndex-=6
                    self.endIndex-=6
                  }
                }
                if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
                {
                  if(0<self.startIndex+6)
                  {
                    self.startIndex-=6
                    self.endIndex-=6
                  }
                }
              }
              if(direction =='next')
              {
                if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                {
                  if(self.mainBranches.length>self.endIndex)
                  {
                    self.startIndex+=6
                    self.endIndex+=6
                  }
                }
                if(self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                {
                  if(self.mainBranches[self.mainBranchActiveIndex].subBranches.length>self.endIndex)
                  {
                    self.startIndex+=6
                    self.endIndex+=6
                  }
                }
                if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
                {
                  if(self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches.length>self.endIndex)
                  {
                    self.startIndex+=6
                    self.endIndex+=6
                  }
                }
              }
            },
            hideUnreadMessages()
            {
              this.unreadMessages=0
            },
            checkActiveTab(){
              // if(Object.keys(this.checkPollAnswer2Data).length > 0){
              //   this.checkPollAnswer2(this.checkPollAnswer2Data);
              // }
            },
            surprizeSelfieTextCheck(chatmessage)
            {
              var className = ''
              if(this.searchkeyword!='' && chatmessage.receivermsg.toLowerCase().includes(this.searchkeyword.toLowerCase()))
              {
                className += 'highlight-msg'
              }

              if(chatmessage.type.toLowerCase() == 'surpriseselfietext')
              {
                className += ' surpriseTextImage'
              }
              return className
            },
            checkEmpty()
            {
              if(this.sendingMessage=='' && this.msgTextTmp=='')
              {
                return false
              }else{
                return true
              }
            },
            record(){
              this.getDetectRTCData().then((res) => {
              if(res == true){
                this.starttime = new Date();
                recorder.start().then((val) => {
                  this.startRecording=true
                  this.handleSuccess(val)
                }).catch((e) => {
                  console.error(e);
                });
              }
              else{
                this.$root.$emit("audioplay");
                this.$notify({
                  title:"Error",
                  text:'Microphone is not connected.',
                  type:'error'
                })
              }
            });
            },
            getDetectRTCData(){
              // debugger // eslint-disable-line
              var DetectRTC = require('detectrtc')
              return new Promise((resolve,reject) => {
                DetectRTC.load(function() {
                  resolve(DetectRTC.hasMicrophone)
                })
              })
            },
            stopRecord(){
              var self = this
              const playerAudio = document.getElementById('adioPlay');
              recorder
                .stop()
                .getMp3().then(([buffer, blob]) => {
                  self.startRecording=false
                  self.stoptime = new Date()
                  const file = new File(buffer, 'me-at-thevoice.mp3', {
                    type: blob.type,
                    lastModified: Date.now()
                  });
                  playerAudio.src = URL.createObjectURL(file)
                  self.sendAudio2(URL.createObjectURL(file),file)
                }).catch((e) => {
                  alert('We could not retrieve your message');
                  console.log("Error",e)
                });
            },
            cancelRecord()
            {
              recorder.stop().getMp3()
              .then(()=>{
                this.startRecording=false
              })
              .catch(error=>{
              })
            },
            handleSuccess(stream){
              const player = document.getElementById('player');
              if ("srcObject" in player) {
                player.srcObject = stream;
              }
              else {
                player.src = window.URL
                  .createObjectURL(stream);
              }
              player.onloadedmetadata = function (ev) {
              player.play();
              };
            },
            timeDifference(date1,date2) {
              const today = date1
              const endDate = date2;
              const days = parseInt((endDate - today) / (1000 * 60 * 60 * 24));
              const hours = parseInt(Math.abs(endDate - today) / (1000 * 60 * 60) % 24);
              const minutes = parseInt(Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60) % 60);
              const seconds = parseInt(Math.abs(endDate.getTime() - today.getTime()) / (1000) % 60); 
              this.audiotime = this.addZero(minutes)+':'+this.addZero(seconds)+':00';
            },

          sendAudio2(audLink,recChunks){
            var self = this;
            self.timeDifference(self.starttime,self.stoptime)
            self.image_list = []
            let docType = ''
            let input = event.target;
            let found = false
            let count = recChunks.length;
            let index = 0;

            self.companyuserlimit.file_type_plan.forEach(data => {
              if(data == 'sound' || data == 'all'){
                found = true
                self.image_list.push(recChunks);
                self.showRecordedMessage=true
              }
            })

            if(found) {
              let from = {
                avtar:self.userData.profileUrl,
                id:self.userData.id,
                name:self.userData.firstName,
                path: self.currCollection!=="default" ? self.currCollection : "",
              };
              let fromArray = [];
              let datausage = 0;
              docType="Audio";
              const imgsLength = self.image_list.length;

              if(self.sendreceArray.length!=0)
              {
                let container = document.getElementById('scrollParent')
                container.scrollTop = container.scrollHeight;
              }
              uploadImageFile(firebaseData.firebasecollection.PROFILEIMAGES,'recordedMessage.mp3',self.image_list[0].size,self.image_list[0],docType,'',res=>{
                self.image.push(res);
                const imagesize = res.imageSize;
                db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialPlan').get().then(docss => {
                  docss.forEach(docs => {
                    // console.log('docsdata',docs.data())
                    datausage = (Number(docs.data().dataUsage) + Number(imagesize))
                    db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialPlan').doc(firebase.auth().currentUser.uid).update({
                        dataUsage :  datausage
                    })
                  })
                })

                if(self.image.length == imgsLength) {
                  let count = 0;
                  const getrequestDataFunction = (row) => {
                    if(count >=  self.image.length){
                      self.showRecordedMessage=false;
                      self.isInArray = '';
                      self.image_list=[]
                      self.image = []
                      self.preview_list=[]
                      return;
                    }
                    console.log("Data",count);
                    const key = row.imageUrl;
                    const imageName = row.imageName;
                    const docType = row.docType;

                    if(self.userData.SocialreadReceipts || self.activeGroup)
                    {
                      fromArray.push(from);
                    }

                    if(self.chatObjectId != undefined && self.chatObjectId != '') {
                      self.addMessageAndCallAPI({
                        createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        from: from,
                        type: docType,
                        name: imageName,
                        msg: key,
                        messageSeen: fromArray,
                        audioTime: self.audiotime,
                      },self.chatObjectId,self.currCollection);
                      count += 1;
                      getrequestDataFunction(self.image[count]);
                    } else {
                      self.currCollection = "default";
                      self.connectedUserArray.push(self.userid)
                      self.connectedUserArray.push(self.chatObject.id)
                      let messageSeenIds=[]
                      messageSeenIds.push({id:firebase.auth().currentUser.uid,count:0})
                      messageSeenIds.push({id:self.chatObject.id,count:0})

                      db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).add({
                        connectedUser : self.connectedUserArray,
                        id:'',
                        isGroup : self.activeGroup,
                        lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        title:'',
                        updateChat:self.updateChat,
                        msgFromBranch:false,
                        muteArray:[],
                        mainBranches:[],
                        updateBranchData:{},
                        SurpriseSelfie : {},
                        ToggleSelfie : [],
                        messageSeenIds:messageSeenIds,
                        blockBy : self.isInArray || ''
                      }).then((doc) => {
                        self.getSetChatData({collection:'socialMessages', chatId:doc.id});
                        self.getChatArray(self.currCollection, doc.id);
                        self.chatObjectId = doc.id
                        db.collection(firebaseData.firebasecollection.USER).doc(self.userid).collection('socialContact').doc(self.chatObject.id).update({
                          chatId:doc.id
                        })
                        db.collection(firebaseData.firebasecollection.USER).doc(self.chatObject.id).collection('socialContact').doc(self.userid).update({
                          chatId:doc.id
                        })
                        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(doc.id).update({
                          id : doc.id
                        })

                        self.addMessageAndCallAPI({
                          createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                          updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                          from: from,
                          type: docType,
                          name: imageName,
                          msg: key,
                          messageSeen: fromArray,
                          audioTime: self.audiotime,
                        },doc.id,self.currCollection);
                        count += 1;
                        getrequestDataFunction(self.image[count]);
                      })
                      .catch(error=>{
                        console.error(error);
                      })
                    }
                  }
                  getrequestDataFunction(self.image[count]);
                }
              });
            } else {
              this.$root.$emit("audioplay");
              this.$notify({
                title:"Error",
                text:"Audio is not supported.",
                type: "error"
              });
              return false;
            }
          },

          openBranchFromMessage(branchName)
          {
            var self = this
            for (let i = 0; i < self.mainBranches.length; i++) {
              if(self.mainBranches[i].name==branchName)
              {
                self.mainBranchActive=true
                self.mainBranchActiveIndex=i

                self.subBranchActive=false
                self.subBranchActiveIndex=0
                self.subSubBranchActive=false
                self.subSubBranchActiveIndex=0
              }
              else
              {
                if(self.mainBranches[i].subBranches){
                  for (let j = 0; j < self.mainBranches[i].subBranches.length; j++) {
                    if(self.mainBranches[i].subBranches[j].name==branchName)
                    {
                      self.subBranchActive=true
                      self.subBranchActiveIndex=j
                    }
                    else
                    {
                      if(self.mainBranches[i].subBranches[j].subSubBranches){
                        for (let k = 0; k < self.mainBranches[i].subBranches[j].subSubBranches.length; k++) {
                          if(self.mainBranches[i].subBranches[j].subSubBranches[k].name==branchName)
                          {
                            self.subSubBranchActive=true
                            self.subSubBranchActiveIndex=k
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            self.getChatArray(branchName,self.chatObjectId)
          },
          getMessageCount(messageSeenIdsArray)
          {
            // debugger // eslint-disable-line

            var uid = firebase.auth().currentUser.uid
            var count = 0
            messageSeenIdsArray.forEach(data=>{
              if(data.id==uid)
              {
                count = data.count
                
              }
            })

            
            return count
            
          },
          listenForEnter(e)
          {
            var self = this;
            // console.log(e)
            if(!e.shiftKey && e.key=='Enter')
            {
              if(!self.sendingMessageFlag && self.msgTextTmp!='')
              {
                self.sendingMessageFlag=true
                self.sendMessage()
              }
              if(self.sendingMessage=='' && self.msgTextTmp=='')
              {
                self.checkEmpty()
              }
              e.preventDefault()
            }
          },
          checkMuted(userIdArray)
          {
            var muted = false
            userIdArray.forEach(data=>{
              if(data==firebase.auth().currentUser.uid)
              {
                muted = true
              }
            })

            //console.log('muted:',muted)

            if(muted)
            {
              return true
            }
            else
            {
              return false
            }
          },
          checkBranchMuted(path,mainBranches)
          {
            var self = this
            var muted = false
            var splitPath = path
            var mainBranchesData = mainBranches
            
            if(splitPath.length==2)
            {
              mainBranchesData.forEach(data=>{
                if(data.name == splitPath)
                {
                  //console.log('Here',splitPath)
                  muted = self.checkMuted(data.muteArray)
                  // return muted
                }
                else
                {
                  data.subBranches.forEach(data2=>{
                    if(data2.name == splitPath)
                    {
                      muted = self.checkMuted(data2.muteArray)
                      // return muted
                    }
                    else
                    {
                      data2.subSubBranches.forEach(data3=>{
                        if(data3.name == splitPath)
                        {
                          muted = self.checkMuted(data3.muteArray)
                          // return muted
                        }
                      })
                    }
                  })
                }
              })
            }
            //console.log('GHIJKLM',muted)
            return muted
          },
          // CHECK EXPIRY TIME OBJECT FOR POLL (Shraddha)
          checkDate(expireyTimeObjects)
          {
            var self = this

            if(expireyTimeObjects.date == self.splitDateObject.date && expireyTimeObjects.month == self.splitDateObject.month &&expireyTimeObjects.year == self.splitDateObject.year)
            {
              return 'Today'
            }
            else
            {
              return `${expireyTimeObjects.date}-${expireyTimeObjects.month}-${expireyTimeObjects.year}`
            }
          },
          //CHECK POLL EXPIRY DATE(Shraddha)
          checkExpirey(expireyTimeObjects)
          {
            var self = this
            var result = false
            // //console.log('Condition1',expireyTimeObjects.date == self.splitDateObject.date ,expireyTimeObjects.month == self.splitDateObject.month ,expireyTimeObjects.year == self.splitDateObject.year ,expireyTimeObjects.hrs == self.todayTime.hrs , expireyTimeObjects.min <= self.todayTime.min)
            // //console.log('Condition2',expireyTimeObjects.date < self.splitDateObject.date , expireyTimeObjects.month <= self.splitDateObject.month , expireyTimeObjects.year <= self.splitDateObject.year)
            if(expireyTimeObjects.date < self.splitDateObject.date && expireyTimeObjects.month <= self.splitDateObject.month && expireyTimeObjects.year <= self.splitDateObject.year)
            {
              // //console.log('else if')
              result = true
            }
            else if(expireyTimeObjects.date <= self.splitDateObject.date && expireyTimeObjects.month == self.splitDateObject.month && expireyTimeObjects.year == self.splitDateObject.year)
            {
              // //console.log('if')
              if(expireyTimeObjects.hrs < self.todayTime.hrs)
              {
                result = true
              }
              else if(expireyTimeObjects.hrs == self.todayTime.hrs && expireyTimeObjects.min <= self.todayTime.min)
              {
                result=true
              }
            }
            else
            {
              // //console.log('else')
              result = false
            }
            // //console.log('=========Expired:',result)
            return result
          },
          muteUnmuteChat(branchName,index,operation)
          {
            // debugger // eslint-disable-line
            var self = this
            self.selectDropdown=false;
              self.selectSubDropdown=false;
              self.selectSubSubDropdown=false;
              self.selectMainBranchActiveDropdown=false;
              self.selectSubBranchActiveDropdown=false;
              self.selectSubSubBranchActiveDropdown=false;
            
            // console.log('====',operation)
            if(operation=='mute')
            {
              // push user id
              //console.log(operation)

              db
                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                .doc(self.chatObjectId)
                .get().then((doc)=>{
                  var mainBranchData = []

                  mainBranchData = self.sortArray(doc.data().mainBranches)

                  var muted = false

                  if(branchName=='mainBranch')
                  {
                    mainBranchData[index].muteArray.forEach(data=>{
                      if(data==self.userid)
                      {
                        muted = true
                      }
                    })
                  }
                  else if(branchName=='subBranch')
                  {
                    mainBranchData[self.mainBranchActiveIndex].subBranches[index].muteArray.forEach(data=>{
                      if(data==self.userid)
                      {
                        muted = true
                      }
                    })
                  }
                  else if(branchName=='subSubBranch')
                  {
                    mainBranchData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[index].muteArray.forEach(data=>{
                      if(data==self.userid)
                      {
                        muted = true
                      }
                    })
                  }


                  if(!muted)
                  {
                    if(branchName=='mainBranch')
                    {
                      mainBranchData[index].muteArray.push(self.userid)
                      mainBranchData[index].subBranches.forEach(data=>{
                        var subMuted = false
                        data.muteArray.forEach(ids=>{
                          if(ids == self.userid)
                          {
                            subMuted = true
                          }
                        })
                        if(!subMuted)
                        {
                          data.muteArray.push(self.userid)
                          data.subSubBranches.forEach(data2=>{
                            var subSubMuted = false
                            data2.muteArray.forEach(ids=>{
                              if(ids == self.userid)
                              {
                                subSubMuted = true
                              }
                            })
                            if(!subSubMuted)
                            {
                              data2.muteArray.push(self.userid)
                            }
                          })
                        }
                      })
                    }
                    else if(branchName=='subBranch')
                    {
                      mainBranchData[self.mainBranchActiveIndex].subBranches[index].muteArray.push(self.userid)
                      mainBranchData[self.mainBranchActiveIndex].subBranches[index].subSubBranches.forEach(data=>{
                        var subSubMuted = false
                        data.muteArray.forEach(ids=>{
                          if(ids == self.userid)
                          {
                            subSubMuted = true
                          }
                        })
                        if(!subSubMuted)
                        {
                          data.muteArray.push(self.userid)
                        }
                      })
                    }
                    else if(branchName=='subSubBranch')
                    {
                      mainBranchData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[index].muteArray.push(self.userid)
                    }
                    db
                      .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                      .doc(doc.id)
                      .update({
                        mainBranches:mainBranchData
                      })
                      .then(()=>{
                        // console.log('abcd',branchName,mainBranchData,doc.id)
                        self.$root.$emit("audioplay");
                        self.$notify({
                          title:"Success",
                          text:'Muted successfully.',
                          type:'success'
                        })
                      })
                      .catch(error=>{
                        console.log(error)
                      })
                  }

                })
                .catch(error=>{
                  console.log(error)
                })
            }
            else if(operation=='unmute')
            {
              // pop user id
              //console.log(operation)
              db
                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                .doc(self.chatObjectId)
                .get().then((doc)=>{
                  var mainBranchData = []

                  mainBranchData = self.sortArray(doc.data().mainBranches)

                  var muted = false

                  if(branchName=='mainBranch')
                  {
                    mainBranchData[index].muteArray.forEach(data=>{
                      if(data==self.userid)
                      {
                        muted = true
                      }
                    })
                  }
                  else if(branchName=='subBranch')
                  {
                    mainBranchData[self.mainBranchActiveIndex].subBranches[index].muteArray.forEach(data=>{
                      if(data==self.userid)
                      {
                        muted = true
                      }
                    })
                  }
                  else if(branchName=='subSubBranch')
                  {
                    mainBranchData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[index].muteArray.forEach(data=>{
                      if(data==self.userid)
                      {
                        muted = true
                      }
                    })
                  }


                  if(muted)
                  {
                    if(branchName=='mainBranch')
                    {
                      for (let i = 0; i < mainBranchData[index].muteArray.length; i++) {
                        if(mainBranchData[index].muteArray[i]==self.userid)
                        {
                          mainBranchData[index].muteArray.splice(i,1)
                        }
                        for (let j = 0; j < mainBranchData[index].subBranches.length; j++) {
                          for (let k = 0; k < mainBranchData[index].subBranches[j].muteArray.length; k++) {
                            if(mainBranchData[index].subBranches[j].muteArray[k]==self.userid)
                            {
                              mainBranchData[index].subBranches[j].muteArray.splice(k,1)
                            }   
                          }
                          for (let l = 0; l < mainBranchData[index].subBranches[j].subSubBranches.length; l++) {
                            for (let m = 0; m < mainBranchData[index].subBranches[j].subSubBranches[l].muteArray.length; m++) {
                              if(mainBranchData[index].subBranches[j].subSubBranches[l].muteArray[m]==self.userid)
                              {
                                mainBranchData[index].subBranches[j].subSubBranches[l].muteArray.splice(m,1)
                              }   
                            }
                          }
                        }
                      }
                    }
                    else if(branchName=='subBranch')
                    {
                      for (let i = 0; i < mainBranchData[self.mainBranchActiveIndex].subBranches[index].muteArray.length; i++) {
                        if(mainBranchData[self.mainBranchActiveIndex].subBranches[index].muteArray[i]==self.userid)
                        {
                          mainBranchData[self.mainBranchActiveIndex].subBranches[index].muteArray.splice(i,1)
                        }
                        for (let l = 0; l < mainBranchData[self.mainBranchActiveIndex].subBranches[index].subSubBranches.length; l++) {
                          for (let m = 0; m < mainBranchData[self.mainBranchActiveIndex].subBranches[index].subSubBranches[l].muteArray.length; m++) {
                            if(mainBranchData[self.mainBranchActiveIndex].subBranches[index].subSubBranches[l].muteArray[m]==self.userid)
                            {
                              mainBranchData[self.mainBranchActiveIndex].subBranches[index].subSubBranches[l].muteArray.splice(m,1)
                            }   
                          }
                        }
                      }
                    }
                    else if(branchName=='subSubBranch')
                    {
                      for (let i = 0; i < mainBranchData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[index].muteArray.length; i++) {
                        if(mainBranchData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[index].muteArray[i]==self.userid)
                        {
                          mainBranchData[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[index].muteArray.splice(i,1)
                        }
                      }
                    }
                    db
                      .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                      .doc(doc.id)
                      .update({
                        mainBranches:mainBranchData
                      })
                      .then(()=>{
                        // console.log('abcd',branchName,mainBranchData,doc.id)
                        self.$root.$emit("audioplay");
                        self.$notify({
                          title:"Success",
                          text:'Unmuted successfully.',
                          type:'success'
                        })
                      })
                      .catch(error=>{
                        console.log(error)
                      })
                  }

                })
                .catch(error=>{
                  console.log(error)
                })
            }
          },
        // CHECK DATE IS PAST DATE OR NOT(Shraddha)
          beforeDateExpired(dateexpired){
            // //console.log(dateexpired)
              var todayDatesplit = new Date().getDate();
              var todayMonthsplit = new Date().getMonth();
              var todayYearsplit = new Date().getFullYear();
              // //console.log('mainmaianmaja',dateexpired.Datesplit,dateexpired.monthsplit,dateexpired.yearsplit)
              // //console.log('mainmsfsewerwerewrwerweaianmaja',todayDatesplit,todayMonthsplit,todayYearsplit)
              var result = false
            // //console.log('Condition1',expireyTimeObjects.date == self.splitDateObject.date ,expireyTimeObjects.month == self.splitDateObject.month ,expireyTimeObjects.year == self.splitDateObject.year ,expireyTimeObjects.hrs == self.todayTime.hrs , expireyTimeObjects.min <= self.todayTime.min)
            // //console.log('Condition2',expireyTimeObjects.date < self.splitDateObject.date , expireyTimeObjects.month <= self.splitDateObject.month , expireyTimeObjects.year <= self.splitDateObject.year)
            if(todayDatesplit > dateexpired.Datesplit && todayMonthsplit >= dateexpired.monthsplit && todayYearsplit >= dateexpired.yearsplit)
            {
              // //console.log('else if')
              result = true
            }
            
            else
            {
              // //console.log('else')
              result = false
            }
            // //console.log('=========Expired:',result)
            return result
          },
          previewImage: function (event) {
            var input = event.target;
            if (input.files) {
              var reader = new FileReader();
              reader.onload = (e) => {
                this.previewSenderImage = e.target.result;
                this.preview = e.target.result;
              };
              this.image = input.files[0];
              reader.readAsDataURL(input.files[0]);
            }
          },
          previewListAddImage(event){
            // debugger // eslint-disable-line
            var self=this
            var files = event.target.files
            self.uploadFiles(files,event)
          
          },          
          
          uploadFiles: function(f,event) {
          // debugger // eslint-disable-line
            var self = this;
            self.previewImageSize = 0;
            self.sendingMessage = ''
            self.msgTextTmp=''
            // self.dropFiles = true;
            var docType = ''
            // console.log('f',f,event)
            var from = {}
            var fromArray = []
            var count = f.length;
            var index = 0;
            var notSupported = []
            var found = false
            var error = false
            // this.preview_list = []
            var updateBranchData={}
            if (f) {
              f.forEach(imgs=>{
              
                var reader = new FileReader();
                reader.onload = (e) => {

                self.previewImageSize = self.previewImageSize + imgs.size;

                if(self.companyuserlimit.dataUsage < self.companyuserlimit.userUsageLimit){
                  if(imgs.size<self.companyuserlimit.max_file_size && (self.companyuserlimit.dataUsage + imgs.size) < self.companyuserlimit.userUsageLimit && (self.companyuserlimit.dataUsage +  self.previewImageSize) < self.companyuserlimit.userUsageLimit)
                    {
                      self.companyuserlimit.file_type_plan.forEach(data => {
                        var docExtentions = imgs.type.split('/');
                        if(data == 'picture'){
                          
                          var docExtention = imgs.type.split('/');
                          if(docExtention[0] == 'image'){
                            docType = 'Image'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true
                          }
                          else {
                            self.$notify({
                              title:"Error",
                              text: imgs.name+' '+ "not supported.",
                              type: "error"
                            })
                          }
                        }else if (data == 'document'){
                          
                          var imageExtention = imgs.name.split('.');
                          if(imgs.type == 'application/pdf'){
                            docType = 'pdf'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true
                            
                          }
                          else if(imageExtention[1] == 'csv' || imageExtention[1] == "xlsx" || imageExtention[1] == "xls" ){
                            docType = 'excel'
                            self.preview_list.push({file:e.target.result,docType : docType});
                              self.image_list.push({file:imgs,docType : docType,text:''});
                              found=true
                            
                          }
                          else if(imageExtention[1] == 'ods'){
                            docType = 'ods'
                            self.preview_list.push({file:e.target.result,docType : docType});
                              self.image_list.push({file:imgs,docType : docType,text:''});
                              found=true
                            
                          }
                          else if(imageExtention[1] == 'docx' || imageExtention[1] == 'doc' ){
                            docType = 'word'
                            self.preview_list.push({file:e.target.result,docType : docType});
                              self.image_list.push({file:imgs,docType : docType,text:''});
                              found=true
                            
                          }
                          else if(imageExtention[1] == 'odt'){
                            docType = 'odt'
                            self.preview_list.push({file:e.target.result,docType : docType});
                              self.image_list.push({file:imgs,docType : docType,text:''});
                              found=true
                            
                          }
                          else if(imageExtention[1] == 'ppt' || imageExtention[1] == 'pptx'){
                            docType = 'ppt'
                            self.preview_list.push({file:e.target.result,docType : docType});
                              self.image_list.push({file:imgs,docType : docType,text:''});
                              found=true
                            
                          }
                          else if(imageExtention[1] == 'html' || imageExtention[1] == 'htm'){
                            docType = 'html'
                            self.preview_list.push({file:e.target.result,docType : docType});
                              self.image_list.push({file:imgs,docType : docType,text:''});
                              found=true
                            

                          }
                          else if(imageExtention[1] == 'txt'){
                            docType = 'plain'
                              self.preview_list.push({file:e.target.result,docType : docType});
                              self.image_list.push({file:imgs,docType : docType,text:''});
                              found=true
                            
                          }
                          else if(imageExtention[1] == 'xd'){
                                docType = 'xd'
                                self.preview_list.push({file:e.target.result,docType : docType});
                                self.image_list.push({file:imgs,docType : docType,text:''});
                                found=true
                          }
                          else {
                            self.$notify({
                              title:"Error",
                              text: imgs.name+' '+ "not supported.",
                              type: "error"
                            })
                          }
                          // else
                          // {
                          //   self.$notify({
                          //     title:"Error",
                          //     text:imgs.name+" not supported.",
                          //     type: "error"
                          //   })
                          // }
                        } else if (data == 'video'){
                          
                          var vedioextention = imgs.type.split('/');
                          if(vedioextention[0] == 'video'){
                            docType = 'Video'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true
                            
                          }
                          else {
                            self.$notify({
                              title:"Error",
                              text: imgs.name+' '+ "not supported.",
                              type: "error"
                            })
                          } 
                        } else if (data == 'all'){
                          
                          var imageExtention = imgs.name.split('.');
                          var vedioextention = imgs.type.split('/');
                          var docExtention = imgs.type.split('/');

                          if(docExtention[0] == 'image'){
                            docType = 'Image'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true
                          }
                          else if(imgs.type == 'application/pdf'){
                            docType = 'pdf'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true
                          }
                          else if(vedioextention[0] == 'video'){
                            docType = 'Video'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true
                          }
                          else if(imageExtention[1] == 'csv' || imageExtention[1] == "xlsx" || imageExtention[1] == "xls" ){
                            docType = 'excel'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true
                          }
                          else if(imageExtention[1] == 'ods'){
                            docType = 'ods'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true
                          }
                          else if(imageExtention[1] == 'docx' || imageExtention[1] == 'doc' ){
                            docType = 'word'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true
                          }
                          else if(imageExtention[1] == 'odt'){
                            docType = 'odt'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true
                          }
                          else if(imageExtention[1] == 'ppt' || imageExtention[1] == 'pptx'){
                            docType = 'ppt'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true
                          }
                          else if(imageExtention[1] == 'html' || imageExtention[1] == 'htm'){
                            docType = 'html'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true

                          }
                          else if(imageExtention[1] == 'txt'){
                            docType = 'plain'
                            self.preview_list.push({file:e.target.result,docType : docType});
                            self.image_list.push({file:imgs,docType : docType,text:''});
                            found=true
                          }
                          else {
                            self.$notify({
                              title:"Error",
                              text: imgs.name+' '+ "not supported.",
                              type: "error"
                            })
                          }
                          // else if(docExtention[0].toLowerCase() == 'audio')
                          // {
                          //   self.$root.$emit("audioplay");
                          //   self.$notify({
                          //     title:"Error",
                          //     text:imgs.name+" not supported.",
                          //     type: "error"
                          //   })
                          //   // return 0
                          // }
                        } else if (docExtentions[0].toLowerCase() == 'audio') {
                          if(f.length == index+1 && found) {
                            self.$root.$emit("audioplay");
                            self.$notify({
                              title:"Error",
                              text:"Audio not supported.",
                              type: "error"
                            })
                          }
                          // return 0
                        }
                        // console.log('self.preview_list',self.preview_list)
                      })
                      if(!found)
                      {
                        var imageExtention = imgs.name.split('.');
                        var vedioextention = imgs.type.split('/');
                        if(imgs.type == 'application/pdf'){
                          if(notSupported.length == 0){
                            notSupported.push('Document')
                          }else{
                            var match = false;
                            notSupported.forEach(matchData => {
                              if(matchData == 'Document'){
                                match = true;
                              }
                            })
                            if(!match){
                              notSupported.push('Document')
                            }
                          }
                        }else if(imageExtention[1] == 'ods'|| imageExtention[1] == 'docx' || imageExtention[1] == 'doc' ||imageExtention[1] == 'odt'|| imageExtention[1] == 'ppt' || imageExtention[1] == 'pptx'|| imageExtention[1] == 'html' || imageExtention[1] == 'htm'||imageExtention[1] == 'csv' || imageExtention[1] == "xlsx" || imageExtention[1] == "xls" || imageExtention[1] == 'xd' || imageExtention[1] == 'txt' ){
                          if(notSupported.length == 0){
                            notSupported.push('Document')
                          }else{
                            var match = false;
                            notSupported.forEach(matchData => {
                              if(matchData == 'Document'){
                                match = true;
                              }
                            })
                            if(!match){
                              notSupported.push('Document')
                            }
                          }
                        }else if(vedioextention[0] == 'video'){
                          if(notSupported.length == 0){
                            notSupported.push('Video')
                          }else{
                            var match = false;
                            notSupported.forEach(matchData => {
                              if(matchData == 'Video'){
                                match = true;
                              }
                            })
                            if(!match){
                              notSupported.push('Video')
                            }
                          }
                        }else if (vedioextention[0] == 'image'){
                            if(notSupported.length == 0){
                            notSupported.push('Image')
                          }else{
                            var match = false;
                            notSupported.forEach(matchData => {
                              if(matchData == 'Image'){
                                match = true;
                              }
                            })
                            if(!match){
                              notSupported.push('Image')
                            }
                          }
                        }
                        else if (vedioextention[0] == 'audio'){
                          if(notSupported.length == 0){
                            notSupported.push('Audio')
                          }else{
                            var match = false;
                            notSupported.forEach(matchData => {
                              if(matchData == 'Audio'){
                                match = true;
                              }
                            })
                            if(!match){
                              notSupported.push('Audio')
                            }
                          }
                        }
                        
                          // console.log('notSupported',notSupported.join(','))
                        
                        if(f.length == index+1 && notSupported.length != 0){
                          self.$root.$emit("audioplay");
                          self.$notify({
                            title:"Error",
                            text:notSupported.join(',')+' '+ "not supported.",
                            type: "error"
                          })
                        }
                      }
                      else
                      {
                        if(self.preview_list.length != f.length && f.length == index+1){
                          error=true
                          // console.log('errororor',error)
                        }
                      }
                  }else{
                    {
                      self.$root.$emit("audioplay");
                      self.$notify({
                        title:"Error",
                        text:imgs.name+' file size is maximum.',
                        type:"error"
                      })
                      self.previewImageSize = self.previewImageSize - imgs.size;
                    }
                  }
                }else{
                  self.$root.$emit("audioplay");
                  self.$notify({
                    title:"Error",
                    text:"You are restricted to sending Picture, Video and Sound files. Please upgrade to Pro to share more",
                    type: "error"
                  })
                }
                // console.log('myerror',error)
                  if(error && notSupported.length > 0){
                    self.$root.$emit("audioplay");
                    self.$notify({
                      title:"Error",
                      text:notSupported.join(',')+' '+ "not supported.",
                      type: "error"
                    })
                  }
                  index++;
                };

                if(!found)
                {
                  reader.readAsDataURL(imgs);
                }
              })
              setTimeout(()=>{
                if(self.preview_list.length || self.image_list.length)
                {
                  self.dropFiles=true
                }
              },500)

              // if(!self.preview_list.length) {
              //   self.dropFiles=false;
              // }
            }
          },
          removeFromPreview(index){
            // debugger // eslint-disable-line
            var self = this;
            self.previewIndex=0
            self.preview_list.splice(index,1)
            self.image_list.splice(index,1)
            // console.log('self.image_list',self.preview_list,self.image_list)
            if(self.image_list.length == 0){
              self.dropFiles = false
            }
          },
          // CLOSE ALL PREVIEW(Shraddha)
          closeAllPreview(){
            //  debugger // eslint-disable-line
            var self = this;
            self.previewIndex=0
            self.preview_list = []
            self.image_list = []
            self.dropFiles = false
            
          },
          
          sendAllDropMedia2(){
            var self = this;

            self.sendingMessage = '';
            self.msgTextTmp='';

            let userData = self.userData;
            let image_list = self.image_list;
            let activeGroup = self.activeGroup;
            let chatObjectId = self.chatObjectId;
            let userid = self.userid;
            let chatObject = self.chatObject;
            let currCollection = self.currCollection;
            let isInArray = self.isInArray;
            let image = self.image;

            const from = {
              avtar: userData.profileUrl,
              id: userData.id,
              name: userData.firstName,
            };
            let fromArray = [];
            let datausage = 0;
            let imgsLength = image_list.length;

            if(image_list.length > 0) {
              self.sendingMessage = '';

              for(var i=0;i<image_list.length;i++) {
                uploadImageFile(firebaseData.firebasecollection.PROFILEIMAGES,image_list[i].file.name,image_list[i].file.size,image_list[i].file,image_list[i].docType,image_list[i].text,res=>{
                  image.push(res);
                  const imagesize = res.imageSize
                  db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialPlan').get().then(docss => {
                    docss.forEach(docs => {
                      // console.log('docsdata',docs.data())
                      datausage = (Number(docs.data().dataUsage) + Number(imagesize))
                      db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialPlan').doc(firebase.auth().currentUser.uid).update({
                          dataUsage :  datausage
                      })
                    })
                  })
                  //console.log('awdawd213123',self.preview_list,image.length,self.image_list.length,imgsLength,res)
                  if(image.length==imgsLength) {
                    let count = 0;
                    const getrequestDataFunction = (row) => {
                      if(count >=  image.length){
                        self.image_list = [];
                        self.image = [];
                        self.preview_list = [];
                        return;
                      }
                      const key = row.imageUrl;
                      const imageName = row.imageName;
                      const docType = row.docType;
                      const Text = row.Text;

                      if(userData.SocialreadReceipts || activeGroup)
                      {
                        fromArray=[from];
                      }

                      if(chatObjectId != undefined && chatObjectId != '') {
                        self.addMessageAndCallAPI({
                          createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                          updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                          from: from,
                          type: docType,
                          name: imageName,
                          mediaUrl:key,
                          msg: Text,
                          messageSeen: fromArray,
                        },chatObjectId,currCollection);
                        count += 1;
                        getrequestDataFunction(image[count]);

                      } else {
                        self.currCollection = "default";
                        currCollection = "default";
                        self.connectedUserArray = [];
                        let connectedUserArray = [];
                        connectedUserArray.push(userid);
                        connectedUserArray.push(chatObject.id);
                        self.connectedUserArray = connectedUserArray;

                        let messageSeenIds=[];
                        messageSeenIds.push({id:firebase.auth().currentUser.uid,count:0});
                        messageSeenIds.push({id: chatObject.id,count:0});

                        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).add({
                          connectedUser : connectedUserArray,
                          id:'',
                          isGroup : activeGroup,
                          lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                          title:'',
                          updateChat:{},
                          msgFromBranch:false,
                          muteArray:[],
                          mainBranches:[],
                          updateBranchData:{},
                          SurpriseSelfie : {},
                          ToggleSelfie : [],
                          messageSeenIds:messageSeenIds,
                          blockBy : isInArray || ''
                        }).then((doc) => {
                          self.chatObjectId = doc.id
                          chatObjectId = doc.id
                          self.getChatArray(currCollection,  doc.id);
                          self.getSetChatData({collection:'socialMessages',chatId:doc.id});

                          db.collection(firebaseData.firebasecollection.USER).doc(userid).collection('socialContact').doc(chatObject.id).update({
                            chatId:doc.id
                          })
                          db.collection(firebaseData.firebasecollection.USER).doc(chatObject.id).collection('socialContact').doc(userid).update({
                            chatId:doc.id
                          })
                          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(doc.id).update({
                            id : doc.id
                          })
                          self.addMessageAndCallAPI({
                            createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                            updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                            from: from,
                            type: docType,
                            name: imageName,
                            mediaUrl:key,
                            msg: Text,
                            messageSeen: fromArray,
                          },chatObjectId,currCollection);
                          setTimeout(()=>{
                              self.getChatArray('default', doc.id)
                          },2000)
                          count += 1;
                          getrequestDataFunction(image[count]);

                        })
                        .catch(error=>{
                          console.error(error);
                          count += 1;
                          getrequestDataFunction(image[count]);
                        })
                      }
                    }
                    getrequestDataFunction(image[count]);
                  }
                })
              }
              self.dropFiles = false;
            }
          },
          //End Dropzone
          reset: function () {
            this.image = [];
            this.preview = null;
            this.image_list = [];
            this.preview_list = [];
          },
          init(){
            // debugger // eslint-disable-line
            var self = this;
            const db = firebase.firestore()
           // EMIT METHOD IS ON THAT COME FROM CONTACTDETAIL PAGE (Shraddha)
            self.$root.$on("openChat",(value,chatId,activeGroup,mainBranches,collection)=>{
              if(self.chatObjectId !== chatId || chatId==="" || self.chatObjectId === "" || self.currCollection !== collection) {
                self.image_list = [];
                self.image = [];
                self.preview_list = [];
                self.dropFiles = false;
              }
              if(self.chatSnapshotListener !== null) {
                self.chatSnapshotListener();
              }
              if(self.snapShotListener !== null)
              {
                // console.log("Detach default snapshot")
                if (Object.keys(self.lastMessageData).length > 0 && self.messageCount > 0) {
                  self.updateCount(self.lastMessageData, self.messageCount);
                }
                self.snapShotListener();
              }

              // Reset Values
              self.currCollection = collection;
              self.botUserEmail = Vue.prototype.$email;
              self.surpriseSelfie = false;
              self.searchErrorMessage = '';
              self.mainBranchActive=false;
              self.mainBranchActiveIndex=0;
              self.currentPage=0;
              self.subBranchActive=false;
              self.subBranchActiveIndex=0;
              self.subSubBranchActive=false;
              self.subSubBranchActiveIndex=0;
              self.senderArray = [];
              self.mainBranches=[];
              self.sendreceArray = [];
              self.chatObject = value;
              self.chatObjectId = chatId;

              self.activeChat = true;
              self.activeGroup = activeGroup.isGroup;
              if (self.activeGroup == undefined) {
                self.activeGroup = false;

                self.$root.$emit('socialBranchUser',value);
              }
              self.groupData = activeGroup;
              self.$root.$emit('clerarMainSearchKeyword');

              self.searchbaroption = 'All';
              self.searchkeyword = '';
              if ( collection!== "default") {
                self.$root.$emit('setBranch',self.mainBranchActiveIndex,self.mainBranches,self.chatObjectId);
              }
              if (self.groupData && chatId ) {
                self.$root.$emit('groupArray',collection,self.groupData,chatId);
              }
              if (chatId && chatId === self.storeChatData.id) {
                self.connectedUserLength = '';
                self.connectedUsers  = [];
                self.connectedUserLength = self.storeChatData.connectedUser.length;
                self.connectedUsers  = self.storeChatData.connectedUser;

                self.sendreceArrayBKP=[];

                self.getChatArray(collection,chatId);

                //snapshot to update chat data.
                self.chatSnapshotListener = db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                  .doc(chatId)
                  .onSnapshot(doc=>{
                    if(!doc.exists || doc.id !== chatId && chatId !== self.storeChatData.id) {
                      return;
                    }

                    self.mainBranches = self.sortArray(doc.data().mainBranches)
                    self.mainBranches.forEach(mb => {
                      mb.subBranches = self.sortArray(mb.subBranches)

                      mb.subBranches.forEach((sb => {
                        sb.subSubBranches = self.sortArray(sb.subSubBranches)
                      }))
                    })
                    self.messageSeenIds=doc.data().messageSeenIds||[]

                    var muted = false
                    doc.data().muteArray.forEach(uids=>{
                      if(uids==firebase.auth().currentUser.uid)
                      {
                        muted = true
                      }
                    })
                    self.mainBranches.forEach((data,index)=>{
                      if(data.name == self.currCollection)
                      {
                        self.mainBranchActive=true
                        self.mainBranchActiveIndex=index
                      }
                      self.mainBranches[index].subBranches.forEach((data2,index2) => {
                        if(data2.name == self.currCollection)
                        {
                          self.mainBranchActive=true
                          self.mainBranchActiveIndex=index
                          self.subBranchActive=true
                          self.subBranchActiveIndex=index2
                        }
                        self.mainBranches[index].subBranches[index2].subSubBranches.forEach((data3,index3) => {
                          if(data3.name == self.currCollection)
                        {
                          self.mainBranchActive=true
                          self.mainBranchActiveIndex=index
                          self.subBranchActive=true
                          self.subBranchActiveIndex=index2
                          self.subSubBranchActive=true
                          self.subSubBranchActiveIndex=index3
                        }
                        })
                      })
                    })
                    self.isGroupMuted=muted;
                  })
              }
            })

            self.$root.$on("activeGroup",(value) => {
              // //debugger // eslint-disable-line
              if(value == 'yes'){
                self.activeGroup = false
                self.activeChat = false
                self.chatObject = {}
              }
            })

            // CHECK LOGIN USER IS ADMIN OR CUSTOMER(Shraddha)
            self.loginUser = localStorage.getItem("IsLoginUser")
            //console.log('loginUser',self.loginUser);
            if(this.loginUser == 'Admin'){
              self.isAdmin = true
            }else{
              self.isAdmin = false
            }
            //console.log(this.isAdmin)

            // GET PROFILE PICTURE (Shraddha)
            self.userData  = {}
            self.userid = firebase.auth().currentUser.uid;
            getLoginUserData(firebaseData.firebasecollection.USER,(res) => {
              if(res.userStatus == 'Inactive') {
                self.$root.$emit('signOut');
              }else{
                self.imageUrl = res.profileUrl;
                self.userData = res;
              }
            })
            self.showLoader = false;
            self.showLoaders = false;
          },
          sortArray(mainBranchArray)
          {
            //console.log('sortCalled')
            // console.log('arr',mainBranchArray)

            var tmp = mainBranchArray||[]
            var tmp2 = ''
            if(tmp.length!=0)
            {
              for (let index = 0; index < tmp.length; index++) {
                for (let j = index+1; j < tmp.length; j++) {
                  if(tmp[index].lastMsgTime.seconds < tmp[j].lastMsgTime.seconds)
                  {
                    tmp2 = tmp[index]
                    tmp[index] = tmp[j]
                    tmp[j]=tmp2
                  }
                }
              }
            }
            return tmp
          },
          getMenuScreen(value) {
            var self = this;
            // debugger // eslint-disable-line
            self.searchErrorMessage = '';
            self.activeChat = false
            self.selectedActiveMenu = value;
            self.chatObject = {}
            self.groupData = {}
            self.activeGroup = false
            self.chatObjectId=''
            self.currCollection=''
            self.sendreceArrayBKP=[]
            self.sendreceArray=[]
            self.senderArray=[]
            self.setStoreChatId('')
            //console.log("valuuu", value);
            localStorage.setItem('backtocontact',value);
          },

          // SEND CHAT MESSAGE (Shraddha)
          sendMessage(){
            var self = this;
            self.usedSelfie = 0;
            self.msgTextTmp = self.msgTextTmp.trim()
            if(self.charecterErrorMessageForMessage == '' && (self.msgTextTmp.length || self.BulkMessage.trim().length)) {
              let sendingMessage=self.msgTextTmp.trim();
              self.tempMessages.push(sendingMessage);
              self.msgTextTmp = ''
              self.sendingMessageFlag=false;

              const res = sendingMessage.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
              let docType = "";
              let name = "";
              if(!self.surpriseSelfie){
                if(res == null){
                  docType = "Text";
                  name = "Text";
                } else {
                  docType = "Link";
                  name = "Link";
                }
              } else {
                docType = "SurpriseSelfieText";
                name = "SurpriseSelfieText";
              }

              const from = {
                avtar: self.userData.profileUrl,
                id: self.userData.id,
                name: self.userData.firstName,
                path: self.currCollection === "default" ? "" : self.currCollection,
              }

              let fromArray = [];
              if(self.userData.SocialreadReceipts || self.activeGroup)
              {
                fromArray.push(from);
              }

              //For Bulk Message
              if(self.bulkDataArray.length != 0){
                let count = 0;

                self.currCollection = "default";

                const getDataFunction = (row) => {
                  if(count == self.bulkDataArray.length){
                    self.bulkDataArray=[];
                    self.BulkMessage="";
                    return;
                  }
                  const chatId = row.chatid;
                  const userId = row.docid;
                  self.connectedUserArray = [];

                  if(self.BulkMessage !== "" && self.BulkMessage!== undefined)
                  {
                    if(chatId !== "" && chatId !== undefined) {
                      // Add the message in the chats' sub collection
                      self.addMessageAndCallAPI({
                        createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        from: from,
                        type: docType,
                        name: name,
                        msg: self.BulkMessage,
                        messageSeen: fromArray,
                        surpriseSelfieUsers : self.surpriseSelfie ? [firebase.auth().currentUser.uid] : []
                      },chatId);

                      self.updateCount({
                        chatId: chatId,
                        socialChat: true,
                        companyId: null,
                        messageCollection: "default",
                        senderData: from,
                        messageData: {msg: self.BulkMessage, type: docType,name:name}
                      },1)
                      self.surpriseSelfie = false;
                      count += 1;
                      getDataFunction(self.bulkDataArray[count]);
                    } else {
                      self.connectedUserArray = []
                      self.connectedUserArray.push(self.userid)
                      self.connectedUserArray.push(userId)
                      let messageSeenIds=[]
                      messageSeenIds.push({id:firebase.auth().currentUser.uid,count:0,time:'',IsEmailSend:false})
                      messageSeenIds.push({id:userId,count:0,time:new Date(),IsEmailSend:false})
  
                      db
                        .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                        .add({
                          connectedUser : self.connectedUserArray,
                          blockUserArray:[],
                          id:'',
                          isGroup : self.activeGroup,
                          lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                          title:'',
                          muteArray:[],
                          mainBranches:[],
                          updateChat:{},
                          msgFromBranch:false,
                          SurpriseSelfie : {},
                          ToggleSelfie : [],
                          updateBranchData:{},
                          messageSeenIds:messageSeenIds,
                        })
                        .then((docRef)=>{
                          const docId = docRef.id;
                          self.chatObjectId = docId;
  
                          self.currCollection = "default";
                          self.getChatArray(self.currCollection, docId);
                          self.getSetChatData({collection:'socialMessages', chatId:docId});
  
                          //add chat id in users documents
                          db.collection(firebaseData.firebasecollection.USER).doc(self.userid).collection('socialContact').doc(userId).update({
                            chatId:docId
                          })
                          db.collection(firebaseData.firebasecollection.USER).doc(userId).collection('socialContact').doc(self.userid).update({
                            chatId:docId
                          })
  
                          //Update doc id of the chat object
                          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(docId).update({
                            id : docId
                          })
                          .then(()=>{
                            // Add the message in the chats' sub collection
                            self.addMessageAndCallAPI({
                              createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                              updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                              from: from,
                              type: docType,
                              name: name,
                              msg: self.BulkMessage,
                              messageSeen: fromArray,
                              surpriseSelfieUsers : self.surpriseSelfie ? [firebase.auth().currentUser.uid] : []
                            },docId);

                            self.updateCount({
                              chatId: docId,
                              socialChat: true,
                              companyId: null,
                              messageCollection: "default",
                              senderData: from,
                              messageData: {msg: self.BulkMessage, type: docType,name:name}
                            },1)
                            self.surpriseSelfie = false;
                            count += 1;
                            getDataFunction(self.bulkDataArray[count]);
                          })
                          .catch(error=>{
                            console.error(error);
                            self.sendingMessageFlag=false
                          })
                        })
                        .catch(error=>{
                          console.error(error);
                          self.sendingMessageFlag=false
                        })
                    }
                  } else {
                    self.surpriseSelfie = false;
                    count += 1;
                  }
                }

                getDataFunction(self.bulkDataArray[count]);
              }
              else{
                // One to One
                if(self.chatObjectId !== "" && self.chatObjectId !== undefined) {
                  // Add the message in the chats' sub collection
                  self.addMessageAndCallAPI({
                    createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                    updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                    from: from,
                    type: docType,
                    name: name,
                    msg: sendingMessage,
                    messageSeen: fromArray,
                    surpriseSelfieUsers : self.surpriseSelfie ? [firebase.auth().currentUser.uid] : []
                  }, self.chatObjectId);
                  self.surpriseSelfie = false;
                } else {
                  self.connectedUserArray = []
                  self.connectedUserArray.push(self.userid)
                  self.connectedUserArray.push(self.chatObject.id)
                  let messageSeenIds=[]
                  messageSeenIds.push({id:firebase.auth().currentUser.uid,count:0,time:'',IsEmailSend:false})
                  messageSeenIds.push({id:self.chatObject.id,count:0,time:new Date(),IsEmailSend:false})
  
                  db
                    .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                    .add({
                      connectedUser : self.connectedUserArray,
                      blockUserArray:[],
                      id:'',
                      isGroup : self.activeGroup,
                      lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                      title:'',
                      muteArray:[],
                      mainBranches:[],
                      updateChat:{},
                      msgFromBranch:false,
                      SurpriseSelfie : {},
                      ToggleSelfie : [],
                      updateBranchData:{},
                      messageSeenIds:messageSeenIds,
                      blockBy : self.isInArray || ''
                    })
                    .then((docRef)=>{
                      const docId = docRef.id;
                      self.chatObjectId = docId;
  
                      self.currCollection = "default";
  
                      self.getSetChatData({collection:'socialMessages',chatId:docId});
  
                      //add chat id in users documents
                      db.collection(firebaseData.firebasecollection.USER).doc(self.userid).collection('socialContact').doc(self.chatObject.id).update({
                        chatId:docId
                      })
                      db.collection(firebaseData.firebasecollection.USER).doc(self.chatObject.id).collection('socialContact').doc(self.userid).update({
                        chatId:docId
                      })
  
                      //Update doc id of the chat object
                      db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(docId).update({
                        id : docId
                      })
                      .then(()=>{
                        // Add the message in the chats' sub collection
                        self.addMessageAndCallAPI({
                          createdAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                          updatedAt: firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                          from: from,
                          type: docType,
                          name: name,
                          msg: sendingMessage,
                          messageSeen: fromArray,
                          surpriseSelfieUsers : self.surpriseSelfie ? [firebase.auth().currentUser.uid] : []
                        }, docId);

                        setTimeout(()=>{
                            self.getChatArray('default', docId)
                        },2000)
                        self.surpriseSelfie = false;
                      })
                      .catch(error=>{
                        console.error(error);
                        self.sendingMessageFlag=false
                      })
                    })
                    .catch(error=>{
                      console.error(error);
                      self.sendingMessageFlag=false
                    })
                }
              }
            }
          },

          addCountInObj(messageSeenIds, sid, count) {
            const ids = messageSeenIds;
            ids.forEach((data)=>{
              if (data.id !== sid) {
                data.count+=count;
                data.time = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
              }
            });
            return ids;
          },

          updatedBranches(mainBranches, sid, messageCollection, count) {
            var self = this;
            const arr = mainBranches;
            arr.forEach((data)=>{
              if (data.name === messageCollection) {
                data.messageSeenIds = self.addCountInObj(data.messageSeenIds, sid, count);
                data.lastMsgTime = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
              } else {
                data.subBranches.forEach((data2)=>{
                  if (data2.name === messageCollection) {
                    data2.messageSeenIds = self.addCountInObj(data2.messageSeenIds, sid, count);
                    data2.lastMsgTime = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
                    data.lastMsgTime = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
                  } else {
                    data2.subSubBranches.forEach((data3)=>{
                      if (data3.name === messageCollection) {
                        data3.messageSeenIds = self.addCountInObj(data3.messageSeenIds, sid, count);
                        data3.lastMsgTime = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
                        data2.lastMsgTime = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
                        data.lastMsgTime = firebaseTimestamp.firestore.Timestamp.fromDate(new Date());
                      }
                    });
                  }
                });
              }
            });

            return arr;
          },

          updateCount(data, count){
            const self = this;

            self.messageCount = 0;
            self.lastMessageData = {};

            setTimeout(()=>{
              const {socialChat, companyId,
                chatId, messageCollection,
                senderData, messageData,
              } = data;
  
              const messageFromBranch = messageCollection!="default";
              const obj = {
                from: senderData,
                msg: messageData.msg,
                name: messageData.name,
                type: messageData.type,
              };
  
              const updateBranchData = {
                from: senderData,
                msg: messageData.msg,
                name: messageData.name,
                type: messageData.type,
              };
  
              let query = "";
  
              if (socialChat) {
                query = db.collection("socialMessages").doc(chatId);
              } else {
                query = db.collection(companyId).doc(companyId)
                  .collection("businessMessages").doc(chatId);
              }
  
              query
                  .get()
                  .then((doc)=>{
                    const sid = senderData.id;
  
                    let messageSeenIds = doc.data().messageSeenIds || [];
                    let mainBranches = doc.data().mainBranches || [];
  
                    let finalUpdate = {};
  
                    try {
                      if (messageCollection === "default") {
                        messageSeenIds = self.addCountInObj(messageSeenIds, sid, count);
                        obj.fromBranch = false;
  
                        finalUpdate = {
                          messageSeenIds: messageSeenIds,
                          mainBranches: mainBranches,
                          updateChat: obj,
                          messageFromBranch: messageFromBranch,
                          lastMsgtime: firebaseTimestamp.firestore.Timestamp.fromDate(new Date()),
                        };
                      } else {
                        obj.fromBranch = true;
                        mainBranches = self.updatedBranches(mainBranches, sid, messageCollection, count);
  
                        finalUpdate = {
                          messageSeenIds: messageSeenIds,
                          mainBranches: mainBranches,
                          updateBranchData: updateBranchData,
                          messageFromBranch: messageFromBranch,
                          lastMsgtime: firebaseTimestamp.firestore.Timestamp.fromDate(new Date()),
                        };
                      }
                    } catch (error) {
                      console.error("Error", error);
                      // reject(error);
                    }
  
                    query
                        .update(finalUpdate)
                        .then(()=>{
                          // console.log("Count Added",finalUpdate);
                          // resolve(finalUpdate);
                        })
                        .catch((error)=>{
                          console.error("Query Update Error", error);
                          // reject(error);
                        });
                  })
                  .catch((error)=>{
                    console.error("QueryResult Error", error);
                    // reject(error);
                  });
            },500)

          },

          addMessageAndCallAPI(chatData,...args){
            var self = this;
            if(chatData.type == 'SurpriseSelfieText'){
              self.updateSurpriseSelfieEntryInDb();
            }
            let id = args.length ? args[0] : self.chatObjectId;
            const collection = args.length > 1 ? args[1] : self.currCollection;
            try{
              self.blockUserArray = self.storeChatData.blockUserArray || []
              self.blockUserArray.forEach(inArray => {
                if(inArray.blockTo == firebase.auth().currentUser.uid){
                  self.isInArray = inArray.blockBy
                }
              })
              const query = db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(id).collection(collection);
              self.messageCount+=1;
              self.lastMessageData = {
                chatId: id,
                socialChat: true,
                companyId: null,
                messageCollection: collection,
                senderData: chatData.from,
                messageData: {msg: chatData.msg, type: chatData.type,name:chatData.name},
              }

              if(self.unreadMessages>0) {
                self.unreadMessages=0;
              }

              if(self.unreadMessages > 0) {
                self.setCountToZero(id);
              }
              // Store message in specified collection
              query
                .add({...chatData, blockBy: self.isInArray || ""})
                .then((docRef)=>{
                  query
                    .doc(docRef.id)
                    .update({
                      id: docRef.id
                    })
                    .catch(error=>{
                      console.error(error);
                    })

                  self.tempMessages.splice(0,1);
                  self.sendingMessageFlag=false;
                  self.$axios.post(self.$apiurl+'sendpushNotification',{
                      userId:self.userData.id,msgCollection: collection,
                      chatId:id,msgId:docRef.id,
                      userName: self.userData.firstName || "",
                      userProfile: self.userData.profileUrl || ""
                  })
                })
                .catch(error=>{
                  console.error(error);
                  self.sendingMessageFlag=false;
                })
            } catch(error) {
              console.error(error);
              self.sendingMessageFlag=false;
            }
          },
          // UPDATE ENTRY WHEN SURPRISE SELFIE TEXT(Shraddha)
          updateSurpriseSelfieEntryInDb(){
            var self = this;
            console.log(self.chatObject,'receiverUser',self.socialPlanData);
              var databaseTime = new Date(self.socialPlanData.selfieTime.seconds * 1000)
              var afterSixHourTime =  new Date(databaseTime.setHours(databaseTime.getHours() + 6));
              if(afterSixHourTime <= new Date()){
                self.socialPlanData.usedSelfie = 0;
              }

              if(self.socialPlanData.usedSelfie == 0){
                var usedselfie = self.socialPlanData.usedSelfie + 1
                self.usedSelfie = usedselfie
                var selfieuser = [];
                selfieuser = self.socialPlanData.selfieUsers || []
                var dynamicObj = {
                  [self.chatObjectId] : self.chatObject.firstName
                }
                selfieuser.push(dynamicObj)
                 let updatedObjectForSurpriseSelfie = {
                    usedSelfie : usedselfie,
                    selfieUsers : selfieuser,
                    selfieTime : firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                }
                self.updateEntrySocialPlan(updatedObjectForSurpriseSelfie);
              }
              else{
                var usedselfie = self.socialPlanData.usedSelfie + 1
                self.usedSelfie = usedselfie
                var selfieuser = [];
                selfieuser = self.socialPlanData.selfieUsers || []
                var dynamicObj = {
                  [self.chatObjectId] : self.chatObject.firstName
                }
                selfieuser.push(dynamicObj)
                let updatedObjectForSurpriseSelfie = {
                    usedSelfie : usedselfie,
                    selfieUsers : selfieuser,
                }
                self.updateEntrySocialPlan(updatedObjectForSurpriseSelfie);
              }
              if(self.usedSelfie == self.socialPlanData.noOfSelfie){
                try{
                  db.collection(firebaseData.firebasecollection.SELFIENOTIFICATION).add({
                    userId : firebase.auth().currentUser.uid,
                    selfieTime : self.socialPlanData.selfieTime,
                    notificationTime : afterSixHourTime,
                    sent : false
                  }).catch(error => {
                    console.log(error)
                  })
                }catch(error){
                  console.log(error)
                }
              }
          },
          // UPDATE ENTRY IN SOCIAL PLAN WHEN SURPRISE SELFIE TEXT(Shraddha)
          updateEntrySocialPlan(updatedObject){

              try{
                db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialPlan').doc(firebase.auth().currentUser.uid).update({
                 updatedObject
                }).catch(error => {
                  console.log(error)
                })
              }catch(error){
                console.log(error);
              }
          },
          compareObjects(object1, object2, key) {
            const obj1 = object1[key];
            const obj2 = object2[key];
            if (obj1 < obj2) {
              return -1
            }
            if (obj1 > obj2) {
              return 1
            }
            return 0
          },
          comparetimestamp(object1, object2, key){
            const obj1 = object1[key];
            const obj2 = object2[key];
            if (obj1 < obj2) {
              return 1
            }
            if (obj1 > obj2) {
              return -1
            }
            return 0
          },
          addZero(val){
            if(val<10)
            {
              return '0'+val;
            }
            else
            {
              return val;
            }
          },
          //FOR GET CHAT MESSAGE(Shraddha)
          getChatArray(collection,chatObjectId){
            var self = this;
            self.showLoader=true
            self.unreadMessages = 0
            if(self.currCollection !== collection) {
              self.image_list = [];
              self.image = [];
              self.preview_list = [];
              self.dropFiles = false;
            }
            self.currCollection = collection 
            // self.sendreceArray=self.sendreceArrayBKP
            self.sendreceArray=[]
            self.searchedChat = []
            // Detach previous snapshot listener
            if(self.snapShotListener !== null)
            {
              // console.log("Detach snapshot")
              self.snapShotListener();
            }
            self.setCurrentCollection(self.currCollection)
            self.getSetChatData({collection:'socialMessages',chatId:chatObjectId})
            const db = firebase.firestore();

            if(self.groupData){
              self.$root.$emit('groupArray',self.currCollection,self.groupData,self.chatObjectId || '')
            }
            var activeBranchName = ''
            if(self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
            {
              activeBranchName = self.mainBranches[self.mainBranchActiveIndex].name
            }
            else if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
            {
              activeBranchName = self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name
            }
            else if(self.mainBranchActive && self.subBranchActive && self.subSubBranchActive)
            {
              activeBranchName = self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name
            }
            self.checkUnreadMessages(self.currCollection,chatObjectId,activeBranchName);

            let query;
            let DataSearch = [];

            if(self.searchbaroption == '' || self.searchbaroption == 'All' ) {
              if(self.searchkeyword == '') {
                query = db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.storeChatId).collection(collection).orderBy('createdAt','desc').limit(15);
              } else {
                query = db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.storeChatId).collection(collection).orderBy('createdAt','desc');
              }
            }
            else {
              if(self.searchbaroption == 'Links'){
                DataSearch = ['Link']
              }
              else if(self.searchbaroption == 'Photos'){
                DataSearch = ['Image']
              }
              else if(self.searchbaroption == 'Videos'){
                DataSearch = ['Video']
              }
              else if(self.searchbaroption == 'Documents'){
                DataSearch = ['pdf','excel','word','odt','ods','plain','xd','ppt','html']
              }

              if(DataSearch.length) {
                query = db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.storeChatId).collection(collection).orderBy('createdAt','desc').where('type','in',DataSearch).limit(15);
              } else {
                query = db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.storeChatId).collection(collection).orderBy('createdAt','desc').limit(15);
              }
            }

            self.senderArray = [];
            self.senderchatId ='';
            self.snapShotListener = query.onSnapshot({includeMetadataChanges:true},(querySnapshot) => {
              if(self.storeChatId != "" && self.chatObjectId === self.storeChatData.id){
                if(self.currCollection!='' && self.currCollection!=undefined)
                {
                  if(self.chatObjectId == chatObjectId || self.chatObjectId==undefined)
                  {
                    var index = 1;
                    if(querySnapshot.empty)
                    {
                      self.showLoader=false
                    }
                    self.showLoader=false
                    querySnapshot.docChanges().forEach(change=>{
                      const docdata = change.doc;
                      if(docdata.id)
                      {
                        self.lastVisible = docdata;
                      }

                      if(change.type=='added')
                      {
                        if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid)
                        {
                          if(self.userid == docdata.data().from.id){
                              self.senderArray.push({
                                  messageType: 'sender',
                                  sendermsg :docdata.data().msg,
                                  mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                  createdAt : docdata.data().createdAt || new Date() ,
                                  Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                                  senderName : docdata.data().from.name,
                                  type:docdata.data().type,
                                  name:docdata.data().name,
                                  msg:docdata.data().msg,
                                  question:docdata.data().questions,
                                  polldata:docdata.data().uploadPollData,
                                  defaultid:docdata.data().id,
                                  messageSeen:docdata.data().messageSeen,
                                  chatId:chatObjectId,
                                  answered:false,
                                  mypollanswer:'',
                                  expireyTimeObjects:{
                                      date:'',
                                      month:'',
                                      year:'',
                                      hrs:'',
                                      min:''
                                  },
                                  beforeDate:{
                                      Datesplit:'',
                                      monthsplit:'',
                                      yearsplit:''
                                  },
                                  expired:false,
                                  branch:docdata.data().branch||'',
                              })
                          }else{
                              self.senderArray.push({
                              messageType: 'receiver',
                              receivermsg :docdata.data().msg,
                              mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                              Time: docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : '',
                              createdAt:docdata.data().createdAt || new Date(),
                              senderName : docdata.data().from.name,
                              type:docdata.data().type,
                              name:docdata.data().name,
                              msg:docdata.data().msg,
                              question:docdata.data().questions,
                              polldata:docdata.data().uploadPollData,
                              defaultid:docdata.data().id,
                              messageSeen:docdata.data().messageSeen,
                              chatId:chatObjectId,
                              answered:false,
                              mypollanswer:'',
                              expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                              },
                              beforeDate:{
                                  Datesplit:'',
                                  monthsplit:'',
                                  yearsplit:''
                              },
                              expired:false,
                              branch:docdata.data().branch||'',
                              })

                              if(!docdata.metadata.hasPendingWrites && self.senderArray[self.senderArray.length-1].defaultid) {
                              self.senderArray[self.senderArray.length-1].messageSeen.length < self.storeChatData.connectedUser.length ? self.markMessageRead(self.senderArray[self.senderArray.length-1]) : '';
                              }
                          }

                          if(self.senderArray[self.senderArray.length-1].type=="TextPoll" || self.senderArray[self.senderArray.length-1].type=="ImagePoll")
                          {
                            self.senderArray[self.senderArray.length-1].polldatapolldata = docdata.data().uploadPollData;
                            self.chatObjectId!="" ?self.checkPollAnswer2(self.senderArray[self.senderArray.length-1]):''
                          }

                          self.senderArray[self.senderArray.length-1].pending = docdata.metadata.hasPendingWrites;
                        }
                        if(index == querySnapshot.size)
                        {
                          self.showLoader=false
                        }
                        index++
                      }
                      if(change.type=='modified') {
                          // --------------------- If changes are from LOCAL ---------------------
                          if(docdata.metadata.hasPendingWrites) {
                          const ind =  self.senderArray.findIndex((x)=>(x.defaultid === undefined && x.msg === docdata.data().msg && x.messageType === 'sender'))
                            if(ind > -1) {
                                self.senderArray[ind].pending = false;
                                self.senderArray[ind].defaultid = docdata.id;
                                self.senderArray[ind].createdAt = docdata.data().createdAt;
                                self.senderArray[ind].messageSeen = docdata.data().messageSeen;
                            }
                          } else {
                          // --------------------- If changes are from SERVER ---------------------
                            if(docdata.data().from.id === self.userid) {
                                const ind =  self.senderArray.findIndex((x)=>{
                                  // console.log("Condition",x.defaultid === undefined , x.msg === docdata.data().msg , x.messageType === 'receiver')
                                  // console.log("Values",x.defaultid, x.msg,docdata.data().msg, x.messageType)
                                  return (x.defaultid === docdata.id)
                                });

                                if(ind > -1 && docdata.data().messageSeen) {
                                  self.senderArray[ind].messageSeen = docdata.data().messageSeen;
                                }
                            } else {
                                const ind =  self.senderArray.findIndex((x)=>{
                                  return (x.defaultid === undefined && x.msg === docdata.data().msg && x.messageType === 'receiver')
                                });

                                if(ind > -1 && self.senderArray[ind].messageSeen.length < self.storeChatData.connectedUser.length) {
                                  self.senderArray[ind].pending = false;
                                  self.senderArray[ind].defaultid = docdata.id;
                                  self.senderArray[ind].createdAt = docdata.data().createdAt;
                                  self.senderArray[ind].messageSeen = docdata.data().messageSeen;
                                  self.markMessageRead(self.senderArray[ind]);
                                }
                            }
                            if(self.senderArray[self.senderArray.length-1].type=="TextPoll" || self.senderArray[self.senderArray.length-1].type=="ImagePoll")
                            {
                              self.senderArray[self.senderArray.length-1].polldata = docdata.data().uploadPollData;
                              self.senderArray[self.senderArray.length-1].question = docdata.data().questions;
                              self.chatObjectId!="" ?self.checkPollAnswer2(self.senderArray[self.senderArray.length-1]):''
                            }
                          }
                      }
                      if(change.type=='removed') {
                          let ind = self.senderArray.findIndex((x)=>x.defaultid === docdata.id)

                          if(ind > -1) {
                            self.senderArray.splice(ind,1);
                          }

                          ind = self.sendreceArray.findIndex((x)=>x.defaultid === docdata.id)

                          if(ind > -1) {
                            self.sendreceArray.splice(ind,1);
                          }
                      }
                      if(!docdata.metadata.hasPendingWrites) {
                        // if(self.sendreceArray.length) {
                        // console.log("Count "+ self.unreadMessages);
                          // self.?unreadMessages=0;
                          // if(document.hasFocus()) {
                          //   if(self.currCollection === "default") {
                          //     self.changeDefaultMessageCount(self.chatObjectId,collection);
                          //   } else {
                          //     self.changeMessageCount(self.chatObjectId,collection);
                          //   }
                          // }
                        // }
                        if(self.sendreceArray.length && self.unreadMessages > 0) {
                          self.unreadMessages = 0
                        }
                      }
                      // console.log(`Check Change:\nChange type: ${change.type},\nFrom Cache: ${docdata.metadata.fromCache}\nFrom local: ${docdata.metadata.hasPendingWrites}\nDoc: `,docdata.data())
                    })
                    setTimeout(()=>{
                      self.setCountToZero(self.chatObjectId);
                    }, 1 * 800);
                  }
                }
                // if(!self.sendreceArray.length) {
                  self.senderArray.forEach((data)=>{
                    if(self.sendreceArray.findIndex(x => x.defaultid === data.defaultid) === -1) {
                      self.sendreceArray.push(data)
                    }
                  })
                  // self.sendreceArray = self.senderArray
                  self.sendreceArray.sort((book1, book2) => {
                    return self.compareObjects(book1, book2, 'createdAt')
                  })
                // } else {
                //   var ids = new Set(self.sendreceArray.map(d => d.createdAt.seconds));
                //   var merged = [...self.sendreceArray, ...self.senderArray.filter(d => !ids.has(d.createdAt.seconds))];
                //   self.sendreceArray = merged
                //   // debugger // eslint-disable-line
                //   self.sendreceArray.sort((book1, book2) => {
                //     return self.compareObjects(book1, book2, 'createdAt')
                //   })
                // }
                self.sendreceArrayBKP=self.sendreceArray
              }
            })
          },
          markMessageRead(data)
          {
            var self = this;
            if(self.chatObjectId=="")
            {
              return;
            }
            if(data.messageType=='sender') {
              return;
            }

            var answered = false
            data.beforeTime = data.createdAt ? ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1))) : ''
            data.beforeDate.Datesplit = data.createdAt ? (new Date(data.createdAt.seconds * 1000).getDate()) : ''
            data.beforeDate.monthsplit = data.createdAt ? (new Date(data.createdAt.seconds * 1000).getMonth()) : ''
            data.beforeDate.yearsplit = data.createdAt ? (new Date(data.createdAt.seconds * 1000).getFullYear()) : ''

            if(data.messageSeen.length >= self.storeChatData.connectedUser.length){
              return;
            }
            var userId = firebase.auth().currentUser.uid
            
            var seen = false
            var connected  = false
            var uidObj = ''

            self.connectedUsers.forEach(uids=>{
              if(uids==userId)
              {
                connected = true
                uidObj = uids
              }
            })
            // console.log('msgSeen',data)
            data.messageSeen.forEach((val)=>{
              if(val.id == userId)
              {
                seen=true;
              }
            })

            var doReadReceipt = false

            if(self.userData.SocialreadReceipts || self.activeGroup)
            {
              doReadReceipt = true
            }

            if(!seen && connected && doReadReceipt)
            {
              if (document.hasFocus()) {

                self.checkPollAnswer2Data = {}
                  data.messageSeen.push({
                    id:userId,
                    name:self.userData.firstName,
                    avtar:self.userData.profileUrl,
                    messageSeenTime:new Date(),
                  })
                if(data.defaultid!='')
                {
                  // console.log("Do read receipts",data,data.messageSeen,userId,data.chatId,self.currCollection,data.defaultid);
                  // console.log('data.defaultid',data.chatId,self.currCollection,data.defaultid)
                  db
                    .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                    .doc(data.chatId)
                    .collection(self.currCollection)
                    .doc(data.defaultid)
                    .update({
                      messageSeen: data.messageSeen
                    })
                    .then(()=>{
                      // console.log("Read Receipts added",data.msg);
                    })
                    .catch(error=>{
                      console.error(error.message);
                    })
                }
              }
            }
          },
          changeMessageCount(docId,collection)
          {
            var mainBranchesData = this.storeChatData.mainBranches||[]
            mainBranchesData.forEach(data=>{
              if(data.name == collection)
              {
                data.messageSeenIds.forEach(ids=>{
                  if(ids.id==firebase.auth().currentUser.uid)
                  {
                    ids.IsEmailSend=true
                    ids.count=0
                    ids.time = ''
                  }
                })
              }
              else
              {
                data.subBranches.forEach(data2=>{
                  if(data2.name == collection)
                  {
                    data2.messageSeenIds.forEach(ids2=>{
                      if(ids2.id==firebase.auth().currentUser.uid)
                      {
                        ids2.IsEmailSend=true
                        ids2.count=0
                        ids2.time = ''
                      }
                    })
                  }
                  else
                  {
                    data2.subSubBranches.forEach(data3=>{
                      if(data3.name == collection)
                      {
                        data3.messageSeenIds.forEach(ids3=>{
                          if(ids3.id==firebase.auth().currentUser.uid)
                          {
                            ids3.IsEmailSend=true
                            ids3.count=0
                            ids3.time = ''
                          }
                        })
                      }
                    })
                  }
                })
              }
            })
      
            db
              .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
              .doc(docId)
              .update({
                mainBranches : mainBranchesData
              })
              .then(()=>{
                //console.log('Count Updated.')
              })
              .catch(error=>{
                console.log(error)
              })

          },
          changeDefaultMessageCount(docId,collection)
          {
            // debugger // eslint-disable-line
            var messageSeenIds = this.storeChatData.messageSeenIds||[];

            messageSeenIds.forEach(data=>{
              if(data.id == firebase.auth().currentUser.uid) {
                data.count=0;
                data.IsEmailSend=false
                data.time="";
              }
            })

            db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
              .doc(docId)
              .update({
                messageSeenIds : messageSeenIds
              })
              .catch(error=>{
                console.log(error)
              })
          },
          checkUnreadMessages(collection,chatObjectId,activeBranchName)
          {
            var self = this
            var uid = firebase.auth().currentUser.uid
            // console.log('checkUnreadMessages',collection,chatObjectId,activeBranchName)
            // db
            //   .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
            //   .doc(chatObjectId)
            //   .get().then(doc=>{
                if(collection != 'default' && activeBranchName == collection)
                {
                  // console.log('Branch checkUnread')
                  if(self.chatObjectId == chatObjectId || self.chatObjectId==undefined)
                  {
                    self.storeChatData.mainBranches.forEach(data=>{
                      if(data.name == self.currCollection)
                      {
                        data.messageSeenIds.forEach(ids=>{
                          if(ids.id == firebase.auth().currentUser.uid)
                          {
                            self.unreadMessages=ids.count
                          
                            // self.changeMessageCount(self.chatObjectId,data.name)
                          }
                        })
                      }
                      else
                      {
                        data.subBranches.forEach(data2=>{
                          if(data2.name == self.currCollection)
                          {
                            data2.messageSeenIds.forEach(ids2=>{
                              if(ids2.id == firebase.auth().currentUser.uid)
                              {
                                self.unreadMessages= ids2.count
                                
                                // self.changeDefaultMessageCount(self.chatObjectId,data2.name)
                              }
                            })
                          }
                          else
                          {
                            data2.subSubBranches.forEach(data3=>{
                            if(data3.name == self.currCollection)
                            {
                              data3.messageSeenIds.forEach(ids3=>{
                                if(ids3.id == firebase.auth().currentUser.uid)
                                {
                                  self.unreadMessages= ids3.count
                                
                                  // self.changeDefaultMessageCount(self.chatObjectId,data3.name)
                                }
                              })
                            }
                          })
                          }
                        })
                      }
                    })
                  }
                }
                else if(collection=='default' && !self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                {
                  // console.log('Default checkUnread')
                  if(self.chatObjectId == chatObjectId || self.chatObjectId==undefined)
                  {
                    self.storeChatData.messageSeenIds.forEach(data=>{
                      if(data.id==firebase.auth().currentUser.uid)
                      {
                        self.unreadMessages = data.count
                        // self.changeDefaultMessageCount(self.chatObjectId,collection)
                      }
                    })
                  }
                }

                // console.log("Data",self.unreadMessages,self.storeChatData.messageSeenIds)
              // })
          },
          // COUNT ALL BRANCH (Shraddha)
          countAllbranch(branchData,branchString){
            // debugger // eslint-disable-line
            var self = this
            var mainCount = 0; 
            var subCount = 0;
            var subSubCount = 0;
            var uid = firebase.auth().currentUser.uid;

            if(!Object.keys(branchData).length) {
                return;
            }

            if(branchString == 'mainbranch'){
              if(branchData.name != self.currCollection){
                branchData.messageSeenIds.forEach(ids=>{
                  if(ids != 0){
                    if(ids.id == firebase.auth().currentUser.uid)
                    {
                      mainCount+=ids.count
                    }
                  }
                })
              
              }
              if(branchData.subBranches){
                branchData.subBranches.forEach(data2=>{
                  if(data2.name != self.currCollection){
                    data2.messageSeenIds.forEach(ids2=>{
                      if(ids2 != 0){
                        if(ids2.id == firebase.auth().currentUser.uid)
                        {
                          subCount+= ids2.count
                        }
                      }
                    })
                    
                  }
                  if(data2.subSubBranches){
                    data2.subSubBranches.forEach(data3=>{
                      if(data3.name != self.currCollection){
                        data3.messageSeenIds.forEach(ids3=>{
                          if(ids3 != 0){
                            if(ids3.id == firebase.auth().currentUser.uid)
                            {
                              subSubCount+= ids3.count
                            }
                          }
                        })
                      }
                    })
                  }
                })
              }
            }
            else if(branchString == 'subbranch'){
              if(branchData.name != self.currCollection){
                branchData.messageSeenIds.forEach(ids=>{
                  if(ids != 0){
                    if(ids.id == firebase.auth().currentUser.uid)
                    {
                      subCount+=ids.count
                    }
                  }
                })
              }
              if(branchData.subSubBranches){
                branchData.subSubBranches.forEach(data3=>{
                  if(data3.name != self.currCollection){
                    data3.messageSeenIds.forEach(ids3=>{
                      if(ids3 != 0){
                        if(ids3.id == firebase.auth().currentUser.uid)
                        {
                          subSubCount+= ids3.count
                        }
                      }
                    })
                  }
                })
              }
            }
            else if (branchString == 'subsubbranch'){
              if(branchData.name != self.currCollection){
                branchData.messageSeenIds.forEach(ids=>{
                  if(ids != 0){
                    if(ids.id == firebase.auth().currentUser.uid)
                    {
                      subSubCount+=ids.count
                    }
                  }
                })
              }
            }

            if(branchString == 'mainbranch'){
                return mainCount + subCount + subSubCount;
            }
            else if(branchString == 'subbranch'){
                return subCount + subSubCount;
            }
            else if (branchString == 'subsubbranch'){
                return subSubCount;
            }

          },
          checkPollAnswer2(data)
          {
            // debugger // eslint-disable-line
            var self = this;

            if(self.chatObjectId=="")
            {
              return 0;
            }

            var answered = false
            data.beforeTime = data.createdAt ? ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1))) : ''
            data.beforeDate.Datesplit = data.createdAt ? (new Date(data.createdAt.seconds * 1000).getDate()) : ''
            data.beforeDate.monthsplit = data.createdAt ? (new Date(data.createdAt.seconds * 1000).getMonth()) : ''
            data.beforeDate.yearsplit = data.createdAt ? (new Date(data.createdAt.seconds * 1000).getFullYear()) : ''

            var userId = firebase.auth().currentUser.uid;

            if(data.type=="TextPoll" || data.type=="ImagePoll")
            {
              var timesplit = data.question.time.split(':')
              var datesplit = data.question.date.split('-')
    
              //console.log('ABCD',timesplit,datesplit)
    
              data.expireyTimeObjects.hrs = timesplit[0]
              data.expireyTimeObjects.min = timesplit[1]
    
              data.expireyTimeObjects.year = datesplit[0]
              data.expireyTimeObjects.month = datesplit[1]
              data.expireyTimeObjects.date = datesplit[2]

              db
                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                .doc(self.chatObjectId)
                .collection(self.currCollection)
                .doc(data.defaultid)
                .collection('answer')
                .get().then(querySnapshot=>{
                  if(!querySnapshot.empty)
                  {
                    querySnapshot.forEach(doc=>{
                      if(doc.id==self.userid)
                      {
                        self.mypollanswer = doc.data().ans
                        answered=true
                      }
                    })
                  }
                  if(answered)
                  {
                    data.mypollanswer = self.mypollanswer
                    data.answered=true
                  }
                  else
                  {
                    data.answered=false
                  }
                  data.expired = self.checkExpirey(data.expireyTimeObjects)
                })
                .catch(error=>{console.log(error)})
    
            }
            // })
            
            // self.sendreceArray = self.senderArray
            // self.sendreceArray.sort((book1, book2) => {
            //   return self.compareObjects(book1, book2, 'createdAt')
            // })
            // self.sendreceArrayBKP=self.sendreceArray
            //console.log('self.sendreceArray', self.sendreceArray)
          },

          //SCROLL METHODS FOR GET MORE MESSAGES(Shraddha)
          scroollmethod(){
            // debugger // eslint-disable-line
          
            var self = this;
          
              // console.log('HELLO',Number(self.messageHistory))
            if(self.searchbaroption == '' || self.searchbaroption == 'All' ){
              if(self.searchkeyword == ''){  
                if(self.messageHistory != 'unlimited'){
                  if(self.senderArray.length < Number(self.messageHistory)){

                  var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

                  const db = firebase.firestore()
                  if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                    {
                      // self.senderArray = [];
                      // self.senderchatId ='',
                      // //console.log('self.lastVisibleself.lastVisible',self.lastVisible)
                      // SENDER AND RECEIVER ARRAY GET(Shraddha)
                        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                        .doc(self.chatObjectId)
                        .collection('default')
                        .orderBy('createdAt','desc')
                        .startAfter(self.lastVisible)
                        .limit(15)
                        .get().then((snap) => {
                          self.senderArray = [];
                          snap.forEach((docdata) => {
                            //  self.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                          self.lastVisible = docdata
                          //  //console.log("last",self.lastVisible);
                            if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid){
                              if(self.userid == docdata.data().from.id){
                                self.senderArray.push({
                                messageType: 'sender',
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                sendermsg :docdata.data().msg,
                                createdAt : docdata.data().createdAt || new Date(),
                                Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:self.chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                  },
                                  expired:false,
                                  branch:docdata.data().branch||'',
                                // connectedUserLength:docdata.data().connectedUser.length
                              })
                              }else{
                                self.senderArray.push({
                                  messageType: 'receiver',
                                  receivermsg :docdata.data().msg,
                                  mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                  Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                                  createdAt:docdata.data().createdAt || new Date(),
                                  senderName : docdata.data().from.name,
                                  type:docdata.data().type,
                                  name:docdata.data().name,
                                  msg:docdata.data().msg,
                                  question:docdata.data().questions,
                                  polldata:docdata.data().uploadPollData,
                                  messageSeen:docdata.data().messageSeen,
                                  defaultid:docdata.data().id,
                                  chatId:self.chatObjectId,
                                  answered:false,
                                  expireyTimeObjects:{
                                    date:'',
                                    month:'',
                                    year:'',
                                    hrs:'',
                                    min:''
                                  },
                                  beforeDate:{
                                      Datesplit:'',
                                      monthsplit:'',
                                      yearsplit:''
                                    },
                                    expired:false,
                                    branch:docdata.data().branch||'',
                                  //  connectedUserLength:docdata.data().connectedUser.length
                                })
                              }  
                            }
                          })
                          self.senderArray.forEach(data=>{
                            var answered = false
                            data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                              
                            data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                            data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                            data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                            if(data.type=="TextPoll" || data.type=="ImagePoll" )
                            {
                              db
                                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                                .doc(self.chatObjectId)
                                .collection('default')
                                .doc(data.defaultid)
                                .collection('answer')
                                .get().then(querySnapshot=>{
                                  if(!querySnapshot.empty)
                                  {
                                    querySnapshot.forEach(doc=>{
                                      if(doc.id==self.userid)
                                      {
                                        answered=true
                                      }
                                    })
                                  }
                                  if(answered)
                                  {
                                    data.answered=true
                                  }
                                  else
                                  {
                                    data.answered=false
                                  }
                                  // if(querySnapshot.size==index)
                                  // {
                                    
                                  // }
                                })
                                .catch(error=>{console.log(error)})
                            }
                            if(data.messageType=='receiver'){
                              var seen = false
                              var connected  = false
                              var userId = firebase.auth().currentUser.uid

                              self.connectedUsers.forEach(uids=>{
                                if(uids==userId)
                                {
                                  connected = true
                                }
                              })
                              data.messageSeen.forEach((val)=>{
                                // //console.log('shradddggggghhhahahahh',data.messageSeen)
                                if(val.id == userId)
                                {
                                  seen=true
                                }
                              })
                              if(!seen && connected)
                              {
                                data.messageSeen.push({
                                  id:self.userid,
                                  name:self.userData.firstName,
                                  avtar:self.userData.profileUrl,
                                  messageSeenTime:new Date(),
                                })
                                db
                                  .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                                  .doc(self.chatObjectId)
                                  .collection('default')
                                  .doc(data.defaultid)
                                  .update({
                                    messageSeen: data.messageSeen
                                    // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                              //   id:userId,
                              //   name:self.userData.firstName,
                              //   avtar:self.userData.profileUrl,
                              //   messageSeenTime:new Date(),
                              // }),
                            })
                            .catch(error=>{
                              console.log(error)
                            })
                        }
                      }
                            //console.log('self.senderArray', self.sendreceArray)
                          })
                          // self.sendreceArray = self.sendreceArray.concat(self.senderArray)
                          var ids = new Set(self.sendreceArray.map(d => d.createdAt.seconds));
                          var merged = [...self.sendreceArray, ...self.senderArray.filter(d => !ids.has(d.createdAt.seconds))];
                          self.sendreceArray = merged
                          // debugger // eslint-disable-line
                          self.sendreceArray.sort((book1, book2) => {
                            return self.compareObjects(book1, book2, 'createdAt')
                          })
                          self.sendreceArrayBKP=self.sendreceArray

                        
                          // if(self.Mainsearchkeyword == 'All')
                          // {
                            
                          // }
                        })
                    }
                    else if(self.mainBranchActive || self.subBranchActive || self.subSubBranchActive)
                    {
                      // self.senderArray = [];
                      // self.senderchatId ='',
                      // //console.log('self.lastVisibleself.lastVisible',self.lastVisible)
                    // SENDER AND RECEIVER ARRAY GET(Shraddha)
                        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                        .doc(self.chatObjectId)
                        .collection(self.currCollection)
                        .orderBy('createdAt','desc')
                        .startAfter(self.lastVisible)
                        .limit(15)
                        .get().then((snap) => {
                          self.senderArray = [];
                          snap.forEach((docdata) => {
                            //  self.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                          self.lastVisible = docdata
                          //  //console.log("last",self.lastVisible);
                            if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid){
                              if(self.userid == docdata.data().from.id){
                                self.senderArray.push({
                                messageType: 'sender',
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                sendermsg :docdata.data().msg,
                                createdAt : docdata.data().createdAt || new Date(),
                                Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:self.chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                  },
                                  expired:false,
                                  branch:docdata.data().branch||'',
                                // connectedUserLength:docdata.data().connectedUser.length
                              })
                              }else{
                                self.senderArray.push({
                                  messageType: 'receiver',
                                  mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                  receivermsg :docdata.data().msg,
                                  Time : docdata.data().createdAt ? new Date(docdata.data().createdAt.seconds * 1000).toLocaleTimeString().replace(/:\d+ /, ' ') : '',
                                  createdAt:docdata.data().createdAt || new Date(),
                                  senderName : docdata.data().from.name,
                                  type:docdata.data().type,
                                  name:docdata.data().name,
                                  msg:docdata.data().msg,
                                  question:docdata.data().questions,
                                  polldata:docdata.data().uploadPollData,
                                  messageSeen:docdata.data().messageSeen,
                                  defaultid:docdata.data().id,
                                  chatId:self.chatObjectId,
                                  answered:false,
                                  expireyTimeObjects:{
                                    date:'',
                                    month:'',
                                    year:'',
                                    hrs:'',
                                    min:''
                                  },
                                  beforeDate:{
                                      Datesplit:'',
                                      monthsplit:'',
                                      yearsplit:''
                                    },
                                    expired:false,
                                  branch:docdata.data().branch||'',
                                  //  connectedUserLength:docdata.data().connectedUser.length
                                })
                              }  
                            }
                          })
                          self.senderArray.forEach(data=>{
                            var answered = false
                            data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                              
                            data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                            data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                            data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                            if(data.type=="TextPoll" || data.type=="ImagePoll" )
                            {
                              db
                                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                                .doc(chatObjectId)
                                .collection(self.currCollection)
                                .doc(data.defaultid)
                                .collection('answer')
                                .get().then(querySnapshot=>{
                                  if(!querySnapshot.empty)
                                  {
                                    querySnapshot.forEach(doc=>{
                                      if(doc.id==self.userid)
                                      {
                                        answered=true
                                      }
                                    })
                                  }
                                  if(answered)
                                  {
                                    data.answered=true
                                  }
                                  else
                                  {
                                    data.answered=false
                                  }
                                  if(querySnapshot.size==index)
                                  {
                                    
                                  }
                                })
                                .catch(error=>{console.log(error)})
                            }
                              if(data.messageType=='receiver'){
                        var seen = false
                        var connected  = false
                        var userId = firebase.auth().currentUser.uid

                        self.connectedUsers.forEach(uids=>{
                          if(uids==userId)
                          {
                            connected = true
                          }
                        })
                        data.messageSeen.forEach((val)=>{
                          // //console.log('shradddggggghhhahahahh',data.messageSeen)
                          if(val.id == userId)
                          {
                            seen=true
                          }
                        })
                        if(!seen && connected)
                        {
                          data.messageSeen.push({
                            id:self.userid,
                            name:self.userData.firstName,
                            avtar:self.userData.profileUrl,
                            messageSeenTime:new Date(),
                          })
                          db
                            .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                            .doc(self.chatObjectId)
                            .collection(self.currCollection)
                            .doc(data.defaultid)
                            .update({
                              messageSeen: data.messageSeen
                              // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                              //   id:userId,
                              //   name:self.userData.firstName,
                              //   avtar:self.userData.profileUrl,
                              //   messageSeenTime:new Date(),
                              // }),
                            })
                            .catch(error=>{
                              console.log(error)
                            })
                        }
                      }
                            //console.log('self.senderArray', self.sendreceArray)
                          })
                          // self.sendreceArray = self.sendreceArray.concat(self.senderArray)
                        var ids = new Set(self.sendreceArray.map(d => d.createdAt.seconds));
                          var merged = [...self.sendreceArray, ...self.senderArray.filter(d => !ids.has(d.createdAt.seconds))];
                          self.sendreceArray = merged
                          
                          self.sendreceArray.sort((book1, book2) => {
                            return self.compareObjects(book1, book2, 'createdAt')
                          })
                          self.sendreceArrayBKP=self.sendreceArray

                          // if(self.searchType=='All')
                          // {
                          //   self.sendreceArrayBKP=self.sendreceArray
                          // }
                        })
                    }
                  }
                }else{
                  var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

                  const db = firebase.firestore()

                  // self.unreadMessages = 0

                  // var joinDate = 0
                  // self.connectedUsers.forEach(val=>{
                  //   if(val == firebase.auth().currentUser.uid)
                  //   {
                  //     joinDate=val.createdAt
                  //   }
                  // })

                    if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                    {
                      // self.senderArray = [];
                      // self.senderchatId ='',
                      // //console.log('self.lastVisibleself.lastVisible',self.lastVisible)
                      // SENDER AND RECEIVER ARRAY GET(Shraddha)
                        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                        .doc(self.chatObjectId)
                        .collection('default')
                        .orderBy('createdAt','desc')
                        .startAfter(self.lastVisible)
                        .limit(15)
                        .get().then((snap) => {
                          self.senderArray = [];
                          snap.forEach((docdata) => {
                            //  self.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                          self.lastVisible = docdata
                          //  //console.log("last",self.lastVisible);
                            if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid){
                              if(self.userid == docdata.data().from.id){
                                self.senderArray.push({
                                messageType: 'sender',
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                sendermsg :docdata.data().msg,
                                createdAt : docdata.data().createdAt || new Date(),
                                Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:self.chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                  },
                                  expired:false,
                                  branch:docdata.data().branch||'',
                                // connectedUserLength:docdata.data().connectedUser.length
                              })
                              }else{
                                self.senderArray.push({
                                  messageType: 'receiver',
                                  receivermsg :docdata.data().msg,
                                  mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                  Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : '',
                                  createdAt:docdata.data().createdAt || new Date(),
                                  senderName : docdata.data().from.name,
                                  type:docdata.data().type,
                                  name:docdata.data().name,
                                  msg:docdata.data().msg,
                                  question:docdata.data().questions,
                                  polldata:docdata.data().uploadPollData,
                                  messageSeen:docdata.data().messageSeen,
                                  defaultid:docdata.data().id,
                                  chatId:self.chatObjectId,
                                  answered:false,
                                  expireyTimeObjects:{
                                    date:'',
                                    month:'',
                                    year:'',
                                    hrs:'',
                                    min:''
                                  },
                                  beforeDate:{
                                      Datesplit:'',
                                      monthsplit:'',
                                      yearsplit:''
                                    },
                                    expired:false,
                                    branch:docdata.data().branch||'',
                                  //  connectedUserLength:docdata.data().connectedUser.length
                                })
                              }  
                            }
                          })
                          self.senderArray.forEach(data=>{
                            var answered = false
                            data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                              
                            data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                            data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                            data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                            if(data.type=="TextPoll" || data.type=="ImagePoll" )
                            {
                              db
                                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                                .doc(self.chatObjectId)
                                .collection('default')
                                .doc(data.defaultid)
                                .collection('answer')
                                .get().then(querySnapshot=>{
                                  if(!querySnapshot.empty)
                                  {
                                    querySnapshot.forEach(doc=>{
                                      if(doc.id==self.userid)
                                      {
                                        answered=true
                                      }
                                    })
                                  }
                                  if(answered)
                                  {
                                    data.answered=true
                                  }
                                  else
                                  {
                                    data.answered=false
                                  }
                                  // if(querySnapshot.size==index)
                                  // {
                                    
                                  // }
                                })
                                .catch(error=>{console.log(error)})
                            }
                            if(data.messageType=='receiver'){
                        var seen = false
                        var connected  = false
                        var userId = firebase.auth().currentUser.uid

                        self.connectedUsers.forEach(uids=>{
                          if(uids==userId)
                          {
                            connected = true
                          }
                        })
                        data.messageSeen.forEach((val)=>{
                          // //console.log('shradddggggghhhahahahh',data.messageSeen)
                          if(val.id == userId)
                          {
                            seen=true
                          }
                        })
                        if(!seen && connected)
                        {
                          data.messageSeen.push({
                            id:self.userid,
                            name:self.userData.firstName,
                            avtar:self.userData.profileUrl,
                            messageSeenTime:new Date(),
                          })
                          db
                            .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                            .doc(self.chatObjectId)
                            .collection('default')
                            .doc(data.defaultid)
                            .update({
                              messageSeen: data.messageSeen
                              // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                              //   id:userId,
                              //   name:self.userData.firstName,
                              //   avtar:self.userData.profileUrl,
                              //   messageSeenTime:new Date(),
                              // }),
                            })
                            .catch(error=>{
                              console.log(error)
                            })
                        }
                      }
                            //console.log('self.senderArray', self.sendreceArray)
                          })
                      
                        //  self.sendreceArray = self.sendreceArray.concat(self.senderArray) 
                          var ids = new Set(self.sendreceArray.map(d => d.createdAt.seconds));
                          var merged = [...self.sendreceArray, ...self.senderArray.filter(d => !ids.has(d.createdAt.seconds))];
                          self.sendreceArray = merged
                          self.sendreceArray.sort((book1, book2) => {
                            return self.compareObjects(book1, book2, 'createdAt')
                          })
                          
                              self.sendreceArrayBKP=self.sendreceArray
                            

                          // if(self.searchType=='All')
                          // {
                          //   self.$root.$emit('searchByTypeValue',self.searchType)
                          // }
                        })
                    }
                    else if(self.mainBranchActive || self.subBranchActive || self.subSubBranchActive)
                    {
                      // self.senderArray = [];
                      // self.senderchatId ='',
                      // //console.log('self.lastVisibleself.lastVisible',self.lastVisible)
                    // SENDER AND RECEIVER ARRAY GET(Shraddha)
                        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                        .doc(self.chatObjectId)
                        .collection(self.currCollection)
                        .orderBy('createdAt','desc')
                        .startAfter(self.lastVisible)
                        .limit(15)
                        .get().then((snap) => {
                          self.senderArray = [];
                          snap.forEach((docdata) => {
                            //  self.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                          self.lastVisible = docdata
                          //  //console.log("last",self.lastVisible);
                            if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid){
                              if(self.userid == docdata.data().from.id){
                                self.senderArray.push({
                                messageType: 'sender',
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                sendermsg :docdata.data().msg,
                                createdAt : docdata.data().createdAt || new Date(),
                                Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,

                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:self.chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                  },
                                  expired:false,
                                  branch:docdata.data().branch||'',
                                // connectedUserLength:docdata.data().connectedUser.length
                              })
                              }else{
                                self.senderArray.push({
                                  messageType: 'receiver',
                                  mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                  receivermsg :docdata.data().msg,
                                  Time :docdata.data().createdAt ? new Date(docdata.data().createdAt.seconds * 1000).toLocaleTimeString().replace(/:\d+ /, ' ') : '',
                                  createdAt:docdata.data().createdAt || new Date(),
                                  senderName : docdata.data().from.name,
                                  type:docdata.data().type,
                                  name:docdata.data().name,
                                  msg:docdata.data().msg,
                                  question:docdata.data().questions,
                                  polldata:docdata.data().uploadPollData,
                                  messageSeen:docdata.data().messageSeen,
                                  defaultid:docdata.data().id,
                                  chatId:self.chatObjectId,
                                  answered:false,
                                  expireyTimeObjects:{
                                    date:'',
                                    month:'',
                                    year:'',
                                    hrs:'',
                                    min:''
                                  },
                                  beforeDate:{
                                      Datesplit:'',
                                      monthsplit:'',
                                      yearsplit:''
                                    },
                                    expired:false,
                                  branch:docdata.data().branch||'',
                                  //  connectedUserLength:docdata.data().connectedUser.length
                                })
                              }  
                            }
                          })
                          self.senderArray.forEach(data=>{
                            var answered = false
                            data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                              
                            data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                            data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                            data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                            if(data.type=="TextPoll" || data.type=="ImagePoll" )
                            {
                              db
                                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                                .doc(chatObjectId)
                                .collection(self.currCollection)
                                .doc(data.defaultid)
                                .collection('answer')
                                .get().then(querySnapshot=>{
                                  if(!querySnapshot.empty)
                                  {
                                    querySnapshot.forEach(doc=>{
                                      if(doc.id==self.userid)
                                      {
                                        answered=true
                                      }
                                    })
                                  }
                                  if(answered)
                                  {
                                    data.answered=true
                                  }
                                  else
                                  {
                                    data.answered=false
                                  }
                                  if(querySnapshot.size==index)
                                  {
                                    
                                  }
                                })
                                .catch(error=>{console.log(error)})
                            }
                              if(data.messageType=='receiver'){
                        var seen = false
                        var connected  = false
                        var userId = firebase.auth().currentUser.uid

                        self.connectedUsers.forEach(uids=>{
                          if(uids==userId)
                          {
                            connected = true
                          }
                        })
                        data.messageSeen.forEach((val)=>{
                          // //console.log('shradddggggghhhahahahh',data.messageSeen)
                          if(val.id == userId)
                          {
                            seen=true
                          }
                        })
                        if(!seen && connected)
                        {
                          data.messageSeen.push({
                            id:self.userid,
                            name:self.userData.firstName,
                            avtar:self.userData.profileUrl,
                            messageSeenTime:new Date(),
                          })
                          db
                            .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                            .doc(self.chatObjectId)
                            .collection(self.currCollection)
                            .doc(data.defaultid)
                            .update({
                              messageSeen: data.messageSeen
                              // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                              //   id:userId,
                              //   name:self.userData.firstName,
                              //   avtar:self.userData.profileUrl,
                              //   messageSeenTime:new Date(),
                              // }),
                            })
                            .catch(error=>{
                              console.log(error)
                            })
                        }
                      }
                            //console.log('self.senderArray', self.sendreceArray)
                          })
                          // self.sendreceArray = self.sendreceArray.concat(self.senderArray)
                          var ids = new Set(self.sendreceArray.map(d => d.createdAt.seconds));
                          var merged = [...self.sendreceArray, ...self.senderArray.filter(d => !ids.has(d.createdAt.seconds))];
                          self.sendreceArray = merged
                          self.sendreceArray.sort((book1, book2) => {
                            return self.compareObjects(book1, book2, 'createdAt')
                          })

                          
                            self.sendreceArrayBKP=self.sendreceArray
                          
                        })
                    }
                }
              }else{
                if(self.messageHistory != 'unlimited'){
                  if(self.senderArray.length < Number(self.messageHistory)){

                  var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

                  const db = firebase.firestore()

                  // self.unreadMessages = 0

                  // var joinDate = 0
                  // self.connectedUsers.forEach(val=>{
                  //   if(val == firebase.auth().currentUser.uid)
                  //   {
                  //     joinDate=val.createdAt
                  //   }
                  // })

                    if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                    {
                      // self.senderArray = [];
                      // self.senderchatId ='',
                      // //console.log('self.lastVisibleself.lastVisible',self.lastVisible)
                      // SENDER AND RECEIVER ARRAY GET(Shraddha)
                        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                        .doc(self.chatObjectId)
                        .collection('default')
                        .orderBy('createdAt','desc')
                        .startAfter(self.lastVisible)
                        // .limit(15)
                        .get().then((snap) => {
                          self.senderArray = [];
                          snap.forEach((docdata) => {
                            //  self.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                          self.lastVisible = docdata
                          //  //console.log("last",self.lastVisible);
                            if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid && docdata.data().msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                              if(self.userid == docdata.data().from.id){
                                self.senderArray.push({
                                messageType: 'sender',
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                sendermsg :docdata.data().msg,
                                createdAt : docdata.data().createdAt || new Date(),
                                Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:self.chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                  },
                                  expired:false,
                                  branch:docdata.data().branch||'',
                                // connectedUserLength:docdata.data().connectedUser.length
                              })
                              }else{
                                self.senderArray.push({
                                  messageType: 'receiver',
                                  receivermsg :docdata.data().msg,
                                  mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                  Time:docdata.data().createdAt ? (new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours()+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : '',
                                  createdAt:docdata.data().createdAt || new Date(),
                                  senderName : docdata.data().from.name,
                                  type:docdata.data().type,
                                  name:docdata.data().name,
                                  msg:docdata.data().msg,
                                  question:docdata.data().questions,
                                  polldata:docdata.data().uploadPollData,
                                  messageSeen:docdata.data().messageSeen,
                                  defaultid:docdata.data().id,
                                  chatId:self.chatObjectId,
                                  answered:false,
                                  expireyTimeObjects:{
                                    date:'',
                                    month:'',
                                    year:'',
                                    hrs:'',
                                    min:''
                                  },
                                  beforeDate:{
                                      Datesplit:'',
                                      monthsplit:'',
                                      yearsplit:''
                                    },
                                    expired:false,
                                    branch:docdata.data().branch||'',
                                  //  connectedUserLength:docdata.data().connectedUser.length
                                })
                              }  
                            }
                          })
                          self.senderArray.forEach(data=>{
                            var answered = false
                            data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                              
                            data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                            data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                            data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                            if(data.type=="TextPoll" || data.type=="ImagePoll" )
                            {
                              db
                                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                                .doc(self.chatObjectId)
                                .collection('default')
                                .doc(data.defaultid)
                                .collection('answer')
                                .get().then(querySnapshot=>{
                                  if(!querySnapshot.empty)
                                  {
                                    querySnapshot.forEach(doc=>{
                                      if(doc.id==self.userid)
                                      {
                                        answered=true
                                      }
                                    })
                                  }
                                  if(answered)
                                  {
                                    data.answered=true
                                  }
                                  else
                                  {
                                    data.answered=false
                                  }
                                  // if(querySnapshot.size==index)
                                  // {
                                    
                                  // }
                                })
                                .catch(error=>{console.log(error)})
                            }
                            if(data.messageType=='receiver'){
                        var seen = false
                        var connected  = false
                        var userId = firebase.auth().currentUser.uid

                        self.connectedUsers.forEach(uids=>{
                          if(uids==userId)
                          {
                            connected = true
                          }
                        })
                        data.messageSeen.forEach((val)=>{
                          // //console.log('shradddggggghhhahahahh',data.messageSeen)
                          if(val.id == userId)
                          {
                            seen=true
                          }
                        })
                        if(!seen && connected)
                        {
                          data.messageSeen.push({
                            id:self.userid,
                            name:self.userData.firstName,
                            avtar:self.userData.profileUrl,
                            messageSeenTime:new Date(),
                          })
                          db
                            .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                            .doc(self.chatObjectId)
                            .collection('default')
                            .doc(data.defaultid)
                            .update({
                              messageSeen: data.messageSeen
                              // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                              //   id:userId,
                              //   name:self.userData.firstName,
                              //   avtar:self.userData.profileUrl,
                              //   messageSeenTime:new Date(),
                              // }),
                            })
                            .catch(error=>{
                              console.log(error)
                            })
                        }
                      }
                            //console.log('self.senderArray', self.sendreceArray)
                          })
                          // self.sendreceArray = self.sendreceArray.concat(self.senderArray)
                          var ids = new Set(self.sendreceArray.map(d => d.createdAt.seconds));
                          var merged = [...self.sendreceArray, ...self.senderArray.filter(d => !ids.has(d.createdAt.seconds))];
                          self.sendreceArray = merged
                          // debugger // eslint-disable-line
                          self.sendreceArray.sort((book1, book2) => {
                            return self.compareObjects(book1, book2, 'createdAt')
                          })
                          self.sendreceArrayBKP=self.sendreceArray

                        
                          // if(self.Mainsearchkeyword == 'All')
                          // {
                            
                          // }
                        })
                    }
                    else if(self.mainBranchActive || self.subBranchActive || self.subSubBranchActive)
                    {
                      // self.senderArray = [];
                      // self.senderchatId ='',
                      // //console.log('self.lastVisibleself.lastVisible',self.lastVisible)
                    // SENDER AND RECEIVER ARRAY GET(Shraddha)
                        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                        .doc(self.chatObjectId)
                        .collection(self.currCollection)
                        .orderBy('createdAt','desc')
                        .startAfter(self.lastVisible)
                        // .limit(15)
                        .get().then((snap) => {
                          self.senderArray = [];
                          snap.forEach((docdata) => {
                            //  self.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                          self.lastVisible = docdata
                          //  //console.log("last",self.lastVisible);
                            if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid && docdata.data().msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                              if(self.userid == docdata.data().from.id){
                                self.senderArray.push({
                                messageType: 'sender',
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                sendermsg :docdata.data().msg,
                                createdAt : docdata.data().createdAt || new Date(),
                                Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:self.chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                  },
                                  expired:false,
                                  branch:docdata.data().branch||'',
                                // connectedUserLength:docdata.data().connectedUser.length
                              })
                              }else{
                                self.senderArray.push({
                                  messageType: 'receiver',
                                  mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                  receivermsg :docdata.data().msg,
                                  Time :docdata.data().createdAt ?  new Date(docdata.data().createdAt.seconds * 1000).toLocaleTimeString().replace(/:\d+ /, ' ') : '',
                                  createdAt:docdata.data().createdAt || new Date(),
                                  senderName : docdata.data().from.name,
                                  type:docdata.data().type,
                                  name:docdata.data().name,
                                  msg:docdata.data().msg,
                                  question:docdata.data().questions,
                                  polldata:docdata.data().uploadPollData,
                                  messageSeen:docdata.data().messageSeen,
                                  defaultid:docdata.data().id,
                                  chatId:self.chatObjectId,
                                  answered:false,
                                  expireyTimeObjects:{
                                    date:'',
                                    month:'',
                                    year:'',
                                    hrs:'',
                                    min:''
                                  },
                                  beforeDate:{
                                      Datesplit:'',
                                      monthsplit:'',
                                      yearsplit:''
                                    },
                                    expired:false,
                                  branch:docdata.data().branch||'',
                                  //  connectedUserLength:docdata.data().connectedUser.length
                                })
                              }  
                            }
                          })
                          self.senderArray.forEach(data=>{
                            var answered = false
                            data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                              
                            data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                            data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                            data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                            if(data.type=="TextPoll" || data.type=="ImagePoll" )
                            {
                              db
                                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                                .doc(chatObjectId)
                                .collection(self.currCollection)
                                .doc(data.defaultid)
                                .collection('answer')
                                .get().then(querySnapshot=>{
                                  if(!querySnapshot.empty)
                                  {
                                    querySnapshot.forEach(doc=>{
                                      if(doc.id==self.userid)
                                      {
                                        answered=true
                                      }
                                    })
                                  }
                                  if(answered)
                                  {
                                    data.answered=true
                                  }
                                  else
                                  {
                                    data.answered=false
                                  }
                                  if(querySnapshot.size==index)
                                  {
                                    
                                  }
                                })
                                .catch(error=>{console.log(error)})
                            }
                              if(data.messageType=='receiver'){
                        var seen = false
                        var connected  = false
                        var userId = firebase.auth().currentUser.uid

                        self.connectedUsers.forEach(uids=>{
                          if(uids==userId)
                          {
                            connected = true
                          }
                        })
                        data.messageSeen.forEach((val)=>{
                          // //console.log('shradddggggghhhahahahh',data.messageSeen)
                          if(val.id == userId)
                          {
                            seen=true
                          }
                        })
                        if(!seen && connected)
                        {
                          data.messageSeen.push({
                            id:self.userid,
                            name:self.userData.firstName,
                            avtar:self.userData.profileUrl,
                            messageSeenTime:new Date(),
                          })
                          db
                            .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                            .doc(self.chatObjectId)
                            .collection(self.currCollection)
                            .doc(data.defaultid)
                            .update({
                              messageSeen: data.messageSeen
                              // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                              //   id:userId,
                              //   name:self.userData.firstName,
                              //   avtar:self.userData.profileUrl,
                              //   messageSeenTime:new Date(),
                              // }),
                            })
                            .catch(error=>{
                              console.log(error)
                            })
                        }
                      }
                            //console.log('self.senderArray', self.sendreceArray)
                          })
                          // self.sendreceArray = self.sendreceArray.concat(self.senderArray)
                        var ids = new Set(self.sendreceArray.map(d => d.createdAt.seconds));
                          var merged = [...self.sendreceArray, ...self.senderArray.filter(d => !ids.has(d.createdAt.seconds))];
                          self.sendreceArray = merged
                          
                          self.sendreceArray.sort((book1, book2) => {
                            return self.compareObjects(book1, book2, 'createdAt')
                          })
                          self.sendreceArrayBKP=self.sendreceArray

                          // if(self.searchType=='All')
                          // {
                          //   self.sendreceArrayBKP=self.sendreceArray
                          // }
                        })
                    }
                  }
                }else{
                  var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

                  const db = firebase.firestore()

                  // self.unreadMessages = 0

                  // var joinDate = 0
                  // self.connectedUsers.forEach(val=>{
                  //   if(val == firebase.auth().currentUser.uid)
                  //   {
                  //     joinDate=val.createdAt
                  //   }
                  // })

                    if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                    {
                      // self.senderArray = [];
                      // self.senderchatId ='',
                      // //console.log('self.lastVisibleself.lastVisible',self.lastVisible)
                      // SENDER AND RECEIVER ARRAY GET(Shraddha)
                        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                        .doc(self.chatObjectId)
                        .collection('default')
                        .orderBy('createdAt','desc')
                        .startAfter(self.lastVisible)
                        // .limit(15)
                        .get().then((snap) => {
                          self.senderArray = [];
                          snap.forEach((docdata) => {
                            //  self.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                          self.lastVisible = docdata
                          //  //console.log("last",self.lastVisible);
                            if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid && docdata.data().msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                              if(self.userid == docdata.data().from.id){
                                self.senderArray.push({
                                messageType: 'sender',
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                sendermsg :docdata.data().msg,
                                createdAt : docdata.data().createdAt || new Date(),
                                Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:self.chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                  },
                                  expired:false,
                                  branch:docdata.data().branch||'',
                                // connectedUserLength:docdata.data().connectedUser.length
                              })
                              }else{
                                self.senderArray.push({
                                  messageType: 'receiver',
                                  receivermsg :docdata.data().msg,
                                  mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                  Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : '',
                                  createdAt:docdata.data().createdAt || new Date(),
                                  senderName : docdata.data().from.name,
                                  type:docdata.data().type,
                                  name:docdata.data().name,
                                  msg:docdata.data().msg,
                                  question:docdata.data().questions,
                                  polldata:docdata.data().uploadPollData,
                                  messageSeen:docdata.data().messageSeen,
                                  defaultid:docdata.data().id,
                                  chatId:self.chatObjectId,
                                  answered:false,
                                  expireyTimeObjects:{
                                    date:'',
                                    month:'',
                                    year:'',
                                    hrs:'',
                                    min:''
                                  },
                                  beforeDate:{
                                      Datesplit:'',
                                      monthsplit:'',
                                      yearsplit:''
                                    },
                                    expired:false,
                                    branch:docdata.data().branch||'',
                                  //  connectedUserLength:docdata.data().connectedUser.length
                                })
                              }  
                            }
                          })
                          self.senderArray.forEach(data=>{
                            var answered = false
                            data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                              
                            data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                            data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                            data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                            if(data.type=="TextPoll" || data.type=="ImagePoll" )
                            {
                              db
                                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                                .doc(self.chatObjectId)
                                .collection('default')
                                .doc(data.defaultid)
                                .collection('answer')
                                .get().then(querySnapshot=>{
                                  if(!querySnapshot.empty)
                                  {
                                    querySnapshot.forEach(doc=>{
                                      if(doc.id==self.userid)
                                      {
                                        answered=true
                                      }
                                    })
                                  }
                                  if(answered)
                                  {
                                    data.answered=true
                                  }
                                  else
                                  {
                                    data.answered=false
                                  }
                                  // if(querySnapshot.size==index)
                                  // {
                                    
                                  // }
                                })
                                .catch(error=>{console.log(error)})
                            }
                            if(data.messageType=='receiver'){
                        var seen = false
                        var connected  = false
                        var userId = firebase.auth().currentUser.uid

                        self.connectedUsers.forEach(uids=>{
                          if(uids==userId)
                          {
                            connected = true
                          }
                        })
                        data.messageSeen.forEach((val)=>{
                          // //console.log('shradddggggghhhahahahh',data.messageSeen)
                          if(val.id == userId)
                          {
                            seen=true
                          }
                        })
                        if(!seen && connected)
                        {
                          data.messageSeen.push({
                            id:self.userid,
                            name:self.userData.firstName,
                            avtar:self.userData.profileUrl,
                            messageSeenTime:new Date(),
                          })
                          db
                            .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                            .doc(self.chatObjectId)
                            .collection('default')
                            .doc(data.defaultid)
                            .update({
                              messageSeen: data.messageSeen
                              // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                              //   id:userId,
                              //   name:self.userData.firstName,
                              //   avtar:self.userData.profileUrl,
                              //   messageSeenTime:new Date(),
                              // }),
                            })
                            .catch(error=>{
                              console.log(error)
                            })
                        }
                      }
                            //console.log('self.senderArray', self.sendreceArray)
                          })
                      
                        //  self.sendreceArray = self.sendreceArray.concat(self.senderArray) 
                          var ids = new Set(self.sendreceArray.map(d => d.createdAt.seconds));
                          var merged = [...self.sendreceArray, ...self.senderArray.filter(d => !ids.has(d.createdAt.seconds))];
                          self.sendreceArray = merged
                          self.sendreceArray.sort((book1, book2) => {
                            return self.compareObjects(book1, book2, 'createdAt')
                          })
                          
                              self.sendreceArrayBKP=self.sendreceArray
                            

                          // if(self.searchType=='All')
                          // {
                          //   self.$root.$emit('searchByTypeValue',self.searchType)
                          // }
                        })
                    }
                    else if(self.mainBranchActive || self.subBranchActive || self.subSubBranchActive)
                    {
                      // self.senderArray = [];
                      // self.senderchatId ='',
                      // //console.log('self.lastVisibleself.lastVisible',self.lastVisible)
                    // SENDER AND RECEIVER ARRAY GET(Shraddha)
                        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                        .doc(self.chatObjectId)
                        .collection(self.currCollection)
                        .orderBy('createdAt','desc')
                        .startAfter(self.lastVisible)
                        // .limit(15)
                        .get().then((snap) => {
                          self.senderArray = [];
                          snap.forEach((docdata) => {
                            //  self.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                          self.lastVisible = docdata
                          //  //console.log("last",self.lastVisible);
                            if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid && docdata.data().msg.toLowerCase().includes(self.searchkeyword.toLowerCase())){
                              if(self.userid == docdata.data().from.id){
                                self.senderArray.push({
                                messageType: 'sender',
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                sendermsg :docdata.data().msg,
                                createdAt : docdata.data().createdAt || new Date(),
                                Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:self.chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                  },
                                  expired:false,
                                  branch:docdata.data().branch||'',
                                // connectedUserLength:docdata.data().connectedUser.length
                              })
                              }else{
                                self.senderArray.push({
                                  messageType: 'receiver',
                                  mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                  receivermsg :docdata.data().msg,
                                  Time : docdata.data().createdAt ? new Date(docdata.data().createdAt.seconds * 1000).toLocaleTimeString().replace(/:\d+ /, ' ') : '',
                                  createdAt:docdata.data().createdAt || new Date(),
                                  senderName : docdata.data().from.name,
                                  type:docdata.data().type,
                                  name:docdata.data().name,
                                  msg:docdata.data().msg,
                                  question:docdata.data().questions,
                                  polldata:docdata.data().uploadPollData,
                                  messageSeen:docdata.data().messageSeen,
                                  defaultid:docdata.data().id,
                                  chatId:self.chatObjectId,
                                  answered:false,
                                  expireyTimeObjects:{
                                    date:'',
                                    month:'',
                                    year:'',
                                    hrs:'',
                                    min:''
                                  },
                                  beforeDate:{
                                      Datesplit:'',
                                      monthsplit:'',
                                      yearsplit:''
                                    },
                                    expired:false,
                                  branch:docdata.data().branch||'',
                                  //  connectedUserLength:docdata.data().connectedUser.length
                                })
                              }  
                            }
                          })
                          self.senderArray.forEach(data=>{
                            var answered = false
                            data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                              
                            data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                            data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                            data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                            if(data.type=="TextPoll" || data.type=="ImagePoll" )
                            {
                              db
                                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                                .doc(chatObjectId)
                                .collection(self.currCollection)
                                .doc(data.defaultid)
                                .collection('answer')
                                .get().then(querySnapshot=>{
                                  if(!querySnapshot.empty)
                                  {
                                    querySnapshot.forEach(doc=>{
                                      if(doc.id==self.userid)
                                      {
                                        answered=true
                                      }
                                    })
                                  }
                                  if(answered)
                                  {
                                    data.answered=true
                                  }
                                  else
                                  {
                                    data.answered=false
                                  }
                                  if(querySnapshot.size==index)
                                  {
                                    
                                  }
                                })
                                .catch(error=>{console.log(error)})
                            }
                              if(data.messageType=='receiver'){
                        var seen = false
                        var connected  = false
                        var userId = firebase.auth().currentUser.uid

                        self.connectedUsers.forEach(uids=>{
                          if(uids==userId)
                          {
                            connected = true
                          }
                        })
                        data.messageSeen.forEach((val)=>{
                          // //console.log('shradddggggghhhahahahh',data.messageSeen)
                          if(val.id == userId)
                          {
                            seen=true
                          }
                        })
                        if(!seen && connected)
                        {
                          data.messageSeen.push({
                            id:self.userid,
                            name:self.userData.firstName,
                            avtar:self.userData.profileUrl,
                            messageSeenTime:new Date(),
                          })
                          db
                            .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                            .doc(self.chatObjectId)
                            .collection(self.currCollection)
                            .doc(data.defaultid)
                            .update({
                              messageSeen: data.messageSeen
                              // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                              //   id:userId,
                              //   name:self.userData.firstName,
                              //   avtar:self.userData.profileUrl,
                              //   messageSeenTime:new Date(),
                              // }),
                            })
                            .catch(error=>{
                              console.log(error)
                            })
                        }
                      }
                            //console.log('self.senderArray', self.sendreceArray)
                          })
                          // self.sendreceArray = self.sendreceArray.concat(self.senderArray)
                          var ids = new Set(self.sendreceArray.map(d => d.createdAt.seconds));
                          var merged = [...self.sendreceArray, ...self.senderArray.filter(d => !ids.has(d.createdAt.seconds))];
                          self.sendreceArray = merged
                          self.sendreceArray.sort((book1, book2) => {
                            return self.compareObjects(book1, book2, 'createdAt')
                          })

                          
                            self.sendreceArrayBKP=self.sendreceArray
                          
                        })
                    }
                }
              }
            }else{
              var DataSearch = [];
                if(self.searchbaroption == 'Links'){
                  DataSearch = ['Link']
                }
                else if(self.searchbaroption == 'Photos'){
                  DataSearch = ['Image']
                }
                else if(self.searchbaroption == 'Videos'){
                  DataSearch = ['Video']
                }
                else if(self.searchbaroption == 'Documents'){
                  DataSearch = ['pdf','excel','word','odt','ods','plain','xd','ppt','html']
                }
                // console.log('self.searchTypeShraddha',self.searchbaroption,DataSearch);
                if(self.messageHistory != 'unlimited'){
                if(self.senderArray.length < Number(self.messageHistory)){

                var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

                const db = firebase.firestore()

                // self.unreadMessages = 0

                // var joinDate = 0
                // self.connectedUsers.forEach(val=>{
                //   if(val == firebase.auth().currentUser.uid)
                //   {
                //     joinDate=val.createdAt
                //   }
                // })

                  if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                  {
                    // self.senderArray = [];
                    // self.senderchatId ='',
                    // //console.log('self.lastVisibleself.lastVisible',self.lastVisible)
                    // SENDER AND RECEIVER ARRAY GET(Shraddha)
                      db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                      .doc(self.chatObjectId)
                      .collection('default')
                      .orderBy('createdAt','desc')
                      .startAfter(self.lastVisible)
                      .where('type','in',DataSearch)
                      .limit(15)
                      .get().then((snap) => {
                        self.senderArray = [];
                        snap.forEach((docdata) => {
                          //  self.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                        self.lastVisible = docdata
                        //  //console.log("last",self.lastVisible);
                          if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid){
                            if(self.userid == docdata.data().from.id){
                              self.senderArray.push({
                              messageType: 'sender',
                              mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                              sendermsg :docdata.data().msg,
                              createdAt : docdata.data().createdAt || new Date(),
                              Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                              senderName : docdata.data().from.name,
                              type:docdata.data().type,
                              name:docdata.data().name,
                              msg:docdata.data().msg,
                              question:docdata.data().questions,
                              polldata:docdata.data().uploadPollData,
                              messageSeen:docdata.data().messageSeen,
                              defaultid:docdata.data().id,
                              chatId:self.chatObjectId,
                              answered:false,
                              expireyTimeObjects:{
                                date:'',
                                month:'',
                                year:'',
                                hrs:'',
                                min:''
                              },
                              beforeDate:{
                                  Datesplit:'',
                                  monthsplit:'',
                                  yearsplit:''
                                },
                                expired:false,
                                branch:docdata.data().branch||'',
                              // connectedUserLength:docdata.data().connectedUser.length
                            })
                            }else{
                              self.senderArray.push({
                                messageType: 'receiver',
                                receivermsg :docdata.data().msg,
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : '',
                                createdAt:docdata.data().createdAt || new Date(),
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:self.chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                  },
                                  expired:false,
                                  branch:docdata.data().branch||'',
                                //  connectedUserLength:docdata.data().connectedUser.length
                              })
                            }  
                          }
                        })
                        self.senderArray.forEach(data=>{
                          var answered = false
                          data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                            
                          data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                          data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                          data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                          if(data.type=="TextPoll" || data.type=="ImagePoll" )
                          {
                            db
                              .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                              .doc(self.chatObjectId)
                              .collection('default')
                              .doc(data.defaultid)
                              .collection('answer')
                              .get().then(querySnapshot=>{
                                if(!querySnapshot.empty)
                                {
                                  querySnapshot.forEach(doc=>{
                                    if(doc.id==self.userid)
                                    {
                                      answered=true
                                    }
                                  })
                                }
                                if(answered)
                                {
                                  data.answered=true
                                }
                                else
                                {
                                  data.answered=false
                                }
                                // if(querySnapshot.size==index)
                                // {
                                  
                                // }
                              })
                              .catch(error=>{console.log(error)})
                          }
                          if(data.messageType=='receiver'){
                      var seen = false
                      var connected  = false
                      var userId = firebase.auth().currentUser.uid

                      self.connectedUsers.forEach(uids=>{
                        if(uids==userId)
                        {
                          connected = true
                        }
                      })
                      data.messageSeen.forEach((val)=>{
                        // //console.log('shradddggggghhhahahahh',data.messageSeen)
                        if(val.id == userId)
                        {
                          seen=true
                        }
                      })
                      if(!seen && connected)
                      {
                        data.messageSeen.push({
                          id:self.userid,
                          name:self.userData.firstName,
                          avtar:self.userData.profileUrl,
                          messageSeenTime:new Date(),
                        })
                        db
                          .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                          .doc(self.chatObjectId)
                          .collection('default')
                          .doc(data.defaultid)
                          .update({
                            messageSeen: data.messageSeen
                            // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                            //   id:userId,
                            //   name:self.userData.firstName,
                            //   avtar:self.userData.profileUrl,
                            //   messageSeenTime:new Date(),
                            // }),
                          })
                          .catch(error=>{
                            console.log(error)
                          })
                      }
                    }
                          //console.log('self.senderArray', self.sendreceArray)
                        })
                        self.sendreceArray = self.sendreceArray.concat(self.senderArray)
                        // debugger // eslint-disable-line
                        self.sendreceArrayBKP=self.sendreceArray
                        self.sendreceArray.sort((book1, book2) => {
                          return self.compareObjects(book1, book2, 'createdAt')
                        })
                      
                        // if(self.Mainsearchkeyword == 'All')
                        // {
                          
                        // }
                      })
                  }
                  else if(self.mainBranchActive || self.subBranchActive || self.subSubBranchActive)
                  {
                    // self.senderArray = [];
                    // self.senderchatId ='',
                    // //console.log('self.lastVisibleself.lastVisible',self.lastVisible)
                  // SENDER AND RECEIVER ARRAY GET(Shraddha)
                      db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                      .doc(self.chatObjectId)
                      .collection(self.currCollection)
                      .orderBy('createdAt','desc')
                      .startAfter(self.lastVisible)
                      .where('type','in',DataSearch)
                      .limit(15)
                      .get().then((snap) => {
                        self.senderArray = [];
                        snap.forEach((docdata) => {
                          //  self.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                        self.lastVisible = docdata
                        //  //console.log("last",self.lastVisible);
                          if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid){
                            if(self.userid == docdata.data().from.id){
                              self.senderArray.push({
                              messageType: 'sender',
                              mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                              sendermsg :docdata.data().msg,
                              createdAt : docdata.data().createdAt || new Date(),
                              Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                              senderName : docdata.data().from.name,
                              type:docdata.data().type,
                              name:docdata.data().name,
                              msg:docdata.data().msg,
                              question:docdata.data().questions,
                              polldata:docdata.data().uploadPollData,
                              messageSeen:docdata.data().messageSeen,
                              defaultid:docdata.data().id,
                              chatId:self.chatObjectId,
                              answered:false,
                              expireyTimeObjects:{
                                date:'',
                                month:'',
                                year:'',
                                hrs:'',
                                min:''
                              },
                              beforeDate:{
                                  Datesplit:'',
                                  monthsplit:'',
                                  yearsplit:''
                                },
                                expired:false,
                                branch:docdata.data().branch||'',
                              // connectedUserLength:docdata.data().connectedUser.length
                            })
                            }else{
                              self.senderArray.push({
                                messageType: 'receiver',
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                receivermsg :docdata.data().msg,
                                Time : docdata.data().createdAt ? new Date(docdata.data().createdAt.seconds * 1000).toLocaleTimeString().replace(/:\d+ /, ' ') : '',
                                createdAt:docdata.data().createdAt || new Date(),
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:self.chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                  },
                                  expired:false,
                                branch:docdata.data().branch||'',
                                //  connectedUserLength:docdata.data().connectedUser.length
                              })
                            }  
                          }
                        })
                        self.senderArray.forEach(data=>{
                          var answered = false
                          data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                            
                          data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                          data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                          data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                          if(data.type=="TextPoll" || data.type=="ImagePoll" )
                          {
                            db
                              .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                              .doc(chatObjectId)
                              .collection(self.currCollection)
                              .doc(data.defaultid)
                              .collection('answer')
                              .get().then(querySnapshot=>{
                                if(!querySnapshot.empty)
                                {
                                  querySnapshot.forEach(doc=>{
                                    if(doc.id==self.userid)
                                    {
                                      answered=true
                                    }
                                  })
                                }
                                if(answered)
                                {
                                  data.answered=true
                                }
                                else
                                {
                                  data.answered=false
                                }
                                if(querySnapshot.size==index)
                                {
                                  
                                }
                              })
                              .catch(error=>{console.log(error)})
                          }
                            if(data.messageType=='receiver'){
                      var seen = false
                      var connected  = false
                      var userId = firebase.auth().currentUser.uid

                      self.connectedUsers.forEach(uids=>{
                        if(uids==userId)
                        {
                          connected = true
                        }
                      })
                      data.messageSeen.forEach((val)=>{
                        // //console.log('shradddggggghhhahahahh',data.messageSeen)
                        if(val.id == userId)
                        {
                          seen=true
                        }
                      })
                      if(!seen && connected)
                      {
                        data.messageSeen.push({
                          id:self.userid,
                          name:self.userData.firstName,
                          avtar:self.userData.profileUrl,
                          messageSeenTime:new Date(),
                        })
                        db
                          .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                          .doc(self.chatObjectId)
                          .collection(self.currCollection)
                          .doc(data.defaultid)
                          .update({
                            messageSeen: data.messageSeen
                            // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                            //   id:userId,
                            //   name:self.userData.firstName,
                            //   avtar:self.userData.profileUrl,
                            //   messageSeenTime:new Date(),
                            // }),
                          })
                          .catch(error=>{
                            console.log(error)
                          })
                      }
                    }
                          //console.log('self.senderArray', self.sendreceArray)
                        })
                        self.sendreceArray = self.sendreceArray.concat(self.senderArray)
                        self.sendreceArrayBKP=self.sendreceArray
                      
                        
                        self.sendreceArray.sort((book1, book2) => {
                          return self.compareObjects(book1, book2, 'createdAt')
                        })
                        // if(self.searchType=='All')
                        // {
                        //   self.sendreceArrayBKP=self.sendreceArray
                        // }
                      })
                  }
                }
                }else{
                  var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

                const db = firebase.firestore()

                // self.unreadMessages = 0

                // var joinDate = 0
                // self.connectedUsers.forEach(val=>{
                //   if(val == firebase.auth().currentUser.uid)
                //   {
                //     joinDate=val.createdAt
                //   }
                // })

                  if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
                  {
                    // self.senderArray = [];
                    // self.senderchatId ='',
                    // //console.log('self.lastVisibleself.lastVisible',self.lastVisible)
                    // SENDER AND RECEIVER ARRAY GET(Shraddha)
                      db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                      .doc(self.chatObjectId)
                      .collection('default')
                      .orderBy('createdAt','desc')
                      .startAfter(self.lastVisible)
                      .where('type','in',DataSearch)
                      .limit(15)
                      .get().then((snap) => {
                        self.senderArray = [];
                        snap.forEach((docdata) => {
                          //  self.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                        self.lastVisible = docdata
                        //  //console.log("last",self.lastVisible);
                          if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid){
                            if(self.userid == docdata.data().from.id){
                              self.senderArray.push({
                              messageType: 'sender',
                              mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                              sendermsg :docdata.data().msg,
                              createdAt : docdata.data().createdAt || new Date(),
                              Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                              senderName : docdata.data().from.name,
                              type:docdata.data().type,
                              name:docdata.data().name,
                              msg:docdata.data().msg,
                              question:docdata.data().questions,
                              polldata:docdata.data().uploadPollData,
                              messageSeen:docdata.data().messageSeen,
                              defaultid:docdata.data().id,
                              chatId:self.chatObjectId,
                              answered:false,
                              expireyTimeObjects:{
                                date:'',
                                month:'',
                                year:'',
                                hrs:'',
                                min:''
                              },
                              beforeDate:{
                                  Datesplit:'',
                                  monthsplit:'',
                                  yearsplit:''
                                },
                                expired:false,
                                branch:docdata.data().branch||'',
                              // connectedUserLength:docdata.data().connectedUser.length
                            })
                            }else{
                              self.senderArray.push({
                                messageType: 'receiver',
                                receivermsg :docdata.data().msg,
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : '',
                                createdAt:docdata.data().createdAt || new Date(),
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:self.chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                  },
                                  expired:false,
                                  branch:docdata.data().branch||'',
                                //  connectedUserLength:docdata.data().connectedUser.length
                              })
                            }  
                          }
                        })
                        self.senderArray.forEach(data=>{
                          var answered = false
                          data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                            
                          data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                          data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                          data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                          if(data.type=="TextPoll" || data.type=="ImagePoll" )
                          {
                            db
                              .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                              .doc(self.chatObjectId)
                              .collection('default')
                              .doc(data.defaultid)
                              .collection('answer')
                              .get().then(querySnapshot=>{
                                if(!querySnapshot.empty)
                                {
                                  querySnapshot.forEach(doc=>{
                                    if(doc.id==self.userid)
                                    {
                                      answered=true
                                    }
                                  })
                                }
                                if(answered)
                                {
                                  data.answered=true
                                }
                                else
                                {
                                  data.answered=false
                                }
                                // if(querySnapshot.size==index)
                                // {
                                  
                                // }
                              })
                              .catch(error=>{console.log(error)})
                          }
                          if(data.messageType=='receiver'){
                      var seen = false
                      var connected  = false
                      var userId = firebase.auth().currentUser.uid

                      self.connectedUsers.forEach(uids=>{
                        if(uids==userId)
                        {
                          connected = true
                        }
                      })
                      data.messageSeen.forEach((val)=>{
                        // //console.log('shradddggggghhhahahahh',data.messageSeen)
                        if(val.id == userId)
                        {
                          seen=true
                        }
                      })
                      if(!seen && connected)
                      {
                        data.messageSeen.push({
                          id:self.userid,
                          name:self.userData.firstName,
                          avtar:self.userData.profileUrl,
                          messageSeenTime:new Date(),
                        })
                        db
                          .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                          .doc(self.chatObjectId)
                          .collection('default')
                          .doc(data.defaultid)
                          .update({
                            messageSeen: data.messageSeen
                            // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                            //   id:userId,
                            //   name:self.userData.firstName,
                            //   avtar:self.userData.profileUrl,
                            //   messageSeenTime:new Date(),
                            // }),
                          })
                          .catch(error=>{
                            console.log(error)
                          })
                      }
                    }
                          //console.log('self.senderArray', self.sendreceArray)
                        })
                        self.sendreceArray = self.sendreceArray.concat(self.senderArray)
                        self.sendreceArrayBKP=self.sendreceArray
                        

                        self.sendreceArray.sort((book1, book2) => {
                          return self.compareObjects(book1, book2, 'createdAt')
                        })
                        // if(self.searchType=='All')
                        // {
                        //   self.$root.$emit('searchByTypeValue',self.searchType)
                        // }
                      })
                  }
                  else if(self.mainBranchActive || self.subBranchActive || self.subSubBranchActive)
                  {
                    // self.senderArray = [];
                    // self.senderchatId ='',
                    // //console.log('self.lastVisibleself.lastVisible',self.lastVisible)
                  // SENDER AND RECEIVER ARRAY GET(Shraddha)
                      db.collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                      .doc(self.chatObjectId)
                      .collection(self.currCollection)
                      .orderBy('createdAt','desc')
                      .startAfter(self.lastVisible)
                      .where('type','in',DataSearch)
                      .limit(15)
                      .get().then((snap) => {
                        self.senderArray = [];
                        snap.forEach((docdata) => {
                          //  self.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                        self.lastVisible = docdata
                        //  //console.log("last",self.lastVisible);
                          if(docdata.exists && docdata.data().blockBy != firebase.auth().currentUser.uid){
                            if(self.userid == docdata.data().from.id){
                              self.senderArray.push({
                              messageType: 'sender',
                              mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                              sendermsg :docdata.data().msg,
                              createdAt : docdata.data().createdAt || new Date(),
                              Time:docdata.data().createdAt ? ((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())) : ((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())) ,
                              senderName : docdata.data().from.name,
                              type:docdata.data().type,
                              name:docdata.data().name,
                              msg:docdata.data().msg,
                              question:docdata.data().questions,
                              polldata:docdata.data().uploadPollData,
                              messageSeen:docdata.data().messageSeen,
                              defaultid:docdata.data().id,
                              chatId:self.chatObjectId,
                              answered:false,
                              expireyTimeObjects:{
                                date:'',
                                month:'',
                                year:'',
                                hrs:'',
                                min:''
                              },
                              beforeDate:{
                                  Datesplit:'',
                                  monthsplit:'',
                                  yearsplit:''
                                },
                                expired:false,
                                branch:docdata.data().branch||'',
                              // connectedUserLength:docdata.data().connectedUser.length
                            })
                            }else{
                              self.senderArray.push({
                                messageType: 'receiver',
                                mediaUrl : docdata.data().mediaUrl ? docdata.data().mediaUrl : docdata.data().msg,
                                receivermsg :docdata.data().msg,
                                Time :docdata.data().createdAt ?  new Date(docdata.data().createdAt.seconds * 1000).toLocaleTimeString().replace(/:\d+ /, ' ') : '',
                                createdAt:docdata.data().createdAt || new Date(),
                                senderName : docdata.data().from.name,
                                type:docdata.data().type,
                                name:docdata.data().name,
                                msg:docdata.data().msg,
                                question:docdata.data().questions,
                                polldata:docdata.data().uploadPollData,
                                messageSeen:docdata.data().messageSeen,
                                defaultid:docdata.data().id,
                                chatId:self.chatObjectId,
                                answered:false,
                                expireyTimeObjects:{
                                  date:'',
                                  month:'',
                                  year:'',
                                  hrs:'',
                                  min:''
                                },
                                beforeDate:{
                                    Datesplit:'',
                                    monthsplit:'',
                                    yearsplit:''
                                  },
                                  expired:false,
                                branch:docdata.data().branch||'',
                                //  connectedUserLength:docdata.data().connectedUser.length
                              })
                            }  
                          }
                        })
                        self.senderArray.forEach(data=>{
                          var answered = false
                          data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
                            
                          data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
                          data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
                          data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())
                          if(data.type=="TextPoll" || data.type=="ImagePoll" )
                          {
                            db
                              .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                              .doc(chatObjectId)
                              .collection(self.currCollection)
                              .doc(data.defaultid)
                              .collection('answer')
                              .get().then(querySnapshot=>{
                                if(!querySnapshot.empty)
                                {
                                  querySnapshot.forEach(doc=>{
                                    if(doc.id==self.userid)
                                    {
                                      answered=true
                                    }
                                  })
                                }
                                if(answered)
                                {
                                  data.answered=true
                                }
                                else
                                {
                                  data.answered=false
                                }
                                if(querySnapshot.size==index)
                                {
                                  
                                }
                              })
                              .catch(error=>{console.log(error)})
                          }
                            if(data.messageType=='receiver'){
                      var seen = false
                      var connected  = false
                      var userId = firebase.auth().currentUser.uid

                      self.connectedUsers.forEach(uids=>{
                        if(uids==userId)
                        {
                          connected = true
                        }
                      })
                      data.messageSeen.forEach((val)=>{
                        // //console.log('shradddggggghhhahahahh',data.messageSeen)
                        if(val.id == userId)
                        {
                          seen=true
                        }
                      })
                      if(!seen && connected)
                      {
                        data.messageSeen.push({
                          id:self.userid,
                          name:self.userData.firstName,
                          avtar:self.userData.profileUrl,
                          messageSeenTime:new Date(),
                        })
                        db
                          .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                          .doc(self.chatObjectId)
                          .collection(self.currCollection)
                          .doc(data.defaultid)
                          .update({
                            messageSeen: data.messageSeen
                            // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                            //   id:userId,
                            //   name:self.userData.firstName,
                            //   avtar:self.userData.profileUrl,
                            //   messageSeenTime:new Date(),
                            // }),
                          })
                          .catch(error=>{
                            console.log(error)
                          })
                      }
                    }
                          //console.log('self.senderArray', self.sendreceArray)
                        })
                        self.sendreceArray = self.sendreceArray.concat(self.senderArray)
                        self.sendreceArrayBKP=self.sendreceArray
                        self.sendreceArray.sort((book1, book2) => {
                          return self.compareObjects(book1, book2, 'createdAt')
                        })
                        // if(self.searchType=='All')
                        // {
                        //   self.sendreceArrayBKP=self.sendreceArray
                        // }
                      })
                  }
              }
            }
          },
          openDropdown(){
            document.getElementById("myDropdown").classList.toggle("show");
            window.onclick = function(event) {
              if (!event.target.matches('.dropbtn')) {
                var dropdowns = document.getElementsByClassName("dropdown-content");
                var i;
                for (i = 0; i < dropdowns.length; i++) {
                  var openDropdown = dropdowns[i];
                  if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                  }
                }
              }
            }
          },
          //FOR ZOOM IMAGE(Shraddha)
          imageZoom(imagetime){
            // //debugger // eslint-disable-line
            var self = this;
            self.imageTimeForZoom = imagetime;
            //console.log('self.imageTimeForZoom',self.imageTimeForZoom)
          },
          //FOR DOWNLOAD DOCUMENT(Shraddha)
          downloadDocument(url,name){
            var naming = name.split('.');
            this.$axios({
                  url: url,
                  method: 'GET',
                  responseType: 'blob',
              }).then((response) => {
                  var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                  var fileLink = document.createElement('a');
                  fileLink.href = fileURL;
                  fileLink.setAttribute('download',name);
                  document.body.appendChild(fileLink);
                  fileLink.click(); 
              })
              .catch(error=>{
                this.$root.$emit("audioplay");
                  this.$notify({
                      title:"Error",
                      text:error.message,
                      type:"error"
                  })
              })
          },
          //SEND BULK MESSAGE(Shraddha)
          BulkData(event,msg){
            // //debugger // eslint-disable-line
            var self=this;
            self.bulkDataArray = []
            self.BulkMessage = ''
            self.bulkDataArray = event
            self.BulkMessage = msg
            //console.log('self.bulkDataArray',self.bulkDataArray)
            self.sendMessage();
          },
          //SELECT OPTION(Shraddha)
          selectOption(option,defaultid,chatId){
            // //debugger // eslint-disable-line
            //console.log(option,defaultid,chatId)
            var self=this;
            if(!self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
            {
            updateAnswerCollection(firebaseData.firebasecollection.SOCIALMESSAGE,'default',chatId,defaultid,self.userid,option,res=>{
              //console.log(res)
              selectPollOption(firebaseData.firebasecollection.SOCIALMESSAGE,'default',chatId,defaultid,res=>{
                  self.uploadData = res.uploadPollData 
                  self.pollquestions = res.questions 
                  var index =  self.uploadData.findIndex(item => {
                    return item.text == option  
                  })
                  //console.log(' self.uploadData', self.uploadData,index)

                self.pollquestions.totalCount = self.pollquestions.totalCount + 1
                self.uploadData[index].count  =  self.uploadData[index].count + 1

                //console.log(' self.uploadData self.uploadData self.uploadData', self.uploadData)
                updatePollAnswer(firebaseData.firebasecollection.SOCIALMESSAGE,'default',chatId,defaultid,self.uploadData,self.pollquestions,res=>{
                  //console.log(res)
                    self.getChatArray('default',chatId);
                  })
                })
              })
              // db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatId).collection('default').doc(defaultid).get().then((doc)=>{
              //   self.uploadData = doc.data().uploadPollData;
              //   self.pollquestions = doc.data().questions

              //   var index =  self.uploadData.findIndex(item => {
              //       return item.text == option  
              //   })
              //   //console.log(' self.uploadData', self.uploadData,index)
              //   self.pollquestions.totalCount = self.pollquestions.totalCount + 1
              //   self.uploadData[index].count  =  self.uploadData[index].count + 1
              //   //console.log(' self.uploadData self.uploadData self.uploadData', self.uploadData)
              // }).then(() => {
                // db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatId).collection('default').doc(defaultid).update({
                //   uploadPollData: self.uploadData,
                //   questions:self.pollquestions
                // }).then(()=>{
                    // db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatId).collection('default').doc(defaultid).collection('answer').doc(self.userid).get().then(doc =>{
                    //   if(!doc.exists){
                    //     db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatId).collection('default').doc(defaultid).collection('answer').doc(self.userid).set({
                    //       ans:option,
                    //       chatId:defaultid
                    //     })
                    //   }
                    // }).then(() =>{
                    //   // self.getPollData(chatId);
                    //   self.getChatArray('default',chatId);
                    // })
                // })
              // })
            }
            else if(self.mainBranchActive && !self.subBranchActive && !self.subSubBranchActive)
            {
              updateAnswerCollection(firebaseData.firebasecollection.SOCIALMESSAGE,self.mainBranches[self.mainBranchActiveIndex].name,chatId,defaultid,self.userid,option,res=>{
              //console.log(res)
              selectPollOption(firebaseData.firebasecollection.SOCIALMESSAGE,self.mainBranches[self.mainBranchActiveIndex].name,chatId,defaultid,res=>{
                  self.uploadData = res.uploadPollData 
                  self.pollquestions = res.questions 
                  var index =  self.uploadData.findIndex(item => {
                    return item.text == option  
                  })
                  //console.log(' self.uploadData', self.uploadData,index)

                self.pollquestions.totalCount = self.pollquestions.totalCount + 1
                self.uploadData[index].count  =  self.uploadData[index].count + 1

                //console.log(' self.uploadData self.uploadData self.uploadData', self.uploadData)
                updatePollAnswer(firebaseData.firebasecollection.SOCIALMESSAGE,self.mainBranches[self.mainBranchActiveIndex].name,chatId,defaultid,self.uploadData,self.pollquestions,res=>{
                  //console.log(res)
                  self.getChatArray(self.mainBranches[self.mainBranchActiveIndex].name,chatId);
                  })
                })
              })
            }
            else if(self.mainBranchActive && self.subBranchActive && !self.subSubBranchActive)
            {
              updateAnswerCollection(firebaseData.firebasecollection.SOCIALMESSAGE,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name,chatId,defaultid,self.userid,option,res=>{
              //console.log(res)
              selectPollOption(firebaseData.firebasecollection.SOCIALMESSAGE,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name,chatId,defaultid,res=>{
                  self.uploadData = res.uploadPollData 
                  self.pollquestions = res.questions 
                  var index =  self.uploadData.findIndex(item => {
                    return item.text == option  
                  })
                  //console.log(' self.uploadData', self.uploadData,index)

                self.pollquestions.totalCount = self.pollquestions.totalCount + 1
                self.uploadData[index].count  =  self.uploadData[index].count + 1

                //console.log(' self.uploadData self.uploadData self.uploadData', self.uploadData)
                updatePollAnswer(firebaseData.firebasecollection.SOCIALMESSAGE,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name,chatId,defaultid,self.uploadData,self.pollquestions,res=>{
                  //console.log(res)
                  self.getChatArray(self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].name,chatId);
                  })
                })
              })
            }
            else if(self.mainBranchActive && self.subBranchActive && self.subSubBranchActive)
            {
              updateAnswerCollection(firebaseData.firebasecollection.SOCIALMESSAGE,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,chatId,defaultid,self.userid,option,res=>{
              //console.log(res)
              selectPollOption(firebaseData.firebasecollection.SOCIALMESSAGE,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,chatId,defaultid,res=>{
                  self.uploadData = res.uploadPollData 
                  self.pollquestions = res.questions 
                  var index =  self.uploadData.findIndex(item => {
                    return item.text == option  
                  })
                  //console.log(' self.uploadData', self.uploadData,index)

                self.pollquestions.totalCount = self.pollquestions.totalCount + 1
                self.uploadData[index].count  =  self.uploadData[index].count + 1

                //console.log(' self.uploadData self.uploadData self.uploadData', self.uploadData)
                updatePollAnswer(firebaseData.firebasecollection.SOCIALMESSAGE,self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,chatId,defaultid,self.uploadData,self.pollquestions,res=>{
                  //console.log(res)
                  self.getChatArray(self.mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].subSubBranches[self.subSubBranchActiveIndex].name,chatId);
                  })
                })
              })
            }
          },
          // SEARCH IN PERTICULAR CHAT(Shraddha)
          updatesearch(event,value){
            // debugger // eslint-disable-line
            var self = this;
            self.searchbaroption = value
            self.searchkeyword = event
            self.getChatArray(self.currCollection,self.chatObjectId)
          },
          //COPY MESSAGE(Shraddha)
          copymessage(copymessage){
            // //debugger // eslint-disable-line
            var copyText = copymessage  
            var input = document.createElement("textarea");
            input.value = copyText;
            document.body.appendChild(input);
            input.select();
            document.execCommand("Copy");
            input.remove();
          },
          //READ RECIPT (Shraddha)
          readrecipt(sendermsg){
            // //debugger // eslint-disable-line
            var self = this;
            //console.log(sendermsg)
            self.$root.$emit('openSidebarForReadRecipt',sendermsg);

          },

          // BUSINESSSIDE///////
          //FOR ACTIVE SOCIAL MENU(Shraddha)
          SocialMenuActiveTab(){
            // debugger // eslint-disable-line
            var self=this;
            self.activeChat = false
            self.businessMenuActive = false
            self.$root.$emit("socialMenuActive", self.businessMenuActive,self.activeChat)
            self.selectedActiveMenu = 'activeList'
            self.chatObjectId=""
            self.sendreceArrayBKP=[]
            self.sendreceArray=[]
            self.senderArray=[]
            self.setStoreChatId('')
          },
          // FOR ACTIVEBUSINESS MENU
          businessMenuActiveTab(){
            // debugger // eslint-disable-line
            var self=this;
          
            self.showLoader = true
            // console.log(self.userData)
            self.businessMenuActive = true;
            self.activeChat = false
            self.$root.$emit("businessMenuActive", self.businessMenuActive,self.activeChat)
            self.chatObjectId=""
            self.sendreceArrayBKP=[]
            self.sendreceArray=[]
            self.senderArray=[]
            self.setStoreChatId('')
            db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).update({
                userRole : 'Business'
            })
            if(self.userData.companies != undefined && self.userData.companies.length > 0){
              self.selectedActiveMenu = 'admin'
              self.showLoader = false
              self.$router.push('/businesschat')
            }else{
              self.$root.$emit("activeBusinessTab","admin");
              self.showLoader = false
              self.$router.push('/generalsettings/Subscription_Details')
            }
          },
          //MESSAGE DELETE (Shraddha)
          messageDelete(chatData){
            // debugger // eslint-disable-line
            var self = this;
            if(chatData.type == "SurpriseSelfieText") {
                db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatData.chatId).collection(self.currCollection).doc(chatData.defaultid).get().then(docData => {
                  if(docData.data().surpriseSelfieUsers.length > 1){
                    const Toast = Swal.mixin({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                    });
                    Swal.fire({
                        title: 'Are you sure you want to delete message?',
                        type: "warning",
                        customClass:{
                            container :"social_confirmation_popup"
                        },
                        showCancelButton: true,
                        confirmButtonColor: "#ffab3f",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                        if (result.value) {
                          
                          // console.log('chatData',chatData);
                          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatData.chatId).collection(self.currCollection).doc(chatData.defaultid).delete().then(() => {
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Success",
                                text:"Message delete successfully.",
                                type:"success"
                              })
                          }) 
                        }
                    })
                  }else{
                    const Toast = Swal.mixin({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                    });
                    Swal.fire({
                        title: 'Are you sure you want to delete message?',
                        type: "warning",
                        customClass:{
                            container :"social_confirmation_popup"
                        },
                        showCancelButton: true,
                        confirmButtonColor: "#ffab3f",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                        if (result.value) {
                          var self = this;
                          // console.log('chatData',chatData);
                          db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatData.chatId).collection(self.currCollection).doc(chatData.defaultid).delete().then(() => {
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Success",
                                text:"Message delete successfully.",
                                type:"success"
                              })
                    db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialPlan').doc(firebase.auth().currentUser.uid).get().then(docs=>{
                      // console.log('firebase.auth().currentUser.uid',firebase.auth().currentUser.uid)
                      var userData = docs.data();
                      if(userData.usedSelfie > 0){
                        userData.usedSelfie--;
                      }
                      var txt = "";
                        userData.selfieUsers.forEach((data,index) => {
                          for (let x in data) {
                            if(x == self.chatObjectId){
                              txt = index;
                              
                            }
                          }
                        })
                        // console.log(txt)
                        userData.selfieUsers.splice(txt,1)
                        db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialPlan').doc(firebase.auth().currentUser.uid).update({
                          usedSelfie : userData.usedSelfie,
                          selfieUsers : userData.selfieUsers
                        })
                      })

                    
                          }) 
                        }
                    })
                  }
                })
              }else{
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Swal.fire({
                  title: 'Are you sure you want to delete message?',
                  type: "warning",
                  customClass:{
                      container :"social_confirmation_popup"
                  },
                  showCancelButton: true,
                  confirmButtonColor: "#ffab3f",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                  if (result.value) {
                    var self = this;
                    // console.log('chatData',chatData);
                    db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatData.chatId).collection(self.currCollection).doc(chatData.defaultid).delete().then(() => {
                      self.$root.$emit("audioplay");
                      self.$notify({
                          title:"Success",
                          text:"Message delete successfully.",
                          type:"success"
                        })
                    }) 
                  }
              })
            }
          },
          // Delete Branch (Common Code) - Shiv
          async handleDeleteBranch(mainbranch, BranchName, type){
            var self = this;
            self.selectDropdown=false;
            self.selectSubDropdown=false;
            self.selectSubSubDropdown=false;
            self.selectMainBranchActiveDropdown=false;
            self.selectSubBranchActiveDropdown=false;
            self.selectSubSubBranchActiveDropdown=false;
            const docId = self.chatObjectId;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });

            let newActiveBranch = BranchName

            Swal.fire({
                title: `Are you sure you want to delete ${BranchName} branch?`,
                type: "warning",
                customClass:{
                    container :"social_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: "#ffab3f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    let mainBranches = mainbranch;

                    let level = "";
                    let deleteArr = [];

                    mainBranches.forEach((mb,mbIndex)=>{
                        if(mb.name === BranchName) {
                            if(self.mainBranchActive && self.mainBranchActiveIndex === mbIndex){
                              newActiveBranch = "default";
                              self.mainBranchActive = false;
                              self.mainBranchActiveIndex = 0;
                              self.subBranchActive = false;
                              self.subBranchActiveIndex = 0;
                              self.subSubBranchActive = false;
                              self.subSubBranchActiveIndex = 0;
                            }

                            level = "mb";
                            deleteArr = mb.subBranches;

                            mainBranches.splice(mbIndex,1);
                        } else {
                            mb.subBranches.forEach((sb,sbIndex)=>{
                                if(sb.name === BranchName) {
                                    if(self.subBranchActive && self.subBranchActiveIndex === sbIndex){
                                      newActiveBranch = mb.name;
                                      self.subBranchActive = false;
                                      self.subBranchActiveIndex = 0;
                                      self.subSubBranchActive = false;
                                      self.subSubBranchActiveIndex = 0;
                                    }
                                    level = "sb";
                                    deleteArr = sb.subSubBranches;

                                    mb.subBranches.splice(sbIndex,1);
                                } else {
                                    sb.subSubBranches.forEach((ssb,ssbIndex)=>{
                                        if(ssb.name === BranchName) {
                                            if(self.subSubBranchActive && self.subSubBranchActiveIndex === ssbIndex){
                                              newActiveBranch = sb.name;
                                              self.subSubBranchActive = false;
                                              self.subSubBranchActiveIndex = 0;
                                            }
                                            level = "ssb";
                                            deleteArr = [];

                                            sb.subSubBranches.splice(ssbIndex,1);
                                        }
                                    })
                                }
                            })
                        }
                    })

                    db
                        .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                        .doc(docId)
                        .update({
                            mainBranches: mainBranches
                        })
                        .then(()=>{
                            if(self.currCollection === BranchName) {
                                self.getChatArray(newActiveBranch,docId);
                            }

                            if(level === "mb") {
                                if(deleteArr.length) {
                                    self.deleteChat(BranchName,docId);
                                    deleteArr.forEach(vals=>{
                                        self.deleteChat(vals.name,docId);

                                        if(vals.subSubBranches.length) {
                                            vals.subSubBranches.forEach(ssb=>{
                                                self.deleteChat(ssb.name,docId);
                                            })
                                        }
                                    })
                                }
                            } else if(level === "sb") {
                                if(deleteArr.length) {
                                    self.deleteChat(BranchName,docId);
                                    deleteArr.forEach(vals=>{
                                        self.deleteChat(vals.name,docId);
                                    })
                                }
                            } else if(level === "ssb") {
                                self.deleteChat(BranchName,docId);
                            }

                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Success",
                                text:"Branch delete successfully.",
                                type:"success"
                            });
                        })
                        .catch(error=>{
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title: "Error",
                                text: error.message,
                                type: "error"
                            });
                        })
                }
            })
          },
          // Delete chat of deleted branch (Common Code) - Shiv
          deleteChat(BranchName,docId) {
            var self = this;
            db
                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                .doc(docId)
                .collection(BranchName)
                .get()
                .then(querySnapshot=>{
                    // Check if any message in the branch.
                    if(!querySnapshot.empty){
                        // Delete messages if found.
                        querySnapshot.forEach(doc=>{
                            db
                                .collection(firebaseData.firebasecollection.SOCIALMESSAGE)
                                .doc(docId)
                                .collection(BranchName)
                                .doc(doc.id)
                                .delete()
                        })

                    }

                    // Change selfie count.
                    self.SelfieBranchDelete(BranchName);
                })
                .catch(error=>{
                    self.$root.$emit("audioplay");
                    self.$notify({
                        title: "Error",
                        text: error.message,
                        type: "error"
                    });
                })
          },
          // Change selfie count
          SelfieBranchDelete(BranchName){
            // debugger // eslint-disable-line
            var self = this;
            // console.log('BranchName',BranchName)
            if(self.chatObject.id != undefined){
              var splitArray = [];
              var splitArrays = [];
              self.socialPlanData.selfieUsers.forEach(data => {
                for (let x in data) {
                  if(x != self.chatObjectId && data[x] != BranchName){
                    splitArray.push(data)
                  }
                }
              })
              db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialPlan').doc(firebase.auth().currentUser.uid).update({
                selfieUsers : splitArray,
                usedSelfie:self.socialPlanData.usedSelfie -1
              })
              // console.log('self.chatObject.id',self.chatObject.id)
              db.collection(firebaseData.firebasecollection.USER).doc(self.chatObject.id).collection('socialPlan').doc(self.chatObject.id).get().then(social => {
              var socialPlan = social.data()
              socialPlan.selfieUsers.forEach(data => {
                for (let x in data) {
                  if(x != self.chatObjectId && data[x] != BranchName){
                    splitArrays.push(data)
                  }
                }
              })
              db.collection(firebaseData.firebasecollection.USER).doc(self.chatObject.id).collection('socialPlan').doc(self.chatObject.id).update({
                selfieUsers : splitArrays,
                usedSelfie:socialPlan.usedSelfie -1
              })
            })
            }
          },

          setCountToZero(chatId) {
            var self = this;

            // if(self.unreadMessages === 0) {
              // console.log("NO NEW MESSAGES!");
              // return;
            // }

            self.sendreceArray.forEach((data)=>{
              if(Object.keys(self.storeChatData).length) {
                if(data.messageSeen.length < self.storeChatData.connectedUser.length && data.defaultid !== "" && data.defaultid !== undefined) {
                  self.markMessageRead(data);
                }
              }
            })

            let collectionName = self.currCollection;
            let mainBranches = self.storeChatData.mainBranches;
            let branchCount = self.storeChatData.branchCount || {};

            // let query = db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(self.chatObjectId);

            // if(mainBranches.length && !Object.keys(branchCount).length) {
            //   self.addCountObj(collectionName, mainBranches, branchCount);
            //   return;
            // }

            if(collectionName === "default" && chatId && collectionName) {
              self.changeDefaultMessageCount(chatId,collectionName);
            } else if(collectionName !== "default" && chatId && collectionName) {
              self.changeMessageCount(chatId,collectionName);
              // self.updateCountObject(collectionName, mainBranches, 0);
            }
          }
        },
        mounted() {
          var self = this;

          // clearInterval(self.messageInterval);
          self.messageInterval = setInterval(() => {
            if(self.messageCount > 0) {
              self.updateCount(self.lastMessageData,self.messageCount);
            }
          },1 * 500) //sec * mls

          window.addEventListener("focus",(e)=>{
            self.sendreceArray.forEach((data)=>{
              if(Object.keys(self.storeChatData).length) {
                if(data.messageSeen.length < self.storeChatData.connectedUser.length && data.defaultid !== "" && data.defaultid !== undefined) {
                  self.markMessageRead(data);
                }
              }
            })

            if(self.currCollection === "default" && self.chatObjectId && self.currCollection) {
              self.changeDefaultMessageCount(self.chatObjectId,self.currCollection);
            } else if(self.currCollection !== "default" && self.chatObjectId && self.currCollection){
              self.changeMessageCount(self.chatObjectId,self.currCollection);
            }
          })

          window.addEventListener("dragenter", function (e) {
                  document.querySelector("#dropzone").style.visibility = "";
                  document.querySelector("#dropzone").style.opacity = 1;
                  document.querySelector("#textnode").style.fontSize = "48px";
          });

          window.addEventListener("dragleave", function (e) {
              e.preventDefault();

                  document.querySelector("#dropzone").style.visibility = "hidden";
                  document.querySelector("#dropzone").style.opacity = 0;
                  document.querySelector("#textnode").style.fontSize = "42px";
              
          });

          window.addEventListener("dragover", function (e) {
              e.preventDefault();
              document.querySelector("#dropzone").style.visibility = "";
              document.querySelector("#dropzone").style.opacity = 1;
              document.querySelector("#textnode").style.fontSize = "48px";
          });

          window.addEventListener("drop", function (e) {
              e.preventDefault();
              document.querySelector("#dropzone").style.visibility = "hidden";
              document.querySelector("#dropzone").style.opacity = 0;
              document.querySelector("#textnode").style.fontSize = "42px";
              
            var files = e.dataTransfer.files;
              // console.log("Drop files:", files,e);
              
              //this.uploadFile(files);
              self.uploadFiles(files,e);
            });
        },
        computed:
        {
          ...mapGetters({chatmessages:"getChatMessages"}),
          ...mapGetters({storeChatData:"getChatData"}),
          ...mapGetters({storeChatId:"getChatId"}),
          ...mapGetters({newMessageInSocial:"getNewMessageInSocial"}),
          ...mapGetters({newMessageInBusiness:"getNewMessageInBusiness"}),
          ...mapGetters({socialIncomingRequests:"getSocialIncomingRequests"}),
        }
      };
    </script>
    <style>
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right: 89px;
  bottom: 89px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}

.highlight-msg{
  background-color: yellow
}

.messageNotSeen{
  background-color: #d3ffdb;
  position: relative;
  border-top-left-radius: 0;
  padding: 6px 0px;
  text-align: center;
  border-radius: 12px;
  font-weight: bold;
}
.userLeftMessage{
  margin:15px 0px;
  /* background-color: #7b7b7b; */
  /* position: relative; */
  /* border-top-left-radius: 0; */
  padding: 6px 0px;
  text-align: center;
  border-radius: 12px;
  font-weight: bold;
  color: white;
}
.userLeftMessage span{
  background-color: #7b7b7b;
  /* position: relative; */
  border-top-left-radius: 0;
  padding: 3px 10px;
  text-align: center;
  border-radius: 12px;
  font-weight: bold;
  color: white;
}

.stopRecordBtn{
  height: 45px;
  margin: 5px 5px;
  background-color: coral;
  color: white;
}

.greenDotNewMessage{
  background-color: #85DA27;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  vertical-align: middle;
  margin-left: 10px;
}
</style>