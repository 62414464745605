<template>
    <div>
        <div class="manage_notification_wrapper">
            <div class="admin_Page_title">
                <h4>Feedback</h4> 
                <img class="title_icon" @click="backtodashboard()" src="@/assets/images/home_icon.png" alt="homeIcon">
                <span class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
                <span class="current_page" @click="backtoUserList()">Feedback</span>
            </div>
            <div class="feedback_area">
                <span><b>Name:</b> {{feedBackListing.userName}}</span>
                <span><b>Email:</b> {{feedBackListing.email}}</span>
                <span><b>Phone:</b> {{feedBackListing.phone}}</span>
                <span><b>Feedback Message:</b> {{feedBackListing.feedBack}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import firebase from "../firebaseInit";
import firebaseData from '../../utils/firebaseCollection.js';
const db = firebase.firestore()
export default({
    name:'feedbackdetail',
     components: {
      firebaseData
    },
    props: [
        "rowId",
        "isFeedView"
    ],
    data(){
        return{
            feedBackListing : {}
        }
    },
    created(){
        // debugger // eslint-disable-line
        var self = this;
        console.log(self.isFeedView,self.rowId)
        db.collection(firebaseData.firebasecollection.FEEDBACK).where('docId','==',self.rowId).get().then(docs => {
            docs.forEach(doc => {
                self.feedBackListing = doc.data();
            })
        })
    },
    methods:{
        backtoUserList(){
            // debugger // eslint-disable-line
            var self = this;
            self.$emit("UpdateList",false); 
        },
        //BACK TO DASHBOARD (Shraddha)
        backtodashboard(){
            var self = this;
            self.$root.$emit('openDashboard',0);
        }
    }
    
})
</script>
