import { render, staticRenderFns } from "./businessSidebar.vue?vue&type=template&id=4ce5d43d&scoped=true&"
import script from "./businessSidebar.vue?vue&type=script&lang=js&"
export * from "./businessSidebar.vue?vue&type=script&lang=js&"
import style0 from "./businessSidebar.vue?vue&type=style&index=0&id=4ce5d43d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce5d43d",
  null
  
)

export default component.exports