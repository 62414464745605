<script>
import { Bar } from 'vue-chartjs'
export default {
    extends : Bar,
    props: {
        xAxis_arr: Array,
        yAxis_arr: Array,
    },
    created(){
        // console.log("chartInfo PROPS Line",this.yAxis_arr);
        this.$root.$on('renderCharts',()=>{
            this.renderChartFun()
        })
    },
    mounted() {
        this.renderChartFun()
    },
    methods:{
        renderChartFun()
        {
            this.renderChart({
                labels: this.xAxis_arr,
                datasets: this.yAxis_arr
            }, {responsive: true, maintainAspectRatio: false})
        }
    }
}
</script>