<template>
    <section class="setting-section Admin-settings">
        <div class="">
            <div class="row">
                <div class="col-md-12">
                    <div class="admin_Page_title">
                    <h4>Settings</h4> 
                    <img class="title_icon" @click="backtodashboard()" src="@/assets/images/home_icon.png" alt="homeIcon">
                    <span class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
                    <span class="current_page">Settings</span>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="loader_div" v-if="isSpinner">
                    <img src="@/assets/images/Orange_GIF.gif" class="lodar_gif">
                </div>
                <!-- Profile Image, change password, my profile selection tabs and logout button -->
                <div class="col-md-4">
                    <div class="setting-profile profile-email-wrapper">
                           <div class="view-detail-content">
                                <div class="photoupload-wrapper">
                                    <img  v-if="preview" :src="preview" class="img-fluid" />
                                    <img class="original" v-else :src="image" />
                                    <input type="file" accept="image/*" @change="uploadImages" class="form-control-file" id="my-file"/>
                                </div>
                                <h4>{{user.firstName}}</h4>
                                <p>Admin</p>
                                <span style="color:red;" v-if="profileErrorMessage">{{profileErrorMessage}}</span>
                            </div>
                            
                        <ul class="nav nav-tabs" id="settingTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">My Profile</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="email-tab" data-toggle="tab" href="#email" role="tab" aria-controls="email" aria-selected="false">Email Notification</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Change Password</a>

                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="tab-content" id="settingTab">
                        <!-- My Profile -->
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <h4>My Profile</h4>
                            <ValidationObserver ref="log" v-slot="{ handleSubmit }" >
                                <form class="setting-form" @submit.prevent="handleSubmit(saveUserProfileData)">
                                    <div class="row mb-5">
                                        <div class="col-md-6">
                                        
                                            <ValidationProvider rules="required|alpha_spaces|min:3" name="firstname" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <!-- <label>First Name<span>*</span></label> -->
                                                    <input type="text" class="form-control" name="firstname" @blur="firstLetterCapital()" autocomplete="off" @keypress="OnlyCharacter($event)" :maxlength="21" @keyup="checkChrecterLengthFirstname(updatedUser.firstName)" placeholder="" v-model="updatedUser.firstName">
                                                    <span class="InvalidInput">{{ errors[0]=='The first name field must be at least 3 characters'?'Minimum 3 character is required.':errors[0] }}</span>
                                                    <span v-if="charecterErrorMessageForFirstname != '' && errors.length == 0"  class="InvalidInput">{{charecterErrorMessageForFirstname}}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider rules="required|alpha_spaces|min:3" name="lastname" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <!-- <label>Last Name<span>*</span></label> -->
                                                    <input type="text" class="form-control" name="lastname" @blur="firstLetterCapital()" autocomplete="off" :maxlength="21" @keypress="OnlyCharacter($event)" @keyup="checkChrecterLengthLastname(updatedUser.lastName)" placeholder="" v-model="updatedUser.lastName">
                                                    <span class="InvalidInput">{{ errors[0]=='The last name field must be at least 3 characters'?'Minimum 3 character is required.':errors[0] }}</span>
                                                    <span v-if="charecterErrorMessageForLastname != '' && errors.length == 0"  class="InvalidInput">{{charecterErrorMessageForLastname}}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <!-- <label>Email<span>*</span></label> -->
                                                    <input type="text" class="form-control" name="email" placeholder="" v-model="updatedUser.email" disabled>
                                                    <span class="InvalidInput">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="from-btn-wrapper">
                                                <button class="btn btn-cancel btn-white" type="reset" @click="cancelProfileEdit()">Cancel</button>
                                                <!-- <b-button type="button" variant="primary" class="btn btn-save btn-yellow" disabled v-if="isSpinner">
                                                    Loading....
                                                </b-button> -->
                                                <button class="btn btn-save btn-yellow" type="submit" :class="{disabled:isSpinner||checkChangeInput}">save</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <!-- Email Notification -->
                        <div class="tab-pane fade" id="email" role="tabpanel" aria-labelledby="email-tab">
                            <h4>Email Notification</h4>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="email_toggle_wrapper">
                                        <h4>New User Sign Up</h4>
                                        <toggle-button :value="updatedUser.userSignupNotification" :sync="true" v-model="updatedUser.userSignupNotification" :labels="false" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="email_toggle_wrapper">
                                        <h4>User Upgrade Account</h4>
                                        <toggle-button :value="updatedUser.userUpgradeNotification" :sync="true" v-model="updatedUser.userUpgradeNotification" :labels="false" />
                                        </div>
                                    </div>
                                </div> <div class="row">
                                    <div class="col-md-6">
                                        <div class="email_toggle_wrapper">
                                        <h4>Support Ticket</h4>
                                        <toggle-button :value="updatedUser.userSupportTicket" :sync="true" v-model="updatedUser.userSupportTicket" :labels="false" />
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                <div class="col-md-6">
                                    <div class="from-btn-wrapper">
                                        <button class="btn btn-cancel btn-white" @click="resetNotification()" type="reset">cancel</button>
                                        <!-- <b-button type="button" variant="primary" class="btn btn-save btn-yellow" disabled v-if="isSpinner">
                                            Loading....
                                        </b-button> -->
                                        <button class="btn btn-save btn-yellow" @click="saveEmailNotification()" :class="{disabled:isSpinner||checkChangeNotificationInput}">save</button>
                                    </div>
                                </div>
                                </div>
                        </div>
                        <!-- Change password -->
                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <h4>Change password</h4>
                            <ValidationObserver ref="passwordChange" v-slot="{ handleSubmit }" @reset.prevent="cancelProfileEdit">
                                <form class="setting-form" @submit.prevent="handleSubmit(saveNewPassword)" style="position:relative;">
                                    <div class="row">
                                            <div class="col-md-6">
                                                <ValidationProvider rules="required" name="Current Password" v-slot="{ errors }">
                                                    <div class="form-group mb-5" style="position:relative;">
                                                        <label>Current Password<span>*</span></label>
                                                        <input :type="passwordType" maxlength="25" class="form-control" placeholder="Current Password*" name="CurrentPassword" v-model="currentPassword" id="password">
                                                        <img v-if="passwordType=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye">
                                                        <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye">
                                                        <span class="InvalidInput">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                    </div>
                                    <div class="row">
                                            <div class="col-md-6">
                                                <ValidationProvider rules="required" name="New Password" v-slot="{ errors }">
                                                    <div class="form-group mb-5" style="position:relative">
                                                        <label>New Password<span>*</span></label>
                                                        <input :type="passwordTypes" maxlength="25" class="form-control" placeholder="New Password" name="NewPassword" v-model="newPassword" id="confirmpassword" @keyup="validatePassword($event)" autocomplete="off">
                                                        <img v-if="passwordTypes=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPasswords();" id="togglePassword" class="password_eye">
                                                        <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPasswords();" id="togglePassword" class="password_eye">
                                                        <span class="InvalidInput">{{ errors[0] }}</span>
                                                        <span class="InvalidInput password_validation_message" v-if="passwordErrorMessage!= '' ">{{passwordErrorMessage}}</span>

                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                    </div>
                                    <div class="row">
                                            <div class="col-md-6">
                                                <ValidationProvider rules="required|confirmed:NewPassword" name="Confirm New Password" v-slot="{ errors }">
                                                    <div class="form-group" style="position:relative">
                                                        <label>Confirm New Password<span>*</span></label>
                                                        <input :type="passwordTypess" maxlength="25" class="form-control" placeholder="Enter confirm new password" name="ConfirmNewPassword" v-model="confirmNewPassword" id="confirmnewpassword">
                                                        <img v-if="passwordTypess=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPasswordss();" id="togglePassword" class="password_eye">
                                                        <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPasswordss();" id="togglePassword" class="password_eye">
                                                        <span class="InvalidInput">{{ errors[0]=='The ConfirmNewPassword field confirmation does not match'?'The password does not match':errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="from-btn-wrapper">
                                                <button class="btn btn-cancel btn-white" type="reset">cancel</button>
                                                <!-- <b-button type="button" variant="primary" class="btn btn-save btn-yellow" disabled v-if="isSpinner">
                                                    Loading....
                                                </b-button> -->
                                                <button class="btn btn-save btn-yellow" type="submit" :class="{disabled:isSpinner}">change password</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Vue from "vue";
import firebase from "../firebaseInit";
import fb from 'firebase';
import firebaseData from '../../utils/firebaseCollection.js';
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)
export default ({
   name:'settings',
    components: {
      firebaseData,
    },
    data () {
        return {
            isSpinner: false,
            active: true,
            // image: 'https://source.unsplash.com/category/nature',
            image:"",
            profileImageFile:null,
            profileImageName:"",
            imageURL:"",
            user:"",
            updatedUser:"",
            currentPassword:"",
            newPassword:"",
            confirmNewPassword:"",
            phonevalid:true,
            usersignup:false,
            upgradeaccount:false,
            supportticket:false,
            preview: "",
            passwordType:"password",
            passwordTypes:"password",
            passwordTypess:"password",
            passwordErrorMessage:'',
            charecterErrorMessageForFirstname:'',
            charecterErrorMessageForLastname:'',
            profileErrorMessage:''
        }
    },
    created()
    {
        var self = this
        self.getUserData()
    },
    methods: {
        validatePassword(evt){
    // //debugger // eslint-disable-line
    var self=this;
    //console.log(evt.target.value)
    var regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      if(regex.test(evt.target.value)){
        // //console.log('dfd',regex.test(evt.target.value))
        self.passwordErrorMessage = ''
      }else{
        if(evt.target.value != ''){
          self.passwordErrorMessage = 'Password must contains at least 8 characters, including one upper/lowercase, numbers and one special character(@#$%).';
          self.$refs.log.reset();
        }else{
          self.passwordErrorMessage = ''
        }
      }
        },
        //ENTER ONLY CHARECTER EVENT(Shraddha)
        OnlyCharacter: function (event) {
            const char = String.fromCharCode(event.keyCode)
            if (!/[a-zA-Z ]/.test(char)) {
                event.preventDefault()
            }
        },
         //CHECK GROUP NAME CHRECTER LENGTH(Shraddha)
        checkChrecterLengthFirstname(groupName){
            // debugger // eslint-disable-line
            var self = this;
            self.updatedUser.firstName=groupName.replace(/[^A-Za-z]/ig,'')

            if(groupName.length < 21){
                self.updatedUser.firstName=groupName.replace(/[^A-Za-z]/ig,'')
                self.charecterErrorMessageForFirstname = '';
            }else{
                self.charecterErrorMessageForFirstname = 'The firstname may not be greater than 20 characters';
                self.updatedUser.firstName = (groupName).slice(0, 20)
            }
        },
        checkChrecterLengthLastname(groupName){
            // debugger // eslint-disable-lines
            var self = this;
            self.updatedUser.lastName=groupName.replace(/[^A-Za-z]/ig,'')
            if(groupName.length < 21){
                self.updatedUser.lastName=groupName.replace(/[^A-Za-z]/ig,'')
                self.charecterErrorMessageForLastname = '';
            }else{
                self.charecterErrorMessageForLastname = 'The lastname may not be greater than 20 characters';
                self.updatedUser.lastName = (groupName).slice(0, 20)
            }

        },
        //Input file method
        onFileChangeNew(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.createImage(files[0]);

            //assign the image file to a variable
            this.profileImageFile=files[0]
        },
        uploadImages(event) {
        var self = this;
        self.preview = '';
        self.isSpinner = true;
        let image_id = Math.floor(Math.random() * (1000000 - 1000 + 1) + 1000);
        let name = "image_" + image_id;
        let imageData = null;
        let context = null;
        let maxWidth = 1024;
        let maxHeigt = 768;
        let width = maxWidth;
        let height = maxHeigt;
        let vm = this;
        let files = event.target.files;
        vm.imageIndex = 0;

        let formData = new FormData();
    
            let reader = new FileReader();
            let file = files[0];
            
            if (!file.type.match('image') || file.type.match('gif')){
                self.preview = self.updatedUser.profileUrl;
                self.profileErrorMessage = 'Only .jpg,.png,.jpeg files are allowed.';
                self.isSpinner = false;
            }else{
                self.isSpinner = true;
                self.profileErrorMessage = '';
                if (file) {
                    var img = document.createElement("img");
                    var canvas = $("<canvas/>").get(0);
                    reader.onload = function (e) {
                        img.src = e.target.result;

                    };
                    reader.readAsDataURL(file);
                    img.onload = function () {
                        // Draw
                        context = canvas.getContext('2d');
                        context.drawImage(img, 0, 0);
                        if (img.width > maxWidth || img.height > maxHeigt) {
                            width = maxWidth;
                            height = maxHeigt;

                            if (img.width > maxWidth) {
                                width = maxWidth;
                                var ration = maxWidth / img.width;
                                height = Math.round(img.height * ration);
                            }

                            if (img.height > maxHeigt) {
                                height = maxHeigt;
                                var ration = maxHeigt / img.height;
                                width = Math.round(img.width * ration);
                            }
                        } else {
                            width = img.width;
                            height = img.height;
                        }
                        canvas.width = width;
                        canvas.height = height;

                        context.drawImage(img, 0, 0, width, height);
                        imageData = canvas.toDataURL('image/jpeg');
                        imageData.replace('data:image/jpeg;base64,', '');

                        let blobToFile = vm.dataURItoBlob(imageData);
                        const cfile = new File([blobToFile], name + ".jpg",
                            {type: "image/jpeg", lastModified: Date.now()});
                        formData.append('ad_image', cfile);
                            self.preview  = img.src;
                            self.profileImageFile=cfile
                            self.image = cfile
                            self.profileErrorMessage = ''
                            self.isSpinner = false;
                            // console.log('previewpreviewpreview',cfile)
                    };
                }
            }
        
        },
        dataURItoBlob(dataURI) {
            // convert base64 to raw binary data held in a string
            var byteString = atob(dataURI.split(',')[1]);
            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            // write the bytes of the string to an ArrayBuffer
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var _ia = new Uint8Array(arrayBuffer);
            for (var i = 0; i < byteString.length; i++) {
                _ia[i] = byteString.charCodeAt(i);
            }
            var dataView = new DataView(arrayBuffer);
            var blob = new Blob([dataView], {type: mimeString});
            return blob;
        },
        createImage(file) {

            //   var image = new Image();
            var reader = new FileReader();
            var vm = this;

            reader.onload = (e) => {
                vm.image = e.target.result;
            }
            reader.readAsDataURL(file);
        },
        //ADD FIRSTLETTER CAPITAL IN FIRSTNAME AND LASTNAME(Shraddha)
        firstLetterCapital()
        {
            if(this.updatedUser.firstName!='')
            {
                this.updatedUser.firstname= this.updatedUser.firstName.charAt(0).toUpperCase()+this.updatedUser.firstName.substring(1)
            }
            if(this.updatedUser.lastName!='')
            {
                this.updatedUser.lastName= this.updatedUser.lastnNme.charAt(0).toUpperCase()+this.updatedUser.lastName.substring(1)
            }
        },
        // RESET FORM(Shraddha)
        cancelProfileEdit()
        {
            var self = this;

            self.updatedUser.lastName = self.user.lastName
            self.updatedUser.firstName = self.user.firstName
            self.updatedUser.email = self.user.email

            self.currentPassword="",
            self.newPassword="",
            self.confirmNewPassword=""
            self.preview = self.user.profileUrl
            self.image=self.imageURL

            //reset error warnings
            self.$refs.log.reset()
            self.$refs.passwordChange.reset()
        },
        //SAVE ADMIN PROFILE DATA(Shraddha)
        saveUserProfileData(){
            // //debugger // eslint-disable-line
            var self = this
            if(self.charecterErrorMessageForFirstname == '' && self.charecterErrorMessageForLastname == ''){
                self.isSpinner=true;
                const db = firebase.firestore()
                if(self.updatedUser.email==self.user.email)
                {
                    if(self.profileImageFile!=null)
                    {
                        
                        var ImageNameSplit = self.profileImageFile.name.split('.')
                        var str = (
                            Math.random().toString(36).substring(2, 8) +
                            Math.random().toString(36).substring(2, 8)
                        ).toUpperCase();
                        var ImageName=""
                        if(ImageNameSplit.length>2)
                        {
                            //console.log("if",ImageNameSplit)
                            for (let index = 0; index < ImageNameSplit.length-1; index++) {
                                ImageName += `${ImageNameSplit[index]}`
                            }
                            ImageName+=`_${str}.${ImageNameSplit[ImageNameSplit.length-1]}`
                        }
                        else
                        {
                            ImageName=`${ImageNameSplit[0]}_${str}.${ImageNameSplit[1]}`
                        }

                        if(self.imageURL==Vue.prototype.$defaultProfile)
                        {
                            var storageRef = firebase.storage().ref()
                            var uploadTask = storageRef.child(firebaseData.firebasecollection.PROFILEIMAGES+ImageName).put(self.profileImageFile)

                            uploadTask.on('stateChanged',
                            (snapshot)=>{
                                var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
                                //console.log(progress)
                            },
                            (error)=>{
                                self.isSpinner=false;
                                self.$notify({
                                    title:"Error",
                                    textL:error.message,
                                    type:'error'
                                })
                            },
                            ()=>{
                                uploadTask.snapshot.ref.getDownloadURL()
                                .then(URL=>{
                                    self.image=URL

                                    //Upload ProfileImage in Firestore Doc
                                    db
                                        .collection(firebaseData.firebasecollection.USER)
                                        .doc(localStorage.getItem("SuperAdminKey"))
                                        .update({
                                            "firstName":self.updatedUser.firstName,
                                            "lastName":self.updatedUser.lastName,
                                            "email":self.updatedUser.email,
                                            "profileUrl":URL,
                                        })
                                        .then(()=>{
                                            self.isSpinner=false;
                                            self.$notify({
                                                title:"Success",
                                                text:'Profile updated successfully.',
                                                type:"success"
                                            })
                                            self.getUserData()
                                        })
                                        .catch(error=>{
                                            self.isSpinner=false;
                                            self.$notify({
                                                title:"Error",
                                                textL:error.message,
                                                type:'error'
                                            })
                                        })
                                })
                            })
                        }
                        else
                        {
                            var storage = firebase.storage().ref()

                                var uploadTask = storage.child(firebaseData.firebasecollection.PROFILEIMAGES+ImageName).put(self.profileImageFile)

                                uploadTask.on('stateChanged',
                                (snapshot)=>{
                                    var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
                                    //console.log(progress)
                                },
                                (error)=>{
                                    self.isSpinner=false;
                                    self.$notify({
                                        title:"Error",
                                        textL:error.message,
                                        type:'error'
                                    })
                                },
                                ()=>{
                                    uploadTask.snapshot.ref.getDownloadURL()
                                    .then(URL=>{
                                        self.image=URL

                                        //Upload ProfileImage in Firestore Doc
                                        db
                                            .collection(firebaseData.firebasecollection.USER)
                                            .doc(localStorage.getItem("SuperAdminKey"))
                                            .update({
                                                "firstName":self.updatedUser.firstName,
                                                "lastName":self.updatedUser.lastName,
                                                "email":self.updatedUser.email,
                                                "profileUrl":URL,
                                            })
                                            .then(()=>{
                                                self.isSpinner=false;
                                                self.$notify({
                                                    title:"Success",
                                                    text:'Profile updated successfully.',
                                                    type:"success"
                                                })
                                                self.getUserData()
                                            })
                                            .catch(error=>{
                                                self.$notify({
                                                    title:"Error",
                                                    textL:error.message,
                                                    type:'error'
                                                })
                                            })
                                    })
                                })
                        }
                    }
                    else
                    {
                        db
                        .collection(firebaseData.firebasecollection.USER)
                        .doc(localStorage.getItem("SuperAdminKey"))
                        .update({
                            "firstName":self.updatedUser.firstName,
                            "lastName":self.updatedUser.lastName,
                        })
                        .then(()=>{
                            self.isSpinner=false;
                            self.$notify({
                                title:"Success",
                                text:'Profile updated successfully.',
                                type:"success"
                            })           
                            self.getUserData()
                        })
                        .catch(error=>{
                            self.isSpinner=false;
                            self.$notify({
                                title:"Error",
                                textL:error.message,
                                type:'error'
                            })
                        })
                    }
                }
                else
                {
                    var user = firebase.auth().currentUser;
                    //console.log(user)

                    user.updateEmail(self.updatedUser.email)
                    .then(()=>{
                        db
                            .collection(firebaseData.firebasecollection.USER)
                            .doc(localStorage.getItem("SuperAdminKey"))
                            .update({
                                "firstName":self.updatedUser.firstName,
                                "lastName":self.updatedUser.lastName,
                                "email":self.updatedUser.email
                            })
                            .then(()=>{
                                self.isSpinner=false;
                                self.$notify({
                                    title:"Success",
                                    text:'Profile updated successfully.',
                                    type:"success"
                                })
                                self.getUserData()
                            })
                            .catch(error=>{
                                self.isSpinner=false;
                                self.$notify({
                                    title:"Error",
                                    textL:error.message,
                                    type:'error'
                                })
                            })
                    })
                    .catch(error=>{
                        self.isSpinner=false;
                        self.$notify({
                            title:"ERROR",
                            text:error.message,
                            type:"error"
                        })
                    })
                }
            }
        },
        // EMAIL NOTIFICATION SAVE(Shraddha)
        saveEmailNotification(){
            // //debugger // eslint-disable-line
            var self = this;
            //console.log(self.usersignup,self.upgradeaccount,self.supportticket);
            const db = firebase.firestore()
            db.collection(firebaseData.firebasecollection.USER)
            .doc(localStorage.getItem("SuperAdminKey"))
            .update({
               'userSignupNotification':self.updatedUser.userSignupNotification,
               'userUpgradeNotification':self.updatedUser.userUpgradeNotification,
               'userSupportTicket':self.updatedUser.userSupportTicket,
            })
            .then(()=>{
                self.isSpinner=false;
                self.$notify({
                    title:"Success",
                    text:'Email Notification Added successfully.',
                    type:"success"
                })
                self.getUserData()
            })
            .catch(error=>{
                self.isSpinner=false;
                console.log(error)
                self.$notify({
                    title:"Error",
                    textL:error,
                    type:'error'
                })
            })
        },
        resetNotification(){
            // //debugger // eslint-disable-line
            var self = this;
            //console.log(self.usersignup,self.upgradeaccount,self.supportticket);
            const db = firebase.firestore()
            db.collection(firebaseData.firebasecollection.USER)
            .doc(localStorage.getItem("SuperAdminKey")).get().then((doc)=>{
                self.updatedUser.userSignupNotification = doc.data().userSignupNotification
                self.updatedUser.userUpgradeNotification = doc.data().userUpgradeNotification,
                self.updatedUser.userSupportTicket = doc.data().userSupportTicket

            })
            // self.usersignup = false,
            // self.upgradeaccount = false,
            // self.supportticket = false

        },
        //SAVE NEW PASSWORD (Shraddha)
        saveNewPassword()
        {
            // //debugger // eslint-disable-line
            var self = this

            self.isSpinner=true;
            if(self.passwordErrorMessage == ''){
            const user = firebase.auth().currentUser

            var cred = fb.auth.EmailAuthProvider.credential(
                user.email,
                self.currentPassword
            )
            // //console.log('cred',cred)

            user.reauthenticateWithCredential(cred)
            .then(()=>{
                user.updatePassword(self.newPassword)
                .then(()=>{
                    self.isSpinner=false;
                    self.cancelProfileEdit()
                    self.$notify({
                        title:"Success",
                        text:'Password updated successfully.',
                        type:"success"
                    })
                })
                .catch(error=>{
                    self.isSpinner=false;
                    self.$notify({
                        title:"Error",
                        text:error.message,
                        type:"error"
                    })
                })
            })
            .catch(error=>{
                self.isSpinner=false;
                self.$notify({
                    title:"Error",
                    text:error.message,
                    type:"error"
                })
            })
            // var self = this;
            // self.isSpinner=true;

            // var currpassword = JSON.parse(localStorage.getItem("rememberMe")).password

            // if(self.currentPassword == currpassword)
            // {
            //     if(self.newPassword==self.confirmNewPassword)
            //     {
            //         self.$axios.post(firebaseData.firebasecollection.APIDOMAIN+'/saveNewPassword',{"password":self.newPassword,docId:localStorage.getItem('SuperAdminKey')})
            //         .then(()=>{                        
            //             self.cancelProfileEdit()
            //             self.isSpinner=false;
            //             self.$notify({
            //                 title:"Success",
            //                 text:'Password updated successfully.',
            //                 type:"success"
            //             })
            //         })
            //         .catch(error=>{
            //             self.isSpinner=false;
            //             self.$notify({
            //                 title:"Error",
            //                 textL:error.message,
            //                 type:'error'
            //             })
            //         })
            //     }
            //     else
            //     {
            //         self.isSpinner=false;
            //         self.$notify({
            //             title:"ERROR",
            //             text:'Passwords did not Matched.',
            //             type:"error"
            //         })
            //     }
            // }
            // else
            // {
            //     self.isSpinner=false;
            //     self.$notify({
            //         title:"ERROR",
            //         text:'Current Password Is Incorrect.',
            //         type:"error"
            //     })
            // }
            }
        },
        //GET ADMIN DATA FROM DATABASE COLLECTION(Shraddha)
        getUserData(){
            // //debugger // eslint-disable-line
            var self = this;
            const db = firebase.firestore()
            db.collection(firebaseData.firebasecollection.USER)
            .doc(localStorage.getItem("SuperAdminKey"))
            .get()
            .then(doc=>{
                self.user = doc.data()
                self.updatedUser = doc.data()
                self.image=doc.data().profileUrl
                self.imageURL=doc.data().profileUrl
                // self.profileImageName=doc.data().profileImageName

                self.$root.$emit('fetchNewData',doc.data().firstName,doc.data().profileUrl)
                // console.log('self.updatedUser',self.updatedUser)
            })
            .catch(error=>{
                self.$notify({
                    title:"Error",
                    textL:error.message,
                    type:'error'
                })
            })
        },
        // TOGGLE PASSWORD (Shraddha)        
        showPassword(){
            if(this.passwordType=='password')
            {
                this.passwordType='text'
            }
            else
            {
                this.passwordType='password'
            }
            // const togglePassword = document.querySelector('#togglePassword');
            // const password = document.querySelector('#password');
            // const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
            // password.setAttribute('type', type);
            // // toggle the eye / eye slash icon
            // this.classList.toggle('bi-eye');
        },
        showPasswords(){
            if(this.passwordTypes=='password')
            {
                this.passwordTypes='text'
            }
            else
            {
                this.passwordTypes='password'
            }
            // const togglePassword = document.querySelector('#toggleConfirmPassword');
            // const password = document.querySelector('#confirmpassword');
            // const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
            // password.setAttribute('type', type);
            // // toggle the eye / eye slash icon
            // this.classList.toggle('bi-eye');
        },
        showPasswordss(){
            if(this.passwordTypess=='password')
            {
                this.passwordTypess='text'
            }
            else
            {
                this.passwordTypess='password'
            }
            // const togglePassword = document.querySelector('#toggleConfirmNewPassword');
            // const password = document.querySelector('#confirmnewpassword');
            // const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
            // password.setAttribute('type', type);
            // // toggle the eye / eye slash icon
            // this.classList.toggle('bi-eye');
        },     
    },
    computed:{
      // CHECK IF CHANGES IN INPUT FEILD OR NOT (Shraddha)
        checkChangeInput(){
        // debugger // eslint-disable-line
            var self = this;
            const db = firebase.firestore()
            var disablereturn = true
            if(Object.keys(self.updatedUser).length != 0 && Object.keys(self.user).length != 0){
                if(self.updatedUser.firstName == self.user.firstName && self.updatedUser.lastName == self.user.lastName && self.image == self.user.profileUrl){
                    return disablereturn 
                }else{
                return  !disablereturn 
                }
            }else{
                return  !disablereturn 
            }
        },
        checkChangeNotificationInput(){
            // debugger // eslint-disable-line
            var self = this;
            const db = firebase.firestore()
            var disablereturn = true
            if(Object.keys(self.updatedUser).length != 0 && Object.keys(self.user).length != 0){
                // console.log(self.updatedUser,self.user)
                if(self.updatedUser.userSignupNotification == self.user.userSignupNotification && self.updatedUser.userUpgradeNotification == self.user.userUpgradeNotification && self.updatedUser.userSupportTicket == self.user.userSupportTicket){
                    return disablereturn 
                }else{
                return  !disablereturn 
                }
            }else{
                return  !disablereturn 
            }
        },
        //BACK TO DASHBOARD (Shraddha)
        backtodashboard(){
            var self = this;
            self.$root.$emit('openDashboard',0);
        }
    }
})
</script>


<style scoped>
.InvalidInput{
    color: red;
}
</style>