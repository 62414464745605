<template>
    <div class="user_management_detail_wrapper detail_wrapper_main ">
        <div class="admin_Page_title">
         <h4>{{customerListing.firstName}} {{customerListing.lastName}}</h4> 
         <img class="title_icon" @click="backtodashboard()" src="@/assets/images/home_icon.png" alt="homeIcon">
         <span class="arrow_title" > <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
         <span class="current_page" @click="backtoUserList()">User Management</span>
         <span class="arrow_title"> <img class="title_icon" src="@/assets/images/tag-arrow.png" alt="homeIcon"></span>
         <span class="current_page">{{customerListing.firstName}} {{customerListing.lastName}}</span>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="user_profile_image">
                    <img :src = customerListing.profileUrl  class="admin_profile">
                     <p>{{customerListing.firstName}} {{customerListing.lastName}}</p>
                </div>
                <div v-if="customerListing.isDeleted">
                    
                </div>
                <div v-else>
                    <button v-if="customerListing.userStatus == 'Active'&& !customerListing.isDeleted" class="btn btn-yellow" @click="IsUserBlock(customerListing)">Block</button>
                    <button v-else-if="customerListing.userStatus == 'Inactive' && !customerListing.isDeleted" class="btn btn-yellow" @click="IsUserBlock(customerListing)">Unblock</button>
                </div>
            </div>
            <div class="col-md-8">
                <div class="admin_user_info_wrapper">
                    <div class="row">
                        <div class="col-md-2">
                            <div>
                                <label>Name</label>
                                <span>{{customerListing.firstName}} {{customerListing.lastName}}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div>
                                <label>Email</label>
                                <span>{{customerListing.email}}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div>
                                <label>Phone</label>
                                <span>{{customerListing.phoneNo}}</span>
                            </div>
                        </div>
                        <div class="col-md-2" v-if="customerListing.BusinessName">
                            <div>
                                <label>Business Name</label>
                                <span>{{customerListing.BusinessName}}</span>
                            </div>
                        </div>
                        <div class="col-md-2" v-if="customerListing.jobTitle">
                            <div>
                                <label>Job Title</label>
                                <span>{{customerListing.jobTitle}}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div>
                                <label>Status</label>
                                <div v-if="!customerListing.isDeleted">
                                    <strong v-if="customerListing.userStatus == 'Active'" class="active">{{customerListing.userStatus}}</strong>
                                    <strong v-else class="inactive">{{customerListing.userStatus}}</strong>
                                </div>
                                <div v-else>
                                    <strong class="inactive">Deactive</strong>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-12" v-if="customerListing.BioDiscription">
                            <div class="bio_area">
                                <label>Bio</label>
                                <span>{{customerListing.BioDiscription}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="deactiveDetails.length > 0">
                    <div class="table_wrapper">
                        <h4>User activity</h4>
                        <table class="table deactive_table" id="myTable">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Deactive Date/time</th>
                                    <th>Deactive Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(rawData, indextr) in deactiveDetails" v-bind:key="indextr">
                                    <td>{{indextr + 1}}</td>
                                    <td>{{convertDate(rawData.deactivedate)}}</td>
                                    <td v-if="rawData.deactivateReason">{{rawData.deactivateReason}}</td>
                                    <td v-else>-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
       
     
       <!-- <FrontFooter /> -->
    </div>
</template>
<script>
import firebase from "../firebaseInit";
import firebaseData from '../../utils/firebaseCollection.js';
import FrontFooter from "@/components/forntfooter.vue";
import Swal from 'sweetalert2'
    export default({
    name:'ContentManagementDetail',
    props: [
        "rowId",
        "isCustomerView"
    ],
    components: {
      firebaseData,
      FrontFooter,
      
    },
        data(){
            return{
                customerListing:{},
                deactiveDetails:[]
            }
        },
        created(){
            this.init();
        },
        methods:{
            //FOR GET CUSTOMER DATA FROM ROWID
            init(){
                // //debugger // eslint-disable-line
                var self = this;
                var customerlist = {}
                const db = firebase.firestore();
                //console.log(self.rowId)
                db.collection(firebaseData.firebasecollection.USER).doc(self.rowId)
                    .get()
                    .then(doc=>{
                        customerlist = doc.data()
                        self.customerListing=customerlist
                    //console.log('self.customerListing',self.customerListing)
                }).then(() => {
                    db.collection(firebaseData.firebasecollection.USER).where('email','==',customerlist.email || 'phoneNo','==',customerlist.phoneNo).get().then(snap => {
                        self.deactiveDetails = [];
                        snap.forEach(snapData => {
                           if(snapData.data().deactivedate){
                               if(self.deactiveDetails.length == 0){
                                    self.deactiveDetails.push({'deactivedate':snapData.data().deactivedate,'deactivateReason':snapData.data().deactivateReason || ''});
                               }else{
                                   var found = false;
                                    self.deactiveDetails.forEach(datas => {
                                       if(datas.deactivedate == snapData.data().deactivedate){
                                           found = true;
                                       }
                                   })
                                   if(!found){
                                        self.deactiveDetails.push({'deactivedate':snapData.data().deactivedate,'deactivateReason':snapData.data().deactivateReason || ''});
                                   }
                               }
                           } 
                        })
                    }).then(() => {
                        self.deactiveDetails.sort((book1, book2) => {
                            return self.compareObjects(book1, book2, 'deactivedate')
                        })
                    })
                })
            },
            //BLOCK BUTTON CLICK(Shraddha)
            IsUserBlock(data){
                debugger // eslint-disable-line
                var self=this;
                var Id = data
                var rawId = Id;
                const db = firebase.firestore();
                self.UserStatus = '';
                db.collection(firebaseData.firebasecollection.USER).doc(rawId.id).get().then(doc=>{
                    self.UserStatus = doc.data().userStatus;
                    if(self.UserStatus == 'Active'){
                        self.UserStatus = 'Inactive'
                    }else if(self.UserStatus == 'Inactive'){
                        self.UserStatus = 'Active'
                    }
                }).then( () => {
                    if(self.UserStatus != ''){
                        if(self.UserStatus == 'Inactive'){
                            const Toast = Swal.mixin({
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                            Swal.fire({
                                title:
                                "Are you sure you want to block" +
                                " " +
                                data.firstName +
                                " " +
                                data.lastName +
                                " " +
                                "?",
                                type: "warning",
                                showCancelButton: true,
                                customClass:{
                                container :"social_confirmation_popup"
                                },
                                confirmButtonColor: "#ffab3f",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Ok",
                            }).then((result) => {
                                if (result.value) {
                                    db.collection(firebaseData.firebasecollection.USER).doc(rawId.id)
                                    .update({
                                        'userStatus': self.UserStatus
                                    }).then(() => {
                                        self.$axios.post(self.$apiurl+'disableUser',{uid:rawId})
                                            .then(res => {
                                            console.log(res);
                                            self.$notify({
                                                title:"Success",
                                                text:data.firstName +' '+data.lastName+' blocked.',
                                                type:"success",
                                                duration: 10000
                                            })
                                        })
                                    })
                                    
                                    self.init();
                                }
                            })
                        }else if(self.UserStatus == 'Active'){
                            const Toast = Swal.mixin({
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                            Swal.fire({
                                title: 'are you sure you want to unblock'+' '+data.firstName+' '+data.lastName+ '?',
                                type: "warning",
                                customClass:{
                                    container :"social_confirmation_popup"
                                },
                                showCancelButton: true,
                                confirmButtonColor: "#ffab3f",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, unblock it!",
                            }).then((result) => {
                                if (result.value) {
                                    db.collection(firebaseData.firebasecollection.USER).doc(rawId.id)
                                    .update({
                                        'userStatus': self.UserStatus
                                    }).then(() => {
                                        self.$axios.post(self.$apiurl+'enableUser',{uid:rawId})
                                        .then(res => {
                                            self.$notify({
                                                title:"Success",
                                                text:data.firstName +' '+data.lastName+' unblocked.',
                                                type:"success",
                                                duration: 10000
                                            })
                                        })
                                    })
                                    
                                    self.init();
                                }
                            })
                        }
                    }
                    self.init();
                })
                
            },
            // FOR BACK TO USER LISTING(Shraddha)
            backtoUserList(){
              var self = this;
              self.$emit("UpdateList",false);  
            },
            //BACK TO DASHBOARD (Shraddha)
            backtodashboard(){
               var self = this;
               self.$root.$emit('openDashboard',0);
            },
            //CONVERT DATE(Shraddha)
            convertDate(date){
                var current_date=new Date(date.seconds*1000);
                var formatted_date = current_date.getDate() + "/" + (current_date.getMonth() + 1) + "/" + current_date.getFullYear() + " " +current_date.getHours() + ":" +current_date.getMinutes();
                return formatted_date;
            },
            compareObjects(object1, object2, key) {
                const obj1 = object1[key];
                const obj2 = object2[key];
                if (obj1 < obj2) {
                return 1
                }
                if (obj1 > obj2) {
                return -1
                }
                return 0
            },
        }
    })
</script>