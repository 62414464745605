<template>
    <b-modal id="create-branch" centered hide-footer title="" :no-close-on-backdrop="true" @close="resetValues()">
     <div class="contact-modal">
            <div class="contact-modal">
            <h4>Create Branch</h4>
            <p>Give this branch a unique, descriptive name</p>
            <ValidationObserver ref="log" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(createBranch)">
                    <ValidationProvider rules="required|min:3" name="branch name" v-slot="{ errors }">
                        <div class="form-group">
                            <label>Branch Name *</label>
                            <input class="form-control" placeholder="Enter branch name" @blur="branchName = branchName.trim()" @keyup="branchName=branchName.charAt(0).toUpperCase()+branchName.substring(1),checkChrecterLengthInBranch(branchName)" maxlength="21"  name="branch name" v-model="branchName">
                            <span class="InvalidInput">{{ errors[0]=='The branch name field must be at least 3 characters'?'Minimum 3 character is required.':errors[0] }}</span>
                            <span v-if="charecterErrorMessageForBranch != '' && errors.length == 0" class="InvalidInput">{{charecterErrorMessageForBranch}}</span>
                        </div>
                        <div class="btn-wrapper">
                            <button class="btn btn-yellow" v-bind:class="{disabled: isSpinner == true}" type="submit">Create</button>
                        </div>
                    </ValidationProvider>
                </form>
            </ValidationObserver>
        </div>
        </div>
  </b-modal>
</template>
<script>
import firebase from "../../views/firebaseInit";
import firebaseTimestamp from "firebase";
import { firebasecollection } from '../../utils/firebaseCollection'

const db = firebase.firestore();

export default {
    name:'createBranch',
    props: ['groupDocId','mainBranchActiveIndex','subBranchActive','subBranchActiveIndex','createBranchMessageId','mainBranches'],
    data(){
        return{
            branchName:'',
            mainbranches:this.mainBranches,
            chatObjectId:'',
            from:{},
            fromArray:[],
            sendingMessage:'',
            updateChat:{},
            image_list:[],
            isSpinner:false,
            charecterErrorMessageForBranch:'',
            connectedArray:[],
        }
    },
    created()
    {
        this.$root.$on('createBranchEvent',(chatId)=>{
            this.groupDocId=chatId
            this.mainBranchActive=false
            this.mainBranchActiveIndex=0
            this.subBranchActive=false
            this.subBranchActiveIndex=0
            // console.log(this.groupDocId),
            this.createBranchMessageId=''
        })

        this.$root.$on("chatObjefct",(value,chatId,activeGroup,mainBranchArray)=>{
            this.chatObjectId = value.id
        })
    },
    methods:
    {
        // CHECK CHARECTER LIMIT IN BRANCH NAME (Shraddha)
        checkChrecterLengthInBranch(groupName){
            var self = this;
            if(groupName.length < 21){
                self.branchName = groupName
                self.charecterErrorMessageForBranch = '';
            }else{
                self.charecterErrorMessageForBranch = 'The Branch name may not be greater than 20 characters';
                self.branchName = (groupName).slice(0,20)
            }
        },
        resetValues()
        {
            this.branchName='';
            this.$refs.log.reset()
        },  
        createBranch()
        {
            this.$emit("ok",newBranchName)
            // debugger // eslint-disable-line
            var self = this;
            self.isSpinner = true;
            var newBranchName = true;
            var userDocId = firebase.auth().currentUser.uid;

            const branchName = self.branchName;

            if(self.groupDocId !== undefined)
            {
                let chatDocId = self.groupDocId;

                self.checkAlreadyExists(chatDocId,branchName)
                .then((doc)=>{
                    let messageSeenIdsValues = [];
                    self.connectedArray = doc.data().connectedUser || [];

                    doc.data().connectedUser.forEach(ids=>{
                        messageSeenIdsValues.push({
                            id:ids,
                            count:0,
                            time:"",
                            IsEmailSend:true
                        })
                    });

                    let mainBranches = self.sortArray(doc.data().mainBranches) || [];

                    let branchObj = {
                        createdAt: new Date(),
                        name: branchName.trim(),
                        msgid: self.createBranchMessageId,
                        muteArray: [],
                        messageSeenIds: messageSeenIdsValues,
                        lastMsgTime: new Date(),
                        flag: false
                    }
                    if(!self.mainBranchActive) {
                        // Add Main branch
                        branchObj.subBranches = [];
                        mainBranches.push(branchObj);
                    } else if(self.mainBranchActive && !self.subBranchActive) {
                        // Add Sub branch
                        branchObj.subSubBranches = [];
                        mainBranches[self.mainBranchActiveIndex].subBranches.push(branchObj);
                    } else if(self.mainBranchActive && self.subBranchActive) {
                        // Add Sub sub branch
                        mainBranches[self.mainBranchActiveIndex].subBranches[self.subBranchActiveIndex].push(branchObj);
                    }

                    db
                        .collection(firebasecollection.SOCIALMESSAGE)
                        .doc(chatDocId)
                        .update({
                            mainBranches:mainBranches
                        })
                        .then(()=>{
                            if(self.createBranchMessageId!= '')
                            {
                                self.sendMessage(branchName,chatDocId,branchName,mainBranches);
                            }
                            else
                            {
                                self.$root.$emit("audioplay");
                                self.$notify({
                                    title:"Success",
                                    text:'Branch creation successful.',
                                    type:"success"
                                })
                                self.isSpinner = false
                                branchName='';
                                self.branchName='';
                                self.$refs.log.reset()
                                self.$bvModal.hide('create-branch')
                            }
                        })
                        .catch(error=>{
                            self.isSpinner = false;
                            console.log(error);
                        })
                })
                .catch(error=>{
                    self.isSpinner = false
                    self.$root.$emit("audioplay");
                    self.$notify({
                        title:"Error",
                        text:error,
                        type:'error'
                    })
                    branchName='';
                    self.branchName='';
                    self.$refs.$log.reset()
                })
            }
        },
        checkAlreadyExists(chatDocId,branchName){
            var self = this;
            return new Promise((resolve,reject)=>{
                db
                    .collection(firebasecollection.SOCIALMESSAGE)
                    .doc(chatDocId)
                    .get()
                    .then(doc=>{
                        let newBranchName = true
                        if(doc.exists && doc.data().mainBranches!==undefined)
                        {
                            if(doc.data().mainBranches.length!=0)
                            {
                                doc.data().mainBranches.forEach(data=>{
                                    if(branchName.trim() == data.name)
                                    {
                                        newBranchName=false
                                    }
                                    if(data.subBranches){
                                        data.subBranches.forEach(data2=>{
                                            if(branchName.trim() == data2.name)
                                            {
                                                newBranchName=false
                                            }
                                            if(data2.subSubBranches){
                                                data2.subSubBranches.forEach(data3=>{
                                                    if(branchName.trim() == data3.name)
                                                    {
                                                        newBranchName=false
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        } else {
                            reject("Something went wrong.")
                        }
                        if(newBranchName) {
                            resolve(doc)
                        } else {
                            reject("Branch name already used.")
                        }
                    })
                    .catch(error=>{
                        reject(error.message);
                    })
            });
        },
        sendMessage(collectionName,chatDocId,branchName,mainBranches){
            debugger // eslint-disable-line
            var self = this;
            self.$refs.log.reset()
            self.$bvModal.hide('create-branch')
            self.$root.$emit('branchBusinessOpen',branchName);
            let from = {}
            let fromArray = []
            let sendingMessage = {};

            //check images atachments
            db.collection(firebasecollection.SOCIALMESSAGE)
                .doc(chatDocId)
                .collection(collectionName)
                .doc(self.createBranchMessageId)
                .update({
                    branch:branchName.trim(),
                })
                .then(()=>{
                    db
                        .collection(firebasecollection.SOCIALMESSAGE)
                        .doc(chatDocId)
                        .collection(collectionName)
                        .doc(self.createBranchMessageId)
                        .get()
                        .then(doc=>{
                            sendingMessage=doc.data()
                            sendingMessage.from.path=branchName.trim()
                            sendingMessage.branch=''
                            sendingMessage.from.id=firebase.auth().currentUser.uid
                            for (let i = 0; i < sendingMessage.messageSeen.length; i++) {
                                if(sendingMessage.messageSeen[i].id!=firebase.auth().currentUser.uid)
                                {
                                    sendingMessage.messageSeen.splice(i,1)
                                }
                            }
                            if(sendingMessage.surpriseSelfieUsers && sendingMessage.surpriseSelfieUsers.length > 0){
                                sendingMessage.surpriseSelfieUsers = self.connectedArray;
                            }

                            // console.log('Found Message')
                            db
                                .collection(firebasecollection.SOCIALMESSAGE)
                                .doc(chatDocId)
                                .collection(branchName.trim())
                                .add(sendingMessage)
                                .then(docRef=>{
                                    // console.log('Added Message')
                                    db
                                        .collection(firebasecollection.SOCIALMESSAGE)
                                        .doc(chatDocId)
                                        .collection(branchName.trim())
                                        .doc(docRef.id)
                                        .update({
                                            id:docRef.id
                                        })
                                        .then(()=>{
                                            // console.log('Update Message Id')
                                            mainBranches.forEach(data=>{
                                                if(data.name == branchName.trim())
                                                {
                                                    data.messageSeenIds.forEach(ids=>{
                                                        if(ids.id!= firebase.auth().currentUser.uid)
                                                        {
                                                            ids.count++
                                                        }
                                                    })
                                                }
                                                else
                                                {
                                                    if(data.subBranches){
                                                        data.subBranches.forEach(data2=>{
                                                            if(data2.name == branchName.trim())
                                                            {
                                                                data2.messageSeenIds.forEach(ids2=>{
                                                                    if(ids2.id!= firebase.auth().currentUser.uid)
                                                                    {
                                                                        ids2.count++
                                                                    }
                                                                })
                                                            }
                                                            else
                                                            {
                                                                if(data2.subSubBranches){
                                                                    data2.subSubBranches.forEach(data3=>{
                                                                        if(data3.name == branchName.trim())
                                                                        {
                                                                            data3.messageSeenIds.forEach(ids3=>{
                                                                                if(ids3.id!= firebase.auth().currentUser.uid)
                                                                                {
                                                                                    ids3.count++
                                                                                }
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        })
                                                    }
                                               }
                                            })
                                            // console.log("messageCounts",mainBranches)
                                            from = {
                                                avtar:sendingMessage.from.avtar,
                                                id:sendingMessage.from.id,
                                                name:sendingMessage.from.name,
                                                path:branchName.trim(),
                                            }
                                            fromArray.push(from);
                                            var updateBranchData = {
                                                from:from,
                                                msg:sendingMessage.msg || '',
                                                type:sendingMessage.type,
                                                name:sendingMessage.name || ''
                                            }
                                            db
                                                .collection(firebasecollection.SOCIALMESSAGE)
                                                .doc(chatDocId)
                                                .update({
                                                    lastMsgtime :firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                    updateBranchData:updateBranchData,
                                                    msgFromBranch:true,
                                                    mainBranches:mainBranches
                                                })
                                                .then(()=>{
                                                    // console.log('update Chat')
                                                    self.$root.$emit("audioplay");
                                                    self.$notify({
                                                        title:"Success",
                                                        text:'Branch creation successful.',
                                                        type:"success"
                                                    })
                                                    // self.$refs.log.reset()
                                                    self.isSpinner = false
                                                    // self.$bvModal.hide('create-branch')
                                                    // self.$root.$emit('branchOpen',branchName)
                                                    self.branchName=''
                                                })
                                                .catch(error=>{
                                                    console.log(error)
                                                    self.isSpinner = false
                                                })
                                        })
                                        .catch(error=>{
                                            console.log(error)
                                            self.isSpinner = false
                                        })
                                })
                        })
                        .catch(error=>{
                            console.log(error)
                            self.isSpinner = false
                        })
                })
                .catch(error=>{
                    console.log(error)
                    self.isSpinner = false
                })

        },
        sortArray(mainBranchArray)
        {
            //console.log('sortCalled')
            // console.log('arr',mainBranchArray)

            var tmp = mainBranchArray
            var tmp2 = ''

            for (let index = 0; index < tmp.length; index++) {
                for (let j = index+1; j < tmp.length; j++) {
                    if(tmp[index].lastMsgTime.seconds < tmp[j].lastMsgTime.seconds)
                    {
                        tmp2 = tmp[index]
                        tmp[index] = tmp[j]
                        tmp[j]=tmp2
                    }
                }
            }
            return tmp
        },
    }
}
</script>