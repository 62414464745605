<template>
  <div class="header">
    <nav class="navbar navbar-expand-md">
      <a class="navbar-brand" href="#"
        ><img src="@/assets/images/logo.png"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="afterlogin-part">
          <ul class="navbar-nav left">
           <li>
            <div class="dropdown-menu">
             <router-link class="dropdown-item" to="/myprofile">My Profile</router-link>
             <!-- <router-link class="dropdown-item" to="/generalsettings">General Settings</router-link>
              <router-link class="dropdown-item" to="/feedback">Give Feedback</router-link> -->
              <a class="dropdown-item" href="#">Logout</a>
            </div>
          </li>
         
          </ul>
      </div>

      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav">
         
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle profileimg_header"
              href="#"
              id="navbardrop"
              data-toggle="dropdown"

            >
              <img :src="adminInfo.profileUrl" />
              <div><b>{{adminInfo.firstName}} {{adminInfo.lastName}}</b></div>
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#" @click="gotoAdminProfile()">My Profile</a>
              <!-- <a class="dropdown-item" href="#">General Settings</a>
              <a class="dropdown-item" href="#">Give Feedback</a> -->
              <a class="dropdown-item" href="#" @click="LogoutAdmin()">Logout</a>
            </div>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">
              <img src="@/assets/images/setting.png" />
            </a>
          </li> --> 
        </ul>
      </div>
    </nav>

    <!-- Modal VIewDetail -->

      <!-- <b-modal id="modal-md" hide-footer centered>
        <div class="view-detail-content">
          <div class="photoupload-wrapper">
            <img v-if="preview" :src="preview" class="img-fluid" />
                    <img v-else src="@/assets/images/noupload.png">
                    <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file">
          </div>
          <div class="form-text-viewdetail">
              <form>
                <div class="form-group">
                    <label>Chat Name *</label>
                    <input type="text" class="form-control" placeholder="Friends" value="">
                </div>
              </form>
              <div class="chat-created-info">
              <p><span>Created by :</span> <span>Manuel Storie</span></p>
              <p><span>Created on :</span> <span>26 March 2021</span></p>
              </div>
              <div class="btn-wrapper"><button class="btn btn-white" @click="$bvModal.hide('modal-md')">Cancel</button><button class="btn btn-yellow">Save</button></div>
          </div>
        </div>
      </b-modal> -->

    <!-- Modal viewDetail End -->

    <!-- Add Member Modal -->
    <!-- <b-modal id="my-modal" centered hide-footer>
      <div class="add-member-content contact-modal">
          <h4>Add Member</h4>
          <form class="modal-search">
              <div class="form-group">
                <input type="search" class="form-control" placeholder="Search">
                <img src="../assets/images/search.png" class="search-icon">
              </div>
          </form>
          <ul class="modal-member-list">
            <li>
              <div class="ml-item">
                <img src="../assets/images/member-img.png" class="member-img">
                <span>Willie Smith</span>
                <button class="btn btn-white">Add</button>
              </div>
            </li>
            <li>
              <div class="ml-item">
                <img src="../assets/images/member-img.png" class="member-img">
                <span>Robert Gehl</span>
                <button class="btn btn-white">Add</button>
              </div>
            </li>
            <li>
              <div class="ml-item">
                <img src="../assets/images/member-img.png" class="member-img">
                <span>Alvin Richards</span>
                <button class="btn btn-white">Add</button>
              </div>
            </li>
          </ul>
      </div>
    </b-modal> -->
    <!-- Add Member Modal End -->

    <!-- Add Member Modal -->
    <!-- <b-modal id="member" centered hide-footer>
      <div class="add-member-content contact-modal">
          <h4>Member</h4>
          <ul class="modal-member-list">
            <li>
              <div class="ml-item">
                <img src="../assets/images/member-img.png" class="member-img">
                <span>Willie Smith</span>
                <button class="btn btn-white">Remove</button>
              </div>
            </li>
            <li>
              <div class="ml-item">
                <img src="../assets/images/member-img.png" class="member-img">
                <span>Robert Gehl</span>
                <button class="btn btn-white">Remove</button>
              </div>
            </li>
            <li>
              <div class="ml-item">
                <img src="../assets/images/member-img.png" class="member-img">
                <span>Alvin Richards</span>
                <button class="btn btn-white">Remove</button>
              </div>
            </li>
          </ul>
      </div>
    </b-modal> -->
    <!-- Add Member Modal End -->

  </div>
</template>

    <script>
    import firebase from '../../views/firebaseInit'
    import firebaseData from '../../utils/firebaseCollection.js';

    const db = firebase.firestore();
    export default {
      name: 'adminheader',
      components: {
          firebaseData,
      },
      // components:{
      //   FrontFooter
      // },
      data(){
          return{
            preview: null,
            image: null,
            preview_list: [],
            image_list: [],
            adminInfo : {}
          }
      },
      created(){
        //debugger // eslint-disable-line
        var self = this;
        var adminkey = localStorage.getItem("SuperAdminKey");
        db.collection(firebaseData.firebasecollection.USER).doc(adminkey).onSnapshot(snap => {
          if(snap.exists){
            self.adminInfo = snap.data();
          }
          // console.log('adminInfo',self.adminInfo)
        })
      },
      methods: {
        previewImage: function(event) {
          var input = event.target;
          if (input.files) {
            var reader = new FileReader();
            reader.onload = (e) => {
              this.preview = e.target.result;
            }
            this.image=input.files[0];
            reader.readAsDataURL(input.files[0]);
          }
        },
        previewMultiImage: function(event) {
          var input = event.target;
          var count = input.files.length;
          var index = 0;
          if (input.files) {
            while(count --) {
              var reader = new FileReader();
              reader.onload = (e) => {
                this.preview_list.push(e.target.result);
              }
              this.image_list.push(input.files[index]);
              reader.readAsDataURL(input.files[index]);
              index ++;
            }
          }
        },
        reset: function() {
          this.image = null;
          this.preview = null;
          this.image_list = [];
          this.preview_list = [];
        },
        LogoutAdmin(){
          firebase.auth().signOut()
                .then(()=>{
                    localStorage.removeItem("SuperAdminKey")
                    localStorage.removeItem("IsLoginUser");
                    localStorage.removeItem("AdminMenu");
                    if(localStorage.getItem('StayAdminLoggedIn')=='false')
                    {
                        localStorage.removeItem("rememberMe")
                    }
                    this.$root.$emit('updateUserFooter')
                    this.$notify({
                        title:"Success",
                        text:' logout successfully.',
                        type:"success"
                    })
                    this.$router.push({'name': 'adminsignin'});
                })
                .catch(error=>{
                    console.log(error)
                })
        },
        gotoAdminProfile(){
          var self=this;
          //debugger // eslint-disable-line
          self.$root.$emit('openDashboard',6);
        }
      }
    }
    </script>