<template>
<div>
  <Header v-if="isAdmin == false" :imageUrl= userData :chatObject= chatObject :activeGroup = activeGroup :activeChat= activeChat :groupData="groupData"></Header>
    <section class="profile-wrapper front_myprofile">
      <div class="container-fluid">
            <div class="row mb-4">
              <div class="col-md-12">
                 <div class="backbtn-wrapper">
                  <router-link to="/chat"><button class="btn btn-white btn-back"><img src="@/assets/images/tag-arrow.png" class="back-arrow"> Back To Chat</button></router-link>
                 </div>
              </div>
            </div>
            
             <!-- <input type="file" accept=".jpg, .jpeg, .png" @change="uploadImages($event)" multiple> -->
            <div class="row">
                <div class="col-md-2">
                    <div class="profile-email-wrapper">
                        <div class="view-detail-content">
                       <div class="photoupload-wrapper">
                        
                            <img v-if="preview" :src="preview" class="img-fluid" />
                            <img v-else :src="image" />
                            <label for="myprofilesetting" class="myprofilesetting" title="Edit profile">
                               <input type="file" accept="image/*" @change="uploadImages" class="form-control-file" id="myprofilesetting"/>
                            </label>
                        </div>
                        <h4>{{user.firstName}} {{user.lastName}}</h4>
                        <p>{{user.email}}</p>
                        <span class="InvalidInput" v-if="profileErrorMessage">{{profileErrorMessage}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-10">
                  <div class="myprofile-form_wrapper">
                    <h4>My Profile</h4>
                    <ValidationObserver ref="log" v-slot="{ handleSubmit }">
                    <form class="myprofile-form" @submit.prevent="handleSubmit(myProfile)">
                        <div class="row">
                        <div class="col-5 col-md-6">

                          <div class="row">
                            <div class="col-sm-6">
                              <ValidationProvider rules="required|min:3" name="first name" v-slot="{ errors }">
                               <div class="from-group  mb-5">
                                <!-- <label>First Name *</label> -->
                                <input type="text" value="Manuel" class="form-control" maxlength="21" placeholder="First name*" v-model="updatedUser.firstName"  @keyup="checkChrecterLengthFirstname(updatedUser.firstName)">
                                 <span class="InvalidInput">{{ errors[0]=='The first name field must be at least 3 characters'?'minimum 3 character is required.':errors[0] }}</span>
                                <span v-if="charecterErrorMessageForFirstname != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForFirstname}}</span>
                               </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-sm-6">
                              <ValidationProvider rules="required|min:3" name="last name" v-slot="{ errors }">
                               <div class="from-group mb-5">
                                <!-- <label>Last Name *</label> -->
                                <input type="text" value="Storie" class="form-control" maxlength="21" placeholder="Last name*" v-model="updatedUser.lastName" @keyup="checkChrecterLengthLastname(updatedUser.lastName)">
                                <span class="InvalidInput">{{ errors[0]=='The last name field must be at least 3 characters'?'minimum 3 character is required.':errors[0] }}</span>
                                <span v-if="charecterErrorMessageForLastname != '' && errors.length == 0" class="InvalidInput">{{charecterErrorMessageForLastname}}</span>

                              </div>
                              </ValidationProvider>
                          </div>  
                          </div>  
                          <div class="row">
                            <div class="col-sm-6">
                              <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
                              <div class="form-group mb-5" style="position:relative;">
                                <!-- <label>Email * <img  class="" style="position:relative;top:-3px;width:15px;" src="@/assets/images/Approved.png"/></label> -->
                                <input type="email" value="manuelstorie@gmail.com" placeholder="Enter email*" class="form-control" v-model="updatedUser.email" disabled ><img  class="" style="position: absolute;top: 8px;width: 15px;right: 0;" src="@/assets/images/Approved.png"/>
                                <span class="InvalidInput">{{ errors[0] }}</span>

                              </div>
                               </ValidationProvider>
                            </div>
                            <div class="col-sm-6">
                              <!-- <ValidationProvider  name="phone number" v-slot="{ errors }"> -->
                              <div class="form-group mb-5 myprofile_phoneno">
                               
                                <!-- <label>Phone Number *  <img  class="" style="position:relative;top:-3px;width:15px;" src="@/assets/images/Approved.png" v-if="isUpdate==false"/> </label> -->
                                <!-- <input type="text" placeholder="Enter phone number" class="form-control" maxlength="15" v-model="updatedUser.phoneNo" @keypress="isCharAt($event)"> -->
                                <VuePhoneNumberInput v-model="updatedUser.phoneNo" :default-country-code="updatedUser.countryFlag"  @update="checkPhoneNumber"  :maxlength = "15" :disabled="showOtp==false || isUpdate==false || disabledPhone" style="position:relative;"/><img  class="" style="position: absolute;top: 8px;width: 15px;right: -32px;" src="@/assets/images/Approved.png" v-if="isUpdate==false"  />
                                <span class="InvalidInput" v-if="phoneerror != '' ">{{phoneerror}}</span>
                                <span style="color:red;" v-if="phonenumberErrorMessage!= '' ">{{phonenumberErrorMessage}}</span>

                                <button class="resend_otp" type="button" v-if="isUpdate==false"  @click="isUpdate = true">Update</button>

                                <button class="resend_otp" ref="ref_otpbutton" type="button"  @click="SendOtp()" :disabled="phoneerror != '' || phonenumberErrorMessage != '' " v-if="resendCode == false && resendTimer == 0 && showOtp == true && isUpdate == true">Get OTP</button>
                                <button class="resend_otp" type="button"  @click="SendOtp()" v-if="resendCode == true && resendTimer == 0 && showOtp == true && isUpdate == true" >Resend Code</button>
                                <span class="resend_otp" v-if="resendTimer!=0  && showOtp == true && isUpdate == true">{{resendTimer}}</span>
                                <!-- <img  class="resend_otp" src="@/assets/images/Approved.png" v-if="showOtp == false && isUpdate == true"/> -->
                                <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                              </div>
                               <!-- </ValidationProvider> -->
                            </div>
                            <div class="col-md-8 my_profile_otp">
                              <ValidationProvider  name="otpcode" v-slot="{ errors }">
                              <div v-if="showVerifyDigitScreen == true && showOtp == true">
                                <div class="title_verification" ><span>Verification code Must be 6 digits</span></div>
                                  <div class="otp-wrapper" >
                                    <v-otp-input
                                    ref="otpInput"
                                    v-model="otpinput"
                                    input-classes="otp-input"
                                    separator=""
                                    :num-inputs="6"
                                    :should-auto-focus="true"
                                    :is-input-num="true"
                                    @on-change="handleOnChange"
                                    @on-complete="handleOnComplete"
                                    />
                                  <span class="InvalidInput">{{ errors[0] }}</span>
                                  <span class="InvalidInput" v-if="otpError!= '' ">{{otpError}}</span>
                                    <!-- <button @click="handleClearInput()">Clear Input</button> -->
                                  </div>
                                  <!-- <span class="verify_text" role="button">Verify Now</span> -->
                              </div>
                            </ValidationProvider>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-6">
                              <ValidationProvider rules="" name="business name" v-slot="{ errors }">
                               <div class="form-group mb-5">
                                  <!-- <label>Business Name</label> -->
                                  <input type="text" placeholder="Enter business name" maxlength="51" class="form-control" v-model="updatedUser.BusinessName" @keyup="checkChrecterLengthBusinessname(updatedUser.BusinessName)">
                                  <span class="InvalidInput">{{ errors[0] }}</span>
                                  <span v-if="charecterErrorMessageForBusinessName != ''" class="InvalidInput">{{charecterErrorMessageForBusinessName}}</span>

                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-sm-6">
                               <ValidationProvider rules="" name="job title" v-slot="{ errors }">
                                <div class="form-group mb-5">
                                  <!-- <label>job title</label> -->
                                  <input type="text" placeholder="Enter job title" maxlength="51" class="form-control" v-model="updatedUser.jobTitle" @keyup="checkChrecterLengthJobTitle(updatedUser.jobTitle)">
                                  <span class="InvalidInput">{{ errors[0] }}</span>
                                <span v-if="charecterErrorMessageForJobTitle != ''" class="InvalidInput">{{charecterErrorMessageForJobTitle}}</span>
                                </div>
                                </ValidationProvider>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <ValidationProvider rules="" name="bio" v-slot="{ errors }">
                              <div class="form-group mb-2">
                                  <!-- <label>Bio</label> -->
                                  <textarea rows="3" class="form-control" placeholder="Enter bio" maxlength="151" v-model="updatedUser.BioDiscription" @keyup="checkChrecterLengthBio(updatedUser.BioDiscription)"></textarea>
                                  <span class="InvalidInput">{{ errors[0] }}</span>
                                <span v-if="charecterErrorMessageForBio != ''" class="InvalidInput">{{charecterErrorMessageForBio}}</span>
                              </div>
                              </ValidationProvider>
                                <div class="btn-wrapper">
                                  <button class="btn btn-white btn-cancel" type="button" @click.prevent="resetForm()">Cancel</button>
                                  
                                  <!-- <b-button type="button" variant="primary" class="btn btn-save" disabled v-if="isSpinner">
                                    Loading....
                                  </b-button> -->
                                  <!-- {{updatedUser.firstName}}
                                  {{user.firstName}} -->
                               
                                 <button class="btn btn-yellow"  v-if="businessMenuActive == false" v-bind:class="{disabled: showOtp && isUpdate || checkChangeInput || isSpinner || profileErrorMessage }" >Save</button>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-7"></div>
                      </div>
                    </form>
                    </ValidationObserver>
                  </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import Header from "../components/header.vue";
import firebaseData from '../utils/firebaseCollection.js';
import firebase from "../views/firebaseInit"
import Vue from "vue";
// import { VueTelInput } from 'vue-tel-input';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {getLoginUserData,getAllPhoneArray} from "@/utils/getQueeryActiveChatList.js";
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
export default {
  name: "myprofile",
  components: {
    Header,
    firebaseData,
    VuePhoneNumberInput
  },
   data() {
    return {
      isSpinner: false,
      preview: null,
      image: null,
      preview_list: [],
      image_list: [],
      user:{},
      updatedUser:{},
      image:"",
      imageURL:"",
      jobTitle:'',
      BusinessName:'',
      userid:'',
      imageUrl:'',
      userData:{},
      chatObject:{
        firstName:'',
        lastName:'',
        profileUrl:'',
        userid:''
      },
      groupData:{},
      activeGroup:false,
      isAdmin:false,
      loginUser:'',
      activeChat:false,
      businessMenuActive:false,
      phoneerror:'',
      phoneValid : true ,
      phonenumber:'',
      mainNumber:'',
      errorphone:'',
      showtooitip:false,
      showVerifyDigitScreen : false,
      resendCode:false,
      resendTimer:0,
      otpinput : '',
      otpvalue : '',
      getData:{},
      showOtp : true,
      otpError : '',
      isUpdate:false,
      phonenumberArray : [],
      phonenumberErrorMessage:'',
      countryflag :'',
      disabledPhone : false,
      countrycallingCode :'',
      profileErrorMessage:'',
      charecterErrorMessageForFirstname:'',
      charecterErrorMessageForLastname:'',
      charecterErrorMessageForBusinessName:'',
      charecterErrorMessageForJobTitle:'',
      charecterErrorMessageForBio:'',
      routeid:this.$route.params.id
      

    };
  },
  created()
  {
      var self = this;
      self.phonenumberArray = []
       const db = firebase.firestore()
      self.getUserData();
     
     
      self.$root.$on('setBusinessMenu',(value) => {
          self.businessMenuActive = value
      })
       // GET PROFILE PICTURE (Shraddha)
      self.userid = localStorage.getItem('customerDocKey');
      getLoginUserData(firebaseData.firebasecollection.USER,(res) => {
        self.userData = res;
        self.imageUrl = res.profileUrl;
      })
    

      // CHECK LOGIN USER IS ADMIN OR CUSTOMER(Shraddha)
      self.loginUser = localStorage.getItem("IsLoginUser")
      //console.log('loginUser',self.loginUser);
      if(this.loginUser == 'Admin'){
        self.isAdmin = true
      }else{
        self.isAdmin = false
      }
      //console.log(this.isAdmin)
      getAllPhoneArray(firebaseData.firebasecollection.USER,(res) => {
        self.phonenumberArray=res;
      })
     

  },
  methods: {
    uploadImages(event) {
    var self = this;
    let image_id = Math.floor(Math.random() * (1000000 - 1000 + 1) + 1000);
    let name = "image_" + image_id;
    let imageData = null;
    let context = null;
    let maxWidth = 1024;
    let maxHeigt = 768;
    let width = maxWidth;
    let height = maxHeigt;
    let vm = this;
    let files = event.target.files;
    vm.imageIndex = 0;

    let formData = new FormData();
   
        let reader = new FileReader();
        let file = files[0];
        
        if (!file.type.match('image') || file.type.match('gif')){
            self.preview = self.updatedUser.profileUrl;
            self.profileErrorMessage = 'Only .jpg,.png,.jpeg files are allowed.';
        }else{
          self.profileErrorMessage = '';
          if (file) {
              var img = document.createElement("img");
              var canvas = $("<canvas/>").get(0);
              reader.onload = function (e) {
                  img.src = e.target.result;

              };
              reader.readAsDataURL(file);
              img.onload = function () {
                  // Draw
                  context = canvas.getContext('2d');
                  context.drawImage(img, 0, 0);
                  if (img.width > maxWidth || img.height > maxHeigt) {
                      width = maxWidth;
                      height = maxHeigt;

                      if (img.width > maxWidth) {
                          width = maxWidth;
                          var ration = maxWidth / img.width;
                          height = Math.round(img.height * ration);
                      }

                      if (img.height > maxHeigt) {
                          height = maxHeigt;
                          var ration = maxHeigt / img.height;
                          width = Math.round(img.width * ration);
                      }
                  } else {
                      width = img.width;
                      height = img.height;
                  }
                  canvas.width = width;
                  canvas.height = height;

                  context.drawImage(img, 0, 0, width, height);
                  imageData = canvas.toDataURL('image/jpeg');
                  imageData.replace('data:image/jpeg;base64,', '');

                  let blobToFile = vm.dataURItoBlob(imageData);
                  const cfile = new File([blobToFile], name + ".jpg",
                      {type: "image/jpeg", lastModified: Date.now()});
                  formData.append('ad_image', cfile);
                    self.preview  = img.src;
                    self.image = cfile
                    self.profileErrorMessage = ''
                    console.log('previewpreviewpreview',cfile)
              };
          }
        }
    
    },

    dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        var byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to an ArrayBuffer
        var arrayBuffer = new ArrayBuffer(byteString.length);
        var _ia = new Uint8Array(arrayBuffer);
        for (var i = 0; i < byteString.length; i++) {
            _ia[i] = byteString.charCodeAt(i);
        }
        var dataView = new DataView(arrayBuffer);
        var blob = new Blob([dataView], {type: mimeString});
        return blob;
    },
    previewImage: function (event) {
      //  debugger // eslint-disable-line
      this.profileErrorMessage = '';
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.image = input.files[0];
          var docExtention = this.image.type.split('/');
          if(docExtention[0] != 'image' || docExtention[1] == 'gif'){
            this.preview = this.updatedUser.profileUrl;
            this.profileErrorMessage = 'Only .jpg,.png,.jpeg files are allowed.';
          }else{
            this.preview = e.target.result
            this.profileErrorMessage = '';
          }
        };
        this.image = input.files[0];
        
        reader.readAsDataURL(input.files[0]);
          
      }
    },
    previewMultiImage: function (event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          this.image_list.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
      }
    },
    reset: function () {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    },
    
    checkPhoneNumber(phone)
    {
      // debugger // eslint-disable-line
      // console.log(phone)
      if(this.isUpdate == true){
        let isInArray = this.phonenumberArray.some(function(item) {
          return item.phoneNo == phone.nationalNumber && !item.isDeleted
        });
        if(isInArray == true ){
          this.$refs.log.reset();
          this.phoneerror = ''
          this.phonenumberErrorMessage = 'Phone number is already exists'
        }else{
          this.phonenumberErrorMessage = ''
          this.mainNumber = phone.formattedNumber;
          this.updatedUser.phoneNo = phone.nationalNumber;
          this.countryflag = phone.countryCode;
          this.phoneValid = phone.isValid;
          this.countrycallingCode = '+'+phone.countryCallingCode
            // console.log("number,phone",this.updatedUser.phoneNo, this.phoneValid);
              if(this.phoneValid == false  && this.updatedUser.phoneNo != ''){
                if(this.updatedUser.phoneNo != undefined){
                  this.phoneerror = 'please enter valid phone number'
                }
              }else{
                this.phoneerror = ''
                this.$nextTick(() => {
                    this.$refs.ref_otpbutton.focus();
                })
              }
          }
        
      }
    },
    SendOtp(){
      // debugger // eslint-disable-line
      var self=this;
      var userData = `${self.updatedUser.firstName} ${self.updatedUser.lastName}`;
      
      self.phonenumber = self.updatedUser.phoneNo.replace(' ', '');
       
      if(self.phonenumber.slice(0,1) == 0){
          self.phonenumber= self.phonenumber.substring(1);
      }
      var after_concate_phone_no = self.countrycallingCode+' '+self.phonenumber
      self.disabledPhone = true;
        if(self.phoneValid && self.updatedUser.phoneNo != '' && self.phonenumberErrorMessage == ''){
          if(self.updatedUser.phoneNo.replace(/\s/g, '') == self.user.phoneNo.replace(/\s/g, '')){
            self.phoneerror = 'This is the same phone number you used previously.';
            self.disabledPhone = false;
          }else{
            self.phoneerror = '';
            self.randomeCode = Math.floor(Math.random() * 99999) + 100000;
            localStorage.removeItem('signupData');
            localStorage.setItem('signupData',JSON.stringify({phoneNumber:self.mainNumber,otp:self.randomeCode}))
            // console.log(self.randomeCode,after_concate_phone_no)
            self.$axios.post(self.$apiurl+'sendOtpOnMObile',{phoneNumber:after_concate_phone_no,otp:self.randomeCode,userName:userData})
            .then((res)=>{
              // console.log('resresres',res)
              self.$root.$emit("audioplay");
              self.$notify({
                    title:"Success",
                    text:'Verification SMS sent.',
                    type:"success",
                    duration: 10000,
              })
              if(res.data.status == true){
                self.showVerifyDigitScreen = true
                self.resentLinkTimer();
              }else{
                self.$root.$emit("audioplay");
                self.$notify({
                    title:"Error",
                    text:res.data.error,
                    type:"error",
                    duration: 10000,
                })
              }
            })
          }
        }else{
          self.phoneerror = 'please enter valid phone number'
        }
      
    },
    resentLinkTimer()
        {
            var self = this
            if(self.resendTimer==0)
            {
                self.resendTimer=120
                
                var interval = setInterval(()=>{
                    if(self.resendTimer>0)
                    {
                        self.resendTimer--
                    }
                    if(self.resendTimer==0)
                    {

                        self.resendTimer=0
                        clearInterval(interval)
                        self.resendCode = true
                        self.otpError = ''
                        localStorage.removeItem('signupData');
                       
                    }
                },1000)
            }
    },
    handleOnComplete(value) {
        // debugger // eslint-disable-line
        var self =this;
        self.otpvalue = value
        var signupdata= localStorage.getItem("signupData");
        self.getData = JSON.parse(signupdata)
        // console.log('self.getData',self.getData)
        if(self.getData &&  self.getData.phoneNumber == self.mainNumber && self.getData.otp == self.otpvalue){
          self.updatedUser.phoneNo = self.mainNumber
          self.showOtp = false
          self.isUpdate = false
          self.otpError = ''
        }else{
          self.otpError = 'Your otp is incorrect.'
          setTimeout(() => {
            self.handleClearInput();
          }, 100)
        }
      },
    handleOnChange(value) {
      //console.log('OTP changed: ', value);
    },
    handleClearInput() {
       this.$refs.otpInput.clearInput();
    },
      //CHECK GROUP NAME CHRECTER LENGTH(Shraddha)
    checkChrecterLengthFirstname(groupName){
        debugger // eslint-disable-line
        var self = this;
        self.updatedUser.firstName=groupName.replace(/[^A-Za-z]/ig,'')
        if(groupName.length < 21){
            self.updatedUser.firstName=groupName.replace(/[^A-Za-z]/ig,'')
            self.charecterErrorMessageForFirstname = '';
        }else{
            self.charecterErrorMessageForFirstname = 'The first name may not be greater than 20 characters';
            self.updatedUser.firstName = (groupName).slice(0, 20)
        }
    },
    checkChrecterLengthLastname(groupName){
        // debugger // eslint-disable-line
        var self = this;
        self.updatedUser.lastName=groupName.replace(/[^A-Za-z]/ig,'')
        if(groupName.length < 21){
          self.updatedUser.lastName=groupName.replace(/[^A-Za-z]/ig,'')
          self.charecterErrorMessageForLastname = '';
        }else{
          self.charecterErrorMessageForLastname = 'The last name may not be greater than 20 characters';
          self.updatedUser.lastName = (groupName).slice(0, 20)
        }
    },
    checkChrecterLengthBusinessname(groupName){
        // debugger // eslint-disable-line
        var self = this;
        self.updatedUser.BusinessName=groupName.replace(/[^A-Za-z]/ig,'')
        if(groupName.length < 51){
          self.updatedUser.BusinessName=groupName.replace(/[^A-Za-z]/ig,'')
          self.charecterErrorMessageForBusinessName = '';
        }else{
          self.charecterErrorMessageForBusinessName = 'The business name may not be greater than 50 characters';
          self.updatedUser.BusinessName = (groupName).slice(0, 50)
        }
        
    },
    checkChrecterLengthJobTitle(groupName){
        // debugger // eslint-disable-line
        var self = this;
        self.updatedUser.jobTitle=groupName.replace(/[^A-Za-z]/ig,'')
        if(groupName.length < 51){
          self.updatedUser.jobTitle=groupName.replace(/[^A-Za-z]/ig,'')
          self.charecterErrorMessageForJobTitle = '';
        }else{
          self.charecterErrorMessageForJobTitle = 'The jobtitle may not be greater than 50 characters';
          self.updatedUser.jobTitle = (groupName).slice(0, 50)
        }
    },
    checkChrecterLengthBio(groupName){
        // debugger // eslint-disable-line
        var self = this;
        self.updatedUser.BioDiscription=groupName.replace(/[^A-Za-z]/ig,'')
        if(groupName.length < 151){
          self.updatedUser.BioDiscription=groupName.replace(/[^A-Za-z]/ig,'')
          self.charecterErrorMessageForBio = '';
        }else{
          self.charecterErrorMessageForBio = 'The bio discription may not be greater than 150 characters';
          self.updatedUser.BioDiscription = (groupName).slice(0, 150)
        }
    },
    //UPDATE CUSTOMER DATA IN DATABASE COLLECTION(Shraddha )
    myProfile(){
      // debugger // eslint-disable-line
            var self = this
            if(self.phonenumberErrorMessage == '' && self.isUpdate == false && self.charecterErrorMessageForFirstname == '' && self.charecterErrorMessageForLastname == '' && self.charecterErrorMessageForBusinessName == '' && self.charecterErrorMessageForJobTitle == '' && self.charecterErrorMessageForBio == ''){
              self.isSpinner=true;
              const db = firebase.firestore()
              //console.log('image',self.image)
              if(self.updatedUser.email==self.user.email)
              {
                  if(self.image.name!=null)
                  {
                    var ImageNameSplit = self.image.name.split('.')
                      var str = (
                          Math.random().toString(36).substring(2, 8) +
                          Math.random().toString(36).substring(2, 8)
                      ).toUpperCase();
                      var ImageName=""
                      if(ImageNameSplit.length>2)
                      {
                          //console.log("if",ImageNameSplit)
                          for (let index = 0; index < ImageNameSplit.length-1; index++) {
                              ImageName += `${ImageNameSplit[index]}`
                          }
                          ImageName+=`_${str}.${ImageNameSplit[ImageNameSplit.length-1]}`
                      }
                      else
                      {
                          ImageName=`${ImageNameSplit[0]}_${str}.${ImageNameSplit[1]}`
                      }

                      if(self.imageURL==Vue.prototype.$defaultProfile)
                      {
                          var storageRef = firebase.storage().ref()
                          var uploadTask = storageRef.child(firebaseData.firebasecollection.PROFILEIMAGES+ImageName).put(self.image)

                          uploadTask.on('stateChanged',
                          (snapshot)=>{
                              var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
                              //console.log(progress)
                          },
                          (error)=>{
                              self.isSpinner=false;
                              // self.$root.$emit("audioplay");// 
                              // self.$notify({
                              //     title:"Error",
                              //     textL:error.message,
                              //     type:'error'
                              // })
                          },
                          ()=>{
                              uploadTask.snapshot.ref.getDownloadURL()
                              .then(URL=>{
                                  self.image=URL
                                  self.$axios.post(self.$WPbaseurl+'wp-json/user/profile-picture',{wp_user_id:self.userData.wp_id,profile_url:URL})
                                  .then((res)=>{
                                    // console.log(res)
                                  })
                                  //Upload ProfileImage in Firestore Doc
                                  db
                                      .collection(firebaseData.firebasecollection.USER)
                                      .doc(self.$route.params.id)
                                      .update({
                                          "firstName":self.updatedUser.firstName,
                                          "lastName":self.updatedUser.lastName,
                                          "email":self.updatedUser.email,
                                          "phoneNo":self.updatedUser.phoneNo.replace(/\s/g, ''),
                                          "BioDiscription":self.updatedUser.BioDiscription,
                                          "profileUrl":URL,
                                          "BusinessName":self.updatedUser.BusinessName,
                                          "jobTitle":self.updatedUser.jobTitle,
                                          "countryFlag" : self.countryflag ? self.countryflag : self.updatedUser.countryFlag,
                                          "countryCode" : self.countrycallingCode ? self.countrycallingCode : self.updatedUser.countryCode,
                                      })
                                      .then(()=>{
                                        self.isSpinner=false;
                                        self.preview = null;
                                          self.$root.$emit("audioplay");
                                          self.$notify({
                                              title:"Success",
                                              text:'profile updated successfully.',
                                              type:"success"
                                          })
                                          self.getUserData()
                                      })
                                      .catch(error=>{
                                        self.isSpinner=false;
                                        
                                          // self.$root.$emit("audioplay");// 
                                          // self.$notify({
                                          //     title:"Error",
                                          //     textL:error.message,
                                          //     type:'error'
                                          // })
                                      })
                              })
                          })
                      }
                      else
                      {
                          var storage = firebase.storage().ref()

                              var uploadTask = storage.child(firebaseData.firebasecollection.PROFILEIMAGES+ImageName).put(self.image)

                              uploadTask.on('stateChanged',
                              (snapshot)=>{
                                  var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
                                  //console.log(progress)
                              },
                              (error)=>{
                                  self.isSpinner=false;
                                  
                                  self.$root.$emit("audioplay");
                                  self.$notify({
                                      title:"Error",
                                      textL:error.message,
                                      type:'error'
                                  })
                              },
                              ()=>{
                                  uploadTask.snapshot.ref.getDownloadURL()
                                  .then(URL=>{
                                      self.image=URL
                                      self.$axios.post(self.$WPbaseurl+'wp-json/user/profile-picture',{wp_user_id:self.userData.wp_id,profile_url:URL})
                                      .then((res)=>{
                                        // console.log(res)
                                      })
                                      //Upload ProfileImage in Firestore Doc
                                      db
                                          .collection(firebaseData.firebasecollection.USER)
                                          .doc(self.$route.params.id)
                                          .update({
                                              "firstName":self.updatedUser.firstName,
                                              "lastName":self.updatedUser.lastName,
                                              "email":self.updatedUser.email,
                                              "phoneNo":self.updatedUser.phoneNo.replace(/\s/g, ''),
                                              "BioDiscription":self.updatedUser.BioDiscription,
                                              "profileUrl":URL,
                                              "BusinessName":self.updatedUser.BusinessName,
                                              "jobTitle":self.updatedUser.jobTitle,
                                              "countryFlag" : self.countryflag ? self.countryflag : self.updatedUser.countryFlag,
                                              "countryCode" : self.countrycallingCode ? self.countrycallingCode : self.updatedUser.countryCode,


                                          })
                                          .then(()=>{
                                              self.isSpinner=false;
                                              self.preview = null;
                                              self.$root.$emit("audioplay");
                                              self.$notify({
                                                  title:"Success",
                                                  text:'profile updated successfully.',
                                                  type:"success"
                                              })
                                              self.getUserData()
                                          })
                                          .catch(error=>{
                                              self.isSpinner=false;
                                              // self.$root.$emit("audioplay");// 
                                              // self.$notify({
                                              //     title:"Error",
                                              //     textL:error.message,
                                              //     type:'error'
                                              // })
                                          })
                                  })
                              })
                      }
                  }
                  else
                  {
                      db
                      .collection(firebaseData.firebasecollection.USER)
                      .doc(self.$route.params.id)
                      .update({
                          "firstName":self.updatedUser.firstName,
                          "lastName":self.updatedUser.lastName,
                          "email":self.updatedUser.email,
                          "phoneNo":self.updatedUser.phoneNo.replace(/\s/g, ''),
                          "BioDiscription":self.updatedUser.BioDiscription,
                          "profileUrl":self.updatedUser.profileUrl,
                          "BusinessName":self.updatedUser.BusinessName,
                          "jobTitle":self.updatedUser.jobTitle,
                          "countryFlag" : self.countryflag ? self.countryflag : self.updatedUser.countryFlag,
                          "countryCode" : self.countrycallingCode ? self.countrycallingCode : self.updatedUser.countryCode,

                      })
                      .then(()=>{
                          self.isSpinner=false;
                          self.preview = null;
                          self.$root.$emit("audioplay");
                          self.$notify({
                              title:"Success",
                              text:'profile updated successfully.',
                              type:"success"
                          })           
                          self.getUserData()
                      })
                      .catch(error=>{
                          self.isSpinner=false;
                          // self.$root.$emit("audioplay");// 
                          // self.$notify({
                          //     title:"Error",
                          //     textL:error.message,
                          //     type:'error'
                          // })
                      })
                  }
              }
              else
              {
                  var user = firebase.auth().currentUser;
                  //console.log(user)

                  user.updateEmail(self.updatedUser.email)
                  .then(()=>{
                      db
                          .collection(firebaseData.firebasecollection.USER)
                          .doc(self.$route.params.id)
                          .update({
                              "firstName":self.updatedUser.firstName,
                              "lastName":self.updatedUser.lastName,
                              "email":self.updatedUser.email,
                              "phoneNo":self.updatedUser.phoneNo.replace(/\s/g, ''),
                              "BioDiscription":self.updatedUser.BioDiscription,
                              "BusinessName":self.updatedUser.BusinessName,
                              "jobTitle":self.updatedUser.jobTitle,
                              "countryFlag" : self.countryflag ? self.countryflag : self.updatedUser.countryFlag,
                              "countryCode" : self.countrycallingCode ? self.countrycallingCode : self.updatedUser.countryCode,

                          })
                          .then(()=>{
                              self.isSpinner=false;
                              self.preview = null;
                              self.$root.$emit("audioplay");
                              self.$notify({
                                  title:"Success",
                                  text:'profile updated successfully.',
                                  type:"success"
                              })
                              self.getUserData()
                          })
                          .catch(error=>{
                              self.isSpinner=false;
                              // self.$root.$emit("audioplay");// 
                              // self.$notify({
                              //     title:"Error",
                              //     textL:error.message,
                              //     type:'error'
                              // })
                          })
                  })
                  .catch(error=>{
                      self.isSpinner=false;
                      // self.$root.$emit("audioplay");// 
                      // self.$notify({
                      //     title:"ERROR",
                      //     text:error.message,
                      //     type:"error"
                      // })
                  })
              }
            }

    },
    //GET CUSTOMER DATA OF LOGIN CUSTOMER(Shraddha)
    getUserData(){
      // debugger // eslint-disable-line
      var self = this;
      const db = firebase.firestore()
      // console.log('self.$route.params.id',self.$route.params.id)
      db.collection(firebaseData.firebasecollection.USER).where('id','==',self.$route.params.id).onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc=>{
          self.user = doc.data()
          self.userData = doc.data()
          self.updatedUser = doc.data()
          self.image=doc.data().profileUrl
          self.imageURL=doc.data().profileUrl
        })
      })
      // .catch(error=>{
      // self.$root.$emit("audioplay");//     
      // self.$notify({
      //         title:"Error",
      //         textL:error.message,
      //         type:'error'
      //     })
      // })
    },
    // ONLY ENTER NUMBER(PHONE NUMBER) (Shraddha)
    isCharAt(evt)
    {
      // //debugger // eslint-disable-line
      const char = String.fromCharCode(evt.keyCode);
        if (!/[0-9 ||.]/.test(char)) {
            evt.preventDefault();
        }
    },
    //RESET FORM WHEN CANCEL BUTTON CLICK(Shraddha)
    resetForm(){
      // //debugger // eslint-disable-line
      var self=this;
      self.isSpinner=false;
      self.updatedUser.firstName = self.user.firstName
      self.updatedUser.lastName = self.user.lastName
      self.updatedUser.phoneNo = self.user.phoneNo
      self.updatedUser.BioDiscription = self.user.BioDiscription
      self.updatedUser.BusinessName = self.user.BusinessName
      self.updatedUser.jobTitle = self.user.jobTitle
      self.preview = self.user.profileUrl
      self.showVerifyDigitScreen = false ;
      self.showOtp = false;
      self.resendCode = false;
      self.disabledPhone = false;
      self.isUpdate = false;
      self.charecterErrorMessageForFirstname='',
      self.charecterErrorMessageForLastname='',
      self.charecterErrorMessageForBusinessName='',
      self.charecterErrorMessageForJobTitle='',
      self.charecterErrorMessageForBio=''

    }
  },
  computed:{
      // CHECK IF CHANGES IN INPUT FEILD OR NOT (Shraddha)
    checkChangeInput(){
      // debugger // eslint-disable-line
      var self = this;
      const db = firebase.firestore()
      var disablereturn = true
      if(Object.keys(self.updatedUser).length != 0 && Object.keys(self.user).length != 0){
        if(self.updatedUser.firstName == self.user.firstName && self.updatedUser.lastName == self.user.lastName && self.updatedUser.phoneNo.replace(/\s/g, '') == self.user.phoneNo && self.updatedUser.BusinessName == self.user.BusinessName && self.updatedUser.jobTitle == self.user.jobTitle && self.updatedUser.BioDiscription == self.user.BioDiscription && self.image == self.user.profileUrl){
            return disablereturn 
        }else{
          return  !disablereturn 
        }
      }else{
        return  !disablereturn 
      }
    },
  }
};
</script>