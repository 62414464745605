<template>
<div>
  <Header v-if="isAdmin == false" :imageUrl= userData :chatObject= chatObject :activeGroup = activeGroup :activeChat= activeChat :groupData="groupData"></Header>
    <section class="profile-wrapper user_profile">
        <div class="container-fluid">
            <div class="row">
                <div class="backbtn-wrapper">
                   <button class="btn btn-white btn-back" @click="backtoContact('contact')"><img src="@/assets/images/tag-arrow.png" class="back-arrow"> Back To Chat</button>
                </div>
                <div class="col-md-2">
                    <div class="profile-email-wrapper">
                       <img :src="customerData.profileUrl" class="useprofile_pic">
                    </div>
                </div>
                <div class="col-md-10">
                  <div class="myprofile-form_wrapper">
                    <div class="user-profile-title">
                        <h4 style="position:relative;">{{customerData.firstName}} {{customerData.lastName}}<div v-if="customerData.isOnline" class="greenDots" style="height:8px;width: 8px;margin-top: 7px;"></div></h4>
                        <button class="btn btn-blue" v-if="userData.companiesOwned != undefined" v-b-modal.modalCompany>Send Business Contact Request</button>
                        <button class="btn btn-blue" v-else  @click="sendBusinessContactRequest()">Send Business Contact Request</button>

                    </div>

                    <div class="row">
                        <div class="col-sm-3">
                            <div class="user-data">
                                <b>Email</b>
                                <p><a href="mailto:">{{customerData.email}}</a></p>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="user-data">
                                <b>Phone</b>
                               <p><a :href="'tel:'+customerData.phoneNo">{{customerData.phoneNo}}</a></p>
                                
                            </div>
                        </div>
                         <div class="col-sm-3" >
                            <div class="user-data">
                                <b>Business Name</b>
                                <p v-if="customerData.BusinessName">{{customerData.BusinessName}}</p>
                                <p v-else>-</p>
                            </div>
                        </div>
                         <div class="col-sm-3" >
                            <div class="user-data">
                                <b>Job title</b>
                                <p v-if="customerData.jobTitle">{{customerData.jobTitle}}</p>
                                <p v-else>-</p>
                            </div>
                        </div>
                        <div class="col-sm-7">
                            <div class="user-data">
                                <b>Bio</b>
                                <p v-if="customerData.BioDiscription">{{customerData.BioDiscription}}</p>
                                <p v-else>-</p>
                            </div>
                        </div>
                        <div class="col-sm-3"></div>

                        <div class="col-sm-7">
                            <div class="btn-wrapper">
                                <button class="btn btn-yellow" @click="removeFromContactList(customerData)">Remove</button>
                              
                                <button class="btn btn-white black" v-if="blockstatus != true" @click="IsBlockCustomer(customerData.id,customerData,chatId)">Block</button>
                                <button class="btn btn-white black" v-else @click="unblockUser(customerData,chatId)">Unblock</button>   
   
                            </div>
                        </div>
                          <b-modal id="modalCompany" class="modal-contact" hide-footer centered @close="closeFire()" :no-close-on-backdrop="true">
                            <div class="contact-modal">
                                <div class="contact-modal add_member_business">
                                <h4>Select Company</h4>
                                <!-- <p>Please enter email or phone number to add contact.</p> -->
                                <ValidationObserver ref="emailRequest" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(companyselection)">
                                   
                                    <ValidationProvider rules="required" name="Company selection" v-slot="{ errors }">
                                    <div class="form-group">
                                        <ul>
                                            <li v-for="(company,index) in userData.companies" v-bind:key="index">
                                                <div class="radio radio_business">
                                                    <input type="radio" :id="'company'+index" name="Company selection" :value="company.companyName" v-model="selectedcompanyName" @change="changeCompanyName(company)" >
                                                    <label class="radio-label" :for="'company'+index">{{company.companyName}}</label>
                                                </div>
                                            </li>
                                            <span class="InvalidInput">{{ errors[0] }}</span>
                                        </ul>
                                    </div>
                                    </ValidationProvider>
                                    <div class="btn-wrapper">
                                        <button type="submit" class="btn btn-blue" v-bind:class="{disabled: isSpinner == true}">Add</button>
                                    </div>
                                </form>
                                </ValidationObserver>
                            </div>
                            </div>
                        </b-modal>
                    </div>
                    
                  </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import Header from "@/components/header.vue";
import firebase from "../views/firebaseInit"
import firebaseData from '../utils/firebaseCollection.js';
import {getFreePlanObject,getCompanyUserArray,getLoginUserData} from '@/utils/getQueeryActiveChatList.js'
import Swal from 'sweetalert2'
const db = firebase.firestore()
export default {
  name: "userprofile",
  components: {
    Header,
    firebaseData
  },
    data(){
      return{
          customerData:{},
          imageUrl:{},
          userData:{},
          loginUser:'',
          isAdmin:false,
          activeGroup:false,
           groupData:{},
           chatObject:{},
           activeChat:false,
           selectedcompanyName:'',
           isSpinner:false,
           selectedCompany:{},
           FreePlanObject:{},
           companyUsersArray:[],
           unblockuser:false,
           chatid:'',
           blockstatus:'',
           chatId:'',
           routeid:this.$route.params.id
          
      }
    },
    created(){
        var self=this;
        self.getCustomerData();
        const db = firebase.firestore()
       // GET PROFILE PICTURE (Shraddha)
      self.userid = localStorage.getItem('customerDocKey');
      getLoginUserData(firebaseData.firebasecollection.USER,(res) => {
        self.userData = res;
        self.imageUrl = res.profileUrl;
        // console.log('self.userData',self.userData,self.imageUrl)
      })
      // CHECK LOGIN USER IS ADMIN OR CUSTOMER(Shraddha)
      self.loginUser = localStorage.getItem("IsLoginUser")
      //console.log('loginUser',self.loginUser);
      if(this.loginUser == 'Admin'){
        self.isAdmin = true
      }else{
        self.isAdmin = false
      }
        
      //console.log(this.isAdmin)
    },
    watch : {
        $route : function (){
            this.getCustomerData();
        }
    },
    methods:{
        //GET CUSTOMER DATA FROM USER COLLECTION USING ROUTE ID(Shraddha)
        getCustomerData(){
            debugger // eslint-disable-line
            var self=this;
            self.customerData = {}
            const db = firebase.firestore()
            var key = self.$route.params.id;
            db.collection(firebaseData.firebasecollection.USER).doc(key).
            onSnapshot((doc)=>{
                self.customerData = doc.data()
                //console.log('self.customerData',self.customerData )
            })
            db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialContact').doc(self.$route.params.id).
            onSnapshot(doc=>{
                self.blockstatus = doc.data().block || ''
                self.chatId = doc.data().chatId || ''
            })
        },
        // REMOVE USER FROM CONTACT LIST(Shraddha)
        removeFromContactList(data){
           debugger // eslint-disable-line
            var self=this;
            var removeid = data.id
            const db = firebase.firestore()
            // //console.log(removeid);
            var user = firebase.auth().currentUser;
            var removeChatid = ''
            //console.log(user.uid)
            db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection("socialContact").doc(removeid).get().then(snap => {
                if(snap.data().chatId != undefined){
                    removeChatid = snap.data().chatId
                    //console.log('removeChatid',removeChatid)
                    db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(removeChatid).delete().then(()=>{
                        db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection("socialContact").doc(removeid).delete().then ( () => {
                            db.collection(firebaseData.firebasecollection.USER).doc(removeid).collection("socialContact").doc(user.uid).delete().then( () => {
                                self.$root.$emit("audioplay");
                                self.$notify({
                                    title:"Success",
                                    text:data.firstName+' '+data.lastName+' removed successfully.' ,
                                    type:"success"
                                })
                               self.backtoContact('contact');
                            });
                        });
                    })
                }else{
                    db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection("socialContact").doc(removeid).delete().then ( () => {
                        db.collection(firebaseData.firebasecollection.USER).doc(removeid).collection("socialContact").doc(user.uid).delete().then( () => {
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Success",
                                text:data.firstName+' '+data.lastName+' removed successfully.' ,
                                type:"success"
                            })
                            self.backtoContact('contact');
                        });
                    });
                }
            })
        },
       // BLOCK USER (Shraddha)
        IsBlockCustomer(blockid,customerData,chatingId){
             debugger // eslint-disable-line
            var self=this;
           self.unblockuser = true
            
            const db = firebase.firestore()
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
          Swal.fire({
              
              title:"Are you sure you want to block"+' '+customerData.firstName+' '+customerData.lastName+' '+"?",
              type: "warning",
              customClass:{
                container :"social_confirmation_popup"
                },
              showCancelButton: true,
              confirmButtonColor: "#ffab3f",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
            // //console.log(blockid);
            var user = firebase.auth().currentUser;
            //console.log(user.uid)
            var removeChatid = '';
            db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection('socialContact').doc(blockid).get().then(() => {
                // if(snap.data().chatId != undefined){
                //     removeChatid = snap.data().chatId
                //     db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(removeChatid).delete().then(()=>{
                //         db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection('socialBlockUser').doc(blockid).set({
                //             id:blockid
                //         }).then( () => {
                //             db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection('socialContact').doc(blockid).set({
                //                 block:true,
                //                 blockFrom : user.uid,
                //                 id:blockid
                //             }).then( () => {
                //                db.collection(firebaseData.firebasecollection.USER).doc(blockid).collection('socialContact').doc(user.uid).set({
                //                    id:user.uid
                //                }).then(()=>{
                // self.$root.$emit("audioplay");//                     
                // self.$notify({
                //                     title:"Success",
                //                     text:'customer is block successfully',
                //                     type:"success"
                //                 })
                //                 self.$root.$emit('activeGroup' ,'yes');
                //                }) 
                //             })
                //         })  
                //     }) 
                // }else{
                  db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection('socialBlockUser').doc(blockid).set({
                        id:blockid
                    }).then( () => {
                        db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection('socialContact').doc(blockid).update({
                            block:true,
                            blockFrom : user.uid,
                        }).then( () => {
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Success",
                                text:customerData.firstName+' '+customerData.lastName+' blocked.',
                                type:"success"
                            })
                            self.$root.$emit('activeGroup' ,'yes');
                        })
                    })
                    if(chatingId != ''){
                        var blockArray = []
                        db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatingId).get().then(doc => {
                            blockArray = doc.data().blockUserArray || []
                            blockArray.push({
                                blockBy : user.uid,
                                blockTo : blockid
                            })
                            
                        }).then(() => {
                            db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chatingId).update({
                            blockUserArray : blockArray,
                            
                            })
                        })
                        }      
                // }
            })
            }
          })
        },
         //UNBLOCK USER CODE(Shraddha)
        unblockUser(blockuser,chattingId){
            debugger // eslint-disable-line
            var self=this
            self.unblockuser = false
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
            Swal.fire({
                title: 'are you sure you want to unblock'+' '+blockuser.firstName+' '+blockuser.lastName+ '?',
                type: "warning",
                customClass:{
                    container :"social_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: "#ffab3f",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, unblock it!",
            }).then((result) => {
                if (result.value) {
                    db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialContact').doc(blockuser.id).get().then(doc => {
                        //console.log('doc',doc)
                        if(doc.data().chatId != undefined){
                            self.chatid = doc.data().chatId
                        }else{
                        self.chatid = '' 
                        }
                    }).then(() => {
                        var blockArray = []
                        if(self.chatid != ''){
                            db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialContact').doc(blockuser.id).set({
                                id: blockuser.id,
                                chatId : self.chatid
                            }).then(()=>{
                                db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialBlockUser').doc(blockuser.id).delete().then(()=>{
                                    self.$root.$emit("audioplay");
                                    self.$notify({
                                        title:"Success",
                                        text:blockuser.firstName+' '+blockuser.lastName+' unblocked.',
                                        type:"success"
                                    })
                                })
                            })
                            db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chattingId).get().then(doc => {
                                blockArray = doc.data().blockUserArray
                                var index = blockArray.findIndex(product => {
                                    return product.blockBy == firebase.auth().currentUser.uid && product.blockTo ==  blockuser.id
                                })
                                blockArray.splice(index,1);
                            }).then(() => {
                            db.collection(firebaseData.firebasecollection.SOCIALMESSAGE).doc(chattingId).update({
                                blockUserArray : blockArray

                            })
                            })
                        }else{
                        db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialContact').doc(blockuser.id).set({
                                id: blockuser.id,
                            }).then(()=>{
                                db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection('socialBlockUser').doc(blockuser.id).delete().then(()=>{
                                self.$root.$emit("audioplay");
                                self.$notify({
                                        title:"Success",
                                        text:blockuser.firstName+' '+blockuser.lastName+' unblocked.',
                                        type:"success"
                                    }) 
                                })
                            }) 
                        }
                    })
                }
            })
        },
        // FOR BACK TO HOME
        backtoContact(value){
           // //debugger // eslint-disable-line
        //    this.$root.$emit('backtocontact',value)
           localStorage.removeItem('backtocontact','');
           localStorage.setItem('backtocontact',value);
           this.$router.push('/chat'); 
        },
        changeCompanyName(company){
            var self = this;
            self.selectedCompany = company
        },
        //SEND BUSINESS CONTACT REQUEST(Shraddha)
        sendBusinessContactRequest(){
            debugger // eslint-disable-line
            var self = this;
            if(self.userData.companiesOwned == undefined){
                self.$router.push('/generalsettings/Subscription_Details')
            }else{
                console.log('companies',self.userData.companies,self.selectedcompanyName)

            }
        },
        //COMPANY POPUP (Shraddha)
        companyselection(){
            debugger // eslint-disable-line
            var self = this; 
            self.companyUsersArray  = []
            self.isSpinner = true
            console.log('companies',self.userData.companies,self.selectedCompany)
            getFreePlanObject(firebaseData.firebasecollection.COMPANY,self.selectedCompany.id,res => {
                self.FreePlanObject = ''
                self.FreePlanObject = res
                console.log(self.FreePlanObject,firebaseData.firebasecollection.BUSINESSREQUEST)
                getCompanyUserArray(firebaseData.firebasecollection.COMPANY,self.selectedCompany.id,res => {
                  self.companyUsersArray = res  
                    console.log(self.companyUsersArray)
                var isInArray =  self.companyUsersArray.some(function(item) {
                    return item.email == self.customerData.email 
                });
              
                
                    if(isInArray == false){   
                        db.collection(self.selectedCompany.id).doc(self.selectedCompany.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).add({
                            id:'',
                            receiverId:self.customerData.id,
                            senderId:self.userData.id,
                            status:0
                        }).then( (docid) => {
                            console.log(docid)
                            db.collection(self.selectedCompany.id).doc(self.selectedCompany.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).doc(docid.id).update({
                                id:docid.id
                            }).then(() => {
                                self.$axios.post(self.$apiurl+'sendEmail',{email:self.customerData.email,type:'Request',requestType:'Business',userId:self.userData.id,companyId:self.selectedCompany.id,receiverId:self.customerData.id,planId:self.FreePlanObject.plan_id})
                                .then((res)=>{
                                    console.log('resresres',res)
                                    self.isSpinner = false;
                                    // self.$refs.emailRequest.reset()
                                })
                                self.$root.$emit("audioplay");
                                self.$notify({
                                    title:"Success",
                                    text:'Invite sent.',
                                    type:"success",
                                    duration: 10000,
                                })
                                db.collection(firebaseData.firebasecollection.USER)
                                .doc(self.customerData.id)
                                .get().then(doc=>{
                                    
                                    var users = []
                                    if(doc.exists)
                                    {
                                        db.collection('companies')
                                        .doc(self.selectedCompany.id)
                                        .get().then(doc2=>{
                                            var plan = doc2.data().plan
                                            users = doc2.data().users
                                            plan.forEach(plans => {
                                            if(plans.plan_id == self.FreePlanObject.plan_id){
                                                    users.push({
                                                        id:self.customerData.id,
                                                        profileUrl :doc.data().profileUrl,
                                                        name:`${doc.data().firstName} ${doc.data().lastName}`,
                                                        planName:plans.plan_name,
                                                        planId:self.FreePlanObject.plan_id,
                                                        email:self.customerData.email,
                                                        usedSelfie : 0,
                                                        selfieUsers:[],
                                                        status:'Pending to accept'
                                                    })
                                                }
                                            })
                                            console.log('usersArr',users)
                                            db.collection('companies')
                                            .doc(self.selectedCompany.id)
                                            .update({
                                                users:users
                                            }).then(() => {
                                                return false
                                            })
                                            .catch(error=>{
                                                console.log(error)
                                                self.isSpinner = false;
                                                
                                            })
                                        })
                                    }
                                })
                                self.selectedcompanyName = ''
                                self.$bvModal.hide('modalCompany');
                                self.$refs.emailRequest.reset()
                                self.isSpinner = false;
                            })
                        })
                    }else{
                        self.isSpinner = false;
                        self.$refs.emailRequest.reset()
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"ERROR",
                            text:'User  already exists',
                            type:"error"
                        })
                    }
                })
            })   
        },
        // CLOSE POPUP (Shraddha)
        closeFire(){
          var self = this;  
          self.selectedcompanyName = '',
          self.selectedCompany = {}
          self.isSpinner = false;
        }
    }
};
</script>
<style>
.greenDots{
  background-color: #85DA27;
  border-radius: 24px;
  vertical-align: middle;
  color: white;
  display: inherit;
  position: absolute;
    top: -2px;
    right: -15px;
}
</style>