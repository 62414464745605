<template>
<div class="chat-list-wrapper">
            <div class="search-area">
              <form @submit.prevent>
                <div class="form-group">
                  <input
                    type="search"
                    class="form-control"
                    placeholder="Search"
                    v-model="keyword"
                    @keyup="searchFilter()"
                  />
                  <button  @click.prevent><img src="@/assets/images/search.png" /></button>
                </div>
              </form>
              <img src="@/assets/images/blue-addcontact.png" title="Add contact" v-b-modal.modal-centers v-if="Comapnyadmin == true"/>
            </div>
            <div class="contact-list-info">
                <div>
                    
                    <div style="margin-top:10px" v-if="Comapnyadmin == true ">
                        <label style="margin-right:10px">FILTER</label>
                        <select v-model="selectFilter" @change="filterByPackage()">
                            <option value="All">All</option>
                            <option v-show="planData" v-for="contactData in planData" :key="contactData.plan_name"  :value="contactData.plan_name">{{contactData.plan_name}}</option>
                        </select>
                        <span v-if="contactListingData.length == 0" style="text-align:center;width:100%;float:left;color:red;">No Data Found</span>
                    </div>
                    
                    <div class="contact-list" >
                        <ul class="mainContactListItem">
                            <li 
                                v-for="(accseptRequest,index) in contactListingData" v-bind:key="index" 
                                
                                v-show="accseptRequest.id!=fuid || removeUserId != accseptRequest.id" 
                                :class="{'deleteduser':accseptRequest.isDeleted}"
                            >
                                <div class="list-item" @click="IsChatCustomer(accseptRequest.id,accseptRequest.chatid)">
                                    <div v-bind:class="{blockadd : accseptRequest.block == true}" > 
                                    <img v-if="accseptRequest.profileUrl" class="contactprofile" :src="accseptRequest.profileUrl" >
                                    <div v-if="accseptRequest.online" class="greenDot" style="height:8px;width: 8px;margin-top: 7px;"></div>
                                    <div v-else-if="botUser.id == accseptRequest.id" class="greenDot" style="height:8px;width: 8px;margin-top: 7px;"></div>
                                    <!-- <span class="contactprofile profilecharecter" v-else>{{accseptRequest.firstName.charAt(0).toUpperCase()}}</span> -->
                                    <span>
                                        
                                    <span v-if="(accseptRequest.firstName.length + accseptRequest.lastName.length) <= 15">{{accseptRequest.firstName}} {{accseptRequest.lastName}}</span>
                                    <span :title="(accseptRequest.firstName + accseptRequest.lastName)" v-else>{{(accseptRequest.firstName + accseptRequest.lastName).slice(0, 15)+'...'}}</span>
                                        <!-- {{accseptRequest.firstName}} {{accseptRequest.lastName}} -->
                                        </span>
                                    </div>
                                    <!-- <img src="@/assets/images/dotimg.png" class="dotimg" /> -->
                                    <b-dropdown id="dropdown-offset" text="" v-if="botUser.id != accseptRequest.id" >
                                        <li > <router-link v-bind:to="{ name: 'businessuserprofile', params: { id: accseptRequest.id }}"  class="dropdown-item">View</router-link></li>
                                        <b-dropdown-item v-if="!accseptRequest.block" href="#" @click="IsChatCustomer(accseptRequest.id,accseptRequest.chatid)">Chat</b-dropdown-item>
                                        <b-dropdown-item v-if="!accseptRequest.block" href="#" @click="IsBlockCustomer(accseptRequest.id,accseptRequest,accseptRequest.chatid)">Block</b-dropdown-item>
                                        <b-dropdown-item v-else-if="accseptRequest.block" href="#" @click="unblockUser(accseptRequest,index,accseptRequest.chatid)">Unblock</b-dropdown-item>
                                        <b-dropdown-item href="#" v-if="Comapnyadmin" @click="removeFromContact(accseptRequest)">Remove</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div v-if="Comapnyadmin == true">
                                    {{accseptRequest.packageName}}
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            <Nocontact v-if="!activeChat" v-show="false"/>
          </div>
</template>
<script>
import Vue from 'vue'
import firebase from "../../views/firebaseInit"
import firebaseData from '../../utils/firebaseCollection.js';
import {Contactlist2,getContactCount2,outgoingUserdata2,incomingUserData2,getIncomingrequestCounting2,getUserData2,getCountingFunction2,getDefaultCollection2,getplanName2} from '../../businessUtills/getQueeryActiveChatList.js'
import {getBotuserData} from "@/utils/getQueeryActiveChatList.js";
import Nocontact from '../../components/businessComponent/businessNoContact.vue'
import Swal from "sweetalert2";
import { mapActions,mapGetters } from "vuex";
const db = firebase.firestore()
import firebaseTimestamp from "firebase";
export default ({
  name:'contactDetail',
  props: ['activeChat'],
    components: {
        firebaseData,
        Nocontact
    },
   
    data(){
      return{
          // FOR OUTGOING REQUEST (Shraddha)
          receiverId : [],
          outgoingRequestArray:[],
          // FOR INCOMING REQUEST(Shraddha)
          senderId:[],
          IncomingRequestArray:[],
          // FOR GET USER COLLECTION(Shraddha)
          getUserData:[],
          // ACCSEPT REQUEST STORE(Shraddha)
          acseptUserData:[],
          //FOR SEARCH
          keyword:'',
          //CHAT OBJECT
          chatObject:{},
          groupObject:{},
          contactListingData:[],
          searchContactArray : [],
          Comapnyadmin : '',
        //   CompanyOtherMember:[]
         selectFilter:'All',
         planData:[],

         fuid:'',
         removeUserId:'',
         chatid:'',
         botUser:{}
          
      }
    },
    created(){
        // //debugger // eslint-disable-line
        var self = this;
        getBotuserData(firebaseData.firebasecollection.USER,(res) => {
            self.botUser = res;
        })
        if(Object.keys(self.userData).length==0)
        {
            self.setUserStoreData(firebase.auth().currentUser.uid)
            .then(result=>{
                // console.log('getChatlist',result)
                // self.getSetSocialChatList()
                self.getSetBusinessChatList()
                // self.getSetSocialContacts()
            })
        }else{
            // self.getSetSocialChatList()
            self.getSetBusinessChatList()
        }

        this.planData = []
        this.$root.$on('getUserList',() => {
            this.getContactList();
        })
        this.$root.$on('changeHeader',(val) => {
            // debugger // eslint-disable-line
            Vue.prototype.$companyName = val
            this.companyName = val
            this.Comapnyadmin = val.isAdmin
        })
        this.fuid = firebase.auth().currentUser.uid
        this.$root.$on('getCompanyAdmin',(val) => {
            //debugger // eslint-disable-line
           
            this.Comapnyadmin = val.isAdmin
            this.planData = []
            //  const db = firebase.firestore()
                // console.log('Vue.prototype.$companyName',Vue.prototype.$companyName.id,firebaseData.firebasecollection.COMPANY)
                if(Vue.prototype.$companyName){
                    db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc => {
                        var plan = doc.data().plan
                        this.planData = []
                        // setTimeout(() => {
                            
                            plan.forEach(data => {
                                if(data.plan_status != 'deactivated'){
                                    this.planData.push({'plan_name':data.plan_name})
                                }
                            })
                            const ids = this.planData.map(o => o.plan_name)
                            const filtered = this.planData.filter(({plan_name}, index) => !ids.includes(plan_name, index + 1))
                            this.planData = filtered
                            // console.log('planDataplanData',this.planData)
                        // },300)
                        
                    })
                }
        })
        var selectCompany = JSON.parse(localStorage.getItem('SelectedCompany'))
        if(selectCompany != '' && selectCompany !=undefined){
            Vue.prototype.$companyName =  selectCompany
            this.Comapnyadmin = selectCompany.isAdmin
            self.companyName = selectCompany
        }
        
        this.getContactList();
        this.$root.$on("getRequest",(value)=>{
            // //debugger // eslint-disable-line
            if(value == 'yes'){
                this.getContactList();
            }
        })
        this.$root.$on("getContact",(value)=>{
            // //debugger // eslint-disable-line
            if(value == 'yes'){
                this.getContactList();
            }
        })
        
    },
    
    methods:{
        ...mapActions({setUserStoreData:"setUserData"}),
        ...mapActions({getSetSocialChatList:"getSetSocialChatList"}),
        ...mapActions({getSetBusinessChatList:"getSetBusinessChatList"}),
        ...mapActions({getSetBusinessChatData:'getSetBusinessChatData'}),
        unblockPopup(userDetails) {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
            Swal.fire({
                title:
                "Unblock " +
                userDetails.firstName +
                " " +
                userDetails.lastName +
                " to send a message.",
                type: "warning",
                customClass:{
                    container :"business_confirmation_popup"
                },
                confirmButtonColor: "#77bef6",
            });
        },
        //UNBLOCK USER CODE(Shraddha)
        unblockUser(blockuser,index,chattingId){
            // debugger // eslint-disable-line
            var self=this
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
            Swal.fire({
                title: 'Are you sure you want to unblock'+' '+blockuser.firstName+' '+blockuser.lastName+ '?',
                type: "warning",
                customClass:{
                    container :"business_confirmation_popup"
                },
                showCancelButton: true,
                confirmButtonColor: "#77bef6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, unblock it!",
            }).then((result) => {
                if (result.value) {
                    // console.log(firebase.auth().currentUser.uid)
                    db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockuser.id).get().then(doc => {
                        //console.log('doc',doc)
                        if(doc.data().chatId != undefined){
                            self.chatid = doc.data().chatId
                        }else{
                        self.chatid = '' 
                        }
                    }).then(() => {
                         var blockArray = []
                        if(self.chatid != ''){
                            db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockuser.id).set({
                                id: blockuser.id,
                                chatId : self.chatid
                            }).then(()=>{
                                db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockuser.id).delete().then(()=>{
                                self.$root.$emit("audioplay");
                                self.$notify({
                                        title:"Success",
                                        text:blockuser.firstName+' '+blockuser.lastName+' unblocked.',
                                        type:"success"
                                    }) 
                                     self.$root.$emit('activeGroup' ,'yes');
                                })
                            })
                            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chattingId).get().then(doc => {
                                blockArray = doc.data().blockUserArray
                                var index = blockArray.findIndex(product => {
                                    return product.blockBy == firebase.auth().currentUser.uid && product.blockTo ==  blockuser.id
                                })
                                blockArray.splice(index,1);
                            }).then(() => {
                                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chattingId).update({
                                    blockUserArray : blockArray,
                                    
                                })
                            })
                        }else{
                        db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockuser.id).set({
                                id: blockuser.id,
                            }).then(()=>{
                                db.collection(firebaseData.firebasecollection.USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockuser.id).delete().then(()=>{
                                self.$root.$emit("audioplay");
                                self.$notify({
                                        title:"Success",
                                        text:blockuser.firstName+' '+blockuser.lastName+' unblocked.',
                                        type:"success"
                                    }) 
                                })
                                 self.$root.$emit('activeGroup' ,'yes');
                            }) 
                        }
                    })
                }
            })
        },
        //GET OUTGOING REQUEST (Shraddha)
        getOutgoingRequest(){
            // //debugger // eslint-disable-line
            var self=this;
            self.outgoingRequestArray = [];
            const db = firebase.firestore()
            var senderId = localStorage.getItem("customerDocKey");
            outgoingUserdata2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSREQUEST,self.outgoingRequestArray,senderId,res =>{
                self.outgoingRequestArray = res
                getUserData2(firebaseData.firebasecollection.USER,self.getUserData,res=>{
                    self.getUserData = res
                    getCountingFunction2(self.outgoingRequestArray,self.getUserData,res=>{
                        self.outgoingRequestArray = res
                    })
                })
            })
        },
        //GET INCOMING REQUEST (Shraddha)
        getIncomingRequest(){
            // //debugger // eslint-disable-line
            var self=this;
            self.IncomingRequestArray = [];
            const db = firebase.firestore()
            var senderId = localStorage.getItem("customerDocKey");
            incomingUserData2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSREQUEST,self.IncomingRequestArray,senderId,res=>{
               self.IncomingRequestArray = res
                getUserData2(firebaseData.firebasecollection.USER,self.getUserData,res=>{
                    self.getUserData = res
                    getIncomingrequestCounting2(self.IncomingRequestArray,self.getUserData,res=>{
                        self.IncomingRequestArray = res
                    })
                })
            })
        },
        //REMOVE OUTGOINGREQUEST WHEN DECLINE(Shraddha)
        removeOutgoingRequest(removeId){
            // //debugger // eslint-disable-line
            var self = this;
            const db = firebase.firestore()
            // //console.log(removeId);
            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).doc(removeId).delete();
            self.getOutgoingRequest();
        },
        //REMOVE INCOMINGREQUEST WHEN DECLINE(Shraddha)
        removeIncomingRequest(removeId){
            // //debugger // eslint-disable-line
            var self = this;
            const db = firebase.firestore()
            // //console.log(removeId);
            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).doc(removeId).delete();
            self.getIncomingRequest();
        },
        //WHEN USER ACCSEPT THE REQUEST(Shraddha)
        accseptIncomingRequest(requestId){
            // //debugger // eslint-disable-line
            var self=this;
            const db = firebase.firestore()
            var requestData = '';
            // //console.log(requestId)
            db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSREQUEST).doc(requestId)
            .get()
            .then(doc => {
                requestData = doc.data();
                // //console.log(requestData);
                db.collection(firebaseData.firebasecollection.USER).doc(requestData.senderId).collection(Vue.prototype.$companyName.id+'_businessContact').doc(requestData.receiverId).set({
                    id:requestData.receiverId,
                   
                })
                db.collection(firebaseData.firebasecollection.USER).doc(requestData.receiverId).collection(Vue.prototype.$companyName.id+'_businessContact').doc(requestData.senderId).set({
                    id:requestData.senderId,
                    
                })

            }).then( () => {
                self.removeIncomingRequest(requestId);
                self.getContactList();
            })
            

        },
        //GET CONTACT LIST(Shraddha)
        getContactList(){
            //debugger // eslint-disable-line
            var self=this;
            self.acseptUserData=[];
            const db = firebase.firestore();
            var user = localStorage.getItem("customerDocKey");
            if(Vue.prototype.$companyName){
                Contactlist2(firebaseData.firebasecollection.USER,self.acseptUserData,res=>{
                    self.acseptUserData = res
                    getContactCount2(firebaseData.firebasecollection.USER,self.getUserData,self.acseptUserData,res =>{
                        self.contactListingData = res 
                        getplanName2(Vue.prototype.$companyName.id,self.contactListingData,res=>{
                            self.contactListingData = res
                            self.searchContactArray = res
                            // getDefaultCollection2(Vue.prototype.$companyName.id,firebaseData.firebasecollection.BUSINESSMESSAGE,self.contactListingData,res=>{
                            //     self.contactListingData = res
                            //     self.searchContactArray = res
                            // //    console.log('self.contactListingData',self.contactListingData)
                            // })
                        })  
                    })
                })
            }  
        },
        //REMOVE FROM CONTACT LIST(Shraddha)
        removeFromContact(data){
            // debugger // eslint-disable-line
            var self=this;
            self.removeUserId = data.id;
            var removeid = data.id
            const db = firebase.firestore()
            // //console.log(removeid);
            var user = firebase.auth().currentUser;
            var removeChatid = ''
            var currentSelectedCompany = ''
            //console.log(user.uid)
            db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).get().then(doc=>{
                var users = doc.data().users
                var index = users.findIndex(usr => {
                    return usr.id == removeid  
                })
                users.splice(index,1)
                // console.log(users)
                    db.collection(firebaseData.firebasecollection.COMPANY).doc(Vue.prototype.$companyName.id).update({
                        users:users
                    })
            }).then(() => {
                db.collection(firebaseData.firebasecollection.USER).doc(removeid).get().then(docs =>{
                    var companies = docs.data().companies
                    var index1 = companies.findIndex(usr => {
                        return usr.id == Vue.prototype.$companyName.id  
                    })
                    companies.splice(index1,1)
                    // console.log(companies)
                    if(companies.length == 0){
                        currentSelectedCompany = 'social'
                    }else{
                        currentSelectedCompany = companies[0].id
                    }
                    db.collection(firebaseData.firebasecollection.USER).doc(removeid).update({
                        companies : companies ,
                        currentSelectedCompany : currentSelectedCompany
                    })
                })
            }).then(() => {
                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).onSnapshot(querySnapshot => {
                          querySnapshot.forEach(doc=>{
                            if(doc.exists){
                              if(doc.data().isGroup == true || doc.data().isChannel == true){
                                var connectedUsers = doc.data().connectedUser
                                var index = connectedUsers.findIndex(item => {
                                    return item == removeid  
                                })
                                if(index != -1){
                                  connectedUsers.splice(index,1);
                                }
                              }
                              db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(doc.data().id).update({
                                connectedUser : connectedUsers
                              })
                            }
                          })
                        })
                      }).then(() => {
                db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(removeid).get().then(snap => {
                    if(snap.data().chatId != undefined){
                        removeChatid = snap.data().chatId
                        //console.log('removeChatid',removeChatid)
                        db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(removeChatid).delete().then(()=>{
                            db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(removeid).delete().then ( () => {
                                db.collection(firebaseData.firebasecollection.USER).doc(removeid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(user.uid).delete().then( () => {
                                    self.$root.$emit("audioplay");
                                    self.$notify({
                                        title:"Success",
                                        text:data.firstName+' '+data.lastName+' removed successfully.',
                                        type:"success"
                                    })
                                    self.getContactList();
                                    self.$root.$emit('activeGroup' ,'yes');
                                });
                            });
                        })
                    }else{
                        db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(removeid).delete().then ( () => {
                            db.collection(firebaseData.firebasecollection.USER).doc(removeid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(user.uid).delete().then( () => {
                                self.$root.$emit("audioplay");
                                self.$notify({
                                    title:"Success",
                                    text:data.firstName+' '+data.lastName+' removed successfully.',
                                    type:"success"
                                })
                                self.getContactList();
                                self.$root.$emit('activeGroup' ,'yes');
                            });
                        });
                    }
                })
            })
        },
        // BLOCK CUSTOMER
        IsBlockCustomer(blockid,accseptRequest,chatingId){
            debugger // eslint-disable-line
            var self=this;
            const db = firebase.firestore()
            // //console.log(blockid);
            var user = firebase.auth().currentUser;
            //console.log(user.uid)
            var removeChatid = '';
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
            Swal.fire({
                title:
                "Are you sure you want to block" +
                " " +
                accseptRequest.firstName +
                " " +
                accseptRequest.lastName +
                " " +
                "?",
                type: "warning",
                customClass:{
                    container :"business_confirmation_popup"
                },

                showCancelButton: true,
                confirmButtonColor: "#77bef6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            })
            .then((result) => {
                if(result.value)
                {
                var user = firebase.auth().currentUser;
                db.collection(firebaseData.firebasecollection.USER)
                .doc(user.uid)
                .collection(Vue.prototype.$companyName.id+'_businessContact')
                .doc(blockid)
                .get()
                .then(() => {
                        
              db.collection(firebaseData.firebasecollection.USER)
                .doc(user.uid)
                .collection(Vue.prototype.$companyName.id+'_businessBlockUser')
                .doc(blockid)
                .set({
                  id: blockid,
                })
                .then(() => {
                  db.collection(firebaseData.firebasecollection.USER)
                    .doc(user.uid)
                    .collection(Vue.prototype.$companyName.id+'_businessContact')
                    .doc(blockid)
                    .update({
                      block: true,
                      blockFrom: user.uid,
                    })
                    .then(() => {
                      self.$root.$emit("audioplay");
                      self.$notify({
                        title: "Success",
                        text: `${accseptRequest.firstName} ${accseptRequest.lastName} blocked.`,
                        type: "success",
                      });
                      self.getContactList();
                      self.$root.$emit("activeGroup", "yes");
                    });
                });
                if(chatingId != undefined){
                    var blockArray = []
                  db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatingId).get().then(doc => {
                      blockArray = doc.data().blockUserArray || []
                      blockArray.push({
                        blockBy : user.uid,
                        blockTo : blockid
                      })
                    
                  }).then(() => {
                    db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatingId).update({
                      blockUserArray : blockArray
                    })
                  })
                }
              
            });
                    // db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockid).get().then((snap) => {
                    //     if(snap.data().chatId != undefined){
                    //         removeChatid = snap.data().chatId
                    //         db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(removeChatid).delete().then(()=>{
                    //             db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockid).set({
                    //                 id:blockid
                    //             }).then( () => {
                    //                 db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockid).set({
                    //                     block:true,
                    //                     blockFrom : user.uid,
                    //                     id:blockid
                    //                 }).then( () => {
                    //                    db.collection(firebaseData.firebasecollection.USER).doc(blockid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(user.uid).set({
                    //                        id:user.uid
                    //                    }).then(()=>{
                    // self.$root.$emit("audioplay");//                         
                    // self.$notify({
                    //                         title:"Success",
                    //                         text:accseptRequest.firstName +" " +accseptRequest.lastName +' blocked.',
                    //                         type:"success"
                    //                     })
                    //                     self.$root.$emit('activeGroup' ,'yes');
                    //                    }) 
                    //                 })
                    //             })  
                    //         })
                           
                           
                    //     }else{
                    //         db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessBlockUser').doc(blockid).set({
                    //             id:blockid
                    //         }).then( () => {
                    //             db.collection(firebaseData.firebasecollection.USER).doc(user.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(blockid).set({
                    //                 block:true,
                    //                 blockFrom : user.uid,
                    //                 id:blockid
                    //             }).then( () => {
                    // self.$root.$emit("audioplay");//                 
                    // self.$notify({
                    //                     title:"Success",
                    //                     text:accseptRequest.firstName +" " +accseptRequest.lastName +' blocked.',
                    //                     type:"success"
                    //                 })
                    //                 self.$root.$emit('activeGroup' ,'yes');
                    //             })
                    //         })  
                    //     }
                    // })
                }
            })
        },
        // CHAT WITH CUSTOMER (Shraddha)
        IsChatCustomer(objectid,chatid){
            // //debugger // eslint-disable-line
            var self=this;
            // self.$root.$emit('getChatid',chatid);
            const db = firebase.firestore()

            // console.log("BusinessUser",Vue.prototype.$companyName.id,'businessMessages',objectid)
            self.getSetBusinessChatData({companyId:Vue.prototype.$companyName.id,collection:'businessMessages',chatId:chatid})

            var mainBranchArray = []
            let initial = true;
            //console.log(objectid);
            if(objectid!=''){
                db.collection(firebaseData.firebasecollection.USER).doc(objectid).onSnapshot((querySnapshot) =>{
                    self.chatObject = querySnapshot.data();
                    if(initial) {
                        self.$root.$emit('openBusinessChat',self.chatObject,chatid,self.groupObject,mainBranchArray,"default");
                    }
                    initial = false;
                    // self.$root.$emit('chatObjefct',self.chatObject,chatid,self.groupObject,mainBranchArray);
                })
            }else{
                db.collection(Vue.prototype.$companyName.id).doc(Vue.prototype.$companyName.id).collection(firebaseData.firebasecollection.BUSINESSMESSAGE).doc(chatid).get().then((doc) =>{
                    self.groupObject = doc.data()
                    // self.$root.$emit('chatObjefct',self.chatObject,chatid,self.groupObject,mainBranchArray);
                    self.$root.$emit('openBusinessChat',self.chatObject,chatid,self.groupObject,mainBranchArray,"default");
                })
            }
            
        },
        // SEARCH FILTER BY CUSTOMER(Shraddha)
        searchFilter(){
            //debugger // eslint-disable-line
            var self=this;
            self.contactListingData =[]
            self.contactListingData = self.searchContactArray
            var arr=[]
            self.contactListingData .forEach(datas=>{
                var fullname = datas.firstName+' '+datas.lastName
                if(datas.firstName.toLowerCase().includes(self.keyword.toLowerCase()))
                {
                   arr.push(datas)
                }
                else if(datas.lastName.toLowerCase().includes(self.keyword.toLowerCase()))
                {
                   arr.push(datas)
                }
                else if(fullname.toLowerCase().includes(self.keyword.toLowerCase()))
                {
                  arr.push(datas)
                }
                // else if(datas.messages.length > 0){
                //     var found = false
                //     datas.messages.forEach(data =>{
                //         if(data.msg.toLowerCase().includes(self.keyword.toLowerCase())){
                //            found = true
                //         }
                //     })
                //     if(found == true){
                //         arr.push(datas)
                //     }  
                // }
                // else if(datas.messages.length > 0){
                //     var found = false
                //     datas.messages.forEach(data =>{
                //         if(data.name.toLowerCase().includes(self.keyword.toLowerCase())){
                //            found = true
                //         }
                //     })
                //     if(found == true){
                //         arr.push(datas)
                //     }  
                // }
                // else if(datas.messages.length > 0){
                //     var found = false
                //     datas.messages.forEach(data =>{
                //         if(data.type.toLowerCase().includes(self.keyword.toLowerCase())){
                //            found = true
                //         }
                //     })
                //     if(found == true){
                //         arr.push(datas)
                //     }  
                // }

            })
            //console.log('arr',arr)
            if(arr.length > 0){
              self.contactListingData  = arr   
            }
            if(arr.length == 0){
              self.contactListingData = []  
            }
            if(self.keyword == ''){
             self.contactListingData =self.searchContactArray
            }
        },
        //FILTER BY PACKGENAME(Shraddha)
        filterByPackage(){
            debugger // eslint-disable-line
            var self=this;
           self.contactListingData = self.searchContactArray 
            // console.log('selectFilter',self.selectFilter,self.searchContactArray,self.contactListingData);
            var filterArray = [];

            if(self.selectFilter != ''){
                self.contactListingData.forEach(row => {
                    if(self.selectFilter == row.packageName){
                        filterArray.push(row)
                    }
                })
                // console.log('filterArray',filterArray) 
                if(filterArray.length > 0){
                  self.contactListingData = filterArray
                  filterArray = [] 
                }else if(self.selectFilter == 'All'){
                  self.contactListingData = self.searchContactArray
                  filterArray = []
                }else{
                  self.contactListingData = []
                  filterArray = []
                }
            } 
        }
    },
    computed:{
        ...mapGetters({userData:"getUserData"}),
        ...mapGetters({storeChatId:"getChatId"}),
        ...mapGetters({storeCollectionName:"getCurrentCollection"}),
        ...mapGetters({storeSelectedCompany:"getSelectedCompany"}),
        ...mapGetters({storeSocialChatList:"getSocialChatList"}),
        ...mapGetters({storeBusinessChatList:"getBusinessChatList"}),
        // getFilterData() {
        //     var self = this;
        //     return self.contactListingData.filter(function(rawData) {
        //         return (
        //         rawData["firstName"]
        //             .toLowerCase()
        //             .indexOf(self.keyword.toLowerCase()) >= 0 ||
        //         rawData["lastName"]
        //             .toLowerCase()
        //             .indexOf(self.keyword.toLowerCase()) >= 0 
        //         );
        //     });
            
        // },
    }  
})
</script>
<style>
.greenDot{
  background-color: #85DA27;
  border-radius: 24px;
  vertical-align: middle;
  color: white;
  display: inherit;
  margin-left: -4px;
  position:inherit !important;
}
</style>
